import { useVue } from "@/app"
import config from "@/classes/config"
import cookies from "@/classes/cookieHandler"
import idleTimer from "@/classes/idleTimer"
import devLog from "@/classes/log"
import products, { clearObjectTypeStores } from "@/classes/objectTypes/index"
import { useUserLogStore } from "@/classes/userLogStore"
import encodingHelpers from "@/helpers/helpers.encoding"
import getterHelpers from "@/helpers/helpers.getters"
import sessionHelpers from "@/helpers/helpers.session"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import router from "@/router/router"
import useRouterStore from "@/router/routerStore"
import { MutationTypes, useStore } from "@/store/vuex.store"
import moment from "moment"
import apis from "../classes/apis"
import queries from "./queries"
import requestHandler from "./requests"

const unifiedSecurityQueries = {
    tenantFlagMapInfo: <FlagObjectMapInfo>["utmEulaAccepted", "utmPrivacyAccepted"],
    login: async function (username: string, password: string, rememberme: boolean, code: string) {
        try {
            return await requestHandler.request(
                "POST",
                "/sms-mgt-api/api/" + config.mgtApiVersionNext + "/auth",
                { username: username, password: password, rememberme: rememberme, code: code }
            )
        } catch (e) {
            throw await e
        }
    },

    getTenantInfo: async function (accountId?: string, props?: string | string[]) {
        let tenantDomain = accountId ? tenantHelpers.getTenantDomain(accountId) : undefined
        if (typeof props == "string") {
            props = [props]
        }
        props = props
            ? "?" +
              props.reduce((a: string, b: string) => {
                  a += `${a.length ? "&" : ""}props[]=${b}`
                  return a
              }, "")
            : ""
        return requestHandler.request(
            "GET",
            `/sms-mgt-api/api/1.1/tenants/${tenantDomain || ""}${props}`
        )
    },

    getFormShema: async function (name: string) {
        let result: any = false
        try {
            result = await $.ajax({
                type: "GET",
                url: "/sms/client/resources/forms/resource-shema-" + name + ".json",
                dataType: "json",
                contentType: "application/x-www-form-urlencoded; charset=UTF-8"
            }).done(function (data: any) {
                return data
            })
        } catch (e: any) {
            console.error(e)
        }
        return result
    },
    resetPassword: async (tenantDomain: string, username: string) => {
        try {
            return await requestHandler.request(
                "POST",
                "/sms-mgt-api/api/2.0/auth/reset_password",
                { tenantDomain, username }
            )
        } catch (e) {
            throw e
        }
    },
    checkAuth: async function () {
        let result: any = false
        let userInfo: any = false
        try {
            userInfo = await requestHandler.request("GET", "/sms-mgt-api/api/2.0/auth")
        } catch (e: any) {
            console.log(e)
            if (e.data.data == "2FA_REQUIRED") {
                cookies.delete("hash")
                cookies.delete("JSESSIONID")
                cookies.delete("SP_SESSIONID")
                cookies.delete("SP_OAUTH2")
                throw "2FA_REQUIRED"
            }
            if (e.data.data == "RSP_2FA_REQUIRED") {
                cookies.delete("hash")
                cookies.delete("JSESSIONID")
                cookies.delete("SP_SESSIONID")
                cookies.delete("SP_OAUTH2")
                if (useRouterStore().getActivePage == "login") {
                    useRouterStore().setContext("rsp2faError")
                } else {
                    useRouterStore().setActivePage("login")
                    useRouterStore().setContext("rsp2faError")
                }
                throw "RSP_2FA_REQUIRED"
            }
            userInfo = false
        }
        if (userInfo) {
            const hasRequiredRole = userInfo.roles.some(function (role: string) {
                return (
                    [
                        useStore().state.resources.roles.RESELLER,
                        useStore().state.resources.roles.SYSTEM,
                        useStore().state.resources.roles.TENANT,
                        useStore().state.resources.roles.ADMIN,
                        useStore().state.resources.roles.SUPPORT
                    ].indexOf(role) > -1
                )
            })

            const hasAccounts = userInfo.scopes.list_accounts.length > 0
            const has2FA = sessionHelpers.has2FAActive(userInfo)

            if (!has2FA && userInfo.domain != "carbon.super") {
                console.log("missing 2fa signing out")
                cookies.delete("hash")
                cookies.delete("JSESSIONID")
                cookies.delete("SP_SESSIONID")
                cookies.delete("SP_OAUTH2")
                if (sessionHelpers.isRspUser(userInfo)) {
                    window.location.href = "/sms/#login-rsp-2fa-error"
                } else {
                    throw "2FA_REQUIRED"
                }
            } else if (hasRequiredRole && hasAccounts) {
                result = userInfo
            } else {
                await requestHandler.request(
                    "DELETE",
                    "/sms-mgt-api/api/" + config.mgtApiVersionNext + "/auth"
                )
                if (
                    typeof useVue()?.$refs?.loginpage?.noRolesAndAccounts?.noAccounts ==
                        "boolean" &&
                    typeof useVue()?.$refs?.loginpage?.noRolesAndAccounts?.noRoles == "boolean"
                ) {
                    useVue().$refs.loginpage.noRolesAndAccounts.noAccounts = hasAccounts == false
                    useVue().$refs.loginpage.noRolesAndAccounts.noRoles = hasRequiredRole == false
                }
            }
        }
        return result
    },

    logout: async function (redirectToLogin: boolean = true) {
        let result: boolean = false
        let accountId = getterHelpers.useStore().state.session.activeAccountId

        const doLogout = async () => {
            devLog.log("Logout", "Killing idleTimer...", undefined, "default", 3)
            idleTimer.killIdleTimer()
            products.unifiedSecurityConsole.topologies.showDialogOnBeforeUnload = false
            try {
                if (useStore().state.session.userInfo.oauth2) {
                    cookies.delete("hash")
                    cookies.delete("JSESSIONID")
                    cookies.delete("SP_SESSIONID")
                    cookies.delete("SP_OAUTH2")
                    useStore().commit(MutationTypes.clear)
                    clearObjectTypeStores()
                    useUserLogStore().resetLog(true)
                    if (getterHelpers.useVue()?.showLog == true) {
                        getterHelpers.useVue()?.toggleLog()
                    }
                    location.href = "/oauth2/sign_out?rd=" + encodingHelpers.encodeURI("/sms/")
                    //result = await requestHandler.request("GET", "")
                } else {
                    useStore().commit(MutationTypes.clear)
                    clearObjectTypeStores()
                    useUserLogStore().resetLog()
                    if (getterHelpers.useVue()?.showLog == true) {
                        getterHelpers.useVue()?.toggleLog(true)
                    }
                    result = await requestHandler.request(
                        "DELETE",
                        "/sms-mgt-api/api/" + config.mgtApiVersionNext + "/auth"
                    )
                }
            } catch (e: any) {
            } finally {
                if (redirectToLogin) {
                    router.navigate("login")
                }
            }
            return result
        }

        // check for topology changes on logout (https://redmine.intern.securepoint.de/issues/38214)
        if (products.unifiedSecurityConsole.topologies.showDialogOnBeforeUnload) {
            products.unifiedSecurityConsole.topologies.view.getUnpulishedChangesDialog(
                accountId,
                doLogout,
                true
            )
        } else {
            doLogout()
        }
    },

    invalidateCache: async function () {
        let result: boolean = false
        try {
            result = await requestHandler.request(
                "GET",
                "/sms-mgt-api/api/" + config.mgtApiVersion + "/admin/caches/invalidate"
            )
        } catch (e: any) {}
        return result
    },

    getTenants: async function () {
        // props would be possible but we don't need that right now
        let result: any = await requestHandler.request(
            "GET",
            `/sms-mgt-api/api/${config.mgtApiVersion}/tenants?props[]=tenantDomain`
        )
        return result
    },
    getTenant: async function (accountid: string, props?: string | string[]) {
        let result: any = false
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore().getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountid)

            if (typeof props == "string") {
                props = [props]
            }
            props = props
                ? "?" +
                  props.reduce((a: string, b: string) => {
                      a += `${a.length ? "&" : ""}props[]=${b}`
                      return a
                  }, "")
                : ""

            let tenant: any = await requestHandler.request(
                "GET",
                `/sms-mgt-api/api/${config.mgtApiVersion}/tenants/${tenantDomain}${props}`
            )

            result = {}
            result[accountid] = {
                unifiedSecurity: {},
                mobileSecurity: {},
                unifiedReporting: {},
                unifiedSecurityConsole: {}
            }

            if (tenant.hasOwnProperty("accountName")) {
                if (!result[accountid].unifiedSecurity.hasOwnProperty("account")) {
                    result[accountid].unifiedSecurity.account = {}
                }
                result[accountid].unifiedSecurity.account.accountname = tenant.accountName
            }
            if (tenant.hasOwnProperty("active")) {
                if (!result[accountid].unifiedSecurity.hasOwnProperty("account")) {
                    result[accountid].unifiedSecurity.account = {}
                }
                result[accountid].unifiedSecurity.account.active = tenant.active
            }
            if (tenant.hasOwnProperty("created")) {
                if (!result[accountid].unifiedSecurity.hasOwnProperty("account")) {
                    result[accountid].unifiedSecurity.account = {}
                }
                result[accountid].unifiedSecurity.account.created = tenant.created
            }
            if (tenant.hasOwnProperty("timezone")) {
                if (!result[accountid].unifiedSecurity.hasOwnProperty("account")) {
                    result[accountid].unifiedSecurity.account = {}
                }
                result[accountid].unifiedSecurity.account.timezone = tenant.timezone
            }

            if (tenant.hasOwnProperty("accountRestrictions")) {
                if (!result[accountid].mobileSecurity.hasOwnProperty("settings")) {
                    result[accountid].mobileSecurity.settings = {}
                }
                result[accountid].mobileSecurity.settings.accountRestrictions =
                    tenant.accountRestrictions
            }

            if (tenant.hasOwnProperty("enrollmentAllowed")) {
                let enrollmentAllowed
                try {
                    enrollmentAllowed = await requestHandler.request(
                        "GET",
                        "/sms-mgt-api/api/2.0/tenants/" +
                            tenantDomain +
                            "?props[]=enrollmentAllowed"
                    )
                    enrollmentAllowed = enrollmentAllowed.enrollmentAllowed
                } catch (e) {
                    console.error(e)
                    enrollmentAllowed = tenant.enrollmentAllowed
                }
                if (!result[accountid].mobileSecurity.hasOwnProperty("settings")) {
                    result[accountid].mobileSecurity.settings = {}
                }
                result[accountid].mobileSecurity.settings.enrollmentAllowed = enrollmentAllowed
            }
            if (tenant.hasOwnProperty("maxDevices")) {
                if (!result[accountid].mobileSecurity.hasOwnProperty("settings")) {
                    result[accountid].mobileSecurity.settings = {}
                }
                result[accountid].mobileSecurity.settings.maxDevices = tenant.maxDevices
            }
            if (tenant.hasOwnProperty("mdmLicenseExceeded")) {
                if (!result[accountid].mobileSecurity.hasOwnProperty("settings")) {
                    result[accountid].mobileSecurity.settings = {}
                }
                result[accountid].mobileSecurity.settings.mdmLicenseExceeded =
                    tenant.mdmLicenseExceeded
            }

            if (tenant.hasOwnProperty("ios")) {
                if (tenant.hasOwnProperty("EID")) {
                    tenant.ios.EID = tenant.EID
                }
                if (tenant.hasOwnProperty("enrollmentURL")) {
                    tenant.ios.enrollmentURL = tenant.enrollmentURL
                }
                if (!result[accountid].mobileSecurity.hasOwnProperty("settings")) {
                    result[accountid].mobileSecurity.settings = {}
                }
                result[accountid].mobileSecurity.settings.ios = tenant.ios

                if (tenant.ios?.dep?.profiles != undefined) {
                    result[accountid].mobileSecurity.depProfiles = {
                        count: tenant.ios.dep.profiles
                    }
                }
            }
            if (tenant.hasOwnProperty("android")) {
                if (tenant.hasOwnProperty("EID")) {
                    tenant.android.EID = tenant.EID
                }
                if (!result[accountid].mobileSecurity.hasOwnProperty("settings")) {
                    result[accountid].mobileSecurity.settings = {}
                }
                result[accountid].mobileSecurity.settings.android = tenant.android

                if (!result[accountid].mobileSecurity.settings.android.zeroTouch) {
                    result[accountid].mobileSecurity.settings.android.zeroTouch = { enabled: false }
                }
            }
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            }
        }
        return result
    },
    getTenantProperties: async function (accountid: string) {
        let result: any = false
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore().getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountid)

            let tenant: any = await requestHandler.request(
                "GET",
                "/sms-mgt-api/api/" +
                    config.mgtApiVersionNext +
                    "/tenants/" +
                    tenantDomain +
                    "/properties"
            )

            result = {}
            result[accountid] = {
                unifiedSecurity: {
                    account: {},
                    privacy: {}
                },
                mobileSecurity: {
                    settings: {}
                },
                unifiedReporting: {
                    settings: {}
                }
            }
            if (tenant.hasOwnProperty("appleId")) {
                if (!result[accountid].mobileSecurity.settings.hasOwnProperty("ios")) {
                    result[accountid].mobileSecurity.settings.ios = {}
                }
                result[accountid].mobileSecurity.settings.ios.appleId = tenant.appleId
            }
            if (tenant.hasOwnProperty("preferredVPNZone")) {
                result[accountid].unifiedSecurity.account.preferredVPNZone = tenant.preferredVPNZone
            }
            if (tenant.hasOwnProperty("privacyLevel")) {
                result[accountid].unifiedSecurity.privacy.privacyLevel = tenant.privacyLevel
            }

            if (tenant.hasOwnProperty("setup")) {
                result[accountid].unifiedSecurity.account.setup = tenant.setup
            }
            if (tenant.hasOwnProperty("legalHold")) {
                result[accountid].unifiedReporting.settings.legalHold = tenant.legalHold
            }
            if (tenant.hasOwnProperty("triggerVPPAppUpdates")) {
                result[accountid].mobileSecurity.settings.triggerVPPAppUpdates =
                    tenant.triggerVPPAppUpdates
            }
            if (tenant.hasOwnProperty("depEnrollmentCode")) {
                result[accountid].mobileSecurity.settings.depEnrollmentCode =
                    tenant.depEnrollmentCode
            }

            if (tenant.hasOwnProperty("appleReEnrollment")) {
                result[accountid].mobileSecurity.settings.appleReEnrollment =
                    tenant.appleReEnrollment
            }
            if (tenant.hasOwnProperty("enterpriseEmail")) {
                result[accountid].mobileSecurity.settings.enterpriseEmail = tenant.enterpriseEmail
            }

            let flagsObject = encodingHelpers.bitMask.getFlagObjectFromDecimal(
                parseInt(tenant.flags || "0"),
                this.tenantFlagMapInfo
            )
            result[accountid].unifiedSecurity.flags = flagsObject
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            }
        }
        return result
    },

    setTenant: async function (accountid: string, value: number) {
        /* VALUE FORMAT: { "KEY":"VALUE" } */
        let result: any = false
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore().getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "PUT",
                "/sms-mgt-api/api/" + config.mgtApiVersion + "/tenants/" + tenantDomain,
                value
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            }
        }
        return result
    },
    getTenantProperty: async function (accountid: string, property: string, payload: any) {
        let result: any = false
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore().getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "GET",
                "/sms-mgt-api/api/" +
                    config.mgtApiVersionNext +
                    "/tenants/" +
                    tenantDomain +
                    "/properties/" +
                    property
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            }
        }
        return result[property]
    },

    setTenantProperty: async function (
        accountid: string,
        property: string,
        value: any,
        additionalData?: any
    ) {
        let result: any = false
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore().getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountid)
            let data: any = {}

            if (property == "flags") {
                // get all Flags
                let flagsObject =
                    useStore().state.session.accounts[accountid]?.unifiedSecurity.flags
                if (!flagsObject) {
                    throw (
                        "tenant is missing flags object in accounts[" +
                        accountid +
                        "].unifiedSecurity"
                    )
                }
                let tenantFlagObject = flagsObject

                for (let flagProperty in value) {
                    tenantFlagObject[flagProperty] = value[flagProperty]
                }
                data[property] = encodingHelpers.bitMask.getDecimalFromFlagObject(
                    tenantFlagObject,
                    unifiedSecurityQueries.tenantFlagMapInfo
                )
            } else {
                data[property] = value
            }

            if (additionalData) {
                for (let key in additionalData) {
                    data[key] = additionalData[key]
                }
            }
            result = await requestHandler.request(
                "PUT",
                "/sms-mgt-api/api/" +
                    config.mgtApiVersionNext +
                    "/tenants/" +
                    tenantDomain +
                    "/properties/" +
                    property,
                data
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            }
        }
        return result
    },

    getAuditLog: async function (accountid: string) {
        let result: any = false
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore().getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "GET",
                "/sms-mgt-api/api/" +
                    config.mgtApiVersionNext +
                    "/tenants/" +
                    tenantDomain +
                    "/auditlog"
            )
            result = { entries: apis.parseApiResponse(result) }
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            }
        }
        return result
    },

    getObjectCount: async function (accountid: string, objectType: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore().getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)

            switch (objectType) {
                case "utms":
                    result = await requestHandler.request(
                        "GET",
                        config.mgtApiUriNext +
                            "/tenants/" +
                            tenantDomain +
                            "/utms?props[]=null&select=count"
                    )
                    break
                case "licenses":
                    console.error("did not request from class")
                    break
                case "users":
                    console.error("did not request from class")
                    break
                case "roles":
                    console.error("did not request from class")
                    break
                case "notifications":
                    result = await requestHandler.request(
                        "GET",
                        config.mgtApiUriNext +
                            "/tenants/" +
                            tenantDomain +
                            "/notifications/services?select=data.count"
                    )
                    break
            }
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return result
    },

    getObjectInfos: async function (
        accountid: string,
        objectType: string,
        props?: any[],
        force: boolean = false
    ) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore().getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountid)
            let timeStamp: number = moment().unix()

            let propertiesString: string = ""

            if (force === true) {
                propertiesString += "?_=" + timeStamp
            }

            ;(props || []).forEach(function (prop: any) {
                if (propertiesString.length == 0) {
                    propertiesString += "?" + prop.property + "=" + prop.value
                } else {
                    propertiesString += "&" + prop.property + "=" + prop.value
                }
            })

            switch (objectType) {
                case "licenses":
                    console.error("did not request from class")
                    break
                case "users":
                    console.error("did not request from class")
                    break
                case "roles":
                    console.error("did not request from class")
                    break
                case "notifications":
                    result = await requestHandler.request(
                        "GET",
                        "/sms-mgt-api/api/2.0/tenants/" +
                            tenantDomain +
                            "/notifications/services" +
                            propertiesString
                    )
                    if (result.data) result = result.data
                    break
                case "images":
                    result = await requestHandler.request(
                        "GET",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/images" +
                            propertiesString
                    )
                    break
            }
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            }
        }
        return result
    },
    getObjectInfo: async function (
        accountid: string,
        objectType: string,
        objectId: string,
        property?: string,
        withProperties: boolean = false,
        customerId?: string,
        props?: any[]
    ) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore().getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountid)
            let propertiesString: string = ""
            ;(props || []).forEach(function (prop: any) {
                if (propertiesString.length == 0) {
                    propertiesString += "?" + prop.property + "=" + prop.value
                } else {
                    propertiesString += "&" + prop.property + "=" + prop.value
                }
            })
            switch (objectType) {
                case "users":
                    console.error("did not request from class")
                    break
                case "roles":
                    console.error("did not request from class")
                    break
                case "notifications":
                    result = await requestHandler.request(
                        "GET",
                        "/sms-mgt-api/api/2.0/tenants/" +
                            tenantDomain +
                            "/notifications/services/" +
                            objectId +
                            propertiesString
                    )
                    if (result.data) result = result.data
                    break
                case "images":
                    result = await requestHandler.request(
                        "GET",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/images/" +
                            objectId +
                            propertiesString
                    )
                    break
            }
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            }
        }
        return result
    },

    addObjectInfo: async function (
        accountid: string,
        objectType: string,
        object: any,
        customerId?: string
    ) {
        let result: any = false
        let results: any = []
        let updateMaskString: string = ""
        let payload: any = ""
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore().getters.hasAccountid(accountid) == false) {
                throw {
                    status: 401
                }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            switch (objectType) {
                case "users":
                    console.error("did not request from class")
                    break
                case "roles":
                    console.error("did not request from class")
                    break
                case "notifications":
                    result = await requestHandler.request(
                        "POST",
                        "/sms-mgt-api/api/2.0/tenants/" + tenantDomain + "/notifications/services/",
                        object
                    )
                    if (result.data) result = result.data
                    break
            }
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = e
        }
        return result
    },

    updateObjectInfo: async function (
        accountid: string,
        objectType: string,
        objectId: string,
        object: any,
        updateMask?: string[],
        customerId?: string
    ) {
        let result: any = false
        let results: any = []
        let updateMaskString: string = ""
        let payload: any = ""
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore().getters.hasAccountid(accountid) == false) {
                throw {
                    status: 401
                }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            switch (objectType) {
                case "users":
                    console.error("did not request from class")
                    break
                case "roles":
                    console.error("did not request from class")
                    break
                case "notifications":
                    result = await requestHandler.request(
                        "PUT",
                        "/sms-mgt-api/api/2.0/tenants/" +
                            tenantDomain +
                            "/notifications/services/" +
                            objectId,
                        object
                    )
                    if (result.data) result = result.data
                    break
            }
            result = true
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = e
        }
        return result
    },

    deleteObject: async function (
        accountid: string,
        objectType: string,
        objectId: string,
        property?: string,
        customerId?: string
    ) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore().getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            switch (objectType) {
                case "users":
                    console.error("did not request from class")
                    break
                case "roles":
                    console.error("did not request from class")
                    break
                case "notifications":
                    result = await requestHandler.request(
                        "DELETE",
                        "/sms-mgt-api/api/2.0/tenants/" +
                            tenantDomain +
                            "/notifications/services/" +
                            objectId
                    )
                    if (result.data) result = result.data
                    break
                case "images":
                    result = await requestHandler.request(
                        "DELETE",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/images/" +
                            objectId
                    )
                    break
            }
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return result
    },

    getTenantNotifications: async function (
        accountId: string,
        product:
            | "UNSPECIFIC"
            | "MOBILE_SECURITY"
            | "UNIFIED_SECURITY_REPORT"
            | "UNIFIED_SECURITY_CONSOLE" = "UNSPECIFIC"
    ) {
        let result: any = null
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore().getters.hasAccountid(accountId) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountId)
            result = await requestHandler.request(
                "GET",
                apis.getTenantApiUrl(tenantDomain, "tenantNotifications") +
                    "/tenant-notifications/" +
                    product
            )
        } catch (e: any) {
            console.error(e)
            result = e
        }
        return result
    },

    deleteTenantNotification: async function (accountId: string, objectId: string) {
        let result: any = null
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore().getters.hasAccountid(accountId) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountId)
            result = await requestHandler.request(
                "DELETE",
                "/sms-mgt-api/api/" +
                    config.mgtApiVersion +
                    "/tenants/" +
                    tenantDomain +
                    "/tenant-notifications/" +
                    objectId
            )
        } catch (e: any) {
            console.error(e)
            result = e
        }
        return result
    },

    getImage: async function (
        accountId: string,
        uuid: string,
        type: undefined | "data-uri" | "raw" | "jpg" | "jpeg" | "png"
    ) {
        let result: any = null
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore().getters.hasAccountid(accountId) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountId)
            result = await requestHandler.request(
                "GET",
                "/sms-mgt-api/api/" +
                    config.mgtApiVersion +
                    "/tenants/" +
                    tenantDomain +
                    "/images/" +
                    uuid
            )
        } catch (e: any) {
            console.error(e)
            result = e
        }
        return result
    },

    checkRSPConnect: async function (accountId: string) {
        let result: any = null
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore().getters.hasAccountid(accountId) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountId)
            result = await requestHandler.request(
                "GET",
                "/sms-mgt-api/api/" +
                    config.mgtApiVersionNext +
                    "/tenants/" +
                    tenantDomain +
                    "/users/rsp/connect"
            )
        } catch (e: any) {
            result = e
        }
        return result
    },
    importRSPUser: async function (accountId: string) {
        let result: any = null
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore().getters.hasAccountid(accountId) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountId)
            result = await requestHandler.request(
                "POST",
                "/sms-mgt-api/api/" +
                    config.mgtApiVersionNext +
                    "/tenants/" +
                    tenantDomain +
                    "/users/rsp/connect"
            )
        } catch (e: any) {
            result = e
        }
        return result
    },

    downloadCsv: async function (accountid: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore().getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            queries.misc.download(
                "/sms-mgt-api/api/2.0/tenants/" + tenantDomain + "/users?csv=1",
                "users.csv"
            )
            await timeHelpers.sleep(300)
            result = true
        } catch (e) {
            console.error(e)
            result = e
        }
        return apis.parseApiResponse(result)
    },
    deleteTenantProperty: async function (accountId: string, property: string) {
        let result: any = false
        try {
            await requestHandler.request(
                "DELETE",
                "/sms-mgt-api/api/" +
                    config.mgtApiVersionNext +
                    "/tenants/" +
                    accountId +
                    ".sms/properties/" +
                    property
            )
            result = true
        } catch (e) {
            console.error(e)
            result = e
        }
        return result
    },
    uploadCsv: async function (accountid: string, csv: any) {
        let result: any = null
        accountid = tenantHelpers.getAccountId(accountid)
        if (useStore().getters.hasAccountid(accountid) == false) {
            throw { status: 401 }
        }
        let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
        result = await requestHandler.request(
            "POST",
            "/sms-mgt-api/api/2.0/tenants/" + tenantDomain + "/users/csv",
            csv,
            "multipart/form-data"
        )
        await timeHelpers.sleep(1000)
        result = result

        return apis.parseApiResponse(result)
    },
    getAzureInfo: async function (accountId: string) {
        let result: any = null
        accountId = tenantHelpers.getAccountId(accountId)
        if (useStore().getters.hasAccountid(accountId) == false) {
            throw { status: 401 }
        }
        let tenantDomain: string = tenantHelpers.getTenantDomain(accountId)
        result = await requestHandler.request(
            "GET",
            "/sms-mgt-api/api/2.0/tenants/" + tenantDomain + "/providers/azure"
        )
        return apis.parseApiResponse(result)
    },
    getAzureTenants: async function (accountId: string) {
        let result: any = null
        accountId = tenantHelpers.getAccountId(accountId)
        if (useStore().getters.hasAccountid(accountId) == false) {
            throw { status: 401 }
        }
        let tenantDomain: string = tenantHelpers.getTenantDomain(accountId)
        result = await requestHandler.request(
            "GET",
            "/sms-mgt-api/api/2.0/tenants/" + tenantDomain + "/providers/azure/tenants"
        )
        return apis.parseApiResponse(result)
    },
    unlinkAzureTenant: async function (accountId: string, tenantId: string) {
        let result: any = null
        accountId = tenantHelpers.getAccountId(accountId)
        if (useStore().getters.hasAccountid(accountId) == false) {
            throw { status: 401 }
        }
        let tenantDomain: string = tenantHelpers.getTenantDomain(accountId)
        result = await requestHandler.request(
            "DELETE",
            "/sms-mgt-api/api/2.0/tenants/" + tenantDomain + "/providers/azure/tenants/" + tenantId
        )
        return apis.parseApiResponse(result)
    }
}
export default unifiedSecurityQueries
