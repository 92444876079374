import { T } from "@/classes/i18n"

const numberHelpers = {
    readableNumber: function (numberToConvert: number, useDots: boolean = false): string {
        if (numberToConvert < 1000) {
            return numberToConvert.toString()
        }
        if (useDots) {
            return numberToConvert.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
        // Values smaller than 1M always be doted
        if (numberToConvert < 1000000) {
            return numberToConvert.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
        const exponent = Math.floor(
            Math.log(numberToConvert) / Math.log(10) / (Math.log(1000) / Math.log(10))
        )
        const cleanNumber = numberToConvert / Math.pow(1000, exponent)
        let labels = ["k", T("Millions"), T("Billions"), T("Trillions")]
        if (Math.floor(cleanNumber) === 1) {
            labels = ["k", T("Million"), T("Billion"), T("Trillion")]
        }
        return (
            (exponent > 1 ? cleanNumber.toFixed(2) : Math.floor(cleanNumber)) +
            " " +
            labels[exponent - 1]
        )
    },

    formatBytes: function (bytes: number, decimals: number = 1) {
        if (bytes == 0) {
            return {
                value: 0,
                unit: "B"
            }
        }
        var k = 1024,
            dm = decimals <= 0 ? 0 : decimals || 2,
            sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
            fullSizes = [
                "Byte",
                "Kilobyte",
                "Megabyte",
                "Gigabyte",
                "Terabyte",
                "Petabyte",
                "Exabyte",
                "Zettabyte",
                "Yottabyte"
            ],
            i = Math.floor(Math.log(bytes) / Math.log(k))
        return {
            value: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
            unit: sizes[i],
            fullUnit: fullSizes[i]
        }
    },

    formatBytesToMb: function (bytes: number, decimals: number = 2) {
        if (bytes == 0) {
            return {
                value: 0,
                unit: "Bytes"
            }
        }
        var k = 1024,
            dm = decimals <= 0 ? 0 : decimals || 2,
            sizes = ["Bytes", "KB", "MB"],
            i = 2
        return {
            value: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
            unit: sizes[i]
        }
    },

    getRandomArbitrary: function (min: number, max: number): number {
        return Math.random() * (max - min) + min
    },

    bytesToSize: function (bytes: any) {
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
        if (bytes === 0) return "n/a"
        const i = Math.ceil(Math.floor(Math.log(bytes) / Math.log(1024)))
        if (i === 0) return `${bytes} ${sizes[i]})`
        return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
    }
}
export default numberHelpers
