import axios from "axios"

const miscQueries = {
    download: function (url: string, filename: string, mimeType?: string) {
        axios
            .get(url, { responseType: "blob" })
            .then((response: any) => {
                // Create a temporary link element
                const link = document.createElement("a")
                link.href = URL.createObjectURL(response.data)

                // Set the download attribute to the desired filename
                link.download = filename

                // Programmatically trigger the download
                link.click()

                // Clean up the temporary link
                URL.revokeObjectURL(link.href)
            })
            .catch(console.error)
    },

    nativeBrowserDownload: function (
        url: string,
        trarget: "_blank" | "_self" | "_parent" | "_top" = "_self"
    ) {
        const downloadButton = document.createElement("a")

        downloadButton.href = url
        downloadButton.target = trarget
        downloadButton.click()
    }
}
export default miscQueries
