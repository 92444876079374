import { vueApp } from "@/app"
import { T } from "@/classes/i18n"
import getterHelpers from "@/helpers/helpers.getters"
import { MutationTypes } from "@/store/vuex.store"
import { nextTick } from "vue"
import inputManagedPropertyConfiguratorComponent from "./input-managed-property-configurator-async"

const inputManagedConfigurationComponent = {
    name: "input-managed-configuration",
    template: `
      <div>
          <a class="btn" v-on:click="configureProperties()" :disabled="disabled || null">
              <i class="fal fa-cog"></i>&nbsp;&nbsp;{{ T('Manage configuration') }}
          </a>
      </div>
          `,
    data: function () {
        return {}
    },
    props: {
        modelValue: {
            required: true,
            default: null
        },
        field: {
            required: true
        },
        disabled: {
            required: false,
            default: false
        }
    },
    methods: {
        T: T,
        configureProperties: function (this: any) {
            let thisComponent: any = this

            let newModal: any = {
                id: "managedConfiguration",
                abortable: true,
                content: {
                    title: {
                        text: T("Managed configuration")
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component: "input-managed-property-configurator"
                    }
                },
                onAbort: function () {},
                buttons: [
                    {
                        text: T("Cancel"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "left",
                        loading: false
                    },
                    {
                        text: T("Save"),
                        icon: "fal fa-fw fa-save",
                        onClick: async function (modal: any) {
                            thisComponent.field.fields = JSON.parse(
                                JSON.stringify(modal.$refs.modalComponent.componentValue)
                            )
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "right",
                        disabled: false,
                        loading: false
                    }
                ]
            }

            vueApp.$refs.modals.addModal(newModal)
            nextTick(function () {
                if (thisComponent.field.fields.length > 0) {
                    vueApp.$refs.modals.$refs.modal.$refs.modalComponent.componentValue =
                        JSON.parse(JSON.stringify(thisComponent.field.fields))
                }
            })
        },
        generateFieldsFromValue: function (this: any, jsonValue: any) {
            let fields: any = []

            for (let property in jsonValue) {
                switch (typeof jsonValue[property]) {
                    case "string":
                        fields.push({
                            title: property,
                            id: property,
                            property: property,
                            placeholder: property,
                            desc: "Add some text",
                            visible: true,
                            type: "text",
                            errors: [],
                            value: jsonValue[property]
                        })
                        break
                    case "number":
                        fields.push({
                            title: property,
                            id: property,
                            property: property,
                            placeholder: property,
                            desc: "Enter a number",
                            visible: true,
                            type: "number",
                            errors: [],
                            value: jsonValue[property]
                        })
                        break
                    case "object":
                        if (jsonValue[property].length) {
                            if (typeof jsonValue[property][0] == "string") {
                                let thisOptions: any = []
                                for (let index in jsonValue[property]) {
                                    thisOptions.push({
                                        id: jsonValue[property][index],
                                        text: jsonValue[property][index]
                                    })
                                }
                                fields.push({
                                    title: property,
                                    id: property,
                                    property: property,
                                    placeholder: property,
                                    desc: "Enter some text",
                                    visible: true,
                                    multiple: true,
                                    type: "select2",
                                    options: thisOptions,
                                    select2Options: "select2Tags",
                                    errors: [],
                                    value: jsonValue[property]
                                })
                            } else if (typeof jsonValue[property][0] == "object") {
                                let thisTemplate = JSON.parse(
                                    JSON.stringify(
                                        this.generateFieldsFromValue(jsonValue[property][0])
                                    )
                                )
                                for (let tmplFieldIndex in thisTemplate) {
                                    if (thisTemplate[tmplFieldIndex].value) {
                                        delete thisTemplate[tmplFieldIndex].value
                                    }
                                }
                                let thisFields = []
                                for (let fieldIndex in jsonValue[property]) {
                                    thisFields.push({
                                        type: "loopentry",
                                        minified: false,
                                        title: "Object",
                                        fields: this.generateFieldsFromValue(
                                            jsonValue[property][fieldIndex]
                                        )
                                    })
                                }
                                fields.push({
                                    title: property,
                                    id: property,
                                    property: property,
                                    placeholder: property,
                                    desc: "Add an object",
                                    visible: true,
                                    type: "loop",
                                    btnIcon: "fal fa-plus",
                                    btnText: "Add an object",
                                    loopFieldTitle: "Object",
                                    errors: [],
                                    template: thisTemplate,
                                    fields: thisFields
                                })
                            }
                        }
                        break
                }
            }
            return JSON.parse(JSON.stringify(fields))
        }
    },
    created: function (this: any) {
        if (this.field.value) {
            let generatedFields = this.generateFieldsFromValue(
                JSON.parse(JSON.stringify(this.field.value))
            )
            this.field.fields = generatedFields
        }
    },
    mounted: function (this: any) {},
    watch: {},
    destroyed: function () {},
    components: {
        "input-managed-property-configurator": inputManagedPropertyConfiguratorComponent
    }
}
export default inputManagedConfigurationComponent
