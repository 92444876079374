import config from "@/classes/config"
import products from "@/classes/objectTypes"
import type { User } from "@/classes/objectTypes/unifiedSecurity/users/users"
import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"
import { useGlobalMixin } from "@/mixins/mixins.global"
import queries from "@/queries/queries"
import { ActionTypes, useStore } from "@/store/vuex.store"
import { debounce } from "throttle-debounce"
import jsonHelpers from "../../helpers/helpers.json"
import itemlistItemComponent from "../components/itemlist-item.vue"
import loaderComponent from "../components/loader.vue"
import inputPasswordComponent from "../inputtypes/input-password-async"
import inputPin from "../inputtypes/input-pin.vue"
import inputVueSelectVue from "../inputtypes/input-vue-select.vue"

const deanonymizeDeviceDialogComponent = {
    mixins: [useGlobalMixin()],
    name: "deanonymize-device-dialog",
    template: `
        <div>
            <template v-if="initialized">
                <div>
                    <template v-if="device == undefined">
                        <div class="row padding-xs-y" v-if="error">
                            <p class="label bg-red padding-xs"><i class="fal fa-fw fa-exclamation-triangle"></i> <span>{{ error }}</span></p>
                        </div>
                        <div class="row padding-xs-b padding-xs-t-0 padding-xs-x-0 form-group form-entry-row-search border-bottom">
                            <div class="first col-xs-24 col-lg-5 col-xl-4">
                                <label for="input-username" :title="T('Username')" class="control-label inputname">{{ T('Username') }}</label>
                            </div>
                            <div class="input col-xs-24 col-lg-19 col-xl-20">
                                <input-vue-select
                                    id="input-username"
                                    :selectOptions="userOptions"
                                    v-model="username"
                                    :placeholder="T('Select user')"
                                />
                            </div>
                        </div>
                        <div class="row padding-xs-t padding-xs-b-0 padding-xs-x-0 form-group form-entry-row-search border-bottom">
                            <div class="first col-xs-24 col-lg-5 col-xl-4">
                                <label for="input-password" :title="T('Password')" class="control-label inputname">{{ T('Password') }}</label>
                            </div>
                            <div class="input col-xs-24 col-lg-19 col-xl-20">
                                <input-password autocomplete="false" id="input-password" v-model="password" v-on:keyup.enter="submit()"/>
                            </div>
                        </div>
                        <div class="row padding-xs-t padding-xs-b-0 padding-xs-x-0 form-group form-entry-row-search border-bottom">
                            <div class="first col-xs-24 col-lg-5 col-xl-4">
                                <label for="input-totp" :title="T('TOTP')" class="control-label inputname">{{ T('TOTP') }}</label>
                            </div>
                            <div class="input col-xs-24 col-lg-19 col-xl-20">
                                <input-pin :numbers-only="true" :pin-length="6" autocomplete="false" id="input-totp" v-model="totp" v-on:keyup.enter="submit()"/>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <itemlist-item
                            :item="device"
                            :index="0"
                            :showInfo="true"
                            :ref="'device'"
                            :altObjectType="type"
                            :showMenu="false"
                            :editable="false"
                            :clickable="false"
                            :refreshCounterProp="refreshCounter"
                            altProductType="mobileSecurity"
                            altColor="green"
                            class="col-lg-24 col-md-24 col-xl-24 col-print-24"
                        />
                    </template>
                </div>
            </template>
            <template v-else>
                <div class="text-center padding-xs-y-8 col-xs">
                    <div class="text-size-3"><loader class="text-size-2 color-red" /></div>
                    <span v-if="loaderInfo" style="opacity: 0.8;">
                        {{ T(loaderInfo) }}
                    </span>
                </div>
            </template>
        </div>
    `,
    data: () => {
        return {
            initialized: false,
            loaderInfo: "",
            username: "",
            userOptions: [],
            password: "",
            totp: "",
            deanonymized: false,
            error: undefined,
            device: undefined,
            type: "devices",
            refreshCounter: 0
        }
    },
    computed: {},
    props: {
        properties: {
            required: true,
            default: () => {
                return {
                    accountid: undefined,
                    objectType: undefined,
                    objectId: undefined,
                    isEnterprise: undefined
                }
            }
        }
    },
    methods: {
        init: async function (this: any) {
            let userOptions: object[] = []
            if (config.canUseNewObjectType("users")) {
                await products.unifiedSecurity.users.queries.getObjectsFromApi(this.activeAccountId)
            } else {
                await useStore().dispatch(ActionTypes.getObjectInfos, {
                    accountId: this.properties.accountid,
                    objectTypes: ["users"]
                })
            }
            let users: User[] = []
            if (config.canUseNewObjectType("users")) {
                users =
                    products.unifiedSecurity.users
                        .useStore?.()
                        ?.getObjectStoreObjects(this.activeAccountId) || []
            } else {
                users =
                    jsonHelpers.getObjectProperty(
                        this,
                        "$store.state.session.accounts." +
                            this.properties.accountid +
                            ".unifiedSecurity.users.items"
                    ) || []
            }
            users.forEach((user: any) => {
                userOptions.push({
                    id: user.username,
                    text: user.username
                })
            })
            userOptions.sort((a: any, b: any) => {
                let aText = a.text.toLowerCase()
                let bText = b.text.toLowerCase()

                if (aText < bText) return -1
                else if (aText > bText) return 1
                else return 0
            })
            this.userOptions = userOptions
            this.initialized = true
        },
        submit: function (this: any) {
            getterHelpers
                .useStore()
                .getters.getActiveModal(this.activeAccountId)
                .buttons[1].onClick()
        },
        deanonymize: async function (this: any) {
            let result: any = false

            try {
                result = await queries.mobileSecurity.getDeviceInfoFromDeviceHistory(
                    this.properties.accountid,
                    this.properties.objectId,
                    this.username,
                    this.password,
                    this.totp,
                    this.properties.isEnterprise
                )
                if (result && this.properties.isEnterprise) {
                    result = tenantHelpers.convertObjectForStore(
                        this.properties.accountid,
                        "enterpriseDevices",
                        result
                    )
                }
                this.type = "devices"
            } catch (e: any) {
                result = false
            }
            if (!result) {
                try {
                    result = await queries.mobileSecurity.getVPNInfoFromVPNHistory(
                        this.properties.accountid,
                        this.properties.objectId,
                        this.username,
                        this.password,
                        this.totp
                    )
                    this.type = "vpns"
                } catch (e: any) {
                    result = false
                }
            }
            if (!result) {
                result = false
            } else {
                this.device = result
            }
            return result
        }
    },
    created: async function (this: any) {
        this.init()
        this.refresh = debounce(1000, this.refreshFunc)
    },
    mounted: function (this: any) {},
    watch: {},
    beforeUnmount: function (this: any) {
        let thisComponent: any = this
    },
    components: {
        loader: loaderComponent,
        "input-password": inputPasswordComponent,
        "itemlist-item": itemlistItemComponent,
        "input-vue-select": inputVueSelectVue,
        "input-pin": inputPin
    }
}
export default deanonymizeDeviceDialogComponent
