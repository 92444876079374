import apis from "@/classes/apis"
import devLog from "@/classes/log"
import timeHelpers from "@/helpers/helpers.time"
import ObjectType, {
    type AccountId,
    type AddObjectTypeObject,
    type ObjectTypePayload
} from "../../objectType"
import vppApi from "./vpp"

export interface VppAssignment {
    assignmentId_custom: string
    adamId: string
    clientUserId?: string
    pricingParam: "STDQ" | "PLUS"
    serialNumber?: string
}

class VppAssignments extends ObjectType<VppAssignment> {
    constructor(payload: ObjectTypePayload<VppAssignment>) {
        super(payload)
        const thisClass = this

        thisClass.queries.getObjectsFromApi = async (
            accountId,
            customerId?,
            props?,
            updateStore = true,
            removeOldObjects = true
        ) => {
            let store = this.useStore?.().getObjectStore(accountId)
            let timestamp = store?.objectsTimestamp || 0
            let isRequestable = (function () {
                return (
                    store?.gettingObjects == false && timeHelpers.getUnixTimeStamp() - timestamp > 5
                )
            })()
            if ((store && isRequestable) || store == undefined) {
                if (store) {
                    store.gettingObjects = true
                    store.objectsTimestamp = timeHelpers.getUnixTimeStamp()
                }
                const propertiesString: string = props ? thisClass.getPropertiesString(props) : ""
                let result: VppAssignment[] | Error
                try {
                    let response = await vppApi.getAllVppAssignments(accountId)
                    response = apis.parseApiResponse(response)
                    if (Array.isArray(response)) {
                        result = response as VppAssignment[]
                    } else {
                        throw new Error("Error getting Objects")
                    }
                    if (updateStore) {
                        if (removeOldObjects == true) {
                            thisClass.useStore?.().clearObjectTypeObjectsFromStore(accountId)
                        }
                        thisClass.useStore?.().setObjectTypeObjects(accountId, result)
                    }
                    if (store) {
                        store.gettingObjects = false
                    }
                    return result
                } catch (e: any) {
                    if (store) {
                        store.gettingObjects = false
                    }
                    devLog.log("ObjectType", e.message, e, "error")
                    throw e as Error
                }
            } else {
                return this.useStore?.().getObjectStoreObjects(accountId) || []
            }
        }
    }
    /**
     * Converts Object for ObjectTypeStore
     */
    convertObjectForStore(accountId: AccountId, objectBase: AddObjectTypeObject<VppAssignment>) {
        objectBase.assignmentId_custom =
            objectBase?.adamId +
            "-" +
            objectBase?.pricingParam +
            "-" +
            (objectBase?.clientUserId
                ? objectBase?.clientUserId
                : objectBase?.serialNumber
                  ? objectBase.serialNumber
                  : "missingInfo")
    }
}

const vppAssignments = new VppAssignments({
    productType: "mobileSecurity",
    slug: "vppassignments",
    objectType: "vppAssignments",
    hasStore: true,
    appearance: {
        iconClass: "fab fa-apple",
        text: {
            plural: "VPP Assignments",
            title: "VPP Assignments",
            sidebarName: "VPP Assignments",
            singular: "VPP Assignment"
        },
        color: "red",
        showInSidebar: true,
        showOnDashboard: true
    },
    objectTypeInfo: {
        primaryKeyProperty: {
            property: "assignmentId_custom",
            pathToPrimaryProperty: undefined
        },
        nameProperty: {
            primary: "adamId",
            pathToPrimaryProperty: "",
            secondary: undefined,
            pathToSecondaryProperty: undefined
        }
    },
    apiInfo: {
        url: "/sms-mgt-api/api/2.0",
        getCountGETProperties: "?props[]=null&select=data.currentPageSize",
        // GET
        getObjectListResponseProperty: "assignments",
        getObjectListMethod: "GET",
        getObjectListPath: "/tenants/{tenantDomain}/apple/vpp/v2/assignments"
    }
})
export default vppAssignments
