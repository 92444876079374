import arrayHelpers from "@/helpers/helpers.arrays"
import encodingHelpers from "@/helpers/helpers.encoding"
import numberHelpers from "@/helpers/helpers.numbers"
import permissionsHelpers from "@/helpers/helpers.permissions"
import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"
import router from "@/router/router"
import Button from "@/templates/components/button/button"
import config from "../config"
import {
    type GenericObject,
    GenericObjectStore,
    type GetPropertiesObjectList,
    type ItemlistDetail,
    type Label,
    type MenuEntry,
    type shemaErrors
} from "../genericObjectStore"
import { T } from "../i18n"
import devLog from "../log"
import type { Username } from "./users"

export type Rolename = string
export interface Role extends GenericObject<Role> {
    rolename: Rolename
    tenantDomain?: string
    users?: Array<Username>
    devices?: Array<string>
    permissionScopes?: Array<string>
    comment?: string
}

export default class Roles extends GenericObjectStore<Role> {
    constructor() {
        super()
        this.settings.primaryKeyProperty = this.settings.nameProperty.primary = "rolename"
        this.settings.productType = "unifiedSecurity"
        this.settings.objectType = "roles"
        this.settings.appearance.iconClass = "fal fa-users"
        this.settings.appearance.text.singular = "Role"
        this.settings.appearance.text.plural = "Roles"
        this.settings.appearance.text.title = "Roles"
        this.settings.apiInfo.listPath = "/tenants/{tenantDomain}/roles"
        this.settings.apiInfo.objectListPropertyInResponse = "roles"

        this.itemlist.getToolbar = (accountId, itemlistComponent) => {
            let toolBarEntries = []
            toolBarEntries.push({
                icon: "fal fa-plus",
                title: T("Add role"),
                link: "#add-tenant-" + accountId + ".sms-role",
                id: "rolesButtonAdd",
                vIf: false
            })
            return toolBarEntries
        }

        this.itemlist.isDisabled = (accountId, item): boolean => {
            return false
        }
        this.itemlist.isClickable = (accountId, item): boolean => {
            return true
        }
        this.itemlist.onClick = (accountId: string, item) => {
            let objectId = item[this.settings.primaryKeyProperty] as string
            router.navigate(
                "edit-tenant-" + tenantHelpers.getTenantDomain(accountId) + "-role-" + objectId
            )
        }
        this.itemlist.hasCheckBox = true
        this.itemlist.getTitle = (item) => {
            return {
                title: item.rolename
            }
        }
        this.itemlist.getStatus = (accountId: string, item) => {
            return undefined
        }
        this.itemlist.getMenuEntries = (accountId: string, item) => {
            let menuEntries: MenuEntry[] = []
            if (item) {
                let objectId = item[this.settings.primaryKeyProperty] as string
                menuEntries.push(
                    new Button({
                        text: T("Edit"),
                        title: T("Edit"),
                        icon: "fal fa-edit",
                        onClick: () => {
                            router.navigate(
                                "edit-tenant-" +
                                    tenantHelpers.getTenantDomain(accountId) +
                                    "-role-" +
                                    objectId
                            )
                        }
                    })
                )
                if (["admin", "auditor"].indexOf(item?.rolename) == -1) {
                    menuEntries.push(
                        new Button({
                            text: T("Delete"),
                            title: T("Delete"),
                            icon: "fal fa-trash",
                            onClick: () => {
                                this.dialogs.getDeleteObjectDialog(accountId, item)
                            }
                        })
                    )
                }
            }

            return menuEntries
        }
        this.itemlist.getLabels = (accountId: string, item) => {
            let result: Label[] = []
            return result
        }

        this.itemlist.getDetails = (accountId: string, item?) => {
            let result: ItemlistDetail[] = []
            let thisPermissionsArray: any = []
            item?.permissionScopes?.forEach((permissionScope: string) => {
                if (permissionScope.indexOf("usc:administration:") != -1) {
                    thisPermissionsArray.push("usc:administration:*")
                } else {
                    thisPermissionsArray.push(permissionScope)
                }
            })
            // Strip duplicate permissionScopes
            thisPermissionsArray = thisPermissionsArray.filter(arrayHelpers.onlyUniqueFilter)
            thisPermissionsArray = thisPermissionsArray.map((permissionScope: string) => {
                const labelText = T(permissionsHelpers.getPermissionLabelFromScope(permissionScope))
                const permissionScopeClass = (function () {
                    let htmlClass: string = ""
                    if (permissionScope == "usc:administration") {
                        htmlClass = "bg-red color-white"
                    }
                    if (permissionScope == "usc:administration:*") {
                        htmlClass = "bg-yellow color-white"
                    }
                    if (permissionScope == "usc:monitoring") {
                        htmlClass = "bg-blue color-white"
                    }
                    return htmlClass
                })()
                return <Label>{
                    id: permissionScope,
                    text: labelText,
                    title: labelText,
                    onClick: undefined,
                    displayType: "label",
                    class: permissionScopeClass
                }
            })
            const thisUsersArray = item?.users?.map((user: string) => {
                return {
                    id: user,
                    text: user,
                    title: user,
                    onClick: () => {
                        router.navigate(
                            "#edit-tenant-" +
                                tenantHelpers.getTenantDomain(accountId) +
                                "-user-" +
                                user
                        )
                    },
                    displayType: "label"
                }
            })
            result = [
                {
                    iconClass: "fal fa-fw fa-user",
                    title: T("User"),
                    key: T("User"),
                    [item ? "labels" : "content"]: item
                        ? thisUsersArray
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-key",
                    title: T("Permissions"),
                    key: T("Permissions"),
                    [item ? "labels" : "content"]: item
                        ? thisPermissionsArray
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                }
            ]
            return result
        }
        this.itemlist.sortingOptions = [
            {
                id: "rolename",
                text: "Name"
            }
        ]
    }
    async getCountFromApi(accountId: string, updateLocalStore: boolean = true) {
        let result: number | Error = 0
        try {
            const response = await requestHandler.request(
                "GET",
                "/sms-mgt-api/api/" +
                    config.mgtApiVersion +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/roles?props[]=null&select=count"
            )
            if (typeof response === "number") {
                result = response
                if (updateLocalStore) {
                    this.setCount(accountId, response)
                }
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error getting count for roles")
            }
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
            } else {
                result = new Error("Error getting count for roles")
            }
            devLog.log("RolesStoreClass", result.message, result, "error")
        }
        return result
    }

    async getObjectFromApi(
        accountId: string,
        objectId: string,
        props?: GetPropertiesObjectList,
        updateLocalStore: boolean = true
    ) {
        let result: Role | Error
        try {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            const response = await requestHandler.request(
                "GET",
                "/sms-mgt-api/api/" +
                    config.mgtApiVersion +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/roles/" +
                    encodingHelpers.encodeURI(objectId) +
                    propertiesString
            )
            if (response.rolename) {
                result = response as Role
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, result) // Add Role to store
                }
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error getting role")
            }
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
            } else {
                result = new Error("Error getting role")
            }

            devLog.log("RolesStoreClass", result.message, result, "error")
        }
        return result
    }

    async addObjectToApi(accountId: string, object: Role, updateLocalStore: boolean = true) {
        let result: Role | Error | shemaErrors
        try {
            const response = await requestHandler.request(
                "POST",
                "/sms-mgt-api/api/" +
                    config.mgtApiVersion +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/roles/",
                object
            )
            if (response.rolename) {
                result = object
                result.rolename = response.rolename
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, object) // Add Role to store
                }
            } else if (response.errors) {
                throw response.errors
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error adding role")
            }
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
                devLog.log("RolesStoreClass", result.message, result, "error")
            } else {
                result = e as shemaErrors
                devLog.log("RolesStoreClass", "Error adding role", result, "error")
            }
        }
        return result
    }

    async updateObjectFromApi(
        accountId: string,
        objectId: Role["rolename"],
        object: Role,
        updateLocalStore: boolean = true
    ) {
        let result: Role | Error | shemaErrors
        try {
            const response = await requestHandler.request(
                "PUT",
                "/sms-mgt-api/api/" +
                    config.mgtApiVersion +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/roles/" +
                    encodingHelpers.encodeURI(object.rolename),
                object
            )
            if (response.rolename) {
                result = object
                result.rolename = response.rolename
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, object) // Add Role to store
                }
            } else if (response.errors) {
                throw response.errors
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error updating role")
            }
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
                devLog.log("RolesStoreClass", result.message, result, "error")
            } else {
                result = e as shemaErrors
                devLog.log("RolesStoreClass", "Error updating role", result, "error")
            }
        }
        return result
    }

    async deleteObjectFromApi(
        accountId: string,
        objectId: string,
        updateLocalStore: boolean = true
    ) {
        let result: true | Error
        try {
            const response = await requestHandler.request(
                "DELETE",
                "/sms-mgt-api/api/" +
                    config.mgtApiVersion +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/roles/" +
                    encodingHelpers.encodeURI(objectId)
            )
            if (response.error) {
                throw new Error(response.error)
            } else {
                result = true
                if (updateLocalStore) {
                    this.removeObjectFromStore(accountId, objectId) // Remove role from store
                }
            }
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
            } else {
                result = new Error("Error deleting role")
            }
            devLog.log("RolesStoreClass", result.message, result, "error")
        }
        return result
    }
}
