<script setup lang="ts">
import { T } from "@/classes/i18n"
import { useStore } from "@/store/vuex.store"
import Button from "@/templates/components/button/button"
import { computed, onMounted, ref, watch } from "vue"
import buttonComponent from "./button/button.vue"
import Loader from "./loader.vue"

import tenantHelpers from "@/helpers/helpers.tenants"
import Label from "@/templates/components/label/label"
import labelComponent from "@/templates/components/label/label.vue"
import InputNumber from "../inputtypes/input-number.vue"
import inputVueSelect from "../inputtypes/input-vue-select.vue"
import Tooltip from "./tooltip.vue"
export type TableEntryText = string
export type TableEntryLink = {
    text: string
    onClick?: Function
    href?: string
    target?: "_blank" | "_self"
}

export type TableEntryButtons = Button[]
export type TableEntryStatus = {
    tooltip: string
    color: "grey" | "green" | "yellow" | "orange" | "red"
    icon?: string
    statusText?: string
    htmlTooltip?: boolean
}

export type TableEntryInputOptions =
    | {
          type: "select"
          disabled?: (entry: any, parentEntry?: any) => boolean
          available?: (entry: any, parentEntry?: any) => boolean
          saveValueToProperty: string
          select?: {
              tags: boolean
              multiple: boolean
              options:
                  | Array<selectOption>
                  | ((entry: any, parentEntry?: any) => Array<selectOption>)
              loading: boolean
          }
      }
    | {
          type: "text"
          disabled?: (entry: any, parentEntry?: any) => boolean
          available?: (entry: any, parentEntry?: any) => boolean
          saveValueToProperty: string
          text: {}
      }

export type TableEntryIcons = {
    class: string
    title?: string
    htmlTooltip?: boolean
}[]

export type TableSubContentOptions = "subText" | "subLabels"

export type TableSubContent = {
    displayOrder: TableSubContentOptions[]
    marginLeft?: number
    subText?: string | ((entry: any, parentEntry?: any) => string)
    subLabels?: Label[] | ((entry: any, parentEntry?: any) => Label[])
}

export type TableEntryInfo =
    | {
          property: string
          width?: number
          text: string
          title?: string | ((entry: any, parentEntry?: any) => string)
          htmlTooltip?: boolean
          displayType: "text"
          subContent?: TableSubContent
          rowClassList?: string[] | ((entry: any, parentEntry?: any) => string[])
          tdId?: (entry: any, parentEntry?: any) => string
          getValue: (entry: any, parentEntry?: any) => TableEntryText
          getSortValue?: (entry: any, parentEntry?: any) => string
          dontSortInRequest?: true
          tdClass?: string | ((entry: any, parentEntry?: any) => string)
          thAlign?: "left" | "right" | "center"
          iconsBefore?: TableEntryIcons | ((entry: any, parentEntry?: any) => TableEntryIcons)
          iconsAfter?: TableEntryIcons | ((entry: any, parentEntry?: any) => TableEntryIcons)
          labelsAfter?:
              | Label[]
              | ((entry: any, parentEntry?: any) => Label[])
              | ((entry: any, parentEntry?: any) => Label[])
          alignLabelsAfter?: "right" | "left"
          smallTextAfter?: (entry: any, parentEntry?: any) => TableEntryText
          flagIcon?: (entry: any, parentEntry?: any) => string
      }
    | {
          property: string
          width?: number
          text: string
          displayType: "link"
          rowClassList?: string[] | ((entry: any, parentEntry?: any) => string[])
          tdId?: (entry: any, parentEntry?: any) => string
          getValue: (entry: any, parentEntry?: any) => TableEntryLink
          getSortValue?: (entry: any, parentEntry?: any) => string
          iconsBefore?: TableEntryIcons | ((entry: any, parentEntry?: any) => TableEntryIcons)
          sortable?: false
          dontSortInRequest?: true
          tdClass?: string | ((entry: any, parentEntry?: any) => string)
          thAlign?: "left" | "right" | "center"
      }
    | {
          property: string
          width?: number
          text: string
          displayType: "labels"
          rowClassList?: string[] | ((entry: any, parentEntry?: any) => string[])
          tdId?: (entry: any, parentEntry?: any) => string
          getValue: (entry: any, parentEntry?: any) => Label[]
          getSortValue?: (entry: any, parentEntry?: any) => string
          dontSortInRequest?: true
          tdClass?: string | ((entry: any, parentEntry?: any) => string)
          thAlign?: "left" | "right" | "center"
      }
    | {
          property: string
          width?: number
          text: string
          displayType: "status"
          statusText?: string
          rowClassList?: string[] | ((entry: any, parentEntry?: any) => string[])
          tdId?: (entry: any, parentEntry?: any) => string
          getValue: (entry: any, parentEntry?: any) => TableEntryStatus | undefined
          getSortValue?: (entry: any, parentEntry?: any) => string
          dontSortInRequest?: true
          tdClass?: string | ((entry: any, parentEntry?: any) => string)
          thAlign?: "left" | "right" | "center"
      }
    | {
          property: string
          width?: number
          text: string
          displayType: "buttons"
          replaceButtonWithStatus?: (entry: any) => boolean
          rowClassList?: string[] | ((entry: any, parentEntry?: any) => string[])
          tdId?: (entry: any, parentEntry?: any) => string
          getValue: (entry: any, parentEntry?: any) => TableEntryButtons | TableEntryStatus
          getSortValue?: (entry: any, parentEntry?: any) => string
          dontSortInRequest?: true
          tdClass?: string | ((entry: any, parentEntry?: any) => string)
          thAlign?: "left" | "right" | "center"
      }
    | {
          property: string
          width?: number
          text: string
          displayType: "input"
          rowClassList?: string[] | ((entry: any, parentEntry?: any) => string[])
          tdId?: (entry: any, parentEntry?: any) => string
          inputOptions: TableEntryInputOptions
          getValue: (entry: any, parentEntry?: any) => any
          getSortValue?: (entry: any, parentEntry?: any) => string
          dontSortInRequest?: true
          tdClass?: string | ((entry: any, parentEntry?: any) => string)
          thAlign?: "left" | "right" | "center"
      }
    | {
          displayType: "subTable"
          property?: string
          text?: string
          draggableRows?: true
          dontSortInRequest?: true
          entries: Array<TableEntryInfo> | ((entry: any) => Array<TableEntryInfo>)
          getObjects: (entry: any, parentEntry?: any) => any[]
          getSortValue?: (entry: any, parentEntry?: any) => string
          isOpened: (entry: any, parentEntry?: any) => boolean
          tdClass?: string | ((entry: any, parentEntry?: any) => string)
          thAlign?: "left" | "right" | "center"
      }

const darkMode = computed(() => {
    return useStore()?.state.browserSettings.darkmode == "1"
})

const acceptedSearchDisplayTypes = ["text", "link", "labels", "status", "buttons"]
let updateObjectListCounter = ref(0)

// Props
const props = withDefaults(
    defineProps<{
        dontCopyData?: boolean
        parentEntry?: any
        isLoading?: boolean
        subTablePaddingLeft?: number
        maxHeight?: number
        minHeight?: number
        selectableColumns: TableEntryInfo[]
        selectedColumnsDefault?: string[]
        usePaging?: boolean
        rowsSelectable?: boolean
        objectList?: any[]
        isSearchable?: boolean
        isFilterable?: boolean
        hasOptions?: boolean
        buttons?: Button[]
        rowValueGetter?: (entry: any, parentEntry?: any) => number | string
        request?: (
            sortingOrder: string,
            sortBy: string,
            searchString: string,
            responseLimit: number,
            offset: number,
            searchBy: string
        ) => Promise<{
            total: number
            result: Array<any>
        }>
        initialSortDirection?: "ASC" | "DESC"
        initialSortProperty?: string
    }>(),
    {
        maxHeight: 0,
        subTablePaddingLeft: 0,
        selectableColumns: () => [],
        usePaging: true,
        rowsSelectable: false,
        isSearchable: true,
        isFilterable: true,
        hasOptions: true
    }
)

const computedMaxHeight = computed(() => {
    return props.maxHeight
})
const computedMinHeight = computed(() => {
    return props.minHeight
})

// COLLUMNS
const thisSelectableColums = ref(<TableEntryInfo[]>[])
const selectedColumns = ref(<string[]>[])
const selection = ref(<number[]>[])

const orderedColumns = computed(() => {
    let newOrder: string[] = []
    if (props.rowsSelectable) {
        newOrder.push("#selector")
    }
    thisSelectableColums.value.forEach((colInfo) => {
        if (colInfo.property && selectedColumns.value.indexOf(colInfo.property) != -1) {
            newOrder.push(colInfo.property)
        }
    })
    return newOrder
})
const selectedColumnsWithInfo = computed(() => {
    let result = orderedColumns.value
        .map((fieldProperty) => {
            if (fieldProperty == "#selector") {
                return "#selector"
            }
            return thisSelectableColums.value.find((field) => {
                return field.property == fieldProperty
            }) as TableEntryInfo
        })
        .filter((field) => {
            return field != undefined
        })
    return result
})
const getColumnInfoByProperty = (property: string) => {
    return thisSelectableColums.value.find((columnInfo) => {
        return columnInfo.property == property
    })
}
const getColKey = (column: TableEntryInfo | string, i: number) => {
    return "column-" + (typeof column == "string" ? column : column.property)
}

// ROWS
const getRowClasses = (entry: any, parentEntry?: any): string[] => {
    let result: string[] = []
    thisSelectableColums.value.forEach((colInfo) => {
        if (colInfo.displayType != "subTable") {
            if (typeof colInfo.rowClassList == "function") {
                result = result.concat(colInfo.rowClassList(entry, parentEntry))
            } else if (
                typeof colInfo.rowClassList == "object" &&
                Array.isArray(colInfo.rowClassList)
            ) {
                result = result.concat(colInfo.rowClassList)
            }
        }
    })
    return result
}
const getRowAsString = (entry: any) => {
    return selectedColumnsWithInfo.value
        .filter((colInfo) => {
            return colInfo != "#selector" && colInfo?.displayType != "input"
        })
        .map((colInfo) => {
            return getFieldValueAsString(colInfo, entry)
        })
        .join(" ")
}
const getRowKey = (i: number) => {
    if (computedObjectList.value[i + virtualScrolling.value.fromIndex]) {
        let objectFirstProperty = Object.keys(
            computedObjectList.value[i + virtualScrolling.value.fromIndex]
        )[0]
        return (
            "row-" +
            i +
            "-" +
            String(
                computedObjectList.value[i + virtualScrolling.value.fromIndex][objectFirstProperty]
            )
        )
    } else {
        return "row-" + i + "-unknown"
    }
}

// SEARCH & SORTING
const search = ref("")
const searchProperty = ref("")
const sorting = ref({
    property: <string | undefined>undefined,
    direction: <"ASC" | "DESC">"ASC"
})
const setSortBy = (property: string) => {
    if (tablewrapper.value) {
        tablewrapper.value.scrollTop = 0
    }
    if (sorting.value.property == property) {
        sorting.value.direction == "ASC"
            ? (sorting.value.direction = "DESC")
            : (sorting.value.direction = "ASC")
    } else {
        sorting.value.property = property
        sorting.value.direction = "ASC"
    }
}
const searchFilter = (entry: any) => {
    let infos = ""
    let searchFor: string = search.value.toLowerCase()
    let searchForValues = searchFor.split(" ")
    let searchBy = searchProperty.value
    let colInfo = searchBy.length ? getColumnInfoByProperty(searchBy) : ""
    let searchResults = (function () {
        return searchForValues.map(() => {
            return false
        })
    })()
    if (searchBy.length && colInfo && typeof colInfo != "string") {
        infos = getFieldValueAsString(colInfo, entry, "search") as string
    } else {
        infos = getRowAsString(entry)
    }
    searchForValues.forEach((searchForValue, index) => {
        if (infos?.indexOf(searchForValue) != -1) {
            searchResults[index] = true
        }
    })
    return searchResults.indexOf(false) == -1
}

// FIELDS
const getFieldValueAsString = (
    colInfo: TableEntryInfo | "#selector",
    entry: any,
    type: "sort" | "search" = "search"
) => {
    // bail out not defined displaytypes
    if (type == "sort" && colInfo != "#selector" && colInfo.getSortValue) {
        return colInfo.getSortValue(entry, props.parentEntry)
    } else if (
        typeof colInfo != "string" &&
        acceptedSearchDisplayTypes.indexOf(colInfo.displayType) != -1 &&
        colInfo.displayType != "subTable" &&
        colInfo.getValue
    ) {
        let thisValue = colInfo.getValue(entry, props.parentEntry)

        if (colInfo.displayType == "text") {
            // return string
            return String(thisValue).toLowerCase()
        }
        if (colInfo.displayType == "link") {
            // return link text string
            return (<TableEntryLink>thisValue).text.toLowerCase()
        }
        if (colInfo.displayType == "labels") {
            // return array as a string
            return (<Label[]>thisValue)
                .map((label) => {
                    return label.text
                })
                .join(" ")
                .toLowerCase()
        }
        if (colInfo.displayType == "status") {
            // return tooltip text
            return (<TableEntryStatus>thisValue).tooltip.toLowerCase()
        }
        if (colInfo.displayType == "buttons") {
            // return array as a string
            return (<TableEntryButtons>thisValue)
                .map((button) => {
                    return button.text || button.title
                })
                .join(" ")
                .toLowerCase()
        }
    }
    return ""
}

// AJAX
// request button
const requestButton = new Button({
    icon: "fal fa-fw fa-search",
    title: "Search",
    text: T("Search"),
    onClick: function () {
        doRequests()
    }
})
// result from ajax request
const result = ref(<any[]>[])
// executes the given request (ajax-mode)
const doRequests = async () => {
    if (typeof props.request == "function") {
        loading.value = true
        let sortBy = sorting.value.property ? sorting.value.property : ""
        let sortingOrder = sorting.value.property ? sorting.value.direction : ""
        let searchString = search.value ? search.value : ""
        let searchBy = searchProperty.value ? searchProperty.value : ""
        let responseLimit = limit.value ? limit.value : 50
        let offset = activePage.value > 1 ? (activePage.value - 1) * limit.value : 0
        let sortByColInfo = getColumnInfoByProperty(sorting.value.property || "")
        if (sortByColInfo && sortByColInfo.dontSortInRequest === true) {
            sortBy = ""
        }
        let response = await props.request(
            sortingOrder,
            sortBy,
            searchString,
            responseLimit,
            offset,
            searchBy
        )
        activePage.value = 1
        if (tablewrapper.value) {
            tablewrapper.value.scrollTop = 0
        }
        // reset paddings
        virtualScrolling.value.paddingsTop = []
        // reset starting item index
        virtualScrolling.value.fromIndex = 0
        calcRowHeights()
        if (response.total != undefined) {
            total.value = response.total
        }
        if (Array.isArray(response.result)) {
            result.value = response.result
        }
        loading.value = false
    }
}

// SETTINGS/OPTIONS
const showOptions = ref(false)
const optionsButton = new Button({
    icon: "fal fa-fw fa-cog",
    onClick: function () {
        showOptions.value = !showOptions.value
    }
})

// page-limit
const limit = ref(50)
// current page
const activePage = ref(1)
// total entries
const total = ref(0)
// is scrollbar active?
const scrollbarActive = computed(() => {
    return !(props.maxHeight == 0)
})
// table max-height
const tableMaxHeightValue = computed(() => {
    return scrollbarActive.value ? props.maxHeight + "px" : "auto"
})
const loading = ref(false)

// ENTRIES
const computedObjectList = computed(() => {
    updateObjectListCounter.value
    let list = result.value?.length
        ? result.value
        : (search.value.length ? props.objectList?.filter(searchFilter) : props.objectList) || []
    return list
})

const sortEntries = () => {
    let list = result.value?.length
        ? result.value
        : (search.value.length ? props.objectList?.filter(searchFilter) : props.objectList) || []
    if (sorting.value.property) {
        const colInfo = getColumnInfoByProperty(sorting.value.property)
        if (colInfo?.getSortValue) {
            list?.sort((a: any, b: any) => {
                let valueA = colInfo?.getSortValue?.(a) || getFieldValueAsString(colInfo, b, "sort")
                let valueB = colInfo?.getSortValue?.(b) || getFieldValueAsString(colInfo, b, "sort")
                if (typeof valueA == "number" && typeof valueB == "number") {
                    if (sorting.value.direction == "DESC") {
                        return valueA > valueB ? -1 : 1
                    } else {
                        return valueA < valueB ? -1 : 1
                    }
                } else if (typeof valueA == "string" && typeof valueB == "string") {
                    if (sorting.value.direction == "DESC") {
                        return valueA.toLowerCase() > valueB.toLowerCase() ? -1 : 1
                    } else {
                        return valueA.toLowerCase() < valueB.toLowerCase() ? -1 : 1
                    }
                } else {
                    return -1
                }
            })
        }
    }
}

onMounted(async () => {
    thisSelectableColums.value = props.selectableColumns
    getContainerHeight()
    calcRowHeights()
    window.addEventListener("resize", () => {
        getContainerHeight()
        calcRowHeights()
    })

    const tableWrapper = tablewrapper.value
    tableWrapper?.addEventListener("scroll", onScroll)

    if (props.selectedColumnsDefault) {
        selectedColumns.value = selectedColumns.value.concat(props.selectedColumnsDefault)
    } else {
        selectedColumns.value = selectedColumns.value.concat(
            thisSelectableColums.value.map((field) => {
                return field.property || ""
            })
        )
    }
    doRequests()
    await setTimeout(() => {
        calcRowHeights()
    }, 1000)

    if (props.initialSortDirection) {
        sorting.value.direction = props.initialSortDirection
    }
    if (props.initialSortProperty) {
        sorting.value.property = props.initialSortProperty
    }
})

// update counter, update forces rerendering. Fixes not updated select input
const updateList = () => {
    updateObjectListCounter.value++
}

// REFS
const tablewrapper = ref(<null | HTMLElement>null)
const tabletopwrapper = ref(<null | HTMLElement>null)
const thRelativeToThis = ref(<null | HTMLElement>null)

// VIRTUAL SCROLLING
const virtualScrolling = ref({
    containerHeight: <number>0,
    lastScrollDistance: 0,
    scrollDistance: 0,
    fromIndex: 0,
    heightList: <number[]>[],
    paddingsTop: <number[]>[],
    paddingsBottom: <number[]>[]
})
const tableTopPadding = computed(() => {
    return (<number[]>virtualScrolling.value.paddingsTop).reduce((pv, cv) => {
        return pv + cv
    }, 0)
})
const tableBottomPadding = computed(() => {
    return (<number[]>virtualScrolling.value.paddingsBottom).reduce((pv, cv) => {
        return pv + cv
    }, 0)
})
const itemsLength = computed(() => {
    return computedObjectList.value?.length || 0
})
const onScroll = () => {
    // scrolled
    // get height of scrolling frame
    const tableWrapper = tablewrapper.value

    if (tabletopwrapper.value != null && tableWrapper) {
        tabletopwrapper.value.scrollLeft = tableWrapper.scrollLeft
    }

    getContainerHeight()
    // calculate the scrolled distance
    const scrollDistance = (virtualScrolling.value.scrollDistance = tableWrapper?.scrollTop || 0)
    // get scrolling direction
    const scrollDirection =
        virtualScrolling.value.scrollDistance > virtualScrolling.value.lastScrollDistance
            ? "down"
            : virtualScrolling.value.scrollDistance == virtualScrolling.value.lastScrollDistance
              ? "ignored"
              : "up"
    // calculate the height of each row
    calcRowHeights()
    const firstRowHeight = virtualScrolling.value.heightList.length
        ? virtualScrolling.value.heightList[0]
        : 0
    const secondRowHeight = virtualScrolling.value.heightList.length
        ? virtualScrolling.value.heightList[1]
        : 0
    const thirdRowHeight = virtualScrolling.value.heightList.length
        ? virtualScrolling.value.heightList[2]
        : 0
    const fourthRowHeight = virtualScrolling.value.heightList.length
        ? virtualScrolling.value.heightList[3]
        : 0
    if (scrollDistance == 0) {
        // reset paddings
        virtualScrolling.value.paddingsTop = []
        // reset starting item index
        virtualScrolling.value.fromIndex = 0
        calcRowHeights()
    } else if (scrollDirection == "down") {
        // Scrolled down
        let paddingsTop =
            virtualScrolling.value.paddingsTop.length > 0
                ? (<number[]>virtualScrolling.value.paddingsTop).reduce(function (pv, cv) {
                      return pv + cv
                  }, 0)
                : 0
        if (scrollDistance > firstRowHeight + secondRowHeight + thirdRowHeight + paddingsTop) {
            // if scroll distance is higher than the first row's heihgt -> all top-paddings
            // update starting item index
            virtualScrolling.value.fromIndex = virtualScrolling.value.fromIndex + 4
            // add top padding
            if (firstRowHeight) {
                virtualScrolling.value.paddingsTop.push(firstRowHeight)
            }
            if (secondRowHeight) {
                virtualScrolling.value.paddingsTop.push(secondRowHeight)
            }
            if (thirdRowHeight) {
                virtualScrolling.value.paddingsTop.push(secondRowHeight)
            }
            if (fourthRowHeight) {
                virtualScrolling.value.paddingsTop.push(secondRowHeight)
            }
            // recalculate row heights
            calcRowHeights()
        }
    } else if (scrollDirection == "up" && virtualScrolling.value.paddingsTop.length > 0) {
        // Scrolled up
        let paddingsTop =
            virtualScrolling.value.paddingsTop.length > 0
                ? (<number[]>virtualScrolling.value.paddingsTop).reduce(function (pv, cv) {
                      return pv + cv
                  }, 0)
                : 0
        if (scrollDistance < firstRowHeight + secondRowHeight + thirdRowHeight + paddingsTop) {
            //scroll distance is smaller than first row + all top-paddings
            // update starting item index
            virtualScrolling.value.fromIndex = virtualScrolling.value.fromIndex - 4
            // remove padding from top
            virtualScrolling.value.paddingsTop.pop()
            virtualScrolling.value.paddingsTop.pop()
            virtualScrolling.value.paddingsTop.pop()
            virtualScrolling.value.paddingsTop.pop()
            // recalculate row heights
            calcRowHeights()
        }
    } else if (scrollDirection == "ignored" && virtualScrolling.value.paddingsTop.length) {
        // scrolling ignored, should be at the top
        // reset paddings
        virtualScrolling.value.paddingsTop = []
        // reset starting item index
        virtualScrolling.value.fromIndex = 0
        // recalculate row heights
        calcRowHeights()
    }
    // set last scroll distance in order to detect the next scrolling direction
    virtualScrolling.value.lastScrollDistance = virtualScrolling.value.scrollDistance
    calcRowHeights()
}

const renderedRows = computed(() => {
    let calcRenderedRows = 15
    let avgHeight: number = 35
    if (virtualScrolling.value.heightList.length) {
        avgHeight =
            virtualScrolling.value.heightList.reduce((partialSum, a) => partialSum + a, 0) /
            virtualScrolling.value.heightList.length
    }
    if (virtualScrolling.value.containerHeight > 0) {
        const itemLengthPerView =
            Math.round(virtualScrolling.value.containerHeight / (avgHeight / 2)) + 15

        if (virtualScrolling.value.fromIndex + itemLengthPerView > itemsLength.value) {
            const overlappingCount =
                virtualScrolling.value.fromIndex + itemLengthPerView - itemsLength.value
            calcRenderedRows = itemLengthPerView - overlappingCount
        } else {
            calcRenderedRows = itemLengthPerView
        }
    }
    return itemsLength.value < calcRenderedRows ? itemsLength.value : calcRenderedRows
})

const calcRowHeights = () => {
    let heightList: number[] = []
    const tableWrapper = tablewrapper.value
    const rowElements = tableWrapper?.children[0].children[0].children
    if (rowElements) {
        for (let i = 0; renderedRows.value > i; i++) {
            if (rowElements[i]) {
                let height = rowElements[i].clientHeight + 1 // +1 because of border-bottom
                if (
                    i + 1 < renderedRows.value &&
                    rowElements[i + 1]?.classList.contains("subtable")
                ) {
                    height += rowElements[i + 1].clientHeight + 1
                }
                if (!rowElements[i]?.classList.contains("subtable")) {
                    heightList.push(height)
                }
            }
        }
    }
    virtualScrolling.value.heightList = heightList
}

const getContainerHeight = () => {
    const tableWrapper = tablewrapper.value
    virtualScrolling.value.containerHeight = tableWrapper?.clientHeight || 0
}

const getSubTableSelectableColumns = (entry: any, index: number, subTableIndex: number) => {
    let subTableCol = getSubTableInfo(index, subTableIndex)
    if (subTableCol?.displayType == "subTable") {
        return subTableCol
            ? typeof subTableCol.entries == "function"
                ? subTableCol.entries(entry)
                : subTableCol.entries
            : []
    }
    return []
}
const getSubTableInfo = (i: number, subTableIndex: number) => {
    return getSubTables()?.[subTableIndex]
}
const getSubTableObjects = (i: number, subTableIndex: number) => {
    let subTableCol = getSubTableInfo(i, subTableIndex)
    let entry = props.objectList?.[i]
    if (subTableCol?.displayType == "subTable" && subTableCol.getObjects != undefined && entry) {
        return subTableCol.getObjects(entry)
    }
    return []
}
const hasSubTable = () => {
    return getSubTables().length > 0
}
const getSubTables = () => {
    return (
        thisSelectableColums.value.filter((column) => {
            return column.displayType == "subTable"
        }) || []
    )
}
const hasSubTableOpened = (i: number, subTableIndex: number) => {
    let subTableCol = getSubTableInfo(i, subTableIndex)
    let entry = props.objectList?.[i]
    if (subTableCol?.displayType == "subTable") {
        return subTableCol.isOpened(entry)
    }
    return false
}
const hasAnySubTableOpened = (i: number) => {
    return getSubTables().some((subTable, subTableIndex) => {
        let subTableCol = getSubTableInfo(i, subTableIndex)
        let entry = props.objectList?.[i]
        if (subTableCol?.displayType == "subTable") {
            return subTableCol.isOpened(entry)
        }
    })

    return false
}

// WATCHER
watch(sorting, doRequests, { deep: true })

watch(
    [updateObjectListCounter, computedMaxHeight, computedMinHeight],
    () => {
        getContainerHeight()
        calcRowHeights()
    },
    { deep: true }
)
watch(activePage, doRequests, { deep: true })
watch(
    limit,
    () => {
        if (activePage.value == 1) {
            doRequests()
        } else {
            activePage.value = 1
        }
    },
    { deep: true }
)

watch(search, () => {
    if (props.request != undefined) {
    } else if (props.objectList != undefined) {
        if (tablewrapper.value) {
            tablewrapper.value.scrollTop = 0
        }
        // reset paddings
        virtualScrolling.value.paddingsTop = []
        // reset starting item index
        virtualScrolling.value.fromIndex = 0
        getContainerHeight()
        calcRowHeights()
    }
})
watch(
    () => {
        return props.isLoading
    },
    () => {
        if (props.isLoading !== undefined) {
            loading.value = props.isLoading
        }
    }
)

watch(result, () => {
    sortEntries()
})
watch(
    () => props.objectList,
    () => {
        sortEntries()
    }
)

// EXPOSED VARS
defineExpose({
    selection,
    updateObjectListCounter
})
</script>
<template>
    <div class="sms-table table-next" style="margin: 0">
        <div class="media-screen-only" style="overflow: hidden">
            <div
                class="row flexrow margin-xs-y-2"
                v-if="
                    hasOptions ||
                    typeof request == 'function' ||
                    isFilterable ||
                    isSearchable ||
                    buttons?.length
                "
            >
                <div class="col-xs padding-xs-x text-center flex-shrink" v-if="hasOptions">
                    <Tooltip :is-tag="'span'" :tooltip="T('Options')">
                        <buttonComponent :button-options="optionsButton"></buttonComponent>
                    </Tooltip>
                </div>

                <div
                    class="col-xs padding-xs-x padding-xs-r-4 flex-shrink"
                    v-if="typeof request == 'function'"
                >
                    <span style="display: inline-block">{{ T("Show") }}</span
                    >&nbsp;
                    <select style="display: inline-block; width: 60px" v-model="limit">
                        <option :value="2">2</option>
                        <option :value="25">25</option>
                        <option :value="50">50</option>
                        <option :value="100">100</option>
                        <option :value="150">150</option></select
                    >&nbsp;
                    <span style="display: inline-block">{{ T("entries") }}</span>
                </div>

                <div class="col-xs padding-xs-x text-left flex-shrink" v-if="isFilterable">
                    <label class="form-inputgroup margin-xs-b-0">
                        <span class="form-icon-prefix"><i class="fal fa-filter"></i></span>
                        <select v-model="searchProperty">
                            <option value="">{{ T("All") }}</option>
                            <template v-for="column in selectableColumns">
                                <option
                                    :value="column.property"
                                    v-if="
                                        acceptedSearchDisplayTypes.indexOf(column.displayType) !=
                                            -1 &&
                                        column.getSortValue != undefined &&
                                        column.property?.[0] !== '#'
                                    "
                                >
                                    {{ column.text ? column.text : "" }}
                                </option>
                            </template>
                        </select>
                    </label>
                </div>

                <div class="col-xs padding-xs-x" v-if="isSearchable">
                    <label class="input text margin-xs-b-0">
                        <span class="form-icon-prefix"><i class="fal fa-search"></i></span>
                        <input
                            type="search"
                            v-on:keyup.enter="doRequests"
                            :placeholder="T('Search')"
                            v-model="search"
                        />
                    </label>
                </div>

                <div class="col-xs padding-xs-x flex-shrink" v-if="typeof request == 'function'">
                    <buttonComponent :button-options="requestButton"></buttonComponent>
                </div>
                <div class="col-xs-8 padding-xs-x flex-grow text-right">
                    <template v-for="buttonInfo in buttons">
                        <buttonComponent :button-options="buttonInfo"></buttonComponent>
                    </template>
                    &nbsp;
                </div>
            </div>
        </div>

        <div
            v-if="showOptions"
            style="padding: 10px 0px; border-top: 1px solid rgba(0, 0, 0, 0.05)"
        >
            <div class="flexrow">
                <template v-for="column in selectableColumns">
                    <div class="margin-xs-r-2" v-if="column.displayType != 'subTable'">
                        <label class="input checkbox">
                            <input
                                type="checkbox"
                                v-model="selectedColumns"
                                :value="column.property"
                            /><span></span> {{ column.text }}
                        </label>
                    </div>
                </template>
            </div>
        </div>
        <div
            ref="tabletopwrapper"
            class="tabletopwrapper"
            style="width: 100%; overflow-x: hidden"
            :style="{ 'padding-right': scrollbarActive ? '6px' : '0px' }"
        >
            <table>
                <thead>
                    <tr>
                        <template
                            v-for="(column, i) in selectedColumnsWithInfo"
                            :key="getColKey(column, i)"
                        >
                            <template v-if="column == '#selector'">
                                <th style="width: 32px"></th>
                            </template>
                            <template v-else-if="column.displayType != 'subTable'">
                                <th
                                    :title="column.text"
                                    :data-key="getColKey(column, i)"
                                    class="thead"
                                    v-on:click="
                                        column.getSortValue == undefined
                                            ? null
                                            : setSortBy(column.property)
                                    "
                                    :style="{ width: column.width ? column.width + 'px' : 'auto' }"
                                    :class="[
                                        {
                                            sortable: column.getSortValue != undefined,
                                            'sort-desc':
                                                column.property == sorting.property &&
                                                'ASC' == sorting?.direction,
                                            'sort-asc':
                                                column.property == sorting.property &&
                                                'DESC' == sorting?.direction
                                        }
                                    ]"
                                >
                                    <span
                                        :class="{
                                            'text-right': column?.thAlign == 'right',
                                            'text-left': column?.thAlign == 'left',
                                            'text-center': column?.thAlign == 'center'
                                        }"
                                        >{{ column.text }}</span
                                    >
                                </th>
                            </template>
                        </template>
                    </tr>
                </thead>
            </table>
        </div>

        <div
            ref="tablewrapper"
            class="tablewrapper"
            :style="{
                'max-height': tableMaxHeightValue,
                'overflow-y': scrollbarActive ? 'scroll' : 'auto'
            }"
            style="width: 100%; overflow-x: visible"
        >
            <table
                ref="thRelativeToThis"
                :style="{
                    'margin-top': +tableTopPadding + 'px',
                    'margin-bottom': +tableBottomPadding + 'px'
                }"
            >
                <tbody>
                    <template
                        v-if="(typeof request == 'function' ? result : computedObjectList)?.length"
                    >
                        <!-- template v-for="entry, index in (typeof request == 'function' ? result : computedObjectList)" :key="String(entry[Object.keys(entry)[0]]) + index" -->
                        <template v-for="index in renderedRows" :key="getRowKey(index)">
                            <tr
                                :class="
                                    getRowClasses(
                                        computedObjectList[index + virtualScrolling.fromIndex - 1],
                                        props.parentEntry
                                    )
                                "
                                :data-key="getRowKey(index + virtualScrolling.fromIndex - 1)"
                                :data-index="index + virtualScrolling.fromIndex - 1"
                            >
                                <template
                                    v-for="(column, i) in selectedColumnsWithInfo"
                                    :key="
                                        getColKey(column, i) +
                                        '-' +
                                        getRowKey(index + virtualScrolling.fromIndex - 1)
                                    "
                                >
                                    <td style="width: 32px" v-if="column == '#selector'">
                                        <label class="input checkbox">
                                            <input
                                                :id="
                                                    getColKey(column, i) +
                                                    '-' +
                                                    getRowKey(
                                                        index + virtualScrolling.fromIndex - 1
                                                    ) +
                                                    '-input'
                                                "
                                                type="checkbox"
                                                :value="
                                                    typeof rowValueGetter == 'function'
                                                        ? rowValueGetter(
                                                              computedObjectList[
                                                                  index +
                                                                      virtualScrolling.fromIndex -
                                                                      1
                                                              ],
                                                              props.parentEntry
                                                          )
                                                        : Number(index)
                                                "
                                                v-model="selection"
                                            /><span></span>
                                        </label>
                                    </td>
                                    <td
                                        v-else-if="column.displayType != 'subTable'"
                                        :style="{
                                            width: column.width ? column.width + 'px' : 'auto'
                                        }"
                                        :id="
                                            column.tdId
                                                ? column.tdId?.(
                                                      computedObjectList[
                                                          index + virtualScrolling.fromIndex - 1
                                                      ],
                                                      props.parentEntry
                                                  )
                                                : ''
                                        "
                                        :class="
                                            typeof column.tdClass == 'function'
                                                ? column.tdClass(
                                                      computedObjectList[
                                                          index + virtualScrolling.fromIndex - 1
                                                      ],
                                                      props.parentEntry
                                                  )
                                                : column.tdClass || ''
                                        "
                                    >
                                        <span>
                                            <template v-if="column.displayType == 'text'">
                                                <template v-if="column.iconsBefore">
                                                    <template
                                                        v-for="icon in typeof column.iconsBefore ==
                                                        'function'
                                                            ? column.iconsBefore(
                                                                  computedObjectList[
                                                                      index +
                                                                          virtualScrolling.fromIndex -
                                                                          1
                                                                  ],
                                                                  props.parentEntry
                                                              )
                                                            : column.iconsBefore"
                                                    >
                                                        <template v-if="icon.title">
                                                            <Tooltip
                                                                :is-tag="'span'"
                                                                :tooltip="icon.title"
                                                                :html-tooltip="icon.htmlTooltip"
                                                            >
                                                                <i :class="icon.class"></i>
                                                            </Tooltip>
                                                        </template>
                                                        <template v-else>
                                                            <i :class="icon.class"></i>
                                                        </template>
                                                    </template>
                                                    &nbsp;
                                                </template>

                                                <template v-if="column.title != undefined">
                                                    <Tooltip
                                                        :is-tag="'span'"
                                                        :tooltip="
                                                            typeof column.title == 'function'
                                                                ? column.title(
                                                                      computedObjectList[
                                                                          index +
                                                                              virtualScrolling.fromIndex -
                                                                              1
                                                                      ],
                                                                      props.parentEntry
                                                                  )
                                                                : column.title
                                                        "
                                                        :html-tooltip="column.htmlTooltip"
                                                    >
                                                        {{
                                                            column.getValue(
                                                                computedObjectList[
                                                                    index +
                                                                        virtualScrolling.fromIndex -
                                                                        1
                                                                ],
                                                                props.parentEntry
                                                            )
                                                        }}
                                                    </Tooltip>
                                                </template>
                                                <template v-else>
                                                    <template
                                                        v-if="
                                                            column.flagIcon?.(
                                                                computedObjectList[
                                                                    index +
                                                                        virtualScrolling.fromIndex -
                                                                        1
                                                                ],
                                                                props.parentEntry
                                                            )
                                                        "
                                                    >
                                                        <Tooltip
                                                            :is-tag="'span'"
                                                            :tooltip="
                                                                tenantHelpers.countryCodeToName(
                                                                    column.flagIcon(
                                                                        computedObjectList[
                                                                            index +
                                                                                virtualScrolling.fromIndex -
                                                                                1
                                                                        ],
                                                                        props.parentEntry
                                                                    )
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="fi"
                                                                :class="`fi-${column.flagIcon(computedObjectList[index + virtualScrolling.fromIndex - 1], props.parentEntry)}`"
                                                            ></i>
                                                        </Tooltip>
                                                    </template>
                                                    <template
                                                        v-else-if="
                                                            column.flagIcon?.(
                                                                computedObjectList[
                                                                    index +
                                                                        virtualScrolling.fromIndex -
                                                                        1
                                                                ],
                                                                props.parentEntry
                                                            ) === null
                                                        "
                                                    >
                                                        <Tooltip
                                                            :is-tag="'span'"
                                                            :tooltip="T('Unknown')"
                                                        >
                                                            <i
                                                                style="color: white"
                                                                class="fal fa-question-circle"
                                                            ></i>
                                                        </Tooltip>
                                                    </template>

                                                    {{
                                                        column.getValue(
                                                            computedObjectList[
                                                                index +
                                                                    virtualScrolling.fromIndex -
                                                                    1
                                                            ],
                                                            props.parentEntry
                                                        )
                                                    }}
                                                </template>

                                                <template v-if="column.smallTextAfter">
                                                    <small
                                                        >{{
                                                            column.smallTextAfter(
                                                                computedObjectList[
                                                                    index +
                                                                        virtualScrolling.fromIndex -
                                                                        1
                                                                ],
                                                                props.parentEntry
                                                            )
                                                        }}
                                                    </small>
                                                </template>

                                                <template v-if="column.iconsAfter">
                                                    &nbsp;
                                                    <template
                                                        v-for="icon in typeof column.iconsAfter ==
                                                        'function'
                                                            ? column.iconsAfter(
                                                                  computedObjectList[
                                                                      index +
                                                                          virtualScrolling.fromIndex -
                                                                          1
                                                                  ],
                                                                  props.parentEntry
                                                              )
                                                            : column.iconsAfter"
                                                    >
                                                        <template v-if="icon.title">
                                                            <Tooltip
                                                                :is-tag="'span'"
                                                                :tooltip="icon.title"
                                                                :htmlTooltip="icon.htmlTooltip"
                                                            >
                                                                <i :class="icon.class"></i>
                                                            </Tooltip>
                                                        </template>
                                                        <template v-else>
                                                            <i :class="icon.class"></i>
                                                        </template>
                                                    </template>
                                                </template>

                                                <template v-if="column.labelsAfter">
                                                    &nbsp;&nbsp;
                                                    <template
                                                        v-for="(
                                                            label, labelIndex
                                                        ) in typeof column.labelsAfter == 'function'
                                                            ? column.labelsAfter(
                                                                  computedObjectList[
                                                                      index +
                                                                          virtualScrolling.fromIndex -
                                                                          1
                                                                  ],
                                                                  props.parentEntry
                                                              )
                                                            : column.labelsAfter"
                                                        :key="
                                                            getColKey(column, i) +
                                                            '-' +
                                                            getRowKey(
                                                                index +
                                                                    virtualScrolling.fromIndex -
                                                                    1
                                                            ) +
                                                            '-label-' +
                                                            labelIndex
                                                        "
                                                    >
                                                        <labelComponent
                                                            :label="label"
                                                        ></labelComponent>
                                                        &nbsp;
                                                    </template>
                                                </template>
                                                <template v-if="column.subContent">
                                                    <br />
                                                    <span
                                                        v-if="column.subContent.marginLeft"
                                                        :style="
                                                            'margin-left:' +
                                                            column.subContent.marginLeft +
                                                            'px'
                                                        "
                                                    ></span>
                                                    <template
                                                        v-for="contentOption in column.subContent
                                                            .displayOrder"
                                                    >
                                                        <small
                                                            class="margin-xs-l"
                                                            v-if="
                                                                contentOption == 'subText' &&
                                                                column.subContent.subText
                                                            "
                                                        >
                                                            {{
                                                                typeof column.subContent.subText ==
                                                                "string"
                                                                    ? column.subContent.subText
                                                                    : column.subContent.subText(
                                                                          computedObjectList[
                                                                              index +
                                                                                  virtualScrolling.fromIndex -
                                                                                  1
                                                                          ]
                                                                      )
                                                            }}
                                                        </small>

                                                        <template
                                                            v-if="
                                                                contentOption == 'subLabels' &&
                                                                column.subContent.subLabels
                                                            "
                                                        >
                                                            &nbsp;&nbsp;
                                                            <template
                                                                v-for="(
                                                                    label, labelIndex
                                                                ) in typeof column.subContent
                                                                    .subLabels == 'function'
                                                                    ? column.subContent.subLabels(
                                                                          computedObjectList[
                                                                              index +
                                                                                  virtualScrolling.fromIndex -
                                                                                  1
                                                                          ],
                                                                          props.parentEntry
                                                                      )
                                                                    : column.subContent.subLabels"
                                                                :key="
                                                                    getColKey(column, i) +
                                                                    '-' +
                                                                    getRowKey(
                                                                        index +
                                                                            virtualScrolling.fromIndex -
                                                                            1
                                                                    ) +
                                                                    '-label-' +
                                                                    labelIndex
                                                                "
                                                            >
                                                                <labelComponent
                                                                    :label="label"
                                                                ></labelComponent>
                                                                &nbsp;
                                                            </template>
                                                        </template>
                                                    </template>
                                                </template>
                                            </template>
                                            <template
                                                v-if="
                                                    column.displayType == 'link' &&
                                                    (column.getValue(
                                                        computedObjectList[
                                                            index + virtualScrolling.fromIndex - 1
                                                        ],
                                                        props.parentEntry
                                                    ).href != undefined ||
                                                        column.getValue(
                                                            computedObjectList[
                                                                index +
                                                                    virtualScrolling.fromIndex -
                                                                    1
                                                            ],
                                                            props.parentEntry
                                                        ).onClick != undefined)
                                                "
                                            >
                                                <template v-if="column.iconsBefore">
                                                    <template
                                                        v-for="icon in typeof column.iconsBefore ==
                                                        'function'
                                                            ? column.iconsBefore(
                                                                  computedObjectList[
                                                                      index +
                                                                          virtualScrolling.fromIndex -
                                                                          1
                                                                  ],
                                                                  props.parentEntry
                                                              )
                                                            : column.iconsBefore"
                                                    >
                                                        <template v-if="icon.title">
                                                            <Tooltip
                                                                :is-tag="'span'"
                                                                :tooltip="icon.title"
                                                                :html-tooltip="icon.htmlTooltip"
                                                            >
                                                                <i :class="icon.class"></i>
                                                            </Tooltip>
                                                        </template>
                                                        <template v-else>
                                                            <i :class="icon.class"></i>
                                                        </template>
                                                    </template>
                                                    &nbsp;
                                                </template>

                                                <a
                                                    :href="
                                                        column.getValue(
                                                            computedObjectList[
                                                                index +
                                                                    virtualScrolling.fromIndex -
                                                                    1
                                                            ],
                                                            props.parentEntry
                                                        ).href
                                                    "
                                                    :v-on:click="
                                                        column.getValue(
                                                            computedObjectList[
                                                                index +
                                                                    virtualScrolling.fromIndex -
                                                                    1
                                                            ],
                                                            props.parentEntry
                                                        ).onClick
                                                            ? column.getValue(
                                                                  computedObjectList[
                                                                      index +
                                                                          virtualScrolling.fromIndex -
                                                                          1
                                                                  ],
                                                                  props.parentEntry
                                                              ).onClick
                                                            : null
                                                    "
                                                    :target="
                                                        column.getValue(
                                                            computedObjectList[
                                                                index +
                                                                    virtualScrolling.fromIndex -
                                                                    1
                                                            ],
                                                            props.parentEntry
                                                        ).target || '_self'
                                                    "
                                                >
                                                    {{
                                                        column.getValue(
                                                            computedObjectList[
                                                                index +
                                                                    virtualScrolling.fromIndex -
                                                                    1
                                                            ],
                                                            props.parentEntry
                                                        ).text
                                                    }}
                                                </a>
                                            </template>
                                            <template
                                                v-if="
                                                    column.displayType == 'link' &&
                                                    column.getValue(
                                                        computedObjectList[
                                                            index + virtualScrolling.fromIndex - 1
                                                        ],
                                                        props.parentEntry
                                                    ).href == undefined &&
                                                    column.getValue(
                                                        computedObjectList[
                                                            index + virtualScrolling.fromIndex - 1
                                                        ],
                                                        props.parentEntry
                                                    ).onClick == undefined
                                                "
                                            >
                                            </template>
                                            <template v-if="column.displayType == 'labels'">
                                                <template
                                                    v-for="(label, labelIndex) in column.getValue(
                                                        computedObjectList[
                                                            index + virtualScrolling.fromIndex - 1
                                                        ],
                                                        props.parentEntry
                                                    )"
                                                    :key="
                                                        getColKey(column, i) +
                                                        '-' +
                                                        getRowKey(
                                                            index + virtualScrolling.fromIndex - 1
                                                        ) +
                                                        '-label-' +
                                                        labelIndex
                                                    "
                                                >
                                                    <labelComponent :label="label"></labelComponent
                                                    >&nbsp;
                                                </template>
                                            </template>
                                            <template v-if="column.displayType == 'buttons'">
                                                <template
                                                    v-if="
                                                        column.replaceButtonWithStatus?.(
                                                            computedObjectList[
                                                                index +
                                                                    virtualScrolling.fromIndex -
                                                                    1
                                                            ]
                                                        ) == true
                                                    "
                                                >
                                                    <div
                                                        class="text-center"
                                                        v-if="
                                                            column.getValue(
                                                                computedObjectList[
                                                                    index +
                                                                        virtualScrolling.fromIndex -
                                                                        1
                                                                ],
                                                                props.parentEntry
                                                            )
                                                        "
                                                    >
                                                        <Tooltip
                                                            :tooltip="
                                                                (<TableEntryStatus>(
                                                                    column.getValue(
                                                                        computedObjectList[
                                                                            index +
                                                                                virtualScrolling.fromIndex -
                                                                                1
                                                                        ],
                                                                        props.parentEntry
                                                                    )
                                                                ))?.tooltip || ''
                                                            "
                                                            :is-tag="'span'"
                                                            :boundary="thRelativeToThis"
                                                        >
                                                            <i
                                                                class="fa fa-fw"
                                                                style="font-size: 1.25em"
                                                                :data-icon="
                                                                    (<TableEntryStatus>(
                                                                        column.getValue(
                                                                            computedObjectList[
                                                                                index +
                                                                                    virtualScrolling.fromIndex -
                                                                                    1
                                                                            ],
                                                                            props.parentEntry
                                                                        )
                                                                    ))?.icon == undefined
                                                                "
                                                                :data-color="
                                                                    (<TableEntryStatus>(
                                                                        column.getValue(
                                                                            computedObjectList[
                                                                                index +
                                                                                    virtualScrolling.fromIndex -
                                                                                    1
                                                                            ],
                                                                            props.parentEntry
                                                                        )
                                                                    ))?.color
                                                                "
                                                                :class="[
                                                                    'color-' +
                                                                        ((<TableEntryStatus>(
                                                                            column.getValue(
                                                                                computedObjectList[
                                                                                    index +
                                                                                        virtualScrolling.fromIndex -
                                                                                        1
                                                                                ],
                                                                                props.parentEntry
                                                                            )
                                                                        ))?.color == 'grey'
                                                                            ? darkMode
                                                                                ? 'darkgrey'
                                                                                : 'lightgray'
                                                                            : (<TableEntryStatus>(
                                                                                  column.getValue(
                                                                                      computedObjectList[
                                                                                          index +
                                                                                              virtualScrolling.fromIndex -
                                                                                              1
                                                                                      ],
                                                                                      props.parentEntry
                                                                                  )
                                                                              ))?.color),
                                                                    {
                                                                        'fa-circle':
                                                                            (<TableEntryStatus>(
                                                                                column.getValue(
                                                                                    computedObjectList[
                                                                                        index +
                                                                                            virtualScrolling.fromIndex -
                                                                                            1
                                                                                    ],
                                                                                    props.parentEntry
                                                                                )
                                                                            ))?.icon == undefined &&
                                                                            ((<TableEntryStatus>(
                                                                                column.getValue(
                                                                                    computedObjectList[
                                                                                        index +
                                                                                            virtualScrolling.fromIndex -
                                                                                            1
                                                                                    ],
                                                                                    props.parentEntry
                                                                                )
                                                                            ))?.color == 'grey' ||
                                                                                (<TableEntryStatus>(
                                                                                    column.getValue(
                                                                                        computedObjectList[
                                                                                            index +
                                                                                                virtualScrolling.fromIndex -
                                                                                                1
                                                                                        ],
                                                                                        props.parentEntry
                                                                                    )
                                                                                ))?.color ==
                                                                                    'orange'),
                                                                        'fa-circle-exclamation':
                                                                            (<TableEntryStatus>(
                                                                                column.getValue(
                                                                                    computedObjectList[
                                                                                        index +
                                                                                            virtualScrolling.fromIndex -
                                                                                            1
                                                                                    ],
                                                                                    props.parentEntry
                                                                                )
                                                                            ))?.icon == undefined &&
                                                                            (<TableEntryStatus>(
                                                                                column.getValue(
                                                                                    computedObjectList[
                                                                                        index +
                                                                                            virtualScrolling.fromIndex -
                                                                                            1
                                                                                    ],
                                                                                    props.parentEntry
                                                                                )
                                                                            ))?.color == 'red',
                                                                        'fa-circle-info':
                                                                            (<TableEntryStatus>(
                                                                                column.getValue(
                                                                                    computedObjectList[
                                                                                        index +
                                                                                            virtualScrolling.fromIndex -
                                                                                            1
                                                                                    ],
                                                                                    props.parentEntry
                                                                                )
                                                                            ))?.icon == undefined &&
                                                                            (<TableEntryStatus>(
                                                                                column.getValue(
                                                                                    computedObjectList[
                                                                                        index +
                                                                                            virtualScrolling.fromIndex -
                                                                                            1
                                                                                    ],
                                                                                    props.parentEntry
                                                                                )
                                                                            ))?.color == 'yellow',
                                                                        'fa-circle-check':
                                                                            (<TableEntryStatus>(
                                                                                column.getValue(
                                                                                    computedObjectList[
                                                                                        index +
                                                                                            virtualScrolling.fromIndex -
                                                                                            1
                                                                                    ],
                                                                                    props.parentEntry
                                                                                )
                                                                            ))?.icon == undefined &&
                                                                            (<TableEntryStatus>(
                                                                                column.getValue(
                                                                                    computedObjectList[
                                                                                        index +
                                                                                            virtualScrolling.fromIndex -
                                                                                            1
                                                                                    ],
                                                                                    props.parentEntry
                                                                                )
                                                                            ))?.color == 'green',
                                                                        [(<TableEntryStatus>(
                                                                            column.getValue(
                                                                                computedObjectList[
                                                                                    index +
                                                                                        virtualScrolling.fromIndex -
                                                                                        1
                                                                                ],
                                                                                props.parentEntry
                                                                            )
                                                                        ))?.icon || '']:
                                                                            (<TableEntryStatus>(
                                                                                column.getValue(
                                                                                    computedObjectList[
                                                                                        index +
                                                                                            virtualScrolling.fromIndex -
                                                                                            1
                                                                                    ],
                                                                                    props.parentEntry
                                                                                )
                                                                            ))?.icon != undefined
                                                                    }
                                                                ]"
                                                            ></i>
                                                        </Tooltip>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <template
                                                        v-for="(
                                                            button, buttonIndex
                                                        ) in column.getValue(
                                                            computedObjectList[
                                                                index +
                                                                    virtualScrolling.fromIndex -
                                                                    1
                                                            ],
                                                            props.parentEntry
                                                        )"
                                                        :key="
                                                            getColKey(column, i) +
                                                            '-' +
                                                            getRowKey(
                                                                index +
                                                                    virtualScrolling.fromIndex -
                                                                    1
                                                            ) +
                                                            '-button-' +
                                                            buttonIndex
                                                        "
                                                    >
                                                        <buttonComponent
                                                            :button-options="button"
                                                        ></buttonComponent>
                                                    </template>
                                                </template>
                                            </template>
                                            <template v-if="column.displayType == 'status'">
                                                <div
                                                    class="text-center cell-status"
                                                    v-if="
                                                        column.getValue(
                                                            computedObjectList[
                                                                index +
                                                                    virtualScrolling.fromIndex -
                                                                    1
                                                            ],
                                                            props.parentEntry
                                                        )
                                                    "
                                                >
                                                    <Tooltip
                                                        :tooltip="
                                                            column.getValue(
                                                                computedObjectList[
                                                                    index +
                                                                        virtualScrolling.fromIndex -
                                                                        1
                                                                ],
                                                                props.parentEntry
                                                            )?.tooltip || ''
                                                        "
                                                        :is-tag="'span'"
                                                        :boundary="thRelativeToThis"
                                                        :html-tooltip="
                                                            column.getValue(
                                                                computedObjectList[
                                                                    index +
                                                                        virtualScrolling.fromIndex -
                                                                        1
                                                                ],
                                                                props.parentEntry
                                                            )?.htmlTooltip || false
                                                        "
                                                    >
                                                        <i
                                                            class="fa fa-fw"
                                                            style="font-size: 1.25em"
                                                            :data-icon="
                                                                column.getValue(
                                                                    computedObjectList[
                                                                        index +
                                                                            virtualScrolling.fromIndex -
                                                                            1
                                                                    ],
                                                                    props.parentEntry
                                                                )?.icon == undefined
                                                            "
                                                            :data-color="
                                                                column.getValue(
                                                                    computedObjectList[
                                                                        index +
                                                                            virtualScrolling.fromIndex -
                                                                            1
                                                                    ],
                                                                    props.parentEntry
                                                                )?.color
                                                            "
                                                            :class="[
                                                                'color-' +
                                                                    (column.getValue(
                                                                        computedObjectList[
                                                                            index +
                                                                                virtualScrolling.fromIndex -
                                                                                1
                                                                        ],
                                                                        props.parentEntry
                                                                    )?.color == 'grey'
                                                                        ? darkMode
                                                                            ? 'lightgray'
                                                                            : 'darkgray'
                                                                        : column.getValue(
                                                                              computedObjectList[
                                                                                  index +
                                                                                      virtualScrolling.fromIndex -
                                                                                      1
                                                                              ],
                                                                              props.parentEntry
                                                                          )?.color),
                                                                {
                                                                    'fa-circle':
                                                                        column.getValue(
                                                                            computedObjectList[
                                                                                index +
                                                                                    virtualScrolling.fromIndex -
                                                                                    1
                                                                            ],
                                                                            props.parentEntry
                                                                        )?.icon == undefined &&
                                                                        (column.getValue(
                                                                            computedObjectList[
                                                                                index +
                                                                                    virtualScrolling.fromIndex -
                                                                                    1
                                                                            ],
                                                                            props.parentEntry
                                                                        )?.color == 'grey' ||
                                                                            column.getValue(
                                                                                computedObjectList[
                                                                                    index +
                                                                                        virtualScrolling.fromIndex -
                                                                                        1
                                                                                ],
                                                                                props.parentEntry
                                                                            )?.color == 'orange'),
                                                                    'fa-circle-exclamation':
                                                                        column.getValue(
                                                                            computedObjectList[
                                                                                index +
                                                                                    virtualScrolling.fromIndex -
                                                                                    1
                                                                            ],
                                                                            props.parentEntry
                                                                        )?.icon == undefined &&
                                                                        column.getValue(
                                                                            computedObjectList[
                                                                                index +
                                                                                    virtualScrolling.fromIndex -
                                                                                    1
                                                                            ],
                                                                            props.parentEntry
                                                                        )?.color == 'red',
                                                                    'fa-circle-info':
                                                                        column.getValue(
                                                                            computedObjectList[
                                                                                index +
                                                                                    virtualScrolling.fromIndex -
                                                                                    1
                                                                            ],
                                                                            props.parentEntry
                                                                        )?.icon == undefined &&
                                                                        column.getValue(
                                                                            computedObjectList[
                                                                                index +
                                                                                    virtualScrolling.fromIndex -
                                                                                    1
                                                                            ],
                                                                            props.parentEntry
                                                                        )?.color == 'yellow',
                                                                    'fa-circle-check':
                                                                        column.getValue(
                                                                            computedObjectList[
                                                                                index +
                                                                                    virtualScrolling.fromIndex -
                                                                                    1
                                                                            ],
                                                                            props.parentEntry
                                                                        )?.icon == undefined &&
                                                                        column.getValue(
                                                                            computedObjectList[
                                                                                index +
                                                                                    virtualScrolling.fromIndex -
                                                                                    1
                                                                            ],
                                                                            props.parentEntry
                                                                        )?.color == 'green',
                                                                    [column.getValue(
                                                                        computedObjectList[
                                                                            index +
                                                                                virtualScrolling.fromIndex -
                                                                                1
                                                                        ],
                                                                        props.parentEntry
                                                                    )?.icon || '']:
                                                                        column.getValue(
                                                                            computedObjectList[
                                                                                index +
                                                                                    virtualScrolling.fromIndex -
                                                                                    1
                                                                            ],
                                                                            props.parentEntry
                                                                        )?.icon != undefined
                                                                }
                                                            ]"
                                                        ></i>
                                                    </Tooltip>

                                                    <span
                                                        v-if="
                                                            column.getValue(
                                                                computedObjectList[
                                                                    index +
                                                                        virtualScrolling.fromIndex -
                                                                        1
                                                                ],
                                                                props.parentEntry
                                                            )?.statusText
                                                        "
                                                        class="padding-xs-l"
                                                    >
                                                        {{
                                                            column.getValue(
                                                                computedObjectList[
                                                                    index +
                                                                        virtualScrolling.fromIndex -
                                                                        1
                                                                ],
                                                                props.parentEntry
                                                            )?.statusText
                                                        }}
                                                    </span>
                                                </div>
                                            </template>
                                            <template
                                                v-if="
                                                    column.displayType == 'input' &&
                                                    column.inputOptions.type == 'text' &&
                                                    column.inputOptions.available?.(
                                                        computedObjectList[
                                                            index + virtualScrolling.fromIndex - 1
                                                        ],
                                                        props.parentEntry
                                                    ) !== false
                                                "
                                            >
                                                <input
                                                    :id="
                                                        getColKey(column, i) +
                                                        '-' +
                                                        getRowKey(
                                                            index + virtualScrolling.fromIndex - 1
                                                        ) +
                                                        '-input'
                                                    "
                                                    type="text"
                                                    v-model="
                                                        computedObjectList[
                                                            index + virtualScrolling.fromIndex - 1
                                                        ][
                                                            column.inputOptions
                                                                ?.saveValueToProperty ||
                                                                column.property
                                                        ]
                                                    "
                                                    :disabled="
                                                        column.inputOptions.disabled?.(
                                                            computedObjectList[
                                                                index +
                                                                    virtualScrolling.fromIndex -
                                                                    1
                                                            ],
                                                            props.parentEntry
                                                        ) !== false
                                                            ? undefined
                                                            : true
                                                    "
                                                />
                                            </template>
                                            <template
                                                v-if="
                                                    column.displayType == 'input' &&
                                                    column.inputOptions.type == 'select' &&
                                                    column.inputOptions.available?.(
                                                        computedObjectList[
                                                            index + virtualScrolling.fromIndex - 1
                                                        ],
                                                        props.parentEntry
                                                    ) !== false
                                                "
                                            >
                                                <inputVueSelect
                                                    :id="
                                                        getColKey(column, i) +
                                                        '-' +
                                                        getRowKey(
                                                            index + virtualScrolling.fromIndex - 1
                                                        ) +
                                                        '-input'
                                                    "
                                                    :tags="column.inputOptions.select?.tags"
                                                    :multiple="column.inputOptions.select?.multiple"
                                                    :select-options="
                                                        typeof column.inputOptions.select
                                                            ?.options == 'function'
                                                            ? column.inputOptions.select.options(
                                                                  computedObjectList[
                                                                      index +
                                                                          virtualScrolling.fromIndex -
                                                                          1
                                                                  ],
                                                                  props.parentEntry
                                                              )
                                                            : column.inputOptions.select?.options ||
                                                              []
                                                    "
                                                    v-model="
                                                        computedObjectList[
                                                            index + virtualScrolling.fromIndex - 1
                                                        ][column.inputOptions.saveValueToProperty]
                                                    "
                                                    v-on:update:modelValue="updateList"
                                                    :disabled="
                                                        column.inputOptions.disabled?.(
                                                            computedObjectList[
                                                                index +
                                                                    virtualScrolling.fromIndex -
                                                                    1
                                                            ],
                                                            props.parentEntry
                                                        ) !== false
                                                            ? undefined
                                                            : true
                                                    "
                                                />
                                            </template>
                                        </span>
                                    </td>
                                </template>
                            </tr>

                            <template v-if="hasSubTable()">
                                <tr
                                    class="subtable"
                                    style="padding: 0"
                                    v-if="
                                        hasAnySubTableOpened(index + virtualScrolling.fromIndex - 1)
                                    "
                                >
                                    <td
                                        :colspan="selectedColumnsWithInfo.length"
                                        style="padding: 0; margin: 0"
                                    >
                                        <template
                                            v-for="(subTable, subTableIndex) in getSubTables()"
                                        >
                                            <template
                                                v-if="
                                                    hasSubTableOpened(
                                                        index + virtualScrolling.fromIndex - 1,
                                                        subTableIndex
                                                    )
                                                "
                                            >
                                                <div
                                                    :style="
                                                        'position:relative;z-index:' +
                                                        (99999 - subTableIndex)
                                                    "
                                                >
                                                    <tableNext
                                                        :object-list="
                                                            getSubTableObjects(
                                                                index +
                                                                    virtualScrolling.fromIndex -
                                                                    1,
                                                                subTableIndex
                                                            )
                                                        "
                                                        :parentEntry="
                                                            computedObjectList[
                                                                index +
                                                                    virtualScrolling.fromIndex -
                                                                    1
                                                            ]
                                                        "
                                                        :style="{
                                                            'padding-left':
                                                                subTablePaddingLeft + 'px'
                                                        }"
                                                        :subTablePaddingLeft="subTablePaddingLeft"
                                                        :selectable-columns="
                                                            getSubTableSelectableColumns(
                                                                computedObjectList[
                                                                    index +
                                                                        virtualScrolling.fromIndex -
                                                                        1
                                                                ],
                                                                index +
                                                                    virtualScrolling.fromIndex -
                                                                    1,
                                                                subTableIndex
                                                            )
                                                        "
                                                        :has-options="false"
                                                        :is-filterable="false"
                                                        :is-searchable="false"
                                                    ></tableNext>
                                                </div>
                                            </template>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </template>
                    <template v-else>
                        <tr>
                            <td>
                                {{ T("No entries found") }}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>

        <div
            class="btn-group"
            style="margin: 18px auto; position: relative; left: 50%; transform: translate(-50%, 0)"
            v-if="usePaging && limit <= total"
        >
            <a class="btn" v-on:click="activePage = 1" :disabled="activePage == 1 || undefined">
                <i class="fal fa-angles-left"></i>
            </a>
            <a
                class="btn"
                v-on:click="activePage = activePage - 1"
                :disabled="activePage == 1 || undefined"
            >
                <i class="fal fa-angle-left"></i>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span style="line-height: 2.5em">Page</span>
            &nbsp;&nbsp;
            <InputNumber
                :min="1"
                :max="Math.ceil((total - limit) / limit) + 1"
                v-model="activePage"
                style="display: inline-block; width: 45px"
            ></InputNumber>
            &nbsp;&nbsp;
            <span style="line-height: 2.5em">of {{ Math.ceil((total - limit) / limit) + 1 }}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a
                class="btn"
                v-on:click="activePage = activePage + 1"
                :disabled="activePage >= Math.ceil((total - limit) / limit) + 1 || undefined"
            >
                <i class="fal fa-angle-right"></i>
            </a>

            <a
                class="btn"
                v-on:click="activePage = Math.ceil((total - limit) / limit) + 1"
                :disabled="activePage >= Math.ceil((total - limit) / limit) + 1 || undefined"
            >
                <i class="fal fa-angles-right"></i>
            </a>
        </div>

        <div
            class="overlay"
            :class="{ hidden: !loading }"
            :style="darkMode ? 'background:rgba(0,0,0,0.3)' : 'background:rgba(255,255,255,0.6)'"
        >
            <Loader
                v-if="loading"
                class="color-red"
                style="
                    font-size: 3em;
                    position: absolute;
                    top: calc(50% - 0.5em);
                    left: calc(50% - 0.5em);
                "
            ></Loader>
        </div>
    </div>
</template>
<style lang="scss">
@use "sass:map";
@import "./../../styles/sass/settings";
.vertical-scroll-wrapper {
    overflow-x: auto;
}

.table-next tr.color-red {
    .vs__selected {
        color: map.get($colors, "red");
    }
}

.tabletopwrapper {
    border-left: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
    border-top: 1px solid $borderColor;
    table {
        border-top: none;
    }
}

.tablewrapper {
    border-left: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
}

.table-next.sms-table {
    table tbody > tr:nth-child(2n) {
        background: #fff;

        + tr.subtable {
            background: #fff;
        }
    }

    table tbody > tr:nth-child(2n - 1) {
        background: #f9f9f9;

        + tr.subtable {
            background: #f9f9f9;
        }
    }

    table thead > tr:nth-child(2n - 1) {
        background: #fff;
    }

    table tbody > tr:nth-child(2n - 1) {
        background: #f9f9f9;
    }
    table tbody > tr.highlight {
        box-shadow: 0 0 0 3px rgba($primaryColor, 0.6) inset;
        z-index: 1000;
        position: relative;
        transition: 1s;
    }
    table td {
        padding: 8px;
    }
    td.text-right {
        text-align: right;
    }
    td.text-center {
        text-align: center;
    }
    &.table-xs {
        table td {
            padding: 4px;
        }
    }
    &.table-s {
        table td {
            padding: 0px;
        }
    }
}

.darkmode .table-next.sms-table {
    table tbody > tr:nth-child(2n) {
        background: darken($darkBgColor, 10%);

        + tr.subtable {
            background: darken($darkBgColor, 10%);
        }
    }

    table tbody > tr:nth-child(2n - 1) {
        background: darken($darkBgColor, 15%);

        + tr.subtable {
            background: darken($darkBgColor, 15%);
        }
    }

    table thead > tr:nth-child(2n - 1) {
        background: darken($darkBgColor, 10%);
    }

    table tbody > tr:nth-child(2n - 1) {
        background: darken($darkBgColor, 15%);
    }
}
</style>
