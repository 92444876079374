<script setup lang="ts">
import { T } from "@/classes/i18n"
import CodeEnrollment, {
    type GetEnrollment
} from "@/classes/objectTypes/mobileSecurity/android/androidCodeEnrollment"
import CenterDialog from "@/templates/templates/center-dialog.vue"
import { onMounted, ref } from "vue"

const tenantDomain = ref<string>("")
const enrollmentTokenId = ref<string>("")
const enrollmentState = ref<GetEnrollment | false>(false)
const loading = ref(true)
const code = ref<string>("")

// Declare an instance of CodeEnrollment class
let codeEnrollmentInstance: CodeEnrollment | null = null

const fetchEnrollment = async () => {
    if (!codeEnrollmentInstance) return

    try {
        enrollmentState.value = await codeEnrollmentInstance.getCheckin()
    } catch (e) {
        console.error("Error fetching enrollment state:", e)
    } finally {
        loading.value = false
    }
}

onMounted(() => {
    const locationHash = location.hash
    const decodedHash = decodeURIComponent(locationHash)
    const match = decodedHash.match(/^#android-code-enrollment-tenant-([^-]+?)-enrollmentId-(.+)$/)

    if (match) {
        tenantDomain.value = match[1]
        enrollmentTokenId.value = match[2]

        codeEnrollmentInstance = new CodeEnrollment(tenantDomain.value, enrollmentTokenId.value)

        fetchEnrollment()
    } else {
        console.error("Invalid route format")
        loading.value = false
    }
})

const submitForm = async () => {
    if (!code.value || !codeEnrollmentInstance) return

    try {
        const payload = { code: code.value }
        const response = await codeEnrollmentInstance.checkin(payload) // Using the instance method

        if (response && response.redirectURL) {
            window.location.href = response.redirectURL
        } else {
            console.error("Failed to submit code.")
        }
    } catch (error) {
        console.error("Error during form submission:", error)
    }
}
</script>

<template>
    <CenterDialog disable-any-navigation>
        <template #default>
            <div v-if="loading">
                {{ T("Loading...") }}
            </div>
            <div
                v-else-if="
                    enrollmentState !== false &&
                    enrollmentState.policyName &&
                    enrollmentState.tokenId
                "
            >
                <h3>{{ T("Android Code Enrollment") }}</h3>
                <table style="width: 100%; margin-bottom: 20px">
                    <tbody>
                        <tr>
                            <td style="text-align: left; width: 30%">
                                <b>{{ T("Profile") }}</b>
                            </td>
                            <td style="text-align: left">{{ enrollmentState.policyName }}</td>
                        </tr>
                        <tr>
                            <td style="text-align: left; width: 30%">
                                <b>{{ T("Token") }}</b>
                            </td>
                            <td style="text-align: left; white-space: nowrap">
                                {{ enrollmentState.tokenId }}...
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p style="text-align: left; margin-bottom: 10px">
                    {{ T("Please enter the code, which has been provided by the IT admin") }}
                </p>
                <form @submit.prevent="submitForm" style="width: 100%">
                    <input
                        v-model="code"
                        type="text"
                        name="code"
                        id="code"
                        :placeholder="T('Code')"
                        required
                        style="
                            width: 100%;
                            padding: 10px;
                            box-sizing: border-box;
                            margin-bottom: 20px;
                        "
                    />
                    <div style="text-align: right">
                        <button
                            id="submit"
                            type="submit"
                            class="btn"
                            :disabled="loading"
                            style="
                                padding: 10px 20px;
                                background-color: #ff4d4f;
                                color: #fff;
                                border: none;
                                border-radius: 4px;
                                cursor: pointer;
                            "
                        >
                            {{ T("Submit") }}
                        </button>
                    </div>
                </form>
            </div>
            <div v-else>
                <h3>{{ T("Oops, this link has expired!") }}</h3>
            </div>
        </template>
    </CenterDialog>
</template>
