<script setup lang="ts">
import { T } from "@/classes/i18n"
import {
    PasswordReset,
    type PasswordResetState
} from "@/classes/objectTypes/unifiedSecurity/users/passwordReset"
import CenterDialog from "@/templates/templates/center-dialog.vue"
import { onMounted, ref } from "vue"

const passwordState = ref<PasswordResetState | false>(false)
const tenantDomain = ref<string | null>(null)
const hash = ref<string | null>(null)
const newPassword = ref("")
const confirmPassword = ref("")
const qrCode = ref("")
const totpSecret = ref("")
const code = ref("")
const passwordError = ref("")
const tokenError = ref("")
const passwordChangeSuccess = ref(false)
const tokenValidationSuccess = ref(false)
const loading = ref(true)
let passwordReset: PasswordReset | null = null

const fetchPasswordResetState = async () => {
    if (!passwordReset) return

    try {
        passwordState.value = await passwordReset.getState()
    } catch (e) {
        console.error("Error fetching password reset state:", e)
    } finally {
        loading.value = false
    }
}

const changePassword = async () => {
    passwordError.value = ""

    const passwordErrorMsg = getError({
        password: newPassword.value,
        password_confirm: confirmPassword.value
    })

    if (passwordErrorMsg) {
        passwordError.value = passwordErrorMsg
        return
    }

    const payload = {
        state: "change",
        password: newPassword.value,
        password_confirm: confirmPassword.value
    }

    if (!passwordReset) return

    const response = await passwordReset.updatePassword(payload)

    if (response.success) {
        passwordChangeSuccess.value = true

        if (response.data && response.data.qrCode && response.data.totpSecret) {
            qrCode.value = response.data.qrCode
            totpSecret.value = response.data.totpSecret
        }
    } else {
        passwordError.value = response.error || T("An unexpected error occurred")
    }
}

const validateToken = async () => {
    tokenError.value = ""
    tokenValidationSuccess.value = false

    const payload = { code: code.value }

    if (!passwordReset) return

    const response = await passwordReset.validateToken(payload)

    if (response.success) {
        tokenValidationSuccess.value = true
        tokenError.value = ""
    } else {
        tokenError.value = response.error || T("An unexpected error occurred")
    }
}

onMounted(() => {
    const locationHash = location.hash
    const decodedHash = decodeURIComponent(locationHash)
    const match = decodedHash.match(/^#password-reset-tenant-([^-]+?)-hash-(.+)$/)

    if (match) {
        tenantDomain.value = match[1]
        hash.value = match[2]

        if (tenantDomain.value && hash.value) {
            passwordReset = new PasswordReset(tenantDomain.value, hash.value)
            fetchPasswordResetState()
        }
    } else {
        console.error("Invalid route format")
        loading.value = false
    }
})

function getError(content?: { password?: string; password_confirm?: string }): string {
    if (!content?.password || !content?.password_confirm) {
        return T("Please enter a password!")
    }

    if (content?.password?.length < 5 || content?.password?.includes(" ")) {
        return T("The password must be at least 5 characters long and must not contain any spaces!")
    }

    if (content?.password !== content?.password_confirm) {
        return T("The two passwords do not match!")
    }

    return ""
}

const copyToClipboard = async () => {
    if (totpSecret.value) {
        try {
            await navigator.clipboard.writeText(totpSecret.value)
            alert(T("Setup key copied to clipboard!"))
        } catch (e) {
            console.error("Failed to copy setup key:", e)
            alert(T("Failed to copy setup key. Please try again."))
        }
    }
}
</script>

<template>
    <CenterDialog>
        <div v-if="loading">
            <p>{{ T("Loading...") }}</p>
        </div>
        <div v-else-if="passwordState && passwordState.username">
            <!-- Password Change Section -->
            <div
                v-if="
                    !passwordChangeSuccess &&
                    (passwordState.state === 'show' || passwordState.state === 'change')
                "
            >
                <div v-if="passwordError" class="error">
                    {{ passwordError }}
                </div>
                <div v-else>
                    <h2 style="margin-top: 16px">
                        {{ T("Set/Change password for") }} <b>{{ passwordState.username }}</b>
                    </h2>
                    <div class="text-center">
                        {{
                            T(
                                "Here you can set a new password for the Securepoint Unified Security user $1."
                            ).replace("$1", passwordState.username)
                        }}
                    </div>
                </div>
                <br />
                <form @submit.prevent="changePassword" class="padding-xs-x-2">
                    <input
                        hidden
                        name="username"
                        :value="passwordState.username || ''"
                        aria-hidden="true"
                        autocomplete="username"
                    />

                    <div class="form-group">
                        <label for="newPassword" class="form-label">{{ T("New Password:") }}</label>
                        <input
                            id="newPassword"
                            type="password"
                            v-model="newPassword"
                            :placeholder="T('New password')"
                            autocomplete="new-password"
                            aria-required="true"
                            class="form-input"
                        />
                    </div>
                    <br />
                    <div class="form-group">
                        <label for="confirmPassword" class="form-label">{{
                            T("Confirm New Password:")
                        }}</label>
                        <input
                            id="confirmPassword"
                            type="password"
                            v-model="confirmPassword"
                            :placeholder="T('Confirm password')"
                            autocomplete="new-password"
                            aria-required="true"
                            class="form-input"
                        />
                    </div>
                    <br />
                    <div class="text-center">
                        {{
                            T(
                                "Ensure that the password is at least 5 characters long and does not contain any spaces."
                            )
                        }}
                    </div>
                    <br />
                    <div class="btn-center">
                        <button type="submit">{{ T("Submit") }}</button>
                    </div>
                </form>
            </div>

            <!-- Two-factor authentication section -->
            <div v-if="passwordChangeSuccess && qrCode">
                <h2 class="text-center">{{ T("Your password has been changed") }}</h2>
                <div class="text-center">
                    {{ T("Two-factor authentication (2FA) is activated for your account.") }}
                    {{ T("This is a requirement for registration on the website.") }}
                    <br />
                    <br />

                    {{
                        T(
                            `If you would like to set up 2FA now, please scan the following QR code with an authenticator app such as Google Authenticator or Microsoft Authenticator.`
                        )
                    }}
                    <br />
                    <br />
                    {{ T("If 2FA has already been set up correctly, you can close this window.") }}
                </div>
                <div class="qr-code-container">
                    <img :src="qrCode" alt="{{ T('TOTP QR Code') }}" />
                </div>
                <p>{{ T("Your setup key:") }}</p>
                <div
                    style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-bottom: 1em;
                    "
                    class="border-bottom"
                >
                    <input
                        type="text"
                        :value="totpSecret"
                        id="secret"
                        readonly
                        style="margin-right: 5px"
                    />
                    <button @click="copyToClipboard">{{ T("Copy") }}</button>
                </div>
                <br />
                {{
                    T(
                        `Transfer the secret to your authenticator and then enter a generated code in the field below and click on Send. Otherwise, the secret will be discarded and the old secret will remain valid.`
                    )
                }}
                <br />
                <br />
                <div style="display: flex; align-items: center">
                    <input
                        type="text"
                        v-model="code"
                        :placeholder="T('Enter code')"
                        autocomplete="one-time-code"
                        style="margin-right: 5px"
                    />
                    <button @click="validateToken">{{ T("Submit") }}</button>
                </div>
                <p v-if="tokenError" class="error">{{ tokenError }}</p>
                <p v-if="tokenValidationSuccess && !tokenError">
                    {{ T("Please click") }}
                    <b
                        ><a href="/"> {{ T("here") }} </a></b
                    >
                    {{ T("to go to the portal.") }}
                </p>
                <p v-if="tokenValidationSuccess && !tokenError">
                    {{ T("You can now log in with the tenant") }}
                    <b>{{ passwordState.tenantDomain }}</b> {{ T("and username") }}
                    <b>{{ passwordState.username }}</b
                    >.
                </p>
            </div>
        </div>
        <div v-else>
            <h3>{{ T("Oops, this link has expired!") }}</h3>
        </div>
    </CenterDialog>
</template>

<style scoped>
.error {
    padding: 18px;
    margin: 16px auto;
    background: #e74c3c;
    border-color: #d62c1a;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
}

.btn-right {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.form-group {
    margin-bottom: 16px;
}

.form-label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    width: 200px;
    text-align: left;
}

.form-input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.qr-code-container {
    display: flex;
    justify-content: center;
    margin: 16px 0;
}
</style>
