import { type OldStore } from "@/store/vuex.store"

const getterHelpers = {
    useVue: () => {
        return window.app?.vue
    },
    useStore: () => {
        return window.app?.vue?.$store as OldStore
    }
}
export default getterHelpers
