import { useStore } from "@/store/vuex.store"
import getterHelpers from "./helpers.getters"

const sessionHelpers = {
    hasOneOfScopes: (arrayOfScopes: string[]) => {
        let permissionScopes = useStore().state.session.userInfo.permissionScopes
        return arrayOfScopes.some((scope) => {
            return permissionScopes.indexOf(scope) != -1
        })
    },
    getSessionInfo: (): Session | undefined => {
        return getterHelpers.useStore()?.state?.session?.userInfo || undefined
    },
    isRspUser: (sessionInfo: Session | undefined = undefined) => {
        if (!sessionInfo) {
            sessionInfo = sessionHelpers.getSessionInfo()
        }
        if (sessionInfo) {
            return sessionInfo.oauth2 == true
        }
        throw "Missing Session"
    },
    has2FAActive: (sessionInfo: Session | undefined = undefined) => {
        if (!sessionInfo) {
            sessionInfo = sessionHelpers.getSessionInfo()
        }

        if (sessionInfo) {
            if (sessionHelpers.isRspUser(sessionInfo)) {
                return (sessionInfo?.scopes.list_user?.security_level || 0) >= 20
            } else if (Object.hasOwn(sessionInfo, "totpEnabled")) {
                return sessionInfo?.totpEnabled
            } else {
                return false
            }
        }
        throw "Missing Session"
    }
}

export default sessionHelpers
