import config from "@/classes/config"
import i18n, { T } from "@/classes/i18n"
import {
    HomescreenLayoutItemType,
    type HomescreenLayout,
    type HomescreenLayoutBase,
    type HomescreenLayoutFormItem,
    type HomescreenLayoutItem,
    type HomescreenLayoutPage
} from "@/classes/mobileSecurity/homescreenlayouts"
import products from "@/classes/objectTypes"
import dialogs from "@/dialogs/dialogs"
import getterHelpers from "@/helpers/helpers.getters"
import stringHelpers from "@/helpers/helpers.strings"
import { useGlobalMixin } from "@/mixins/mixins.global"
import queries from "@/queries/queries"
import requestHandler from "@/queries/requests"
import { useStore } from "@/store/vuex.store"
import { nextTick } from "vue"
import { VueDraggableNext } from "vue-draggable-next"
import jsonHelpers from "../../helpers/helpers.json"
import loaderComponent from "../components/loader.vue"
import tooltip from "../components/tooltip.vue"
import InputText from "./input-text.vue"
import inputVueSelectVue from "./input-vue-select.vue"

const inputAppleHomescreenLayoutComponent = {
    mixins: [useGlobalMixin()],
    name: "input-applehomescreenlayout",
    template: `
		<div v-if="initialized">
				<div class="row padding-xs-y form-group form-entry-row-search border-bottom validation-base" v-if="false">
					<div class="first col-xs-24 col-lg-6 col-xl-5">
						<label for="homescreenDeviceType" class="control-label">
							Layout
						</label>
					</div>
					<div class="input col-xs-24 col-lg-8 col-xl-7">
						<label class="form-field select">
							<select v-model="homescreenDeviceType" id="homescreenDeviceType">
								<template v-for="homescreenDeviceInfo,homescreenDeviceName in homescreenDeviceTypes">
									<option :value="homescreenDeviceName">
										{{ homescreenDeviceInfo.title }}
									</option>
								</template>
							</select>
						</label>
					</div>
				</div>
				<div id="IpadScreen" :class="{ 'tvos':isTvOS }" style="background: rgb(51, 51, 51);color: rgb(255, 255, 255);padding: 10px 10px 0px;overflow: auto hidden;border-radius: 4px 4px 0 0;position: relative;padding-bottom: 83px; box-shadow:0 0 12px 2px rgb(0 0 0 / 40%) inset">
					<div class="pagesArea" :class="{'blurred': viewFolder.show && viewFolder.folder != undefined }" >
						<template v-for="page,i in homescreenLayout.Pages">
							<div class="page" :style="'width:'+homescreenDeviceTypes[homescreenDeviceType].homescreenPageWidth+'px'">
								<a class="deletePage" v-on:click="deletePage(i,homescreenLayout.Pages)">
									<i class="fal fa-times"></i>
								</a>
								<draggable 
									v-model="homescreenLayout.Pages[i]" 
									class="homescreen-group"
									group="homescreen"
									:move="onDrag"
									@end="onDrop"
									invertSwap="true"
								>
									<div 
										class="homescreen-list-item" 
										v-for="(item,j) in page"
										:key="item.randomId"
									>
										<div 
											:class="{'icon':true,'folder':item.Type == 'Folder'}"
											:style="(item.assetInfos && item.assetInfos.artworkUrl60 ? 'background-position:center;background-image:url('+ item.assetInfos.artworkUrl60 +')' : (['Application','WebClip'].indexOf(item.Type) != -1 ? 'background-color: #ccc' : undefined))"
											:title="item.DisplayName"
										>
											<a class="deleteApplication" v-on:click="deleteApplication(j,page,item.BundleID || undefined)">
												<i class="fal fa-times"></i>
											</a>
											<a v-if="item.Type == 'Folder'" class="editFolder" v-on:click="showFolder(item)">
												<i class="fal fa-cog"></i>
											</a>
											<div class="folderContentPreview" v-if="item.Type == 'Folder'">
												<draggable 
													v-model="item.Pages[item.draggingItemOver ? (item.Pages.length - 1) : 0]" 
													class="homescreen-group"
													group="homescreen"
													invertSwap="true"
													@add="onAddToFolder(item,$event)"
													draggable=".homescreen-list-item"
													:key="'folder'+item.randomId"
													:disabled="draggingFolder || null"
												>
													<template v-for="(folderItem,l) in item.Pages[item.draggingItemOver ? (item.Pages.length - 1) : 0]">
														<div 
															v-if="l < 9"
															class="folder-list-item" 
															:key="folderItem.randomId"
														>
															<div
																class="icon" 
																:style="(folderItem.assetInfos && folderItem.assetInfos.artworkUrl60 ? 'background-position:center;background-image:url('+ folderItem.assetInfos.artworkUrl60 +')' : 'background-color: #cecece')"
																:title="folderItem.DisplayName"
															>
															</div>
														</div>
													</template>
												</draggable>
											</div>
										</div>
										<div class="text text-center" style="font-size:0.7em; white-space: nowrap; margin-top:5px; line-height:1em">
											<template v-if="item.DisplayName && item.DisplayName.length > 10">
												{{ item.DisplayName.substring(0,8) + '...' }}
											</template>
											<template v-else>
												{{ item.DisplayName }}
											</template>
										</div>
									</div>
								</draggable>
							</div>
						</template>
						<div 
							class="addHomescreenPage"
							v-on:click="addPage()"
							:title="T('Add page')"
						>
							<i 
								class="fal fa-plus"
								style="align-self: center; width: 100%; text-align: center; font-size:2em"
							></i>
						</div>
					</div>
					<div 
						class="dockArea" 
						:class="{'blurred': viewFolder.show && viewFolder.folder != undefined }"
						:style="'min-width:'+homescreenDeviceTypes[homescreenDeviceType].homescreenDockWidth+'px'"
					>

						<draggable 
							v-model="homescreenLayout.Dock" 
							class="homescreen-group"
							group="homescreen"
							:move="onDrag"
							@end="onDrop"
							invertSwap="true"
						>
							<div 
								class="homescreen-list-item" 
								v-for="(item,j) in homescreenLayout.Dock"
								:key="item.randomId"
							>
								<div 
									:class="{'icon':true,'folder':item.Type == 'Folder'}"
									:style="(item.assetInfos && item.assetInfos.artworkUrl60 ? 'background-position:center;background-image:url('+ item.assetInfos.artworkUrl60 +')' : (['Application','WebClip'].indexOf(item.Type) != -1 ? 'background-color: #cecece' : undefined))"
									:title="item.DisplayName"
								>
									<a class="deleteApplication" v-on:click="deleteApplication(j,homescreenLayout.Dock,item.BundleID || undefined)">
										<i class="fal fa-times"></i>
									</a>
									<a v-if="item.Type == 'Folder'" class="editFolder" v-on:click="showFolder(item)">
										<i class="fal fa-cog"></i>
									</a>
									<div class="folderContentPreview" v-if="item.Type == 'Folder'">
										<draggable 
											v-model="item.Pages[item.draggingItemOver ? (item.Pages.length - 1) : 0]" 
											class="homescreen-group"
											group="homescreen"
											invertSwap="true"
											@add="onAddToFolder(item,$event)"
											draggable=".homescreen-list-item"
											:key="'folder'+item.randomId"
											:disabled="draggingFolder || null"
										>
											<template v-for="(folderItem,l) in item.Pages[item.draggingItemOver ? (item.Pages.length - 1) : 0]">
												<div 
													v-if="l < 9"
													class="folder-list-item" 
													:key="folderItem.randomId"
												>
													<div
														class="icon" 
														:style="(folderItem.assetInfos && folderItem.assetInfos.artworkUrl60 ? 'background-position:center;background-image:url('+ folderItem.assetInfos.artworkUrl60 +')' : 'background-color: #cecece')"
														:title="folderItem.DisplayName"
													>
													</div>
												</div>
											</template>
										</draggable>
									</div>
								</div>
								<div class="text text-center" style="font-size:0.7em; white-space: nowrap; margin-top:5px; line-height:1em">
									<template v-if="item.DisplayName && item.DisplayName.length > 10">
										{{ item.DisplayName.substring(0,8) + '...' }}
									</template>
									<template v-else>
										{{ item.DisplayName }}
									</template>
								</div>
							</div>
						</draggable>

					</div>

					<div
						class="disableOverlay" 
						:class="{'show':viewFolder.show && viewFolder.folder != undefined}"
						v-on:click="hideFolder()"
					>
						<draggable 
						class="disableOverlay homescreen-group" 
						group="homescreen"
						:class="{'show':viewFolder.show && viewFolder.folder != undefined}"
						:key="'closeFolder'"
						>
						</draggable>
					</div>
					
					<template v-if="viewFolder.folder != undefined">
						<div 
							id="folderView"
							v-on:click.stop
							:class="{'hidden':!viewFolder.show}"
						>
							<div class="pagesArea">
								<template v-for="page,i in (viewFolder.folder || {Pages:[[]]}).Pages">
									<div class="page">
										<a class="deletePage" v-on:click="deletePage(i,viewFolder.folder.Pages)">
											<i class="fal fa-times"></i>
										</a>
										<draggable 
											v-model="viewFolder.folder.Pages[i]" 
											class="homescreen-group"
											group="homescreen"
											:move="onDragInFolder"
											@end="onDrop"
											invertSwap="true"
										>
											<div 
												class="homescreen-list-item" 
												v-for="(item,j) in page"
												:key="item.randomId"
											>
												<div 
													:class="{'icon':true,'folder':item.Type == 'Folder'}"
													:style="(item.assetInfos && item.assetInfos.artworkUrl60 ? 'background-position:center;background-image:url('+ item.assetInfos.artworkUrl60 +')' : (['Application','WebClip'].indexOf(item.Type) != -1 ? 'background-color: #cecece' : undefined))"
													:title="item.DisplayName"
												>
													<a class="deleteApplication" v-on:click="deleteApplication(j,page,item.BundleID || undefined)">
														<i class="fal fa-times"></i>
													</a>
												</div>
												<div class="text text-center" style="font-size:0.7em; white-space: nowrap; margin-top:5px; line-height:1em">
													<template v-if="item.DisplayName && item.DisplayName.length > 10">
														{{ item.DisplayName.substring(0,8) + '...' }}
													</template>
													<template v-else>
														{{ item.DisplayName }}
													</template>
												</div>
											</div>
										</draggable>
									</div>
								</template>
								<div 
									class="addHomescreenPage"
									v-on:click="addPage(viewFolder.folder.Pages)"
									:title="T('Add page')"
								>
									<i 
										class="fal fa-plus"
										style="align-self: center; width: 100%; text-align: center; font-size:2em"
									></i>
								</div>
							</div>

						</div>
					</template>
				</div>
				<div id="appsArea">
					<div class="row flexrow padding-xs-y-0 padding-xs-x-0 form-group">
						<div class="col-xs flex-shrink padding-xs-x">
							<label for="selectType" :title="T('Type')" class="control-label inputname">{{ T('Type') }}</label>
						</div>
						<div class="input col-xs flex-shrink padding-xs-x">
							<select v-model="addItemForm.Type" id="selectType">
								<option value="SystemApplication">{{ T('System Application') }} (iOS/iPadOS)</option>
								<option value="Application">{{ T('Application') }} (iOS/iPadOS)</option>
								<option value="SystemApplicationTv">{{ T('System Application') }} (tvOS)</option>
								<option value="ApplicationTv">{{ T('Application') }} (tvOS)</option>
								<option value="WebClip">{{ T('Webclip') }}</option>
								<option value="Folder">{{ T('Folder') }}</option>
							</select>
						</div>
						<template v-if="addItemForm.Type == 'SystemApplication'">
							<div class="col-xs flex-shrink padding-xs-x">
								<label for="searchApp" :title="T('Select App')" class="control-label inputname">{{ T('Select App') }}</label>
							</div>
							<div class="input col-xs col-xl-5 flex-shrink padding-xs-x" :style="{'min-width': appSelectionWidth}">
								<input-vue-select
									id="systemAppSelector" 
									v-model="addItemForm.BundleID" 
									:placeholder="T('Select app')"
									:selectOptions="iosNativeAppsBundleId"
									ref="select2Apps"
									key="sysAppSelect"
								/>
							</div>
						</template>
						<template v-if="addItemForm.Type == 'SystemApplicationTv'">
							<div class="col-xs flex-shrink padding-xs-x">
								<label for="searchApp" :title="T('Select App')" class="control-label inputname">{{ T('Select App') }}</label>
							</div>
							<div class="input col-xs col-xl-5 flex-shrink padding-xs-x" :style="{'min-width': appSelectionWidth}">
								<input-vue-select
									id="systemAppSelector" 
									v-model="addItemForm.BundleID" 
									:placeholder="T('Select app')"
									:selectOptions="tvosNativeAppsBundleId"
									ref="select2Apps"
									key="sysAppSelect"
								/>
							</div>
						</template>
						<template v-if="addItemForm.Type == 'Application'">
							<div class="col-xs flex-shrink padding-xs-x">
								<label for="searchApp" :title="T('Select App')" class="control-label inputname">{{ T('Select App') }}</label>
							</div>
							<div class="input col-xs col-xl-5 flex-shrink padding-xs-x" :style="{'min-width': appSelectionWidth}">
								<input-vue-select
									id="appSelector" 
									v-model="addItemForm.BundleID" 
									:placeholder="T('Select app')"
									:selectOptions="iosAppsPlusNativeAppsBundleId"
									ref="select2Apps"
									key="appSelect"
									:query="itunesAppSearchOptions"
								/>
							</div>
						</template>
						<template v-if="addItemForm.Type == 'ApplicationTv'">
							<div class="col-xs flex-shrink padding-xs-x">
								<label for="searchApp" :title="T('Select App')" class="control-label inputname">{{ T('Select App') }}</label>
							</div>
							<div class="input col-xs col-xl-5 flex-shrink padding-xs-x" :style="{'min-width': appSelectionWidth}">
								<input-vue-select
									id="appSelector" 
									v-model="addItemForm.BundleID" 
									:placeholder="T('Select app')"
									:selectOptions="tvosAppsPlusNativeAppsBundleId"
									ref="select2Apps"
									key="appSelect"
									:query="itunesTvAppSearchOptions"
								/>
							</div>
						</template>
						<template v-if="addItemForm.Type == 'WebClip'">
							<div class="col-xs flex-shrink padding-xs-x">
								<label for="webClipUrl"  title="URL" class="control-label inputname">Webclip</label>
							</div>
							<div class="input col-xs col-xl-5 flex-shrink padding-xs-x" :style="{'min-width': webclipWidth}">
								<input-vue-select
									id="webClipUrl" 
									type="text"
									v-model="addItemForm.webclipId" 
									:placeholder="T('Select webclip')"
									:selectOptions="webclipOptions"
									key="webclipSelect"
								/>
							</div>
						</template>
						<template v-if="addItemForm.Type == 'Folder'">
							<div class="col-xs flex-shrink padding-xs-x" >
								<label for="displayName"  title="Name" class="control-label inputname">Name</label>
							</div>
							<div class="input col-xs col-xl-5 flex-shrink padding-xs-x">
								<input-text
									:disabled="addItemForm.Type == 'Application' || null"
									id="displayName" 
									placeholder="Name" 
									v-model="addItemForm.DisplayName"
									:regex="/^[0-9A-Za-zäÄöÖüÜß\\(\\)\\._-]{0,30}$/m"
								/>
								
							</div>
							<div class="col-xs flex-shrink padding-xs-x">
								<tooltip 
									isTag="span"
									:tooltip="T('Only letters and numbers are allowed')"
								>
									<i class="fal fa-info-circle"></i>
								</tooltip>
							</div>
						</template>
						<div class="input col-xs flex-shrink padding-xs-x">
							<a class="btn btn-primary" :disabled="!canAdd() || null" v-on:click="addItem">
								{{ T('Add') }}
							</a>
						</div>
						<div class="input col-xs flex-shrink padding-xs-x">
							<a class="btn btn-primary" v-on:click="addAllSystemApps" :disabled="hasAllNativeApps || null" v-if="addItemForm.Type == 'SystemApplication'">
								{{ T('Add all system apps') }}
							</a>
							<a class="btn btn-primary" v-on:click="addAllSystemAppsTv" :disabled="hasAllNativeAppsTv || null" v-if="addItemForm.Type == 'SystemApplicationTv'">
								{{ T('Add all system apps') }}
							</a>
							<a class="btn btn-primary" v-on:click="addAllMobSecApps" :disabled="hasAllMobSecApps || null" v-if="addItemForm.Type == 'Application'">
								{{ T('Add all apps (without system apps)') }}
							</a>
						</div>
					</div>
				</div>
		</div>
	`,
    data: () => {
        return {
            initialized: false,
            homescreenDeviceType: "ipad",
            homescreenDeviceTypes: {
                iphone: {
                    title: "iPhone",
                    homescreenPageWidth: 260,
                    homescreenDockWidth: 260
                },
                ipad: {
                    title: "iPad",
                    homescreenPageWidth: 380,
                    homescreenDockWidth: 380
                }
            },
            drag: false,
            draggingFolder: false,
            lastDragOverFolder: undefined,
            addItemForm: <HomescreenLayoutItem>{
                Type: "Application",
                BundleID: "",
                DisplayName: "",
                URL: "",
                assetInfos: undefined,
                Pages: undefined,
                webclipId: ""
            },
            addedBundleIds: [],
            assetInfoMap: {},
            viewFolder: {
                show: false,
                folder: undefined
            }
        }
    },
    computed: {
        isTvOS: function (this: any) {
            return (
                getterHelpers
                    .useVue()
                    ?.$refs?.editpage?.getFieldById?.(
                        getterHelpers.useVue()?.$refs?.editpage?.form,
                        "profilePlatform"
                    ).value == "TVOS"
            )
        },
        nativeAppInfos: function (this: any) {
            return useStore().state.resources.apple.nativeAppInfos.apps
        },
        nativeAppInfosTv: function (this: any) {
            return useStore().state.resources.apple.tvOsNativeApps.apps
        },
        iosAppsPlusNativeAppsBundleId: function (this: any) {
            return this.isTvOS
                ? []
                : (
                      getterHelpers.useVue()?.$refs.editpage.selectOptions
                          .iosAppsPlusNativeAppsBundleId || []
                  ).sort(function (a: selectOption, b: selectOption) {
                      const firstText = a.text.toLowerCase()
                      const secondText = b.text.toLowerCase()
                      return firstText === secondText ? 0 : firstText > secondText ? 1 : -1
                  })
        },
        iosNativeAppsBundleId: function (this: any) {
            return this.isTvOS
                ? []
                : (
                      getterHelpers.useVue()?.$refs.editpage.selectOptions.iosNativeAppsBundleId ||
                      []
                  ).sort(function (a: selectOption, b: selectOption) {
                      const firstText = a.text.toLowerCase()
                      const secondText = b.text.toLowerCase()
                      return firstText === secondText ? 0 : firstText > secondText ? 1 : -1
                  })
        },
        tvosAppsPlusNativeAppsBundleId: function (this: any) {
            return (
                getterHelpers.useVue()?.$refs.editpage.selectOptions
                    .tvosAppsPlusNativeAppsBundleId || []
            ).sort(function (a: selectOption, b: selectOption) {
                const firstText = a.text.toLowerCase()
                const secondText = b.text.toLowerCase()
                return firstText === secondText ? 0 : firstText > secondText ? 1 : -1
            })
        },
        tvosNativeAppsBundleId: function (this: any) {
            return (
                getterHelpers.useVue()?.$refs.editpage.selectOptions.tvosNativeAppsBundleId || []
            ).sort(function (a: selectOption, b: selectOption) {
                const firstText = a.text.toLowerCase()
                const secondText = b.text.toLowerCase()
                return firstText === secondText ? 0 : firstText > secondText ? 1 : -1
            })
        },
        iosAppsBundleId: function (this: any) {
            return getterHelpers.useVue()?.$refs.editpage.selectOptions.iosAppsBundleId
        },
        hasAllNativeApps: function (this: any) {
            let result = false
            if ((this.addedBundleIds || []).length) {
                result = !this.iosNativeAppsBundleId.some((bundleIdOption: any) => {
                    return this.addedBundleIds.indexOf(bundleIdOption.id) == -1
                })
            }
            return result
        },
        hasAllNativeAppsTv: function (this: any) {
            let result = false
            if ((this.addedBundleIds || []).length) {
                result = !this.tvosNativeAppsBundleId.some((bundleIdOption: any) => {
                    return this.addedBundleIds.indexOf(bundleIdOption.id) == -1
                })
            }
            return result
        },
        hasAllMobSecApps: function (this: any) {
            let result = false
            if ((this.addedBundleIds || []).length) {
                result = !this.iosAppsBundleId.some((bundleIdOption: any) => {
                    return this.addedBundleIds.indexOf(bundleIdOption.id) == -1
                })
            }
            return result
        },
        webclipOptions: function (this: any) {
            return (getterHelpers.useVue()?.$refs.editpage.selectOptions.webclips || []).sort(
                function (a: selectOption, b: selectOption) {
                    const firstText = a.text.toLowerCase()
                    const secondText = b.text.toLowerCase()
                    return firstText === secondText ? 0 : firstText > secondText ? 1 : -1
                }
            )
        },
        apps: function (this: any) {
            let result: any = []

            result = config.canUseNewObjectType("iosApps")
                ? products.mobileSecurity.iosApps
                      .useStore?.()
                      .getObjectStoreObjects(this.activeAccountId)
                : useStore().getters.getObjectTypeStore({
                      accountId: this.activeAccountId,
                      objectType: "apps"
                  })?.items || []

            return result
        },
        appSelectionWidth: function (this: any) {
            return i18n.getLanguage() === "de" ? "145px" : "120px"
        },
        webclipWidth: function (this: any) {
            return i18n.getLanguage() === "de" ? "170px" : "140px"
        }
    },
    props: {
        homescreenLayout: <HomescreenLayoutBase>{
            Dock: [],
            Pages: []
        }
    },
    methods: {
        init: async function (this: any) {
            // get all BundleIDs
            let bundleIDs: string[] = this.homescreenLayoutToAppList(
                <HomescreenLayout>this.homescreenLayout
            )
            if (bundleIDs.length > 0) {
                // get assetInfos for all BundleIDs && set assetInfoMap in component to make it accessable from within other functions
                this.assetInfoMap = await queries.mobileSecurity.vpp.getAssetInfosMapFromAppList(
                    bundleIDs,
                    this.isTvOS ? "tvOS" : "iOS"
                )
            }
            this.populateHomescreenLayout(<HomescreenLayout>this.homescreenLayout)
            // set initialized to true -> renders html elements inside component
            this.initialized = true
        },
        homescreenLayoutToAppList: function (homescreenLayout: HomescreenLayout): string[] {
            let appList: string[] = []
            homescreenLayout.Pages.forEach(function (homescreenLayoutPage) {
                homescreenLayoutPage.forEach(function (item) {
                    if (item.BundleID) {
                        appList.push(item.BundleID)
                    }
                    if (item.Type == "Folder") {
                        item.Pages?.forEach(function (folderPage) {
                            folderPage.forEach(function (application) {
                                if (application.BundleID) {
                                    appList.push(application.BundleID)
                                }
                            })
                        })
                    }
                })
            })
            homescreenLayout.Dock.forEach(function (item) {
                if (item.BundleID) {
                    appList.push(item.BundleID)
                }
                if (item.Type == "Folder") {
                    item.Pages?.forEach(function (folderPage) {
                        folderPage.forEach(function (application) {
                            if (application.BundleID) {
                                appList.push(application.BundleID)
                            }
                        })
                    })
                }
            })
            return appList
        },
        populateHomescreenLayout: function (this: any, homescreenLayout: HomescreenLayout) {
            let thisComponent = this
            // Handle pages
            homescreenLayout.Pages.forEach(function (homescreenLayoutPage, pageIndex) {
                if (
                    (<HomescreenLayout>thisComponent.homescreenLayout).Pages[pageIndex] == undefined
                ) {
                    ;(<HomescreenLayout>thisComponent.homescreenLayout).Pages.push([])
                }

                homescreenLayoutPage.forEach(function (item: HomescreenLayoutFormItem) {
                    item.randomId = stringHelpers.generateRandomString(12)

                    if (item.BundleID) {
                        thisComponent.addedBundleIds.push(item.BundleID)
                        item.assetInfos = thisComponent.assetInfoMap[item.BundleID] || undefined
                        item.Type = HomescreenLayoutItemType.Application
                        if (!item.DisplayName) {
                            item.DisplayName =
                                thisComponent.assetInfoMap[item.BundleID]?.trackName ||
                                item.BundleID
                        }
                    }

                    if (!item.BundleID && !item.URL && item.Pages) {
                        item.Type = HomescreenLayoutItemType.Folder
                        if (!item.DisplayName) {
                            item.DisplayName = "Folder"
                        }
                        item.Pages?.forEach(function (folderPage) {
                            folderPage.forEach(function (application: HomescreenLayoutFormItem) {
                                application.randomId = stringHelpers.generateRandomString(12)
                                if (application.BundleID) {
                                    thisComponent.addedBundleIds.push(application.BundleID)
                                    application.assetInfos =
                                        thisComponent.assetInfoMap[application.BundleID] ||
                                        undefined
                                    application.Type = HomescreenLayoutItemType.Application
                                    if (!application.DisplayName) {
                                        application.DisplayName =
                                            thisComponent.assetInfoMap[application.BundleID]
                                                ?.trackName || application.BundleID
                                    }
                                }
                                if (application.URL) {
                                    application.Type = HomescreenLayoutItemType.WebClip
                                    let webclipInfos: any = thisComponent.getWebClipByUrl(
                                        application.URL
                                    )
                                    if (webclipInfos) {
                                        application.DisplayName =
                                            webclipInfos.Label || webclipInfos.name
                                        if (webclipInfos["Icon:data"]) {
                                            application.assetInfos = {
                                                artworkUrl60:
                                                    "data:image/png;base64," +
                                                    webclipInfos["Icon:data"]
                                            }
                                        }
                                    }
                                    if (!application.DisplayName) {
                                        application.DisplayName = "WebClip"
                                    }
                                }
                            })
                        })
                    }

                    if (item.URL) {
                        item.Type = HomescreenLayoutItemType.WebClip
                        let webclipInfos: any = thisComponent.getWebClipByUrl(item.URL)
                        if (webclipInfos) {
                            item.DisplayName = webclipInfos.Label || webclipInfos.name
                            if (webclipInfos["Icon:data"]) {
                                item.assetInfos = {
                                    artworkUrl60:
                                        "data:image/png;base64," + webclipInfos["Icon:data"]
                                }
                            }
                        }
                        if (!item.DisplayName) {
                            item.DisplayName = "WebClip"
                        }
                    }
                })
            })

            // Handle dock
            homescreenLayout.Dock.forEach(function (item: HomescreenLayoutFormItem) {
                item.randomId = stringHelpers.generateRandomString(12)

                if (item.BundleID) {
                    thisComponent.addedBundleIds.push(item.BundleID)
                    item.assetInfos = thisComponent.assetInfoMap[item.BundleID] || undefined
                    item.Type = HomescreenLayoutItemType.Application
                    if (!item.DisplayName) {
                        item.DisplayName =
                            thisComponent.assetInfoMap[item.BundleID]?.trackName || item.BundleID
                    }
                }

                if (!item.BundleID && !item.URL && item.Pages) {
                    item.Type = HomescreenLayoutItemType.Folder
                    if (!item.DisplayName) {
                        item.DisplayName = "Folder"
                    }
                    item.Pages?.forEach(function (folderPage) {
                        folderPage.forEach(function (application: HomescreenLayoutFormItem) {
                            application.randomId = stringHelpers.generateRandomString(12)
                            if (application.BundleID) {
                                thisComponent.addedBundleIds.push(application.BundleID)
                                application.assetInfos =
                                    thisComponent.assetInfoMap[application.BundleID] || undefined
                                application.Type = HomescreenLayoutItemType.Application
                                if (!application.DisplayName) {
                                    application.DisplayName =
                                        thisComponent.assetInfoMap[application.BundleID]
                                            ?.trackName || application.BundleID
                                }
                            }
                        })
                    })
                }

                if (item.URL) {
                    item.Type = HomescreenLayoutItemType.WebClip
                    let webclipInfos: any = thisComponent.getWebClipByUrl(item.URL)
                    if (webclipInfos) {
                        item.DisplayName = webclipInfos.Label || webclipInfos.name
                        if (webclipInfos["Icon:data"]) {
                            item.assetInfos = {
                                artworkUrl60: "data:image/png;base64," + webclipInfos["Icon:data"]
                            }
                        }
                    }
                    if (!item.DisplayName) {
                        item.DisplayName = "WebClip"
                    }
                }
            })
        },
        getWebClipByUrl: function (this: any, url: string) {
            return (
                this.apps.find((webclip: any) => {
                    return webclip.platform == "WEBCLIP" && webclip.URL == url
                }) || undefined
            )
        },
        addPage: function (this: any, pages?: HomescreenLayoutPage[]) {
            if (pages != undefined) {
                pages.push([])
            } else {
                this.homescreenLayout.Pages.push([])
            }
        },
        deletePage: function (this: any, pageNumber: number, inArray: HomescreenLayoutPage[]) {
            dialogs.misc.confirmDialog(
                this.activeAccountId,
                T("Delete page"),
                T("Do you really want to delete this page?"),
                () => {
                    inArray[pageNumber].forEach((entry, index) => {
                        if (entry.BundleID) {
                            this.addedBundleIds = this.addedBundleIds.filter(
                                (addedBundleId: string) => {
                                    return addedBundleId != entry.BundleID
                                }
                            )
                        }
                    })
                    inArray.splice(pageNumber, 1)
                    if (inArray.length == 0) {
                        inArray.push([])
                    }
                },
                undefined
            )
        },
        updateAddItemFormAssetInfos: function (this: any, assetInfos: any): void {
            if (assetInfos) {
                this.addItemForm.assetInfos = assetInfos
                this.addItemForm.DisplayName = assetInfos.trackName
            }
        },
        canAdd: function (this: any): boolean {
            let item: HomescreenLayoutItem = this.addItemForm
            let canAdd: boolean = false
            if (
                [
                    "Application",
                    "ApplicationTv",
                    "SystemApplication",
                    "SystemApplicationTv"
                ].indexOf(item.Type) != -1 &&
                item.BundleID &&
                this.addedBundleIds.indexOf(item.BundleID) == -1
            ) {
                canAdd = true
            }
            if (item.Type == "WebClip" && item.URL) {
                canAdd = true
            }
            if (item.Type == "Folder") {
                canAdd = true
            }
            return canAdd
        },
        addItem: function (this: any): void {
            let pageCount: number = (<HomescreenLayout>this.homescreenLayout).Pages.length
            let item: HomescreenLayoutFormItem = jsonHelpers.copyObject(this.addItemForm)
            let type: HomescreenLayoutItemType = item.Type
            item.randomId = stringHelpers.generateRandomString(12)
            if (type == "Folder") {
                if (item.DisplayName == "") {
                    item.DisplayName = T("Folder")
                }
                item.Pages = [[]]
            }
            if (
                <any>type == "SystemApplication" ||
                <any>type == "SystemApplicationTv" ||
                <any>type == "ApplicationTv"
            ) {
                type = item.Type = HomescreenLayoutItemType.Application
            }
            if (type == "Application" && item.BundleID && item.DisplayName == "") {
                item.DisplayName = item.BundleID
            }
            if (type == "Application" && item.BundleID) {
                delete item.URL
                this.addedBundleIds.push(item.BundleID)
            }

            if (type == "WebClip") {
                delete item.BundleID
            }

            if (this.viewFolder.show === true && item.Type !== "Folder") {
                this.viewFolder.folder.Pages[0].push(item)
            } else {
                ;(<HomescreenLayout>this.homescreenLayout).Pages[pageCount - 1].push(item)
            }

            this.addItemForm.BundleID = type == "Application" ? "" : ""
            this.addItemForm.DisplayName = ""
            this.addItemForm.URL = type == "WebClip" ? "" : ""
            this.addItemForm.assetInfos = undefined
            this.addItemForm.Pages = type == "Folder" ? [[]] : undefined

            if (this.$refs.select2Apps) {
                $(this.$refs.select2Apps.$el).val("").trigger("change")
            }
        },
        deleteApplication: function (
            this: any,
            appNumber: number,
            inArray: HomescreenLayoutPage,
            bundleId?: string
        ) {
            const thisComponent = this
            dialogs.misc.confirmDialog(
                this.activeAccountId,
                T("Delete application"),
                T("Do you really want to delete this application?"),
                () => {
                    inArray.splice(appNumber, 1)
                    if (bundleId) {
                        thisComponent.addedBundleIds = thisComponent.addedBundleIds.filter(
                            (addedBundleId: string) => {
                                return addedBundleId != bundleId
                            }
                        )
                    }
                },
                undefined
            )
        },
        onDrag: function (this: any, event: any) {
            let folderPageItemsLimit: number = 16
            let draggingItem: HomescreenLayoutItem = event.draggedContext?.element
            let targetItem: HomescreenLayoutItem = event.relatedContext?.element

            if (draggingItem?.Type == "Application" && targetItem?.Type == "Folder") {
                targetItem.Pages = targetItem.Pages != undefined ? targetItem.Pages : [[]]
                if (
                    targetItem.Pages.length == 0 ||
                    targetItem.Pages[targetItem.Pages.length - 1].length == folderPageItemsLimit
                ) {
                    targetItem.Pages.push([])
                }
                if (this.lastDragOverFolder != undefined) {
                    this.lastDragOverFolder.draggingItemOver = false
                }
                ;(<any>targetItem).draggingItemOver = true

                this.lastDragOverFolder = targetItem
            }

            if (draggingItem?.Type == "Folder") {
                this.draggingFolder = true
            }
        },
        onDrop: function (this: any, event: any) {
            this.draggingFolder = false
            if (this.lastDragOverFolder != undefined) {
                this.lastDragOverFolder.draggingItemOver = false
                this.lastDragOverFolder = undefined
            }
        },
        onAddToFolder: function (this: any, folder: HomescreenLayoutItem, event: any) {
            ;(<any>folder).draggingItemOver = false
            // New item has been added
            // Handle item limit in pages
            let droppedItem = event.item?._underlying_vm_

            if (folder.Type == "Folder") {
                folder.Pages = folder.Pages != undefined ? folder.Pages : [[]]

                // FIX MOVING ITEM FROM FOLDER INTO ITSELF
                let thisFolderPage = folder.Pages[folder.Pages.length - 1]
                if (
                    !thisFolderPage.some((item) => {
                        return (
                            item.Type == "Application" &&
                            droppedItem.Type == "Application" &&
                            item.BundleID == droppedItem.BundleID
                        )
                    })
                ) {
                    thisFolderPage.push(droppedItem)
                }

                let folderPageItemsLimit: number = 16
                // to be safe: loop through all pages and move all items that exceed the folderPageItemsLimit to the last page
                for (let i: number = 0; i < folder.Pages.length; i++) {
                    let thisFolderPage: HomescreenLayoutPage = folder.Pages[i]

                    // get all items that exceed the limit
                    let moveThisItemsToLastPage: HomescreenLayoutItem[] =
                        thisFolderPage.slice(folderPageItemsLimit)
                    // only leave items that dont exceed the limit
                    thisFolderPage = thisFolderPage.slice(0, folderPageItemsLimit)
                    // move all items that exceeded the limit to the last page
                    moveThisItemsToLastPage.forEach(function (item: HomescreenLayoutItem) {
                        folder.Pages = folder.Pages != undefined ? folder.Pages : [[]] // dunno why i need to do this again
                        // add new page if last page has reached the folderPageItemsLimit.
                        if (folder.Pages[folder.Pages.length - 1].length == folderPageItemsLimit) {
                            folder.Pages.push([])
                        }
                        // add item to last page
                        folder.Pages[folder.Pages.length - 1].push(item)
                    })
                    // maybe worky now
                }
            }
        },
        onDragInFolder: function (this: any, event: any) {
            let key =
                event?.relatedContext?.component?.$vnode?.key ||
                event?.relatedContext?.component?._?.vnode?.key
            if (key == "closeFolder") {
                if (
                    (event?.originalEvent?.path &&
                        event?.originalEvent?.path[0]?.classList[0] == "disableOverlay") ||
                    event?.originalEvent?.originalTarget?.classList[0] == "disableOverlay" ||
                    event?.originalEvent?.target?.classList[0] == "disableOverlay"
                ) {
                    this.hideFolder()
                }
            }
        },
        onDragOutOfFolder: function (this: any, event: any) {},
        showFolder: function (this: any, folderItem: HomescreenLayoutItem) {
            if (folderItem.Type == "Folder") {
                this.viewFolder.folder = folderItem
                this.viewFolder.show = true
            }
        },
        hideFolder: function (this: any) {
            this.viewFolder.show = false
        },
        itunesAppSearchOptions: async function (this: any, searchString: string) {
            let thisComponent = this
            let result: Array<selectOption> = []
            let response = await requestHandler.request(
                "GET",
                "https://itunes.apple.com/search?term=" +
                    searchString +
                    "&country=DE&media=software&entity=software,iPadSoftware"
            )
            if (response.results) {
                result = response.results.map((result: any) => {
                    return {
                        id: result.bundleId,
                        text: result.trackName
                    }
                })
            }

            let existingBungleIds: string[] = []
            result = result.filter((item: any) => {
                if (item.id) {
                    existingBungleIds.push(item.id)
                }
                return (
                    item.text.toLowerCase().indexOf(searchString.toLowerCase()) != -1 ||
                    item.id.toLowerCase().indexOf(searchString.toLowerCase()) != -1 ||
                    item.id != undefined
                )
            })
            if (result.length != 0) {
                response.results.forEach((item: any) => {
                    thisComponent.assetInfoMap[item.bundleId] = item
                    if (existingBungleIds.indexOf(item.bundleId) == -1) {
                        result.push({
                            text: item.trackName + " (" + item.bundleId + ")",
                            id: item.bundleId
                        })
                    }
                })
            }

            return result
        },
        itunesTvAppSearchOptions: async function (this: any, searchString: string) {
            let thisComponent = this
            let result: Array<selectOption> = []
            let response = await requestHandler.request(
                "GET",
                "https://itunes.apple.com/search?term=" +
                    searchString +
                    "&country=DE&media=software&entity=tvSoftware"
            )
            if (response.results) {
                result = response.results.map((result: any) => {
                    return {
                        id: result.bundleId,
                        text: result.trackName
                    }
                })
            }

            let existingBungleIds: string[] = []
            result = result.filter((item: any) => {
                if (item.id) {
                    existingBungleIds.push(item.id)
                }
                return (
                    item.text.toLowerCase().indexOf(searchString.toLowerCase()) != -1 ||
                    item.id.toLowerCase().indexOf(searchString.toLowerCase()) != -1 ||
                    item.id != undefined
                )
            })
            if (result.length != 0) {
                response.results.forEach((item: any) => {
                    thisComponent.assetInfoMap[item.bundleId] = item
                    if (existingBungleIds.indexOf(item.bundleId) == -1) {
                        result.push({
                            text: item.trackName + " (" + item.bundleId + ")",
                            id: item.bundleId
                        })
                    }
                })
            }

            return result
        },
        addAllSystemApps: function (this: any) {
            this.iosNativeAppsBundleId.forEach((bundleIdOption: any) => {
                const pageCount: number = (<HomescreenLayout>this.homescreenLayout).Pages.length
                const bundleId = bundleIdOption.id

                const item: HomescreenLayoutFormItem = jsonHelpers.copyObject(this.addItemForm)

                let appInfo = (this.nativeAppInfos || []).find((appInfo: any) => {
                    return appInfo.bundleId == bundleId
                })
                item.BundleID = bundleId
                item.DisplayName = appInfo?.trackName || bundleId
                item.Type = HomescreenLayoutItemType.Application

                if (appInfo != undefined) {
                    item.assetInfos = {
                        artworkUrl60: appInfo.artworkUrl60
                    }
                }

                nextTick(() => {
                    if (this.addedBundleIds.indexOf(bundleId) == -1) {
                        this.addedBundleIds.push(bundleId)
                        if (this.viewFolder.show === true) {
                            this.viewFolder.folder.Pages[0].push(item)
                        } else {
                            ;(<HomescreenLayout>this.homescreenLayout).Pages[pageCount - 1].push(
                                item
                            )
                        }
                    }
                })
            })
        },
        addAllSystemAppsTv: function (this: any) {
            this.tvosNativeAppsBundleId.forEach((bundleIdOption: any) => {
                const pageCount: number = (<HomescreenLayout>this.homescreenLayout).Pages.length
                const bundleId = bundleIdOption.id

                const item: HomescreenLayoutFormItem = jsonHelpers.copyObject(this.addItemForm)

                let appInfo = (this.nativeAppInfosTv || []).find((appInfo: any) => {
                    return appInfo.bundleId == bundleId
                })
                item.BundleID = bundleId
                item.DisplayName = appInfo?.trackName || bundleId
                item.Type = HomescreenLayoutItemType.Application

                if (appInfo != undefined) {
                    item.assetInfos = {
                        artworkUrl60: appInfo.artworkUrl60
                    }
                }

                nextTick(() => {
                    if (this.addedBundleIds.indexOf(bundleId) == -1) {
                        this.addedBundleIds.push(bundleId)
                        if (this.viewFolder.show === true) {
                            this.viewFolder.folder.Pages[0].push(item)
                        } else {
                            ;(<HomescreenLayout>this.homescreenLayout).Pages[pageCount - 1].push(
                                item
                            )
                        }
                    }
                })
            })
        },
        addAllMobSecApps: function (this: any) {
            this.iosAppsBundleId.forEach((bundleIdOption: any) => {
                const pageCount: number = (<HomescreenLayout>this.homescreenLayout).Pages.length
                const bundleId = bundleIdOption.id
                const item: HomescreenLayoutFormItem = jsonHelpers.copyObject(this.addItemForm)

                let appInfo = (this.nativeAppInfos || []).find((appInfo: any) => {
                    return appInfo.bundleId == bundleId
                })

                if (!appInfo) {
                    appInfo = (this.apps || []).find((appInfo: any) => {
                        return (
                            appInfo.bundleId == bundleId || appInfo.uninstallPackageName == bundleId
                        )
                    })
                    if (appInfo != undefined) {
                        appInfo = appInfo.assetInfo
                    }
                }

                item.BundleID = bundleId
                item.DisplayName = bundleIdOption.text.replace(" (" + bundleId + ")", "")
                item.Type = HomescreenLayoutItemType.Application

                if (appInfo) {
                    item.assetInfos = {
                        artworkUrl60: appInfo.artworkUrl60
                    }
                }

                nextTick(() => {
                    if (this.addedBundleIds.indexOf(bundleId) == -1) {
                        this.addedBundleIds.push(bundleId)
                        if (this.viewFolder.show === true) {
                            this.viewFolder.folder.Pages[0].push(item)
                        } else {
                            ;(<HomescreenLayout>this.homescreenLayout).Pages[pageCount - 1].push(
                                item
                            )
                        }
                    }
                })
            })
        }
    },
    beforeCreate(this: any) {},
    created: function (this: any) {},
    mounted: function (this: any) {
        this.init()
    },
    watch: {
        "addItemForm.Type": function (this: any, type: string) {
            this.addItemForm.BundleID = type == "Application" ? "" : ""
            this.addItemForm.DisplayName = ""
            this.addItemForm.URL = type == "WebClip" ? "" : ""
            this.addItemForm.assetInfos = undefined
            this.addItemForm.Pages = type == "Folder" ? [[]] : undefined
            this.addItemForm.webclipId = type == "WebClip" ? "" : ""
            if (this.$refs.select2Apps) {
                $(this.$refs.select2Apps.$el).val("").trigger("change")
            }
        },
        "addItemForm.webclipId": function (this: any, webclipId: string | undefined) {
            if (webclipId) {
                const webclip: any = config.canUseNewObjectType("iosApps")
                    ? products.mobileSecurity.iosApps
                          .useStore?.()
                          .getObjectStoreObject(this.activeAccountId, webclipId)
                    : useStore().getters.getObject({
                          accountId: this.activeAccountId,
                          productType: "mobileSecurity",
                          objectType: "apps",
                          objectId: webclipId
                      })

                if (webclip) {
                    this.addItemForm.URL = webclip.URL
                    this.addItemForm.DisplayName = webclip.Label

                    let webclipInfos: any = this.getWebClipByUrl(webclip.URL)
                    if (webclip.Icon) {
                        this.addItemForm.assetInfos = { artworkUrl60: webclip.Icon }
                    }
                    if (webclipInfos) {
                        this.addItemForm.DisplayName = webclipInfos.Label || webclipInfos.name
                        if (webclipInfos["Icon:data"]) {
                            this.addItemForm.assetInfos = {
                                artworkUrl60: "data:image/png;base64," + webclipInfos["Icon:data"]
                            }
                        }
                    }
                } else {
                    this.addItemForm.webclipId = this.addItemForm.type == "WebClip" ? "" : ""
                }
            }
        },
        "addItemForm.BundleID": function (this: any, bundleId: string) {
            if (bundleId) {
                let appInfos: any = config.canUseNewObjectType("iosApps")
                    ? products.mobileSecurity.iosApps
                          .useStore?.()
                          .getObjectStoreObjects(this.activeAccountId)
                    : useStore().getters.getObjectTypeStore({
                          accountId: this.activeAccountId,
                          objectType: "apps"
                      })?.items || []
                let appInfo = (appInfos || []).filter((appInfo: any) => {
                    return (
                        appInfo.assetInfo?.bundleId == bundleId ||
                        appInfo.uninstallPackageName == bundleId
                    )
                })[0]

                if (!appInfo) {
                    let nativeAppsList = this.nativeAppInfos.concat(this.nativeAppInfosTv) || []

                    appInfo = (nativeAppsList || []).filter((appInfo: any) => {
                        return appInfo.bundleId == bundleId
                    })[0]
                }
                if (!appInfo) {
                    appInfo = this.assetInfoMap[bundleId] || undefined
                }
                if (appInfo) {
                    this.addItemForm.DisplayName =
                        appInfo.assetInfo?.trackName || appInfo.name || appInfo.trackName
                    if (appInfo.assetInfo?.artworkUrl60 || appInfo.artworkUrl60) {
                        this.addItemForm.assetInfos = {
                            artworkUrl60: appInfo.assetInfo?.artworkUrl60 || appInfo.artworkUrl60
                        }
                    }
                } else {
                    this.addItemForm.BundleID = this.addItemForm.type == "Application" ? "" : ""
                }
            } else {
                this.addItemForm.BundleID = this.addItemForm.type == "Application" ? "" : ""
            }
        }
    },
    components: {
        loader: loaderComponent,
        "input-vue-select": inputVueSelectVue,
        "input-text": InputText,
        draggable: VueDraggableNext,
        tooltip: tooltip
    }
}
export default inputAppleHomescreenLayoutComponent
