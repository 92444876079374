import encodingHelpers, {
    type FlagObject,
    type FlagObjectMapInfo
} from "../helpers/helpers.encoding"

class CookieHandler {
    public consentHandler: CookieConsent = new CookieConsent(this)
    public dialogSuspender: DialogSuspender = new DialogSuspender(this)

    set(
        name: string,
        value: string | number,
        days?: number,
        consentCategory?: string,
        force: boolean = false
    ) {
        if (!consentCategory) {
            consentCategory = this.consentHandler.getConsentCategoryFromCookieName(name)
        }

        if (force || this.consentHandler.isAbleToSetCookie(name, consentCategory)) {
            if (days) {
                var date = new Date()
                date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
                var expires = "; expires=" + date.toUTCString()
            } else {
                var expires = ""
            }
            document.cookie = name + "=" + value.toString() + expires + "; path=/"
        }
    }
    get(name: string) {
        var cookieArray = document.cookie.split(";")
        let result = cookieArray.find((thisCookie) => {
            if (thisCookie.charAt(0) == " ") {
                return thisCookie.startsWith(" " + name + "=")
            } else {
                return thisCookie.startsWith(name + "=")
            }
        })
        if (result != undefined) {
            result = result.split("=")[1]
        }
        return result
    }
    delete(name: string) {
        this.set(name, "", -1)
    }
}
class DialogSuspender {
    public suspendedDialogsFlagMap: FlagObjectMapInfo = [
        "exceededLicense",
        "noMobSecSetup",
        "depExpired",
        "vppExpired",
        "rspImport"
    ]

    private cookies: CookieHandler | undefined = undefined
    private suspendedDialogs: FlagObject = {
        exceededLicense: false,
        noMobSecSetup: false,
        depExpired: false,
        vppExpired: false,
        rspImport: false
    }
    constructor(cookies: CookieHandler) {
        this.cookies = cookies
        this.getSuspendedDialogsFromCookie()
    }
    getSuspendedDialogs = () => {
        return this.suspendedDialogs
    }
    getSuspendedDialogsFromCookie = () => {
        const value: number = this.cookies?.get("suspendedDialogs")
            ? Number(this.cookies?.get("suspendedDialogs")) || 0
            : 0
        let suspendedDialogs = encodingHelpers.bitMask.getFlagObjectFromDecimal(
            value,
            this.suspendedDialogsFlagMap
        )
        this.suspendedDialogs = suspendedDialogs
        return suspendedDialogs
    }
    saveSuspendedDialogsToCookie = () => {
        let suspendedDialogs = this.getSuspendedDialogs()
        let decimalValue = encodingHelpers.bitMask
            .getDecimalFromFlagObject(suspendedDialogs, this.suspendedDialogsFlagMap)
            .toString()
        this.cookies?.set("suspendedDialogs", decimalValue, 45)
    }
    setSuspendedDialog = (
        dialogName: (typeof this.suspendedDialogsFlagMap)[number],
        value: boolean
    ) => {
        let suspendedDialogs = this.getSuspendedDialogs()
        suspendedDialogs[dialogName] = value
        this.saveSuspendedDialogsToCookie()
    }
    isDialogSuspended = (dialogName: (typeof this.suspendedDialogsFlagMap)[number]) => {
        return this.getSuspendedDialogs()[dialogName]
    }
}
class CookieConsent {
    initialized: boolean = false
    public consentInfo: any = {
        accepted: false,
        essential: true,
        utility: false
    }
    private cookieToConsent: any = {
        SP_CONSENT: "essential",
        dd93599f: "essential",
        iddqd: "essential",
        hash: "essential",
        gridView: "utility",
        showDetails: "utility",
        search: "utility",
        signedInOnly: "utility",
        darkmode: "utility",
        suspendedDialogs: "utility"
    }
    private consentFlagMap: FlagObjectMapInfo = ["accepted", "essential", "utility"]
    private cookies: CookieHandler | undefined = undefined
    constructor(cookies: CookieHandler) {
        this.cookies = cookies
        this.getInfo()
    }
    getConsentCategoryFromCookieName(name: string) {
        return this.cookieToConsent[name] || undefined
    }
    setInfo(flagObject: FlagObject) {
        let decimal = encodingHelpers.bitMask.getDecimalFromFlagObject(
            flagObject,
            this.consentFlagMap
        )
        try {
            this.cookies?.set("SP_CONSENT", decimal.toString(), 365, undefined, true)
            this.consentInfo = flagObject
        } catch (e: any) {
            return false
        }
    }
    getInfo() {
        let decimal = this.cookies?.get("SP_CONSENT")
        let result = decimal
            ? encodingHelpers.bitMask.getFlagObjectFromDecimal(Number(decimal), this.consentFlagMap)
            : undefined
        if (result) {
            this.consentInfo = result
        }
        return result
    }
    isAbleToSetCookie(cookie: string, consentCategory?: string) {
        if (this.consentInfo.accepted || consentCategory == "essential") {
            if (consentCategory == undefined) {
                consentCategory = this.cookieToConsent[cookie]
            }
            if (consentCategory) {
                return this.consentInfo[consentCategory]
            } else {
                return false
            }
        }
        return false
    }
    shouldShowConsentDialog() {
        return !this.consentInfo.accepted
    }
}

const cookies = new CookieHandler()

export default cookies
