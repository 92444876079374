import { T } from "@/classes/i18n"
import numberHelpers from "@/helpers/helpers.numbers"
import tenantHelpers from "@/helpers/helpers.tenants"
import router from "@/router/router"
import Button from "@/templates/components/button/button"
import moment from "moment"
import IntroductionComponent from "../../../templates/components/introductions/usc-zt-introduction.vue"
import ObjectType, {
    type Label,
    type ObjectTypeObject,
    type ObjectTypePayload
} from "../objectType"

type UTMZeroTouchProfileStatus = {
    value: "UNUSED" | "USED" | "ERROR"
    timestamp: number
    error?: string
    ipAddress?: string
}

export interface TUscZtProfile {
    id: string
    name: string
    serialNumber: string
    utmEnrollmentPin: number
    license: string
    startsOn: number
    expiresOn: number
    status: UTMZeroTouchProfileStatus
}

class UscZtProfile extends ObjectType<TUscZtProfile> {
    private usedStatusSet = new Set(["USED", "ERROR"])

    constructor(payload: ObjectTypePayload<TUscZtProfile>) {
        super(payload)

        this.itemlist.getSortingOptions = () => {
            return [
                {
                    id: "name",
                    text: T("Name")
                },
                {
                    id: "startsOn",
                    text: T("Start Date")
                },
                {
                    id: "expiresOn",
                    text: T("Ablaufdatum")
                },
                {
                    id: "serialNumber",
                    text: T("Serial Number")
                }
            ]
        }

        this.itemlist.getToolbarButtons = (accountId, itemlistComponent) => {
            let thisToolbarEntries = []

            thisToolbarEntries.push({
                icon: "fal fa-plus",
                title: T("Add profile"),
                link: "#add-tenant-" + tenantHelpers.getTenantDomain(accountId) + "-uscZtProfiles",
                id: "uscZtButtonAdd",
                vIf: false
            })

            thisToolbarEntries.push({
                icon: "fal fa-clock",
                id: "showExpiredUscZtProfilesButton",
                title:
                    itemlistComponent.exposed.showExpiredUscZtProfiles.value == true
                        ? T("Abgelaufene Profile ausblenden")
                        : T("Abgelaufene Profile einblenden"),
                onClick: async () => {
                    itemlistComponent.exposed.toggleExpiredUscZtProfiles()
                },
                class:
                    itemlistComponent.exposed.showExpiredUscZtProfiles.value == true ? "active" : ""
            })

            return thisToolbarEntries
        }

        this.itemlistItem.hasCheckbox = () => {
            return true
        }

        this.itemlistItem.getMenuEntries = (accountId, item) => {
            let menuLinks = []
            const tenantDomain = tenantHelpers.getTenantDomain(accountId)

            menuLinks.push(
                new Button({
                    title: T("Edit"),
                    text: T("Edit"),
                    link: "#edit-tenant-" + tenantDomain + "-uscZtProfiles-" + item?.id,
                    icon: "fal fa-edit",
                    disabled: this.usedStatusSet.has(item.status?.value)
                })
            )

            menuLinks.push(
                new Button({
                    title: T("Delete"),
                    text: T("Delete"),
                    onClick: () => {
                        this.dialogs.getDeleteObjectDialog(accountId, item)
                    },
                    icon: "fal fa-trash"
                })
            )
            return menuLinks
        }

        this.itemlistItem.getDetails = (accountId, item) => {
            const defaultItem =
                "<span class='content-placeholder' style='width:" +
                numberHelpers.getRandomArbitrary(50, 250) +
                "px;'></span>"

            const details = []

            details.push({
                iconClass: "fal fa-fw fa-cloud",
                title: T("Start Date"),
                key: T("Start Date"),
                value: item ? moment(item.startsOn * 1000).format("DD.MM.YYYY") : defaultItem
            })

            details.push({
                iconClass: "fal fa-fw fa-cloud",
                title: T("Ablaufdatum"),
                key: T("Ablaufdatum"),
                value: item ? moment(item.expiresOn * 1000).format("DD.MM.YYYY") : defaultItem
            })

            if (this.usedStatusSet.has(item?.status?.value)) {
                details.push({
                    iconClass: "fal fa-fw fa-check",
                    title: T("Retrieved on"),
                    key: T("Retrieved on"),
                    value: item
                        ? moment(item.status.timestamp * 1000).format("DD.MM.YYYY HH:mm:ss")
                        : defaultItem
                })

                details.push({
                    iconClass: "fal fa-fw fa-check",
                    title: T("Retrieved by"),
                    key: T("Retrieved by"),
                    value: item ? item.status.ipAddress : defaultItem
                })
            }

            if (item.status?.value === "ERROR") {
                details.push({
                    iconClass: "fal fa-fw fa-exclamation-triangle",
                    title: T("Error"),
                    key: T("Error"),
                    value: item ? item.status.error : defaultItem
                })
            }

            return details
        }

        this.itemlistItem.onClick = (accountId, item) => {
            router.navigate("#edit-tenant-" + accountId + ".sms-uscZtProfiles-" + item?.id)
        }

        this.itemlistItem.getDisabledState = (accountId, item) => {
            return this.usedStatusSet.has(item.status?.value)
        }

        this.itemlistItem.isClickable = (accountId, item) => {
            return !this.usedStatusSet.has(item.status?.value)
        }
        this.itemlistItem.getLabels = (
            accountId: string,
            item: ObjectTypeObject<TUscZtProfile>
        ) => {
            let result: Label[] = []

            if (this.isObjectExpired(item)) {
                result.push({
                    icon: "fal fa-clock",
                    class: "bg-red",
                    displayType: "label",
                    text: T("Expired"),
                    title: T("Expired")
                })
            }

            if (item?.status?.value === "USED") {
                result.push({
                    icon: "fal fa-check",
                    class: "bg-green",
                    displayType: "label",
                    text: T("Applied"),
                    title: T("Applied")
                })
            }

            if (item?.status?.value === "ERROR") {
                result.push({
                    icon: "fal fa-exclamation-triangle",
                    class: "bg-red",
                    displayType: "label",
                    text: T("Errors when applying"),
                    title: T("A UTM tried to apply this profile but an error occurred.")
                })
            }

            return result
        }

        this.itemlist.getIntroductionComponent = () => {
            return IntroductionComponent
        }
    }

    isObjectExpired(item: ObjectTypeObject<TUscZtProfile>) {
        const expieryDay = typeof item?.expiresOn == "number" ? item.expiresOn * 1000 : undefined
        const today = Date.now()
        return expieryDay && today >= expieryDay
    }
}

const uscZtProfile = new UscZtProfile({
    productType: "unifiedSecurityConsole",
    slug: "uscZtProfiles",
    objectType: "uscZtProfiles",
    hasStore: true,
    appearance: {
        iconClass: "fal fa-box-check",
        text: {
            plural: "Zero-Touch Profiles",
            singular: "Zero-Touch Profile",
            title: "Zero-Touch Profile",
            sidebarName: "Zero-Touch Profile"
        },
        color: "red",
        showInSidebar: true,
        showOnDashboard: true
    },
    objectTypeInfo: {
        primaryKeyProperty: {
            property: "id",
            pathToPrimaryProperty: undefined
        },
        nameProperty: {
            primary: "name",
            pathToPrimaryProperty: undefined,
            secondary: undefined,
            pathToSecondaryProperty: undefined
        }
    },
    apiInfo: {
        url: "/sms-mgt-api/api/2.0",
        getCountGETProperties: "?props[]=null&select=data.count",
        // GET
        getObjectListResponseProperty: "utmZeroTouchProfiles",
        getObjectListMethod: "GET",
        getObjectListPath: "/tenants/{tenantDomain}/utms/zerotouch/profiles",
        // Update
        updateObjectMethod: "PUT",
        updateObjectPath: "/tenants/{tenantDomain}/utms/zerotouch/profiles/{objectId}"
    }
})

export default uscZtProfile
