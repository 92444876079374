import config from "@/classes/config"
import { useGlobalMixin } from "@/mixins/mixins.global"
import { useStore } from "@/store/vuex.store"
import loaderComponent from "./loader.vue"

const googleMapComponent = {
    name: "gmap",
    mixins: [useGlobalMixin()],
    template: `
        <div 
            style="min-height:300px"
        >
        </div>`,
    computed: {
        activeAccountId: function (this: any) {
            return useStore().state.session.activeAccountId || undefined
        },
        isTenantInitialized: function (this: any) {
            return useStore().state.session.accounts[this.activeAccountId].initialized
        },
        mgtApiVersion: function (this: any) {
            return config.mgtApiVersion
        }
    },
    props: {
        entries: {
            required: false,
            default: () => {
                return []
            }
        },
        defaultZoom: {
            required: false,
            default: 5
        },
        entryZoom: {
            required: false,
            default: 15
        }
    },
    data: () => {
        return {
            mapCache: {
                map: undefined,
                entries: <
                    {
                        position: {
                            lat: number
                            lng: number
                            accuracy: number
                        }
                        infoHtml?: string
                    }[]
                >[]
            }
        }
    },
    methods: {
        indexOfEntry(this: any, entryId: string) {
            let result: number = -1
            for (let i: number = 0; this.mapCache.entries.length > i; i++) {
                if (this.mapCache.entries[i].id == entryId) {
                    result = i
                    break
                }
            }
            return result
        },
        async updateMap(this: any) {
            let thisComponent: any = this
            let $deviceLocationMap = $(thisComponent.$el)
            const darkmodeStyles: google.maps.MapTypeStyle[] = [
                { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
                { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
                { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
                {
                    featureType: "administrative.locality",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#d59563" }]
                },
                {
                    featureType: "poi",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#d59563" }]
                },
                {
                    featureType: "poi.park",
                    elementType: "geometry",
                    stylers: [{ color: "#263c3f" }]
                },
                {
                    featureType: "poi.park",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#6b9a76" }]
                },
                {
                    featureType: "road",
                    elementType: "geometry",
                    stylers: [{ color: "#38414e" }]
                },
                {
                    featureType: "road",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#212a37" }]
                },
                {
                    featureType: "road",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#9ca5b3" }]
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry",
                    stylers: [{ color: "#746855" }]
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry.stroke",
                    stylers: [{ color: "#1f2835" }]
                },
                {
                    featureType: "road.highway",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#f3d19c" }]
                },
                {
                    featureType: "transit",
                    elementType: "geometry",
                    stylers: [{ color: "#2f3948" }]
                },
                {
                    featureType: "transit.station",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#d59563" }]
                },
                {
                    featureType: "water",
                    elementType: "geometry",
                    stylers: [{ color: "#17263c" }]
                },
                {
                    featureType: "water",
                    elementType: "labels.text.fill",
                    stylers: [{ color: "#515c6d" }]
                },
                {
                    featureType: "water",
                    elementType: "labels.text.stroke",
                    stylers: [{ color: "#17263c" }]
                }
            ]
            if (!this.mapCache.map || this.mapCache.map.getDiv() != $deviceLocationMap[0]) {
                this.mapCache.map = new google.maps.Map($deviceLocationMap[0], {
                    center: {
                        lat: 51.165691,
                        lng: 10.451526
                    },
                    zoom: thisComponent.defaultZoom,
                    disableDefaultUI: true,
                    scaleControl: true,
                    rotateControl: true,
                    fullscreenControl: true,
                    styles: this.darkmode == "1" ? darkmodeStyles : []
                })
            }

            if (this.entries.length) {
                for (let i: number = 0; this.entries.length > i; i++) {
                    let thisEntry: any = this.entries[i]

                    if (i == 0) {
                        if (thisEntry.position) {
                            this.mapCache.map.setCenter({
                                lat: Number(thisEntry.position.lat),
                                lng: Number(thisEntry.position.lng)
                            })
                            this.mapCache.map.setZoom(thisComponent.entryZoom)
                        }
                    }

                    if (this.indexOfEntry(thisEntry.id) != -1) {
                        let thisEntryIndex = this.indexOfEntry(thisEntry.id)
                        this.mapCache.entries[thisEntryIndex].marker.setPosition({
                            lat: Number(thisEntry.position.lat),
                            lng: Number(thisEntry.position.lng)
                        })
                        this.mapCache.entries[thisEntryIndex].circle.setCenter({
                            lat: Number(thisEntry.position.lat),
                            lng: Number(thisEntry.position.lng)
                        })
                        this.mapCache.entries[thisEntryIndex].info.setContent(thisEntry.infoHtml)
                        this.mapCache.entries[thisEntryIndex].circle.setRadius(
                            thisEntry.position.accuracy != undefined
                                ? thisEntry.position.accuracy > 0
                                    ? thisEntry.position.accuracy
                                    : 200
                                : 0
                        )
                    } else {
                        let newEntryIndex: number = this.mapCache.entries.length
                        this.mapCache.entries.push({
                            id: thisEntry.id,
                            marker: new google.maps.Marker({
                                position: {
                                    lat: Number(thisEntry.position.lat),
                                    lng: Number(thisEntry.position.lng)
                                },
                                map: thisComponent.mapCache.map
                            }),
                            circle: new google.maps.Circle({
                                strokeColor: "#E74C3C",
                                strokeOpacity: 0.6,
                                strokeWeight: 1,
                                fillColor: "#E74C3C",
                                fillOpacity: 0.35,
                                map: thisComponent.mapCache.map,
                                center: {
                                    lat: Number(thisEntry.position.lat),
                                    lng: Number(thisEntry.position.lng)
                                },
                                radius:
                                    thisEntry.position.accuracy != undefined
                                        ? thisEntry.position.accuracy > 0
                                            ? thisEntry.position.accuracy
                                            : 200
                                        : 0
                            }),
                            info: new google.maps.InfoWindow({
                                content: thisEntry.infoHtml
                            })
                        })
                        this.mapCache.entries[newEntryIndex].marker.addListener(
                            "click",
                            function () {
                                thisComponent.mapCache.entries[newEntryIndex].info.open(
                                    thisComponent.mapCache.map,
                                    thisComponent.mapCache.entries[newEntryIndex].marker
                                )
                            }
                        )
                    }
                }
            }
        }
    },
    mounted(this: any) {
        this.updateMap()
    },
    watch: {
        darkmode: function (this: any) {
            this.mapCache.entries = []
            this.mapCache.map = undefined
            this.updateMap()
        },
        entries: {
            handler: function (this: any) {
                this.updateMap()
            },
            deep: true
        }
    },
    components: {
        loader: loaderComponent
    }
}
export default googleMapComponent
