import getterHelpers from "@/helpers/helpers.getters"
import { useGlobalMixin } from "@/mixins/mixins.global"
import { MutationTypes, useStore } from "@/store/vuex.store"
import moment from "moment"
import loaderComponent from "../components/loader.vue"

const utmRestoreBackupDialogComponent = {
    mixins: [useGlobalMixin()],
    name: "utm-restore-backup-dialog",
    template: `
        <div>
            <template v-if="initialized">
                <div class="padding-xs-2">
                
                    <template v-for="error in errors">
                        <p class="label bg-red">
                            <i class="fal fa-fw fa-exclamation"></i> {{ error }}
                        </p>
                    </template>

                    <p>    
                        {{ T('Do you really want to restore this backup?') }}
                    </p>
                    <p>
                        <label class="checkbox toggle">
                            <input type="checkbox" class="toggle" v-model="loadAndBootConfig"><span></span>  {{ T("Load configuration, set as bootconfiguration and reboot.") }}
                        </label>
                    </p>
                </div>
            </template>
            <template v-else>
                <div class="text-center padding-xs-y-8 col-xs">
                    <div class="text-size-3"><loader class="text-size-2 color-red" /></div>
                    <span v-if="loaderInfo"  style="opacity: 0.8;">{{ T(loaderInfo) }}</span>
                </div>
            </template>
        </div>
    `,
    data: () => {
        return {
            initialized: false,
            loaderInfo: false,
            loadAndBootConfig: false,
            errors: []
        }
    },
    computed: {},
    props: {
        properties: {
            required: true,
            default: () => {
                return {
                    callback: undefined
                }
            }
        }
    },
    methods: {
        init: async function (this: any) {
            this.initialized = true
        },
        submit: async function (this: any) {
            this.errors = []
            useStore().getters.getActiveModal(this.activeAccountId).buttons[1].loading = true
            useStore().getters.getActiveModal(this.activeAccountId).buttons[1].disabled = true
            this.properties.callback(this.loadAndBootConfig)
        }
    },
    created: async function (this: any) {
        const thisComponent = this
        useStore().commit(MutationTypes.addSubscriptionHook, {
            accountId: thisComponent.activeAccountId,
            hookKey: "utmPage",
            hookFunction: async function (message: any) {
                if (message.topic == "/usc/utm/" + thisComponent.objectId + "/message") {
                    let jobId: string = message.data?.id
                    let jobType: string = message.data?.type || "spcli"
                    let jobContext: string =
                        message.data?.clientContext
                            .replace(jobType + "-", "")
                            .split("-")
                            .join(" ") || "UNKNOWN"
                    let jobStatus: string = (
                        message.data?.data?.state ||
                        message.data?.data?.hint?.status ||
                        (message.data?.status == 200 ? "OK" : "UNKNOWN") ||
                        "UNKNOWN"
                    ).toUpperCase()
                    let jobStatusText: string =
                        message.data?.data?.message ||
                        message.data?.data?.hint?.message ||
                        message.data?.data?.[0]?.message ||
                        message.data?.data?.payload?.[0]?.message ||
                        ""
                    let jobLastUpdate: number = message.timestamp || moment().unix()
                    let jobIssued: undefined | number = message.data?.data?.start || undefined
                    let jobEnded: undefined | number = message.data?.data?.end || undefined
                    let jobQueue: string = "in"
                    if (
                        message.data?.status == 500 ||
                        message.data?.data.status == 500 ||
                        message.data?.data.status == 403
                    ) {
                        if (
                            jobContext == "system config load" ||
                            jobContext == "system config set"
                        ) {
                            thisComponent.errors.push(jobStatusText)
                            thisComponent.$parent.$parent.modals[0].buttons[1].loading = false
                            thisComponent.$parent.$parent.modals[0].buttons[1].disabled = false
                        }
                    } else if (jobContext == "system config set") {
                        thisComponent.$parent.$parent.modals[0].buttons[1].loading = false
                        thisComponent.$parent.$parent.modals[0].buttons[1].disabled = false
                        getterHelpers.useStore().commit(MutationTypes.removeModal)
                    }
                }
            }
        })
    },
    mounted: function (this: any) {
        this.init()
    },
    watch: {},
    beforeUnmount: function (this: any) {},
    components: {
        loader: loaderComponent
    }
}
export default utmRestoreBackupDialogComponent
