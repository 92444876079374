import config from "@/classes/config"
import cookies from "@/classes/cookieHandler"
import idleTimer from "@/classes/idleTimer"
import axios from "axios"
import apis from "./../classes/apis"

export interface BatchRequestObject {
    config: {
        tenantDomain: string
        path: string
        context: "sms-mgt-api" | "wso2-companion"
        version: string
    }
    parameters: {
        method: "GET" | "POST" | "PUT" | "DELETE"
        props?: string[]
        paging?: {
            limit: number
            offset: number
        }
        fields?: string
        data?: string
        headers?: any
    }
}
export interface BatchRequestsObject {
    [requestName: string]: BatchRequestObject
}

interface BatchRequestObjectWithId extends BatchRequestObject {
    id: number
}
interface BatchRequestPayload {
    requests: BatchRequestObjectWithId[]
}

class Requests {
    constructor() {}
    async request(
        method: "GET" | "POST" | "PUT" | "DELETE",
        url: string,
        data?: any,
        contentType: string = "application/json"
    ) {
        let requestOptions: any = {
            url: url,
            method: method,
            headers: {
                "Content-Type": contentType
            }
        }

        let CSRF_TOKEN = cookies.get("CSRF_TOKEN")
        if (CSRF_TOKEN) {
            requestOptions.headers["X-CSRF-TOKEN"] = CSRF_TOKEN
        }

        if (data) {
            requestOptions.data = data
        }
        try {
            let result = (await axios.request(requestOptions)) as any
            if (url.indexOf("auth") == -1) {
                idleTimer.resetIdleTimer()
            }
            return apis.parseApiResponse(result.data)
        } catch (e: any) {
            throw await Promise.reject(e.response)
        }
    }

    async batchRequests(requestsObject: BatchRequestsObject) {
        const batchLimit: number = 20
        const batchesPayload: Array<BatchRequestPayload> = []
        let result: { [key: string]: any } = {}
        let indexToProperty: { [index: number]: string } = {}

        let allBatches: Array<BatchRequestObjectWithId> = Object.keys(requestsObject).map(
            (objectKey: string, index: number) => {
                result[objectKey] = undefined
                indexToProperty[index] = objectKey
                return {
                    id: index,
                    ...requestsObject[objectKey]
                } as BatchRequestObjectWithId
            }
        )
        for (let i = 0; i < allBatches.length; i += batchLimit) {
            const batch = allBatches.slice(i, i + batchLimit)
            batchesPayload.push({
                requests: batch
            })
        }
        try {
            for (const payload of batchesPayload) {
                const response = await this.request(
                    "POST",
                    config.mgtApiUriNext + "/batch",
                    payload
                )

                if (response?.length > 0) {
                    response.forEach((singleResult: any) => {
                        result[indexToProperty[singleResult.id]] = apis.parseApiResponse(
                            singleResult.body
                        )
                    })
                } else {
                    throw "Missing data in response"
                }
            }
            return result
        } catch (e) {
            console.error(e)
            throw e
        }
    }
}
const requestHandler = new Requests()
export default requestHandler
