import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"

export interface GetEnrollment {
    policyName: string
    tokenId: string
}

export interface CheckinEnrollment {
    redirectURL: string
}

export class CodeEnrollment {
    private accountId: string
    private enrollmentTokenId: string

    constructor(accountId: string, enrollmentTokenId: string) {
        this.accountId = accountId
        this.enrollmentTokenId = enrollmentTokenId
    }

    public async getCheckin(): Promise<GetEnrollment | false> {
        let result: GetEnrollment | false = false
        try {
            const tenantDomain: string = tenantHelpers.getTenantDomain(this.accountId)
            result = await requestHandler.request(
                "GET",
                `/sms-mgt-api/api/2.0/tenants/${tenantDomain}/android/emm/enterprise/enrollmentTokens/${this.enrollmentTokenId}/checkin?frontendDialog=1`
            )
        } catch (e: any) {
            if (e.status === 401) {
                // Handle 401 error, e.g. redirect to an error page
                // app.router.push({'name':'401'});
            } else {
                console.error(e)
            }
            result = false
        }
        return result
    }

    public async checkin(payload: { code: string }): Promise<CheckinEnrollment | false> {
        let result: CheckinEnrollment | false = false
        try {
            const tenantDomain: string = tenantHelpers.getTenantDomain(this.accountId)
            result = await requestHandler.request(
                "POST",
                `/sms-mgt-api/api/2.0/tenants/${tenantDomain}/android/emm/enterprise/enrollmentTokens/${this.enrollmentTokenId}/checkin`,
                payload
            )
        } catch (e: any) {
            if (e.status === 401) {
                // Handle 401 error, e.g. redirect to an error page
                // app.router.push({'name':'401'});
            } else {
                console.error(e)
            }
            result = false
        }
        return result
    }
}

export default CodeEnrollment
