<script setup lang="ts">
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import products from "@/classes/objectTypes"
import type { WindowsEnrollmentToken } from "@/classes/objectTypes/mobileSecurity/windows/windowsEnrollmenttokens"
import { type SendInvitePayload } from "@/classes/objectTypes/mobileSecurity/windows/windowsVpns"
import type { User } from "@/classes/unifiedSecurity/users"
import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"
import { MutationTypes, useStore } from "@/store/vuex.store"
import type Button from "@/templates/components/button/button"
import { computed, onMounted, ref, watchEffect } from "vue"
import loader from "../components/loader.vue"
import inputSelectComponent from "../inputtypes/input-vue-select.vue"

const props = defineProps<{
    properties: {
        token?: WindowsEnrollmentToken
    }
}>()

const errors = ref(<string[]>[])
const activeAccountId = computed(() => {
    return useStore().state.session.activeAccountId || ""
})
const userSelectOptions = computed(() => {
    return (
        products.unifiedSecurity.users.useStore?.().getObjectStoreObjects(activeAccountId.value) ||
        []
    )
        .map((user: User) => {
            return {
                id: user.profile?.email,
                text: user.profile?.email
            } as selectOption
        })
        .filter((selectOption) => {
            if (selectOption.id) return true
            else return false
        })
})

const windowsEnrollmentTokenSelectOptions = computed(() => {
    if (props.properties.token) {
        return [
            {
                id: props.properties.token.id,
                text: props.properties.token.name
            } as selectOption
        ]
    } else {
        return (
            products.unifiedSecurityConsole.windowsEnrollmentTokens
                .useStore?.()
                .getObjectStoreObjects(activeAccountId.value) || []
        )
            .filter((token: WindowsEnrollmentToken) => {
                return token.isExpired == false
            })
            .map((token: WindowsEnrollmentToken) => {
                return {
                    id: token.id,
                    text: token.name
                } as selectOption
            })
    }
})

const selectedUsers = ref(<string[]>[])
const selectedToken = ref("")
const loading = ref(false)
const loaderText = ref("")

onMounted(async () => {
    loading.value = true

    loaderText.value = "Getting Users..."
    await products.unifiedSecurity.users.queries.getObjectsFromApi(activeAccountId.value)

    if (props.properties.token != undefined) {
        selectedToken.value = props.properties.token.id
    } else {
        loaderText.value = "Getting Enrollmenttoken..."
        await products.unifiedSecurityConsole.windowsEnrollmentTokens.queries.getObjectsFromApi(
            activeAccountId.value
        )
    }

    ;(
        getterHelpers.useStore().getters.getActiveModal(activeAccountId.value).buttons[1] as Button
    ).loading = false
    loading.value = false
})

watchEffect(() => {
    if (selectedToken.value !== "" && selectedUsers.value.length > 0) {
        ;(
            getterHelpers.useStore().getters.getActiveModal(activeAccountId.value)
                .buttons[1] as Button
        ).disabled = false
    } else {
        ;(
            getterHelpers.useStore().getters.getActiveModal(activeAccountId.value)
                .buttons[1] as Button
        ).disabled = true
    }
})

async function submit() {
    ;(
        getterHelpers.useStore().getters.getActiveModal(activeAccountId.value).buttons[1] as Button
    ).loading = true
    ;(
        getterHelpers.useStore().getters.getActiveModal(activeAccountId.value).buttons[0] as Button
    ).loading = true
    errors.value = []
    try {
        const payload: SendInvitePayload = {
            enrollmentTokenUUID: selectedToken.value,
            to: selectedUsers.value
        }
        requestHandler.request(
            "POST",
            config.mgtApiUriNext +
                "/tenants/" +
                tenantHelpers.getTenantDomain(activeAccountId.value) +
                "/windows/emails/invite",
            payload
        )
        getterHelpers.useStore().commit(MutationTypes.removeModal)
    } catch (e: unknown) {
        ;(
            getterHelpers.useStore().getters.getActiveModal(activeAccountId.value)
                .buttons[1] as Button
        ).loading = false
        ;(
            getterHelpers.useStore().getters.getActiveModal(activeAccountId.value)
                .buttons[0] as Button
        ).loading = false
        console.error(e)
        if (e instanceof Error) {
            errors.value.push(e.message)
        }
    }
}

defineExpose({
    submit
})
</script>
<template>
    <template v-if="loading == true">
        <div class="text-size-3 text-center padding-xs-t-4">
            <div class="text-size-2">
                <loader class="color-red"></loader>
            </div>
        </div>
        <div class="text-center padding-xs-t-2" style="opacity: 0.8">
            <span>
                {{ T(loaderText) }}
            </span>
        </div>
    </template>
    <template v-else>
        <template v-if="errors.length > 0">
            <div v-for="error of errors" class="alert notification content bg-red text-center">
                {{ T(error) }}
            </div>
            <br />
        </template>

        {{
            T(
                "It is possible to connect new VPN clients to the Unified Security Portal via Email by sending an invitation Email."
            )
        }}
        {{
            T(
                "This invitation email contains a link to log the installed Securepoint VPN Client onto the portal."
            )
        }}
        {{
            T(
                "After clicking on the link, the VPN Client is opened, logged in and is then available in the Unified Security Portal."
            )
        }}
        <br />
        <br />
        <div class="row padding-xs-y form-group border-bottom">
            <div class="first col-xs-24 col-lg-5">
                <label class="control-label inputname">
                    {{ T("xEnrollmenttoken") }}
                </label>
            </div>
            <div class="input col-xs-24 col-lg-10">
                <span class="form-field margin-xs-b-0">
                    <inputSelectComponent
                        v-model="selectedToken"
                        :selectOptions="windowsEnrollmentTokenSelectOptions"
                        :placeholder="T('xEnrollmenttoken')"
                        :disabled="props.properties.token != undefined"
                        :multiple="false"
                        :tags="false"
                        id="enrollmentToken"
                    >
                    </inputSelectComponent>
                </span>
            </div>
            <div class="desc col-xs-24 col-lg-9">
                <p class="input-description">
                    {{
                        T(
                            "Select the Enrollmenttoken that is to be used to register the VPN client. Make sure that the token has a sufficient validity period."
                        )
                    }}
                </p>
            </div>
        </div>

        <div class="row padding-xs-y form-group">
            <div class="first col-xs-24 col-lg-5">
                <label class="control-label inputname">
                    {{ T("Email-Recipients") }}
                </label>
            </div>
            <div class="input col-xs-24 col-lg-10">
                <span class="form-field margin-xs-b-0">
                    <inputSelectComponent
                        v-model="selectedUsers"
                        :selectOptions="userSelectOptions"
                        :placeholder="T('Email-Recipients')"
                        :multiple="true"
                        :tags="true"
                        id="emails"
                    >
                    </inputSelectComponent>
                </span>
            </div>
            <div class="desc col-xs-24 col-lg-9">
                <p class="input-description">
                    {{
                        T(
                            "Please select the Email addresses to which the Enrollmenttoken for enrolling on the portal should be sent by Email."
                        )
                    }}
                </p>
            </div>
        </div>
    </template>
</template>
