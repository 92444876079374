import { useVue } from "@/app"
import { T } from "@/classes/i18n"
import { type WindowsEnrollmentToken } from "@/classes/objectTypes/mobileSecurity/windows/windowsEnrollmenttokens"
import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"
import { MutationTypes, useStore } from "@/store/vuex.store"

const adaptiveSecureConnectDialogs = {
    inviteWindowsVpnClient(accountId: string, token?: WindowsEnrollmentToken) {
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore().getters.hasAccountid(accountId) == false) {
                throw "Wrong accountid, stopping process"
            }
            let modal: any = {
                id: "enrollWindowsVpnClients",
                abortable: true,
                content: {
                    title: {
                        text: T("Enroll VPN clients to the portal via Email invitation"),
                        icon: "fal fa-plus"
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component: "enrollWindowsVpnClients",
                        properties: {
                            token: token
                        }
                    }
                },
                buttons: [
                    {
                        text: T("Cancel"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "left",
                        disabled: false,
                        loading: false
                    },
                    {
                        text: T("Send invitation"),
                        icon: "fal fa-fw fa-envelope",
                        onClick: async function () {
                            useVue().$refs.modals.$refs.modal.$refs.modalComponent.submit()
                        },
                        align: "right",
                        disabled: true,
                        loading: true
                    }
                ]
            }
            useVue().$refs.modals.addModal(modal)
        } catch (e: unknown) {
            console.error(e)
        }
    }
}
export default adaptiveSecureConnectDialogs
