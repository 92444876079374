import { T } from "@/classes/i18n"
import { useGlobalMixin } from "@/mixins/mixins.global"
import { useStore } from "@/store/vuex.store"
import loaderComponent from "../components/loader.vue"
import inputTextComponent from "../inputtypes/input-text"

const linkToIdentityProviderDialogComponent = {
    mixins: [useGlobalMixin()],
    name: "link-to-identityprovider-dialog",
    template: `<div>
        <template v-if="properties.deleteScheduled">
            <p class="notification bg-red">
                {{ T('This process usually takes up to 24 hours') }}
            </p>
        </template>
        {{ T('Enter an Entra ID Tenant-ID or an Entra ID Domain') }} (Optional)
        <input-text 
            v-model="uuidOrDomain"
            placeholder="123456.onmicrosoft.com | 12345678-1234-1234-1234-123456789000"
        ></input-text>
        <br>
        <a class="btn" :href="this.properties.azureUrl + (uuidOrDomain ? ('?domainOrId='+uuidOrDomain) : '')">
            <i class="fab fa-microsoft"></i> ${T("Link to Microsoft Entra ID")}
        </a>
    </div>`,
    data: () => {
        return {
            uuidOrDomain: ""
        }
    },
    computed: {
        routes: function (this: any) {
            return this.$router.options.routes
        }
    },
    props: {
        properties: {
            required: true,
            default: () => {
                return {
                    accountId: undefined,
                    objectId: undefined,
                    azureUrl: undefined,
                    deleteScheduled: false
                }
            }
        }
    },
    methods: {
        submit: function (this: any) {
            let message: string = this.value
            useStore().getters.getActiveModal(this.activeAccountId).buttons[1].onClick(message)
        }
    },
    mounted: function (this: any) {},
    components: {
        loader: loaderComponent,
        "input-text": inputTextComponent
    }
}
export default linkToIdentityProviderDialogComponent
