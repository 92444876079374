const inputTextComponent = {
    name: "input-text",
    template: `
        <input
            type="text"
            v-bind="$attrs"
            v-on="{
                input: event => $emit('update:modelValue', event.target.value)
            }"
            style="width:100%"
        />
    `,
    props: {
        modelValue: null
    }
}
export default inputTextComponent
