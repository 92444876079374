import { useGlobalMixin } from "@/mixins/mixins.global"

const inputManagedPropertyConfiguratorComponent = {
    mixins: [useGlobalMixin()],
    name: "input-managed-property-configurator",
    template: `
    <div>
      <template v-for="field,index in componentValue">
        <div class="row padding-xs-y form-group border-bottom">
          <div class="first col-xs-24 col-lg-10 col-xl-10">
            <input v-on:change="updateField(field,index,parentField)" type="text" :placeholder="T('Property')" v-model="field.property">
          </div>
          <div class="input col-xs-20 col-lg-12 col-xl-12">
            <select placeholder="type" v-model="field.type" :disabled="field.property == '' || null" v-on:change="updateField(field,index,parentField)">
              <option value="none" disabled>{{ T('Choose a type') }}</option>
              <option value="text">{{ T('String') }}</option>
              <option value="number">{{ T('Number/Integer') }}</option>
              <option value="select2">{{ T('Array of strings') }}</option>
              <option value="loop">{{ T('Array of objects') }}</option>
            </select>
          </div>
          <div class="input col-xs-4 col-lg-2 col-xl-2">
            <a style="line-height: 2.5em;" v-on:click="deleteField(index,parentField)"><i class="fal fa-trash-alt"></i></a>
          </div>
        </div>
        <template v-if="field.type == 'loop'">
          <div class="padding-xs-2 padding-xs-l-4">
            <input-managed-property-configurator
              v-model="field.template"
              :value="field.template"
              :parent-field="field"
              :isChild="true"
            />
          </div>
        </template>
      </template>
      <a class="btn margin-xs-t" v-on:click="addField(parentField)"><i class="fal fa-plus"></i>&nbsp;&nbsp;{{ T('Add field') }}</a>
    </div>
        `,
    data: function () {
        return {
            componentValue: []
        }
    },
    props: {
        modelValue: {
            required: false,
            default: function () {
                return []
            }
        },
        isChild: {
            required: false,
            default: false
        },
        "parent-field": {
            required: false,
            default: function () {
                return {}
            }
        }
    },
    methods: {
        addField: function (this: any, parentField: any) {
            let emptyField: any = {
                title: "",
                desc: "",
                id: "",
                placeholder: "",
                property: "",
                type: "none",
                options: [],
                errors: [],
                template: [],
                fields: [],
                visible: true,
                value: null,
                btnIcon: "fal fa-plus",
                btnText: "Add Object",
                loopFieldTitle: "Object"
            }

            if (parentField.type == "loop") {
                for (let fieldIndex in parentField.fields) {
                    parentField.fields[fieldIndex].fields.push(emptyField)
                }
            }
            this.componentValue.push(emptyField)
        },
        updateField: function (this: any, field: any, index: number, parentField: any) {
            if (parentField.type == "loop") {
                for (let fieldIndex in parentField.fields) {
                    parentField.fields[fieldIndex].fields[index].id = field.property
                    parentField.fields[fieldIndex].fields[index].title = field.property
                    parentField.fields[fieldIndex].fields[index].placeholder = field.property
                }
            }
            field.id = field.property
            field.title = field.property
            field.placeholder = field.property
            if (field.type == "select2") {
                field.select2Options = "select2Tags"
                field.multiple = true
                field.desc = "Add some text"
            }

            if (field.type == "text") {
                field.desc = "Enter some text"
            }

            if (field.type == "number") {
                field.desc = "Enter a number"
            }

            if (field.type == "loop") {
                field.desc = "Add an object"
            }
        },
        deleteField: function (this: any, index: number, parentField: any) {
            if (parentField.type == "loop") {
                for (let fieldIndex in parentField.fields) {
                    parentField.fields[fieldIndex].fields.splice(index, 1)
                }
            }
            this.componentValue.splice(index, 1)
        }
    },
    created: function (this: any) {},
    mounted: function (this: any) {
        if (this.modelValue) {
            this.componentValue = this.modelValue
        }
    },
    watch: {
        componentValue: {
            handler: function (this: any, newValue: any) {
                if (this.isChild == true) {
                    this.$emit("update:modelValue", newValue)
                }
            },
            deep: true
        }
    },
    destroyed: function () {}
}
export default inputManagedPropertyConfiguratorComponent
