import miscDialogs from "./dialogs.misc"
import mobileSecurityDialogs from "./dialogs.mobile.security"
import secureDnsDialogs from "./dialogs.secure.dns"
import adaptiveSecureConnectDialogs from "./dialogs.unified.network"
import unifiedReportingDialogs from "./dialogs.unified.reporting"
import unifiedSecurityDialogs from "./dialogs.unified.security"

const dialogs = {
    misc: miscDialogs,
    mobileSecurity: mobileSecurityDialogs,
    unifiedReporting: unifiedReportingDialogs,
    unifiedSecurity: unifiedSecurityDialogs,
    adaptiveSecureConnect: adaptiveSecureConnectDialogs,
    secureDns: secureDnsDialogs
}
export default dialogs
