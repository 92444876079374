import { vueApp } from "@/app"
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import products from "@/classes/objectTypes"
import dialogs from "@/dialogs/dialogs"
import jsonFormHelpers from "@/helpers/helpers.jsonForms"
import stringHelpers from "@/helpers/helpers.strings"
import { useGlobalMixin } from "@/mixins/mixins.global"
import { useStore } from "@/store/vuex.store"
import { nextTick } from "vue"
import { ColorPicker } from "vue3-colorpicker"
import "vue3-colorpicker/style.css"
import devLog from "../../classes/log"
import cronojoblistComponent from "../components/cronojoblist"
import type { TabsEntry } from "../components/tabs.vue"
import tabsComponent from "../components/tabs.vue"
import uscProfilePublishState from "../components/uscProfilePublishState.vue"
import windowsProfilePublishState from "../components/windowsProfilePublishState.vue"
import inputAppleHomescreenLayoutComponent from "./input-applehomescreenlayout-async"
import inputComplexPassword from "./input-complex-password.vue"
import inputCountry from "./input-country.vue"
import inputCronComponent from "./input-cron-async"
import inputDateComponent from "./input-date-async"
import inputDropzone from "./input-dropzone.vue"
import inputGoogleDateComponent from "./input-googledate-async"
import inputGoogleIframeComponent from "./input-googleiframe-async"
import inputHeatSlider from "./input-heat-slider.vue"
import inputHomescreenLayoutComponent from "./input-homescreenlayout/input-homescreenlayout.vue"
import inputIosWallpaperComponent from "./input-ioswallpaper-async"
import inputManagedConfigurationComponent from "./input-managed-configuration-async"
import inputMobileConfigComponent from "./input-mobileconfig-async"
import inputNumberVue from "./input-number.vue"
import inputPin from "./input-pin.vue"
import profileUncInfo from "./input-profile-vpn-config.vue"
import inputText from "./input-text.vue"
import inputTimeRangeComponent from "./input-time-range.vue"
import InputUtmZtLicense from "./input-utm-zt-license.vue"
import inputVueSelectVue from "./input-vue-select.vue"

const jsonFormEntryComponent = {
    name: "jsonform-entry",
    mixins: [useGlobalMixin()],
    data() {
        return {
            refreshFieldsCounter: 0,
            form: <any>undefined,
            devMode: config.devMode
        }
    },
    props: {
        thisField: {
            required: false,
            default: function () {
                return {} as JsonFormShemaObject
            }
        },
        fullJson: {
            required: false,
            default: function () {
                return {} as JsonFormShemaObject
            }
        },
        fields: {
            required: true,
            default: function (this: any) {
                this.refreshFieldsCounter
                return [] as JsonFormShemaObject[]
            }
        },
        "get-title-from": {
            required: false,
            default: undefined
        },
        "get-title-from-alt": {
            required: false,
            default: undefined
        }
    },
    computed: {
        tabs: function (this: any) {
            const thisComponent = this
            if (
                thisComponent.thisField?.type == "tabs" ||
                thisComponent.fields[0]?.type == "tabs"
            ) {
                const tabsField: JsonFormShemaObject =
                    thisComponent.thisField.type == "tabs"
                        ? thisComponent.thisField
                        : thisComponent.thisField.fields[0]

                return (tabsField.fields || [])
                    .map((tab) => {
                        if (
                            thisComponent.areConditionsFulfilled(tab.conditions, tab) &&
                            thisComponent.areConditionsFulfilled(tab.showConditions, tab)
                        ) {
                            return {
                                id: tab.id,
                                disabled: tab.disableConditions
                                    ? !thisComponent.areConditionsFulfilled(
                                          tab.disableConditions,
                                          tab
                                      )
                                    : false,
                                onClick: () => {
                                    thisComponent.setActiveTab(tabsField, tab.id)
                                },
                                hasError: tab.errors
                                    ? tab.errors.length > 0
                                    : false || thisComponent.hasErrorsTab(tab),
                                text: T(tab.title),
                                showHeaderInContent: true,
                                labels: tab.labels?.map((label: any) => {
                                    if (label.text) {
                                        label.text = T(label.text)
                                    }

                                    return label
                                }),
                                icon: tab.icon
                            } as TabsEntry
                        }
                    })
                    .filter((tab) => {
                        if (tab) return tab
                    }) as TabsEntry[]
            }
        }
    },
    template: `
	<div>
		<template v-for="field, fieldIndex in fields">
			<div v-if="field.type == 'tabs'" style="margin-top:-16px;margin-left:-16px; margin-right:-16px">
				<tabs :tabs="tabs" :menuType="'vertical'" :activeTab="field.activeTab">
					<template v-for="tab in field.fields" v-slot:[tab.id]>
						<template v-if="tab.activator">
							<div class="padding-xs-x-2" v-if="tab.activator.visible">
								<div class="row padding-xs-y form-group border-bottom">
									<div class="first col-xs-24 col-lg-6 col-xl-5">
										<label class="control-label inputname" :for="'tab-' + tab.id + '-activator'">
											{{ T(tab.activator.title) }}
										</label>
									</div>
									<div class="input "
										:class="[{ 'col-xs-24 col-lg-8 col-xl-7': tab.activator.desc }, { 'col-xs-24 col-lg-18 col-xl-19': !tab.activator.desc }]">
										<label class="form-field margin-xs-b-0 toggle checkbox"
											:class="'tab-' + tab.id + '-activator'">
											<input type="checkbox" class="toggle" v-model="tab.activator.value">
											<span></span>
										</label>
									</div>
									<div class="desc col-xs-24 col-lg-10 col-xl-12" v-if="tab.activator.desc">
										<p class="input-description" v-html="T(tab.activator.desc)"></p>
									</div>
								</div>
							</div>
						</template>
						<jsonform-entry
							:fields="tab.fields"
							:this-field="tab"
							:key="tab.id"
							:full-json="fullJson"
						/>
						<template v-if="tab.activator">
							<template v-if="!tab.activator.value">
								<div class="overlay hideform"></div>
							</template>
						</template>
					</template>
				</tabs>
			</div>
			<template v-else-if="field.type == 'section'">
				<div :class="[{'hidden' : !areConditionsFulfilled(field.conditions,field) || !areConditionsFulfilled(field.showConditions,field) || field.visible === false }, field.rowClasses]">
					<template v-if="field.showTitle">
						<div class="row" :class="{'border-bottom': field.showBox == false }">
							<div class="col-xs-24">
								<h5 style="margin:24px 0 8px 0">
									{{ T(field.title) }}
								</h5>
							</div>
						</div>
					</template>
					<template v-if="field.desc">
						<div class="row" :class="{'border-bottom': field.showBox == false }">
							<div class="col-xs-24">
								<p class="padding-xs-y-2 input-description">
									{{ T(field.desc) }}
								</p>
							</div>
						</div>
					</template>
					<template v-for="error in field.errors">
						<span class="error-bubble label bg-red margin-xs-t">
							{{ getErrorText(error) }}
						</span>
					</template>
					<template v-if="field.activator">
						<template v-if="field.activator.visible">
							<div class="row padding-xs-y form-group" :class="{'border-bottom': field.showBox == false }">
								<div class="first col-xs-24 col-lg-6 col-xl-5">
									<label
										class="control-label inputname"
										:for="'tab-'+ field.id + '-activator'"
									>
										{{ T(field.activator.title) }}
									</label>
								</div>
								<div class="input " :class="[{'col-xs-24 col-lg-8 col-xl-7':field.activator.desc},{'col-xs-24 col-lg-18 col-xl-19':!field.activator.desc}]">
									<label class="form-field margin-xs-b-0 toggle checkbox" :class="'tab-'+ field.id + '-activator'">
										<input
											type="checkbox"
											class="toggle"
											v-model="field.activator.value"
											:disabled="field.activator.disabled || null"
										>
										<span></span>
									</label>
								</div>
								<div class="desc col-xs-24 col-lg-10 col-xl-12" v-if="field.activator.desc">
									<p
										class="input-description"
										v-html="T(field.activator.desc)"
									></p>
								</div>
							</div>
						</template>
					</template>
					<div style="position:relative">
						<template v-if="field.showBox == true">
							<div class="row">
								<div class="col-xs-24 padding-xs">
									<div class="box-shadow padding-xs-x-2">
										<jsonform-entry
											:fields="field.fields"
											:this-field="field"
											:key="field.id"
											:full-json="fullJson"
										/>
									</div>
								</div>
							</div>
						</template>
						<template v-if="field.showBox == false">
							<jsonform-entry
								:fields="field.fields"
								:this-field="field"
								:key="field.id"
								:full-json="fullJson"
							/>
						</template>
						<template v-if="(field.activator && !field.activator.value) || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false)">
							<div class="overlay hideform"></div>
						</template>
					</div>
				</div>
			</template>
			<template v-else-if="field.type == 'homescreenLayout'">
				<div
					class="row padding-xs-y form-group form-entry-row-search"
					v-if="!field.dev || (field.dev == true && devMode == true)"
					:class="[{'hidden' : !field.visible || !areConditionsFulfilled(field.conditions,field) || !areConditionsFulfilled(field.showConditions,field) }, field.rowClasses]"
				>
					<div class="first col-xs-24">
						<input-applehomescreenlayout 
							:homescreenLayout="field.value" 
						/>
					</div>
				</div>
			</template>

			<template v-else-if="field.type == 'homescreenLayoutNew'">
				<div
					class="row padding-xs-y form-group form-entry-row-search"
					v-if="!field.dev || (field.dev == true && $root.DEV_MODE == true)"
					:class="[{'hidden' : !field.visible || !areConditionsFulfilled(field.conditions,field) || !areConditionsFulfilled(field.showConditions,field) }, field.rowClasses]"
				>
					<div class="first col-xs-24">
						<input-homescreenlayout-new
							v-model="field.value" 
						/>
					</div>
				</div>
			</template>

			<template v-else-if="field.type == 'cronoJobList'">
				<cronojoblist/>
			</template>
			<template v-else-if="field.type == 'uscProfilePublishState'">
				<uscProfilePublishState/>
			</template>
			<template v-else-if="field.type == 'windowsProfilePublishState'">
				<windowsProfilePublishState :form="fullJson" />
			</template>
			<template v-else-if="field.type == 'iosWallpaper'">
				<input-ios-wallpaper
					v-if="areConditionsFulfilled(field.conditions,field) && areConditionsFulfilled(field.showConditions,field)"
					:value="field.value"
					v-model="field.value"
				/>
			</template>
			<template v-else-if="field.type == 'loop'">
				<div
					class="row padding-xs-y form-group form-entry-row-search"
					v-if="!field.dev || (field.dev == true && devMode == true)"
					:class="[{'hidden' : !field.visible || !areConditionsFulfilled(field.conditions,field) || !areConditionsFulfilled(field.showConditions,field) }, field.rowClasses]"
				>
					<div class="first col-xs-24 col-lg-6 col-xl-5">
						<label
							class="control-label inputname"
							:for="'form-'+ fieldIndex + '-' + field.id"
						>
							{{ T(field.title) }}
						</label>
					</div>
					<div class="input col-xs-24 col-lg-8 col-xl-7">
						<a
							class="btn" v-on:click="addLoopField(field)"
							v-if="(field.limit != undefined && field.fields.length < field.limit) || field.limit == undefined"
							:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
						>
							<i v-if="field.btnIcon" :class="field.btnIcon"></i>
							<span>
								{{ T(field.btnText) }}
							</span>
						</a>&nbsp;
					</div>
					<div class="desc col-xs-24 col-lg-10 col-xl-12">
						<p
							v-if="field.desc"
							class="input-description"
							v-html="T(field.desc)"
						></p>
					</div>
				</div>
				<template v-if="field.errors.length">
					<div class="padding-xs">
						<template v-for="error in field.errors">
							<span class="error-bubble label bg-red margin-xs-t">
								{{ getErrorText(error) }}
							</span>
						</template>
					</div>
				</template>
				<div class="padding-xs" v-if="field.fields.length">
					<div class="row padding-xs-0" :style="darkmode == '1' ? 'background:rgba(0,0,0,0.1);' : 'background:rgba(0,0,0,0.05);'">
						<div class="col-xs-24 padding-xs" v-for="loopField,loopFieldIndex in field.fields">
							<div class="box-shadow padding-xs-x" style="border-left: 3px solid rgba(0,0,0,0.1);">
								<div class="padding-xs-y">
									<div class="row" style="user-select: none;">
										<div class="col-xs-16">
											<a :class="{'color-text':darkmode == '0','color-gray':darkmode == '1'}" v-on:click="maximizeLoopField(loopField)">
												<small class="text-bold">
													{{ getLoopFieldTitle(loopField,loopFieldIndex) }}
												</small>
											</a>
										</div>
										<div class="col-xs-8 text-right">
											<a title="${T("Minify")}" v-on:click="toggleLoopFieldMinified(loopField)">
												<i class="fal" :class="[{ 'fa-minus':!loopField.minified }, { 'fa-window-maximize': loopField.minified }]"></i>
											</a>
											&nbsp;
											<a title="${T("Delete")}"  v-on:click="deleteLoopField(field,loopFieldIndex)" v-if="field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : !field.disabled">
												<i class="fal fa-trash"></i>
											</a>
										</div>
									</div>
								</div>
								<div class="loopFieldContent" :class="{'hidden' : loopField.minified}" v-if="!loopField.minified">
									<hr>
									<jsonform-entry
										:key="loopField.randomID"
										:fields="loopField.fields"
										:this-field="loopField"
										:get-title-from="field.getLoopFieldTitleFrom"
										:get-title-from-alt="field.getLoopFieldTitleFromAlt"
										:full-json="fullJson"
									/>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</template>
			<template v-else-if="field.type == 'managedConfiguration'">
				<div
					class="row padding-xs-y form-group form-entry-row-search"
					v-if="!field.dev || (field.dev == true && devMode == true)"
					:class="[{'hidden' : !field.visible || !areConditionsFulfilled(field.conditions,field) || !areConditionsFulfilled(field.showConditions,field) }, field.rowClasses]"
				>
					<div class="first col-xs-24 col-lg-6 col-xl-5">
						<label class="control-label inputname" :for="'form-'+ fieldIndex + '-' + field.id">
								{{ T(field.title) }}
						</label>
					</div>
					<div class="input col-xs-24 col-lg-8 col-xl-7">
						<input-managed-configuration
							:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
							v-model="field.value"
							:field="field"
							:key="field.id"
						/>
					</div>
					<div class="desc col-xs-24 col-lg-10 col-xl-12">
						<p v-if="field.desc" class="input-description" v-html="T(field.desc)"></p>
					</div>
				</div>
				
				<div class="padding-xs" v-if="field.fields.length != 0 && areConditionsFulfilled(field.conditions,field) && areConditionsFulfilled(field.showConditions,field)">
					<div class="row padding-xs" :class="{'bg-lightgray':darkmode != '1'}" :style="darkmode == '1' ? 'background:#2b2e32;' : ''">
						<div class="box bg-white row margin-xs-0">
							<div class="col-xs-24 padding-xs-b">
								<jsonform-entry
									:fields="field.fields"
									:this-field="field"
									:key="field.id"
									:full-json="fullJson"
								/>
							</div>
							<template v-for="error in field.errors">
								<span class="error-bubble label bg-red margin-xs-t">
									{{ getErrorText(error) }}
								</span>
							</template>
						</div>
					</div>
				</div>

			</template>
			<template v-else-if="field.type == 'sunInfo'">
				<profile-unc-info :sun-info="field.value"></profile-unc-info>
			</template>
			<template v-else-if="field && field.type != 'hidden'">
				<div
					class="row padding-xs-y form-group form-entry-row-search border-bottom validation-base"
					v-if="!field.dev || (field.dev == true && devMode == true)"
					:class="[{'hidden' : !field.visible || !areConditionsFulfilled(field.conditions,field) || !areConditionsFulfilled(field.showConditions,field)}, field.rowClasses]"
				>
					<div v-if="field.title" class="first col-xs-24 col-lg-6 col-xl-5">
						<label class="control-label inputname" :for="'form-'+ fieldIndex + '-' + field.id">
							{{ T(field.title) }}
						</label>
					</div>
					<div class="input col-xs-24 col-lg-8 col-xl-7">
						<template v-if="['text','email','search','password','complexPassword'].indexOf(field.type) != -1">
							<label 
								class="form-field margin-xs-b-0" 
								:class="[
									'form-'+ fieldIndex + '-' + field.id,
									field.type, 
									{'checkbox toggle':['toggle','spAppToggle','spLocationAppToggle','spToolboxAppToggle'].indexOf(field.type) != -1},
									{'file':field.type == 'base64LogoChange'},
									{'focus':field.focus == true }
								]"
								:style="field.type == 'password' ? 'display:flex' : ''"
							>
								<template v-if="field.type == 'complexPassword'">
									<input-complex-password
										v-model="field.value"
										:placeholder="T(field.placeholder)"
										:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
										v-on:change="execFunction(field.onchange,null,$event)"
										v-on:keyup="execFunction(field.onKeyUp,null,$event)"
										:style="field.type == 'password' ? 'width: calc(100% - 48px); display: inline-block;' : ''"
										@focus="field.focus = true"
										@blur="field.focus = false"
										:inputMask="field.inputMask"
									/>
								</template>
								<template v-else-if="field.type == 'text'">
									<input-text
										:type="field.showPassword ? 'text' : field.type"
										v-model="field.value"
										:placeholder="T(field.placeholder)"
										:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
										:onchange="execFunction(field.onchange, null, null, field)"
										v-on:change="execFunction(field.onchange,null,$event)"
										v-on:keyup="execFunction(field.onKeyUp,null,$event)"
										:style="field.type == 'password' ? 'width: calc(100% - 48px); display: inline-block;' : ''"
										@focus="field.focus = true"
										@blur="field.focus = false"
										:inputMask="field.inputMask"
									/>
								</template>
								<template v-else>
									<input
										:type="field.showPassword ? 'text' : field.type"
										v-model="field.value"
										:placeholder="T(field.placeholder)"
										:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
										v-on:change="execFunction(field.onchange,null,$event)"
										v-on:keyup="execFunction(field.onKeyUp,null,$event)"
										:style="field.type == 'password' ? 'display: inline-block;' : ''"
										:autocomplete="field.type == 'password' ? 'new-password' : 'off'"
										@focus="field.focus = true"
										@blur="field.focus = false"
									/>
								</template>
								<a
									v-if="field.type == 'password'"
									class="btn btn-primary"
									v-on:click="toggleShowPass(field)"
									:title="field.showPassword ? T('Hide password') : T('Show password')"
								>
									<i
										class="fal"
										:class="{
											'fa-eye':field.showPassword == false,
											'fa-eye-slash':field.showPassword == true,
										}"
									>
									</i>
								</a>
								</label>
							</template>
							<template v-if="['textarea'].indexOf(field.type) != -1">
								<label 
									class="form-field margin-xs-b-0" 
									:class="[
										'form-'+ fieldIndex + '-' + field.id,
										field.type, 
										{'checkbox toggle':['toggle','spAppToggle','spLocationAppToggle','spToolboxAppToggle'].indexOf(field.type) != -1},
										{'file':field.type == 'base64LogoChange'},
										{'focus':field.focus == true }
									]"
								>
								<textarea
									v-model="field.value"
									:placeholder="T(field.placeholder)"
									rows="5"
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									v-on:change="execFunction(field.onchange,null,$event)"
									:max="field.max"
									@focus="field.focus = true"
									@blur="field.focus = false"
								>
								</textarea>
								</label>
							</template>

							<template v-if="['date','dateTime'].indexOf(field.type) != -1">
								<label 
									class="form-field margin-xs-b-0" 
									:class="[
										'form-'+ fieldIndex + '-' + field.id,
										field.type, 
										{'checkbox toggle':['toggle','spAppToggle','spLocationAppToggle','spToolboxAppToggle'].indexOf(field.type) != -1},
										{'file':field.type == 'base64LogoChange'},
										{'focus':field.focus == true }
									]"
								>
								<input-date
									v-model="field.value"
									:value="field.value"
									:placeholder="T(field.placeholder)"
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									v-on:change="execFunction(field.onchange,null,$event)"
									:id="field.id"
									:key="field.id"
									:dateTime="field.type == 'dateTime'"
								/>
								</label>
							</template>

							<template v-if="['googleDate'].indexOf(field.type) != -1">
								<label 
									class="form-field margin-xs-b-0" 
									:class="[
										'form-'+ fieldIndex + '-' + field.id,
										field.type, 
										{'checkbox toggle':['toggle','spAppToggle','spLocationAppToggle','spToolboxAppToggle'].indexOf(field.type) != -1},
										{'file':field.type == 'base64LogoChange'},
										{'focus':field.focus == true }
									]"
								>
								<input-googledate
									v-model="field.value"
									:value="field.value"
									:placeholder="T(field.placeholder)"
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									v-on:change="execFunction(field.onchange,null,$event)"
									:id="field.id"
									:key="field.id"
								/>
								</label>
							</template>

							<template v-if="['number'].indexOf(field.type) != -1">
								<label 
									class="form-field margin-xs-b-0" 
									:class="[
										'form-'+ fieldIndex + '-' + field.id,
										field.type, 
										{'checkbox toggle':['toggle','spAppToggle','spLocationAppToggle','spToolboxAppToggle'].indexOf(field.type) != -1},
										{'file':field.type == 'base64LogoChange'},
										{'focus':field.focus == true }
									]"
								>
									<input-number
										:type="field.type"
										v-model="field.value"
										:placeholder="T(field.placeholder)"
										:max="getConditionalProperty(field.max)"
										:min="getConditionalProperty(field.min)"
										:step="field.step"
										:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
										v-on:change="execFunction(field.onchange,null,$event)"
										@focus="field.focus = true"
										@blur="field.focus = false"
									/>
								</label>
							</template>
							<template v-if="['select'].indexOf(field.type) != -1">
								<label 
									class="form-field margin-xs-b-0" 
									:class="[
										'form-'+ fieldIndex + '-' + field.id,
										field.type, 
										{'checkbox toggle':['toggle','spAppToggle','spLocationAppToggle','spToolboxAppToggle'].indexOf(field.type) != -1},
										{'file':field.type == 'base64LogoChange'},
										{'focus':field.focus == true }
									]"
								>
								<select
									:selectOptions="field.type"
									v-model="field.value"
									:placeholder="T(field.placeholder)"
									v-on:change="execFunction(field.onchange,$event.target,null,field)"
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									@focus="field.focus = true"
									@blur="field.focus = false"
								>
									<option
										v-for="option in field.options"
										:value="option.id"
										:disabled="typeof option.disabled == 'boolean' ? option.disabled : false"
									>
										{{ T(option.text) }}
									</option>
								</select>
								<p class="input-description" v-if="getOptionDescription(field)" v-html="T(getOptionDescription(field))"></p>
								</label>
							</template>
							<template v-if="['select2'].indexOf(field.type) != -1 && field.visible">
								<span 
									class="form-field margin-xs-b-0" 
									:class="[
										'form-'+ fieldIndex + '-' + field.id,
										field.type, 
										{'checkbox toggle':['toggle','spAppToggle','spLocationAppToggle','spToolboxAppToggle'].indexOf(field.type) != -1},
										{'file':field.type == 'base64LogoChange'},
										{'focus':field.focus == true }
									]"
								>


								<input-vue-select
									v-model="field.value"
									:selectOptions="field.options || []"
									:multiple="field.multiple"
									:tags="field.tags"
									:placeholder="T(field.placeholder)"
									:select2Options="field.select2Options"
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									:selectable="field.selectable"
									:id="field.id"
									:key="field.id"
									:noTranslations="field.noTranslations"
									v-on:change="execFunction(field.onchange,undefined,undefined,field)"
									:thisJsonField="field"
									@focus="field.focus = true"
									@blur="field.focus = false"
									:ref="'select-'+field.id"
									:inputMask="field.inputMask"
									:useTwoRows="field.biggerField"
									:searchPlaceholder="field.searchPlaceholder"
									:noSort="field.noSort || false"
								></input-vue-select>

								<template v-if="field.property == 'allowListedAppBundleIDs' || field.property == 'blockedAppBundleIDs'">
									<a
										:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
										class="btn btn-primary"
										v-on:click="addDefaultBundleAppsTo(field)"
									>
										<i class="fal fa-plus"></i> ${T("Add system default apps")}
									</a>
								</template>
		
								<template v-if="field.id == 'uscTenantDomains'">
									<a
										:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
										class="btn btn-primary"
										v-on:click="addAllUscTenants(field)"
									>
										<i class="fal fa-plus"></i> ${T("Select all")}
									</a>
								</template>

								<template v-if='field.selectAll === true'>
									<a
										:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
										class="btn btn-primary"
										v-on:click="select2SelectAll(field)"
									>
										<i class="fal fa-plus"></i> ${T("Select all")}
									</a>
								</template>

								<p class="input-description" v-if="getOptionDescription(field)" v-html="T(getOptionDescription(field))"></p>
								</span>
							</template>
							<template v-if="['toggle'].indexOf(field.type) != -1">
								<label 
									class="form-field margin-xs-b-0" 
									:class="[
										'form-'+ fieldIndex + '-' + field.id,
										field.type, 
										{'checkbox toggle':['toggle','spAppToggle','spLocationAppToggle','spToolboxAppToggle'].indexOf(field.type) != -1},
										{'file':field.type == 'base64LogoChange'},
										{'focus':field.focus == true }
									]"
								>
								<input
									type="checkbox"
									class="toggle"
									v-model="field.value"
									:value="typeof field.value == 'boolean' ? true : 1"
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									v-on:change="execFunction(field.onchange,null,$event)"
								>
								<span></span>
								</label>
							</template>

							<template v-if="['spAppToggle'].indexOf(field.type) != -1">
								<label 
									class="form-field margin-xs-b-0" 
									:class="[
										'form-'+ fieldIndex + '-' + field.id,
										field.type, 
										{'checkbox toggle':['toggle','spAppToggle','spLocationAppToggle','spToolboxAppToggle'].indexOf(field.type) != -1},
										{'file':field.type == 'base64LogoChange'},
										{'focus':field.focus == true }
									]"
								>
								<input
									type="checkbox"
									class="toggle checkbox"
									v-model="field.value"
									value="true"
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									v-on:change="toggleEnterpriseSecurityTab($event)"
								>
								<span></span>
								</label>
							</template>
							<template v-if="['spLocationAppToggle'].indexOf(field.type) != -1">
								<label 
									class="form-field margin-xs-b-0" 
									:class="[
										'form-'+ fieldIndex + '-' + field.id,
										field.type, 
										{'checkbox toggle':['toggle','spAppToggle','spLocationAppToggle','spToolboxAppToggle'].indexOf(field.type) != -1},
										{'file':field.type == 'base64LogoChange'},
										{'focus':field.focus == true }
									]"
								>
								<input
									type="checkbox"
									class="toggle checkbox"
									v-model="field.value"
									value="true"
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									v-on:change="toggleEmmLocation($event)"
								>
								<span></span>
								</label>
							</template>
							<template v-if="['spToolboxAppToggle'].indexOf(field.type) != -1">
								<label 
									class="form-field margin-xs-b-0" 
									:class="[
										'form-'+ fieldIndex + '-' + field.id,
										field.type, 
										{'checkbox toggle':['toggle','spAppToggle','spLocationAppToggle','spToolboxAppToggle'].indexOf(field.type) != -1},
										{'file':field.type == 'base64LogoChange'},
										{'focus':field.focus == true }
									]"
								>
								<input
									type="checkbox"
									class="toggle checkbox"
									v-model="field.value"
									value="true"
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									v-on:change="toggleEnterpriseToolbox($event)"
								>
								<span></span>
								</label>
							</template>

							<template v-if="['button'].indexOf(field.type) != -1">
								<label 
									class="form-field margin-xs-b-0" 
									:class="[
										'form-'+ fieldIndex + '-' + field.id,
										field.type, 
										{'checkbox toggle':['toggle','spAppToggle','spLocationAppToggle','spToolboxAppToggle'].indexOf(field.type) != -1},
										{'file':field.type == 'base64LogoChange'},
										{'focus':field.focus == true }
									]"
								>
								<a
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									class="btn"
									v-on:click="execFunction(field.button.onClick)"
								>
									<i class="fal" :class="field.button.icon"></i> <span>{{ T(field.button.text) }}</span>
								</a>
								</label>
							</template>

							<template v-if="['base64LogoUpload'].indexOf(field.type) != -1">
								<label 
									class="form-field margin-xs-b-0" 
									:class="[
										'form-'+ fieldIndex + '-' + field.id,
										field.type, 
										{'checkbox toggle':['toggle','spAppToggle','spLocationAppToggle','spToolboxAppToggle'].indexOf(field.type) != -1},
										{'file':field.type == 'base64LogoChange'},
										{'focus':field.focus == true }
									]"
								>
								<div :class="{'hidden':field.value != ''}">
									<input style="display:none" type="file" v-on:change="base64LogoChange($event,field)">
									<div class="uploadbox"></div>
								</div>
								<div>
									<a v-if="field.value == ''" class="btn btn-primary"><i class="fal fa-plus"></i> {{ T('Add logo') }}</a>
									<a v-if="field.value != ''" class="btn btn-primary"><i class="fal fa-wrench"></i> {{ T('Change logo') }}</a>
									<a v-if="field.value != ''" class="btn btn-primary" v-on:click.prevent.stop="setFieldValue(field,'')"><i class="fal fa-trash"></i> {{ T('Delete logo') }}</a>
								</div>
								<div :class="{'hidden':field.value == ''}">
									<div :title="T('Change image')" style="cursor: pointer; width: 300px; border: 1px solid rgba(0,0,0,0.1); height: 150px; background-size: auto; background-color:#333; margin-top: 0.5em; background-repeat: no-repeat; background-position: center;" :style="'background-image:url('+field.value+')'"></div>
								</div>
								</label>
							</template>

							<template v-if="['mobileconfigUpload'].indexOf(field.type) != -1">
								<label 
									class="form-field margin-xs-b-0" 
									:class="[
										'form-'+ fieldIndex + '-' + field.id,
										field.type, 
										{'checkbox toggle':['toggle','spAppToggle','spLocationAppToggle','spToolboxAppToggle'].indexOf(field.type) != -1},
										{'file':field.type == 'base64LogoChange'},
										{'focus':field.focus == true }
									]"
								>
								<input-mobileconfig-upload
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									:id="field.id"
									:key="field.id"
									:field="field"
								/>
								</label>
							</template>

							<template v-if="field.type == 'colorPicker'">
								<label 
									class="form-field margin-xs-b-0" 
									:class="[
										'form-'+ fieldIndex + '-' + field.id,
										field.type, 
										{'checkbox toggle':['toggle','spAppToggle','spLocationAppToggle','spToolboxAppToggle'].indexOf(field.type) != -1},
										{'file':field.type == 'base64LogoChange'},
										{'focus':field.focus == true }
									]"
								>
								<colorPicker
									:isWidget="false"
									format="hex"
									shape="square"
									v-model:pureColor="field.value"
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									:id="field.id"
									:key="field.id"
									v-on:change="execFunction(field.onchange,undefined,undefined,field)"
								/>
								</label>
							</template>

							<template v-if="['googleIframe'].indexOf(field.type) != -1">
								<span 
									class="form-field margin-xs-b-0" 
									:class="[
										'form-'+ fieldIndex + '-' + field.id,
										field.type, 
										{'checkbox toggle':['toggle','spAppToggle','spLocationAppToggle','spToolboxAppToggle'].indexOf(field.type) != -1},
										{'file':field.type == 'base64LogoChange'},
										{'focus':field.focus == true }
									]"
								>
								<input-google-iframe
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									v-model="field.value"
									:type="field.googleIframeOptions.type"
									:button="field.googleIframeOptions.button"
									:packageName="field.googleIframeOptions.packageNameFromInput"
									:value="field.value"
									:exception="field.exception"
									:onchange="field.onchange"
								/>
								</span>
							</template>
							
							<template v-if="field.type == 'cron'">
								<input-cron
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									v-model="field.value"
									:value="field.value"
									:onchange="field.onchange"
									:cronOptions="field.cronOptions"
								/>
							</template>

							<template v-if="field.type == 'timerange'">
								<input-time-range
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									v-model="field.value"
									:value="field.value"
									:onchange="field.onchange"
								/>
							</template>

							<template v-if="field.type == 'country'">
								<input-country
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									v-model="field.value"
									:value="field.value"
									:onchange="field.onchange"
								/>
							</template>

                            <template v-if="field.type == 'heatSlider'">
								<input-heat-slider
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									v-model="field.value"
									:value="field.value"
									:onchange="field.onchange"
                                    :min-value="field.minValue"
                                    :max-value="field.maxValue"
                                    :step="field.step"
                                    :unit="field.unit"
                                    :description="field.sliderDescription"
                                    :reverse="field.reverse"
                                    :show-possible-values="field.showPossibleValues"
								/>
							</template>

							<template v-if="field.type == 'pin'">
								<input-pin
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									v-model="field.value"
									:pin-length="field.pinLength"
									:numbers-only="field.numbersOnly"
									:number-and-char="field.numberAndChar"
									:onchange="execFunction(field.onchange, null, null, field)"
								/>
							</template>

							<template v-if="field.type == 'dropzone'">
								<input-dropzone
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									v-model="field.value"
									:drop-text="field.dropText"
									:accept="field.accept"
									:onchange="execFunction(field.onchange,null,$even, field)"
								/>
							</template>

							<template v-if="field.type == 'utmZtLicense'">
								<input-utm-zt-license
									:disabled="field.disabled || (field.disableConditions ? !areConditionsFulfilled(field.disableConditions,field) : false) || null"
									v-model="field.value"
									:drop-text="field.dropText"
									:accept="field.accept"
									:onchange="execFunction(field.onchange,null,$even, field)"
								/>
							</template>


						<template v-for="error in field.errors">
							<span class="error-bubble label bg-red margin-xs-t">
								{{ getErrorText(error) }}
							</span>
						</template>
						<template v-if="field.errorConditions && areConditionsFulfilled(field.disableConditions,field)">
							<template v-for="condition in field.errorConditions">
								<span v-if="!areConditionsFulfilled([condition],field,true)" class="error-bubble label bg-red margin-xs-t" v-html="T(condition.message)">
									
								</span>
							</template>
						</template>
						<template v-if="field.warningConditions">
							<template v-for="condition in field.warningConditions">
								<span v-if="!areConditionsFulfilled([condition],field)" class="error-bubble label bg-yellow margin-xs-t" v-html="T(condition.message)">
									
								</span>
							</template>
						</template>
					</div>
					<div class="desc" :class="[!field.title && !field.type ? 'col-xs-24 col-lg-24 col-xl-24' : 'col-xs-24 col-lg-10 col-xl-12']">
						<p v-if="field.desc" class="input-description" v-html="T(field.desc).replace('{tenantDomain}', this.activeAccountId + '.sms')"></p>
						<a v-if="field.copyToClipboardButton"
						:class="[
							'btn btn-primary',
							{ 'disabled': isCopyToClipboardButtonDisabled() },
						]"
						:disabled = "isCopyToClipboardButtonDisabled() == true ? true : null"
						:title="T(field.copyToClipboardButtonTooltip ? field.copyToClipboardButtonTooltip : 'Copy to clipboard')" 
						v-on:click="copyItemToClipboard">
							{{ T('Copy to clipboard') }}
						</a>
					</div>
				</div>
			</template>
		</template>
	</div>
	`,
    methods: {
        T: T,
        refreshFields: function (this: any) {
            this.refreshFieldsCounter = this.refreshFieldsCounter + 1
        },
        isCopyToClipboardButtonDisabled: function (this: any) {
            let result: boolean = false
            switch (this.activePage) {
                case "windowsEnrollmentTokens":
                    const token = products.unifiedSecurityConsole.windowsEnrollmentTokens
                        .useStore?.()
                        .getObjectStoreObject(this.activeAccountId, this.objectId)
                    if (token) {
                        result = token.isExpired == true
                    }
                    break

                default:
                    result = false
                    break
            }
            return result
        },
        copyItemToClipboard: function (this: any) {
            try {
                //@ts-ignore
                if (
                    config.canUseNewObjectType(this.objectType) &&
                    products[this.productType]?.[this.objectType]?.copyItemToClipboard
                ) {
                    //@ts-ignore
                    products[this.productType][this.objectType].copyItemToClipboard(
                        this.objectId,
                        this.activeAccountId
                    )
                }
            } catch (e: unknown) {
                console.error(e)
            }
        },
        setSecurityDependingOnLicenseFromDevices: function (this: any, anyData?: any) {
            return this.$root.$refs.editpage.setSecurityDependingOnLicenseFromDevices()
        },
        togglePrivacyAllApplications: function (this: any) {
            return this.$root.$refs.editpage.togglePrivacyAllApplications()
        },
        togglePrivacyApplication: function (this: any) {
            return this.$root.$refs.editpage.togglePrivacyApplication()
        },
        toggleLogOnly: function (this: any) {
            return this.$root.$refs.editpage.toggleLogOnly()
        },
        toggleLogAndBlock: function (this: any) {
            return this.$root.$refs.editpage.toggleLogAndBlock()
        },
        setAndroidSecureDnsApp: function (this: any) {
            let appsField = jsonFormHelpers.getFieldById(this.fullJson, "emm_applications")
            if (!appsField) {
                return
            }

            const secDnsActivated = jsonFormHelpers.getFieldById(
                this.fullJson,
                "secureDnsEnabled"
            )?.value
            const androidPackageName = "de.securepoint.quantum_query"

            if (secDnsActivated) {
                this.addLoopField(appsField)
                const newLoopField = appsField.fields?.[0]
                let packageNameField = newLoopField?.fields?.[0]

                if (packageNameField) {
                    packageNameField.value = androidPackageName
                }
            } else {
                appsField.fields = appsField.fields?.filter((field: any) => {
                    return field.fields[0].value !== androidPackageName
                })
            }
        },
        setAndroidSecureDnsProfile: function (this: any) {
            let appsField = jsonFormHelpers.getFieldById(this.fullJson, "emm_applications")
            const configField = jsonFormHelpers.getFieldById(this.fullJson, "secDnsProfile")
            if (!appsField || !configField) {
                return
            }

            const androidPackageName = "de.securepoint.quantum_query"
            for (const field of appsField.fields || []) {
                const packageName = field?.fields?.[0].value
                if (packageName !== androidPackageName) {
                    continue
                }

                let managedConfigField = field?.fields?.[4]
                if (managedConfigField?.fields?.length) {
                    managedConfigField.fields = []
                }

                managedConfigField?.fields?.push({
                    title: "configId",
                    id: "configId",
                    property: "configId",
                    placeholder: "configId",
                    visible: true,
                    multiple: true,
                    type: "text",
                    errors: [],
                    value: configField.value || ""
                } as any)
            }
        },
        onUtmLicenseChange: function (this: any, anyData: any, event: any, field: any) {
            //this is a hack..
            if (field?.errors?.includes("This UTM license is not valid.") && field?.value === "") {
                field.errors = []
            }
        },
        checkEnterpriseProfileName: function (this: any) {
            if (this.activePage == "androidProfiles") {
                let thisProfileNameInput = jsonFormHelpers.getFieldById(
                    this.fullJson as JsonFormShemaObject,
                    "name"
                )
                let thisProfileName = thisProfileNameInput.value

                let profiles = config.canUseNewObjectType("androidProfiles")
                    ? products.mobileSecurity.androidProfiles
                          .useStore?.()
                          .getObjectStoreObjects(this.activeAccountId)
                    : useStore().getters.getObjects({
                          accountId: this.activeAccountId,
                          objectType: "profiles",
                          productType: "mobileSecurity"
                      })
                const profile = profiles.find((profile: any) => {
                    return config.canUseNewObjectType("androidProfiles")
                        ? thisProfileName == profile.profileId
                        : profile.platform == "ANDROID_ENTERPRISE"
                          ? thisProfileName == profile.profileId
                          : false
                })
                this.$root.$refs.editpage.isEnterpriseProfileNameTaken = profile != undefined
            } else {
                this.$root.$refs.editpage.isEnterpriseProfileNameTaken = false
            }
        },
        ensureUpperCase: function (this: any, event: any, a: any, b: any) {
            if (event?.target?.value) {
                event.target.value = event.target.value.toUpperCase()
            }

            if (typeof b?.value === "string") {
                b.value = b.value.toUpperCase()
            }
        },
        setFieldValue: function (this: any, field: any, value: any) {
            if (
                this.activePage == "branding" &&
                (field.id === "logoBody" || field.id === "logo") &&
                value === ""
            ) {
                dialogs.misc.confirmDialog(
                    this.activeAccountId,
                    T("Reset to default"),
                    T("The logo will be resetted to the default Securepoint-Logo"),
                    () => {
                        let defaultField = jsonFormHelpers.getFieldById(
                            this.fullJson as JsonFormShemaObject,
                            "logoDefault"
                        )
                        field.value = defaultField?.value || ""
                    }
                )
            } else {
                field.value = value
            }
        },
        areConditionsFulfilled: function (this: any, conditions: JsonFormCondition[], field?: any) {
            return jsonFormHelpers.areConditionsFulfilled(this, conditions, field)
        },
        getConditionalProperty: function (minValue: any = null) {
            let result: any = minValue
            if (
                minValue != null &&
                (minValue.hasOwnProperty("operator") ||
                    minValue.hasOwnProperty("refId") ||
                    minValue.hasOwnProperty("default"))
            ) {
                result = minValue.default
                /* @ts-ignore */
                let refInput = jsonFormHelpers.getFieldById(
                    this.fullJson as JsonFormShemaObject,
                    minValue.refId
                )
                let refValue: any = null
                switch (minValue.operator) {
                    case "equals":
                        refValue = refInput[minValue.value as keyof typeof refInput]
                        result = refValue || minValue.default
                        break
                    case "largerThan": // integer only
                        refValue = refInput[minValue.value as keyof typeof refInput]
                        result = parseInt(refValue) + 1 || minValue.default
                        break
                    case "smallerThan": // integer only
                        refValue = refInput[minValue.value as keyof typeof refInput]
                        result = parseInt(refValue) - 1 || minValue.default
                        break
                }
            }
            return result
        },
        addDefaultBundleAppsTo: function (this: any, field: any) {
            if (field.select2Options == "select2AppSearchTvOs") {
                for (let i in useStore().state.resources.apple.tvOsNativeApps.apps) {
                    if (
                        !field.options.some((option: any) => {
                            return (
                                option.id ==
                                useStore().state.resources.apple.tvOsNativeApps.apps[i].bundleId
                            )
                        })
                    ) {
                        field.options.push({
                            id: useStore().state.resources.apple.tvOsNativeApps.apps[i].bundleId,
                            text: useStore().state.resources.apple.tvOsNativeApps.apps[i].trackName
                        })
                    }
                    if (
                        field.value.indexOf(
                            useStore().state.resources.apple.tvOsNativeApps.apps[i].bundleId
                        ) == -1
                    ) {
                        field.value.push(
                            useStore().state.resources.apple.tvOsNativeApps.apps[i].bundleId
                        )
                    }
                }
            } else {
                for (let i in useStore().state.resources.apple.defaultBundleIds.bundleIds) {
                    if (
                        !field.options.some((option: any) => {
                            return option.id == useStore().state.resources.apple.defaultBundleIds[i]
                        })
                    ) {
                        field.options.push({
                            id: useStore().state.resources.apple.defaultBundleIds.bundleIds[i],
                            text: useStore().state.resources.apple.defaultBundleIds.bundleIds[i]
                        })
                    }
                    if (
                        field.value.indexOf(
                            useStore().state.resources.apple.defaultBundleIds.bundleIds[i]
                        ) == -1
                    ) {
                        field.value.push(
                            useStore().state.resources.apple.defaultBundleIds.bundleIds[i]
                        )
                    }
                }
            }
            this.$refs["select-" + field.id][0]?.updateModelValue()
            $("#" + field.id).trigger("change")
        },
        addAllUscTenants: function (this: any, field: any) {
            vueApp.$refs.editpage.selectOptions.childTenantsWithUtms.forEach((option: any) => {
                if (field.value.indexOf(option.id) == -1) {
                    field.value.push(option.id)
                }
            })
            this.$refs["select-" + field.id][0]?.updateModelValue()
        },
        select2SelectAll: function (this: any, field: any) {
            const ignoredIds = ["Location"]

            field.options.forEach((option: any) => {
                const { id } = option
                if (ignoredIds.includes(id)) {
                    return
                }

                field.value.push(id)
            })

            this.$refs["select-" + field.id][0]?.updateModelValue()
        },
        // SELECT / SELECT2
        select2Tags: function () {
            return {
                tags: true,
                allowClear: true
            }
        },
        select2IpMask: function (select: any) {
            return {
                tags: true,
                createTag: function (params: any) {
                    var $el = select.parent().find(".select2-search__field")
                    if ($el.inputmask("isComplete")) {
                        return {
                            id: params.term,
                            text: params.term
                        }
                    }
                },
                dropdownCss: { display: "none" }
            }
        },
        ipMaskTrigger: function (select: any) {
            select
                .on("select2:open", function () {
                    select.parent().find(".select2-search__field").inputmask({
                        alias: "ipcidr",
                        greedy: false
                    })
                })
                .trigger("change")
        },
        toggleShowPass: function (this: any, field: any) {
            field.showPassword = !field.showPassword
        },
        toBase64: function (file: any) {
            return new Promise((resolve: any, reject: any) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result)
                reader.onerror = (error) => reject(error)
            })
        },
        readFileContents: function (file: any) {
            return new Promise((resolve: any, reject: any) => {
                const reader = new FileReader()
                reader.readAsText(file)
                reader.onload = () => resolve(reader.result)
                reader.onerror = (error) => reject(error)
            })
        },
        base64LogoChange: async function (this: any, event: any, field: JsonFormShemaObject) {
            field.errors = []
            let file: any = event.target.files ? event.target.files[0] : ""
            if (file && ["image/png", "image/jpg", "image/jpeg"].indexOf(file?.type) == -1) {
                field.errors?.push(T("Wrong filetype") + ": " + file.type)
                file = ""
            }
            field.value = file ? await this.toBase64(file) : ""
        },
        // TABS
        setActiveTab: function (this: any, field: any, tabId: any) {
            field.activeTab = tabId
        },
        execFunction: function (
            this: any,
            functionName: string,
            el: any = null,
            $event: any = null,
            field: JsonFormShemaObject | null = null
        ) {
            if (functionName) {
                let initialFunctionName = functionName
                let ANDIndex = functionName.indexOf("&&")
                let nextFunctionName: string | undefined = undefined
                if (ANDIndex != -1) {
                    functionName = functionName.substring(0, ANDIndex)
                    nextFunctionName = initialFunctionName.substring(
                        ANDIndex + 2,
                        initialFunctionName.length
                    )
                }
                if (el != null && $event != null && field != null) {
                    this[functionName](el, $event, field)
                } else if (el != null && field != null) {
                    this[functionName](el, null, field)
                } else if ($event != null && field != null) {
                    this[functionName](null, $event, field)
                } else if (el == null && $event == null && field != null) {
                    this[functionName](null, null, field)
                } else if (el != null && $event != null) {
                    this[functionName](el, $event)
                } else if (el != null) {
                    this[functionName](el)
                } else if ($event != null) {
                    this[functionName]($event)
                } else if (field != null) {
                    this[functionName](field)
                } else {
                    this[functionName]()
                }
                if (nextFunctionName != undefined) {
                    this.execFunction(nextFunctionName, el, $event, field)
                }
            }
        },
        updateZeroTouchDeviceOptions: function (
            this: any,
            el: any = null,
            $event: any = null,
            field: JsonFormShemaObject
        ) {
            if (field) {
                let customerId = field.value
                let devicesField: any = jsonFormHelpers.getFieldById(this.fullJson, "devices")
                devicesField.type = "text"
                devicesField.options.forEach((option: any) => {
                    option.disabled = true
                    let thisDevice = config.canUseNewObjectType("androidDevices")
                        ? products.mobileSecurity.androidDevices
                              .useStore?.()
                              .getObjectStoreObject(this.activeAccountId, option.deviceId)
                        : useStore().getters.getObject({
                              accountId: this.activeAccountId,
                              productType: "mobileSecurity",
                              objectType: "zeroTouchDevices",
                              objectId: option.deviceId
                          })
                    if (thisDevice && customerId && thisDevice?.name.split("/")[1] == customerId) {
                        option.disabled = false
                    }
                })
                nextTick(() => {
                    devicesField.type = "select2"
                })
            }
        },
        // LOOP FIELDS
        addLoopField: function (this: any, field: JsonFormShemaObject) {
            let stringFields: any = JSON.stringify(field.template)
            stringFields = stringFields.replace(/{index}/g, (+new Date()).toString(36).slice(-5))
            stringFields = stringFields.replace(/{childIndex}/g, "{index}")
            stringFields = stringFields.replace(/{childChildIndex}/g, "{childIndex}")
            stringFields = stringFields.replace(/{childChildChildIndex}/g, "{childChildIndex}")

            let newFields: any = this.manipulateLoopFields(JSON.parse(stringFields))

            let loopFieldObject: any = {
                randomID: (+new Date()).toString(36).slice(-5),
                type: "loopentry",
                minified: false,
                title: field.loopFieldTitle,
                fields: newFields
            }

            field.fields?.splice(0, 0, loopFieldObject)

            if (field.id == "permissionGrantsApp") {
                let $event: any = {
                    target: {
                        type: "loop",
                        value: "add"
                    }
                }
                this.updatePermissions(null, $event, field)
            }
        },
        manipulateLoopFields: function (this: any, loopFields: JsonFormShemaObject[]) {
            for (let i in loopFields) {
                let field = loopFields[i]
                if (field.fields) {
                    field.fields = this.manipulateLoopFields(field.fields)
                }
                if (field.manipulateOnInsert) {
                    switch (field.manipulateOnInsert) {
                        case "addUUIDToValue":
                            field.value = stringHelpers.generateUUID()
                            break
                    }
                }
            }
            return loopFields
        },
        deleteLoopField: function (this: any, field: JsonFormShemaObject, loopFieldIndex: number) {
            field.fields?.splice(loopFieldIndex, 1)
            if (field.ondelete) {
                this.execFunction(field.ondelete, null, null, field)
            }
        },
        updatePermissions: function (this: any, el: any, $event: any, field: JsonFormShemaObject) {
            let thisComponent: any = this
            let allPermissionOptions: any = []
            let allPermissions: any = []
            let selectedPermissions: any = []

            if (field.id == "permission") {
                // get loopfields if field is single loop
                let fieldIndex: any = null
                for (let i in this.$parent.thisField.fields as JsonFormShemaObject[]) {
                    if (this.$parent.thisField.fields[i].id == "permissionGrantsApp") {
                        field = this.$parent.thisField.fields[i] as JsonFormShemaObject
                        break
                    }
                }
                // get permissions
                allPermissionOptions = this.$parent.thisField.fields[0].permissions
            } else {
                // get permissions
                allPermissionOptions = this.thisField.fields[0].permissions
            }

            for (let i in allPermissionOptions) {
                allPermissions.push(allPermissionOptions[i].id)
            }

            // Get selected permissions
            for (let i in field.fields) {
                if (field.fields?.[Number(i)].fields?.[0].value) {
                    selectedPermissions.push(field.fields?.[Number(i)].fields?.[0].value)
                }
            }
            if (selectedPermissions.length) {
                for (let i in field.fields) {
                    let thisOptions: any = []
                    for (let permissionIndex in allPermissions) {
                        //@ts-ignore
                        if (
                            selectedPermissions.indexOf(allPermissions[permissionIndex]) == -1 ||
                            field.fields[i].fields[0].value == allPermissions[permissionIndex]
                        ) {
                            thisOptions.push({
                                id: allPermissions[permissionIndex],
                                text: allPermissions[permissionIndex]
                            })
                        }
                    }
                    //@ts-ignore
                    field.fields[i].fields[0].options = thisOptions
                }
            }
        },
        toggleLoopFieldMinified: function (this: any, loopField: JsonFormShemaObject) {
            loopField.minified = !loopField.minified
        },
        maximizeLoopField: function (this: any, loopField: JsonFormShemaObject) {
            loopField.minified = false
        },
        getPackageNameFromInput: function (this: any, inputId: any) {
            let result: any = null
            if (inputId) {
                result = jsonFormHelpers.getFieldById(this.fullJson, inputId)
            }
            return result
        },
        getLoopFieldTitle: function (
            this: any,
            loopField: JsonFormShemaObject,
            loopFieldIndex: any
        ) {
            if (loopField.title == "Application") {
                return (
                    loopField.fields?.[0].appTitle ||
                    loopField.fields?.[0].value ||
                    T(loopField.title)
                )
            } else {
                return T(loopField.title)
            }
        },
        hasErrorsTab: function (this: any, formObj: JsonFormShemaObject) {
            let result: boolean = false
            for (let i in formObj.fields) {
                let field = formObj.fields?.[Number(i)]
                if (field.errors) {
                    if (field.errors.length) {
                        result = true
                        break
                    }
                }
                if (field.fields) {
                    if (this.hasErrorsTab(field)) {
                        result = true
                        break
                    }
                }
            }
            return result
        },

        toggleEnterpriseSecurityTab: function (this: any, $event: any = null) {
            let doAnything: boolean = false
            let hasApp: boolean = false
            let shouldInstall: boolean = true
            let appsField = jsonFormHelpers.getFieldById(this.fullJson, "emm_applications")
            let currentAppIndex: number = -1
            let appsCount: number = appsField.fields?.length || 0

            hasApp =
                (appsField.fields?.filter((application: any, index: number) => {
                    if (
                        application.fields[0].property == "packageName" &&
                        application.fields[0].value == "de.securepoint.ms.agent"
                    ) {
                        currentAppIndex = index
                        devLog.log(
                            "[jsonform]",
                            "packagename de.securepoint.ms.agent found. Index: " + index
                        )
                        return true
                    }
                }).length || 0) > 0

            if ($event?.target?.type == "checkbox" || $event?.target?.type == "change") {
                if ($event.target.checked) {
                    shouldInstall = true
                    doAnything = true
                }
            } else if (
                $event?.target?.type == "appSearch" &&
                $event.target.value == "de.securepoint.ms.agent"
            ) {
                doAnything = false
                shouldInstall = true
                hasApp = true
            } else if ($event?.target?.type == "loop" && $event.target.value == "delete") {
                if (!hasApp) {
                    doAnything = true
                    shouldInstall = false
                }
            }

            if (!hasApp && event == null) {
                shouldInstall = false
                doAnything = true
            }

            if (doAnything && appsField) {
                let addToggle: any = jsonFormHelpers.getFieldById(
                    this.fullJson,
                    "toggleEnterpriseSecuritySection"
                )
                let enterpriseSecuritySection: any = jsonFormHelpers.getFieldById(
                    this.fullJson,
                    "section_emm_security"
                )

                if (hasApp && !shouldInstall) {
                    enterpriseSecuritySection.activator.value = false
                    addToggle.value = false
                } else if (!hasApp && shouldInstall) {
                    this.addLoopField(appsField)
                    // set packageName
                    let loopfield = appsField.fields?.[0]
                    let packagenameField = loopfield?.fields?.[0]
                    if (packagenameField) {
                        packagenameField.value = "de.securepoint.ms.agent"
                        devLog.log("[jsonForm]", "Added Securepoint Mobile Security app")
                    } else {
                        devLog.log(
                            "[jsonForm]",
                            "Error adding Securepoint Mobile Security app",
                            undefined,
                            "error"
                        )
                    }

                    if (loopfield?.fields?.[1]) {
                        loopfield.fields[1].value = "REQUIRED_FOR_SETUP"
                    }

                    enterpriseSecuritySection.activator.value = true
                    addToggle.value = true
                } else if (!hasApp && !shouldInstall) {
                    enterpriseSecuritySection.activator.value = false
                    addToggle.value = false
                } else {
                    enterpriseSecuritySection.activator.value = true
                    addToggle.value = true
                }
            }
        },
        toggleEmmLocation: function (this: any, $event: any = null) {
            const appsField: any = jsonFormHelpers.getFieldById(this.fullJson, "emm_applications")
            const statusReportingField: any = jsonFormHelpers.getFieldById(
                this.fullJson,
                "statusReportingSettings"
            )
            const applicationReportingField: any = jsonFormHelpers.getFieldById(
                this.fullJson,
                "applicationReportsEnabled"
            )
            const locationModeField: any = jsonFormHelpers.getFieldById(
                this.fullJson,
                "locationMode"
            )
            const locatingToggleField: any = jsonFormHelpers.getFieldById(
                this.fullJson,
                "toggleLocationPlugin"
            )

            const hasToolbox: boolean = appsField.fields.some((application: any) => {
                return application.fields[0].value == "de.securepoint.emm.toolbox"
            })
            const hasToolboxLocation: boolean = appsField.fields.some((application: any) => {
                return application.fields[0].value == "de.securepoint.emm.toolbox.location"
            })

            const isStatusReportingActive: boolean = statusReportingField?.activator?.value || false
            const isApplicationReportingActive: boolean = applicationReportingField?.value || false
            const isLocationModeEnforced: boolean = locationModeField?.value == "LOCATION_ENFORCED"

            const isEverythingSet: boolean =
                hasToolbox &&
                hasToolboxLocation &&
                isStatusReportingActive &&
                isApplicationReportingActive &&
                isLocationModeEnforced

            const isToggleActive: boolean = locatingToggleField?.value || false

            if (!isToggleActive && isEverythingSet) {
                locatingToggleField.value = true
            }
            if (isToggleActive && !isEverythingSet) {
                locatingToggleField.value = false
            }

            // check if emmLocation has been toggled
            if (
                ($event?.target?.type == "checkbox" || $event?.target?.type == "change") &&
                $event?.target?.labels?.[0].className.indexOf("spLocationAppToggle") != -1
            ) {
                if ($event.target.checked === true && !isEverythingSet) {
                    // emmLocation has been toggled from false to true
                    if (!hasToolbox) {
                        this.addLoopField(appsField)
                        appsField.fields[0].fields[0].value = "de.securepoint.emm.toolbox"
                        appsField.fields[0].fields[1].value = "FORCE_INSTALLED"
                        appsField.fields[0].fields[2].value = "GRANT"
                        devLog.log("[jsonForm]", "Added de.securepoint.emm.toolbox")
                    }
                    if (!hasToolboxLocation) {
                        this.addLoopField(appsField)
                        appsField.fields[0].fields[0].value = "de.securepoint.emm.toolbox.location"
                        appsField.fields[0].fields[1].value = "FORCE_INSTALLED"
                        appsField.fields[0].fields[2].value = "GRANT"
                        devLog.log("[jsonForm]", "Added de.securepoint.emm.toolbox.location")
                    }

                    statusReportingField.activator.value = true
                    applicationReportingField.value = true
                    locatingToggleField.value = true

                    locationModeField.value = "LOCATION_ENFORCED"
                } else if ($event.target.checked === false && hasToolboxLocation) {
                    appsField.fields = appsField.fields.filter((field: any) => {
                        return field.fields[0].value != "de.securepoint.emm.toolbox.location"
                    })
                    locatingToggleField.value = false
                }
            }
        },
        toggleEnterpriseToolbox: function (this: any, $event: any = null) {
            const appsField: any = jsonFormHelpers.getFieldById(this.fullJson, "emm_applications")
            const toolboxToggleField: any = jsonFormHelpers.getFieldById(
                this.fullJson,
                "toggleToolboxPlugin"
            )
            const hasToolbox: boolean = appsField.fields.some((application: any) => {
                return application.fields[0].value == "de.securepoint.emm.toolbox"
            })
            const isEverythingSet: boolean = hasToolbox
            const isToggleActive: boolean = toolboxToggleField?.value || false

            if (!isToggleActive && isEverythingSet) {
                toolboxToggleField.value = true
            }
            if (isToggleActive && !isEverythingSet) {
                toolboxToggleField.value = false
            }

            // check if EnterpriseToolbox has been toggled
            if ($event?.target?.type == "checkbox" || $event?.target?.type == "change") {
                if ($event.target.checked === true && !isEverythingSet) {
                    // EnterpriseToolbox has been toggled from false to true
                    if (!hasToolbox) {
                        this.addLoopField(appsField)
                        appsField.fields[0].fields[0].value = "de.securepoint.emm.toolbox"
                        appsField.fields[0].fields[1].value = "FORCE_INSTALLED"
                        appsField.fields[0].fields[2].value = "GRANT"
                        devLog.log("[jsonForm]", "Added de.securepoint.emm.toolbox")
                        toolboxToggleField.value = true
                    }
                }
            }
        },
        getOptionDescription: function (this: any, field: any) {
            let result: any = null
            if (field.value !== undefined && field.value !== null) {
                for (let i in field.options) {
                    let id = field.options[i].id
                    if (id == field.value && field.options[i].desc != undefined) {
                        result = field.options[i].desc
                    }
                }
            }
            return result
        },
        getErrorText: (errorMessage: string) => {
            return T(
                errorMessage.replace("is not an allowed value.", T("is not an allowed value."))
            )
        }
    },
    created: async function (this: any) {
        if (this.thisField) {
            if (this.thisField.type == "loopentry") {
                if (this.getTitleFrom) {
                    for (let i in this.thisField.fields) {
                        let field: any = this.thisField.fields[i]
                        if (
                            (field.property == this.getTitleFrom ||
                                field.id == this.getTitleFrom) &&
                            field.value
                        ) {
                            if (field.value) {
                                this.thisField.title = field.value
                            }
                        }
                    }
                }
                if (!this.thisField.title && this.getTitleFromAlt) {
                    for (let i in this.thisField.fields) {
                        let field: any = this.thisField.fields[i]
                        if (
                            (field.property == this.getTitleFromAlt ||
                                field.id == this.getTitleFromAlt) &&
                            field.value
                        ) {
                            if (field.value) {
                                this.thisField.title = field.value
                            }
                        }
                    }
                }

                if (
                    this.thisField.fields &&
                    this.thisField.fields[0].property == "permission" &&
                    this.thisField.fields[0].options == "appPermissions" &&
                    this.$parent.thisField.fields[0].permissions
                ) {
                    let appPermissions: any = JSON.parse(
                        JSON.stringify(this.$parent.thisField.fields[0].permissions)
                    )
                    this.thisField.fields[0].options = appPermissions
                }
            }
        }
    },
    mounted: function (this: any) {
        if (this.thisField?.id == "tab_enterprise_toolbox_location") {
            this.toggleEmmLocation()
        }
    },
    components: {
        "input-applehomescreenlayout": inputAppleHomescreenLayoutComponent,
        "input-homescreenlayout-new": inputHomescreenLayoutComponent,
        "input-ios-wallpaper": inputIosWallpaperComponent,
        "input-googledate": inputGoogleDateComponent,
        "input-date": inputDateComponent,
        "input-number": inputNumberVue,
        "input-google-iframe": inputGoogleIframeComponent,
        "jsonform-entry": <any>undefined,
        "input-mobileconfig-upload": inputMobileConfigComponent,
        "input-managed-configuration": inputManagedConfigurationComponent,
        "input-cron": inputCronComponent,
        cronojoblist: cronojoblistComponent,
        colorPicker: ColorPicker,
        "input-vue-select": inputVueSelectVue,
        "input-text": inputText,
        uscProfilePublishState: uscProfilePublishState,
        windowsProfilePublishState: windowsProfilePublishState,
        "input-complex-password": inputComplexPassword,
        tabs: tabsComponent,
        "input-time-range": inputTimeRangeComponent,
        "input-pin": inputPin,
        "input-dropzone": inputDropzone,
        "input-country": inputCountry,
        "profile-unc-info": profileUncInfo,
        "input-utm-zt-license": InputUtmZtLicense,
        "input-heat-slider": inputHeatSlider
    }
}
jsonFormEntryComponent.components["jsonform-entry"] = jsonFormEntryComponent
export default jsonFormEntryComponent
