<template>
    <inputSelect v-model="selectedCountries" :selectOptions="allCountries" :multiple="true" />

    <highcharts
        class="highcharts-nobg highcharts-gauge map"
        :constructorType="'mapChart'"
        :options="worldMap"
    />
</template>

<script setup lang="ts">
import { T } from "@/classes/i18n"
import worldGeo from "@highcharts/map-collection/custom/world.geo.json"
import { computed, onMounted, ref, watch } from "vue"
import inputSelect from "./input-vue-select.vue"

const props = defineProps<{
    modelValue: string[]
}>()

const emit = defineEmits(["update:modelValue"])

const selectedCountries = ref<string[]>([])

const allCountries = computed(() => {
    return worldGeo.features.reduce(
        (acc, feature) => {
            if (feature.properties && feature.properties.name) {
                const countryIso = feature.properties["hc-key"]

                acc.push({
                    id: countryIso,
                    text: T(feature.properties.name),
                    icon: `fi fi-${countryIso}`
                })
            }

            return acc
        },
        [] as {
            id: string
            text: string
            icon?: string
        }[]
    )
})

const worldMap = computed(() => ({
    chart: {
        map: worldGeo,
        animation: false
    },
    title: {
        text: ""
    },
    subtitle: {
        text: ""
    },
    mapNavigation: {
        enabled: true,
        enableButtons: false
    },
    mapView: {
        maxZoom: 1
    },
    legend: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    plotOptions: {
        series: {
            point: {
                events: {
                    click: function () {
                        //@ts-ignore
                        const country = this["hc-key"]

                        if (country) {
                            toggleCountry(country)
                        }
                    }
                }
            }
        }
    },
    series: [
        {
            name: "countries",
            data: allCountries.value.map((country) => ({
                "hc-key": country.id,
                value: selectedCountries.value.includes(country.id) ? 1 : 0,
                selected: selectedCountries.value.includes(country.id)
            })),
            states: {
                hover: {
                    color: "gray"
                }
            },
            allowPointSelect: true,
            cursor: "pointer"
        }
    ],
    tooltip: {
        headerFormat: "",
        formatter: function (): any {
            const _this = this as any

            const country = _this.point.name as string
            const countryIso = _this.point["hc-key"] as string

            let ret = ""

            ret += '<div class="highcharts-toolbar-text">'
            ret += `<span class="fi fi-${countryIso} "></span> <b>${T(country)}</b><br>`

            return ret
        },
        useHTML: true
    },
    colorAxis: {
        min: 0,
        max: 1,
        stops: [
            [0, "#2DCC71"], // Unselected color
            [1, "#E74C3C"] // Selected color
        ]
    }
}))

function toggleCountry(country: string) {
    const index = selectedCountries.value.indexOf(country)

    if (index === -1) {
        selectedCountries.value.push(country)
    } else {
        selectedCountries.value.splice(index, 1)
    }

    //force a update
    selectedCountries.value = [...selectedCountries.value]
}

onMounted(() => {
    selectedCountries.value = props.modelValue
})

watch(selectedCountries, () => {
    emit("update:modelValue", selectedCountries.value)
})
</script>

<style scoped>
.map {
    margin-top: 1rem;
    position: absolute;
    /* bypass parent width and height */
    width: 60%;
    height: 27em;
}

:deep(.highcharts-point-select) {
    fill: #e74c3c !important;
    stroke: #ffffff;
}
</style>
