import ClipboardHandler from "@/classes/clipboard"
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import frontendNotifications from "@/classes/notifications"
import deviceHelpers from "@/helpers/helpers.devices"
import numberHelpers from "@/helpers/helpers.numbers"
import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"
import router from "@/router/router"
import Button from "@/templates/components/button/button"
import ObjectType, {
    type ItemlistItemDetail,
    type Label,
    type MenuEntry,
    type ObjectTypeObject,
    type ObjectTypePayload
} from "../../objectType"

export interface ApiKey extends ObjectType<ApiKey> {
    name: string
    id: string
    key?: string
    tenants?: Array<string>
    scopes?: Array<"metrics">
    issuedAt?: number
    expiresOn?: number
    comment?: string
}

class ApiKeys extends ObjectType<ApiKey> {
    constructor(payload: ObjectTypePayload<ApiKey>) {
        super(payload)
        const thisClass = this

        this.itemlist.getInfoBoxContent = (accountId, itemlistComponent) => ""
        this.itemlist.getSortingOptions = (accountId, itemlistComponent) => []
        this.itemlist.getToolbarButtons = (accountId, itemlistComponent) => {
            let toolBarEntries = []
            toolBarEntries.push({
                icon: "fal fa-plus",
                title: T("Add API Key"),
                link: "#add-tenant-" + accountId + ".sms-apikey",
                id: "apiKeyButtonAdd",
                vIf: false
            })
            toolBarEntries.push({
                icon: "fal fa-clock",
                id: "showExpiredApiKeysButton",
                title:
                    itemlistComponent.exposed.showExpiredKeys.value == true
                        ? T("Hide expired API Keys")
                        : T("Show expired API Keys"),
                onClick: async () => {
                    itemlistComponent.exposed.toggleExpiredKeys()
                },
                class: itemlistComponent.exposed.showExpiredKeys.value == true ? "active" : ""
            })
            return toolBarEntries
        }

        this.itemlistItem.getDetails = (accountId, item, component) => {
            let result: ItemlistItemDetail[] = []

            const thisTenantsArray = item?.tenants?.map((tenant: string) => {
                return {
                    id: tenant,
                    text: tenant,
                    title: tenant,
                    displayType: "label"
                }
            })
            const thisScopesArray = item?.scopes?.map((scope: string) => {
                return {
                    id: scope,
                    text: this.scopeToDislayName(scope),
                    title: this.scopeToDislayName(scope),
                    displayType: "label"
                }
            })
            result = [
                {
                    iconClass: "fal fa-fw fa-user",
                    title: T("Tenants"),
                    key: T("Tenants"),
                    [item ? "labels" : "content"]: item
                        ? thisTenantsArray
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-key",
                    title: T("Scope"),
                    key: T("Scope"),
                    [item ? "labels" : "content"]: item
                        ? thisScopesArray
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-key",
                    title: T("Key"),
                    key: T("Key"),
                    [item ? "labels" : "content"]: item
                        ? [
                              {
                                  title: T("Copy to clipboard"),
                                  text: T("Copy to clipboard"),
                                  onClick: function () {
                                      if (item.key) {
                                          const clipboard = new ClipboardHandler()
                                          clipboard.copy(item.key || "")
                                          frontendNotifications.addNotification(accountId, {
                                              accountId: accountId,
                                              content: {
                                                  body: {
                                                      content: item.key?.substring(0, 20) + "..."
                                                  },
                                                  title: {
                                                      text:
                                                          "API Key " +
                                                          thisClass.itemlistItem.getTitle(
                                                              item,
                                                              undefined
                                                          ).title +
                                                          " " +
                                                          T("copied"),
                                                      icon: "fal fa-check"
                                                  }
                                              },
                                              highlightNew: true
                                          })
                                      }
                                  },
                                  icon: "fal fa-copy",
                                  class: "bg-red",
                                  displayType: "label"
                              }
                          ]
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                }
            ]

            return result
        }
        this.itemlistItem.getLabels = (accountId: string, item: ObjectTypeObject<ApiKey>) => {
            let result: Label[] = []
            const expieryDay =
                typeof item?.expiresOn == "number" ? item.expiresOn * 1000 : undefined
            const today = Date.now()
            if (expieryDay && today >= expieryDay) {
                result.push({
                    icon: "fal fa-clock",
                    class: "bg-red",
                    displayType: "label",
                    text: T("Expired"),
                    title: T("Expired")
                })
            }
            return result
        }
        this.itemlistItem.getStatus = (accountId: string, item: ObjectTypeObject<ApiKey>) => {
            return undefined
        }
        this.itemlistItem.hasCheckbox = (item: ObjectTypeObject<ApiKey>) => {
            return true
        }

        this.itemlistItem.isClickable = (accountId: string, item: ObjectTypeObject<ApiKey>) => {
            return true
        }

        this.itemlistItem.getTitle = (item, component) => {
            return {
                title:
                    typeof item?.name == "string" && item.name.trim()
                        ? item.name.trim()
                        : item?.id
                          ? deviceHelpers.getShortDeviceId(item.id)
                          : T("undefined"),
                small: undefined
            }
        }

        this.itemlistItem.onClick = (accountId: string, item: ObjectTypeObject<ApiKey>) => {
            let objectId = item[
                apiKeysNew.options.objectTypeInfo.primaryKeyProperty.property as keyof ApiKey
            ] as string
            router.navigate(
                "edit-tenant-" + tenantHelpers.getTenantDomain(accountId) + "-apikey-" + objectId
            )
        }
        this.itemlistItem.getMenuEntries = (accountId: string, item: ObjectTypeObject<ApiKey>) => {
            let menuEntries: MenuEntry[] = []
            if (item) {
                let objectId = item[
                    apiKeysNew.options.objectTypeInfo.primaryKeyProperty.property as keyof ApiKey
                ] as string
                menuEntries.push(
                    new Button({
                        text: T("Edit"),
                        title: T("Edit"),
                        icon: "fal fa-edit",
                        onClick: () => {
                            router.navigate(
                                "edit-tenant-" +
                                    tenantHelpers.getTenantDomain(accountId) +
                                    "-apikey-" +
                                    objectId
                            )
                        }
                    })
                )
                menuEntries.push(
                    new Button({
                        text: T("Copy"),
                        title: T("Copy"),
                        icon: "fal fa-copy",
                        onClick: () => {
                            if (item.key) {
                                // @ts-ignore
                                const clipboard = new ClipboardHandler()
                                clipboard.copy(item.key || "")
                                frontendNotifications.addNotification(accountId, {
                                    accountId: accountId,
                                    content: {
                                        body: {
                                            content: item.key?.substring(0, 20) + "..."
                                        },
                                        title: {
                                            text: "Text " + T("copied"),
                                            icon: "fal fa-check"
                                        }
                                    },
                                    highlightNew: true
                                })
                            }
                        }
                    })
                )
                if (["admin", "auditor"].indexOf(item?.id) == -1) {
                    menuEntries.push(
                        new Button({
                            text: T("Delete"),
                            title: T("Delete"),
                            icon: "fal fa-trash",
                            onClick: () => {
                                apiKeysNew.dialogs.getDeleteObjectDialog(accountId, item)
                            }
                        })
                    )
                }
            }

            return menuEntries
        }
    }

    scopeToDislayName = (scope: string) => {
        switch (scope) {
            case "metrics":
                return T("Metrics")
            case "product:ms:role:admin":
                return T("Mobile Security Administration")
            case "utm:zt:admin":
                return T("UTM Zero-Touch Administration")
            default:
                return scope
        }
    }

    getApiKeyScopes = (accountId: string) => {
        try {
            return requestHandler.request(
                "GET",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/apikeys/scopes"
            )
        } catch (e) {
            console.error(e)
            throw e
        }
    }

    isApiKeyExpired = (item: ApiKey): boolean => {
        let result = false
        const expieryDay = typeof item?.expiresOn == "number" ? item.expiresOn * 1000 : undefined
        const today = Date.now()
        if (expieryDay && today >= expieryDay) {
            result = true
        }
        return result
    }

    copyItemToClipboard = (objectId: string, accountId: string) => {
        const item = this.useStore?.().getObjectStoreObject(accountId, objectId)
        if (item?.key) {
            const clipboard = new ClipboardHandler()
            clipboard.copy(item.key || "")
            frontendNotifications.addNotification(accountId, {
                accountId: accountId,
                content: {
                    body: {
                        content: item.key?.substring(0, 20) + "..."
                    },
                    title: {
                        text:
                            "API Key " +
                            this.itemlistItem.getTitle(item, undefined).title +
                            " " +
                            T("copied"),
                        icon: "fal fa-check"
                    }
                },
                highlightNew: true
            })
        }
    }
}

const apiKeysNew = new ApiKeys({
    objectType: "apiKeys",
    productType: "unifiedSecurity",
    slug: "apikeys",
    hasStore: true,
    appearance: {
        iconClass: "fal fa-key",
        text: {
            plural: "API Keys",
            title: "API Keys",
            sidebarName: "API Keys",
            singular: "API Key"
        },
        color: "red",
        showInSidebar: true,
        showOnDashboard: false
    },
    objectTypeInfo: {
        primaryKeyProperty: {
            property: "id",
            pathToPrimaryProperty: undefined
        },
        nameProperty: {
            primary: "name",
            pathToPrimaryProperty: undefined,
            secondary: undefined,
            pathToSecondaryProperty: undefined
        }
    },
    apiInfo: {
        url: "/sms-mgt-api/api/2.0",
        getCountGETProperties: "?props[]=null&select=data.count",
        // GET
        getObjectListResponseProperty: "apiKeys",
        getObjectListMethod: "GET",
        getObjectListPath: "/tenants/{tenantDomain}/apikeys",
        // Add
        addObjectMethod: "POST",
        addObjectPath: "/tenants/{tenantDomain}/apikeys",
        // Update
        updateObjectMethod: "PUT",
        updateObjectPath: "/tenants/{tenantDomain}/apikeys/{objectId}",
        // Delete
        deleteObjectMethod: "DELETE",
        deleteObjectPath: "/tenants/{tenantDomain}/apikeys/{objectId}"
    }
})
export default apiKeysNew
