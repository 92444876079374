<script setup lang="ts">
import { useVue } from "@/app"
import apis from "@/classes/apis"
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import products from "@/classes/objectTypes"
import {
    type CreateService,
    type CreateServiceGroup,
    type SunNkViewService
} from "@/classes/objectTypes/adaptiveSecureConnect/topologies"
import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"
import { MutationTypes } from "@/store/vuex.store"
import { debounce } from "throttle-debounce"
import { computed, onMounted, ref, watch, type ComputedRef } from "vue"
import loader from "../components/loader.vue"
import inputNumber from "../inputtypes/input-number.vue"
import inputTextComponent from "../inputtypes/input-text"
import inputSelectComponent from "../inputtypes/input-vue-select.vue"

const props = defineProps<{
    properties: {
        sattelitteId: string
        topologyId: string
        isCoreDns?: true
    }
}>()

// Refs

const error = ref("")
const loading = ref(true)

// V-Models
const name = ref("")
const protocol = ref("")
const icmp = ref("")
const icmpIpv6 = ref("")
const serviceGroupName = ref("")
const contractHelper = ref("")
const destinationPortType = ref(<"singlePort" | "portRange">"singlePort")
const destinationPort = ref(1)
const desitnationPortStart = ref(1)
const destinationPortEnd = ref(2)
const sourcePortType = ref(<"all" | "singlePort" | "portRange">"all")
const sourcePort = ref(1)
const sourcePortStart = ref(1)
const sourcePortEnd = ref(2)

// Computed Values

const activeAccountId = computed(() => {
    return getterHelpers.useStore()?.getters.getActiveAccountId || ""
})
const activeTenantDomain = computed(() => {
    return tenantHelpers.getTenantDomain(activeAccountId.value)
})

const node = computed(() => {
    return products.unifiedSecurityConsole.utmNodes
        .useStore?.()
        .getObjectStoreObject(activeAccountId.value, props.properties.sattelitteId)
})
const allServiceGroups = computed(() => {
    return (node.value?.nkView?.services || []) as any[]
})
const serviceGroupSelectOptions: ComputedRef<selectOption[]> = computed(() => {
    return (
        allServiceGroups.value.map((service: any) => {
            if (service.service_refs)
                return {
                    id: service.name,
                    text: service.name
                }
            else if (service.id == undefined && Array.isArray(service.services))
                return {
                    id: service.name,
                    text: service.name
                }
        }) as selectOption[]
    ).filter((option: selectOption) => option !== undefined)
})
const reservedObjects = computed(() => {
    //@ts-ignore
    return (node.value?.nkView?.reservedObjects || []) as string[]
})
const allProtocolSelectOptions = computed(() => {
    return [
        "3pc",
        "any",
        "ah",
        "argus",
        "aris",
        "ax.25",
        "bbn-rcc",
        "bna",
        "br-sat-mon",
        "carp",
        "cbt",
        "cftp",
        "chaos",
        "compaq-peer",
        "cphb",
        "cpnx",
        "crtp",
        "crudp",
        "dccp",
        "dcn",
        "ddp",
        "ddx",
        "dgp",
        "dsr",
        "egp",
        "eigrp",
        "emcon",
        "encap",
        "esp",
        "etherip",
        "fc",
        "fire",
        "ggp",
        "gmtp",
        "gre",
        "hmp",
        "iatp",
        "icmp",
        "idpr",
        "idpr-cmtp",
        "idrp",
        "ifmp",
        "igmp",
        "igp",
        "il",
        "i-nlsp",
        "ipcomp",
        "ipcv",
        "ipip",
        "iplt",
        "ippc",
        "ipv4",
        "ipv6",
        "ipv6-frag",
        "ipv6-icmp",
        "ipv6-nonxt",
        "ipv6-opts",
        "ipv6-route",
        "ipx-in-ip",
        "irtp",
        "isis",
        "iso-ip",
        "iso-tp4",
        "kryptolan",
        "l2tp",
        "larp",
        "leaf-1",
        "leaf-2",
        "merit-inp",
        "mfe-nsp",
        "micp",
        "mobile",
        "mobility-header",
        "mpls-in-ip",
        "mtp",
        "mux",
        "narp",
        "netblt",
        "nsfnet-igp",
        "nvp",
        "ospf",
        "pgm",
        "pim",
        "pipe",
        "pnni",
        "prm",
        "ptp",
        "pup",
        "pvp",
        "qnx",
        "rdp",
        "rsvp",
        "rsvp-e2e-ignore",
        "rvd",
        "sat-expak",
        "sat-mon",
        "scc-sp",
        "scps",
        "sctp",
        "sdrp",
        "secure-vmtp",
        "skip",
        "sm",
        "smp",
        "snp",
        "sprite-rpc",
        "sps",
        "srp",
        "sscopmce",
        "stp",
        "sun-nd",
        "swipe",
        "tcf",
        "tcp",
        "tlsp",
        "tp++",
        "trunk-1",
        "trunk-2",
        "ttp",
        "udp",
        "udplite",
        "uti",
        "vines",
        "visa",
        "vmtp",
        "wb-expak",
        "wb-mon",
        "wsn",
        "xnet",
        "xns-idp",
        "xtp"
    ].map((protocol: string) => {
        return {
            id: protocol,
            text: protocol
        }
    }) as selectOption[]
})

const icmpListSelectOptions = computed(() => {
    return [
        { id: "any", text: "any" },
        { id: "0", text: T("Echo Reply (Ping)") },
        { id: "3", text: T("Destination Unreachable") },
        { id: "4", text: T("Source Quench") },
        { id: "5", text: T("Redirect") },
        { id: "8", text: T("Echo Request (Ping)") },
        { id: "9", text: T("Router Advertisement") },
        { id: "10", text: T("Router Solicitation/Selection") },
        { id: "11", text: T("Time Exceeded") },
        { id: "12", text: T("Parameter Problem") },
        { id: "13", text: T("Timestamp") },
        { id: "14", text: T("Timestamp Reply") },
        { id: "15", text: T("Information Request") },
        { id: "16", text: T("Information Reply") },
        { id: "17", text: T("Address Mask Request") },
        { id: "18", text: T("Address Mask Reply") },
        { id: "30", text: T("Reserved (for Security)") },
        { id: "31", text: T("Datagram Conversion Error") },
        { id: "32", text: T("Mobile Host Redirect") },
        { id: "35", text: T("Mobile Registration Request") },
        { id: "36", text: T("Mobile Registration Reply") },
        { id: "37", text: T("Domain Name Request") },
        { id: "38", text: T("Domain Name Reply") },
        { id: "39", text: T("SKIP") },
        { id: "40", text: T("Photuris") }
    ] as selectOption[]
})

const ipv6IcmpSelectOptions = computed(() => {
    return [
        { id: "any", text: T("any") },
        { id: "1", text: T("Destination unreachable") },
        { id: "2", text: T("Packet too big") },
        { id: "3", text: T("Time exceeded") },
        { id: "4", text: T("Parameter problem") },
        { id: "128", text: T("Echo Request") },
        { id: "129", text: T("Echo Reply") },
        { id: "133", text: T("Router Solicitation") },
        { id: "134", text: T("Router Advertisement") },
        { id: "135", text: T("Neighbor Solicitation") },
        { id: "136", text: T("Neighbor Advertisement") },
        { id: "137", text: T("Redirect Message") },
        { id: "138", text: T("Router Renumbering") },
        { id: "139", text: T("ICMP Node Information Query") },
        { id: "140", text: T("ICMP Node Information Response") },
        { id: "141", text: T("Inverse Neighbor Discovery Solicitation Message") },
        { id: "142", text: T("Inverse Neighbor Discovery Advertisement Message") },
        { id: "143", text: T("Multicast Listener Discovery reports (RFC 3810)") },
        { id: "144", text: T("Home Agent Address Discovery Request Message") },
        { id: "145", text: T("Home Agent Address Discovery Reply Message") },
        { id: "146", text: T("Mobile Prefix Solicitation") },
        { id: "147", text: T("Mobile Prefix Advertisement") },
        { id: "148", text: T("Certification Path Solicitation") },
        { id: "149", text: T("Certification Path Advertisement") },
        { id: "151", text: T("Multicast Router Advertisement") },
        { id: "152", text: T("Multicast Router Solicitation") },
        { id: "153", text: T("Multicast Router Termination") },
        { id: "155", text: T("RPL Control Message") }
    ] as selectOption[]
})

const contractHelpers = computed(() => {
    const result: selectOption[] = []
    if (["tcp", "udp", "gre"].includes(protocol.value)) {
        if (protocol.value == "tcp") {
            result.push(
                {
                    text: "ftp",
                    id: "ftp"
                },
                {
                    text: "irc",
                    id: "irc"
                },
                {
                    text: "sane",
                    id: "sane"
                },
                {
                    text: "Q.931",
                    id: "Q.931"
                },
                {
                    text: "sane",
                    id: "sane"
                },
                {
                    id: "sip",
                    text: "sip"
                },
                {
                    id: "pptp",
                    text: "pptp"
                }
            )
        } else if (protocol.value == "udp") {
            result.push(
                {
                    id: "tftp",
                    text: "tftp"
                },
                {
                    id: "RAS",
                    text: "RAS"
                },
                {
                    id: "H.245",
                    text: "H.245"
                },
                {
                    id: "netbios-ns",
                    text: "netbios-ns"
                },
                {
                    id: "snmp",
                    text: "snmp"
                },
                {
                    id: "sip",
                    text: "sip"
                }
            )
        } else if (protocol.value == "gre") {
            result.push({
                id: "pptp",
                text: "pptp"
            })
        }
    }
    return result
})

const getPayload = async () => {
    let payload: CreateService | CreateServiceGroup
    payload = {
        name: name.value,
        protocol: protocol.value
    }
    if (["tcp", "udp"].includes(protocol.value)) {
        payload["ct_helper"] = contractHelper.value
        payload["dst-ports"] =
            destinationPortType.value == "singlePort"
                ? [destinationPort.value.toString()]
                : [
                      desitnationPortStart.value.toString() +
                          "-" +
                          destinationPortEnd.value.toString()
                  ]
        payload["src-ports"] =
            sourcePortType.value == "all"
                ? undefined
                : sourcePortType.value == "singlePort"
                  ? [sourcePort.value.toString()]
                  : [sourcePortStart.value.toString() + "-" + sourcePortEnd.value.toString()]
    } else if (["icmp", "ipv6-icmp"].includes(protocol.value)) {
        payload["icmp-types"] = protocol.value == "icmp" ? [icmp.value] : [icmpIpv6.value]
    } else if (["gre"].includes(protocol.value)) {
        payload["ct_helper"] = contractHelper.value
    }

    // Delete Empty Strings
    if (!payload["ct_helper"]) delete payload["ct_helper"]

    if (serviceGroupName.value) {
        let oldServices: SunNkViewService[] = []
        if (props.properties.topologyId && props.properties.isCoreDns === true) {
            const allServices =
                await products.unifiedSecurityConsole.utmNodes.getLocalNodesOrServices(
                    activeAccountId.value,
                    props.properties.sattelitteId,
                    props.properties.topologyId,
                    "services"
                )
            if (
                allServices &&
                allServices[serviceGroupName.value as keyof typeof allServices] != undefined
            ) {
                const serviceGrp = allServices[
                    serviceGroupName.value as keyof typeof allServices
                ] as SunNkViewService
                oldServices = Array.isArray(serviceGrp?.services) ? serviceGrp.services : []
            }
        }
        payload = (<CreateServiceGroup>{
            name: serviceGroupName.value,
            services: [payload].concat(oldServices)
        }) as CreateServiceGroup
    }
    return payload
}
// Functions
const submit = async () => {
    error.value = ""
    const payload = await getPayload()
    try {
        useVue().$refs.modals.$refs.modal.modal.buttons[1].loading = true
        const result = await requestHandler.request(
            "POST",
            config.mgtApiUriNext +
                "/tenants/" +
                activeTenantDomain.value +
                "/sun/topologies/" +
                props.properties.topologyId +
                "/nodes/" +
                props.properties.sattelitteId +
                "/services",
            payload
        )
        useVue().$refs.modals.$refs.modal.modal.buttons[1].loading = false
        if (result instanceof Error) throw result
        getterHelpers.useStore().commit(MutationTypes.removeModal, {})
        setTimeout(() => {
            if (payload.name) {
                useVue().$refs.modals.$refs.modal.$refs.modalComponent.service = payload.name
            }
        }, 700)
    } catch (e: unknown) {
        e = apis.parseApiResponse(e)
        console.error(e)
        error.value = T(
            "An unknown error occurred while adding the service. Please try again in a short time or change the input values."
        )
        useVue().$refs.modals.$refs.modal.modal.buttons[1].loading = false
    }
}

// State Management with Watched

const resetState = () => {
    contractHelper.value = ""
    icmp.value = "any"
    icmpIpv6.value = "any"
    destinationPort.value = 1
    desitnationPortStart.value = 1
    destinationPortEnd.value = 2
    sourcePort.value = 1
    sourcePortStart.value = 1
    sourcePortEnd.value = 2
}
const setPorts = debounce(1000, () => {
    if (destinationPortEnd.value <= desitnationPortStart.value) {
        destinationPortEnd.value = desitnationPortStart.value + 1
    }
    if (sourcePortEnd.value <= sourcePortStart.value) {
        sourcePortEnd.value = sourcePortStart.value + 1
    }
})

function checkReadyState() {
    if (
        name.value &&
        protocol.value &&
        desitnationPortStart.value < destinationPortEnd.value &&
        sourcePortStart.value < sourcePortEnd.value &&
        error.value == ""
    ) {
        useVue().$refs.modals.$refs.modal.modal.buttons[1].disabled = false
    } else {
        useVue().$refs.modals.$refs.modal.modal.buttons[1].disabled = true
    }
}
const checkForErrors = debounce(1000, () => {
    const nameRegex: RegExp = new RegExp("^[^\\s]+$")
    if (serviceGroupName.value != "" && name.value != "" && name.value == serviceGroupName.value)
        error.value = T("Please chose unique identifiers for name and service group name.")
    else if (
        reservedObjects.value.includes(name.value) ||
        (reservedObjects.value.includes(serviceGroupName.value) &&
            !serviceGroupSelectOptions.value.some((option) => option.id == serviceGroupName.value))
    )
        error.value = T("Some of the provided names collide with the reserved names of the UTM.")
    else if (
        (name.value && nameRegex.test(name.value) == false) ||
        (serviceGroupName.value && nameRegex.test(serviceGroupName.value) == false)
    )
        error.value = T("Some of the provided names contain backslashes or whitespaces.")
    else error.value = ""
    checkReadyState()
})

// LifeCycles
onMounted(async () => {
    loading.value = true
    loading.value = false
})

// Watches
watch(protocol, () => {
    resetState()
})
watch([desitnationPortStart, destinationPortEnd, sourcePortStart, sourcePortEnd], () => {
    setPorts()
})
watch([protocol, desitnationPortStart, destinationPortEnd, sourcePortStart, sourcePortEnd], () => {
    checkReadyState()
})
watch([name, serviceGroupName], () => {
    error.value = "debounced"
    useVue().$refs.modals.$refs.modal.modal.buttons[1].disabled = true
    checkForErrors()
})

defineExpose({
    submit,
    getPayload
})
</script>
<template>
    <div class="content-2">
        <template v-if="loading == false">
            <div v-if="error && error !== 'debounced'" class="text-center notification bg-red">
                {{ T(error) }}
            </div>
            <!--Name for Service -->
            <div class="row padding-xs-t padding-xs-b-2 form-group border-bottom">
                <div class="first col-xs-24 col-lg-6 col-xl-6">
                    <label class="control-label inputname" for="serviceName">
                        {{ T("Name") }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-13 col-xl-10">
                    <inputTextComponent
                        id="serviceName"
                        :placeholder="T('Service name')"
                        v-model="name"
                    >
                    </inputTextComponent>
                </div>
                <div class="col-lg-7 col-xl-8"></div>
            </div>

            <!-- ServiceGroup -->

            <div
                class="row padding-xs-t padding-xs-b-2 form-group border-bottom"
                v-if="!properties.isCoreDns === true"
            >
                <div class="first col-xs-24 col-lg-6 col-xl-6">
                    <label class="control-label inputname" for="serviceGroupName">
                        {{ T("Service group name") }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-13 col-xl-10">
                    <inputSelectComponent
                        id="serviceGroupName"
                        :placeholder="T('Service group name')"
                        v-model="serviceGroupName"
                        :clearable="true"
                        :select-options="serviceGroupSelectOptions"
                        :tags="true"
                    >
                    </inputSelectComponent>
                </div>
                <div class="col-lg-7 col-xl-8">
                    <p class="input-description">
                        {{ T("Adds service to an existing service group or creates a new one") }}
                    </p>
                </div>
            </div>

            <!-- Select of Protocol -->
            <div
                :class="{
                    'row padding-xs-t padding-xs-b-2 form-group': true,
                    'border-bottom': ['icmp', 'tcp', 'gre', 'udp', 'ipv6-icmp'].includes(protocol)
                }"
            >
                <div class="first col-xs-24 col-lg-6 col-xl-6">
                    <label class="control-label inputname" for="selectedProtocol">
                        {{ T("Protocol") }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-13 col-xl-10">
                    <inputSelectComponent
                        id="selectedProtocol"
                        :select-options="allProtocolSelectOptions"
                        v-model="protocol"
                        :placeholder="T('Select a protocol')"
                    >
                    </inputSelectComponent>
                </div>
                <div class="col-lg-7 col-xl-8"></div>
            </div>

            <!-- Icmp Exception -->

            <div v-if="protocol == 'icmp'" class="row padding-xs-t padding-xs-b-2 form-group">
                <div class="first col-xs-24 col-lg-6 col-xl-6">
                    <label class="control-label inputname" for="icmp">
                        {{ protocol }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-13 col-xl-10">
                    <inputSelectComponent
                        id="icmp"
                        v-model="icmp"
                        :select-options="icmpListSelectOptions"
                    >
                    </inputSelectComponent>
                </div>
                <div class="col-lg-7 col-xl-8"></div>
            </div>

            <!-- Ipv6Icmp Exception -->

            <div v-if="protocol == 'ipv6-icmp'" class="row padding-xs-t padding-xs-b-2 form-group">
                <div class="first col-xs-24 col-lg-6 col-xl-6">
                    <label class="control-label inputname" for="icmpIpv6">
                        {{ protocol }}
                    </label>
                </div>
                <div class="input col-xs-24 col-lg-13 col-xl-10">
                    <inputSelectComponent
                        id="icmpIpv6"
                        v-model="icmpIpv6"
                        :select-options="ipv6IcmpSelectOptions"
                    >
                    </inputSelectComponent>
                </div>
                <div class="col-lg-7 col-xl-8"></div>
            </div>

            <!-- Contract Helpers -->

            <template v-if="contractHelpers.length > 0">
                <div
                    :class="{
                        'row padding-xs-t padding-xs-b-2 form-group': true,
                        'border-bottom': protocol != 'gre'
                    }"
                >
                    <div class="first col-xs-24 col-lg-6 col-xl-6">
                        <label class="control-label inputname" for="contractHelperSelect">
                            {{ T("Protocol type") }}
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-13 col-xl-10">
                        <inputSelectComponent
                            id="contractHelperSelect"
                            v-model="contractHelper"
                            :select-options="contractHelpers"
                            :placeholder="T('Select a contract helper')"
                        >
                        </inputSelectComponent>
                    </div>
                    <div class="col-lg-7 col-xl-8"></div>
                </div>
            </template>

            <!-- UDP / TCP -->

            <!-- Destination Port Type -->

            <template v-if="['udp', 'tcp'].includes(protocol)">
                <div class="row padding-xs-t padding-xs-b-2 form-group">
                    <div class="first col-xs-24 col-lg-6 col-xl-6">
                        <label class="control-label inputname" for="destinationPortType">
                            {{ T("Destination port type") }}
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-13 col-xl-10">
                        <inputSelectComponent
                            id="destinationPortType"
                            v-model="destinationPortType"
                            :select-options="[
                                {
                                    id: 'singlePort',
                                    text: T('Single Port')
                                },
                                {
                                    id: 'portRange',
                                    text: T('Port range')
                                }
                            ]"
                        >
                        </inputSelectComponent>
                    </div>
                    <div class="col-lg-7 col-xl-8"></div>
                </div>

                <!-- Single Port -->
                <div
                    v-if="destinationPortType == 'singlePort'"
                    class="row padding-xs-t padding-xs-b-2 form-group border-bottom"
                >
                    <div class="first col-xs-24 col-lg-6 col-xl-6">
                        <label class="control-label inputname" for="destinationPort">
                            {{ T("Destination port") }}
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-13 col-xl-10">
                        <inputNumber
                            id="destinationPort"
                            v-model="destinationPort"
                            :min="1"
                            :max="65535"
                        ></inputNumber>
                    </div>
                    <div class="col-lg-7 col-xl-8"></div>
                </div>

                <!-- Port Range -->
                <template v-else-if="destinationPortType == 'portRange'">
                    <div class="row padding-xs-t padding-xs-b-2 form-group">
                        <div class="first col-xs-24 col-lg-6 col-xl-6">
                            <label class="control-label inputname" for="desitnationPortStart">
                                {{ T("Destination port start") }}
                            </label>
                        </div>
                        <div class="input col-xs-24 col-lg-13 col-xl-10">
                            <inputNumber
                                id="desitnationPortStart"
                                v-model="desitnationPortStart"
                                :min="1"
                                :max="65534"
                            >
                            </inputNumber>
                        </div>
                        <div class="col-lg-7 col-xl-8"></div>
                    </div>

                    <div class="row padding-xs-t padding-xs-b-2 form-group border-bottom">
                        <div class="first col-xs-24 col-lg-6 col-xl-6">
                            <label class="control-label inputname" for="destinationPortEnd">
                                {{ T("Destination port end") }}
                            </label>
                        </div>
                        <div class="input col-xs-24 col-lg-13 col-xl-10">
                            <inputNumber
                                id="destinationPortEnd"
                                v-model="destinationPortEnd"
                                :min="2"
                                :max="65535"
                            >
                            </inputNumber>
                        </div>
                        <div class="col-lg-7 col-xl-8"></div>
                    </div>
                </template>

                <!-- Source Port -->

                <div class="row padding-xs-t padding-xs-b-2 form-group">
                    <div class="first col-xs-24 col-lg-6 col-xl-6">
                        <label class="control-label inputname" for="sourcePortType">
                            {{ T("Source port type") }}
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-13 col-xl-10">
                        <inputSelectComponent
                            id="sourcePortType"
                            v-model="sourcePortType"
                            :select-options="[
                                {
                                    id: 'all',
                                    text: T('All')
                                },
                                {
                                    id: 'singlePort',
                                    text: T('Single Port')
                                },
                                {
                                    id: 'portRange',
                                    text: T('Port range')
                                }
                            ]"
                        >
                        </inputSelectComponent>
                    </div>
                    <div class="col-lg-7 col-xl-8"></div>
                </div>

                <!-- Single Port -->
                <template v-if="sourcePortType == 'singlePort'">
                    <div class="row padding-xs-t padding-xs-b-2 form-group">
                        <div class="first col-xs-24 col-lg-6 col-xl-6">
                            <label class="control-label inputname" for="sourcePort">
                                {{ T("Source port") }}
                            </label>
                        </div>
                        <div class="input col-xs-24 col-lg-13 col-xl-10">
                            <inputNumber
                                id="sourcePort"
                                v-model="sourcePort"
                                :min="1"
                                :max="65535"
                            ></inputNumber>
                        </div>
                        <div class="col-lg-7 col-xl-8"></div>
                    </div>
                </template>

                <!-- Port Range -->
                <template v-else-if="sourcePortType == 'portRange'">
                    <div class="row padding-xs-t padding-xs-b-2 form-group">
                        <div class="first col-xs-24 col-lg-6 col-xl-6">
                            <label class="control-label inputname" for="sourcePortStart">
                                {{ T("Source port start") }}
                            </label>
                        </div>
                        <div class="input col-xs-24 col-lg-13 col-xl-10">
                            <inputNumber
                                id="sourcePortStart"
                                v-model="sourcePortStart"
                                :min="1"
                                :max="65534"
                            >
                            </inputNumber>
                        </div>
                        <div class="col-lg-7 col-xl-8"></div>
                    </div>

                    <div class="row padding-xs-t padding-xs-b-2 form-group">
                        <div class="first col-xs-24 col-lg-6 col-xl-6">
                            <label class="control-label inputname" for="sourcePortEnd">
                                {{ T("Source port end") }}
                            </label>
                        </div>
                        <div class="input col-xs-24 col-lg-13 col-xl-10">
                            <inputNumber
                                id="sourcePortEnd"
                                v-model="sourcePortEnd"
                                :min="2"
                                :max="65536"
                            ></inputNumber>
                        </div>
                        <div class="col-lg-7 col-xl-8"></div>
                    </div>
                </template>
            </template>
        </template>
        <template v-else>
            <div class="text-size-3 text-center padding-xs-t-4">
                <div class="text-size-2">
                    <loader class="color-red"></loader>
                </div>
            </div>
            <div class="text-center padding-xs-t-2" style="opacity: 0.8">
                <span>
                    {{ T("Getting Node Information...") }}
                </span>
            </div>
        </template>
    </div>
</template>
