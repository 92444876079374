import config from "@/classes/config"
import { T } from "@/classes/i18n"
import objectStores from "@/classes/init"
import products from "@/classes/objectTypes"
import type { User } from "@/classes/unifiedSecurity/users"
import getterHelpers from "@/helpers/helpers.getters"
import jsonHelpers from "@/helpers/helpers.json"
import mixinHelpers from "@/helpers/helpers.mixins"
import { useGlobalMixin } from "@/mixins/mixins.global"
import { ActionTypes, useStore } from "@/store/vuex.store"
import $ from "jquery"
import moment from "moment"
import loaderComponent from "../components/loader.vue"
import inputVueSelectVue from "../inputtypes/input-vue-select.vue"

const iosInviteDialogComponent = {
    mixins: [useGlobalMixin()],
    name: "ios-invite-dialog",
    template: `
        <div>
            <template v-if="initialized">
                <template v-if="tenant.mobileSecurity.settings.ios.apns.expired === false">
                    <div class="row padding-xs-y form-group border-bottom">
                        <div class="first col-xs-24 col-lg-3">
                            <label class="control-label inputname" for="enrollmentType">
                                {{ T('Enrollment type') }}
                            </label>
                        </div>
                        <div class="input col-xs-24 col-lg-12">
                            <label>
                                <select v-model="enrollmentType" id="enrollmentType">
                                    <option value="0">
                                        {{ T('iOS device enrollment') }}
                                    </option>
                                    <option value="1" v-if="tenant.mobileSecurity.settings.ios.apns.expired === false">
                                        {{ T('iOS user enrollment') }}
                                    </option>
                                </select>
                            </label>
                        </div>
                        <div class="desc col-xs-24 col-lg-9">
                            <p class="input-description">
                                {{ T('Enrollment type') }}
                            </p>
                        </div>
                    </div>
                    <template v-if="['0','1'].indexOf(enrollmentType) != -1">
                        <div class="row padding-xs-y form-group border-bottom" v-if="license.licenseOptions.length">
                            <div class="first col-xs-24 col-lg-3">
                                <label class="control-label inputname" for="licenseUUID">
                                    {{ T('License') }}
                                </label>
                            </div>
                            <div class="input col-xs-24 col-lg-12">
                                <label>
                                    <select id="licenseUUID" :disabled="license.disabled || null" v-model="license.selected" style="width:100%">
                                        <option value="" disabled :disabled="true || null">
                                            {{ T('Please select a license') }}
                                        </option>
                                        <template v-for="license in license.licenseOptions">
                                            <option :value="license.id" :disabled="license.disabled || null">
                                                {{ license.text }}
                                            </option>
                                        </template>
                                    </select>
                                </label>
                            </div>
                            <div class="desc col-xs-24 col-lg-9">
                                <p class="input-description">
                                    {{ T('The license that will be used for new enrolled devices.') }}
                                </p>
                            </div>
                        </div>
                        <div class="row padding-xs-y form-group border-bottom" v-if="license.licenseOptions.length <= 0">
                            <div class="first col-xs-24 col-lg-12">
                                <p class="notification">
                                    <i class='fas fa-exclamation-triangle color-red'></i> {{ T('No valid license found') }}
                                </p>
                            </div>
                        </div>
                    </template>

                    <div class="row padding-xs-y form-group border-bottom">
                        <div class="first col-xs-24 col-lg-3">
                            <label
                                class="control-label inputname"
                                for="emails"
                                v-html="enrollmentType == '0' ? T('Emails') : T('Managed Apple IDs')"
                            ></label>
                        </div>
                        <div class="input col-xs-24 col-lg-12">
                            <label :class="{'hidden': enrollmentType != '0'}">
                                <input-vue-select
                                    v-model="value"
                                    :selectOptions="options"
                                    :placeholder="T('Emails')"
                                    :multiple="true"
                                    :tags="true"
                                    id="emails"
                                >
                                </input-vue-select>
                            </label>

                            <label :class="{'hidden': enrollmentType != '1'}">
                                <input-vue-select
                                    v-model="value"
                                    :selectOptions="appleIdOptions"
                                    :placeholder="T('Managed Apple IDs')"
                                    :multiple="true"
                                    id="appleIds"
                                >
                                </input-vue-select>
                            </label>

                        </div>
                        <div class="desc col-xs-24 col-lg-9">
                            <p class="input-description">
                                <template v-if="enrollmentType == '0'">
                                    {{ T('Choose a user or enter an email address') }}
                                </template>
                                <template v-else>
                                    {{ T('Choose a user') }}
                                </template>
                            </p>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="row padding-xs-y form-group border-bottom">
                        <div class="first col-xs-24 col-lg-12">
                            <p class="notification">
                                <i class='fas fa-exclamation-triangle color-red'></i>
                                <strong>
                                    {{ T('No invite/enrollment type usable') }}
                                </strong><br>
                                {{ T('Please set up an apple push certificate.') }}
                            </p>
                            <a :href="'#show-tenant-'+activeTenantDomain+'-mobile-security-settings'" class="btn btn-red">
                                <i class="fal fa-cogs"></i> {{ T('Set up now') }}
                            </a>
                        </div>
                    </div>
                </template>
            </template>
            <template v-else-if="!initialized">
                <div class="text-size-3 text-center padding-xs-t-4">
                    <loader class="color-red"></loader>
                </div>
                <template v-if="loaderText">
                    <div class="text-center padding-xs-t-2 padding-xs-b-4" style="opacity:0.8">
                        <span>{{ T(loaderText) }}</span>
                    </div>
                </template>
            </template>
        </div>
    `,
    data: function () {
        return {
            initialized: false,
            loaderText: "Loading...",
            value: [],
            options: [],
            appleIdOptions: [],
            enrollmentType: "0",
            license: {
                licenseOptions: [],
                selected: "",
                disabled: false
            }
        }
    },
    computed: {
        tenant: function (this: any) {
            return useStore().state.session.accounts[this.activeAccountId] || undefined
        }
    },
    methods: {
        T: function (content: string) {
            return T(content)
        },
        getDate: function (this: any, date: string) {
            return moment(date).format("DD.MM.YYYY HH:mm")
        },
        isExpired: function (this: any, expirationDate: string) {
            return !moment(expirationDate).isSameOrAfter(moment())
        },
        getLicenseTypeFromAdditionalData: function (this: any, additionalData: string) {
            let licenseUUID: string =
                jsonHelpers.getObjectProperty(JSON.parse(additionalData), "licenseUUID") || ""
            let licenseType = "Unknown"

            if (licenseUUID != "") {
                let licenses =
                    (config.canUseNewObjectType("licenses")
                        ? products.unifiedSecurity.licenses
                              .useStore?.()
                              .getObjectStoreObjects(this.activeAccountId)
                        : objectStores.licenses.getObjectsFromStore(this.activeAccountId)) || []
                let foundLicenses = licenses.filter(function (license) {
                    return license.uuid == licenseUUID
                })
                if (foundLicenses.length == 1) {
                    licenseType = foundLicenses[0].type
                }
            }
            return licenseType
        },
        updateButtonState: function (this: any) {
            if (["1", "2"].indexOf(this.enrollmentType) != -1 && this.license.selected == "") {
                getterHelpers
                    .useStore()
                    .getters.getActiveModal(
                        getterHelpers.useStore().state.session.activeAccountId
                    ).buttons[1].disabled = true
            } else if (this.enrollmentType == 2 && this.enterprise.selectedToken.index == "none") {
                getterHelpers
                    .useStore()
                    .getters.getActiveModal(
                        getterHelpers.useStore().state.session.activeAccountId
                    ).buttons[1].disabled = true
            } else if (this.value.length == 0) {
                getterHelpers
                    .useStore()
                    .getters.getActiveModal(
                        getterHelpers.useStore().state.session.activeAccountId
                    ).buttons[1].disabled = true
            } else {
                getterHelpers
                    .useStore()
                    .getters.getActiveModal(
                        getterHelpers.useStore().state.session.activeAccountId
                    ).buttons[1].disabled = false
            }
        }
    },
    created: async function (this: any) {
        getterHelpers
            .useStore()
            .getters.getActiveModal(
                getterHelpers.useStore().state.session.activeAccountId
            ).buttons[1].disabled = true

        let activeTenantDomain: any = this.activeTenantDomain
        let thisComponent: any = this

        // LICENSE SELECTION
        this.loaderText = "Getting licenses..."

        await useStore().dispatch(ActionTypes.getObjectInfos, {
            accountId: this.activeAccountId,
            objectTypes: ["licenses"]
        })

        let licenses: object[] = [],
            licenseOptions: any[] = []

        licenses =
            (config.canUseNewObjectType("licenses")
                ? products.unifiedSecurity.licenses
                      .useStore?.()
                      .getObjectStoreObjects(this.activeAccountId)
                : objectStores.licenses.getObjectsFromStore(this.activeAccountId)) || []

        for (let i: number = 0; licenses.length > i; i++) {
            let license: any = licenses[i]
            let licenceUser = mixinHelpers.getLicenseLimit(license)
            if (
                (license.type == "Mobile Security" || license.type == "MDM") &&
                license.uuid &&
                license.assignedUser < licenceUser &&
                !license.expired &&
                !license.dead
            ) {
                licenseOptions.push({
                    id: license.uuid,
                    text:
                        license.name +
                        " | " +
                        license.type +
                        " [" +
                        license.assignedUser +
                        "/" +
                        licenceUser +
                        "] (" +
                        license.uuid.substring(0, 4) +
                        ")",
                    disabled:
                        license.assignedUser >= licenceUser || license.expired || license.dead,
                    licenseType: license.type
                })
            }
        }
        this.license.licenseOptions = licenseOptions

        if (licenseOptions.length) {
            this.license.selected = licenseOptions[0].id
        }

        this.loaderText = "Getting registered Email addresses..."
        try {
            let users: any = {}
            if (this.tenant.unifiedSecurity.users.length > 0) {
                users = JSON.parse(JSON.stringify(this.tenant.unifiedSecurity.users))
            } else {
                if (config.canUseNewObjectType("users")) {
                    await products.unifiedSecurity.users.queries.getObjectsFromApi(
                        this.activeAccountId
                    )
                } else {
                    await useStore().dispatch(ActionTypes.getObjectInfos, {
                        accountId: this.activeAccountId,
                        objectTypes: ["users"]
                    })
                }

                users = config.canUseNewObjectType("users")
                    ? products.unifiedSecurity.users
                          .useStore?.()
                          ?.getObjectStoreObjects(this.activeAccountId)
                    : useStore().getters.getObjectTypeStore({
                          accountId: this.activeAccountId,
                          objectType: "users"
                      })?.items || []
            }
            if (users.length) {
                let leadingZeros: number = users.length.toString().length

                for (let userIndex in users) {
                    let user: User = users[userIndex]
                    this.options.push({
                        text: user.username + "<" + user.profile?.email + ">",
                        id:
                            user.profile?.email +
                            "#usrNr" +
                            (Array(leadingZeros + 1).join("0") + userIndex).slice(-leadingZeros)
                    })
                    if (user.profile?.appleId) {
                        this.appleIdOptions.push({
                            text: user.username + "<" + user.profile?.appleId + ">",
                            id: JSON.stringify({
                                email: user.profile?.email,
                                appleId: user.profile?.appleId,
                                usrNr: (Array(leadingZeros + 1).join("0") + userIndex).slice(
                                    -leadingZeros
                                ),
                                clientUserIdStr: user.profile?.clientUserIdStr
                            })
                        })
                    }
                }
            }
        } catch (e: any) {
            console.error(e)
            this.loaderText =
                "<i class='fas fa-exclamation-triangle color-red'></i> Something went wrong"
        }

        if (this.$parent.passData != undefined) {
            this.value = this.$parent.passData
        }
        this.loaderText = "Finished loading..."
        this.initialized = true
    },
    mounted: function (this: any) {
        $("#device-assign-alias").focus()
    },
    watch: {
        value: function (this: any) {
            this.updateButtonState()
        },
        enrollmentType: function (this: any) {
            this.value = []
            this.updateButtonState()
        },
        "license.selected": function (this: any) {
            this.updateButtonState()
        },
        "enterprise.selectedToken.index": function (this: any) {
            this.updateButtonState()
        }
    },
    beforeCreate(this: any) {},
    components: {
        loader: loaderComponent,
        "input-vue-select": inputVueSelectVue
    }
}
export default iosInviteDialogComponent
