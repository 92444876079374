import config from "@/classes/config"
import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import queries from "@/queries/queries"
import requestHandler from "@/queries/requests"

const branding = {
    getPreview: async function (accountid: string) {
        let result: boolean = false
        try {
            if (getterHelpers.useStore()?.getters.hasAccountid(accountid) == false) {
                throw {
                    status: 401
                }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "GET",
                "/sms-mgt-api/api/" +
                    config.mgtApiVersion +
                    "/tenants/" +
                    tenantDomain +
                    "/laas/branding/esr/preview"
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return result
    },
    addPreview: async function (accountid: string, object: any) {
        let result: boolean = false
        try {
            if (getterHelpers.useStore()?.getters.hasAccountid(accountid) == false) {
                throw {
                    status: 401
                }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "POST",
                "/sms-mgt-api/api/" +
                    config.mgtApiVersion +
                    "/tenants/" +
                    tenantDomain +
                    "/laas/branding/esr/preview",
                object
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return result
    },

    downloadBrandingPreview: async function (tenantDomain: string) {
        queries.misc.download(
            "/sms-mgt-api/api/1.1/tenants/" + tenantDomain + "/laas/branding/esr/preview/download",
            "Branding_preview.pdf"
        )
        await timeHelpers.sleep(300)
    },

    getBranding: async function (accountid: string) {
        let result: any = false
        try {
            if (getterHelpers.useStore()?.getters.hasAccountid(accountid) == false) {
                throw {
                    status: 401
                }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "GET",
                "/sms-mgt-api/api/" +
                    config.mgtApiVersion +
                    "/tenants/" +
                    tenantDomain +
                    "/laas/branding/esr"
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return result
    },
    updateBranding: async function (accountid: string, object: any) {
        let result: any = false
        try {
            if (getterHelpers.useStore()?.getters.hasAccountid(accountid) == false) {
                throw {
                    status: 401
                }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "PUT",
                "/sms-mgt-api/api/" +
                    config.mgtApiVersion +
                    "/tenants/" +
                    tenantDomain +
                    "/laas/branding/esr",
                object
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return result
    }
}
export default branding
