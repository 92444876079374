<template>
    <p>
        {{
            T(
                "Please provide as much information as possible to help locate the lost device. This information will be displayed on the device."
            )
        }}
    </p>
    <p>
        <b>{{ T("Attention") }}</b
        >: {{ T("The reason is displayed in the audit log but not on the device.") }}
    </p>

    <p class="padding-xs-t">{{ T("Organization") }}</p>
    <input type="text" v-model="lostOrganization" />

    <p class="padding-xs-t">{{ T("Address") }}</p>
    <input type="text" v-model="lostStreetAddress" />

    <p class="padding-xs-t">{{ T("Email Address") }}</p>
    <input type="text" v-model="lostEmailAddress" />

    <p class="padding-xs-t">{{ T("Phone Number") }}</p>
    <input type="text" v-model="lostPhoneNumber" />

    <p class="padding-xs-t-2">{{ T("Reason") }}</p>
    <input type="text" v-model="reason" />
</template>

<script setup lang="ts">
import { T } from "@/classes/i18n"
import getterHelpers from "@/helpers/helpers.getters"
import { useStore } from "@/store/vuex.store"
import { computed, onMounted, ref, watch } from "vue"

const activeAccountId = computed(() => {
    return useStore().state.session.activeAccountId
})

const account = computed(() => {
    return (
        useStore().state.session.userInfo.scopes.list_accounts.filter((account: any) => {
            return activeAccountId.value == account.accountid
        })[0] || undefined
    )
})

const lostPhoneNumber = ref(account.value.phone)
const lostStreetAddress = ref(
    `${account.value.bill_street}, ${account.value.bill_code} ${account.value.bill_city}`
)
const lostOrganization = ref(account.value.accountname)
const lostEmailAddress = ref(account.value.email)
const reason = ref("")

function checkConditions() {
    // reason must be filled
    if (!reason.value) {
        getterHelpers.useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled =
            true
        return
    }

    // at least one of the fields must be filled
    if (
        !lostPhoneNumber.value &&
        !lostStreetAddress.value &&
        !lostOrganization.value &&
        !lostEmailAddress.value
    ) {
        getterHelpers.useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled =
            true
        return
    }

    if (!lostPhoneNumber.value && !lostEmailAddress.value && !lostStreetAddress.value) {
        getterHelpers.useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled =
            true
        return
    }

    getterHelpers.useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled =
        false
}

watch([lostPhoneNumber, lostStreetAddress, lostOrganization, lostEmailAddress, reason], () => {
    checkConditions()
})

onMounted(() => {
    checkConditions()
})

defineExpose({
    lostPhoneNumber,
    lostStreetAddress,
    lostOrganization,
    lostEmailAddress,
    reason
})
</script>
