<script lang="ts" setup>
import { T } from "@/classes/i18n"
import products from "@/classes/objectTypes"
import { type Report } from "@/classes/objectTypes/unifiedReporting/reports/reports"
import dialogs from "@/dialogs/dialogs"
import arrayHelpers from "@/helpers/helpers.arrays"
import getterHelpers from "@/helpers/helpers.getters"
import { MutationTypes, useStore } from "@/store/vuex.store"
import { computed, onMounted, ref } from "vue"
import loaderComponent from "../components/loader.vue"
import table2Component from "../components/table2"
import tableNext from "../components/tableNext.vue"
const props = defineProps<{
    properties: {
        reportId: Report["reportId"]
    }
}>()

const activeAccountId = computed(() => {
    return getterHelpers.useStore().state.session.activeAccountId || ""
})
const report = computed(() => {
    return products.unifiedReporting.reports
        .useStore?.()
        .getObjectStoreObject(activeAccountId.value, props.properties.reportId)
})
const files = computed(() => {
    return report.value?.files || []
})

const initialized = ref(false)
const loaderText = ref("Loading...")
const reportsTable = ref({
    loading: true,
    table: {
        titles: [
            {
                id: "name",
                text: "Name",
                sortable: false
            },
            {
                id: "action",
                text: T("Actions"),
                width: 260,
                sortable: false
            }
        ],
        rows: <any[]>[]
    }
})

const getTableRowByIndex = (i: string) => {
    return reportsTable.value.table.rows.find((row: any) => {
        return row.data.rowIndex == i
    })
}

const init = () => {
    let rows: object[] = []

    initialized.value = false
    loaderText.value = "Loading data..."
    arrayHelpers.sortByObjProperty(report.value?.files || [], "date", "DESC")

    report.value?.files?.forEach((file, i) => {
        if (file.name.indexOf("preview") == -1) {
            let thisRow = () => {
                return getTableRowByIndex(String(i))
            }
            rows.push({
                data: {
                    rowIndex: String(i),
                    name: file.name,
                    action: {
                        buttons: [
                            {
                                onClick: async function (this: any) {
                                    let thisButton = thisRow()?.data.action.buttons[0]
                                    thisButton.loading = true
                                    thisButton.disabled = true
                                    let success: boolean =
                                        await products.unifiedReporting.reports.sendMail(
                                            activeAccountId.value,
                                            report.value?.reportId || props.properties.reportId,
                                            file.filename
                                        )
                                    if (success) {
                                        thisButton.loading = false
                                        thisButton.disabled = true
                                        thisButton.onClick = undefined
                                        thisButton.icon = "fal fa-check"
                                        thisButton.text = T("Sent")
                                        setTimeout(function () {
                                            thisButton.loading = false
                                            thisButton.disabled = false
                                            thisButton.icon = "fal fa-paper-plane"
                                            thisButton.text = report.value?.recipients?.length
                                                ? T("Send mail")
                                                : T("No recipients")
                                        }, 2000)
                                    } else {
                                        thisButton.loading = false
                                        thisButton.disabled = false
                                        thisButton.icon = "fal fa-exclamation-triangle"
                                        thisButton.text = T("Failed")
                                        setTimeout(function () {
                                            thisButton.loading = false
                                            thisButton.disabled = false
                                            thisButton.icon = "fal fa-paper-plane"
                                            thisButton.text = T("Retry")
                                        }, 2000)
                                    }
                                },
                                icon: "fal fa-paper-plane",
                                text: report.value?.recipients?.length
                                    ? T("Send mail")
                                    : T("No recipients"),
                                loading: false,
                                disabled: (report.value?.recipients?.length || 0) > 0 ? false : true
                            },
                            {
                                onClick: async function (this: any) {
                                    let thisButton = thisRow()?.data.action.buttons[1]
                                    thisButton.loading = true
                                    thisButton.disabled = true
                                    useStore().commit(MutationTypes.removeModal, {
                                        accountId: useStore().state.session.activeAccountId
                                    })
                                    dialogs.misc.confirmDialog(
                                        activeAccountId.value,
                                        T("Confirm delete"),
                                        T("Do you really want to delete this report?"),
                                        async () => {
                                            await products.unifiedReporting.reports.deleteFile(
                                                activeAccountId.value,
                                                report.value?.reportId || props.properties.reportId,
                                                file.filename
                                            )
                                            dialogs.unifiedReporting.showReportsDialog(report)
                                        }
                                    )
                                },
                                icon: "fal fa-trash",
                                text: T("Delete"),
                                loading: false,
                                disabled: false
                            }
                        ]
                    }
                }
            })
        }
    })
    reportsTable.value.table.rows = rows
    loaderText.value = "Finished loading..."
    initialized.value = true
    reportsTable.value.loading = false
}

onMounted(async () => {
    await products.unifiedReporting.reports.queries.getObjectsFromApi(activeAccountId.value)
    init()
})
</script>
<template>
    <div>
        <template v-if="initialized">
            <div class="form-group">
                <tableNext
                    :has-options="false"
                    :is-searchable="false"
                    :is-filterable="false"
                    :object-list="files"
                    :selectable-columns="tableEntryInfos"
                ></tableNext>
                <table2-component
                    :table="reportsTable.table"
                    :scrollbar="true"
                    :maxheight="350"
                    :minheight="350"
                    :loading="reportsTable.loading"
                    style="margin: 0"
                    :searchable="false"
                ></table2-component>
            </div>
        </template>
        <template v-else-if="!initialized">
            <div class="text-size-3 text-center padding-xs-t-4">
                <loaderComponent class="color-red"></loaderComponent>
            </div>
            <template v-if="loaderText">
                <div class="text-center padding-xs-t-2 padding-xs-b-4" style="opacity: 0.8">
                    <span>{{ T(loaderText) }}</span>
                </div>
            </template>
        </template>
    </div>
</template>
