<template>
    <div class="app-item" @click="emit('click')">
        <a v-if="deletable" class="delete-item" v-on:click="emit('delete')">
            <i class="fal fa-times"></i>
        </a>

        <template v-if="app.Type === 'Folder'">
            <div class="folder">
                <img
                    v-for="folderApp in app.Pages?.[0].slice(0, 9) || []"
                    :src="getAppData(folderApp.BundleID || folderApp.URL!).iconUrl"
                    class="folder-icon"
                />
            </div>
            <span v-if="showAppNames" class="app-name">{{ renderName(app.DisplayName!) }}</span>
        </template>
        <template v-else>
            <img :src="getAppData(app.BundleID || app.URL!).iconUrl" alt="" class="app-icon" />
            <span v-if="showAppNames" class="app-name">{{
                renderName(getAppData(app.BundleID || app.URL!).name)
            }}</span>
        </template>
    </div>
</template>

<script setup lang="ts">
import type { HomescreenLayoutItem } from "@/classes/mobileSecurity/homescreenlayouts"
import QuestionIcon from "../question.png"

const props = withDefaults(
    defineProps<{
        app: HomescreenLayoutItem
        idInfoMap: InternalBundleIdMap

        deletable?: boolean
        showAppNames?: boolean
    }>(),
    {
        deletable: false,
        showAppNames: true
    }
)

const emit = defineEmits(["delete", "click"])

function getAppData(bundleId: string) {
    const info = props.idInfoMap[bundleId]

    if (!info) {
        return {
            name: bundleId,
            iconUrl: QuestionIcon
        }
    }

    return info
}

function renderName(name: string) {
    if (name?.length > 12) {
        return name.slice(0, 12) + "..."
    }

    return name
}
</script>

<style scoped lang="scss">
@use "sass:math";

@mixin responsive($breakpoint) {
    @if $breakpoint ==tablet {
        @media (min-width: 768px) {
            @content;
        }
    } @else if $breakpoint ==desktop {
        @media (min-width: 1024px) {
            @content;
        }
    }
}

.app-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 2px;
    cursor: grab;
    z-index: 10;
    user-select: none;
}

.app-icon {
    width: clamp(30px, 5vw, 50px);
    height: clamp(30px, 5vw, 50px);
    border-radius: clamp(8px, 1vw, 15px);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    @include responsive(tablet) {
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }
}

.darkmode .app-icon {
    box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.1);

    @include responsive(tablet) {
        box-shadow: 0px 4px 6px rgba(255, 255, 255, 0.1);
    }
}

// apple tv specific icons with different sizes
.tv .app-icon,
.tv .folder,
.app-library-tv .app-icon {
    width: 80px;
    height: 55px;
}

.delete-item {
    position: absolute;
    display: none;
    top: -5px;
    right: 7px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #e74c3c;
    color: #fff;
    text-align: center;
    line-height: 17px;
    font-size: 12px;
}

.ipad .delete-item {
    right: 16px;
    top: -6px;
}

.ipad .navigation-bar .delete-item {
    right: 0;
}

.tv .delete-item {
    right: 0;
    top: 0;
}

.tv .navigation-bar .delete-item {
    right: 20px;
}

.tv .folder-view .delete-item {
    right: 72px;
    top: -5px;
}

.ipad .folder-view .delete-item {
    right: 35px;
}

.app-item:hover > .delete-item {
    display: block;
}

.folder {
    width: clamp(30px, 5vw, 50px);
    height: clamp(30px, 5vw, 50px);
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: clamp(8px, 1vw, 15px);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    padding: 2px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 4px;
    cursor: pointer;
}

.folder-icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    padding: 1px;
}

.app-name {
    margin-top: 1rem;
    font-size: clamp(8px, 1.5vw, 12px);
    line-height: 1.2;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ipad .app-name {
    margin-top: 0.5rem;
}

.tv .app-name {
    margin-top: 0.3rem;
}
</style>
