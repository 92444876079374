<script setup lang="ts">
import { T } from "@/classes/i18n"
import frontendNotifications, { type FrontendNotification } from "@/classes/notifications"
import dialogs from "@/dialogs/dialogs"
import { MutationTypes, useStore } from "@/store/vuex.store"
import { computed, onMounted, ref } from "vue"
import notificationComponent from "./notification.vue"

const showNotifications = ref(false)
const initialized = ref(false)

const selectedNotifications = ref(<Array<FrontendNotification["id"]>>[])
const activeAccountId = computed(() => {
    return useStore().state.session.activeAccountId || ""
})
const notifications = computed(() => {
    return activeAccountId.value
        ? useStore().state.session.accounts[activeAccountId.value]?.notifications || []
        : []
})
const hasSelectedNotifications = computed(() => {
    return selectedNotifications.value.length > 0
})
// METHODS

const toggleShowNotifications = () => {
    showNotifications.value = !showNotifications.value
}
const hideNotifications = () => {
    showNotifications.value = false
}
const addNotification = (options: any) => {
    options.accountid = options.accountid || activeAccountId.value
    frontendNotifications.addNotification(options.accountId, options)
}
const removeNotification = (id: string, accountId?: string) => {
    accountId = accountId || activeAccountId.value
    accountId ? frontendNotifications.removeNotification(accountId, id) : null
}
const getNewNotificationsCount = (highlightedOnly: boolean = false) => {
    let count = notifications.value.filter((notification: any) => {
        return highlightedOnly
            ? notification.state == "new" && notification.highlightNew
            : notification.state == "new"
    }).length
    return count
}
const deleteNotificationsDialog = (type: "all" | "selected") => {
    let dialogContent =
        type == "all"
            ? T("Do you really want to delete all notifications?")
            : T("Do you really want to delete all selected notifications?")
    dialogs.misc.confirmDialog(
        activeAccountId.value,
        T("Confirm delete"),
        dialogContent,
        async () => {
            let promises: any = []
            if (type == "all") {
                notifications.value.forEach((notification) => {
                    promises.push(
                        frontendNotifications.removeNotification(
                            activeAccountId.value,
                            notification.id
                        )
                    )
                })
            } else if (selectedNotifications.value.length > 0) {
                selectedNotifications.value.forEach((notificationId) => {
                    promises.push(
                        frontendNotifications.removeNotification(
                            activeAccountId.value,
                            notificationId
                        )
                    )
                })
            }
            await Promise.all(promises)
        }
    )
}

const addSelectedNotifications = (notifications: Array<FrontendNotification["id"]>) => {
    notifications.forEach((id) => {
        if (selectedNotifications.value.indexOf(id) == -1) {
            selectedNotifications.value.push(id)
        }
    })
}
const removeSelectedNotifications = (notifications: Array<FrontendNotification["id"]>) => {
    notifications.forEach((id) => {
        selectedNotifications.value = selectedNotifications.value.filter((selectedId) => {
            return selectedId != id
        })
    })
}

onMounted(() => {
    useStore().commit(MutationTypes.addOnClickHandler, {
        id: "notifications",
        ref: "root",
        method: () => {
            if (showNotifications.value) {
                showNotifications.value = false
            }
        }
    })
    initialized.value = true
})

defineExpose({
    toggleShowNotifications,
    hideNotifications,
    addNotification,
    removeNotification,
    getNewNotificationsCount,
    deleteNotificationsDialog
})
</script>
<template>
    <div class="menu" v-on:click.stop>
        <ul>
            <li>
                <a class="toggle" v-on:click="toggleShowNotifications()">
                    <i class="fal fa-comment"></i>
                    <span class="count" v-if="getNewNotificationsCount() > 0">
                        {{ getNewNotificationsCount() }}
                    </span>
                </a>
                <transition
                    name="notifications"
                    :duration="{ enter: 300, leave: 300 }"
                    enterActiveClass="notifications-entering"
                    enterToClass="notifications-enter-to"
                    leaveActiveClass="notifications-leaving"
                    leaveToClass="notifications-leave-to"
                >
                    <ul
                        v-on:blur="hideNotifications()"
                        class="menu"
                        v-if="showNotifications"
                        :key="'default'"
                    >
                        <li v-if="notifications.length">
                            <div class="padding-xs-2 text-center">
                                <a
                                    v-on:click="deleteNotificationsDialog('all')"
                                    class="btn btn-primary deleteAll"
                                >
                                    {{ T("Delete all") }}
                                </a>
                                <a
                                    v-on:click="deleteNotificationsDialog('selected')"
                                    class="btn btn-primary deleteSelected"
                                    :disabled="!hasSelectedNotifications || null"
                                    :class="{ disabled: !hasSelectedNotifications }"
                                >
                                    {{ T("Delete selected only") }}
                                </a>
                            </div>
                        </li>
                        <li>
                            <template
                                v-for="(notification, index) in notifications"
                                :key="notification.id"
                            >
                                <notificationComponent
                                    :index="index"
                                    :notification="notification"
                                    :add-selected-notifications="addSelectedNotifications"
                                    :remove-selected-notifications="removeSelectedNotifications"
                                />
                            </template>
                            <template v-if="notifications.length == 0">
                                <p
                                    class="text-bold padding-xs-y-2 text-center"
                                    style="opacity: 0.7"
                                >
                                    {{ T("No notifications") }}
                                </p>
                            </template>
                        </li>
                    </ul>
                </transition>
                <transition
                    name="notifications"
                    :duration="{ enter: 300, leave: 300 }"
                    enterActiveClass="notifications-entering"
                    enterToClass="notifications-enter-to"
                    leaveActiveClass="notifications-leaving"
                    leaveToClass="notifications-leave-to"
                >
                    <ul
                        v-if="!showNotifications && getNewNotificationsCount(true) > 0"
                        :key="'highlights'"
                    >
                        <li>
                            <template v-for="(notification, index) in notifications">
                                <notificationComponent
                                    v-if="notification.highlightNew"
                                    :key="notification.id"
                                    :index="index"
                                    :notification="notification"
                                    :add-selected-notifications="addSelectedNotifications"
                                    :remove-selected-notifications="removeSelectedNotifications"
                                />
                            </template>
                        </li>
                    </ul>
                </transition>
            </li>
        </ul>
    </div>
</template>
