import apis from "@/classes/apis"
import config from "@/classes/config"
import licenseHelpers from "@/helpers/helpers.license"
import requestHandler from "@/queries/requests"
import { ActionTypes, useStore } from "@/store/vuex.store"
import { defineStore } from "pinia"
import products from "../.."
import tenantHelpers from "../../../../helpers/helpers.tenants"

type AzureTenantResponse = {
    accounts: Array<AzureTenant>
}
type AzureInfoResponse = {
    enabled: boolean
}
type AzureStautsResponse = {
    total: number
    status: AzureStatus[]
}

export type AzureStatus = {
    errors: string[]
    status: "pending" | "importing" | "done" | "error" | "idle"
    valid: boolean
    importedUsersCount: number
    importedRolesCount: number
    modifiedUsersCount: number
    roleMapping: {
        [groupId: string]: {
            rolename: string
        }
    }
}

export type AzureTenant = {
    domainName: string
    id: string
}
export type AzureGroup = {
    displayName: string
    id: string
    // Frontend Mapping
    azureTenant?: AzureTenant
    mappingName?: string
    selectOption?: selectOption
}

export type AzureErrorResponse = {
    code: string
    message: string
    innerError: {
        date: string
        "request-id": string
        "client-request-id": string
    }
}

export type ImportAzurePayload = {
    [azureTenantId: string]: {
        roleMapping: {
            [azureGroupId: string]: {
                rolename: string
                importGroupRelations: boolean
                removeExistingRoles: boolean
            }
        }
    }
}

export const useAzureStore = defineStore("azureAd", {
    state: () => ({
        enabled: false,
        azureTenants: <AzureTenant[]>[],
        azureGroups: <AzureGroup[]>[],
        importStatuses: <AzureStatus[]>[]
    }),

    getters: {
        isAzureImporting(state) {
            return state.importStatuses.some((status) => {
                return status.status == "pending" || status.status == "importing"
            })
        }
    },

    actions: {
        /**
         * Sets up the Azure Store and gets all relevant information
         * @param accountId
         */
        async initStore(accountId: string) {
            await this.reset()
            await this.getAzureStauts(accountId)
            await this.getAllAzureGroups(accountId)
            await this.getImportStatuses(accountId)
        },

        /**
         * Turns the Store back to it's initial state
         */
        async reset() {
            this.$reset()
        },
        /**
         * Gets the redirectURL to link a Tenant
         * @param accountId Current AccountId
         * @returns false | string
         */
        async getAzureRedirectURL(accountId: string, domainOrId: string): Promise<string | false> {
            let redirectURL: string | false = false
            const tenantDomain = tenantHelpers.getTenantDomain(accountId)

            try {
                redirectURL = (
                    await requestHandler.request(
                        "GET",
                        `${config.mgtApiUriNext}/tenants/${tenantDomain}/providers/azure/link?domainOrId=${domainOrId}`
                    )
                ).redirectURL
            } catch (e: unknown) {
                console.error(e)
                redirectURL = false
            }
            return redirectURL
        },
        /**
         * Get if Azure is currently linked
         * @param accountId
         * @returns false or and Object with enabled property
         */
        async getAzureStauts(accountId: string) {
            let result: AzureInfoResponse | false = false
            const tenantDomain: string = tenantHelpers.getTenantDomain(accountId)

            try {
                result = await requestHandler.request(
                    "GET",
                    "/sms-mgt-api/api/2.0/tenants/" + tenantDomain + "/providers/azure"
                )
                result = result

                if (typeof result != "boolean" && result.enabled !== undefined) {
                    this.$state.enabled = result.enabled
                }
            } catch (e: unknown) {
                console.error(e)
                result = false
            }
            return result
        },

        /**
         * Gets Azure Tenants for Current Account
         * @param accountId
         * @returns List of AzureTenants or false
         */
        async getAzureTenants(accountId: string): Promise<false | AzureTenant[]> {
            const tenantDomain = tenantHelpers.getTenantDomain(accountId)
            let result: AzureTenant[] | false = []
            let response: AzureTenantResponse

            try {
                response = await requestHandler.request(
                    "GET",
                    config.mgtApiUriNext + "/tenants/" + tenantDomain + "/providers/azure/tenants"
                )
                result = response.accounts

                if (Array.isArray(result)) {
                    this.$state.azureTenants = result
                }
            } catch (e: unknown) {
                console.error(e)
                result = false
            }
            return result
        },

        /**
         * Gets the AzureGroups for a Tenant
         * @param accountId Current AccountId
         * @param azureId Id of the AzureTenant to get the groups from
         * @param azureTenant The AzureTenant itself
         * @returns false | AzureErrorResponse if it did not work or the List of Groups
         */
        async getAzureGroups(
            accountId: string,
            azureId: string,
            azureTenant?: AzureTenant
        ): Promise<AzureGroup[] | AzureErrorResponse | false> {
            let result: AzureGroup[] | AzureErrorResponse | false = false
            const tenantDomain = tenantHelpers.getTenantDomain(accountId)

            try {
                result = await requestHandler.request(
                    "GET",
                    config.mgtApiUriNext +
                        "/tenants/" +
                        tenantDomain +
                        "/providers/azure/tenants/" +
                        azureId +
                        "/groups"
                )
                result = apis.parseApiResponse(result)
                if (Array.isArray(result)) {
                    result.forEach((azureGroup: AzureGroup) => {
                        azureGroup.mappingName = azureGroup.displayName
                        azureGroup.selectOption = {
                            id: azureGroup.displayName,
                            text: azureGroup.displayName
                        }
                        if (azureTenant) azureGroup.azureTenant = azureTenant
                    })

                    this.$state.azureGroups.push(...result)
                    this.$state.azureGroups = Array.from(new Set(this.$state.azureGroups))
                } else {
                    console.error(result)
                }
            } catch (e: unknown) {
                console.error(e)
                result = false
            }
            return result
        },

        /**
         * Gets all Entra ID groups for a Tenant
         * @param accountId Current AccountId
         * @returns All AzureGroups in the current Store
         */
        async getAllAzureGroups(accountId: string): Promise<AzureGroup[]> {
            await this.getAzureTenants(accountId)

            for (const azureTenant of this.$state.azureTenants) {
                await this.getAzureGroups(accountId, azureTenant.id, azureTenant)
            }

            return this.$state.azureGroups
        },

        /**
         * Import groups from Entra ID
         * @param accountId Current AccountId
         * @param payload Payload Required to Import an Entra ID group
         * @returns Object with the StatusId
         */
        async importAzureGroups(
            accountId: string,
            payload: ImportAzurePayload
        ): Promise<unknown | any[]> {
            try {
                const tenantDomain = tenantHelpers.getTenantDomain(accountId)
                let results: any[] = []

                for (const azureTenantId of Object.keys(payload)) {
                    try {
                        const result = await requestHandler.request(
                            "POST",
                            config.mgtApiUriNext +
                                `/tenants/${tenantDomain}/providers/azure/tenants/${azureTenantId}/users/import`,
                            payload[azureTenantId]
                        )
                        if (result) {
                            result["azureTenantId"] = azureTenantId
                        }
                        results.push(result)
                        this.getImportStatuses(accountId)
                    } catch (e: unknown) {
                        console.error(e)
                        results.push(e)
                    }
                }

                return results
            } catch (e: unknown) {
                console.error(e)
                return e
            }
        },

        async getImportStatuses(accountId: string) {
            const tenantDomain = tenantHelpers.getTenantDomain(accountId)
            let result: AzureStautsResponse | undefined = undefined
            let previousImportState: boolean = this.isAzureImporting

            try {
                result = await requestHandler.request(
                    "GET",
                    config.mgtApiUriNext + `/tenants/${tenantDomain}/providers/azure/status`
                )
                result = apis.parseApiResponse(result)
                if (result != undefined && Array.isArray(result.status)) {
                    this.$state.importStatuses = result.status
                }
            } catch (e: unknown) {
                console.error(e)
                result = e as any
            }
            if (this.isAzureImporting === false && previousImportState == true) {
                try {
                    //Refresh from Itemlist
                    const requestObjectTypes: string[] = []

                    // MDM
                    if (licenseHelpers.hasOneOfLicenses(accountId, ["Mobile Security", "MDM"])) {
                        requestObjectTypes.push(
                            "devices?props[]=deviceId&props[]=deviceType&props[]=username&props[]=info&props[]=alias"
                        )
                        if (tenantHelpers.hasFunctionality(accountId, "enterprise")) {
                            requestObjectTypes.push(
                                "enterpriseDevices?props[]=appliedPolicyName&props[]=networkInfo&props[]=appliedState&props[]=enrollmentTime&props[]=props&props[]=hardwareInfo&props[]=lastStatusReportTime&props[]=managementMode&props[]=name&props[]=ownership&props[]=policyName&props[]=previousDeviceNames&props[]=state&props[]=alias&props[]=deviceId&props[]=username&props[]=consent&props[]=licenseUUID&props[]=signedIn&fields=*(name,policyName,appliedPolicyName,hardwareInfo,softwareInfo,lastStatusReportTime,previousDeviceNames,ownership,enrollmentTime,managementMode,networkInfo,nonComplianceDetails,state,appliedState)"
                            )
                        }
                    }
                    // Users and Roles
                    if (
                        config.canUseNewObjectType("roles") &&
                        config.canUseNewObjectType("users")
                    ) {
                        products.unifiedSecurity.roles.queries.getObjectsFromApi(
                            accountId,
                            undefined,
                            [
                                { property: "props[]", value: "rolename" },
                                { property: "props[]", value: "permissionScopes" },
                                { property: "props[]", value: "users" }
                            ]
                        )
                        products.unifiedSecurity.users.queries.getCountFromApi(accountId)
                        products.unifiedSecurity.users.queries.getObjectsFromApi(accountId)
                    } else {
                        requestObjectTypes.push(
                            "roles?props[]=rolename&props[]=permissionScopes&props[]=users"
                        )
                        requestObjectTypes.push("users")
                        useStore().dispatch(ActionTypes.getObjectTypeCount, {
                            accountId: accountId,
                            objectType: "users"
                        })
                    }

                    // Vpp
                    if (tenantHelpers.hasFunctionality(accountId, "vpp")) {
                        if (config.canUseNewObjectType("vppUsers")) {
                            products.mobileSecurity.vppUsers.queries.getObjectsFromApi(accountId)
                        } else {
                            requestObjectTypes.push("vppUsers")
                        }
                    }

                    if (requestObjectTypes.length) {
                        useStore().dispatch(ActionTypes.getObjectInfos, {
                            accountId: accountId,
                            objectTypes: requestObjectTypes
                        })
                    }
                } catch (e: unknown) {
                    console.error(e)
                }
            }
            return result
        }
    }
})
