import EnterpriseDevices from "./mobileSecurity/enterpriseDevices"
import Homescreenlayouts from "./mobileSecurity/homescreenlayouts"
import VppUsers from "./mobileSecurity/vppUsers"
import ZeroTouchDevices from "./mobileSecurity/zeroTouchDevices"
import ApiKeys from "./unifiedSecurity/apiKeys"
import Licenses from "./unifiedSecurity/licenses"
import Roles from "./unifiedSecurity/roles"
import Users from "./unifiedSecurity/users"
import UscProfiles from "./unifiedSecurityConsole/uscProfiles"
import UscUtms from "./unifiedSecurityConsole/uscUtms"

const objectStores = {
    zeroTouchDevices: new ZeroTouchDevices(),
    homescreenlayouts: new Homescreenlayouts(),
    users: new Users(),
    roles: new Roles(),
    licenses: new Licenses(),
    uscUtms: new UscUtms(),
    uscProfiles: new UscProfiles(),
    vppUsers: new VppUsers(),
    enterpriseDevices: new EnterpriseDevices(),
    apiKeys: new ApiKeys()
}
export default objectStores
