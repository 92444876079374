<template>
    <div class="row flexrow">
        <div class="col-xs-24 col-lg-16">
            <div class="box-shadow padding-xs-2" style="height: 100%">
                <h4 class="margin-xs-b-2">
                    <i class="fal fa-fw fa-box-check"></i>
                    {{ T("UTM Zero-Touch-Enrollment und -Konfiguration") }}
                </h4>
                <p>
                    {{
                        T(
                            "Mit UTM Zero-Touch-Enrollment und -Konfiguration können Sie Ihre UTM vor der Inbetriebnahme im Portal registrieren und die Grundeinstellungen festlegen. Diese Funktion ermöglicht es, ein neues Gerät oder ein Ersatzgerät direkt vor Ort zu installieren, ohne dass eine lokale Administration erforderlich ist."
                        )
                    }}
                </p>

                <p class="text-bold margin-xs-t-3">{{ T("Erste Schritte zur Konfiguration:") }}</p>
                <ol class="margin-xs-b-3">
                    <li>
                        {{
                            T(
                                "Erstellen Sie ein UTM Zero-Touch-Profil mit Seriennummer und Enrollment-PIN."
                            )
                        }}
                    </li>
                    <li>{{ T("Laden Sie die entsprechende UTM-Lizenz hoch.") }}</li>
                    <li>{{ T("Setzen Sie eine Websession-PIN.") }}</li>
                    <li>{{ T("Laden Sie optional eine Konfigurationsdatei hoch.") }}</li>
                </ol>

                <p>
                    {{
                        T(
                            'Bei der ersten Inbetriebnahme verbindet sich eine noch nicht konfigurierte UTM automatisch mit dem Portal und wird entsprechend der dort hinterlegten Einstellungen lizenziert und konfiguriert. Achten Sie darauf, dass LAN 1 der UTM korrekt an den Router angeschlossen ist, der die Verbindung zum Internet herstellt, so dass die UTM eine IP Adresse per DHCP beziehen kann. Das Profil wird als "angewendet" markiert und kann nicht erneut verwendet werden.'
                        )
                    }}
                </p>

                <p>
                    <b>{{ T("Attention") }}</b
                    >: {{ T("UTM Zero-Touch ist erst ab UTM Version 14.0 oder höher verfügbar.") }}
                </p>

                <p>
                    {{ T("Weitere Informationen finden Sie in unserem Wiki unter") }}
                    <a href="https://wiki.securepoint.de/USC/Zero-Touch" target="_blank">{{
                        T("UTM Zero-Touch")
                    }}</a
                    >.
                </p>

                <p class="margin-xs-t-4 text-center" style="font-size: 1.15em">
                    <btn :button-options="addProfileButton" />
                </p>
            </div>
        </div>
        <div class="col-xs-24 col-lg-8">
            <div class="box-shadow padding-xs-2 text-center" style="height: 100%">
                <img
                    :src="darkmode ? diagramImageDark : diagramImage"
                    style="
                        display: inline;
                        max-width: 400px;
                        width: 100%;
                        filter: grayscale(1);
                        opacity: 0.7;
                    "
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { T } from "@/classes/i18n"
import getterHelpers from "@/helpers/helpers.getters"
import diagramImageDark from "@/img/utmZtLandingpage_dark.svg"
import diagramImage from "@/img/utmZtLandingpage_light.svg"
import router from "@/router/router"
import { useStore } from "@/store/vuex.store"
import { computed, ref } from "vue"
import Button from "../button/button"
import btn from "../button/button.vue"

const darkmode = computed(() => {
    return getterHelpers.useStore().state.browserSettings.darkmode == "1"
})

const accountId = computed(() => {
    return useStore().state.session.activeAccountId || ""
})

const addProfileButton = ref(
    new Button({
        icon: "fa-plus",
        text: T("Zero-Touch-Profil jetzt hinzufügen"),
        title: T("Zero-Touch-Profil jetzt hinzufügen"),
        onClick: () => {
            router.navigate(`#add-tenant-${accountId.value}.sms-uscZtProfiles`)
        }
    })
)
</script>
