import config from "@/classes/config"
import products from "@/classes/objectTypes"
import deviceHelpers from "@/helpers/helpers.devices"
import encodingHelpers from "@/helpers/helpers.encoding"
import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"
import { useGlobalMixin } from "@/mixins/mixins.global"
import { ActionTypes, MutationTypes, useStore } from "@/store/vuex.store"
import loaderComponent from "../components/loader.vue"

const appInstallUninstallDialogComponent = {
    mixins: [useGlobalMixin()],
    name: "app-install-uninstall-dialog",
    template: `
    <div class="content-2">
      <template v-if="initialized">
        <p class="notification">
          <i class="fas fa-info-circle"></i> 
          <template v-if="properties.context == 'install'">
            {{ T('Once the install-process has started, you can close this window. The apps will still be installed.') }}
          </template>
          <template v-else>
            {{ T('Once the uninstall-process has started, you can close this window. The apps will still be uninstalled.') }}
          </template>
        </p>
        <hr>
        <div class="flexrow">
          <div class="col-xs content text-center" style="width:40%">
            <strong>{{ T('App') }}</strong>
          </div>
          <div class="flexrow">
            <div class="col-xs content text-center" style="width:20%">
              <strong>&nbsp;</strong>
            </div>
          </div>
          <div class="col-xs content text-center" style="width:40%">
            <strong>{{ T('Device') }}</strong>
          </div>
        </div>
        <hr>

        <template v-if="application?.affectedDeviceIds?.length === 0">
          <div>{{ T('No affected devices.') }}</div>
        </template>

        <template v-else>
          <template v-for="deviceId in application?.affectedDeviceIds || []">
            <div class="row app-install-status padding-xs-3">
              <div class="col-md-24 label-primary">
                <div class="row flexrow">
                  <div class="col-xs" style="width:40%">
                    <div class="installation-app-box content box-fullheight">
                      <i class="fal fa-fw fa-rocket"></i> {{ getAliasedShortDeviceId(application.appId, application.name) }}
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="col-xs position-relative appmap-connection" style="width:20%">
                    <div class="appmap-connector"></div>
                    <div 
                      class="installation-progress" 
                      :class="{'loading':deviceIdToStatus[deviceId] == 'PENDING' || deviceIdToStatus[deviceId] == 'SENT'}"
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <div class="col-xs" style="width:40%">
                    <div class="installation-phone-box content">
                      <i class="fas fa-mobile-alt"></i>
                      &nbsp;{{ getAliasedShortDeviceId(deviceId, deviceMap[deviceId] ? deviceMap[deviceId].alias : undefined) }}
                      <span class="install-status">
                        <small v-if="deviceIdToStatus[deviceId]"
                          :class="'label '+statusToLabelClass[deviceIdToStatus[deviceId]]">{{ deviceIdToStatus[deviceId] }}</small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
      </template>

      <template v-else>
        <div class="text-center padding-xs-y-8 col-xs">
          <div class="text-size-3"><loader class="text-size-2 color-red" /></div>
        </div>
      </template>
    </div>
  `,
    data: () => {
        return {
            initialized: true,
            deviceIdToStatus: <
                { [deviceid: string]: "PENDING" | "SENT" | "ACKNOWLEDGED" | "ERROR" }
            >{},
            statusToLabelClass: {
                PENDING: "bg-yellow",
                SENT: "",
                ACKNOWLEDGED: "bg-green",
                ERROR: "bg-red"
            }
        }
    },
    computed: {
        application(this: any) {
            return config.canUseNewObjectType("iosApps")
                ? products.mobileSecurity.iosApps
                      .useStore?.()
                      .getObjectStoreObject(this.activeAccountId, this.properties.appId)
                : useStore().getters.getObject({
                      accountId: this.activeAccountId,
                      objectType: "apps",
                      objectId: this.properties.appId
                  })
        },
        deviceMap(this: any) {
            return (
                config.canUseNewObjectType("iosDevices")
                    ? products.mobileSecurity.iosDevices
                          .useStore?.()
                          .getObjectStoreObjects(this.activeAccountId)
                    : useStore().getters.getObjectTypeStore({
                          accountId: this.activeAccountId,
                          objectType: "iosDevices"
                      })?.items || []
            ).reduce(function (deviceMap: any, device: any) {
                deviceMap[device.deviceId] = { alias: device.alias, type: device.deviceType }
                return deviceMap
            }, {})
        }
    },
    props: {
        properties: {
            required: true,
            default: () => {
                return {
                    appId: <string>"",
                    context: <"install" | "uninstall">"install"
                }
            }
        }
    },
    methods: {
        escapeHTML: encodingHelpers.escapeHTML,
        getAliasedShortDeviceId: deviceHelpers.getAliasedShortDeviceId
    },
    async created(this: any) {},
    async mounted(this: any) {
        getterHelpers.useStore().getters.getActiveModal(this.activeAccountId).buttons[1].loading =
            true
        if (config.canUseNewObjectType("iosApps")) {
            await products.mobileSecurity.iosApps.queries.getObjectFromApi(
                this.activeAccountId,
                this.properties.appId
            )
        } else {
            await useStore().dispatch(ActionTypes.getObjectInfo, {
                accountId: this.activeAccountId,
                objectId: this.properties.appId,
                productType: "mobileSecurity",
                objectType: "apps"
            })
        }
        if (config.canUseNewObjectType("iosDevices")) {
            await products.mobileSecurity.iosDevices.queries.getObjectsFromApi(this.activeAccountId)
        } else {
            await useStore().dispatch(ActionTypes.getObjectInfos, {
                accountId: this.activeAccountId,
                objectTypes: tenantHelpers.hasFunctionality(this.activeAccountId, "dep")
                    ? ["devices", "depDevices"]
                    : ["devices"]
            })
        }

        useStore().commit(MutationTypes.addSubscriptionHook, {
            accountId: this.activeAccountId,
            hookKey: "installOrUninstallApps",
            hookFunction: (message: any) => {
                if (
                    message.topic === "/message/out/create" ||
                    message.topic === "/message/out/update"
                ) {
                    var p = message.data?.payload
                    const application = this.application

                    const IsSameApp = (function () {
                        const sameIdentifier =
                            p?.Identifier != undefined
                                ? p?.Identifier == application.Identifier ||
                                  p?.Identifier == application.uninstallPackageName
                                : undefined
                        const sameiTunesStoreID =
                            p?.iTunesStoreID != undefined
                                ? p?.iTunesStoreID == application.iTunesStoreID
                                : undefined
                        const sameManifestURL =
                            p?.ManifestURL != undefined
                                ? p?.ManifestURL == application.ManifestURL
                                : undefined
                        const samepackage =
                            p?.Identipackagefier != undefined
                                ? p?.package == application.package
                                : undefined
                        return sameIdentifier || sameiTunesStoreID || sameManifestURL || samepackage
                    })()

                    if (IsSameApp) {
                        var deviceId = message.data?.deviceId
                        var status = message.data?.status
                        this.deviceIdToStatus[deviceId] = status
                    }
                }
            }
        })
        getterHelpers.useStore().getters.getActiveModal(this.activeAccountId).buttons[1].loading =
            false

        this.initialized = true
    },
    beforeUnmount(this: any) {
        useStore().commit(MutationTypes.deleteSubscriptionHook, {
            accountId: this.activeAccountId,
            hookKey: "installOrUninstallApps"
        })
    },
    watch: {},
    components: {
        loader: loaderComponent
    }
}
export default appInstallUninstallDialogComponent
