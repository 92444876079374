const stringHelpers = {
    capitalizeFirstLetter: function (string: string) {
        if (string) {
            string = string.toLowerCase()
            return string.charAt(0).toUpperCase() + string.slice(1)
        }
        return ""
    },
    generateRandomString: function (length: number): string {
        let result: string = ""
        const characters: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length))
        }
        return result
    },
    generateUUID: function () {
        let u: string =
            new Date().getTime().toString(16) +
            Math.random().toString(16).substring(2) +
            Math.random().toString(16).substring(2)
        var guid =
            u.substr(0, 8) +
            "-" +
            u.substr(8, 4) +
            "-4" +
            u.substr(12, 3) +
            "-8" +
            u.substr(15, 3) +
            "-" +
            u.substr(18, 12)
        return guid
    },
    removeSpaces: function (string: string) {
        return (string || "").replace(new RegExp(/ +/g), "")
    },
    outputStringMaxLength: function (string: string, maxLength: number = 0, postFix?: string) {
        if (string && maxLength > 0 && string.length > maxLength) {
            return string.slice(0, maxLength - (postFix || "").length) + (postFix ? postFix : "")
        } else {
            return string
        }
    }
}
export default stringHelpers
