<script setup lang="ts">
import { T } from "@/classes/i18n"
import objectStores from "@/classes/init"
import { type UscUtm } from "@/classes/unifiedSecurityConsole/uscUtms"
import dialogs from "@/dialogs/dialogs"
import mixinHelpers from "@/helpers/helpers.mixins"
import { ActionTypes, useStore } from "@/store/vuex.store"
import { computed, inject, onMounted, ref, watch } from "vue"
import inputSelectComponent from "../inputtypes/input-select"
import inputTextComponent from "../inputtypes/input-text"
import inputVueSelectVue from "../inputtypes/input-vue-select.vue"
import Label from "./label/label"
import LabelComponent from "./label/label.vue"
import loaderComponent from "./loader.vue"

const newValue = ref(<string | string[]>"")
const loading = ref(true)
const selectOptions = ref([] as selectOption[])

const darkmode = computed(() => {
    return mixinHelpers.getDarkmode()
})
const activeAccountId = computed(() => {
    return mixinHelpers.getActiveAccountId()
})
const productType = computed(() => {
    return mixinHelpers.getProductType() || ""
})
const objectType = computed(() => {
    return mixinHelpers.getObjectType() || ""
})
const showUtmProfileError = ref(false)

const utmProfileErrorLabel = new Label({
    class: "bg-red",
    icon: {
        class: "fal fa-exclamation-triangle"
    },
    title: T(
        "Über dieses Tag weisen Sie dieser Cluster-UTM ein Profil zu. Bitte beachten Sie, dass es dadurch zu Inkonsistenzen im Cluster kommen kann. Nutzen Sie bei Bedarf wenn möglich die direkte Zuweisung der UTM an ein Profil."
    )
})

const error = inject("itemlistItemError") as any
const errorMsg = inject("itemlistItemErrorMessage") as any
const props = withDefaults(
    defineProps<{
        inputType: string
        submitFunction: Function | ((value: any) => Promise<any>)
        abortFunction: Function | (() => Promise<any>)
        value?: any
        select2Settings?: any
        options?: any
        placeholder?: string
        object: any
    }>(),
    {
        options: undefined,
        select2Settings: undefined,
        placeholder: ""
    }
)

function $getProductTypeFromObjectType(objectType: string) {
    return mixinHelpers.$getProductTypeFromObjectType(objectType)
}
function $getSelectOptionsForObjectType(accountId: string, objectType: string) {
    return mixinHelpers.$getSelectOptionsForObjectType(accountId, objectType)
}
function $tenantItemNeedsRefresh(accountId: string, productType: string, objectType: string) {
    return mixinHelpers.$tenantItemNeedsRefresh(accountId, productType, objectType)
}

async function init() {
    newValue.value = props.value as any
    let productType: any = ""
    if (["select", "select2"].indexOf(props.inputType) != -1) {
        if (typeof props.options == "string") {
            try {
                productType = $getProductTypeFromObjectType(props.options)
                if ($tenantItemNeedsRefresh(activeAccountId.value, productType, props.options)) {
                    let status: any = await useStore().dispatch(ActionTypes.getObjectInfos, {
                        accountId: activeAccountId.value,
                        objectTypes: [
                            props.options == "users" ? "users?props[]=username" : props.options
                        ]
                    })
                    if (status != true) {
                        throw "Error fetching " + props.options
                    }
                }

                let thisSelectOptions: selectOption[] = await $getSelectOptionsForObjectType(
                    activeAccountId.value,
                    props.options
                )
                if (props.options == "users") {
                    thisSelectOptions = thisSelectOptions.sort(function (
                        a: selectOption,
                        b: selectOption
                    ) {
                        const firstElement = a.text.toLowerCase()
                        const secondElement = b.text.toLowerCase()
                        if (firstElement > secondElement) return 1
                        if (firstElement < secondElement) return -1
                        return 0
                    })
                    thisSelectOptions.push({
                        id: "noUserSelected",
                        text: T("none")
                    })
                }
                selectOptions.value = thisSelectOptions
            } catch (e: any) {
                console.error(e)
                let thisErrorMsg = T("Error")
                if (e?.errors?.errors?.[0]?.message != undefined) {
                    thisErrorMsg = e?.errors?.errors?.[0]?.message
                }
                error.value = true
                errorMsg.value = thisErrorMsg

                setTimeout(function () {
                    error.value = false
                    errorMsg.value = ""
                }, 4000)
                props.abortFunction()
            }
        } else {
            selectOptions.value = props.options
        }
    }
    loading.value = false
}

function submit() {
    loading.value = true
    if (props.submitFunction && typeof props.submitFunction == "function") {
        props.submitFunction(newValue.value)
    }
}
// value watcher
watch(newValue, (newValue, oldValue) => {
    // check for utm profiles with new added tag
    if (
        Array.isArray(newValue) &&
        Array.isArray(oldValue) &&
        objectType.value == "uscUtms" &&
        (<UscUtm>props.object)?.cluster?.isCluster == true
    ) {
        let addedTag: string | string[] = newValue.filter((tag: string) => {
            return oldValue.indexOf(tag) == -1
        })
        if (addedTag.length >= 1) {
            addedTag = addedTag[0]
            const uscProfiles = objectStores.uscProfiles.getObjectsFromStore(activeAccountId.value)
            const tagExistsInProfile = uscProfiles.some((profile) => {
                return profile.tags.indexOf(addedTag as string) != -1
            })

            if (tagExistsInProfile) {
                showUtmProfileError.value = true
                dialogs.misc.confirmDialog(
                    activeAccountId.value,
                    T("Tag wird in UTM Profilen verwendet"),
                    T(
                        "Über dieses Tag weisen Sie dieser Cluster-UTM ein Profil zu. Bitte beachten Sie, dass es dadurch zu Inkonsistenzen im Cluster kommen kann. Nutzen Sie bei Bedarf wenn möglich die direkte Zuweisung der UTM an ein Profil."
                    ),
                    () => {},
                    undefined,
                    T("Close"),
                    "fal fa-times",
                    false,
                    "fal fa-exclamation-triangle"
                )
            }
        }
    }
})

onMounted(() => {
    init()
})
</script>
<template>
    <span v-on:click.stop v-on:keyup.enter="submit()">
        <template v-if="inputType == 'text'">
            <inputTextComponent
                type="text"
                v-model="newValue"
                :value="newValue"
                :disabled="loading || null"
                v-on:keyup.enter="submit()"
                style="
                    display: inline-block;
                    width: calc(100% - 90px);
                    min-width: 90px;
                    min-height: 1em;
                    padding: 0px 8px 0 4px;
                "
            />
        </template>
        <template v-if="inputType == 'select' && !loading">
            <inputSelectComponent
                v-model="newValue"
                :value="newValue"
                :disabled="loading || null"
                style="
                    display: inline-block;
                    width: calc(100% - 90px);
                    min-width: 90px;
                    min-height: 1em;
                    padding: 0px 8px 0 4px;
                    background-position: center right;
                "
                :selectOptions="selectOptions"
            />
        </template>
        <template v-if="inputType == 'select2' && !loading">
            <span
                style="
                    display: inline-block;
                    min-width: 90px;
                    position: relative;
                    vertical-align: middle;
                "
                :style="'width: calc(100% - ' + (showUtmProfileError ? '120px' : '90px') + ');'"
            >
                <inputVueSelectVue
                    v-model="newValue"
                    :value="newValue"
                    :selectOptions="selectOptions"
                    :bindSettingsToSelect2="select2Settings"
                    :placeholder="
                        select2Settings && select2Settings.placeholder
                            ? T(select2Settings.placeholder)
                            : T(placeholder)
                    "
                    :multiple="(select2Settings && select2Settings.multiple) || false"
                    :tags="(select2Settings && select2Settings.tags) || false"
                    :inputMask="props.options == 'tags' ? 'tags' : undefined"
                    :noTranslations="true"
                />
            </span>
        </template>
        &nbsp;&nbsp;
        <template v-if="!loading">
            <a
                v-on:click.stop="submit()"
                class="btn padding-xs-y-0"
                :class="darkmode == '1' ? 'btn-darkbluegrey' : 'btn-white color-gray'"
            >
                <i class="fal fa-save"></i>
            </a>
            <a
                v-on:click.stop="abortFunction()"
                class="btn padding-xs-y-0"
                :class="darkmode == '1' ? 'btn-darkbluegrey' : 'btn-white color-gray'"
            >
                <i class="fal fa-times"></i>
            </a>
            <template v-if="showUtmProfileError == true">
                <LabelComponent
                    style="position: relative; line-height: 17px; top: 0.75px"
                    :label="utmProfileErrorLabel"
                >
                </LabelComponent>
            </template>
        </template>
        <loaderComponent v-if="loading" />
    </span>
</template>
