<template>
    <section id="secureDnsLogs">
        <div class="row margin-xs-0" v-if="accountInitialized != undefined">
            <div class="col-xs-24 padding-xs-x-4 padding-xs-t-4">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="padding-xs-t padding-xs-l">
                            <div class="toolbar flexrow">
                                <div class="col-xs padding-xs-0 padding-xs-b padding-xs-r">
                                    <label class="form-field margin-xs-b-0">
                                        <span class="form-icon-prefix"
                                            ><i class="fal fa-pen"></i
                                        ></span>
                                        <select v-model="itemsPerPage" class="form-control">
                                            <option value="50">{{ "50 " + T("Entries") }}</option>
                                            <option value="100">{{ "100 " + T("Entries") }}</option>
                                            <option value="500">{{ "500 " + T("Entries") }}</option>
                                            <option value="1000">
                                                {{ "1000 " + T("Entries") }}
                                            </option>
                                        </select>
                                    </label>
                                </div>

                                <div
                                    class="col-xs"
                                    style="margin-bottom: 8px; margin-right: 8px"
                                    :title="T('Period')"
                                >
                                    <label class="form-field margin-xs-b-0">
                                        <span class="form-icon-prefix"
                                            ><i class="fal fa-clock"></i
                                        ></span>
                                        <select v-model="period" class="form-control">
                                            <option value="today">{{ T("Today") }}</option>
                                            <option value="lastday">{{ T("Yesterday") }}</option>
                                            <option value="currentweek">
                                                {{ T("Current week") }}
                                            </option>
                                            <option value="currentmonth">
                                                {{ T("Current month") }}
                                            </option>
                                            <option value="last30days">
                                                {{ T("Last 30 days") }}
                                            </option>
                                        </select>
                                    </label>
                                </div>

                                <div class="col-xs padding-xs-0 padding-xs-b padding-xs-r-3">
                                    <label class="form-inputgroup margin-xs-b-0">
                                        <span class="form-icon-prefix">
                                            <i class="fal fa-search"></i>
                                        </span>
                                        <input
                                            id="itemlistSearch"
                                            type="text"
                                            v-model="filter"
                                            :placeholder="T('Search')"
                                        />
                                    </label>
                                </div>

                                <div
                                    class="col-xs-3"
                                    style="margin-bottom: 8px; margin-right: 4px"
                                    :title="T('Automatic refresh')"
                                >
                                    <label class="form-field margin-xs-b-0">
                                        <span class="form-icon-prefix"
                                            ><i class="fal fa-stopwatch"></i
                                        ></span>
                                        <select v-model="autoRefreshInterval" class="form-control">
                                            <!-- value = seconds -->
                                            <option value="0">{{ T("Disabled") }}</option>
                                            <option value="30">{{ `30 ${T("seconds")}` }}</option>
                                            <option value="60">{{ `1 ${T("minute")}` }}</option>
                                            <option value="300">{{ `5 ${T("minutes")}` }}</option>
                                        </select>
                                    </label>
                                </div>

                                <div
                                    class="btn-toolbar"
                                    style="margin-bottom: 8px; margin-right: 8px"
                                >
                                    <div class="btn-group">
                                        <a
                                            id="itemlistButtonRefresh"
                                            class="btn btn-menu btn-loader twist-in"
                                            style="max-height: 34.5px; max-width: 35.75px"
                                            v-on:click="load(true)"
                                            :class="{ 'btn-loading': refreshing }"
                                        >
                                            <span class="animate">
                                                <i
                                                    v-if="refreshing"
                                                    class="progress-circular no-progress"
                                                    style="
                                                        font-size: 1.5em;
                                                        top: 50%;
                                                        position: absolute;
                                                        left: 50%;
                                                        transform: translate(-50%, -50%);
                                                    "
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="1em"
                                                        height="1em"
                                                        viewBox="0 0 50 50"
                                                        style="stroke-width: 4px"
                                                    >
                                                        <circle
                                                            cx="25"
                                                            cy="25"
                                                            r="20"
                                                            style="
                                                                stroke: rgba(0, 0, 0, 0.1);
                                                                fill: none;
                                                            "
                                                        ></circle>
                                                        <g transform="translate(25,25) rotate(-90)">
                                                            <circle
                                                                stroke-dasharray="110"
                                                                stroke-dashoffset="0"
                                                                cx="0"
                                                                cy="0"
                                                                r="20"
                                                                style="
                                                                    fill: none;
                                                                    stroke-linecap: round;
                                                                "
                                                                transform="rotate(14.0181)"
                                                            >
                                                                <animate
                                                                    attributeName="stroke-dashoffset"
                                                                    values="360;140"
                                                                    dur="2.2s"
                                                                    keyTimes="0;1"
                                                                    calcMode="spline"
                                                                    fill="freeze"
                                                                    keySplines="0.41,0.314,0.8,0.54"
                                                                    repeatCount="indefinite"
                                                                    begin="0"
                                                                ></animate>
                                                                <animate
                                                                    attributeName="stroke"
                                                                    fill="freeze"
                                                                    dur="8s"
                                                                    begin="0"
                                                                    repeatCount="indefinite"
                                                                ></animate>
                                                            </circle>
                                                        </g>
                                                    </svg>
                                                </i>
                                            </span>
                                            <span v-if="!refreshing"
                                                ><i
                                                    :title="T('Refresh')"
                                                    class="fal fa-fw fa-sync"
                                                ></i
                                            ></span>
                                            <span v-if="refreshing">
                                                <loaderComponent class="text-size-2 color-red" />
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-if="!loadingAnimation">
                            <tableNext
                                :objectList="logEntries"
                                :selectable-columns="tableStructure"
                                :isSearchable="false"
                                :isFilterable="false"
                                :hasOptions="false"
                                :maxHeight="500"
                            />

                            <div class="pagination">
                                <button @click="previousPage" :disabled="currentPage === 1">
                                    &laquo; Zurück
                                </button>
                                <span>Seite {{ currentPage }} von {{ totalPages }}</span>
                                <button @click="nextPage" :disabled="currentPage === totalPages">
                                    Weiter &raquo;
                                </button>
                            </div>
                        </template>
                        <template v-else>
                            <div style="text-align: center; padding: 122px 0">
                                <loaderComponent class="color-red text-size-3"></loaderComponent>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { T } from "@/classes/i18n"
import frontendNotifications from "@/classes/notifications"
import secureDns from "@/classes/objectTypes/mobileSecurity/secure-dns/secure-dns"
import dialogs from "@/dialogs/dialogs"
import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"
import router from "@/router/router"
import Button from "@/templates/components/button/button"
import Label from "@/templates/components/label/label"
import loaderComponent from "@/templates/components/loader.vue"
import moment from "moment"
import { debounce } from "throttle-debounce"
import { computed, onMounted, onUnmounted, reactive, ref, watch } from "vue"
import { useStore } from "vuex"
import tableNext, { type TableEntryInfo } from "../components/tableNext.vue"

interface ProfileArrayData {
    [key: string]: string[]
}

const activeAccountId = computed(() => {
    return useStore().state.session.activeAccountId
})

const accountInitialized = computed(() => {
    return useStore().state.session.accounts[activeAccountId.value || ""].initialized
})

const autoRefreshIntervalStorageKey = `secdns:protocols:${activeAccountId.value}:autoRefreshInterval`
const autoRefreshInterval = ref(localStorage.getItem(autoRefreshIntervalStorageKey) || "30")
watch(autoRefreshInterval, () =>
    localStorage.setItem(autoRefreshIntervalStorageKey, autoRefreshInterval.value)
)

const itemsPerPageStorageKey = `secdns:protocols:${activeAccountId.value}:itemsPerPage`
const itemsPerPage = ref(parseInt(localStorage.getItem(itemsPerPageStorageKey) || "0") || 50)
watch(itemsPerPage, () =>
    localStorage.setItem(itemsPerPageStorageKey, itemsPerPage.value.toString())
)

const periodStorageKey = `secdns:protocols:${activeAccountId.value}:period`
const period = ref(localStorage.getItem(periodStorageKey) || "currentweek")
watch(period, () => localStorage.setItem(periodStorageKey, period.value))

const filter = ref("")

const autoRefreshTimer = ref<ReturnType<typeof setInterval> | null>(null)
const loadingAnimation = ref(false)
const refreshing = ref(false)
const allowedDomains = ref<ProfileArrayData>({})
const blockedDomains = ref<ProfileArrayData>({})
const successStatusCodes = new Set(["NOERROR", "NXDOMAIN"])

const totalEntries = ref(0)
const logEntries = ref<any[]>([])
const secDnsIdNameMapping = ref<{ [id: string]: string }>({})
const currentPage = ref(1)
const totalPages = computed(() => Math.ceil(totalEntries.value / itemsPerPage.value) || 1)
const offset = computed(() => (currentPage.value - 1) * itemsPerPage.value)

const tableStructure: TableEntryInfo[] = [
    {
        property: "domain",
        text: T("Domain"),
        displayType: "text",
        // "flagIcon": (item: any) => item.data.countries.find((country: string) => !!country),
        getValue: (item: any) => item.data.domain || T("No info")
    },
    {
        property: "country",
        text: T("Country"),
        displayType: "text",
        flagIcon: (item: any) => item.data.countries.find((country: string) => !!country) || null,
        getValue: (item: any) => "",
        width: 50
    },
    {
        property: "type",
        text: T("Typ"),
        displayType: "labels",
        getValue: (item: any) => {
            return item.data.types.sort().map((type: string) => {
                return new Label({
                    text: type
                })
            })
        }
    },
    {
        property: "client",
        text: T("Client"),
        displayType: "labels",
        getValue: (item: any) => {
            const labels = []

            if (item.data.deviceName) {
                labels.push(
                    new Label({
                        text: item.data.deviceName,
                        icon: "fa fa-user"
                    })
                )
            }

            // fallback
            if (labels.length === 0) {
                labels.push(
                    new Label({
                        text: T("No info"),
                        icon: "fa fa-ban"
                    })
                )
            }

            return labels
        }
    },
    {
        property: "profile",
        text: T("Profile"),
        displayType: "labels",
        getValue: (item: any) => {
            const profileId = item.data.profileId
            const profileName = secDnsIdNameMapping.value[profileId]

            return [
                new Label({
                    text: profileName || "N/A",
                    ...(profileName && {
                        onClick: () => {
                            router.navigate(
                                "edit-tenant-" +
                                    activeAccountId.value +
                                    ".sms-secureDnsProfiles-" +
                                    item.data.profileId
                            )
                        }
                    })
                })
            ]
        }
    },
    {
        property: "time",
        text: T("Time"),
        displayType: "text",
        getValue: (item: any) => {
            const momentDate = moment.utc(item.data.windowStart).local()

            return `${moment(momentDate).fromNow()} (${momentDate.format("DD.MM HH:mm")})`
        }
    },
    {
        property: "status",
        text: T("Status"),
        displayType: "status",
        getValue: (item: any) => {
            const blockReason = item.data.blockReason

            if (blockReason === null) {
                return {
                    color: "green",
                    statusText: "OK",
                    tooltip: "OK"
                }
            } else {
                return {
                    color: "red",
                    statusText: `${T("Blocked")} (${tenantHelpers.secureDnsCategoryIdToName(blockReason)})`,
                    tooltip: T("Blocked")
                }
            }
        }
    },
    {
        property: "reply",
        text: T("Reply"),
        displayType: "labels",
        getValue: (item: any) => {
            let status = item.data.status
            let icon = "check"

            if (!status || status === "RCODE_UNK") {
                status = T("Unknown")
                icon = "question"
            } else if (!successStatusCodes.has(status)) {
                icon = "xmark"
            }

            return [
                new Label({
                    text: status,
                    icon: "fa fa-" + icon
                })
            ]
        }
    },
    {
        property: "action",
        text: T("Actions"),
        displayType: "buttons",
        width: 120,
        getValue: (item: any) => {
            const { profileId, domain, blockReason } = item.data
            const disable = domain === null
            let action: "block" | "allow" = "block"
            let icon = "fa-ban"
            let text = T("Block")

            if (!secDnsIdNameMapping.value[profileId]) {
                return []
            }

            const domainMatchesAllowedDomain = allowedDomains.value[profileId].find((d: string) =>
                d.endsWith(domain)
            )
            const domainMatchesBlockedDomain = blockedDomains.value[profileId].find((d: string) =>
                d.endsWith(domain)
            )

            if (
                domainMatchesBlockedDomain ||
                (!domainMatchesAllowedDomain && blockReason !== null)
            ) {
                action = "allow"
                icon = "fa-check-circle"
                text = T("Allow")
            }

            const button = reactive(
                new Button({
                    text: text,
                    icon: "fal fa-fw " + icon,
                    disabled: disable,
                    onClick: async () => {
                        button.loading = true
                        const resp = await handleDomainAction(domain, profileId, action)
                        button.loading = false

                        if (resp) {
                            if (action === "allow") {
                                allowedDomains.value[profileId].push(domain)
                                blockedDomains.value[profileId] = blockedDomains.value[
                                    profileId
                                ].filter((d) => d !== domain)
                            } else {
                                allowedDomains.value[profileId] = allowedDomains.value[
                                    profileId
                                ].filter((d) => d !== domain)
                                blockedDomains.value[profileId].push(domain)
                            }
                        } else {
                            frontendNotifications.addNotification(activeAccountId.value, {
                                accountId: activeAccountId.value,
                                content: {
                                    body: {
                                        content: T("Something went wrong, please try again.")
                                    },
                                    title: {
                                        text: T("Error"),
                                        icon: "fal fa-exclamation-triangle"
                                    }
                                },
                                highlightNew: true
                            })
                        }
                    }
                })
            )

            return [button]
        }
    }
]

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--
    }
}

function nextPage() {
    if (currentPage.value < totalPages.value) {
        currentPage.value++
    }
}

async function load(loadProfiles = false, animate = true) {
    if (animate) {
        loadingAnimation.value = true
    }

    refreshing.value = true

    const data = await requestHandler.request(
        "POST",
        "/sms-mgt-api/api/2.0/tenants/" +
            tenantHelpers.getTenantDomain(activeAccountId.value || "") +
            "/stats/execute",
        {
            query: {
                modul: "SecureDns",
                name: "Protocols",
                options: {
                    size: parseInt(itemsPerPage.value as any as string),
                    offset: offset.value,
                    period: period.value,
                    ...(!!filter.value && {
                        search: filter.value
                    })
                }
            }
        }
    )

    totalEntries.value = data.result.totalCount
    logEntries.value = data.result.entries.map((entry: any) => ({ data: entry }))

    if (loadProfiles) {
        const items = await secureDns.queries.getObjectsFromApi(activeAccountId.value || "")
        if (items instanceof Error) {
            dialogs.misc.errorDialog(
                activeAccountId.value,
                T("Error"),
                T("Failed to load secdns profiles")
            )
            console.error("failed to load secdns profiles", items)

            return
        }

        items.forEach((item) => {
            allowedDomains.value[item.id] = item.allowlist.hosts
            blockedDomains.value[item.id] = item.denylist.hosts
            secDnsIdNameMapping.value[item.id] = item.name
        })
    }

    loadingAnimation.value = false
    refreshing.value = false
}

async function handleDomainAction(domain: string, profileId: string, action: "allow" | "block") {
    try {
        const resp = await requestHandler.request(
            "PUT",
            `/sms-mgt-api/api/2.0/tenants/${tenantHelpers.getTenantDomain(activeAccountId.value || "")}/secure-dns/configs/${profileId}/domain-action`,
            [
                {
                    domain,
                    action
                }
            ]
        )

        if (resp?.errors?.valid) {
            return true
        } else {
            return false
        }
    } catch (e) {
        return false
    }
}

function updateAutoRefreshTimer(val: string) {
    if (val === "0") {
        return
    }

    autoRefreshTimer.value = setInterval(
        async () => {
            await load(false, false)
        },
        parseInt(val) * 1000
    )
}

const loadDebounce = debounce(500, load)

onMounted(async () => {
    await load(true)
    updateAutoRefreshTimer(autoRefreshInterval.value)
})

onUnmounted(() => {
    if (autoRefreshTimer.value) {
        clearInterval(autoRefreshTimer.value)
    }
})

watch(filter, async () => {
    await loadDebounce()
})

watch([itemsPerPage, currentPage, period], async () => {
    await load()
})

watch([itemsPerPage, period, filter], async () => {
    currentPage.value = 1
})

watch(autoRefreshInterval, (newVal) => {
    if (autoRefreshTimer.value) {
        clearInterval(autoRefreshTimer.value)
    }

    updateAutoRefreshTimer(newVal)
})
</script>

<style scoped>
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 10px;
}

.pagination span {
    margin: 0 10px;
}
</style>
