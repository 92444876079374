import dialogs from "@/dialogs/dialogs"
import deviceHelpers from "@/helpers/helpers.devices"
import encodingHelpers from "@/helpers/helpers.encoding"
import getterHelpers from "@/helpers/helpers.getters"
import numberHelpers from "@/helpers/helpers.numbers"
import stringHelpers from "@/helpers/helpers.strings"
import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"
import router from "@/router/router"
import { MutationTypes, useStore } from "@/store/vuex.store"
import Button from "@/templates/components/button/button"
import jsonHelpers from "../../helpers/helpers.json"
import {
    GenericObjectStore,
    type GenericObject,
    type GetPropertiesObjectList,
    type ItemlistDetail,
    type Label,
    type MenuEntry,
    type shemaErrors
} from "../genericObjectStore"
import { T } from "../i18n"
import devLog from "../log"
import type { UscUtm } from "./uscUtms"

export interface UscProfile extends GenericObject<UscProfile> {
    name: string
    id: string
    priority?: number
    profile?: {
        autoUpdateOnce: boolean
        autoUpdateCronValues: string
        autoUpdateEnabled: boolean
        update: {
            enabled: boolean
            enabledUpdate: boolean
            cronValue: string
        }
    }
    template?: {
        tenantDomains?: string[]
        uuid: string
        childProfiles?: { [tenantDoman: string]: string } // tenantDomain:uuid
    }
    status: string
    tags: string[]
    tenantDomain: string
    comment: string
    utms: string[]
}

export default class UscProfiles extends GenericObjectStore<UscProfile> {
    constructor() {
        super()
        this.settings.primaryKeyProperty = "id"
        this.settings.nameProperty.primary = "name"
        this.settings.productType = "unifiedSecurityConsole"
        this.settings.objectType = "uscProfiles"
        this.settings.appearance.iconClass = "fal fa-shield"
        this.settings.appearance.text.singular = "Profile"
        this.settings.appearance.text.plural = "Profiles"
        this.settings.appearance.text.title = "Profiles"
        this.settings.apiInfo.listPath = "/tenants/{tenantDomain}/utms/profiles"
        this.settings.apiInfo.objectListPropertyInResponse = "utmProfiles"
        this.settings.apiInfo.url = "/sms-mgt-api/api/2.0"
        this.itemlist.getToolbar = (accountId, itemlistComponent) => {
            return [
                {
                    icon: "fal fa-plus",
                    title: T("Add profile"),
                    link:
                        "#add-tenant-" + tenantHelpers.getTenantDomain(accountId) + "-usc-profiles",
                    id: "addUscProfile"
                }
            ]
        }
        // setup itemlist options
        this.itemlist.isDisabled = (accountId, item): boolean => {
            let result = false
            return result
        }
        this.itemlist.isClickable = (accountId, item): boolean => {
            let result = true
            return result
        }
        this.itemlist.onClick = (accountId: string, item) => {
            let objectId = item[this.settings.primaryKeyProperty] as string
            router.navigate(
                "edit-tenant-" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "-usc-profiles-" +
                    objectId
            )
        }
        this.itemlist.hasCheckBox = false
        this.itemlist.getTitle = (item, component) => {
            return {
                title: item?.name
            }
        }
        this.itemlist.getStatus = (accountId: string, item) => {
            return undefined
        }

        this.itemlist.getMenuEntries = (accountId: string, item) => {
            let menuEntries: MenuEntry[] = []
            if (item) {
                let objectId = item[this.settings.primaryKeyProperty] as string

                if (item?.template?.childProfiles != undefined) {
                    menuEntries.push(
                        new Button({
                            text: T("Edit"),
                            title: T("Edit"),
                            icon: "fal fa-edit",
                            onClick: () => {
                                router.navigate(
                                    "edit-tenant-" +
                                        tenantHelpers.getTenantDomain(accountId) +
                                        "-usc-profiles-" +
                                        objectId
                                )
                            }
                        })
                    )
                }
                if (item?.template == undefined) {
                    menuEntries.push(
                        new Button({
                            text: T("Edit"),
                            title: T("Edit"),
                            icon: "fal fa-edit",
                            onClick: () => {
                                router.navigate(
                                    "edit-tenant-" +
                                        tenantHelpers.getTenantDomain(accountId) +
                                        "-usc-profiles-" +
                                        objectId
                                )
                            }
                        })
                    )
                }

                if (item?.template?.childProfiles != undefined) {
                    menuEntries.push(
                        new Button({
                            text: T("Delete"),
                            title: T("Delete"),
                            icon: "fal fa-trash",
                            onClick: () => {
                                this.dialogs.getDeleteTemplateObjectDialog(accountId, item)
                            }
                        })
                    )
                } else if (item?.template?.uuid == undefined) {
                    menuEntries.push(
                        new Button({
                            text: T("Delete"),
                            title: T("Delete"),
                            icon: "fal fa-trash",
                            onClick: () => {
                                this.dialogs.getDeleteObjectDialog(accountId, item)
                            }
                        })
                    )
                }
            }

            return menuEntries
        }
        this.itemlist.getLabels = (accountId: string, item) => {
            let result: Label[] = []
            if (item?.template?.tenantDomains != undefined) {
                result.push({
                    text: T("Cross-tenant profile"),
                    title: T("Cross-tenant profile"),
                    icon: "fa fa-circle-nodes"
                })
            }
            if (item?.priority != null) {
                result.push({
                    title: T("Priority") + ": " + item?.priority.toString(),
                    text: item.priority.toString(),
                    class: "",
                    icon: "fal fa-sort"
                })
            }
            if (item?.template?.tenantDomains == undefined && item?.template?.uuid != undefined) {
                result.push({
                    text: T("Generated"),
                    title: T("Generated from Cross-tenant profile"),
                    icon: "fa fa-cog"
                })
            }
            return result
        }
        this.itemlist.getDetails = (accountId: string, item, component) => {
            let result: ItemlistDetail[] = []

            let thisTagsArray = item?.tags?.map(function (tag) {
                return {
                    id: tag,
                    text: tag,
                    title: tag,
                    onClick: function () {},
                    displayType: "label"
                }
            })

            const utms: UscUtm[] =
                useStore()?.getters.getObjectTypeStore({
                    accountId: String(useStore()?.state.session.activeAccountId),
                    objectType: "uscUtms"
                }).items || []

            const thisUtms: Label[] = utms
                ?.filter((utm) => {
                    return item ? item.utms.indexOf(utm.utmId) != -1 : false
                })
                ?.map((utm) => {
                    return {
                        text: deviceHelpers.getAliasedShortDeviceId(utm.utmId, utm.utmname, false),
                        title: deviceHelpers.getAliasedShortDeviceId(utm.utmId, utm.utmname, false),
                        displayType: "label",
                        onClick: () => {
                            router.navigate(
                                "show-tenant-" +
                                    tenantHelpers.getTenantDomain(accountId) +
                                    "-usc-utms-" +
                                    utm.utmId
                            )
                        }
                    }
                })
            result.push({
                iconClass: "fal fa-tags",
                key: T("Tags"),
                title: T("Tags"),
                [item ? "labels" : "value"]: item
                    ? thisTagsArray
                    : '<span class="content-placeholder" style="width:' +
                      numberHelpers.getRandomArbitrary(50, 250) +
                      'px;" title="' +
                      T("Loading...") +
                      '"></span>',
                editableContent: undefined
            })
            if (item?.template?.tenantDomains) {
                result.push({
                    iconClass: "fal fa-tags",
                    key: T("Tenants"),
                    title: T("Tenants"),
                    [item?.template?.tenantDomains?.length ? "labels" : "value"]: item?.template
                        ?.tenantDomains.length
                        ? item?.template?.tenantDomains.map((tenantDomain: string) => {
                              let accountInfo = useStore()?.getters.getAccountInfo(
                                  tenantHelpers.getAccountId(tenantDomain)
                              )
                              return {
                                  text: accountInfo?.accountname + " (" + tenantDomain + ")",
                                  title: accountInfo?.accountname + " (" + tenantDomain + ")",
                                  displayType: "label"
                              }
                          })
                        : "",
                    editableContent: undefined
                })
            } else {
                result.push({
                    iconClass: "fal fa-tags",
                    key: T("UTMs"),
                    title: T("UTMs"),
                    [item?.utms.length ? "labels" : "value"]: item?.utms.length ? thisUtms : "",
                    editableContent: undefined
                })
            }
            result.push({
                iconClass: "fal fa-sync",
                key: T("Auto Updates"),
                title: T("Auto Updates"),
                value: item
                    ? item.profile?.autoUpdateEnabled || item.profile?.update?.enabledUpdate
                        ? item.profile?.autoUpdateOnce
                            ? T("Once (deprecated)")
                            : T("Enabled")
                        : T("Disabled")
                    : '<span class="content-placeholder" style="width:' +
                      numberHelpers.getRandomArbitrary(50, 250) +
                      'px;" title="' +
                      T("Loading...") +
                      '"></span>',
                editableContent: undefined
            })

            return result
        }

        this.itemlist.sortingOptions = [
            {
                id: "name",
                text: "Name"
            },
            {
                id: "priority",
                text: T("Priority")
            }
        ]

        this.dialogs.getDeleteTemplateObjectDialog = (accountId: string, item: UscProfile) => {
            let singularOfObjectType = this.settings.appearance.text.singular.toLocaleLowerCase()
            let objectIdProperty = this.settings.primaryKeyProperty
            dialogs.misc.confirmDialog(
                accountId,
                T("Confirm delete"),
                T("Do you really want to delete this " + singularOfObjectType + "?") +
                    "<br><br><strong>" +
                    T("Warning") +
                    "</strong><p>" +
                    T(
                        "This will also delete the automatic generated profiles for all affected tenants"
                    ) +
                    "</p>",
                async () => {
                    let deleteProfiles = []
                    await this.deleteObjectFromApi(accountId, item[objectIdProperty] as string)
                    const existingTenants: string[] =
                        item.template?.childProfiles != undefined
                            ? Object.keys(item.template.childProfiles)
                            : []
                    for (let i = 0; existingTenants.length > i; i++) {
                        if (item.template?.childProfiles) {
                            deleteProfiles.push(
                                existingTenants[i] +
                                    "/" +
                                    item.template.childProfiles[existingTenants[i]]
                            )
                        }
                    }
                    this.dialogs.getManyTemplateRequestsDialog(
                        accountId,
                        "delete",
                        undefined,
                        undefined,
                        undefined,
                        deleteProfiles
                    )
                }
            )
        }

        this.dialogs.getManyTemplateRequestsDialog = (
            accountId: string,
            type: "add" | "update" | "delete",
            childObject?: UscProfile,
            addProfiles?: string[],
            updateProfiles?: string[],
            deleteProfiles?: string[]
        ) => {
            let modal: any = {
                accountId: accountId,
                id: "utmsUpdateCheck",
                abortable: true,
                content: {
                    title: {
                        text:
                            type == "add"
                                ? T("Add profiles")
                                : type == "update"
                                  ? T("Update profiles")
                                  : type == "delete"
                                    ? T("Delete profiles")
                                    : "",
                        icon: "fal fa-info-circle"
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component: "uscProfiles-many-requests-dialog",
                        properties: {
                            childObject: childObject,
                            addProfiles: addProfiles,
                            updateProfiles: updateProfiles,
                            deleteProfiles: deleteProfiles
                        }
                    }
                },
                buttons: [
                    {
                        text: T("Cancel"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            getterHelpers
                                .useStore()
                                .commit(MutationTypes.removeModal, { accountId })
                        },
                        align: "left",
                        loading: false
                    }
                ]
            }
            useStore()?.commit(MutationTypes.addModal, modal)
        }
    }

    async getCountFromApi(accountId: string, updateLocalStore: boolean = true) {
        let result: number | Error = 0
        try {
            const countResult = await requestHandler.request(
                "GET",
                this.settings.apiInfo.url +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms/profiles?select=data.count"
            )
            if (typeof countResult === "number") {
                result = countResult
                if (updateLocalStore) {
                    this.setCount(accountId, countResult)
                }
            } else if (countResult.error) {
                throw new Error(countResult.error)
            } else {
                throw new Error("Error getting count for profiles")
            }
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
            } else {
                result = new Error("Error getting count for profiles")
            }
            devLog.log("UscProfilesStoreClass", result.message, result, "error")
        }
        return result
    }

    async getObjectsFromApi(
        accountId: string,
        props?: GetPropertiesObjectList,
        updateLocalStore: boolean = true
    ) {
        let result: UscProfile[] | Error
        try {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            const response = await requestHandler.request(
                "GET",
                this.settings.apiInfo.url +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms/profiles/" +
                    propertiesString
            )
            if (response.utmProfiles) {
                result = response.utmProfiles as UscProfile[]
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, result) // Add to store
                }
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error getting utmProfiles")
            }
        } catch (e: any) {
            result = e.responseJSON || e
            const message = e.responseJSON?.message || e.message
            devLog.log("UscProfilesStoreClass", message, result, "error")
            throw result
        }
        return result
    }

    async getObjectFromApi(
        accountId: string,
        objectId: string,
        props?: GetPropertiesObjectList,
        updateLocalStore: boolean = true
    ) {
        let result: UscProfile | Error
        try {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            const response = await requestHandler.request(
                "GET",
                this.settings.apiInfo.url +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms/profiles/" +
                    encodingHelpers.encodeURI(objectId as string) +
                    propertiesString
            )
            if (response.id) {
                result = response as UscProfile
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, result) // Add UscProfile to store
                }
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error getting UscProfile")
            }
        } catch (e: any) {
            result = e.responseJSON || e
            const message = e.responseJSON?.message || e.message
            devLog.log("UscProfilesStoreClass", message, result, "error")
            throw result
        }
        return result
    }
    async updateObjectFromApi(
        accountId: string,
        objectId: UscProfile["id"],
        object: UscProfile,
        updateLocalStore: boolean = true
    ) {
        let result: UscProfile | Error | shemaErrors
        try {
            let response: any
            let originalObject = this.getObjectFromStore(accountId, objectId)
            if (
                object.template?.tenantDomains != undefined ||
                originalObject.template?.tenantDomains != undefined
            ) {
                const existingTenants: string[] =
                    originalObject.template?.childProfiles != undefined
                        ? Object.keys(originalObject.template.childProfiles)
                        : []
                let addProfiles: string[] = []
                let updateProfiles: string[] = []
                let deleteProfiles: string[] = []

                if (object.template?.tenantDomains?.length) {
                    if (!object.template.uuid) {
                        object.template.uuid = stringHelpers.generateUUID()
                    }

                    // GET PROFILES THAT SHOULD BE ADDED OR UPDATED
                    object.template?.tenantDomains.forEach((tenantDomain) => {
                        if (existingTenants.indexOf(tenantDomain) == -1) {
                            if (tenantHelpers.tenantExists(tenantDomain)) {
                                addProfiles.push(tenantDomain)
                            }
                        } else {
                            if (
                                originalObject.template?.childProfiles?.[tenantDomain] != undefined
                            ) {
                                if (tenantHelpers.tenantExists(tenantDomain)) {
                                    updateProfiles.push(
                                        tenantDomain +
                                            "/" +
                                            originalObject.template.childProfiles[tenantDomain]
                                    )
                                }
                            }
                        }
                    })
                }
                if (existingTenants.length) {
                    // GET PROFILES THAT SHOULD BE DELETED
                    existingTenants.forEach((tenantDomain) => {
                        if ((object.template?.tenantDomains || []).indexOf(tenantDomain) == -1) {
                            if (
                                originalObject.template?.childProfiles?.[tenantDomain] != undefined
                            ) {
                                if (tenantHelpers.tenantExists(tenantDomain)) {
                                    deleteProfiles.push(
                                        tenantDomain +
                                            "/" +
                                            originalObject.template.childProfiles[tenantDomain]
                                    )
                                }
                            }
                        }
                    })
                }
                response = await requestHandler.request(
                    "PUT",
                    this.settings.apiInfo.url +
                        "/tenants/" +
                        tenantHelpers.getTenantDomain(accountId) +
                        "/utms/profiles/" +
                        encodingHelpers.encodeURI(objectId),
                    object
                )

                if (addProfiles.length || updateProfiles.length || deleteProfiles.length) {
                    // Create child Object
                    let childObject = jsonHelpers.copyObject(object)
                    // delete tenantDomains from childObject
                    delete childObject?.template?.tenantDomains
                    delete childObject?.template?.childProfiles

                    if (addProfiles.length + updateProfiles.length + deleteProfiles.length >= 1) {
                        this.dialogs.getManyTemplateRequestsDialog(
                            accountId,
                            "update",
                            childObject,
                            addProfiles,
                            updateProfiles,
                            deleteProfiles
                        )
                    } else {
                        if (addProfiles.length) {
                            // add profiles
                            for (let i = 0; addProfiles.length > i; i++) {
                                const tenantDomain = addProfiles[i]
                                await requestHandler.request(
                                    "POST",
                                    this.settings.apiInfo.url +
                                        "/tenants/" +
                                        tenantDomain +
                                        "/utms/profiles",
                                    childObject
                                )
                            }
                        }
                        if (updateProfiles.length) {
                            // update profiles
                            for (let i = 0; updateProfiles.length > i; i++) {
                                const tenantDomain = updateProfiles[i].split("/")[0]
                                const profileId = updateProfiles[i].split("/")[1]
                                await requestHandler.request(
                                    "PUT",
                                    this.settings.apiInfo.url +
                                        "/tenants/" +
                                        tenantDomain +
                                        "/utms/profiles/" +
                                        encodingHelpers.encodeURI(profileId),
                                    childObject
                                )
                            }
                        }
                        if (deleteProfiles.length) {
                            // delete profiles
                            for (let i = 0; deleteProfiles.length > i; i++) {
                                const tenantDomain = deleteProfiles[i].split("/")[0]
                                const profileId = deleteProfiles[i].split("/")[1]
                                await requestHandler.request(
                                    "DELETE",
                                    this.settings.apiInfo.url +
                                        "/tenants/" +
                                        tenantDomain +
                                        "/utms/profiles/" +
                                        encodingHelpers.encodeURI(profileId)
                                )
                            }
                        }
                    }
                }
            } else {
                response = await requestHandler.request(
                    "PUT",
                    this.settings.apiInfo.url +
                        "/tenants/" +
                        tenantHelpers.getTenantDomain(accountId) +
                        "/utms/profiles/" +
                        encodingHelpers.encodeURI(objectId),
                    object
                )
            }
            if (response.data?.id) {
                result = object
                result.id = response.data.id
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, object) // Add UscProfile to store
                }
            } else if (response.id) {
                result = object
                result.id = response.id
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, object) // Add UscProfile to store
                }
            } else if (response.errors) {
                throw response.errors
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error updating UscProfile")
            }
        } catch (e: any) {
            result = e.responseJSON || e
            const message = e.responseJSON?.message || e.message
            devLog.log("UscProfilesStoreClass", message, result, "error")
            throw result
        }
        return result
    }
    async addObjectToApi(accountId: string, object: UscProfile, updateLocalStore: boolean = true) {
        let result: UscProfile | Error | shemaErrors
        try {
            let response: any = {}
            if (object.template?.tenantDomains?.length) {
                // adding template
                // generate UUID
                object.template.uuid = stringHelpers.generateUUID()
                response = await requestHandler.request(
                    "POST",
                    this.settings.apiInfo.url +
                        "/tenants/" +
                        tenantHelpers.getTenantDomain(accountId) +
                        "/utms/profiles",
                    object
                )

                // Create child Object
                let childObject = jsonHelpers.copyObject(object)
                // delete tenantDomains from childObject
                delete childObject.template.tenantDomains

                if (object.template.tenantDomains.length >= 1) {
                    this.dialogs.getManyTemplateRequestsDialog(
                        accountId,
                        "add",
                        childObject,
                        object.template.tenantDomains
                    )
                } else {
                    for (let i = 0; object.template.tenantDomains.length > i; i++) {
                        const tenantDomain = object.template.tenantDomains[i]
                        let thisResult = await requestHandler.request(
                            "POST",
                            this.settings.apiInfo.url +
                                "/tenants/" +
                                tenantDomain +
                                "/utms/profiles",
                            childObject
                        )
                    }
                    await this.getObjectsFromApi(accountId)
                }
            } else {
                response = await requestHandler.request(
                    "POST",
                    this.settings.apiInfo.url +
                        "/tenants/" +
                        tenantHelpers.getTenantDomain(accountId) +
                        "/utms/profiles",
                    object
                )
            }
            if (response?.data?.id) {
                result = object
                result.id = response.data.id
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, object) // Add UscProfile to store
                }
            } else if (response.errors) {
                throw response.errors
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error adding UscProfile")
            }
        } catch (e: any) {
            result = e.responseJSON || e
            const message = e.responseJSON?.message || e.message
            devLog.log("UscProfilesStoreClass", message, result, "error")
            throw result
        }
        return result
    }
    async deleteObjectFromApi(
        accountId: string,
        objectId: string,
        updateLocalStore?: boolean
    ): Promise<true | Error> {
        let result: true | Error
        try {
            const response = await requestHandler.request(
                "DELETE",
                this.settings.apiInfo.url +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms/profiles/" +
                    encodeURI(objectId as string)
            )
            if (response.error) {
                throw new Error(response.error)
            }
            result = true
            this.removeObjectFromStore(accountId, objectId) // Remove UscProfile from store
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
            } else {
                result = new Error("Error deleting UscProfile")
            }
            devLog.log("UscProfilesStoreClass", result.message, result, "error")
        }
        return result
    }

    async getProfileJobsFromApi(
        accountId: string,
        objectId: string,
        props?: GetPropertiesObjectList
    ) {
        let result: any[] | Error
        try {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            const response = await requestHandler.request(
                "GET",
                this.settings.apiInfo.url +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms/profiles/" +
                    encodingHelpers.encodeURI(objectId as string) +
                    "/jobs" +
                    propertiesString
            )
            if (response.code == 200) {
                result = response.data
            } else if (Array.isArray(response)) {
                result = response
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error getting jobs for usc profile")
            }
        } catch (e: any) {
            result = e.responseJSON || e
            const message = e.responseJSON?.message || e.message
            devLog.log("UscProfilesStoreClass", message, result, "error")
            throw result
        }
        return result
    }
    async getProfileHistoryJobsFromApi(
        accountId: string,
        objectId: string,
        props?: GetPropertiesObjectList
    ) {
        let result: any[] | Error
        try {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            const response = await requestHandler.request(
                "GET",
                this.settings.apiInfo.url +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms/profiles/" +
                    encodingHelpers.encodeURI(objectId as string) +
                    "/jobs/history" +
                    propertiesString
            )
            if (Array.isArray(response)) {
                result = response
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error getting jobs history for usc profile")
            }
        } catch (e: any) {
            result = e.responseJSON || e
            const message = e.responseJSON?.message || e.message
            devLog.log("UscProfilesStoreClass", message, result, "error")
            throw result
        }
        return result
    }

    async getUTMJobsFromApi(accountId: string, objectId: string, props?: GetPropertiesObjectList) {
        let result: any[] | Error
        try {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            const response = await requestHandler.request(
                "GET",
                this.settings.apiInfo.url +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms/" +
                    encodingHelpers.encodeURI(objectId as string) +
                    "/jobs" +
                    propertiesString
            )
            if (response.code == 200) {
                result = response.data
            } else if (Array.isArray(response)) {
                result = response
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error getting jobs for utm")
            }
        } catch (e: any) {
            result = e.responseJSON || e
            const message = e.responseJSON?.message || e.message
            devLog.log("UscProfilesStoreClass", message, result, "error")
            throw result
        }
        return result
    }

    async getUTMHistoryJobsFromApi(
        accountId: string,
        objectId: string,
        props?: GetPropertiesObjectList
    ) {
        let result: any[] | Error
        try {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            const response = await requestHandler.request(
                "GET",
                this.settings.apiInfo.url +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms/" +
                    encodingHelpers.encodeURI(objectId as string) +
                    "/jobs/history" +
                    propertiesString
            )
            if (response.code == 200) {
                result = response.data
            } else if (Array.isArray(response)) {
                result = response
            } else if (Array.isArray(response)) {
                result = response
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error getting jobs history for utm")
            }
        } catch (e: any) {
            result = e.responseJSON || e
            const message = e.responseJSON?.message || e.message
            devLog.log("UscProfilesStoreClass", message, result, "error")
            throw result
        }
        return result
    }

    async getPublishMessages(accountId: string, objectId: string, props?: GetPropertiesObjectList) {
        let result: any[] | Error
        try {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            const response = await requestHandler.request(
                "GET",
                this.settings.apiInfo.url +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/utms/profiles/" +
                    encodingHelpers.encodeURI(objectId as string) +
                    "/messages" +
                    propertiesString
            )
            if (response.code == 200) {
                result = response.data
            } else if (Array.isArray(response)) {
                result = response
            } else if (Array.isArray(response)) {
                result = response
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error getting jobs history for utm")
            }
        } catch (e: any) {
            result = e.responseJSON || e
            const message = e.responseJSON?.message || e.message
            devLog.log("UscProfilesStoreClass", message, result, "error")
            throw result
        }
        return result
    }
}
