import { useGlobalMixin } from "@/mixins/mixins.global"
import table2Component from "../components/table2"

const keyAppStatesComponent = {
    name: "key-app-states",
    mixins: [useGlobalMixin()],
    props: {
        properties: {
            required: true,
            default: () => {
                return {
                    tableData: {
                        loading: true,
                        titles: [],
                        rows: []
                    }
                }
            }
        }
    },
    computed: {
        statesTableData: function (this: any) {
            return this.properties?.tableData
        }
    },
    mounted: function (this: any) {},
    components: {
        table2: table2Component
    },
    template: `
        <div class="row">
            <div class="col-xs-24 col-print-24 col-lg-24 padding-xs">
                <table2
                    ref="statesTable"
                    class="statesTable"
                    :table="statesTableData"
                    style="margin:0;"
                    minheight="32"
                    maxheight="100000"
                    :search="false"
                    :scrollbar="false"
                >
                </table2>
            </div>
        </div>
    `
}
export default keyAppStatesComponent
