import apis from "@/classes/apis"
import config from "@/classes/config"
import devLog from "@/classes/log"
import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import requestHandler from "@/queries/requests"
import { useStore } from "@/store/vuex.store"
import products from "../.."
import ObjectType, { type ObjectTypePayload } from "../../objectType"

export interface ZeroTouchDevice extends ObjectType<ZeroTouchDevice> {
    id: string
    deviceId: string
    name: string
    claims: {
        ownerCompanyId: string
        resellerId: string
        sectionType: string
    }[]
    deviceMetadata?: any // Only strings inside
    configuration?: any
    policy?: string
    deviceIdentifier: {
        imei?: string // One of: imei or meid
        meid?: string // One of: imei or meid
        manufacturer?: string
        serialNumber?: string // Wifi only
        model?: string // Wifi only
    }
    zeroTouchDevice: true
    zeroTouchOnly?: boolean
}

class ZeroTouchDevices extends ObjectType<ZeroTouchDevice> {
    constructor(payload: ObjectTypePayload<ZeroTouchDevice>) {
        super(payload)

        this.queries.getObjectsFromApi = async (
            accountId,
            customerId?,
            props?,
            updateStore = false
        ) => {
            let store = this.useStore?.().getObjectStore(accountId)
            let timestamp = store?.objectsTimestamp || 0
            let isRequestable = (function () {
                return (
                    store?.gettingObjects == false && timeHelpers.getUnixTimeStamp() - timestamp > 5
                )
            })()
            if ((store && isRequestable) || store == undefined) {
                if (store) {
                    store.gettingObjects = true
                    store.objectsTimestamp = timeHelpers.getUnixTimeStamp()
                }
                const tenantDomain = tenantHelpers.getTenantDomain(accountId)
                const propertiesString: string = props ? this.getPropertiesString(props) : ""
                let result: ZeroTouchDevice[] | Error

                try {
                    result = []
                    let getNextBatch: Function = async function (nextPageToken: string) {
                        if (customerId) {
                            let currentResult: any = await requestHandler.request(
                                "GET",
                                "/sms-mgt-api/api/" +
                                    apis.getApiVersionForFeature("zeroTouch") +
                                    "/tenants/" +
                                    tenantDomain +
                                    "/android/androiddeviceprovisioning/customers/" +
                                    customerId +
                                    "/devices" +
                                    (propertiesString ? propertiesString + "&" : "?") +
                                    "pageSize=75" +
                                    (nextPageToken ? "&nextPageToken=" + nextPageToken : "")
                            )
                            currentResult = apis.parseApiResponse(currentResult)
                            if (currentResult.devices.length && Array.isArray(result)) {
                                result = result.concat(currentResult.devices)
                            }
                            if (currentResult.nextPageToken != null) {
                                await getNextBatch(currentResult.nextPageToken)
                            }
                        }
                    }

                    if (customerId) {
                        await getNextBatch("")
                    } else {
                        let zeroTouchCustomers: any = config.canUseNewObjectType(
                            "zeroTouchCustomers"
                        )
                            ? products.mobileSecurity.zeroTouchCustomers
                                  .useStore?.()
                                  .getObjectStoreObjects(accountId)
                            : useStore()?.getters.getObjects({
                                  accountId: accountId,
                                  objectType: "zeroTouchCustomers",
                                  productType: "mobileSecurity"
                              })
                        if (config.canUseNewObjectType("zeroTouchCustomers")) {
                            for (let i: number = 0; (zeroTouchCustomers?.length || 0) > i; i++) {
                                let account = zeroTouchCustomers[i]
                                for (let j: number = 0; (account.customers?.length || 0) > j; j++) {
                                    customerId = account.customers[j].companyId
                                    await getNextBatch("")
                                }
                            }
                        } else {
                            for (let i: number = 0; (zeroTouchCustomers?.length || 0) > i; i++) {
                                customerId = zeroTouchCustomers[i].companyId
                                await getNextBatch("")
                            }
                        }
                    }

                    if (!Array.isArray(result)) {
                        throw "Error getting objects"
                    }

                    if (getterHelpers.useVue()?.$refs?.itemlist?.refreshCounter != undefined) {
                        getterHelpers.useVue().$refs.itemlist.refreshCounter++
                    }
                    if (store) {
                        store.gettingObjects = false
                    }
                    return result
                } catch (e: any) {
                    if (store) {
                        store.gettingObjects = false
                    }
                    devLog.log("GenericStoreClass", e.message, e, "error")
                    throw e
                }
            } else {
                return this.useStore?.().getObjectStoreObjects(accountId) || []
            }
        }

        this.convertObjectForStore = (accountId, objectBase) => {
            objectBase.zeroTouchDevice = true
        }
    }

    updateZeroTouchDeviceConfiguration = async function (
        accountid: string,
        customerId: string,
        deviceId: string,
        configurationId: string
    ) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "PUT",
                "/sms-mgt-api/api/2.0/tenants/" +
                    tenantDomain +
                    "/android/androiddeviceprovisioning/customers/" +
                    customerId +
                    "/devices/" +
                    deviceId +
                    "/configuration/" +
                    configurationId
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return apis.parseApiResponse(result)
    }
    deleteZeroTouchDeviceConfiguration = async function (
        accountid: string,
        customerId: string,
        deviceId: string
    ) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            result = await requestHandler.request(
                "DELETE",
                apis.getTenantApiUrl(tenantDomain, "zeroTouch") +
                    "/android/androiddeviceprovisioning/customers/" +
                    customerId +
                    "/devices/" +
                    deviceId +
                    "/configuration"
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return apis.parseApiResponse(result)
    }
}

const zeroTouchDevices = new ZeroTouchDevices({
    productType: "mobileSecurity",
    objectType: "zeroTouchDevices",
    slug: "zeroTouchDevices",
    apiInfo: {
        url: "/sms-mgt-api/api/2.0",
        getCountGETProperties: "?props[]=null&select=data.count",
        // GET
        getObjectListResponseProperty: "emm",
        getObjectListMethod: "GET",
        getObjectListPath: "/tenants/{tenantDomain}/android/emm/enterprise/devices",
        // Update
        updateObjectMethod: "PUT",
        updateObjectPath: "/tenants/{tenantDomain}/android/emm/enterprise/devices/{objectId}"
    }
})
export default zeroTouchDevices
