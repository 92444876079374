<script setup lang="ts">
import { T } from "@/classes/i18n"
import { computed, onMounted, ref, type Ref } from "vue"

const props = withDefaults(
    defineProps<{
        modelValue: string
        pinLength: number
        isDisabled?: boolean
        numbersOnly?: boolean
        numberAndChar?: boolean
        focusOnMount?: boolean
        regularSize?: boolean
        totp?: boolean
        hidePinOnMount?: boolean
        canToggleVisibility?: boolean
    }>(),
    {
        modelValue: "",
        pinLength: 4,
        isDisabled: false,
        numbersOnly: false,
        numberAndChar: false,
        focusOnMount: false,
        regularSize: false,
        totp: false,
        hidePinOnMount: false,
        canToggleVisibility: false
    }
)
const hidePin = ref(false)
const pinInput: Ref<null | HTMLInputElement> = ref(null)
const isFocused = ref(false)
const emit = defineEmits(["update:modelValue", "submit"])
const value = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        const numbersRegex = /^[0-9]*$/
        const numberAndCharRegex = /^[0-9a-zA-Z]*$/

        const blocked = () => {
            if (pinInput.value) {
                pinInput.value.value = props.modelValue
            }
        }

        if (props.numbersOnly) {
            if (numbersRegex.test(newValue)) {
                emit("update:modelValue", newValue)
            } else {
                blocked()
            }
        } else if (props.numberAndChar) {
            if (numberAndCharRegex.test(newValue)) {
                emit("update:modelValue", newValue)
            } else {
                blocked()
            }
        } else {
            emit("update:modelValue", newValue)
        }
    }
})

function emitSubmit() {
    if (value.value.length == props.pinLength) {
        emit("submit")
    }
}

onMounted(() => {
    if (props.hidePinOnMount) {
        hidePin.value = true
    }
    if (props.focusOnMount) {
        focusInput()
    }
})

function focusInput() {
    if (pinInput.value != undefined) {
        pinInput.value.focus()
    }
}
defineExpose({
    pinInput,
    focusInput
})
const toggleView = () => {
    hidePin.value = !hidePin.value
}
</script>

<template>
    <div
        class="pin-input-container"
        :class="{ 'regular-size': props.regularSize === true }"
        @click="focusInput"
    >
        <template v-for="index in props.pinLength" :key="index">
            <div
                class="pin-box"
                :class="{ 'active-pin-box': isFocused, hidePin: hidePin == true }"
                v-if="
                    (props.isDisabled == false &&
                        ((value[index - 1] == undefined && typeof value[index - 2] == 'string') ||
                            (value[index - 1] == undefined && index - 1 == 0))) ||
                    (value[index - 1] != undefined && index == props.pinLength)
                "
            >
                <template v-if="value[index - 1]">
                    <template v-if="hidePin">
                        <span class="asterik">*</span>
                    </template>
                    <template v-else>
                        {{ value[index - 1] }}
                    </template>
                </template>
                <template v-else>
                    <span class="blinker" :class="{ blinking: isFocused }">_</span>
                </template>
            </div>
            <div
                v-else
                class="pin-box"
                :class="{
                    'disabled-pin-box': props.isDisabled == true,
                    hidePin: hidePin == true
                }"
            >
                <template v-if="value[index - 1]">
                    <template v-if="hidePin">
                        <span class="asterik">*</span>
                    </template>
                    <template v-else>
                        {{ value[index - 1] }}
                    </template>
                </template>
                <template v-else>
                    <span class="blinker"> _ </span>
                </template>
            </div>
        </template>
        <template v-if="props.canToggleVisibility">
            <a
                v-on:click="toggleView()"
                style="padding: 9px 11px"
                class="btn btn-primary margin-xs-y-0"
                :title="!hidePin ? T('Hide password') : T('Show password')"
            >
                <i
                    class="fal fa-fw"
                    :class="{
                        'fa-eye': hidePin,
                        'fa-eye-slash': !hidePin
                    }"
                ></i>
            </a>
        </template>
    </div>
    <div :class="props.totp == true ? 'unseenTotp' : 'unseen'">
        <!-- when using "type: text" the input will be reversed on android devices with chromium: https://issues.chromium.org/issues/349994195  -->
        <input
            ref="pinInput"
            :id="props.totp ? '2fa' : undefined"
            :type="$props.numbersOnly ? 'tel' : 'text'"
            v-model="value"
            :disabled="props.isDisabled"
            :maxlength="props.pinLength"
            @keyup.enter="emitSubmit"
            @focus="isFocused = true"
            @blur="isFocused = false"
        />
    </div>
</template>

<style scoped lang="scss">
.pin-input-container {
    display: flex;
    .pin-box {
        width: 40px;
        height: 40px;
        font-size: 16px;
        text-align: center;
        margin: 0 5px;
        align-items: center;
        justify-content: center;
        line-height: 40px;

        border: 1px solid rgba(0, 0, 0, 0.125);
        display: block;
        transition: 0.3s;
        background: rgba(0, 0, 0, 0.01);
        color: rgba(0, 0, 0, 0.6);
        border-radius: 2px;
        box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.1) inset;
        .asterik {
            font-size: 24px;
            line-height: 48px;
        }
    }

    .active-pin-box {
        border: 1px solid #e52b2b;
        color: #e52b2b;
    }

    .disabled-pin-box {
        border: 1px solid #ccc;
        color: #777;
        background-color: #eee;
        pointer-events: none;
    }
    .blinker {
        opacity: 0.5;
        &.blinking {
            animation: blinker 1.5s linear infinite;
        }
    }

    &.regular-size {
        .pin-box {
            height: 34px;
            line-height: 34px;
            margin: 0 2px;
            &.disabled-pin-box {
                opacity: 0.5;
                cursor: default;
                -webkit-appearance: none;
                background: none;
            }
        }
    }
}
.darkmode {
    .pin-input-container {
        .pin-box {
            border: 1px solid rgba(255, 255, 255, 0.125);
            background: rgba(0, 0, 0, 0.05);
            color: rgba(255, 255, 255, 0.6);
            box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.1) inset;
        }

        .active-pin-box {
            border: 1px solid #e52b2b;
            color: #e52b2b;
        }

        .disabled-pin-box {
            border: 1px solid rgba(255, 255, 255, 0.05);
            color: rgba(255, 255, 255, 0.3);
        }
        .blinker {
            opacity: 0.5;
            &.blinking {
                animation: blinker 1.5s linear infinite;
            }
        }
    }
}
.unseen {
    width: 0px;
    height: 0px;
    overflow: hidden;
    opacity: 0;
}

.unseenTotp {
    height: 0px;
    overflow: hidden;
    opacity: 0;
}

@keyframes blinker {
    0% {
        opacity: 0;
    }

    49.9% {
        opacity: 0;
    }

    50% {
        opacity: initial;
    }
}
</style>
