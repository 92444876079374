import { useVue } from "@/app"
import dialogs from "@/dialogs/dialogs"
import arrayHelpers from "@/helpers/helpers.arrays"
import deviceHelpers from "@/helpers/helpers.devices"
import encodingHelpers from "@/helpers/helpers.encoding"
import getterHelpers from "@/helpers/helpers.getters"
import licenseHelpers from "@/helpers/helpers.license"
import numberHelpers from "@/helpers/helpers.numbers"
import permissionsHelpers from "@/helpers/helpers.permissions"
import stringHelpers from "@/helpers/helpers.strings"
import tenantHelpers from "@/helpers/helpers.tenants"
import queries from "@/queries/queries"
import requestHandler from "@/queries/requests"
import type { ProductType } from "@/resources/registeredProducts"
import router from "@/router/router"
import { MutationTypes, useStore } from "@/store/vuex.store"
import Button from "@/templates/components/button/button"
import jsonHelpers from "../../helpers/helpers.json"
import apis from "../apis"
import config from "../config"
import {
    GenericObjectStore,
    type GenericObject,
    type GetPropertiesObjectList,
    type ItemlistDetail,
    type Label,
    type MenuEntry,
    type shemaErrors
} from "../genericObjectStore"
import { T } from "../i18n"
import objectStores from "../init"
import devLog from "../log"
import type { VppUser } from "../mobileSecurity/vppUsers"
import products from "../objectTypes"

export type Username = string

export interface UserProfile {
    appleId?: string
    city?: string
    linkWithVPPUser?: string
    clientUserIdStr?: "create" | string
    comment?: string
    country?: string
    email?: string
    firstname?: string
    lastname?: string
    state?: string
    variable1?: string
    variable2?: string
    variable3?: string
}

export interface UserAndroidInfo {
    enterprise?: {
        devices?: Array<string>
    }
}

export interface User extends GenericObject<User> {
    username: Username
    TOTP?: boolean
    tenantDomain?: string
    rspUserId?: string
    rspLogin?: string
    roles: Array<string>
    profile?: UserProfile
    permissionScopes?: Array<string>
    devices: {
        apple: Array<string>
        android: Array<string>
    }
    android?: UserAndroidInfo
    vppUser?: VppUser
}

export default class Users extends GenericObjectStore<User> {
    constructor() {
        super()
        this.settings.primaryKeyProperty = this.settings.nameProperty.primary = "username"
        this.settings.productType = "unifiedSecurity"
        this.settings.objectType = "users"
        this.settings.appearance.iconClass = "fal fa-user"
        this.settings.appearance.text.singular = "User"
        this.settings.appearance.text.plural = "Users"
        this.settings.appearance.text.title = "Users"
        this.settings.apiInfo.listPath = "/tenants/{tenantDomain}/users"
        this.settings.apiInfo.objectListPropertyInResponse = "users"

        this.itemlist.getToolbar = (accountId, itemlistComponent) => {
            itemlistComponent = itemlistComponent?.exposed
                ? itemlistComponent?.exposed
                : itemlistComponent
            let toolBarEntries = []

            toolBarEntries.push({
                icon: "fal fa-plus",
                title: T("Add user"),
                link: "#add-tenant-" + accountId + ".sms-user",
                id: "usersButtonAdd",
                vIf: false
            })
            if (config.devMode) {
                toolBarEntries.push({
                    icon: "fal fa-sync",
                    title: T("Sync DEP Users"),
                    onClick: function () {
                        dialogs.misc.confirmDialog(
                            accountId,
                            T("Confirm"),
                            `<p>
                  ${T("This will sync Apple DEP Users with Securepoint Mobile Security")}
                </p>`,
                            async () => {
                                await requestHandler.request(
                                    "GET",
                                    config.mgtApiUri +
                                        "/tenants/" +
                                        tenantHelpers.getTenantDomain(accountId) +
                                        "/ios/dep/roster/class/person/sync"
                                )
                            }
                        )
                    },
                    id: "syncAppleDEPUsers",
                    vIf: "canUseAppleDEP"
                })
            }
            toolBarEntries.push(
                {
                    icon: "fal fa-user",
                    title: T("Connect RSP User"),
                    onClick: function () {
                        dialogs.misc.confirmDialog(
                            accountId,
                            T("Confirm"),
                            `<p>
                          ${T("Please confirm connecting your user from the Securepoint Reseller Portal to Securepoint Unified Security.")}
                        </p>
                        <p>
                          ${T('Connecting your RSP-Account includes the creation of a user and a role called "USC-Administrator", that will be assigned to your user.')}
                        </p>`,
                            async () => {
                                await queries.unifiedSecurity.importRSPUser(
                                    itemlistComponent.activeAccountId
                                )
                                itemlistComponent.refresh()
                                useStore().commit(MutationTypes.setTenants, {
                                    [accountId]: {
                                        unifiedSecurity: {
                                            account: {
                                                rspUserConnectable: false
                                            }
                                        }
                                    }
                                })
                            }
                        )
                    },
                    id: "importRSPUsersButton",
                    vIf: "canImportRSPUsers"
                },
                {
                    icon: "fal fa-download",
                    title: T("Download CSV"),
                    onClick: function () {
                        const accountId = useStore()?.state.session.activeAccountId || ""
                        let users = useStore().getters.getObjects({
                            accountId: accountId,
                            productType: "unifiedSecurity" as ProductType,
                            objectType: "users"
                        })
                        if (users.length && accountId != "") {
                            queries.unifiedSecurity.downloadCsv(accountId)
                        } else {
                            dialogs.misc.errorDialog(
                                accountId,
                                T("An error has occurred"),
                                T("An error occurred while exporting the users.csv")
                            )
                        }
                    },
                    id: "exportUsersButton",
                    vIf: "hasUsers"
                },
                {
                    icon: "fal fa-upload",
                    title: T("Import users"),
                    onClick: function () {
                        let newModal = {
                            id: "uploadUsers",
                            abortable: true,
                            content: {
                                title: {
                                    text: T("Import users")
                                },
                                body: {
                                    use: true,
                                    content: undefined,
                                    component: "import-users",
                                    properties: undefined
                                }
                            },
                            buttons: [
                                {
                                    text: T("Cancel"),
                                    icon: "fal fa-times",
                                    onClick: async function () {
                                        getterHelpers.useStore().commit(MutationTypes.removeModal)
                                    },
                                    align: "left",
                                    loading: false
                                },
                                {
                                    text: T("Select .csv File"),
                                    icon: "fal fa-fw fa-file-word",
                                    onClick: async function () {
                                        useVue().$refs.modals.$refs.modal.$refs.modalComponent.importUsers()
                                    },
                                    align: "right",
                                    disabled: false,
                                    loading: false
                                }
                            ]
                        }
                        useVue().$refs.modals.addModal(newModal)
                    },
                    id: "importUsersButton",
                    vIf: false
                }
            )
            return toolBarEntries
        }

        // setup itemlist options
        this.itemlist.isDisabled = (accountId, item): boolean => {
            return false
        }
        this.itemlist.isClickable = (accountId, item): boolean => {
            return true
        }
        this.itemlist.onClick = (accountId: string, item) => {
            let objectId = item[this.settings.primaryKeyProperty] as string
            router.navigate(
                "edit-tenant-" + tenantHelpers.getTenantDomain(accountId) + "-user-" + objectId
            )
        }
        this.itemlist.hasCheckBox = true
        this.itemlist.getTitle = (item) => {
            return {
                title: item?.rspUserId ? item.rspLogin : item?.username,
                small: item?.rspUserId ? "(" + item.rspUserId + ")" : undefined
            }
        }
        this.itemlist.getStatus = (accountId: string, item) => {
            return undefined
        }

        this.itemlist.getMenuEntries = (accountId: string, item) => {
            let menuEnties: MenuEntry[] = []
            if (item) {
                let objectId = item[this.settings.primaryKeyProperty] as string
                menuEnties.push(
                    new Button({
                        text: T("Edit"),
                        title: T("Edit"),
                        icon: "fal fa-edit",
                        onClick: () => {
                            router.navigate(
                                "edit-tenant-" +
                                    tenantHelpers.getTenantDomain(accountId) +
                                    "-user-" +
                                    objectId
                            )
                        }
                    })
                )
                menuEnties.push(
                    new Button({
                        text: T("Delete"),
                        title: T("Delete"),
                        icon: "fal fa-trash",
                        onClick: () => {
                            this.dialogs.getDeleteObjectDialog(accountId, item)
                        }
                    })
                )
            }

            return menuEnties
        }
        this.itemlist.getLabels = (accountId: string, item) => {
            let result: Label[] = []

            if (item?.rspUserId) {
                result.push({
                    title: T("Reseller Portal User"),
                    text: T("RSP"),
                    class: ""
                })
            }
            return result
        }
        this.itemlist.getDetails = (accountId: string, item?) => {
            let result: ItemlistDetail[] = []
            let thisPermissionsArray: any = []
            let roles = useStore()?.getters.getObjectTypeStore({
                accountId: accountId,
                objectType: "roles"
            })
            let thisDeviceIds: string[] = item?.devices?.apple
                ? jsonHelpers.copyObject(item?.devices?.apple) || []
                : []

            ;(item?.devices?.android || []).forEach((deviceId) => {
                thisDeviceIds.push(deviceId.split("/")[3])
            })

            roles?.items?.forEach((role: any) => {
                if (item?.roles.indexOf(role.rolename) != -1) {
                    role.permissionScopes.forEach((permissionScope: string) => {
                        if (permissionScope.indexOf("usc:administration:") != -1) {
                            thisPermissionsArray.push("usc:administration:*")
                        } else {
                            thisPermissionsArray.push(permissionScope)
                        }
                    })
                }
            })
            thisPermissionsArray = thisPermissionsArray.filter(arrayHelpers.onlyUniqueFilter)
            thisPermissionsArray = thisPermissionsArray.map((permissionScope: string) => {
                const labelText = T(permissionsHelpers.getPermissionLabelFromScope(permissionScope))
                const permissionScopeClass = (function () {
                    let htmlClass: string = ""
                    if (permissionScope == "usc:administration") {
                        htmlClass = "bg-red color-white"
                    }
                    if (permissionScope == "usc:administration:*") {
                        htmlClass = "bg-yellow color-white"
                    }
                    if (permissionScope == "usc:monitoring") {
                        htmlClass = "bg-blue color-white"
                    }
                    return htmlClass
                })()
                return <Label>{
                    id: permissionScope,
                    text: labelText,
                    title: labelText,
                    onClick: undefined,
                    displayType: "label",
                    class: permissionScopeClass
                }
            })

            let vppUserState: string | undefined = undefined
            if (item?.profile?.clientUserIdStr) {
                let id = item.profile.clientUserIdStr
                let vppUser = config.canUseNewObjectType("vppUsers")
                    ? products.mobileSecurity.vppUsers
                          .useStore?.()
                          .getObjectStoreObject(accountId, id)
                    : objectStores.vppUsers.getObjectFromStore(accountId, id)
                vppUserState = vppUser?.status || undefined
            }

            result.push(
                {
                    iconClass: "fal fa-at",
                    key: T("Email"),
                    title: T("EMail"),
                    value: item
                        ? item.profile?.email || ""
                        : '<span class="content-placeholder" style="width:' +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          'px;" title="' +
                          T("Loading...") +
                          '"></span>'
                },
                {
                    iconClass: "fal fa-users",
                    key: T("Roles"),
                    title: T("Roles"),
                    value: item
                        ? undefined
                        : '<span class="content-placeholder" style="width:' +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          'px;" title="' +
                          T("Loading...") +
                          '"></span>',
                    labels: item
                        ? item.roles.map((role) => {
                              return {
                                  title: role,
                                  text: role,
                                  class: "clickable",
                                  onClick: () => {
                                      router.navigate(
                                          "edit-tenant-" +
                                              tenantHelpers.getTenantDomain(accountId) +
                                              "-role-" +
                                              role
                                      )
                                  },
                                  displayType: "label"
                              }
                          })
                        : undefined
                },
                {
                    iconClass: "fal fa-mobile-alt",
                    key: T("Devices"),
                    title: T("Devices"),
                    labels: licenseHelpers.hasOneOfLicenses(accountId, ["Mobile Security", "MDM"])
                        ? thisDeviceIds?.map((deviceID) => {
                              let device = useStore()?.getters.getObject({
                                  accountId: accountId,
                                  productType: "mobileSecurity",
                                  objectType: "devices",
                                  objectId: deviceID
                              })
                              return {
                                  id: deviceID,
                                  text: deviceHelpers.getAliasedShortDeviceId(
                                      device?.deviceId || deviceID,
                                      device?.alias || device?.info?.deviceName || undefined,
                                      false
                                  ),
                                  title: deviceHelpers.getAliasedShortDeviceId(
                                      device?.deviceId || deviceID,
                                      device?.alias || undefined,
                                      false
                                  ),
                                  onClick: () => {
                                      if (device?.deviceType == "IOS") {
                                          router.navigate(
                                              "#show-tenant-" +
                                                  tenantHelpers.getTenantDomain(accountId) +
                                                  "-ios-devices-dashboard-" +
                                                  deviceID
                                          )
                                      } else if (device?.deviceType == "ANDROID") {
                                          router.navigate(
                                              "#show-tenant-" +
                                                  tenantHelpers.getTenantDomain(accountId) +
                                                  "-android-devices-dashboard-" +
                                                  deviceID
                                          )
                                      }
                                  },
                                  displayType: "label"
                              }
                          })
                        : undefined
                },
                {
                    iconClass: "fal fa-fw fa-at",
                    title: T("VPP Apple ID"),
                    key: T("VPP Apple ID"),
                    value:
                        item && item.profile?.clientUserIdStr
                            ? item.profile?.appleId +
                              (vppUserState ? " (" + T(vppUserState) + ")" : "")
                            : "not linked"
                },
                {
                    iconClass: "fal fa-fw fa-key",
                    title: T("Permissions"),
                    key: T("Permissions"),
                    labels: item ? thisPermissionsArray : undefined
                }
            )

            return result
        }

        this.itemlist.sortingOptions = [
            {
                id: "username",
                text: "Name"
            }
        ]
    }

    async getCountFromApi(accountId: string, updateLocalStore: boolean = true) {
        let result: number | Error = 0
        try {
            const countResult = await requestHandler.request(
                "GET",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/users?props[]=null&select=data.count"
            )
            if (typeof countResult === "number") {
                result = countResult
                if (updateLocalStore) {
                    this.setCount(accountId, countResult)
                }
            } else if (countResult.error) {
                throw new Error(countResult.error)
            } else {
                throw new Error("Error getting count for users")
            }
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
            } else {
                result = new Error("Error getting count for users")
            }
            devLog.log("UsersStoreClass", result.message, result, "error")
        }
        return result
    }

    /**
     * Gets object-list from sms-mgt-api
     * @param props (optional) GET request Payoad as array
     * @param updateLocalStore (optional) GET request Payoad as updateLocalStore (optional) wether the changes will affect the local store or not. defaults to true
     */
    async getObjectsFromApi(
        accountId: string,
        props?: GetPropertiesObjectList,
        updateLocalStore: boolean = true,
        newApi?: boolean
    ): Promise<User[] | Error> {
        const tenantDomain = tenantHelpers.getTenantDomain(accountId)
        const propertiesString: string = props ? this.getPropertiesString(props) : ""
        let result: User[] | Error
        try {
            let response = await requestHandler.request(
                "GET",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/users" +
                    propertiesString
            )
            response = apis.parseApiResponse(response)

            if (
                typeof this.settings.apiInfo.objectListPropertyInResponse == "string" &&
                this.settings.apiInfo.objectListPropertyInResponse.length > 0 &&
                response[this.settings.apiInfo.objectListPropertyInResponse]
            ) {
                result = response[
                    this.settings.apiInfo.objectListPropertyInResponse as string
                ] as User[]
            } else if (typeof this.settings.apiInfo.objectListPropertyInResponse == "function") {
                result = response[this.settings.apiInfo.objectListPropertyInResponse()] as User[]
            } else if (response.length) {
                result = response as User[]
            } else {
                throw "Error getting Users"
            }
            if (updateLocalStore) {
                this.addOrUpdateObjectsInStore(accountId, result) // Add Users to store
            }
            return result
        } catch (e: any) {
            devLog.log("UsersClass", e.message, e, "error")
            throw e as Error
        }
    }

    async getObjectFromApi(
        accountId: string,
        objectId: User[keyof User],
        props?: GetPropertiesObjectList,
        updateLocalStore: boolean = true
    ) {
        let result: User | Error
        try {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            const response = await requestHandler.request(
                "GET",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/users/" +
                    encodingHelpers.encodeURI(objectId as string) +
                    propertiesString
            )
            if (response.username) {
                result = response as User
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, result) // Add User to store
                }
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error getting user")
            }
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
            } else {
                result = new Error("Error getting user")
            }

            devLog.log("UsersStoreClass", result.message, result, "error")
        }
        return result
    }

    async addObjectToApi(accountId: string, object: User, updateLocalStore: boolean = true) {
        let result: User | Error | shemaErrors
        let vppResult: any | VppUser | Error | undefined
        let linkWithVPPUser: boolean = false
        let addOrUpdateVppUser: "add" | "update" = "add"
        let clientUserIdStr: string | undefined = undefined
        let email: string | undefined = undefined
        let managedAppleIDStr: string | undefined = undefined

        try {
            if (object.profile?.linkWithVPPUser) {
                delete object.profile.linkWithVPPUser
                linkWithVPPUser = true
                clientUserIdStr =
                    object.profile.clientUserIdStr == "create" ||
                    object.profile.clientUserIdStr == ""
                        ? stringHelpers.generateUUID()
                        : object.profile.clientUserIdStr
                email = object.profile.email
                managedAppleIDStr = object.profile.appleId
                if (
                    managedAppleIDStr &&
                    (object.profile.clientUserIdStr == "create" ||
                        object.profile.clientUserIdStr == "")
                ) {
                    // add new vppUser
                    addOrUpdateVppUser = "add"
                } else if (
                    managedAppleIDStr &&
                    object.profile.clientUserIdStr != undefined &&
                    object.profile.clientUserIdStr != "create" &&
                    object.profile.clientUserIdStr != ""
                ) {
                    // link existing vppUser
                    addOrUpdateVppUser = "update"
                } else if (!managedAppleIDStr) {
                    linkWithVPPUser = false
                }

                object.profile.clientUserIdStr = clientUserIdStr
                delete object.profile.linkWithVPPUser
            }

            const response = await requestHandler.request(
                "POST",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/users/",
                object
            )

            if (linkWithVPPUser && addOrUpdateVppUser == "add") {
                if (clientUserIdStr && email && managedAppleIDStr) {
                    // add new vppUser
                    if (config.canUseNewObjectType("vppUsers")) {
                        vppResult = await products.mobileSecurity.vppUsers.queries.addObjectToApi(
                            accountId,
                            {
                                clientUserId: clientUserIdStr,
                                email: email,
                                managedAppleId: managedAppleIDStr
                            }
                        )
                    } else {
                        vppResult = await objectStores.vppUsers.addObjectToApi(accountId, {
                            clientUserIdStr,
                            email,
                            managedAppleIDStr
                        })
                    }
                    if (vppResult instanceof Error) {
                        throw "can't add vppUser"
                    }
                }
            } else if (linkWithVPPUser && addOrUpdateVppUser == "update") {
                if (clientUserIdStr && email && managedAppleIDStr) {
                    if (config.canUseNewObjectType("vppUsers")) {
                        vppResult =
                            await products.mobileSecurity.vppUsers.queries.updateObjectFromApi(
                                accountId,
                                clientUserIdStr,
                                {
                                    clientUserId: clientUserIdStr,
                                    email: email,
                                    managedAppleId: managedAppleIDStr
                                }
                            )
                    } else {
                        // link existing vppUser
                        vppResult = await objectStores.vppUsers.updateObjectFromApi(
                            accountId,
                            clientUserIdStr,
                            {
                                clientUserIdStr,
                                email,
                                managedAppleIDStr
                            }
                        )
                    }
                    if (vppResult instanceof Error) {
                        throw "can't update vppUser"
                    }
                }
            }

            if (response.username) {
                result = object
                result.username = response.username
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, object) // Add User to store
                }
            } else if (response.errors) {
                throw response.errors
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error adding user")
            }
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
                devLog.log("UsersStoreClass", result.message, result, "error")
            } else {
                result = e as shemaErrors
                devLog.log("UsersStoreClass", "Error adding user", result, "error")
            }
        }
        return result
    }

    async updateObjectFromApi(
        accountId: string,
        objectId: User["username"],
        object: User,
        updateLocalStore: boolean = true
    ) {
        let result: User | Error | shemaErrors
        let vppResult: VppUser | Error | undefined
        let linkWithVPPUser: boolean = false
        let retireVPPUser: boolean = false
        let addOrUpdateVppUser: "add" | "update" = "add"
        let clientUserIdStr: string | undefined = undefined
        let email: string | undefined = undefined
        let managedAppleIDStr: string | undefined = undefined

        try {
            const rolesStore = objectStores.roles
            await objectStores.roles.getObjectsFromApi(accountId)

            if (object.profile?.linkWithVPPUser) {
                delete object.profile.linkWithVPPUser
                linkWithVPPUser = true
                clientUserIdStr =
                    object.profile.clientUserIdStr == "create" ||
                    object.profile.clientUserIdStr == ""
                        ? stringHelpers.generateUUID()
                        : object.profile.clientUserIdStr
                email = object.profile.email
                managedAppleIDStr = object.profile.appleId
                if (
                    managedAppleIDStr &&
                    (object.profile.clientUserIdStr == "create" ||
                        object.profile.clientUserIdStr == "")
                ) {
                    // add new vppUser
                    addOrUpdateVppUser = "add"
                } else if (
                    managedAppleIDStr &&
                    object.profile.clientUserIdStr != undefined &&
                    object.profile.clientUserIdStr != "create" &&
                    object.profile.clientUserIdStr != ""
                ) {
                    // link existing vppUser
                    addOrUpdateVppUser = "update"
                } else if (!managedAppleIDStr) {
                    linkWithVPPUser = false
                }
                object.profile.clientUserIdStr = clientUserIdStr
                delete object.profile.linkWithVPPUser
            }

            let user = this.getObjectFromStore(accountId, objectId)

            if (
                (user.profile?.clientUserIdStr == undefined ||
                    user.profile?.clientUserIdStr == "" ||
                    user.profile?.clientUserIdStr == "create") &&
                !linkWithVPPUser
            ) {
                delete object.profile?.appleId
                delete object.profile?.clientUserIdStr
                delete object.profile?.linkWithVPPUser
            }

            if (user.profile?.clientUserIdStr && !linkWithVPPUser) {
                clientUserIdStr = user.profile?.clientUserIdStr
                delete object.profile?.appleId
                delete object.profile?.clientUserIdStr
                delete object.profile?.linkWithVPPUser

                retireVPPUser = true
            }

            const response = (result = await requestHandler.request(
                "PUT",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/users/" +
                    encodingHelpers.encodeURI(object.username),
                object
            ))
            try {
                if (retireVPPUser && clientUserIdStr) {
                    if (config.canUseNewObjectType("vppUsers")) {
                        await products.mobileSecurity.vppUsers.queries.deleteObjectFromApi(
                            accountId,
                            clientUserIdStr
                        )
                    } else {
                        await objectStores.vppUsers.deleteObjectFromApi(accountId, clientUserIdStr)
                    }
                }

                if (linkWithVPPUser && addOrUpdateVppUser == "add") {
                    if (clientUserIdStr && email && managedAppleIDStr) {
                        // add new vppUser
                        if (config.canUseNewObjectType("vppUsers")) {
                            vppResult =
                                await products.mobileSecurity.vppUsers.queries.addObjectToApi(
                                    accountId,
                                    {
                                        clientUserId: clientUserIdStr,
                                        email: email,
                                        managedAppleId: managedAppleIDStr
                                    }
                                )
                        } else {
                            vppResult = await objectStores.vppUsers.addObjectToApi(accountId, {
                                clientUserIdStr,
                                email,
                                managedAppleIDStr
                            })
                        }
                        if (vppResult instanceof Error) {
                            throw "can't add vppUser"
                        }
                    }
                } else if (linkWithVPPUser && addOrUpdateVppUser == "update") {
                    if (clientUserIdStr && email && managedAppleIDStr) {
                        if (config.canUseNewObjectType("vppUsers")) {
                            vppResult =
                                await products.mobileSecurity.vppUsers.queries.updateObjectFromApi(
                                    accountId,
                                    clientUserIdStr,
                                    {
                                        clientUserId: clientUserIdStr,
                                        email: email,
                                        managedAppleId: managedAppleIDStr
                                    }
                                )
                        } else {
                            // link existing vppUser
                            vppResult = await objectStores.vppUsers.updateObjectFromApi(
                                accountId,
                                clientUserIdStr,
                                {
                                    clientUserIdStr,
                                    email,
                                    managedAppleIDStr
                                }
                            )
                        }
                        if (vppResult instanceof Error) {
                            throw "can't update vppUser"
                        }
                    }
                }
            } catch (e: any) {}

            if (response.code == 200) {
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, object) // Add User to store
                }
            } else if (response.errors) {
                throw response.errors
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error updating user")
            }
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
                devLog.log("UsersStoreClass", result.message, result, "error")
            } else {
                result = e as shemaErrors
                devLog.log("UsersStoreClass", "Error updating user", result, "error")
            }
        }
        return result
    }

    async deleteObjectFromApi(
        accountId: string,
        objectId: User[keyof User],
        updateLocalStore: boolean = true
    ) {
        let result: true | Error
        try {
            const user = this.getObjectFromStore(accountId, objectId)

            const response = await requestHandler.request(
                "DELETE",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/users/" +
                    encodingHelpers.encodeURI(objectId as string)
            )

            if (user.profile?.clientUserIdStr) {
                if (config.canUseNewObjectType("vppUsers")) {
                    await products.mobileSecurity.vppUsers.queries.deleteObjectFromApi(
                        accountId,
                        user.profile.clientUserIdStr
                    )
                } else {
                    await objectStores.vppUsers.deleteObjectFromApi(
                        accountId,
                        user.profile.clientUserIdStr
                    )
                }
            }

            if (response.error) {
                throw new Error(response.error)
            } else {
                result = true
                if (updateLocalStore) {
                    this.removeObjectFromStore(accountId, objectId) // Remove user from store
                }
            }
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
            } else {
                result = new Error("Error deleting user")
            }
            devLog.log("UsersStoreClass", result.message, result, "error")
        }
        return result
    }
    renderShowPortalupdatesDialog(accountId: string) {
        let modal = {
            accountId: accountId,
            id: "portalupdateDialog",
            abortable: true,
            content: {
                title: {
                    text: T("Portal-Updates"),
                    icon: "fal fa-circle-info"
                },
                body: {
                    use: true,
                    abortable: false,
                    content: undefined,
                    component: "portal-updates-dialog",
                    properties: undefined
                }
            },
            buttons: [
                {
                    text: T("Ok"),
                    icon: "fal fa-check",
                    onClick: async function (component: any) {
                        useVue().$refs.modals.$refs.modal.$refs.modalComponent.submit()
                    },
                    align: "right",
                    loading: false
                }
            ]
        }
        useStore()?.commit(MutationTypes.addModal, modal)
    }
}
