//@ts-ignore
import { default as $, default as jQuery } from "jquery"
import router, { navigation } from "./router/router"
Object.assign(window, { $: jQuery, jQuery })
//@ts-ignore
import { useStore } from "@/store/vuex.store"
import cookies from "./classes/cookieHandler"

import "@/styles_custom/style_before_default.css"
import "drawflow/dist/drawflow.min.css"
import "pace-progressbar"
import "pace-progressbar/themes/red/pace-theme-minimal.css"

import "@/styles/fontawesome-pro/scss/brands.scss"
import "@/styles/fontawesome-pro/scss/fontawesome.scss"
import "@/styles/fontawesome-pro/scss/light.scss"
import "@/styles/fontawesome-pro/scss/solid.scss"
import "@/styles_custom/style.scss"
import "@/styles_custom/style_after_default.css"
import "flag-icons"

import objectStores from "./classes/init"
import products from "./classes/objectTypes"
import websocketHandler from "./classes/websocket"
import getterHelpers from "./helpers/helpers.getters"
import vue from "./main"
import queries from "./queries/queries"
import requestHandler from "./queries/requests"
import useRouterStore from "./router/routerStore"
export const useVue = () => {
    return vue || undefined
}
const app = {
    objectStores: objectStores,
    navigation: navigation,
    queries: queries,
    tempStorage: <any>{},
    vue: vue,
    request: requestHandler,
    cookies: cookies,
    products: products,
    websocketHandler: websocketHandler
}
export const vueApp = app.vue

declare global {
    interface Window {
        app: any
        vueApp: typeof app.vue
    }
}
window.app = app
window.vueApp = app.vue

// init darkmode
let darkmode: string | boolean | undefined = cookies.get("darkmode")
darkmode =
    darkmode != undefined
        ? darkmode == "1"
        : window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
useStore().dispatch("setDarkmode", darkmode ? "1" : "0")

$(function () {
    let hash: any = window.location.hash
    if (hash == "#") {
        hash = undefined
    }
    router.listen()
    if (import.meta.env.VITE_MODE == "local") {
        router.check("")
    }
    const urlParams = (document.location.search?.replace("?", "")?.split("&") || []).map(
        (param: string) => {
            return {
                key: param.split("=")[0],
                value: param.split("=")[1]
            }
        }
    )
    if (urlParams) {
        let redirect = urlParams.find((param) => {
            return param.key == "rd"
        })
        if (redirect) {
            document.location.href = redirect.value
        }
    }
    hash = hash || cookies.get("hash")
    const session = useStore().state.session.userInfo
    if (/logout/.test(hash) || !session.domain) {
        hash = ""
        cookies.set("hash", "")
    }
})

// Check for closed lid / hybernation (https://redmine.intern.securepoint.de/issues/38214#note-7)
let lastTick = 0 // timestamp for last-tick-check
const tickCounter = () => {
    let time = new Date().getTime() / 1000
    if (
        lastTick !== 0 &&
        time - lastTick >= 20 &&
        products.unifiedSecurityConsole.topologies.showDialogOnBeforeUnload
    ) {
        let accountId = getterHelpers.useStore().state.session.activeAccountId
        let routerStore = useRouterStore()
        if (routerStore.activePage != "adaptiveSecureConnectConfiguration") {
            products.unifiedSecurityConsole.topologies.view.getUnpulishedChangesDialog(accountId)
        }
    }
    lastTick = time
    setTimeout(() => {
        tickCounter()
    }, 17000)
}

tickCounter()
// check for closing tab/window or changing url (https://redmine.intern.securepoint.de/issues/38214)
window.onbeforeunload = function (e) {
    let accountId = getterHelpers.useStore().state.session.activeAccountId
    if (products.unifiedSecurityConsole.topologies.showDialogOnBeforeUnload) {
        products.unifiedSecurityConsole.topologies.view.getUnpulishedChangesDialog(accountId)
        window.onbeforeunload = null
        return true
    }
}
