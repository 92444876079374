<script setup lang="ts">
import { onMounted, ref } from "vue"
import loaderVue from "../components/loader.vue"
import inputVueSelectVue from "../inputtypes/input-vue-select.vue"

const props = defineProps<{
    properties: {
        selectOptions: selectOption[]
        text?: string
    }
}>()

const selectedInputs = ref(<any>[])
const initialized = ref(false)

onMounted(() => {
    props?.properties.selectOptions?.forEach((option: selectOption) => {
        selectedInputs.value.push(option)
    })
    initialized.value = true
})
</script>
<template class="disabled-select-dialog">
    <template v-if="initialized">
        <div class="row" style="margin-bottom: 10px">
            <div class="col-xs-24" v-if="properties.text">
                <div v-html="properties.text"></div>
            </div>
        </div>
        <div class="row margin-xs-b-2">
            <inputVueSelectVue
                class="col-xs-24"
                :selectOptions="properties.selectOptions"
                v-model="selectedInputs"
                :multiple="true"
                id="disabledSelect"
                :disabled="true"
                :dontEscapeMarkup="true"
            >
            </inputVueSelectVue>
        </div>
    </template>
    <div v-else class="text-size-3 text-center padding-xs-t-4">
        <loaderVue class="color-red"></loaderVue>
    </div>
</template>
