import timeHelpers from "@/helpers/helpers.time"
import { MutationTypes, useStore } from "@/store/vuex.store"

export type FrontendNotificationList = FrontendNotification[]

export interface FrontendNotificationLists {
    [accountId: string]: FrontendNotificationList
}

export interface FrontendNotification extends FrontendNotificationAdd {
    accountId: string
    id: string
    highlightNew: boolean
    product: string
    state: "new" | "warning" | "highlighted" | "read" | "deleted"
    timestamp: string | number
}
export interface FrontendNotificationAdd {
    accountId?: string
    id?: string
    content: {
        title: {
            text: string
            icon: string
        }
        body: {
            content?: string
            component?: string
            properties?: any
        }
    }
    onClick?: Function | Promise<any>
    onDelete?: Function | Promise<any>
    timestamp?: string | number
    state?: "new" | "warning" | "highlighted" | "read" | "deleted"
    highlightNew?: boolean
    product?: string
}

export class FrontendNotifications {
    notificationLists: FrontendNotificationLists
    constructor(notificationLists?: FrontendNotificationLists) {
        if (notificationLists) {
            this.notificationLists = notificationLists
        } else {
            this.notificationLists = {}
        }
    }
    addNotificationList(accountId: string, notificationList: FrontendNotificationList) {
        this.notificationLists[accountId] = notificationList
        return this.notificationLists[accountId]
    }
    addNotification(accountId: string, newNotification: FrontendNotificationAdd) {
        if (!newNotification.id) newNotification.id = new Date().getTime().toString()
        if (!newNotification.timestamp)
            newNotification.timestamp = timeHelpers.getUnixTimeStamp() * 1000
        if (!newNotification.state) newNotification.state = "new"
        if (!newNotification.highlightNew) newNotification.highlightNew = false
        if (!newNotification.product) newNotification.product = "Unified Security"
        if (!newNotification.content.body.content && !newNotification.content.body.component)
            newNotification.content.body.content = "Missing info"

        useStore()?.commit(MutationTypes.addNotification, <FrontendNotification>newNotification)
    }
    async removeNotification(accountId: string, notificationId: string) {
        let notification = (this.notificationLists[accountId] || []).find(
            (notification: FrontendNotification) => {
                return notification.id == notificationId
            }
        )
        try {
            if (notification != undefined && typeof notification.onDelete == "function") {
                await notification.onDelete()
            }
            useStore()?.commit(MutationTypes.removeNotification, {
                accountId: accountId,
                objectId: notificationId
            })
        } catch (e: any) {
            console.error(e)
        }
    }
}
const frontendNotifications: FrontendNotifications = new FrontendNotifications()
export default frontendNotifications
