import config from "@/classes/config"
import products from "@/classes/objectTypes"
import type ObjectType from "@/classes/objectTypes/objectType"
import tenantHelpers from "@/helpers/helpers.tenants"
import { useGlobalMixin } from "@/mixins/mixins.global"
import { ActionTypes, useStore } from "@/store/vuex.store"
import { nextTick } from "vue"
import inputTextComponent from "../inputtypes/input-text"
import loaderComponent from "./loader.vue"

const deviceInlineRenameComponent = {
    mixins: [useGlobalMixin()],
    name: "device-inline-rename",
    template: `
        <strong>

            <input
                type="text"
                v-model="newValue"
                :disabled="loading || null"
                ref="deviceRenameInput"
                v-on:keyup.enter="save()"
                v-on:click.stop
                v-on:keyup="$emit('update:modelValue',newValue)"
                v-on:input="$emit('update:modelValue',newValue)"
                v-on:inputEvent="$emit('update:modelValue',newValue)"
                id="inlineRenameInputField"
                style="width: calc(100% - 135px); float: left;"
            >
            &nbsp;
            <template v-if="!loading">
                <a v-on:click.stop="save()" class="btn padding-xs-y-0" :class="darkmode == '1' ? 'btn-darkbluegrey' : 'btn-white color-gray'">
                    <i class="fal fa-save"></i>
                </a>
                <a  v-on:click.stop="close()" 
                    class="btn padding-xs-y-0"
                    :class="darkmode == '1' ? 'btn-darkbluegrey' : 'btn-white color-gray'"
                >
                    <i class="fal fa-times"></i>
                </a>
            </template>
            <loader
                v-if="loading"
            />
        </strong>
    `,
    data: function () {
        return {
            newValue: "",
            loading: false
        }
    },
    computed: {
        object: function (this: any) {
            let productType = tenantHelpers.getProductTypeFromObjectType(this.objectType)
            if (!productType) throw "Missing productType"

            let newProductType = config.canUseNewObjectType(this.objectType)
                ? products[productType as keyof typeof products]
                : undefined
            let newObjectType = newProductType?.[this.objectType as keyof typeof newProductType]

            if (newObjectType != undefined && config.canUseNewObjectType(this.objectType)) {
                return (<ObjectType<any>>newObjectType)
                    .useStore?.()
                    .getObjectStoreObject(this.activeAccountId, this.itemObjectId)
            } else {
                return useStore().getters.getObject({
                    accountId: this.activeAccountId,
                    productType: productType,
                    objectType: this.objectType,
                    objectId: this.itemObjectId
                })
            }
        },

        alias: function (this: any) {
            if (this.objectType == "uscUtms") {
                return this.object.utmname
            } else {
                return this.object.alias
            }
        }
    },
    props: {
        accountid: {
            required: true,
            default: undefined
        },
        itemObjectId: {
            required: true,
            default: undefined
        }
    },
    created: function (this: any) {
        if (this.alias != "") {
            this.newValue = this.alias
        }
    },
    mounted: function (this: any) {
        let thisComponent: any = this
        nextTick(() => {
            thisComponent.setFocus()
        })
    },
    methods: {
        setFocus: function (this: any) {
            let thisComponent: any = this
            let $input = $("#inlineRenameInputField")
            $input.focus()
        },
        save: async function (this: any) {
            let $input = $("#inlineRenameInputField")
            $input.off("focusout")
            let queryType = this.objectType
            let property = "alias"
            if (this.objectType == "devices") queryType = "deviceProperties"
            if (this.objectType == "enterpriseDevices") queryType = "enterpriseDeviceProperties"
            if (this.objectType == "uscUtms") {
                queryType = "uscUtms"
                property = "utmname"
            }
            let productType = tenantHelpers.getProductTypeFromObjectType(this.objectType)
            if (!productType) throw "Missing productType"
            this.loading = true

            let newProductType = config.canUseNewObjectType(this.objectType)
                ? products[productType as keyof typeof products]
                : undefined
            let newObjectType = newProductType?.[this.objectType as keyof typeof newProductType]

            let result: any = undefined

            if (newObjectType != undefined && config.canUseNewObjectType(this.objectType)) {
                if (this.objectType == "androidDevices") {
                    result = (<ObjectType<any>>newObjectType).queries.updateObjectPropertiesFromApi(
                        this.activeAccountId,
                        this.itemObjectId,
                        { [property]: this.newValue },
                        "alias",
                        undefined,
                        undefined,
                        "/properties?updateMask[]=alias"
                    )
                }
                if (this.objectType == "windowsVpns") {
                    result = (<ObjectType<any>>newObjectType).queries.updateObjectPropertiesFromApi(
                        this.activeAccountId,
                        this.itemObjectId,
                        { [property]: this.newValue },
                        "alias",
                        undefined,
                        undefined,
                        "/properties",
                        "PUT"
                    )
                } else {
                    result = (<ObjectType<any>>newObjectType).queries.updateObjectPropertiesFromApi(
                        this.activeAccountId,
                        this.itemObjectId,
                        { [property]: this.newValue },
                        "alias",
                        undefined,
                        undefined,
                        "/properties/alias",
                        "PUT"
                    )
                }
            } else {
                result = await useStore().dispatch(ActionTypes.updateObjectProperty, {
                    accountId: this.accountid,
                    objectId: this.itemObjectId,
                    productType: productType,
                    objectType: this.objectType,
                    queryType: queryType,
                    value: this.newValue,
                    object: { [property]: this.newValue },
                    property: property
                })
            }

            if (result) {
                this.loading = false
                this.$parent.editAlias = false
            }
        },
        close: function (this: any) {
            this.$parent.editAlias = false
        }
    },
    watch: {},
    components: {
        loader: loaderComponent,
        "input-text": inputTextComponent
    }
}
export default deviceInlineRenameComponent
