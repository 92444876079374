const enterpriseHelpers = {
    getCurrentPolicyName: function (enterpriseInfo: any): string {
        return (
            enterpriseInfo?.policyName?.split("/")[3] ||
            enterpriseInfo?.appliedPolicyName?.split("/")[3] ||
            ""
        )
    }
}
export default enterpriseHelpers
