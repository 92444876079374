class ClipboardHandler {
    public textArea: any
    constructor() {
        return this
    }
    isOS() {
        return navigator.userAgent.match(/ipad|iphone/i)
    }
    isMac() {
        const isMac = navigator.userAgent.toUpperCase().indexOf("MAC") != -1
    }
    createTextArea(text: string) {
        this.textArea = document.createElement("textArea")
        this.textArea.value = text
        document.body.appendChild(this.textArea)
    }
    selectText() {
        let range: any
        let selection: any

        if (this.isOS()) {
            range = document.createRange()
            range.selectNodeContents(this.textArea)
            selection = window.getSelection()
            selection.removeAllRanges()
            selection.addRange(range)
            this.textArea.setSelectionRange(0, 999999)
        } else {
            this.textArea.select()
        }
    }
    async copyToClipboard(text: string) {
        navigator.clipboard.writeText(text)
    }
    removeTextArea() {
        document.body.removeChild(this.textArea)
    }
    async copy(text: string) {
        this.createTextArea(text)
        this.selectText()
        await this.copyToClipboard(text)
        this.removeTextArea()
    }
}

export default ClipboardHandler
