<script setup lang="ts">
import { T } from "@/classes/i18n"
import dialogs from "@/dialogs/dialogs"
import deviceHelpers from "@/helpers/helpers.devices"
import encodingHelpers from "@/helpers/helpers.encoding"
import numberHelpers from "@/helpers/helpers.numbers"
import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"
import router from "@/router/router"
import { ActionTypes, useStore } from "@/store/vuex.store"
import Button from "@/templates/components/button/button"
import loaderComponent from "@/templates/components/loader.vue"
import { Chart, type ChartConfiguration } from "chart.js"
import $ from "jquery"
import { computed, onMounted, ref, watch } from "vue"
import deviceStatsHelpers from "../../helpers/helpers.deviceStats"
import table2 from "../components/table2"
var Base64: any

const activeAccountId = computed(function () {
    return useStore().state.session.activeAccountId
})
const accountInitialized = computed(function () {
    return useStore().state.session.accounts[activeAccountId.value || ""].initialized
})
const activeAccount = computed(function () {
    return useStore().state.session.accounts[activeAccountId.value || ""]
})

const search = ref({
    period: "today",
    type: "count",
    key: ""
})
const stats = ref({
    totalUrlBlocked: <string | undefined>undefined,
    totalUrlHits: <string | undefined>undefined,
    totalDevices: <string | undefined>undefined,
    totalViruses: <string | undefined>undefined,
    mostUsedDevices: {
        loading: false,
        tableData: { titles: <any[]>[], rows: <any[]>[], loading: <boolean>false }
    },
    topVirus: {
        loading: false,
        tableData: { titles: <any[]>[], rows: <any[]>[], loading: <boolean>false }
    },
    mostVisitedDomains: {
        loading: false,
        tableData: { titles: <any[]>[], rows: <any[]>[], loading: <boolean>false }
    },
    mostBlockedDomains: {
        loading: false,
        tableData: { titles: <any[]>[], rows: <any[]>[], loading: <boolean>false }
    },
    mostVisitedCategories: {
        loading: false,
        tableData: { titles: <any[]>[], rows: <any[]>[], loading: <boolean>false }
    },
    mostBlockedCategories: {
        loading: false,
        tableData: { titles: <any[]>[], rows: <any[]>[], loading: <boolean>false }
    }
})

const init = async () => {
    await useStore().dispatch(ActionTypes.getObjectInfos, {
        accountId: activeAccountId.value,
        objectTypes: ["historyVpns", "historyDevices"]
    })
    stats.value.totalUrlBlocked = undefined
    stats.value.totalUrlHits = undefined
    stats.value.totalDevices = undefined
    stats.value.totalViruses = undefined
    stats.value.mostUsedDevices = {
        loading: false,
        tableData: { loading: false, titles: <any[]>[], rows: <any[]>[] }
    }
    stats.value.topVirus = {
        loading: false,
        tableData: { loading: false, titles: <any[]>[], rows: <any[]>[] }
    }
    stats.value.mostVisitedDomains = {
        loading: false,
        tableData: { loading: false, titles: <any[]>[], rows: <any[]>[] }
    }
    stats.value.mostBlockedDomains = {
        loading: false,
        tableData: { loading: false, titles: <any[]>[], rows: <any[]>[] }
    }
    stats.value.mostVisitedCategories = {
        loading: false,
        tableData: { loading: false, titles: <any[]>[], rows: <any[]>[] }
    }
    stats.value.mostBlockedCategories = {
        loading: false,
        tableData: { loading: false, titles: <any[]>[], rows: <any[]>[] }
    }
    renderTotalHits()
    renderHitsChart()
    getDeviceTableData()
    renderVirusFoundTable()
    renderMostVisitedDomainsTable()
    renderMostVisitedCategoriesTable()
    renderMostBlockedCategoriesTable()
    renderMostBlockedDomainsTable()
}
const renderTotalHits = async () => {
    let activeTenantDomain = tenantHelpers.getTenantDomain(activeAccountId.value || "")
    let period: any = search.value.period
    let relate: any = search.value.type
    let timezone: any = activeAccount.value?.unifiedSecurity.account.timezone
    let queryCountBlocked: any = {
        query: {
            modul: "Http",
            name: "CountUrls",
            options: {
                relate: "count",
                period: period,
                size: 1,
                timezone: timezone,
                filter: [
                    {
                        field: "blocked",
                        operator: "equal",
                        value: true
                    }
                ]
            }
        }
    }
    let queryCountUrls: any = {
        query: {
            modul: "Http",
            name: "CountUrls",
            options: {
                relate: relate,
                period: period,
                size: 1,
                timezone: timezone,
                filter: []
            }
        }
    }
    let queryDevices: any = {
        query: {
            modul: "Http",
            name: "CountDevices",
            options: {
                relate: "count",
                period: period,
                size: 9999,
                timezone: timezone,
                filter: []
            }
        }
    }
    let queryVirus: any = {
        query: {
            modul: "Virus",
            name: "CountVirus",
            options: {
                relate: "count",
                period: period,
                size: 1,
                timezone: timezone,
                filter: []
            }
        }
    }

    let countBlocked: any = 0
    let countURLs: any = 0
    let countDevices: any = 0
    let countViruses: any = 0
    try {
        countBlocked = await requestHandler.request(
            "POST",
            "/sms-mgt-api/api/1.1/tenants/" + activeTenantDomain + "/stats/execute",
            queryCountBlocked
        )
        countURLs = await requestHandler.request(
            "POST",
            "/sms-mgt-api/api/1.1/tenants/" + activeTenantDomain + "/stats/execute",
            queryCountUrls
        )
        countDevices = await requestHandler.request(
            "POST",
            "/sms-mgt-api/api/1.1/tenants/" + activeTenantDomain + "/stats/execute",
            queryDevices
        )
        countViruses = await requestHandler.request(
            "POST",
            "/sms-mgt-api/api/1.1/tenants/" + activeTenantDomain + "/stats/execute",
            queryVirus
        )
    } catch (e: any) {
        console.error(e)
    }

    if (countBlocked.result[0].total != undefined) {
        stats.value.totalUrlBlocked = numberHelpers.readableNumber(countBlocked.result[0].total)
    } else {
        stats.value.totalUrlBlocked = "0"
    }
    if (countURLs.result[0].total != undefined) {
        if (relate == "count") {
            stats.value.totalUrlHits = numberHelpers.readableNumber(countURLs.result[0].total)
        } else if (relate == "traffic") {
            stats.value.totalUrlHits =
                numberHelpers.formatBytes(countURLs.result[0].total).value +
                " " +
                numberHelpers.formatBytes(countURLs.result[0].total).unit
        }
    } else {
        stats.value.totalUrlHits = "0"
    }
    if (countDevices.result.length != undefined) {
        stats.value.totalDevices = numberHelpers.readableNumber(countDevices.result.length)
    } else {
        stats.value.totalDevices = "0"
    }
    if (countViruses.result[0].count != undefined) {
        stats.value.totalViruses = numberHelpers.readableNumber(countViruses.result[0].count)
    } else {
        stats.value.totalViruses = "0"
    }
}
const renderHitsChart = async () => {
    let activeTenantDomain = tenantHelpers.getTenantDomain(activeAccountId.value || "")
    let relate: any = search.value.type
    let period: any = search.value.period
    let timezone: any = activeAccount.value.unifiedSecurity.account.timezone

    let queryChartData: any = {
        query: {
            modul: "Http",
            name: "UrlsHistogramm",
            options: {
                relate: relate,
                period: period,
                size: 2,
                offset: 0,
                order: {
                    asc: false
                },
                timezone: timezone,
                filter: []
            }
        }
    }

    $("#tenant-hits-chart-loading").show()

    try {
        var responseChartData = await requestHandler.request(
            "POST",
            "/sms-mgt-api/api/1.1/tenants/" + activeTenantDomain + "/stats/execute",
            queryChartData
        )
        //var responseChartData = arguments && arguments.length && arguments[0]
        // Create time chart
        // First remove the chart element and append it again
        $("#tenant-hits-chart").remove()
        $("#tenant-hits-chart-container").append('<canvas id="tenant-hits-chart"><canvas>')
        // Arrays for options object
        var chartLabels = []
        var chartDatapoints = []
        try {
            //
            var chartBuckets = responseChartData.result
            //
            for (
                var chartBucketIndex = 0;
                chartBucketIndex < chartBuckets.length;
                ++chartBucketIndex
            ) {
                var bucket = chartBuckets[chartBucketIndex]
                //
                var bucketTimestampInMilliseconds = bucket.interval
                var bucketUrlCount: any = bucket.count
                if (relate == "traffic") {
                    bucketUrlCount = numberHelpers.formatBytesToMb(bucket.bytes).value
                }
                // Get day and month
                var bucketDate = new Date(bucketTimestampInMilliseconds)
                //
                var bucketDay: number | string = bucketDate.getDate()
                bucketDay = bucketDay < 10 ? "0" + bucketDay : bucketDay
                // Month starts at 0 instead of 1
                var bucketMonth: number | string = bucketDate.getMonth() + 1
                bucketMonth = bucketMonth < 10 ? "0" + bucketMonth : bucketMonth
                //
                var dataDate: number | string = bucketDay + "." + bucketMonth
                // On period day we got hours
                if (period === "lastday" || period === "today") {
                    dataDate = bucketDate.getHours()
                    dataDate = dataDate < 10 ? "0" + dataDate : dataDate
                }
                //
                chartLabels.push(dataDate)
                chartDatapoints.push(bucketUrlCount)
                if (chartBuckets.length == 1) {
                    chartLabels.push(dataDate)
                    chartDatapoints.push(bucketUrlCount)
                }
            }
        } catch (chartError) {
            //
        }
        var labelString = T("Requests")
        if (relate == "traffic") {
            labelString = T("Traffic (MB)")
        }
        // Build data and option object
        var urlChartOptions: ChartConfiguration = {
            type: "line",
            data: {
                labels: chartLabels,
                datasets: [
                    {
                        label: labelString,
                        data: chartDatapoints,
                        backgroundColor: "rgba(235, 76, 54, 0.2)",
                        borderColor: "rgba(235, 76, 54, 1)",
                        fill: "start"
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    line: {
                        tension: 0.4
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    }
                },
                scales: {
                    x: {
                        stacked: true
                    },
                    y: {
                        ticks: {
                            //beginAtZero: true
                        },
                        title: {
                            display: true,
                            text: labelString
                        }
                    }
                }
            }
        }
        //
        var context = (<HTMLCanvasElement>document.getElementById("tenant-hits-chart"))!.getContext(
            "2d"
        )
        if (context) {
            new Chart(context, urlChartOptions)
            $("#tenant-hits-chart-loading").fadeOut()
        }
    } catch (e) {
        console.error(e)
        $("#tenant-hits-chart-loading").fadeOut()
        $("#tenant-hits-chart-container").text(T("No hits found"))
    }
}
const getDeviceTableData = async () => {
    let activeTenantDomain = tenantHelpers.getTenantDomain(activeAccountId.value || "")
    let privacyLevel: string = activeAccount.value.unifiedSecurity.privacy.privacyLevel
    let relate: any = search.value.type
    let period: any = search.value.period
    let timezone: any = activeAccount.value.unifiedSecurity.account.timezone
    let devices: any = {}
    let vpns: any = {}
    let queryDevices: any = {
        query: {
            modul: "Http",
            name: "CountDevices",
            options: {
                relate: relate,
                period: period,
                size: 9999,
                timezone: timezone,
                filter: []
            }
        }
    }
    try {
        let countDevices: any = await requestHandler.request(
            "POST",
            "/sms-mgt-api/api/1.1/tenants/" + activeTenantDomain + "/stats/execute",
            queryDevices
        )
        devices = JSON.parse(
            JSON.stringify(activeAccount.value.mobileSecurity.historyDevices.items)
        )
        vpns = JSON.parse(JSON.stringify(activeAccount.value.mobileSecurity.historyVpns.items))
        let buckets = []
        let topDevicesList: any = []
        let thisValue: any = ""
        let relateKey = "count"
        stats.value.mostUsedDevices.loading = true
        stats.value.mostUsedDevices.tableData.titles = [
            {
                id: "position",
                text: "#",
                sortable: false,
                width: 45
            },
            {
                id: "device",
                text: "Device",
                sortable: false,
                width: 300
            },
            {
                id: "hits",
                text: relate == "count" ? "Requests" : "Traffic",
                sortable: false
            },
            {
                id: "actions",
                text: "Actions",
                sortable: false,
                width: 100
            }
        ]

        if (relate == "traffic") {
            relateKey = "bytes"
        }
        try {
            for (let i in countDevices.result) {
                if (countDevices.result[i].device != null) {
                    buckets.push(countDevices.result[i])
                }
            }
            // Device query also fill the device table
            //
            var sumAllCounts = deviceStatsHelpers.sumArrayFields(buckets, relateKey)
            //
            stats.value.mostUsedDevices.tableData.rows = []

            for (var bucketIndex = 0; bucketIndex < buckets.length; ++bucketIndex) {
                const bucket = buckets[bucketIndex]

                if (!bucket?.device) {
                    bucket.device = ""
                }
                if (relate == "count") {
                    thisValue = numberHelpers.readableNumber(bucket[relateKey])
                } else if (relate == "traffic") {
                    thisValue =
                        numberHelpers.formatBytes(bucket[relateKey]).value +
                        " " +
                        numberHelpers.formatBytes(bucket[relateKey]).unit
                }

                let thisDeviceHtml: any = ""
                // calc the percentage usage of each value, based on the sum off all array items
                // not of all items in the database!
                var percentageUsage = Math.ceil((bucket[relateKey] / sumAllCounts) * 100)
                /* bucket.key is uuid, that is: device is not deleted */

                let isEnterprise: boolean = false

                thisDeviceHtml = deviceHelpers.getAliasedShortDeviceId(
                    bucket.device,
                    devices.reduce(function (label: string, device: any) {
                        if (device.deviceId == bucket.device) {
                            label = device.alias
                        } else if (
                            device.name != undefined &&
                            device.name.split("/")[3] == bucket.device
                        ) {
                            label = device.alias
                        } else if (bucket.device == null) {
                            label = ""
                        }
                        if (device.anonymousDeviceId == bucket.device && device.enterpriseDevice) {
                            isEnterprise = true
                        }
                        return (thisDeviceHtml = label)
                    }, "") ||
                        vpns.reduce(function (label: string, vpn: any) {
                            if (vpn.vpnId == bucket.device) {
                                label = vpn.vpnname
                            }
                            return (thisDeviceHtml = label)
                        }, "")
                )

                stats.value.mostUsedDevices.tableData.rows.push({
                    data: {
                        position: bucketIndex + 1,
                        device:
                            privacyLevel == "1"
                                ? T("Anonymized device") + " (" + thisDeviceHtml + ")"
                                : thisDeviceHtml,
                        hits: thisValue + " (" + percentageUsage + "%)",
                        actions:
                            privacyLevel == "1"
                                ? {
                                      buttons: [
                                          new Button({
                                              icon: "fal fa-eye",
                                              title: T("deanonymize device"),
                                              onClick: () => {
                                                  dialogs.mobileSecurity.deanonymizeDevice(
                                                      useStore().state.session.activeAccountId ||
                                                          "",
                                                      "historyDevices",
                                                      bucket.device,
                                                      isEnterprise
                                                  )
                                              }
                                          })
                                      ]
                                  }
                                : ""
                    }
                })
            }
        } catch (bucketError) {
        } finally {
            stats.value.mostUsedDevices.loading = false
        }
    } catch (e: any) {
        console.error(e)
        $("#tenant-stats-devices-table").empty()
        $("#tenant-stats-devices-loading").fadeOut()
    }
}
const renderVirusFoundTable = async () => {
    let activeTenantDomain = tenantHelpers.getTenantDomain(activeAccountId.value || "")
    let period: any = search.value.period
    let timezone: any = activeAccount.value.unifiedSecurity.account.timezone

    stats.value.topVirus.loading = true
    stats.value.topVirus.tableData.titles = [
        {
            id: "position",
            text: "#",
            sortable: false,
            width: 45
        },
        {
            id: "virus",
            text: "Virus",
            sortable: false,
            width: 300
        },
        {
            id: "count",
            text: "Count",
            sortable: false
        }
    ]

    let queryTableData: any = {
        query: {
            modul: "Virus",
            name: "TopVirus",
            options: {
                relate: "count",
                period: period,
                size: 9999,
                timezone: timezone,
                filter: []
            }
        }
    }

    $("#tenant-stats-virus-loading").show()
    stats.value.topVirus.tableData.rows = []

    let responseTableData = await requestHandler.request(
        "POST",
        "/sms-mgt-api/api/1.1/tenants/" + activeTenantDomain + "/stats/execute",
        queryTableData
    )

    // Build table
    let buckets = []
    try {
        buckets = responseTableData.result
        //
        var sumAllCounts = deviceStatsHelpers.sumArrayFields(buckets, "count")
        //
        for (var bucketIndex = 0; bucketIndex < buckets.length; ++bucketIndex) {
            var bucket = buckets[bucketIndex]
            if (bucket && !bucket.virus) {
                continue
            }
            // calc the percentage usage of each value, based on the sum off all array items
            // not of all items in the database!
            var percentageUsage = Math.ceil((bucket.count / sumAllCounts) * 100)
            //

            stats.value.topVirus.tableData.rows.push({
                data: {
                    position: bucketIndex + 1,
                    virus: encodingHelpers.escapeHTML(bucket.virus),
                    count:
                        numberHelpers.readableNumber(bucket.count) + " (" + percentageUsage + "%)"
                }
            })
        }
    } catch (responseError) {
        //
    } finally {
        stats.value.topVirus.loading = false
    }
}

const renderMostVisitedDomainsTable = async () => {
    let activeTenantDomain = tenantHelpers.getTenantDomain(activeAccountId.value || "")
    let period: any = search.value.period
    let timezone: any = activeAccount.value.unifiedSecurity.account.timezone
    let relate: any = search.value.type
    let mostVisitedDomains: any = []
    let thisValue: any = ""
    let relateKey = "count"
    if (relate == "traffic") {
        relateKey = "bytes"
    }

    stats.value.mostVisitedDomains.loading = true
    stats.value.mostVisitedDomains.tableData.rows = []
    stats.value.mostVisitedDomains.tableData.titles = [
        {
            id: "position",
            text: "#",
            sortable: false,
            width: 45
        },
        {
            id: "domain",
            text: "Domain",
            sortable: false,
            width: 300
        },
        {
            id: "total",
            text: relate == "count" ? "Requests" : "Traffic",
            sortable: false
        }
    ]

    let queryTableData: any = {
        query: {
            modul: "Http",
            name: "TopUrls",
            options: {
                relate: relate,
                period: period,
                size: 10,
                offset: 0,
                timezone: timezone,
                order: {
                    asc: false
                },
                filter: [
                    {
                        field: "blocked",
                        operator: "equal",
                        value: false
                    }
                ]
            }
        }
    }

    $("#tenant-stats-visited-domains-loading").show()
    let responseTableData = await requestHandler.request(
        "POST",
        "/sms-mgt-api/api/1.1/tenants/" + activeTenantDomain + "/stats/execute",
        queryTableData
    )

    // Build table
    let buckets = []
    try {
        buckets = responseTableData.result
        //
        var sumAllCounts = deviceStatsHelpers.sumArrayFields(buckets, relateKey)
        //
        for (var bucketIndex = 0; bucketIndex < buckets.length; ++bucketIndex) {
            var bucket = buckets[bucketIndex]
            if (bucket && !bucket.url) {
                continue
            }
            // calc the percentage usage of each value, based on the sum off all array items
            // not of all items in the database!
            var percentageUsage = Math.ceil((bucket[relateKey] / sumAllCounts) * 100)
            if (relate == "count") {
                thisValue = numberHelpers.readableNumber(bucket[relateKey])
            } else if (relate == "traffic") {
                thisValue =
                    numberHelpers.formatBytes(bucket[relateKey]).value +
                    " " +
                    numberHelpers.formatBytes(bucket[relateKey]).unit
            }

            stats.value.mostVisitedDomains.tableData.rows.push({
                data: {
                    position: bucketIndex + 1,
                    domain: encodingHelpers.escapeHTML(
                        encodingHelpers.punycodeToUnicode(bucket.url)
                    ),
                    total: thisValue + " (" + percentageUsage + "%)"
                }
            })
        }
    } catch (responseError) {
        //
    } finally {
        stats.value.mostVisitedDomains.loading = false
    }
}

const renderMostVisitedCategoriesTable = async () => {
    let activeTenantDomain = tenantHelpers.getTenantDomain(activeAccountId.value || "")
    let period: any = search.value.period
    let timezone: any = activeAccount.value.unifiedSecurity.account.timezone

    $("#tenant-stats-visited-categories-loading").show()
    let relate: any = search.value.type
    let thisValue: any = ""

    let relateKey = "count"
    if (relate == "traffic") {
        relateKey = "bytes"
    }
    var query = {
        query: {
            modul: "Http",
            name: "TopCategories",
            options: {
                relate: "traffic",
                period: period,
                size: 10,
                offset: 0,
                timezone: timezone,
                order: {
                    asc: false
                },
                filter: [{ field: "blocked", operator: "equal", value: false }]
            }
        }
    }

    stats.value.mostVisitedCategories.loading = true
    stats.value.mostVisitedCategories.tableData.rows = []
    stats.value.mostVisitedCategories.tableData.titles = [
        {
            id: "position",
            text: "#",
            sortable: false,
            width: 45
        },
        {
            id: "category",
            text: "Category",
            sortable: false,
            width: 300
        },
        {
            id: "total",
            text: relate == "count" ? "Requests" : "Traffic",
            sortable: false
        }
    ]

    let response = await requestHandler.request(
        "POST",
        "/sms-mgt-api/api/1.1/tenants/" + activeTenantDomain + "/stats/execute",
        query
    )

    let buckets = []
    try {
        buckets = response.result

        //
        var sumAllCounts = deviceStatsHelpers.sumArrayFields(buckets, relateKey)
        //
        for (var bucketIndex = 0; bucketIndex < buckets.length; ++bucketIndex) {
            var bucket = buckets[bucketIndex]

            if (bucket && !bucket.cat) {
                continue
            }

            if (relate == "count") {
                thisValue = numberHelpers.readableNumber(bucket[relateKey])
            } else if (relate == "traffic") {
                thisValue =
                    numberHelpers.formatBytes(bucket[relateKey]).value +
                    " " +
                    numberHelpers.formatBytes(bucket[relateKey]).unit
            }
            // calc the percentage usage of each value, based on the sum off all array items
            // not of all items in the database!
            var percentageUsage = Math.ceil((bucket[relateKey] / sumAllCounts) * 100)
            //

            stats.value.mostVisitedCategories.tableData.rows.push({
                data: {
                    position: bucketIndex + 1,
                    category: categoryFromNumber(bucket.cat),
                    total: thisValue + " (" + percentageUsage + "%)"
                }
            })
        }
    } catch (responseError) {
        //
    } finally {
        stats.value.mostVisitedCategories.loading = false
    }
}

const renderMostBlockedCategoriesTable = async () => {
    let activeTenantDomain = tenantHelpers.getTenantDomain(activeAccountId.value || "")
    let period: any = search.value.period
    let timezone: any = activeAccount.value.unifiedSecurity.account.timezone

    $("#tenant-stats-blocked-categories-loading").show()

    var query = {
        query: {
            modul: "Http",
            name: "TopCategories",
            options: {
                relate: "count",
                period: period,
                size: 10,
                offset: 0,
                timezone: timezone,
                order: {
                    asc: false
                },
                filter: [{ field: "blocked", operator: "equal", value: true }]
            }
        }
    }

    stats.value.mostBlockedCategories.loading = true
    stats.value.mostBlockedCategories.tableData.rows = []
    stats.value.mostBlockedCategories.tableData.titles = [
        {
            id: "position",
            text: "#",
            sortable: false,
            width: 45
        },
        {
            id: "category",
            text: "Category",
            sortable: false,
            width: 300
        },
        {
            id: "total",
            text: "Requests",
            sortable: false
        }
    ]
    let response = await requestHandler.request(
        "POST",
        "/sms-mgt-api/api/1.1/tenants/" + activeTenantDomain + "/stats/execute",
        query
    )
    var tableContent = ""
    let buckets = []
    try {
        buckets = response.result
        //
        var sumAllCounts = deviceStatsHelpers.sumArrayFields(buckets, "count")
        //
        for (var bucketIndex = 0; bucketIndex < buckets.length; ++bucketIndex) {
            var bucket = buckets[bucketIndex]
            if (bucket && !bucket.cat) {
                continue
            }
            //
            // calc the percentage usage of each value, based on the sum off all array items
            // not of all items in the database!
            var percentageUsage = Math.ceil((bucket.count / sumAllCounts) * 100)
            //

            stats.value.mostBlockedCategories.tableData.rows.push({
                data: {
                    position: bucketIndex + 1,
                    category: categoryFromNumber(bucket.cat),
                    total:
                        numberHelpers.readableNumber(bucket.count) + " (" + percentageUsage + "%)"
                }
            })
        }
    } catch (responseError) {
        //
    } finally {
        stats.value.mostBlockedCategories.loading = false
    }
}
const renderMostBlockedDomainsTable = async () => {
    let activeTenantDomain = tenantHelpers.getTenantDomain(activeAccountId.value || "")
    let period: any = search.value.period
    let timezone: any = activeAccount.value.unifiedSecurity.account.timezone
    let queryTableData: any = {
        query: {
            modul: "Http",
            name: "TopUrls",
            options: {
                relate: "count",
                period: period,
                size: 10,
                offset: 0,
                timezone: timezone,
                order: {
                    asc: false
                },
                filter: [
                    {
                        field: "blocked",
                        operator: "equal",
                        value: true
                    }
                ]
            }
        }
    }
    $("#tenant-stats-blocked-domains-loading").show()

    stats.value.mostBlockedDomains.loading = true
    stats.value.mostBlockedDomains.tableData.rows = []
    stats.value.mostBlockedDomains.tableData.titles = [
        {
            id: "position",
            text: "#",
            sortable: false,
            width: 45
        },
        {
            id: "domain",
            text: "Domain",
            sortable: false,
            width: 300
        },
        {
            id: "total",
            text: "Requests",
            sortable: false
        }
    ]

    let responseTableData = await requestHandler.request(
        "POST",
        "/sms-mgt-api/api/1.1/tenants/" + activeTenantDomain + "/stats/execute",
        queryTableData
    )
    // Build table
    let buckets = []
    try {
        buckets = responseTableData.result
        //
        var sumAllCounts = deviceStatsHelpers.sumArrayFields(buckets, "count")
        //
        for (var bucketIndex = 0; bucketIndex < buckets.length; ++bucketIndex) {
            var bucket = buckets[bucketIndex]
            if (bucket && !bucket.url) {
                continue
            }
            // calc the percentage usage of each value, based on the sum off all array items
            // not of all items in the database!
            var percentageUsage = Math.ceil((bucket.count / sumAllCounts) * 100)
            //

            stats.value.mostBlockedDomains.tableData.rows.push({
                data: {
                    position: bucketIndex + 1,
                    domain: encodingHelpers.escapeHTML(
                        encodingHelpers.punycodeToUnicode(bucket.url)
                    ),
                    total:
                        numberHelpers.readableNumber(bucket.count) + " (" + percentageUsage + "%)"
                }
            })
        }
    } catch (responseError) {
        //
    } finally {
        stats.value.mostBlockedDomains.tableData.loading = false
    }
}
const categoryFromNumber = (_ip: number | string) => {
    let ip = "" + _ip
    let len = ip.length
    if (len == 12 || len == 16) {
        ip = ip
            .match(/(.{1,3})\.?/g)!
            .map((octed) => parseInt(octed, 10))
            .join(".")
    }
    // Debug domain
    if (ip == "127.0.999.999") {
        return T("No cf message")
    } else if (ip == "127.0.70.1") {
        return T("Server unreached. Global def.")
    } else if (ip == "127.0.70.2") {
        return T("Server unreached. Group def.")
    } else if (ip == "127.0.70.3") {
        return T("No rules for host, user or ip")
    } else if (ip == "127.0.70.4") {
        return T("List did't match")
    } else if (ip == "127.0.70.5") {
        return T("List did't match. Internal def.")
    } else if (ip == "127.0.70.6") {
        return T("Group define")
    } else if (ip == "127.0.70.7") {
        return T("List define")
    }
    return useStore().state.resources.ContentFilterCategories[ip]
        ? useStore().state.resources.ContentFilterCategories[ip].category
        : ""
}
const showTenantReportSearch = async (type: any, device: any = "all") => {
    let activeTenantDomain = tenantHelpers.getTenantDomain(activeAccountId.value || "")
    // Get timezone
    let timezone: any = activeAccount.value.unifiedSecurity.account.timezone

    var searchObject = {
        period: search.value.period,
        type: "domain",
        device: device,
        alert: "all",
        category: "all",
        string: "",
        domainType: "all",
        relate: search.value.type,
        term: ""
    }

    switch (type) {
        case "devices":
            searchObject.type = "list"
            break
        case "blocked":
            searchObject.domainType = "blocked"
            break
        case "viruses":
            searchObject.type = "viruses"
            searchObject.relate = "count"
            break
    }
    var baseString = searchObjectAsBase64(searchObject)
    router.navigate("show-tenant-" + activeTenantDomain + "-stats-search-" + baseString)
}
const searchObjectAsBase64 = (searchObject: any) => {
    return Base64.encodeURI(JSON.stringify(searchObject))
}
const setSearchUrl = () => {
    let searchRelate: string = search.value.type
    let searchPeriod: string = search.value.period
    let activeTenantDomain = tenantHelpers.getTenantDomain(activeAccountId.value || "")
    let thisHash: string =
        "show-tenant-" +
        activeTenantDomain +
        "-stats-dashboard-" +
        searchPeriod +
        "-" +
        searchRelate
    router.navigate(thisHash)
}

watch(
    search,
    () => {
        setSearchUrl()
        init()
    },
    {
        deep: true
    }
)
onMounted(() => {
    init()
})

defineExpose({
    search // expose search for external access
})
</script>
<template>
    <section class="padding-xs padding-md-2 padding-lg-4 pagecontent">
        <div class="row media-screen-only" v-if="accountInitialized != undefined">
            <div class="col-xs-24 padding-xs-b">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="padding-xs-t padding-xs-l">
                            <div class="toolbar flexrow">
                                <div
                                    class="col-xs"
                                    style="margin-bottom: 8px; margin-right: 8px"
                                    :title="T('Period')"
                                >
                                    <label class="form-field margin-xs-b-0">
                                        <span class="form-icon-prefix"
                                            ><i class="fal fa-clock"></i
                                        ></span>
                                        <select
                                            id="tenant-report-dashboard-period"
                                            v-model="search.period"
                                            class="form-control"
                                        >
                                            <option value="today">{{ T("Today") }}</option>
                                            <option value="lastday">{{ T("Yesterday") }}</option>
                                            <option value="currentweek">
                                                {{ T("Current week") }}
                                            </option>
                                            <option value="currentmonth">
                                                {{ T("Current month") }}
                                            </option>
                                            <option value="last30days">
                                                {{ T("Last 30 days") }}
                                            </option>
                                        </select>
                                    </label>
                                </div>
                                <div
                                    class="col-xs"
                                    style="margin-bottom: 8px; margin-right: 8px"
                                    :title="T('Output')"
                                >
                                    <label class="form-field margin-xs-b-0">
                                        <span class="form-icon-prefix"
                                            ><i class="fal fa-hashtag"></i
                                        ></span>
                                        <select
                                            id="tenant-report-dashboard-type"
                                            v-model="search.type"
                                            class="form-control"
                                        >
                                            <option value="count">{{ T("Requests") }}</option>
                                            <option value="traffic">{{ T("Traffic") }}</option>
                                        </select>
                                    </label>
                                </div>
                                <div
                                    class="btn-toolbar"
                                    style="margin-bottom: 8px; margin-right: 8px"
                                >
                                    <div class="btn-group">
                                        <a
                                            class="btn margin-btn-b-0 btn-loader twist-in"
                                            v-on:click="init()"
                                            title="Refresh"
                                        >
                                            <span class="animate">
                                                <i
                                                    class="progress-circular no-progress"
                                                    style="font-size: 1.5em; top: 2px"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="1em"
                                                        height="1em"
                                                        viewBox="0 0 50 50"
                                                        style="stroke-width: 4px"
                                                    >
                                                        <circle
                                                            cx="25"
                                                            cy="25"
                                                            r="20"
                                                            style="
                                                                stroke: rgba(0, 0, 0, 0.1);
                                                                fill: none;
                                                            "
                                                        ></circle>
                                                        <g transform="translate(25,25) rotate(-90)">
                                                            <circle
                                                                stroke-dasharray="110"
                                                                stroke-dashoffset="0"
                                                                cx="0"
                                                                cy="0"
                                                                r="20"
                                                                style="
                                                                    fill: none;
                                                                    stroke-linecap: round;
                                                                "
                                                                transform="rotate(14.0181)"
                                                            >
                                                                <animate
                                                                    attributeName="stroke-dashoffset"
                                                                    values="360;140"
                                                                    dur="2.2s"
                                                                    keyTimes="0;1"
                                                                    calcMode="spline"
                                                                    fill="freeze"
                                                                    keySplines="0.41,0.314,0.8,0.54"
                                                                    repeatCount="indefinite"
                                                                    begin="0"
                                                                ></animate>
                                                                <animate
                                                                    attributeName="stroke"
                                                                    fill="freeze"
                                                                    dur="8s"
                                                                    begin="0"
                                                                    repeatCount="indefinite"
                                                                ></animate>
                                                            </circle>
                                                        </g>
                                                    </svg>
                                                </i>
                                            </span>
                                            <span><i class="fal fa-fw fa-sync"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row print-new-page">
            <div class="col-xs-24 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3>
                                <i class="fal fa-chart-area"></i>
                                <template v-if="search.type == 'count'">
                                    {{ T("Hits total") }}
                                </template>
                                <template v-if="search.type == 'traffic'">
                                    {{ T("Total traffic") }}
                                </template>
                            </h3>
                            <div class="row-2"><hr class="margin-xs-b-2" /></div>
                            <div
                                id="tenant-hits-chart-container"
                                class="chart"
                                style="max-height: 350px"
                            >
                                <canvas id="tenant-hits-chart"></canvas>
                            </div>
                            <div id="tenant-hits-chart-loading" class="overlay box-overlay">
                                <i
                                    class="progress-circular no-progress"
                                    style="
                                        stroke: #e74c3c;
                                        font-size: 48px;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                    "
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1em"
                                        height="1em"
                                        viewBox="0 0 50 50"
                                        style="stroke-width: 4px"
                                    >
                                        <circle
                                            cx="25"
                                            cy="25"
                                            r="20"
                                            style="stroke: rgba(0, 0, 0, 0.1); fill: none"
                                        ></circle>
                                        <g transform="translate(25,25) rotate(-90)">
                                            <circle
                                                stroke-dasharray="110"
                                                stroke-dashoffset="0"
                                                cx="0"
                                                cy="0"
                                                r="20"
                                                style="fill: none; stroke-linecap: round"
                                                transform="rotate(14.0181)"
                                            >
                                                <animate
                                                    attributeName="stroke-dashoffset"
                                                    values="360;140"
                                                    dur="2.2s"
                                                    keyTimes="0;1"
                                                    calcMode="spline"
                                                    fill="freeze"
                                                    keySplines="0.41,0.314,0.8,0.54"
                                                    repeatCount="indefinite"
                                                    begin="0"
                                                ></animate>
                                                <animate
                                                    attributeName="stroke"
                                                    fill="freeze"
                                                    dur="8s"
                                                    begin="0"
                                                    repeatCount="indefinite"
                                                ></animate>
                                            </circle>
                                        </g>
                                    </svg>
                                </i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-24 col-md-6 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-icon icon-center">
                            <div class="icon-circle icon-circle bg-green">
                                <i class="fal fa-signal"></i>
                            </div>
                        </div>
                        <div class="box-content-2 text-center">
                            <p class="text-size-3" v-if="stats.totalUrlHits != undefined">
                                {{ stats.totalUrlHits }}
                            </p>
                            <p v-else class="text-size-3 color-red">
                                <loaderComponent></loaderComponent>
                            </p>
                        </div>
                        <hr />
                        <div class="text-center text-bold padding-xs-y-2">
                            <template v-if="search.type == 'count'">
                                {{ T("Hits total") }}
                            </template>
                            <template v-if="search.type == 'traffic'">
                                {{ T("Total traffic") }}
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-md-6 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-icon icon-center">
                            <div class="icon-circle icon-circle bg-yellow">
                                <i class="fal fa-mobile-alt"></i>
                            </div>
                        </div>
                        <div class="box-content-2 text-center">
                            <p class="text-size-3" v-if="stats.totalDevices != undefined">
                                {{ stats.totalDevices }}
                            </p>
                            <p v-else class="text-size-3 color-red">
                                <loaderComponent></loaderComponent>
                            </p>
                        </div>
                        <hr />
                        <div class="text-center text-bold padding-xs-y-2">
                            {{ T("Devices used") }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-md-6 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-icon icon-center">
                            <div class="icon-circle icon-circle bg-aqua">
                                <i class="fal fa-ban"></i>
                            </div>
                        </div>
                        <div class="box-content-2 text-center">
                            <p class="text-size-3" v-if="stats.totalUrlBlocked != undefined">
                                {{ stats.totalUrlBlocked }}
                            </p>
                            <p v-else class="text-size-3 color-red">
                                <loaderComponent></loaderComponent>
                            </p>
                        </div>
                        <hr />
                        <div class="text-center text-bold padding-xs-y-2">
                            {{ T("Blocked domains") }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-md-6 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-icon icon-center">
                            <div class="icon-circle icon-circle bg-red">
                                <i class="fal fa-bug"></i>
                            </div>
                        </div>
                        <div class="box-content-2 text-center">
                            <p class="text-size-3" v-if="stats.totalViruses != undefined">
                                {{ stats.totalViruses }}
                            </p>
                            <p v-else class="text-size-3 color-red">
                                <loaderComponent></loaderComponent>
                            </p>
                        </div>
                        <hr />
                        <div class="text-center text-bold padding-xs-y-2">
                            {{ T("Virus found") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-24 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3>
                                <i class="fal fa-mobile-alt"></i>
                                {{ T("Most used devices") }}
                            </h3>
                            <div class="row-2"><hr class="margin-xs-b-2" /></div>
                            <table2
                                ref="mostUsedDevices"
                                class="mostUsedDevices"
                                :loading="stats.mostUsedDevices.loading"
                                :table="stats.mostUsedDevices.tableData"
                                :searchable="false"
                                maxheight="520"
                            ></table2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row print-new-page">
            <div class="col-xs-24 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3><i class="fal fa-bug"></i> {{ T("Top virus") }}</h3>
                            <div class="row-2"><hr class="margin-xs-b-2" /></div>
                            <table2
                                ref="topVirus"
                                class="topVirus"
                                :loading="stats.topVirus.loading"
                                :table="stats.topVirus.tableData"
                                :searchable="false"
                                maxheight="520"
                            ></table2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row print-new-page">
            <div class="col-xs-24 col-md-12 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3>
                                <i class="fal fa-chart-line"></i>
                                <template v-if="search.type == 'count'">
                                    {{ T("Most visited domains") }}
                                </template>
                                <template v-if="search.type == 'traffic'">
                                    {{ T("Traffic per domain") }}
                                </template>
                            </h3>
                            <div class="row-2"><hr class="margin-xs-b-2" /></div>
                            <table2
                                ref="mostVisitedDomains"
                                class="mostVisitedDomains"
                                :loading="stats.mostVisitedDomains.loading"
                                :table="stats.mostVisitedDomains.tableData"
                                :searchable="false"
                                maxheight="320"
                            ></table2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-md-12 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3><i class="fal fa-ban"></i> {{ T("Most blocked domains") }}</h3>
                            <div class="row-2"><hr class="margin-xs-b-2" /></div>
                            <table2
                                ref="mostBlockedDomains"
                                class="mostBlockedDomains"
                                :loading="stats.mostBlockedDomains.loading"
                                :table="stats.mostBlockedDomains.tableData"
                                :searchable="false"
                                maxheight="320"
                            ></table2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row print-new-page">
            <div class="col-xs-24 col-md-12 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3>
                                <i class="fal fa-chart-line"></i>
                                <template v-if="search.type == 'count'">
                                    {{ T("Most visited categories") }}
                                </template>
                                <template v-if="search.type == 'traffic'">
                                    {{ T("Traffic per category") }}
                                </template>
                            </h3>
                            <div class="row-2"><hr class="margin-xs-b-2" /></div>
                            <table2
                                ref="mostVisitedCategories"
                                class="mostVisitedCategories"
                                :loading="stats.mostVisitedCategories.loading"
                                :table="stats.mostVisitedCategories.tableData"
                                :searchable="false"
                                maxheight="320"
                            ></table2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-24 col-md-12 padding-xs">
                <div class="box-shadow">
                    <div class="box-body">
                        <div class="box-content-2">
                            <h3><i class="fal fa-ban"></i> {{ T("Most blocked categories") }}</h3>
                            <div class="row-2"><hr class="margin-xs-b-2" /></div>
                            <table2
                                ref="mostBlockedCategories"
                                class="mostBlockedCategories"
                                :loading="stats.mostBlockedCategories.loading"
                                :table="stats.mostBlockedCategories.tableData"
                                :searchable="false"
                                maxheight="320"
                            ></table2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
