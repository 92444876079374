import storeHelpers from "@/helpers/helpers.store"
import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"
import { ActionTypes, MutationTypes, useStore } from "@/store/vuex.store"
import apis from "../apis"
import config from "../config"
import cookies from "../cookieHandler"
import {
    GenericObjectStore,
    type GenericObject,
    type GetPropertiesObjectList,
    type shemaErrors
} from "../genericObjectStore"
import { T } from "../i18n"
import devLog from "../log"
import products from "../objectTypes"
import type { EnterpriseDevice, EnterpriseDevicePlusZeroTouch } from "./enterpriseDevices"

export interface ZeroTouchDevice extends GenericObject<ZeroTouchDevice> {
    zeroTouchDevice: boolean
    deviceId: string
    name: string
    claims: {
        ownerCompanyId: string
        resellerId: string
        sectionType: string
    }[]
    deviceMetadata?: any // Only strings inside
    configuration?: any
    policy?: string
    deviceIdentifier: {
        imei?: string // One of: imei or meid
        meid?: string // One of: imei or meid
        manufacturer?: string
        serialNumber?: string // Wifi only
        model?: string // Wifi only
    }
}

export const fakeZTDevices: ZeroTouchDevice[] = [
    {
        zeroTouchDevice: true,
        deviceId: "1968390440644697608",
        name: "customers/1367085738/devices/1968390440644697608",
        claims: [
            {
                ownerCompanyId: "1367085738",
                resellerId: "303432115",
                sectionType: "SECTION_TYPE_ZERO_TOUCH"
            }
        ],
        configuration: "test/test/test/153175953",
        deviceMetadata: {},
        deviceIdentifier: {
            serialNumber: "00ce26976fb9934e",
            model: "Nexus 5X"
        }
    }
]

export default class ZeroTouchDevices extends GenericObjectStore<ZeroTouchDevice> {
    constructor() {
        super()
        this.settings.primaryKeyProperty = "deviceId"
        this.settings.productType = "mobileSecurity"
        this.settings.objectType = "zeroTouchDevices"
        this.settings.appearance.iconClass = "fal fa-mobile-alt"
        this.settings.appearance.text.singular = "Device"
        this.settings.appearance.text.plural = "Devices"
        this.settings.appearance.text.title = "Devices"
        this.settings.apiInfo.listPath =
            "/tenants/{tenantDomain}/android/androiddeviceprovisioning/customers/{customerId}/devices?pageSize=75"
        this.settings.apiInfo.objectListPropertyInResponse = "devices"

        this.settings.nameProperty.primary = <keyof ZeroTouchDevice>"deviceId"
        this.settings.nameProperty.secondary = undefined
    }

    public dialogs = {
        renderTosDialog: (accountId: string, message: string) => {
            useStore()?.dispatch(ActionTypes.addModal, {
                accountId: accountId,
                id: "androidTOSNotAccepted",
                abortable: true,
                content: {
                    title: {
                        text: T("Terms of Service not accepted"),
                        icon: "fal fa-text"
                    },
                    body: {
                        use: true,
                        content: `
              <p>
                ${T("There is a lack of agreement to the terms of use for Android Zero Touch.")}<br>
                ${T("Please visit $1 in order to agree to the terms of use.").replace("$1", "<a href='https://partner.android.com/zerotouch' target='_blank'>https://partner.android.com/zerotouch</a>")}
              </p>
              <p>
                <strong>${T("Message")}:</strong><br>
                ${message}
              </p>
            `,
                        component: undefined
                    }
                },
                buttons: [
                    {
                        loading: false,
                        onClick: function () {
                            useStore()?.commit(MutationTypes.removeModal, {
                                accountId: accountId
                            })
                        },
                        icon: "fal fa-times",
                        text: T("Close"),
                        align: "center",
                        disabled: false
                    }
                ]
            })
        }
    }

    /**
     * Adds or Updates object(s) in this class' object list depending on its existance
     * @param objects
     */
    addOrUpdateObjectsInStore(
        accountId: string,
        objects: ZeroTouchDevice | Array<ZeroTouchDevice>
    ) {
        let items = []
        if (Array.isArray(objects)) {
            items = objects
        } else {
            items = [objects]
        }

        items.forEach((item) => {
            item = this.addGenericObjectInfos(accountId, item)
        })

        let result = useStore()?.commit(MutationTypes.addOrUpdateObjects, {
            accountId: accountId,
            productType: this.settings.productType,
            objectType: this.settings.objectType,
            items: items
        })

        let addedAndUpdatedItems: any[] = []
        const enterpriseDevicesStore = storeHelpers.getObjectTypeStore(
            accountId,
            "mobileSecurity",
            "enterpriseDevices"
        )
        items.forEach((item: ZeroTouchDevice) => {
            if (enterpriseDevicesStore?.items?.length) {
                const ztDeviceId: string = item.deviceId,
                    ztImei: string | undefined = item.deviceIdentifier.imei || undefined,
                    ztMeid: string | undefined = item.deviceIdentifier.meid || undefined,
                    ztManufacturer: string | undefined =
                        item.deviceIdentifier.manufacturer || undefined,
                    ztSerialNumber: string | undefined =
                        item.deviceIdentifier.serialNumber || undefined,
                    ztModel: string | undefined = item.deviceIdentifier.model || undefined

                let enterpriseDevice: EnterpriseDevice | undefined =
                    enterpriseDevicesStore?.items.find(
                        (existingItem: EnterpriseDevice | ZeroTouchDevice) => {
                            if ((<ZeroTouchDevice>existingItem).deviceIdentifier == undefined) {
                                // Is enterprise device
                                const aeImei: string =
                                        (<EnterpriseDevice>existingItem).networkInfo?.imei || "",
                                    aeMeid: string =
                                        (<EnterpriseDevice>existingItem).networkInfo?.meid || "",
                                    aeManufacturer: string =
                                        (<EnterpriseDevice>existingItem).hardwareInfo
                                            ?.manufacturer || "",
                                    aeBrand: string =
                                        (<EnterpriseDevice>existingItem).hardwareInfo?.brand || "",
                                    aeSerialNumber: string =
                                        (<EnterpriseDevice>existingItem).hardwareInfo
                                            ?.serialNumber || "",
                                    aeModel: string =
                                        (<EnterpriseDevice>existingItem).hardwareInfo?.model || "",
                                    hasSameImei: boolean = ztImei ? ztImei === aeImei : false,
                                    hasSameMeid: boolean = ztMeid ? ztMeid === aeMeid : false,
                                    hasSameManufacturer: boolean = ztManufacturer
                                        ? ztManufacturer.toLowerCase() ===
                                              aeManufacturer.toLowerCase() ||
                                          ztManufacturer.toLowerCase() === aeBrand.toLowerCase()
                                        : false,
                                    hasSameSerial: boolean = ztSerialNumber
                                        ? ztSerialNumber === aeSerialNumber
                                        : false,
                                    hasSameModel: boolean = ztModel ? ztModel === aeModel : false

                                return (
                                    (hasSameImei && hasSameManufacturer) ||
                                    (hasSameMeid && hasSameManufacturer) ||
                                    (hasSameSerial && hasSameModel)
                                )
                            }
                        }
                    )

                if (enterpriseDevice != undefined) {
                    // found enterprise device
                    ;(<EnterpriseDevicePlusZeroTouch>enterpriseDevice).zeroTouchDevice = true
                    ;(<EnterpriseDevicePlusZeroTouch>enterpriseDevice).zeroTouchInfo = item
                } else {
                    // found no enterprise device, search for existing zt info
                    if (
                        !useStore().getters.hasObject({
                            accountId: accountId,
                            productType: "mobileSecurity",
                            objectType: "enterpriseDevices",
                            objectId: item.deviceId,
                            objectIdProperty: "deviceId"
                        })
                    ) {
                        // add object
                        enterpriseDevicesStore.items.push(item)
                    } else {
                        // update object
                        let targetItem = useStore().getters.getObject({
                            accountId: accountId,
                            productType: "mobileSecurity",
                            objectType: "enterpriseDevices",
                            objectId: item.deviceId,
                            property: "deviceId"
                        })
                        for (let property in item) {
                            targetItem[property] = item[property as keyof ZeroTouchDevice]
                        }
                        addedAndUpdatedItems.push(targetItem)
                    }
                }
            } else {
                enterpriseDevicesStore?.items.push(item)
                addedAndUpdatedItems.push(item)
            }
        })

        return result
    }

    async getCountFromApi(accountId: string, updateLocalStore: boolean = true) {
        let result: number | Error = 0
        try {
            let countSelectStr =
                apis.getApiVersionForFeature("zeroTouch") == "1.1"
                    ? "&select=count"
                    : "&select=data.count"
            const countResult = await requestHandler.request(
                "GET",
                apis.getApiVersionForFeature("zeroTouch") +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/android/emm/enterprise/devices?fields=devices.name&props[]=name" +
                    countSelectStr
            )
            if (typeof countResult === "number") {
                result = countResult
                if (updateLocalStore) {
                    this.setCount(accountId, countResult)
                }
            } else if (countResult.error) {
                throw new Error(countResult.error)
            } else {
                throw new Error("Error getting count for ZeroTouchDevices")
            }
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
            } else {
                result = new Error("Error getting count for users")
            }
            devLog.log("UsersStoreClass", result.message, result, "error")
            this.errorHandler(accountId, e)
        }
        return result
    }

    async getZeroTouchConfigurations(accountId: string) {
        let result = 0
        try {
            let zeroTouchCustomers: any = config.canUseNewObjectType("zeroTouchCustomers")
                ? products.mobileSecurity.zeroTouchCustomers
                      .useStore?.()
                      .getObjectStoreObjects(accountId)
                : useStore()?.getters.getObjects({
                      accountId: accountId,
                      objectType: "zeroTouchCustomers",
                      productType: "mobileSecurity"
                  })
            let getNextConfigsBatch: Function = async function (
                nextPageToken: string,
                companyId: string
            ) {
                if (companyId) {
                    let currentResult: any = await requestHandler.request(
                        "GET",
                        apis.getApiVersionForFeature("zeroTouch") +
                            "/tenants/" +
                            tenantHelpers.getTenantDomain(accountId) +
                            "/android/androiddeviceprovisioning/customers/" +
                            companyId +
                            "/configurations?pageSize=75" +
                            (nextPageToken ? "&nextPageToken=" + nextPageToken : "")
                    )
                    currentResult = apis.parseApiResponse(currentResult)
                    if (currentResult.configurations.length) {
                        result = result + currentResult.configurations.length
                    }
                    if (currentResult.nextPageToken != null) {
                        await getNextConfigsBatch(currentResult.nextPageToken)
                    }
                }
            }
            for (let i: number = 0; (zeroTouchCustomers?.length || 0) > i; i++) {
                await getNextConfigsBatch("", zeroTouchCustomers[i].companyId)
            }
        } catch (e: any) {
            result = e
            this.errorHandler(accountId, e)
        }
        return result
    }

    async getObjectsFromApi(
        accountId: string,
        props?: GetPropertiesObjectList,
        updateLocalStore: boolean = true,
        newApi?: boolean,
        customerId?: string
    ): Promise<ZeroTouchDevice[] | Error> {
        const tenantDomain = tenantHelpers.getTenantDomain(accountId)
        const propertiesString: string = props ? this.getPropertiesString(props) : ""
        let result: ZeroTouchDevice[] | Error

        try {
            if (cookies.get("fakeDevices") == "1") {
                this.addOrUpdateObjectsInStore(accountId, fakeZTDevices)
                return fakeZTDevices
            } else {
                result = []
                let getNextDevicesBatch: Function = async function (nextPageToken: string) {
                    if (customerId) {
                        let currentResult: any = await requestHandler.request(
                            "GET",
                            "/sms-mgt-api/api/" +
                                apis.getApiVersionForFeature("zeroTouch") +
                                "/tenants/" +
                                tenantDomain +
                                "/android/androiddeviceprovisioning/customers/" +
                                customerId +
                                "/devices" +
                                (propertiesString ? propertiesString + "&" : "?") +
                                "pageSize=75" +
                                (nextPageToken ? "&nextPageToken=" + nextPageToken : "")
                        )
                        currentResult = apis.parseApiResponse(currentResult)
                        if (currentResult.devices.length && Array.isArray(result)) {
                            result = result.concat(currentResult.devices)
                        }
                        if (currentResult.nextPageToken != null) {
                            await getNextDevicesBatch(currentResult.nextPageToken)
                        }
                    }
                }

                if (customerId) {
                    await getNextDevicesBatch("")
                } else {
                    let zeroTouchCustomers: any = config.canUseNewObjectType("zeroTouchCustomers")
                        ? products.mobileSecurity.zeroTouchCustomers
                              .useStore?.()
                              .getObjectStoreObjects(accountId)
                        : useStore()?.getters.getObjects({
                              accountId: accountId,
                              objectType: "zeroTouchCustomers",
                              productType: "mobileSecurity"
                          })
                    for (let i: number = 0; (zeroTouchCustomers?.length || 0) > i; i++) {
                        customerId = zeroTouchCustomers[i].companyId
                        await getNextDevicesBatch("")
                    }
                }

                if (!Array.isArray(result)) {
                    throw "Error getting objects"
                }
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, result) // Add Users to store
                }
                return result
            }
        } catch (e: any) {
            devLog.log("GenericStoreClass", e.message, e, "error")
            this.errorHandler(accountId, e)
            throw e as Error
        }
    }

    async getObjectFromApi(
        accountId: string,
        objectId: ZeroTouchDevice[keyof ZeroTouchDevice],
        props?: GetPropertiesObjectList,
        updateLocalStore: boolean = true,
        customerId?: string
    ) {
        let result: ZeroTouchDevice | Error
        try {
            const propertiesString: string = props ? this.getPropertiesString(props) : ""
            let response = await requestHandler.request(
                "GET",
                "/sms-mgt-api/api/" +
                    apis.getApiVersionForFeature("zeroTouch") +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/android/androiddeviceprovisioning/customers/" +
                    customerId +
                    "/devices/" +
                    encodeURI(objectId as string) +
                    propertiesString
            )
            response = apis.parseApiResponse(response)
            if (response.deviceId) {
                result = response as ZeroTouchDevice
                if (updateLocalStore) {
                    this.addOrUpdateObjectsInStore(accountId, result) // Add ZeroTouchDevice to store
                }
            } else if (response.error) {
                throw new Error(response.error)
            } else {
                throw new Error("Error getting ZeroTouchDevice")
            }
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
            } else {
                result = new Error("Error getting ZeroTouchDevice")
            }
            this.errorHandler(accountId, e)

            devLog.log("ZeroTouchDevicesStoreClass", result.message, result, "error")
        }
        return result
    }

    async updateObjectFromApi(
        accountId: string,
        objectId: string,
        object: ZeroTouchDevice,
        updateLocalStore: boolean = true,
        updateProperties?: boolean,
        properties?: string[]
    ) {
        let result: ZeroTouchDevice | Error | shemaErrors
        let updateMaskString = ""
        let response: any = undefined
        try {
            if (updateLocalStore && Array.isArray(properties)) {
                properties.forEach((property, i) => {
                    if (i == 0) {
                        updateMaskString += "?updateMask[]="
                    } else {
                        updateMaskString += "&updateMask[]="
                    }
                    updateMaskString += property
                })
            } else {
                let i: number = 0
                for (let key in object) {
                    if (i == 0) {
                        updateMaskString += "?updateMask[]="
                    } else {
                        updateMaskString += "&updateMask[]="
                    }
                    updateMaskString += key
                    i++
                }
            }

            response = await requestHandler.request(
                "PUT",
                apis.getApiVersionForFeature("zeroTouch") +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "/android/emm/enterprise/devices/" +
                    encodeURI(objectId) +
                    (updateProperties ? "/properties" + updateMaskString : ""),
                object
            )
            response = apis.parseApiResponse(response)
            if (response.errors) {
                throw response.errors
            } else if (response.error) {
                throw new Error(response.error)
            }
            result = response as ZeroTouchDevice
            if (updateLocalStore) this.addOrUpdateObjectsInStore(accountId, object) // Add User to store
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
                devLog.log("ZeroTouchDevicesStoreClass", result.message, result, "error")
            } else {
                result = e as shemaErrors
                devLog.log(
                    "ZeroTouchDevicesStoreClass",
                    "Error updating ZeroTouchDevice",
                    result,
                    "error"
                )
            }
            this.errorHandler(accountId, e)
        }
        return apis.parseApiResponse(result)
    }

    async deleteObjectFromApi(
        accountId: string,
        objectId: ZeroTouchDevice[keyof ZeroTouchDevice],
        updateLocalStore: boolean = true
    ) {
        let result: true | Error
        try {
            let response = await requestHandler.request(
                "DELETE",
                apis.getApiVersionForFeature("zeroTouch") +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(accountId) +
                    "android/emm/enterprise/devices/" +
                    encodeURI(objectId as string)
            )
            response = apis.parseApiResponse(response)
            if (response.error) {
                throw new Error(response.error)
            }
            result = true
            if (updateLocalStore) {
                this.removeObjectFromStore(accountId, objectId) // Remove ZeroTouchDevice from store
            }
        } catch (e: any) {
            if (e instanceof Error) {
                result = new Error(e.message)
            } else {
                result = new Error("Error deleting ZeroTouchDevice")
            }
            devLog.log("ZeroTouchDevicesStoreClass", result.message, result, "error")
            this.errorHandler(accountId, e)
        }
        return result
    }

    errorHandler(accountId: string, e: any) {
        if (e?.details?.[0]?.latestTosAccepted === false) {
            this.dialogs.renderTosDialog(accountId, e.message)
        }
    }

    addGenericObjectInfos(accountId: string, item: ZeroTouchDevice) {
        if (item?.$itemlist == undefined)
            item.$itemlist = {
                isCheckboxChecked: false,
                isCheckboxHovering: false
            }
        if (item?.zeroTouchDevice == undefined) item.zeroTouchDevice = true
        return item as ZeroTouchDevice
    }
}
