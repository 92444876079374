import miscQueries from "./misc"
import mobileSecurityQueries from "./mobile.security"
import unifiedReportingQueries from "./unified.reporting"
import unifiedSecurityQueries from "./unified.security"
import unifiedSecurityConsoleQueries from "./unified.security.console"

const queries = {
    misc: miscQueries,
    unifiedSecurity: unifiedSecurityQueries,
    mobileSecurity: mobileSecurityQueries,
    unifiedReporting: unifiedReportingQueries,
    unifiedSecurityConsole: unifiedSecurityConsoleQueries
}

export default queries
