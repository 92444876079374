import type { ProductType } from "@/resources/registeredProducts"
import { useStore } from "@/store/vuex.store"
import type { AccountId, ObjectStore } from "../../../types/vuex"

const storeHelpers = {
    getStore: function () {},
    getProductStore: function (accountId: AccountId, productType: ProductType) {
        const accountStores = useStore()?.state.session.accounts
        const accountStore = accountStores?.[accountId as keyof typeof accountStores]
        const productStore = accountStore?.[productType as keyof typeof accountStore]
        if (!productStore)
            throw 'Missing productStore "' + productType + '" for accountId ' + accountId
        return productStore
    },
    getObjectTypeStore: function (
        accountId: AccountId,
        productType: ProductType,
        objectType: string
    ) {
        const accountStores = useStore()?.state.session.accounts
        const accountStore = accountStores?.[accountId as keyof typeof accountStores]
        const productStore = accountStore?.[productType as keyof typeof accountStore]
        if (!productStore)
            throw 'Missing productStore "' + productType + '" for accountId ' + accountId
        const objectTypeStore = productStore?.[objectType as keyof typeof productStore] as
            | ObjectStore<any>
            | undefined
        return objectTypeStore
    }
}
export default storeHelpers
