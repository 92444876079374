<template>
    <div class="heatmap-slider-container">
        <input
            type="range"
            class="heatmap-slider"
            :class="{ 'heatmap-slider--reverse': reverse }"
            v-model="currentValue"
            :min="minValue"
            :max="maxValue"
            :step="step"
        />
        <div class="value-container">
            <span v-if="showPossibleValues" v-for="value in possibleValues" :key="value"
                >{{ value }}{{ unit }}</span
            >
            <template v-else>
                <span>{{ minValue }}{{ unit }}</span>
                <span class="text-bold">{{ currentValue }}%</span>
                <span>{{ maxValue }}{{ unit }}</span>
            </template>
        </div>

        <p v-if="description" class="input-description">
            {{ sprintf(T(description), currentValue) }}
        </p>
    </div>
</template>

<script setup lang="ts">
import { T } from "@/classes/i18n"
import { sprintf } from "sprintf-js"
import { computed, onMounted, ref, watch } from "vue"

const props = withDefaults(
    defineProps<{
        modelValue: number
        minValue: number
        maxValue: number
        step: number
        unit: string
        description: string
        reverse: boolean
        showPossibleValues: boolean
    }>(),
    {
        minValue: 10,
        maxValue: 100,
        step: 5,
        unit: "%",
        reverse: false,
        showPossibleValues: false
    }
)
const emit = defineEmits(["update:modelValue"])
const currentValue = ref<number>()

const possibleValues = computed(() => {
    return Array.from(
        { length: (props.maxValue - props.minValue) / props.step + 1 },
        (_, i) => props.minValue + i * props.step
    )
})

watch(currentValue, (value) => {
    emit("update:modelValue", parseInt(value?.toString() || "0"))
})

onMounted(() => {
    currentValue.value = props.modelValue
})
</script>

<style lang="scss">
$heatmap-colors: (
    0%: #6ece71,
    20%: #4caf50,
    40%: #ffeb3b,
    60%: #ff9800,
    80%: #f44336,
    100%: #e91e63
);

$heatmap-colors-reverse: (
    0%: #e91e63,
    20%: #f44336,
    40%: #ff9800,
    60%: #ffeb3b,
    80%: #4caf50,
    100%: #6ece71
);

$border-color: #666;
$box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

$thumb-light: #fff;
$thumb-dark: #202125;

@function create-gradient($colors) {
    $gradient: "to right";
    @each $stop, $color in $colors {
        $gradient: $gradient + ", #{$color} #{$stop}";
    }
    @return unquote($gradient);
}

.heatmap-slider-container {
    position: relative;
    user-select: none;

    .heatmap-slider {
        width: 100%;
        height: 20px;
        appearance: none;
        background: linear-gradient(create-gradient($heatmap-colors));
        border-radius: 10px;
        outline: none;
        opacity: 0.7;
        transition: opacity 0.2s;
        border: 2px solid $border-color;

        &--reverse {
            background: linear-gradient(create-gradient($heatmap-colors-reverse));
        }

        &:hover {
            opacity: 1;
        }

        &:focus {
            outline: none;
            border: 2px solid $border-color;
        }

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 25px;
            height: 25px;
            background: $thumb-light;
            border-radius: 50%;
            border: 2px solid $border-color;
            cursor: pointer;
            box-shadow: $box-shadow;
        }

        &::-moz-range-thumb {
            width: 25px;
            height: 25px;
            background: $thumb-light;
            border-radius: 50%;
            border: 2px solid $border-color;
            cursor: pointer;
            box-shadow: $box-shadow;
        }

        .darkmode & {
            &::-webkit-slider-thumb {
                background: $thumb-dark;
            }

            &::-moz-range-thumb {
                background: $thumb-dark;
            }
        }
    }

    .value-container {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        font-size: 14px;
    }
}
</style>
