<script setup lang="ts">
import Inputmask from "inputmask"
import { computed, onMounted, ref } from "vue"

const props = defineProps<{
    modelValue: string
    placeholder?: string
    inputMask?: Inputmask.Options | string
    regex?: string | RegExp
}>()

const emit = defineEmits(["update:modelValue"])

const value = computed({
    get: () => props.modelValue,
    set: (thisValue) => {
        emit("update:modelValue", thisValue)
    }
})

const thisInput = ref(<null | HTMLInputElement>null)

const updateValue = (thisValue: string) => {
    if (props.regex) {
        const thisRegex = new RegExp(props.regex)
        if (thisRegex.test(thisValue)) {
            value.value = thisValue.match(thisRegex)?.[0] || ""
            if (thisInput.value) {
                thisInput.value.value = thisValue.match(thisRegex)?.[0] || ""
            }
        } else {
            if (thisInput.value) {
                thisInput.value.value = value.value
            }
        }
    } else {
        value.value = thisValue
    }
}

onMounted(() => {
    const textInput = thisInput.value
    // Inputmasks
    if (props.inputMask && textInput) {
        Inputmask(props.inputMask as Inputmask.Options).mask(textInput)
    }
})
</script>
<template name="input-text">
    <input
        ref="thisInput"
        class="textInput"
        type="text"
        style="width: 100%"
        :value="value"
        v-on:input="
            ($event) => {
                if ($event.target !== null) {
                    updateValue($event.target.value)
                }
            }
        "
        :placeholder="placeholder"
    />
</template>
