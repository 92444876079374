<template>
    <!-- workaround to not show "password" dialog on input select -->
    <input style="display: none" type="password" />

    <input-vue-select
        v-model="licenseType"
        :select-options="licenseTypeSelectOptions"
        :disabled="false"
    />
    <p
        class="input-description margin-xs-b"
        v-html="
            T(
                'You can either upload a licence yourself or select an existing licence. <b>Warning</b>: You can only select an existing licence if you are logged in via a reseller account.'
            )
        "
    ></p>

    <template v-if="licenseType === ELicenseType.RSP">
        <input-vue-select
            v-model="selectedUtmLicense"
            :select-options="utmLicenseSelectOptions"
            :disabled="disabled || isLicenseUserConflict"
        />
    </template>
    <template v-else>
        <input-dropzone
            v-model="utmLicenseDropzone"
            drop-text="Drop UTM license (.pem) here"
            accept=".pem"
            :disabled="disabled"
        />
    </template>

    <div
        v-if="isLicenseUserConflict"
        class="alert notification content bg-red text-center margin-xs-t"
    >
        <i class="fal fa-fw fa-exclamation-triangle"></i>
        {{
            T(
                "The RSP licence was added via a reseller account and cannot be edited by a local user."
            )
        }}
    </div>
</template>

<script setup lang="ts">
import { T } from "@/classes/i18n"
import products from "@/classes/objectTypes"
import { useStore } from "@/store/vuex.store"
import { computed, onMounted, ref, watch } from "vue"
import inputDropzone from "./input-dropzone.vue"
import inputVueSelect from "./input-vue-select.vue"

enum ELicenseType {
    RSP,
    CUSTOM
}

const props = withDefaults(
    defineProps<{
        modelValue: any
        disabled: boolean
    }>(),
    {
        disabled: false
    }
)

const userInfo = computed(() => useStore().state.session.userInfo)
const isResellerLogin = computed(() => !!userInfo.value.oauth2)
const activeAccountId = computed(() => {
    return useStore().getters.getActiveAccountId
})

const emit = defineEmits(["update:modelValue"])

const licenseType = ref<ELicenseType>(ELicenseType.RSP)
const licenseTypeSelectOptions = ref([
    {
        id: ELicenseType.RSP,
        text: T("Select licence from reseller portal"),
        icon: "fal fa-cloud",
        disabled: false,
        tooltip: { content: null } as any
    },
    {
        id: ELicenseType.CUSTOM,
        text: T("Select locally saved licence"),
        icon: "fal fa-file",
        disabled: false,
        tooltip: {}
    }
])

const selectedUtmLicense = ref<string | null>(null)
const utmLicenseSelectOptions = ref()
const utmLicenseDropzone = ref<any>()

const isLicenseUserConflict = computed(() => {
    return licenseType.value === ELicenseType.RSP && !isResellerLogin.value
})

function init() {
    const _licenses = products.unifiedSecurity.licenses
        .useStore?.()
        .getObjectStoreObjects(activeAccountId.value)
    if (_licenses) {
        utmLicenseSelectOptions.value = _licenses
            .filter((license) => license.family === "utm" && license.expired === false)
            .map((license) => ({ id: license.id, text: `${license.name} (${license.id})` }))

        selectedUtmLicense.value = utmLicenseSelectOptions.value[0]?.id
    }

    if (props.modelValue.filename && props.modelValue.data) {
        utmLicenseDropzone.value = {
            filename: props.modelValue.filename,
            data: props.modelValue.data
        }
        licenseType.value = ELicenseType.CUSTOM
    } else if (props.modelValue.licenseId) {
        selectedUtmLicense.value = props.modelValue.licenseId
        licenseType.value = ELicenseType.RSP
    }

    if (!isResellerLogin.value) {
        licenseTypeSelectOptions.value[0].disabled = true
        licenseTypeSelectOptions.value[0].tooltip.content = T(
            "You can only select an existing licence if you are logged in via a reseller account."
        )

        licenseType.value = ELicenseType.CUSTOM
    }

    if (props.modelValue.licenseId && !isResellerLogin.value) {
        licenseType.value = ELicenseType.RSP
        licenseTypeSelectOptions.value[0].disabled = false
    }
}

watch(
    [licenseType, selectedUtmLicense, utmLicenseDropzone],
    () => {
        switch (licenseType.value) {
            case ELicenseType.RSP:
                emit("update:modelValue", {
                    licenseId: selectedUtmLicense.value,
                    data: ""
                })

                break
            case ELicenseType.CUSTOM:
                if (!utmLicenseDropzone.value?.data) {
                    return emit("update:modelValue", "") // make sure to show error message
                }

                emit("update:modelValue", utmLicenseDropzone.value)
                break
        }
    },
    { deep: true }
)

onMounted(() => {
    init()
})
</script>
