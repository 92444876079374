<script setup lang="ts">
import { T } from "@/classes/i18n"
import type { Label } from "@/classes/objectTypes/objectType"
import { computed, onMounted, ref } from "vue"
import LabelComponent from "./label/label.vue"
import Loader from "./loader.vue"
import scrollToTopButton from "./scrollToTopButton.vue"
import Tooltip from "./tooltip.vue"

export interface TabsEntry {
    icon?: string
    text?: string
    disabled?: boolean | (() => boolean)
    visible?: boolean | (() => boolean)
    loading?: boolean
    tooltip?: string | (() => string)
    id: string
    onClick?: Function
    labels?: Label[]
    hasError?: boolean
    showHeaderInContent?: boolean
}
// Props
const props = withDefaults(
    defineProps<{
        tabs: TabsEntry[]
        menuType?: "vertical" | "horizontal"
        activeTab?: string
        setActiveTab?: (tabId: string) => void
    }>(),
    {
        tabs: () => {
            return []
        },
        menuType: "horizontal"
    }
)

const activeTab = ref(<string | undefined>undefined)
const computedActiveTab = computed(() => {
    return props.activeTab || activeTab.value
})

const setActiveTab = (tabname: string) => {
    props.setActiveTab?.(tabname)
    activeTab.value = tabname
}
const container = ref(<HTMLElement | undefined>undefined)
onMounted(() => {
    if (props.tabs?.length) {
        activeTab.value = props.tabs[0].id
    }
})

defineExpose({
    setActiveTab
})
</script>
<template>
    <div class="tabs" :class="{ 'menu-vertical': props.menuType == 'vertical' }">
        <nav>
            <ul class="nav-tabs">
                <template v-for="tab in tabs">
                    <li>
                        <template
                            v-if="
                                tab.tooltip && typeof tab.tooltip == 'function'
                                    ? tab.tooltip()
                                    : tab.tooltip
                            "
                        >
                            <Tooltip
                                :tooltip="
                                    typeof tab.tooltip == 'function' ? tab.tooltip() : tab.tooltip
                                "
                                is-tag="span"
                                v-if="
                                    typeof tab.visible == 'function'
                                        ? tab.visible() !== false
                                        : tab.visible !== false
                                "
                            >
                                <!-- prettier-ignore -->
                                <a
                                    :class="{
                                        active: computedActiveTab == tab.id,
                                        disabled:
                                            (typeof tab.disabled == 'function'
                                                ? tab.disabled()
                                                : tab.disabled === true) || tab.loading === true
                                    }"
                                    v-on:click="
                                        setActiveTab(tab.id);
                                        tab.onClick ? tab.onClick() : null
                                    "
                                >
                                    <template v-if="tab.loading">
                                        <span
                                            style="width: 1.25em; display: inline-block"
                                            class="text-center"
                                        >
                                            <Loader class="color-red" style="top: 0.1em"></Loader>
                                        </span>
                                    </template>
                                    <template v-else-if="tab.icon">
                                        <i :class="tab.icon" class="fa-fw"></i>
                                    </template>
                                    {{ tab.text }}
                                </a>
                            </Tooltip>
                        </template>
                        <template v-else>
                            <!-- prettier-ignore -->
                            <a
                                v-if="
                                    typeof tab.visible == 'function'
                                        ? tab.visible() !== false
                                        : tab.visible !== false
                                "
                                :class="{
                                    active: computedActiveTab == tab.id,
                                    disabled:
                                        (typeof tab.disabled == 'function'
                                            ? tab.disabled()
                                            : tab.disabled === true) || tab.loading === true
                                }"
                                v-on:click="
                                    computedActiveTab = tab.id;
                                    tab.onClick ? tab.onClick() : null
                                "
                            >
                                <template v-if="tab.loading">
                                    <span
                                        style="width: 1.25em; display: inline-block"
                                        class="text-center"
                                    >
                                        <Loader class="color-red" style="top: 0.1em"></Loader>
                                    </span>
                                </template>
                                <template v-else-if="tab.hasError == true">
                                    <i class="fal fa-exclamation-triangle color-red text-bold"></i>
                                </template>
                                <template v-else-if="tab.icon">
                                    <i :class="tab.icon" class="fa-fw"></i>
                                </template>
                                {{ tab.text }}
                            </a>
                        </template>
                    </li>
                </template>
            </ul>
        </nav>
        <div
            class="tab-content"
            :class="{ 'col-xs': props.menuType == 'vertical' }"
            ref="container"
        >
            <template v-for="tab in tabs">
                <div
                    class="tab-pane"
                    :class="{ hidden: computedActiveTab != tab.id }"
                    style="position: relative"
                >
                    <div
                        class="box-content-2"
                        :class="{ 'box-content-4': props.menuType == 'vertical' }"
                    >
                        <template v-if="tab.showHeaderInContent != false">
                            <h3 class="margin-xs-b-3">
                                <i :class="tab.icon" class="fa-fw" v-if="tab.icon"></i>
                                {{ T(tab.text) }}

                                <label-component
                                    class="text-small"
                                    v-for="label in tab.labels"
                                    :label="label"
                                />
                            </h3>
                            <hr class="margin-xs-y" />
                        </template>
                        <slot :name="tab.id"></slot>
                    </div>
                </div>
            </template>
            <scrollToTopButton :container-el="container"></scrollToTopButton>
        </div>
    </div>
</template>
<style lang="scss">
.tabs {
    nav {
        .nav-tabs {
            li {
                a {
                    &.active {
                        i {
                            color: #e52b2b;
                        }
                    }
                    &:hover {
                        color: rgba(0, 0, 0, 0.7);
                    }
                    &.disabled {
                        color: rgba(0, 0, 0, 0.2);
                    }
                }
            }
        }
    }
}

.tabs {
    &.menu-vertical {
        display: flex;
        nav {
            min-height: calc(100vh - 64px - 150px - 64px - 68px - 32px - 48px);
            max-height: calc(100vh - 64px - 150px - 64px - 68px - 32px - 48px);
            border-bottom: 0;
            min-width: fit-content;
            width: fit-content;
            overflow-y: auto;
            overflow-x: hidden;
            .nav-tabs {
                width: fit-content;
                height: 100%;
                li {
                    display: block;
                    a {
                        margin-bottom: 0;
                        border-right: 2px solid rgba(0, 0, 0, 0.08);
                        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                        &.active {
                            border-right: 2px solid #e52b2b;
                            color: rgba(0, 0, 0, 0.7);
                        }
                    }
                }
            }
        }
        .tab-content {
            min-height: calc(100vh - 64px - 150px - 64px - 68px - 32px - 48px);
            max-height: calc(100vh - 64px - 150px - 64px - 68px - 32px - 48px);
            overflow-y: auto;
            overflow-x: hidden;
            position: relative;
        }
    }
}

.darkmode {
    .tabs {
        &.menu-vertical {
            nav {
                .nav-tabs {
                    li {
                        a {
                            &:hover,
                            &.active {
                                color: rgba(255, 255, 255, 0.7);
                            }
                            &.disabled {
                                color: rgba(255, 255, 255, 0.2);
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
