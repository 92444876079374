<template>
    <div key="bg" id="loginBg"></div>
    <div class="login-wrapper" style="max-width: 555px">
        <div class="login-box box-shadow">
            <header id="main-header" class="text-center">
                <div id="logoarea">
                    <a :href="disableAnyNavigation ? undefined : '/'" class="logo-small">
                        <img :src="logoIcon" class="logo" />&nbsp;
                        <span class="app-name">{{ props.appName }}</span>
                    </a>
                </div>
            </header>

            <main class="text-center">
                <div class="content-2">
                    <slot></slot>
                </div>
            </main>

            <mainFooterComponent
                key="footer"
                :showAppName="false"
                ref="footer"
                :disableNavigation="disableAnyNavigation"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import logoIcon from "@/img/securepoint-icon-w.svg"
import mainFooterComponent from "../templates/footer.vue"

const props = withDefaults(
    defineProps<{
        disableAnyNavigation?: boolean
        appName?: string
    }>(),
    {
        disableAnyNavigation: false,
        appName: "Unified Security"
    }
)
</script>
