import { T } from "@/classes/i18n"
import numberHelpers from "@/helpers/helpers.numbers"
import stringHelpers from "@/helpers/helpers.strings"
import tenantHelpers from "@/helpers/helpers.tenants"
import router from "@/router/router"
import Button from "@/templates/components/button/button"
import ObjectType, {
    type ItemlistItemDetail,
    type MenuEntry,
    type ObjectTypeObject,
    type ObjectTypePayload
} from "../../objectType"

interface NotificationService {
    serviceId: string
    servicename: string
    tenantDomain: string
    token: string
    topics: string[]
    type: "pushover" | "email" | "pushbullet"
    recipients: string[]
}

class NotificationServices extends ObjectType<NotificationService> {
    constructor(payload: ObjectTypePayload<NotificationService>) {
        super(payload)
        this.itemlist.getInfoBoxContent = (accoundId, component) => {
            let infoBoxContent: string = ""
            return infoBoxContent
        }
        this.itemlist.getSortingOptions = (accoundId, component) => {
            let result: { id: string; text: string }[] = [
                {
                    id: "servicename",
                    text: "Name"
                },
                {
                    id: "type",
                    text: T("Type")
                }
            ]
            return result
        }
        this.itemlist.getToolbarButtons = (accountId, component) => {
            let toolbarButtons: {
                icon: string
                title: string
                link?: string
                onClick?: Function
                id: string
                disabled?: boolean
                vIf?: boolean | string
                class?: string
            }[] = [
                {
                    icon: "fal fa-plus",
                    title: T("Add notification"),
                    id: "notificationsButtonAdd",
                    link:
                        "#add-tenant-" + tenantHelpers.getTenantDomain(accountId) + "-notification"
                }
            ]
            return toolbarButtons
        }

        this.itemlistItem.getTitle = (item, component) => {
            return {
                title: item.servicename
            }
        }
        this.itemlistItem.getDetails = (accountId, item) => {
            let result: ItemlistItemDetail[] = []
            let thisTopics: string = ""

            for (let i in item?.topics) {
                if (parseInt(i) >= 1 && parseInt(i) + 1 <= item?.topics.length) {
                    thisTopics += ", "
                }
                let topic: string = item?.topics[i]

                if (topic == "/device/enrolled") {
                    thisTopics += T("Enrolled device")
                } else if (topic == "/device/unenrolled") {
                    thisTopics += T("Unenrolled device")
                } else if (topic == "/tenant/notification") {
                    thisTopics += T("Tenant notification")
                }
            }

            result = [
                {
                    iconClass: "fal fa-fw fa-envelope",
                    title: T("Type"),
                    key: T("Type"),
                    value: item
                        ? stringHelpers.capitalizeFirstLetter(item?.type).replace("Email", "E-Mail")
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                },
                {
                    iconClass: "fal fa-fw fa-calendar",
                    title: T("Topics"),
                    key: T("Topics"),
                    value: item
                        ? thisTopics
                        : "<span class='content-placeholder' style='width:" +
                          numberHelpers.getRandomArbitrary(50, 250) +
                          "px;'></span>"
                }
            ]
            return result
        }
        this.itemlistItem.onClick = (
            accountId: string,
            item: ObjectTypeObject<NotificationService>
        ) => {
            if (item?.serviceId) {
                router.navigate(
                    "#edit-tenant-" +
                        tenantHelpers.getTenantDomain(accountId) +
                        "-notification-" +
                        item?.serviceId
                )
            }
        }
        this.itemlistItem.getMenuEntries = (
            accountId: string,
            item: ObjectTypeObject<NotificationService>
        ): MenuEntry[] => {
            let result: MenuEntry[] = []

            result.push(
                new Button({
                    title: T("Edit"),
                    text: T("Edit"),
                    link:
                        "#edit-tenant-" +
                        tenantHelpers.getTenantDomain(accountId) +
                        "-notification-" +
                        item?.serviceId,
                    icon: "fal fa-edit"
                }),
                new Button({
                    title: T("Delete"),
                    text: T("Delete"),
                    onClick: () => {
                        this.dialogs.getDeleteObjectDialog(accountId, item)
                    },
                    icon: "fal fa-trash"
                })
            )

            return result
        }
        this.itemlistItem.getIconBackgroundImage = (
            accountId: string,
            item: ObjectTypeObject<NotificationService>
        ) => {
            return "fal fa-comment-alt"
        }
        this.itemlistItem.isChecked = (item: ObjectTypeObject<NotificationService>): boolean => {
            return item?.$itemlist?.isCheckboxChecked || false
        }
    }
}

const notificationServices = new NotificationServices({
    objectType: "notifications",
    productType: "unifiedSecurity",
    slug: "notifications",
    apiInfo: {
        url: "/sms-mgt-api/api/2.0/",
        getCountGETProperties: "?props[]=null&select=data.count",
        // Get
        getObjectListResponseProperty: "services",
        getObjectMethod: "GET",
        getObjectListPath: "tenants/{tenantDomain}/notifications/services",
        // Add
        addObjectMethod: "POST",
        addObjectPath: "tenants/{tenantDomain}/notifications/services",
        // Update
        updateObjectMethod: "PUT",
        updateObjectPath: "/tenants/{tenantDomain}/notifications/services/{objectId}",
        // Delete
        deleteObjectMethod: "DELETE",
        deleteObjectPath: "tenants/{tenantDomain}/notifications/services/{objectId}"
    },
    appearance: {
        iconClass: "fal fa-comment-alt",
        color: "red",
        showInSidebar: true,
        showOnDashboard: false,
        text: {
            title: "Notifications",
            singular: "Notification",
            plural: "Notifications",
            sidebarName: "Notifications"
        }
    },
    objectTypeInfo: {
        nameProperty: {
            primary: "servicename",
            pathToPrimaryProperty: undefined,
            secondary: "serviceId",
            pathToSecondaryProperty: undefined
        },
        primaryKeyProperty: {
            property: "serviceId",
            pathToPrimaryProperty: undefined
        }
    },
    hasStore: true
})
export default notificationServices
