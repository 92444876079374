export type ProductType =
    | "mobileSecurity"
    | "unifiedSecurity"
    | "unifiedReporting"
    | "unifiedSecurityConsole"
    | "adaptiveSecureConnect"
    | "secureDns"
export interface ObjectTypeInfo {
    text: {
        singular: string
        plural: string
        dashboard?: string
        details?: string
        add?: string
        edit?: string
    }
    link?: {
        href?: string
        target?: string
        onClick?: string
    }
    appearance: {
        color: string
        icon: string
        showOnDashboard?: boolean
        countFrom?: string
        countProperty?: string
        showConditions?: any[]
    }
    idProperty?: string
    nameProperty?: {
        primary: string
        secondary?: string
    }
    options?: {
        canCopy?: boolean
        objectType?: string
    }
    filters?: any[]
    featureFlag?: string
}
export type ObjectTypesInfo = {
    [productType: string]: ObjectTypeInfo
}
export type RegisteredProducts = {
    [productType in ProductType]?: {
        text: {
            singular?: string
            icon?: string
        }
        licenses?: string[]
        featureFlag?: string
        objectTypes: ObjectTypesInfo
    }
}
const registeredProducts: RegisteredProducts = {
    mobileSecurity: {
        text: {
            singular: "Mobile Security",
            icon: "fa-mobile"
        },
        licenses: ["Mobile Security", "MDM"],
        objectTypes: {
            msDashboard: {
                text: {
                    singular: "Reseller-Dashboard",
                    plural: "Reseller-Dashboard",
                    dashboard: "Reseller-Dashboard"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-mobile-security-dashboard",
                    target: "_self"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "blue",
                    icon: "fal fa-tachometer-alt",
                    showConditions: [
                        {
                            root: "isReseller"
                        }
                    ]
                }
            },
            myMsDashboard: {
                text: {
                    singular: "Dashboard",
                    plural: "Dashboard",
                    dashboard: "xDashboard"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-my-mobile-security-dashboard",
                    target: "_self"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "blue",
                    icon: "fal fa-tachometer-alt"
                }
            },
            devices: {
                text: {
                    singular: "Device",
                    plural: "Devices"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-mobile-alt"
                },
                idProperty: "deviceId",
                nameProperty: {
                    primary: "alias",
                    secondary: "deviceId"
                },
                options: {
                    canCopy: true
                }
            },
            iosDevices: {
                text: {
                    singular: "Device",
                    plural: "Devices",
                    dashboard: "iOS-Devices"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-ios-devices-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "darkblue",
                    icon: "fal fa-mobile-alt",
                    countFrom: "devices",
                    countProperty: "count"
                },
                idProperty: "deviceId",
                nameProperty: {
                    primary: "alias",
                    secondary: "deviceId"
                },
                options: {
                    canCopy: true,
                    objectType: "devices"
                },
                filters: [
                    {
                        id: "supervised",
                        text: "Supervised",
                        propertyPath: "info.IsSupervised",
                        searchType: "select",
                        defaultValue: true,
                        options: [
                            {
                                id: true,
                                text: "Yes"
                            },
                            {
                                id: false,
                                text: "No"
                            }
                        ]
                    },
                    {
                        id: "signedIn",
                        text: "Signed in",
                        propertyPath: "signedIn",
                        searchType: "select",
                        defaultValue: true,
                        options: [
                            {
                                id: true,
                                text: "Yes"
                            },
                            {
                                id: false,
                                text: "No"
                            }
                        ]
                    },
                    {
                        id: "configured",
                        text: "Configured",
                        propertyPath: "configured",
                        searchType: "select",
                        defaultValue: true,
                        options: [
                            {
                                id: true,
                                text: "Yes"
                            },
                            {
                                id: false,
                                text: "No"
                            }
                        ]
                    },
                    {
                        id: "newDevices",
                        text: "New devices",
                        searchType: null,
                        conditions: [
                            {
                                propertyPath: "enrolledAt",
                                operator: "not_older_than",
                                value: 223200
                            }
                        ]
                    },
                    {
                        id: "lostMode",
                        text: "Lost",
                        propertyPath: "lostMode",
                        searchType: "select",
                        defaultValue: true,
                        options: [
                            {
                                id: true,
                                text: "Yes"
                            },
                            {
                                id: false,
                                text: "No"
                            }
                        ]
                    }
                ]
            },
            profiles: {
                text: {
                    singular: "Profile",
                    plural: "Profiles"
                },
                appearance: {
                    color: "darkblue",
                    icon: "fal fa-shield-alt"
                },
                idProperty: "profileId",
                nameProperty: {
                    primary: "profilename"
                },
                options: {
                    canCopy: true,
                    objectType: "profiles"
                }
            },
            iosProfiles: {
                text: {
                    singular: "Profile",
                    plural: "Profiles",
                    dashboard: "iOS-Profiles"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-ios-profiles-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "darkblue",
                    icon: "fal fa-shield-alt",
                    countFrom: "profiles",
                    countProperty: "iosCount"
                },
                idProperty: "profileId",
                nameProperty: {
                    primary: "profilename"
                },
                options: {
                    canCopy: true
                }
            },
            apps: {
                text: {
                    singular: "App",
                    plural: "Apps",
                    dashboard: "iOS-Apps"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-ios-apps-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "darkblue",
                    icon: "fal fa-rocket",
                    countProperty: "count"
                },
                idProperty: "appId",
                options: {
                    canCopy: true
                },
                nameProperty: {
                    primary: "name"
                },
                filters: [
                    {
                        id: "type",
                        text: "Type",
                        propertyPath: "platform",
                        searchType: "select",
                        defaultValue: "IOS",
                        options: [
                            {
                                id: "IOS",
                                text: "App"
                            },
                            {
                                id: "WEBCLIP",
                                text: "Webclip"
                            }
                        ]
                    }
                ]
            },
            homescreenlayouts: {
                text: {
                    singular: "Home Screen Layout",
                    plural: "Home Screen Layouts",
                    dashboard: "Home Screen Layouts"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-homescreenlayouts-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "darkblue",
                    icon: "fal fa-mobile",
                    countProperty: "count",
                    showConditions: [
                        {
                            root: "isDev"
                        }
                    ]
                },
                idProperty: "layoutId",
                options: {
                    canCopy: true
                },
                nameProperty: {
                    primary: "Name"
                },
                filters: []
            },
            vppassets: {
                text: {
                    singular: "VPP License",
                    plural: "VPP Licenses",
                    details: "VPP Licenses",
                    dashboard: "VPP Licenses"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-vpp-assets-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: true,
                    showConditions: [
                        {
                            type: "hasFunctionality",
                            functionality: "vpp"
                        }
                    ],
                    color: "darkblue",
                    icon: "fal fa-rocket"
                },
                options: {
                    canCopy: false
                },
                idProperty: "adamIdStr",
                nameProperty: {
                    primary: "assetInfo.trackName"
                }
            },
            depProfiles: {
                text: {
                    singular: "DEP Profile",
                    plural: "DEP Profiles",
                    dashboard: "DEP Profiles"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-dep-profiles-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    color: "darkblue",
                    icon: "fal fa-shield-alt",
                    countProperty: "count",
                    showOnDashboard: true,
                    showConditions: [
                        {
                            type: "hasFunctionality",
                            functionality: "dep"
                        }
                    ]
                },
                idProperty: "profile_uuid",
                nameProperty: {
                    primary: "profile_name"
                },
                options: {
                    canCopy: false
                }
            },
            androidDevices: {
                text: {
                    singular: "Device",
                    plural: "Devices",
                    dashboard: "Android-Devices"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-android-devices",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "green",
                    icon: "fal fa-mobile-alt",
                    countProperty: "count"
                },
                idProperty: "id",
                nameProperty: {
                    primary: "alias",
                    secondary: "id"
                },
                options: {
                    canCopy: false,
                    objectType: "enterpriseDevices"
                }
            },
            androidProfiles: {
                text: {
                    singular: "Profile",
                    plural: "Profiles",
                    dashboard: "Android-Profiles"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "green",
                    icon: "fal fa-shield-alt",
                    countFrom: "profiles",
                    countProperty: "androidCount"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-android-profiles-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                idProperty: "profileId",
                nameProperty: {
                    primary: "profilename"
                },
                options: {
                    canCopy: true,
                    objectType: "profiles"
                }
            },
            zeroTouchDevices: {
                text: {
                    singular: "Zero-Touch devices",
                    plural: "Zero-Touch devices"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-users"
                },
                idProperty: "deviceId",
                nameProperty: {
                    primary: "companyName"
                },
                options: {
                    canCopy: false
                }
            },
            zeroTouchCustomers: {
                text: {
                    singular: "Zero-Touch customer",
                    plural: "Zero-Touch customers"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-users"
                },
                idProperty: "customerId",
                nameProperty: {
                    primary: "companyName"
                },
                options: {
                    canCopy: false
                }
            },
            zeroTouchConfigurations: {
                text: {
                    singular: "Android Zero-Touch",
                    plural: "Android Zero-Touch",
                    add: "Add Zero-Touch configuration",
                    edit: "Edit Zero-Touch configuration",
                    dashboard: "Android Zero Touch"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-zerotouchconfigurations-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: true,
                    showConditions: [
                        {
                            type: "hasFunctionality",
                            functionality: "zeroTouch"
                        }
                    ],
                    color: "green",
                    icon: "fab fa-android",
                    countFrom: "zeroTouchConfigurations",
                    countProperty: "count"
                },
                idProperty: "configurationId",
                nameProperty: {
                    primary: "configurationName"
                },
                options: {
                    canCopy: false
                }
            },

            vpns: {
                text: {
                    singular: "Other device (VPN)",
                    plural: "Other devices (VPN)",
                    dashboard: "Other devices (VPN)"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-vpns-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "red",
                    icon: "fal fa-laptop",
                    countProperty: "count"
                },
                options: {
                    canCopy: true
                },
                idProperty: "vpnId",
                nameProperty: {
                    primary: "vpnname"
                }
            },
            certificates: {
                text: {
                    singular: "Certificate",
                    plural: "Certificates",
                    dashboard: "Certificates"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-certificates-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "red",
                    icon: "fal fa-certificate",
                    countProperty: "count"
                },
                options: {
                    canCopy: false
                },
                idProperty: "certificateId",
                nameProperty: {
                    primary: "name"
                }
            },
            tags: {
                text: {
                    singular: "Tag",
                    plural: "Tags"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-tag"
                },
                options: {
                    canCopy: false
                },
                idProperty: "id",
                nameProperty: {
                    primary: "text"
                }
            }
        }
    },
    unifiedReporting: {
        text: {
            singular: "Unified Reporting",
            icon: "fa-chart-pie"
        },
        licenses: ["laas"],
        objectTypes: {
            utms: {
                text: {
                    singular: "UTM",
                    plural: "UTMs",
                    dashboard: "UTMs"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-usrutms-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "red",
                    icon: "fal fa-server",
                    countProperty: "count"
                },
                options: {
                    canCopy: false
                },
                idProperty: "utmId",
                nameProperty: {
                    primary: "utmname"
                }
            },
            reports: {
                text: {
                    singular: "Report",
                    plural: "Reports",
                    dashboard: "Reports"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-reports-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "red",
                    icon: "fal fa-file-alt",
                    countProperty: "count"
                },
                options: {
                    canCopy: false
                },
                idProperty: "reportId",
                nameProperty: {
                    primary: "reportname"
                }
            }
        }
    },
    unifiedSecurityConsole: {
        text: {
            singular: "Unified Security Console",
            icon: "fa-server"
        },
        licenses: ["unifiedSecurityConsole"],
        objectTypes: {
            uscDashboard: {
                text: {
                    singular: "Reseller-Dashboard",
                    plural: "Reseller-Dashboard",
                    dashboard: "Reseller-Dashboard"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-usc-dashboard",
                    target: "_self"
                },
                appearance: {
                    color: "blue",
                    icon: "fal fa-tachometer-alt",
                    showOnDashboard: true,
                    showConditions: [
                        {
                            root: "isReseller"
                        }
                    ]
                }
            },
            myUscDashboard: {
                text: {
                    singular: "Dashboard",
                    plural: "Dashboard",
                    dashboard: "xDashboard"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-my-usc-dashboard",
                    target: "_self"
                },
                appearance: {
                    color: "blue",
                    icon: "fal fa-tachometer-alt",
                    showOnDashboard: true
                }
            },
            uscCommandCenter: {
                text: {
                    singular: "UTM Commandcenter",
                    plural: "UTM Commandcenter",
                    dashboard: "UTM Commandcenter"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-usc-utms-commandcenter",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: false,
                    color: "red",
                    icon: "fal fa-boxes",
                    countProperty: "count"
                },
                options: {
                    canCopy: false
                }
            },
            uscVpnConfig: {
                text: {
                    singular: "VPN-Configuration",
                    plural: "VPN-Configuration",
                    dashboard: "VPN-Configuration"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-usc-vpn-configuration",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "red",
                    icon: "fal fa-puzzle",
                    countProperty: "count"
                },
                options: {
                    canCopy: false
                },
                idProperty: "utmId",
                nameProperty: {
                    primary: "utmname"
                }
            },
            uscUtms: {
                text: {
                    singular: "UTM",
                    plural: "UTMs",
                    dashboard: "UTMs"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-usc-utms-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "red",
                    icon: "fal fa-server",
                    countProperty: "count"
                },
                options: {
                    canCopy: false
                },
                idProperty: "utmId",
                nameProperty: {
                    primary: "utmname"
                }
            },
            uscProfiles: {
                text: {
                    singular: "Profile",
                    plural: "Profiles",
                    dashboard: "UTM Profiles"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-usc-profiles-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "red",
                    icon: "fal fa-shield",
                    countProperty: "count"
                },
                options: {
                    canCopy: false
                },
                idProperty: "profileId",
                nameProperty: {
                    primary: "profilename"
                }
            },
            uscZtProfiles: {
                text: {
                    singular: "UTM Zero-Touch",
                    plural: "UTM Zero-Touch",
                    dashboard: "UTM Zero-Touch"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-uscZtProfiles",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "darkblue",
                    icon: "fal fa-box-check",
                    countProperty: "count"
                },
                featureFlag: "UTM_ZERO_TOUCH"
            },
            windowsVpns: {
                text: {
                    singular: "Windows VPN-Client",
                    plural: "Windows VPN-Clients",
                    dashboard: "Windows VPN-Clients"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-windows-vpns-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "green",
                    icon: "fal fa-laptop",
                    countProperty: "count"
                },
                featureFlag: "WINDOWS_DEVICES"
            },
            windowsProfiles: {
                text: {
                    singular: "Windows Profile",
                    plural: "Windows Profiles",
                    dashboard: "Windows Profiles"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-windows-profiles-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "green",
                    icon: "fal fa-shield-alt",
                    countProperty: "count"
                },
                featureFlag: "WINDOWS_DEVICE_PROFILES"
            },
            windowsEnrollment: {
                text: {
                    singular: "Windows Enrollment",
                    plural: "Windows Enrollment",
                    dashboard: "Windows Enrollment"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-windows-enrollmenttokens-dashboard",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "green",
                    icon: "fal fa-sign-in",
                    countProperty: "count"
                },
                featureFlag: "WINDOWS_ENROLLMENTTOKENS"
            },
            ccutmStates: {
                text: {
                    singular: "UTM",
                    plural: "UTMs",
                    dashboard: "UTMs"
                },
                link: {
                    href: "",
                    target: "_self",
                    onClick: "vueApp.setItemlistSearchString('')"
                },
                appearance: {
                    showOnDashboard: false,
                    color: "red",
                    icon: "fal fa-server"
                },
                options: {
                    canCopy: false
                },
                idProperty: "deviceId"
            }
        }
    },
    unifiedSecurity: {
        text: {
            singular: "Unified Security"
        },
        objectTypes: {
            dashboard: {
                text: {
                    singular: "Dashboard",
                    plural: "Dashboard"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-dashboard",
                    target: "_self"
                },
                appearance: {
                    color: "green",
                    icon: "fal fa-tachometer-alt"
                }
            },
            users: {
                text: {
                    singular: "User",
                    plural: "Users"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-user"
                },
                options: {
                    canCopy: false
                },
                idProperty: "username",
                nameProperty: {
                    primary: "username"
                }
            },
            roles: {
                text: {
                    singular: "Role",
                    plural: "Roles"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-users"
                },
                options: {
                    canCopy: true
                },
                idProperty: "rolename",
                nameProperty: {
                    primary: "rolename"
                }
            },
            notifications: {
                text: {
                    singular: "Notification",
                    plural: "Notifications"
                },
                options: {
                    canCopy: true
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-comment-alt"
                },
                idProperty: "serviceId",
                nameProperty: {
                    primary: "servicename"
                },
                filters: [
                    {
                        id: "type",
                        text: "Type",
                        propertyPath: "type",
                        searchType: "select",
                        defaultValue: true,
                        options: [
                            {
                                id: "pushover",
                                text: "Pushover"
                            },
                            {
                                id: "pushbullet",
                                text: "Pushbullet"
                            },
                            {
                                id: "email",
                                text: "E-Mail"
                            }
                        ]
                    }
                ]
            },

            apiKeys: {
                text: {
                    singular: "API Key",
                    plural: "API Keys"
                },
                options: {
                    canCopy: false
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-key"
                },
                idProperty: "id",
                nameProperty: {
                    primary: "name"
                },
                filters: []
            },

            warnings: {
                text: {
                    singular: "Warning",
                    plural: "Warnings"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-bell"
                },
                idProperty: "warningId"
            },
            licenses: {
                text: {
                    singular: "License",
                    plural: "Licenses"
                },
                appearance: {
                    color: "green",
                    icon: "fal fa-comment-alt"
                },
                options: {
                    canCopy: false
                },
                idProperty: "id",
                nameProperty: {
                    primary: "name"
                }
            },
            account: {
                text: {
                    singular: "Account",
                    plural: "Accounts"
                },
                appearance: {
                    color: "green",
                    icon: "fal fa-key"
                },
                options: {
                    canCopy: false
                },
                idProperty: "accountid",
                nameProperty: {
                    primary: "accountname"
                }
            },
            pluginLicenses: {
                text: {
                    singular: "License",
                    plural: "Licenses"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-key"
                },
                options: {
                    canCopy: false
                }
            },
            images: {
                text: {
                    singular: "Image",
                    plural: "Images"
                },
                appearance: {
                    color: "red",
                    icon: "fal fa-image"
                },
                options: {
                    canCopy: false
                },
                idProperty: "imageId"
            }
        }
    },
    secureDns: {
        text: {
            singular: "Secure DNS",
            icon: "fal fa-globe"
        },
        licenses: ["Mobile Security"], // TODO: SECURE DNS license
        featureFlag: "SECURE_DNS",
        objectTypes: {
            profiles: {
                text: {
                    singular: "Profiles",
                    plural: "Profiles",
                    dashboard: "Profiles"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-secureDnsProfiles",
                    target: "_self"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "red",
                    icon: "fal fa-shield-alt"
                }
            },
            statistics: {
                text: {
                    singular: "Statistic",
                    plural: "Statistics",
                    dashboard: "Statistics"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-secureDns-stats",
                    target: "_self"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "blue",
                    icon: "fal fa-chart-area"
                }
            },
            logs: {
                text: {
                    singular: "Log",
                    plural: "Logs",
                    dashboard: "Logs"
                },
                link: {
                    href: "#show-tenant-{tenantDomain}-secureDns-logs",
                    target: "_self"
                },
                appearance: {
                    showOnDashboard: true,
                    color: "blue",
                    icon: "fal fa-history"
                }
            }
        }
    }
}

export default registeredProducts
