import { T } from "@/classes/i18n"
import tenantHelpers from "@/helpers/helpers.tenants"
import requestHandler from "@/queries/requests"

export interface PasswordResetState {
    tenantDomain: string
    username: string
    hash: string
    state: string
    totpSecret: string
    qrCode: string
}

export interface PostPasswordReset extends PasswordResetState {
    password: string
    password_confirm: string
}

export class PasswordReset {
    private accountId: string
    private hash: string
    private tenantDomain: string

    constructor(accountId: string, hash: string) {
        this.accountId = accountId
        this.hash = hash
        this.tenantDomain = tenantHelpers.getTenantDomain(accountId)
    }

    public async getState(): Promise<PasswordResetState | false> {
        let result: PasswordResetState | false = false
        try {
            result = await requestHandler.request(
                "GET",
                `/sms-mgt-api/api/2.0/tenants/${this.tenantDomain}/users/temporary/${this.hash}`
            )
        } catch (e: any) {
            if (e.status === 401) {
                // app.router.push({'name':'401'});
            } else {
                console.error(e)
            }
            result = false
        }
        return result
    }

    public async updatePassword(payload: {
        state: string
        password: string
        password_confirm: string
    }): Promise<{ success: boolean; error?: string; data?: PostPasswordReset }> {
        try {
            const result = await requestHandler.request(
                "POST",
                `/sms-mgt-api/api/2.0/tenants/${this.tenantDomain}/users/temporary/${this.hash}`,
                payload
            )
            return { success: true, data: result } // Request succeeded, return data
        } catch (e: any) {
            let errorMessage = T("An error occurred")

            if (e.data) {
                switch (e.data.code) {
                    case 400:
                        errorMessage = T("Invalid request")
                        break
                    case 404:
                        errorMessage = T("The user was not found!")
                        break
                    case 410:
                        errorMessage = T("The link has expired!")
                        break
                    case 401:
                        errorMessage = T("Unauthorized access!")
                        break
                    default:
                        errorMessage = T("An unexpected error occurred")
                        break
                }
            }

            return { success: false, error: errorMessage } // Return error structure
        }
    }

    // Validate token with a confirmation code
    public async validateToken(payload: {
        code: string
    }): Promise<{ success: boolean; error?: string }> {
        try {
            await requestHandler.request(
                "POST",
                `/sms-mgt-api/api/2.0/tenants/${this.tenantDomain}/users/temporary/${this.hash}/confirm`,
                payload
            )
            return { success: true }
        } catch (e: any) {
            let errorMessage = T("An error occurred")
            if (e.data) {
                switch (e.data.code) {
                    case 400:
                        errorMessage = T("The code entered is invalid!")
                        break
                    case 404:
                        errorMessage = T("The user was not found!")
                        break
                    case 410:
                        errorMessage = T("The link has expired!")
                        break
                    case 401:
                        errorMessage = T("Unauthorized access!")
                        break
                    default:
                        errorMessage = T("An unexpected error occurred")
                        break
                }
            }
            return { success: false, error: errorMessage }
        }
    }
}

export default PasswordReset
