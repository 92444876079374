import { T } from "@/classes/i18n"
import { useGlobalMixin } from "@/mixins/mixins.global"
import queries from "@/queries/queries"
import { useStore } from "@/store/vuex.store"
import apis from "../../classes/apis"
import loaderComponent from "../components/loader.vue"
//@ts-ignore
import config from "@/classes/config"
import Dropzone from "dropzone"

const appleVPPDialogComponent = {
    mixins: [useGlobalMixin()],
    name: "apple-vpp-dialog",
    template: `
        <div>
            <div class="padding-xs">
                <h5 class="margin-xs-y-2">
                    {{
                        properties.type == 'expired' 
                            ? (T('Follow these steps to reconnect to the Apple Volume Purchase Program')) 
                            : (T('Follow these steps to connect to the Apple Volume Purchase Program'))
                    }}:
                </h5>
                <ol style="line-height:2.25em">
                    <li class="margin-xs-y">
                        {{ T("Please visit the Apple Volume Purchase Program in the") }} <a target="_blank" rel="noreferrer noopener" href="https://business.apple.com">{{ T('Apple Business Manager') }}</a> {{ T('or') }} <a target="_blank" rel="noreferrer noopener" href="https://school.apple.com">{{T('Apple School Manager')}}</a>, {{ T('in order to generate your Token-file.') }} 
                        {{ T('You can find more detailed instructions on how to do this here:')}} <a target="_blank" rel="noreferrer noopener" href="https://wiki.securepoint.de/MS/deployment/Apple-vpp-connect">Wiki</a>
                    </li>
                    <hr>
                    <li class="margin-xs-y">

                        {{ T("Upload your Token-file") }}:&nbsp;&nbsp;&nbsp;

                        <label style="display:inline-block; width:160px; min-width:0px; max-width:100%;" class="file form-inputgroup">
                            <a v-if="!tokenLoading && !tokenUploaded" id="tokenChooser" class="btn btn-primary">
                                <i class="fal fa-fw fa-upload"></i>&nbsp;&nbsp;{{ T('Upload Token') }}
                            </a>
                            <loader v-if="tokenLoading" class="color-primary text-size-2"/>
                            <i v-if="!tokenLoading && tokenUploaded" class="fal fa-fw fa-check color-green"></i>
                        </label>

                        <span class="bg-red label error padding-xs display-block" style="max-width: 400px;" v-if="tokenError">
                            <i class="fal fa-exclamation-triangle"></i>&nbsp;&nbsp;{{ tokenError }}
                        </span>

                        <div id="theDropZone" class="dropzoneArea" style="margin: 10px 0; padding: 24px;" v-if="!tokenUploaded">
                            <div class="uploadcontent dz-default dz-message" v-if="!tokenError">
                                <template v-if="!tokenLoading">
                                    <i class="fal fa-upload"></i> {{ T('Drop File (*.vpptoken) here') }}
                                </template>
                                <loader v-if="tokenLoading" class="color-primary text-size-2"/>
                            </div>
                        </div>

                    </li>
                </ol>
            </div>
        </div>
    `,
    data: () => {
        return {
            initialized: true,
            certLoading: false,
            certDownloaded: false,

            tokenLoading: false,
            tokenUploaded: false,
            tokenError: undefined
        }
    },
    computed: {},
    props: {
        properties: {
            required: true,
            default: () => {
                return {
                    accountid: undefined,
                    type: "add"
                }
            }
        }
    },
    methods: {
        submit: function (this: any) {
            if (this.emailValid && this.certUploaded) {
                useStore().getters.getActiveModal(this.activeAccountId).buttons[1].onClick()
            }
        },
        downloadCert: async function (this: any) {
            this.certLoading = true
            try {
                await queries.mobileSecurity.downloadApplePushCert(this.properties.accountid)
                this.certDownloaded = true
            } catch (e: any) {
                console.error(e)
            }
            this.certLoading = false
        }
    },
    created: function (this: any) {},
    mounted: function (this: any) {
        let thisComponent: any = this

        Dropzone.options.theDropZone = {
            clickable: "#tokenChooser",
            uploadMultiple: false,
            paramName: "file",
            accept: function (file: any, done: Function) {
                thisComponent.tokenError = undefined
                if (!/vpptoken$/.test(file.name)) {
                    done(T("Please provide the correct filetype: *.vpptoken"))
                } else {
                    done()
                }
            },
            previewTemplate: `<div class="dz-preview dz-file-preview" style="width:100%">
                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                <div class="dz-success-mark"><span><i class="fal fa-check color-lightgreen"></i> ${T("FILE SUCCESSFULLY UPLOADED")}</span></div>
                <div class="dz-error-mark"><span><i class="fal fa-times color-red"></i> ${T("ERROR UPLOADING FILE")}</span></div>
                <br>
                <div class="dz-details">
                    <div class="dz-filename">
                        <strong>${T("File")}:</strong> <span data-dz-name></span>
                    </div>
                </div>
                <div class="dz-error-message">
                    <strong>${T("Error")}:</strong> <span data-dz-errormessage></span>
                </div>
                <br>
                <div class="dz-close empty"></div>
            </div>`
        }
        let url = ""
        if (config.canUseNewObjectType("vppUsers")) {
            url =
                "/sms-mgt-api/api/2.0/tenants/" +
                thisComponent.properties.accountid +
                ".sms/apple/vpp/v2"
        } else {
            if (apis.getApiVersionForFeature("appleVPP") == "1.1") {
                url =
                    apis.getTenantApiUrl(thisComponent.properties.accountid, "appleVPP") +
                    "/vpp/token"
            } else {
                url = apis.getTenantApiUrl(thisComponent.properties.accountid, "appleVPP") + "/vpp"
            }
        }
        var myDropzone = new Dropzone("#theDropZone", { url: url })

        myDropzone.on("error", function (file: any, error: any) {
            if (error.message) {
                error = error.message
            }
            $("span[data-dz-errormessage]").text(error)
        })
        myDropzone.on("complete", function (event: any) {
            if (event.status == "success") {
                thisComponent.tokenLoading = false
                thisComponent.tokenUploaded = true
            } else {
                thisComponent.tokenLoading = false
                thisComponent.tokenUploaded = false
                thisComponent.tokenError = T("Something went wrong. Please try again.")
            }
        })
        this.initialized = true
    },
    watch: {},
    components: {
        loader: loaderComponent
    }
}
export default appleVPPDialogComponent
