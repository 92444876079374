import cookies from "./cookieHandler"

class DevLog {
    private devMode: boolean = false
    private loglevel: number = 0
    constructor() {
        let devCookie = cookies.get("iddqd")
        let loglevel = cookies.get("loglevel")
        this.devMode = devCookie ? true : false
        this.loglevel = loglevel ? Number(loglevel) : 0
        this.initLog()
    }
    initLog() {
        let devMode
        if (this.devMode) {
            this.log("devLog", "Initialized devLog", this, "info", 3)
        }
    }
    log(
        templateName: string = "Unified Security",
        info?: string,
        data?: any,
        type: "default" | "info" | "success" | "warning" | "error" = "default",
        minLoglevel: number = 0
    ) {
        //type -> success || info || warning || error
        if (this.loglevel >= minLoglevel) {
            let css = {
                default:
                    "padding:2px 4px; border-radius:3px; background:#545861;color:rgba(255,255,255,.7)",
                info: "padding:2px 4px; border-radius:3px; background:#3498DB;color:#e1f0fa",
                success: "padding:2px 4px; border-radius:3px; background:#2DCC71; color:#282828",
                warning: "padding:2px 4px; border-radius:3px; background:#F0AD4E;color:#282828",
                error: "padding:2px 4px; border-radius:3px; background:#E74C3C;color:#fdf3f2"
            }
            if (data != undefined) {
                console.log("[" + templateName + "] %c" + info, css[type], data)
            } else {
                console.log("[" + templateName + "] %c" + info, css[type])
            }
        }
    }
}
const devLog = new DevLog()
export default devLog
