import { useGlobalMixin } from "@/mixins/mixins.global"

const inputPasswordComponent = {
    mixins: [useGlobalMixin()],

    name: "input-text",
    template: `
        <div class="row flexrow">
            <div class="col-xs padding-xs-x flex-grow">
                <input
                    :type="showPassword ? 'text' : 'password'"
                    v-bind="$attrs"
                    v-on="{
                        input: event => $emit('update:modelValue', event.target.value)
                    }"
                    style="width:100%"
                />
            </div>
            <div class="col-xs padding-xs-x flex-shrink">
                <a
                    v-on:click="toggleView()" class="btn btn-primary margin-xs-y-0"
                    :title="showPassword ? T('Hide password') : T('Show password')"
                >
                    <i
                        class="fal fa-fw"
                        :class="{
                            'fa-eye':!showPassword,
                            'fa-eye-slash':showPassword
                        }"
                    ></i>
                </a>
            </div>
        </div>
    `,
    props: {
        modelValue: null
    },
    data: function () {
        return {
            showPassword: false
        }
    },
    methods: {
        toggleView: function (this: any) {
            this.showPassword = !this.showPassword
        }
    }
}
export default inputPasswordComponent
