import config from "@/classes/config"
import { T } from "@/classes/i18n"
import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"
import validationHelpers from "@/helpers/helpers.validation"
import { useGlobalMixin } from "@/mixins/mixins.global"
import requestHandler from "@/queries/requests"
import { MutationTypes, useStore } from "@/store/vuex.store"
import loaderComponent from "../components/loader.vue"
import inputVueSelectVue from "../inputtypes/input-vue-select.vue"

const newScanDialogComponent = {
    mixins: [useGlobalMixin()],
    name: "new-scan-dialog",
    template: `
        <div>
            <template v-if="!initialized">
                <div class="text-size-3 text-center padding-xs-t-4">
                    <loader class="color-red"></loader>
                </div>
                <template v-if="loaderText">
                    <div class="text-center padding-xs-t-2 padding-xs-b-4" style="opacity:0.8">
                        <span>
                            {{ T(loaderText) }}
                        </span>
                    </div>
                </template>
            </template>
            <template v-else>
                <template v-for="error in errors">
                    <p class="label bg-red">
                        {{ error }}
                    </p>
                </template>
                <div class="padding-xs-2">
                    <template v-if="scanOptions.selectOptions.addressesInitialized">
                        <template v-if="scanOptions.selectOptions.hasPublicAddress">

                            <div class="row padding-xs-x padding-xs-b-2">
                                <p>
                                    {{T('Here you have the option of triggering a security scan of the UTM. The security scan helps you to quickly discover and correct possible misconfigurations. When you create a security scan, it is added to the queue.When the security scan has been completed, you will receive information in the tenant notifications.')}}<br><br>
                                    {{T('Please note that the UTM needs a public IP for this.')}}
                                </p>
                            </div>

                            <div class="row padding-xs-y form-group border-bottom">
                                <div class="first col-xs-24 col-lg-6 col-xl-3">
                                    <label for="address" class="control-label">
                                        {{ T('IP address') }}
                                    </label>
                                </div>
                                <div class="input col-xs-24 col-lg-8 col-xl-11">
                                    <label class="form-field" v-if="scanOptions.selectOptions.addressesInitialized">
                                        <input-vue-select
                                            id="addresses"
                                            :selectOptions="scanOptions.selectOptions.addresses"
                                            v-model="scanOptions.host.address"
                                        />
                                    </label>
                                    <loader v-else class="color-red"/>
                                </div>
                                <div class="desc col-xs-24 col-lg-10 col-xl-10">
                                    <p class="input-description">
                                        {{ T('The IP that should be scanned') }}
                                    </p>
                                </div>
                            </div>

                            <div class="row padding-xs-t form-group">
                                <div class="first col-xs-24">
                                    <label for="scan-profile" class="control-label">
                                        {{ T('Profile') }}
                                    </label>
                                </div>
                            </div>
                            <template v-for="profileInfo,profileProperty in scanOptions.selectOptions.profiles">
                                <div class="row">
                                    <div class="padding-xs item">
                                        <div class="box-shadow">
                                            <div class="box-body" style="cursor:pointer" v-on:click="toggleProfileInfo(profileProperty)">
                                                <div class="box-icon-left">
                                                    <div class="icon-circle bg-gray hover-bg-red">
                                                        <i class="fal fa-list"></i>
                                                    </div>
                                                </div>
                                                <div class="box-content-2">
                                                    <div class="box-title" style="padding-top:14px;">
                                                        <strong>{{ profileInfo.name }} ({{ profileInfo.tcp && profileInfo.udp ? "TCP & UDP" : profileInfo.tcp ? "TCP" : "UDP" }})</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr>
                                            <div class="box-body details" v-if="scanOptions.profile.toggledInfos.indexOf(profileProperty) != -1">
                                                <div class="text-smaller">
                                                    <div class="data-row" v-for="port in profileInfo.ports">
                                                        <span class="data-key text-semibold">
                                                            {{ port.port }}:
                                                        </span>

                                                        <span class="data-value" style="width:100%;">
                                                            {{ port.usedBy }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <div class="row padding-xs form-group">
                                <p>
                                    {{ T('No public IP was found on this UTM, which means that a security scan is unfortunately not possible.') }}
                                </p>
                            </div>
                        </template>
                    </template>
                    <template v-else>
                        <p class="padding-xs-y-4">
                            <loader class="color-red"/>
                        </p>
                    </template>
                </div>
            </template>
        </div>
    `,
    data: () => {
        return {
            initialized: false,
            loaderText: "Loading...",
            errors: [],
            scanOptions: {
                selectOptions: {
                    addressesInitialized: false,
                    hasPublicAddress: false,
                    addresses: [],
                    profiles: {
                        securepointTopList: {
                            name: "Securepoint Top Ports",
                            tcp: true,
                            udp: false,
                            ports: [
                                { port: 7, usedBy: "Echo-Service" },
                                { port: 9, usedBy: "Discard-Server" },
                                { port: 13, usedBy: "Daytime [RFC867]" },
                                { port: 21, usedBy: "File Transfer Protocol [RFC 959]" },
                                { port: 22, usedBy: "Secure Shell (SSH) Protocol [RFC 4251]" },
                                { port: 23, usedBy: "Telnet" },
                                { port: 25, usedBy: "SMTP" },
                                { port: 26, usedBy: "RSFTP, alternate to port 25 SMTP" },
                                { port: 37, usedBy: "TIME protocol [RFC 868] [RFC 956]" },
                                { port: 53, usedBy: "Domain Name System (DNS)" },
                                { port: 79, usedBy: "Finger" },
                                { port: 80, usedBy: "Hyper Text Transfer Protocol (HTTP)" },
                                { port: 81, usedBy: "Hyper Text Transfer Protocol (HTTP)" },
                                { port: 88, usedBy: "Kerberos (v5)" },
                                { port: 106, usedBy: "poppassd" },
                                { port: 110, usedBy: "POP3" },
                                { port: 111, usedBy: "Sun Remote Procedure Call" },
                                { port: 113, usedBy: "IDENT" },
                                { port: 119, usedBy: "Network News Transfer Protocol [RFC3977]" },
                                { port: 135, usedBy: "Remote Procedure Call (RPC)" },
                                { port: 139, usedBy: "NetBIOS" },
                                { port: 143, usedBy: "IMAP (Internet Mail Access Protocol)" },
                                { port: 144, usedBy: "Universal Management Architecture" },
                                { port: 179, usedBy: "Border Gateway Protocol(BGP) [RFC 4960]" },
                                { port: 199, usedBy: "SMUX" },
                                {
                                    port: 389,
                                    usedBy: "Lightweight Directory Access Protocol (LDAP)"
                                },
                                { port: 443, usedBy: "HTTP protocol over TLS/SSL [RFC4960]" },
                                {
                                    port: 444,
                                    usedBy: "Cisco Webex Teams, Simple Network Paging Protocol [RFC1568]"
                                },
                                {
                                    port: 445,
                                    usedBy: "Microsoft-DS Active Directory, Windows shares"
                                },
                                {
                                    port: 465,
                                    usedBy: "Outgoing SMTP Mail over SSL (SMTPS) [RFC 2487]"
                                },
                                { port: 513, usedBy: "Login via Telnet" },
                                { port: 514, usedBy: "Shell(RSH), Syslog" },
                                { port: 515, usedBy: "Printer Daemon" },
                                { port: 543, usedBy: "Kerberos login" },
                                { port: 544, usedBy: "Kerberos Remote Shell" },
                                { port: 548, usedBy: "Apple Filing Protocol (AFP)" },
                                {
                                    port: 554,
                                    usedBy: "Real Time Streaming Protocol (RTSP) Windows"
                                },
                                { port: 587, usedBy: "Outgoing SMTP Mail [RFC2476]" },
                                { port: 631, usedBy: "Internet Printing Protocol" },
                                { port: 646, usedBy: "Label Distribution Protocol  (LDP)" },
                                { port: 873, usedBy: "QNAP NAS, Rsync" },
                                { port: 990, usedBy: " FTP over TLS/SSL" },
                                { port: 993, usedBy: "IMAP (Internet Mail Access Protocol)" },
                                { port: 995, usedBy: "POP3 mail over SSL" },
                                { port: 1025, usedBy: "Windows RPC" },
                                { port: 1433, usedBy: "MsSQL-Server" },
                                { port: 1494, usedBy: "Citrix ICA Terminal-Server" },
                                { port: 1720, usedBy: "Microsoft NetMeeting" },
                                { port: 1723, usedBy: "Point-to-Point Tunneling Protocol (PPTP)" },
                                { port: 2001, usedBy: "Often use by trojans/backdoors" },
                                {
                                    port: 2049,
                                    usedBy: "Network File System (NFS) [RFC 1813] [RFC5665]."
                                },
                                { port: 2121, usedBy: "FTP-Proxy" },
                                { port: 2717, usedBy: "KnowShowGo P2P" },
                                { port: 3000, usedBy: "Ruby on Rails applications, NodeJS etc." },
                                { port: 3128, usedBy: "Squid HTTP-Proxy Standard" },
                                { port: 3306, usedBy: "MySQL-Server" },
                                { port: 3389, usedBy: "Windows Remote Desktop" },
                                { port: 4899, usedBy: "Radmin remote administration tool" },
                                { port: 5000, usedBy: "Universal Plug and Play (UPnP)" },
                                { port: 5004, usedBy: "Cisco Webex Teams" },
                                {
                                    port: 5009,
                                    usedBy: "Apple AirPort Admin Utility, Microsoft Windows Filesystem"
                                },
                                { port: 5060, usedBy: "Session Initiation Protocol (SIP)" },
                                { port: 5101, usedBy: "Yahoo P2P Instant Messages" },
                                {
                                    port: 5190,
                                    usedBy: "ICQ, AIM (AOL Instant Messenger), Apple iChat"
                                },
                                { port: 5432, usedBy: "PostgreSQL Database" },
                                { port: 5631, usedBy: "PC-Anywhere" },
                                { port: 5666, usedBy: "Nagios NRPE" },
                                { port: 5800, usedBy: "Virtual Network Computing (VNC)" },
                                { port: 5900, usedBy: "Virtual Network Computing (VNC)" },
                                { port: 6000, usedBy: "X Window System" },
                                { port: 6001, usedBy: "X Window System" },
                                { port: 6178, usedBy: "Securepoint Operation Center (SOC)" },
                                { port: 8000, usedBy: "Alternate HTTP port" },
                                { port: 8008, usedBy: "Citrix virtual Desktops" },
                                { port: 8080, usedBy: "HTTP-Proxy" },
                                { port: 8081, usedBy: "HTTP-Proxy" },
                                { port: 8110, usedBy: "Pop3Proxy" },
                                { port: 8443, usedBy: "Alternate HTTP over TLS/SSL port" },
                                { port: 8888, usedBy: "Alternate HTTP port" },
                                { port: 9200, usedBy: "Elasticsearch Query & Adminitration" },
                                { port: 9999, usedBy: "SOC Logcenter" },
                                { port: 10000, usedBy: "Webmin - Web-based Linux admin tool" },
                                { port: 11115, usedBy: "Securepoint UTM Administration" },
                                { port: 49152, usedBy: "Microsoft Lync, P2P Torent, Apple AirPlay" }
                            ]
                        }
                    }
                },
                profile: {
                    name: "securepointTopList",
                    toggledInfos: []
                },
                host: {
                    address: "",
                    interface: ""
                }
            }
        }
    },
    computed: {},
    props: {
        properties: {
            required: true,
            default: () => {
                return {
                    callback: undefined
                }
            }
        }
    },
    methods: {
        init: async function (this: any) {
            await requestHandler.request(
                "POST",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantHelpers.getTenantDomain(this.activeAccountId) +
                    "/utms/" +
                    this.objectId +
                    "/jobs/spcli",
                { data: ["interface", "address", "get"] }
            )
            this.initialized = true
        },
        toggleProfileInfo: function (this: any, profileProperty: string) {
            if (this.scanOptions.profile.toggledInfos.indexOf(profileProperty) == -1) {
                this.scanOptions.profile.toggledInfos.push(profileProperty)
            } else {
                this.scanOptions.profile.toggledInfos =
                    this.scanOptions.profile.toggledInfos.filter((entry: string) => {
                        return entry != profileProperty
                    })
            }
        },
        submit: async function (this: any) {
            this.errors = []
            try {
                if (typeof this.properties.callback == "function") {
                    let result = await this.properties.callback(this.scanOptions)
                    if (result && result.errors?.error === true) {
                        throw result.errors?.payload
                    }
                } else {
                    throw "No callback provided"
                }
                getterHelpers.useStore().commit(MutationTypes.removeModal)
            } catch (e: any) {
                this.errors.push(e)
            }
        },
        T: function (this: any, text: string) {
            return T(text)
        }
    },
    created: async function (this: any) {},
    mounted: function (this: any) {
        useStore().commit(MutationTypes.addSubscriptionHook, {
            accountId: this.activeAccountId,
            hookKey: "utmInterfaces",
            hookFunction: async (message: any) => {
                if (message.topic == "/usc/utm/" + this.objectId + "/message") {
                    let jobType: string = message.data?.type || "spcli"
                    let jobContext: string =
                        message.data?.clientContext
                            .replace(jobType + "-", "")
                            .split("-")
                            .join(" ") || "UNKNOWN"
                    if (jobContext == "interface address get") {
                        let options: {
                            id: string
                            text: string
                            address: string
                            interface: string
                        }[] = []
                        let data: any[] = message.data?.data || []

                        let publicAddressInfos = data.filter((entry: any) => {
                            let address =
                                entry?.address?.replaceAll("&#x2f;", "/").split("/")[0] ||
                                entry.address
                            return (
                                validationHelpers.isPublicIPv4(address) ||
                                validationHelpers.isPublicIpV6(address)
                            )
                        })
                        if (publicAddressInfos.length) {
                            options = publicAddressInfos.map((entry: any) => {
                                let address =
                                    entry?.address?.replaceAll("&#x2f;", "/").split("/")[0] ||
                                    entry.address
                                return {
                                    id: address,
                                    address: address,
                                    interface: entry.device,
                                    text: `${address} (${entry.device})`
                                }
                            })
                            this.scanOptions.selectOptions.hasPublicAddress = true
                            getterHelpers
                                .useStore()
                                .getters.getActiveModal(
                                    getterHelpers.useStore().state.session.activeAccountId
                                ).buttons[1].disabled = false
                        } else {
                            this.scanOptions.selectOptions.hasPublicAddress = false
                            getterHelpers
                                .useStore()
                                .getters.getActiveModal(
                                    getterHelpers.useStore().state.session.activeAccountId
                                ).buttons[1].disabled = true
                        }

                        this.scanOptions.selectOptions.addresses = options
                        if (options.length) {
                            this.scanOptions.host.address = options[0].id
                        }
                        this.scanOptions.selectOptions.addressesInitialized = true
                        getterHelpers
                            .useStore()
                            .getters.getActiveModal(
                                getterHelpers.useStore().state.session.activeAccountId
                            ).buttons[1].loading = false
                    }
                }
            }
        })
        this.init()
    },
    watch: {
        "scanOptions.host.address": function (this: any, address: string) {
            if (this.scanOptions.selectOptions.hasPublicAddress) {
                let optionInfos =
                    this.scanOptions.selectOptions.addresses.find((option: any) => {
                        return option.address == address
                    }) || {}
                this.scanOptions.host.interface = optionInfos.interface || ""
            }
        }
    },
    components: {
        loader: loaderComponent,
        "input-vue-select": inputVueSelectVue
    },
    beforeUnmount: function (this: any) {
        useStore().commit(MutationTypes.deleteSubscriptionHook, {
            accountId: this.activeAccountId,
            hookKey: "utmInterfaces"
        })
    }
}
export default newScanDialogComponent
