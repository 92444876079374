import { T } from "@/classes/i18n"
import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"
import router from "@/router/router"
import { ActionTypes, MutationTypes } from "@/store/vuex.store"
const miscDialogs = {
    confirmDialog: function (
        accountid: string,
        title: string,
        content: string,
        onSubmit: any,
        enterString?: string,
        okButtonText?: string,
        okButtonIcon?: string,
        abortable: boolean = true,
        dialogIcon = "fal fa-exclamation-triangle",
        onAbort?: any,
        autoRemove: boolean = true
    ) {
        let buttons = [
            {
                loading: false,
                onClick: async function (modalComponent: any) {
                    if (typeof onAbort === "function") {
                        await onAbort(modalComponent)
                    }
                    getterHelpers
                        .useStore()
                        .commit(MutationTypes.removeModal, { accountId: accountid })
                },
                icon: "fal fa-times",
                text: T("Cancel"),
                align: "left",
                disabled: false,
                id: "close"
            },
            {
                loading: false,
                onClick: async function (modalComponent: any) {
                    this.loading = true
                    this.disabled = true
                    getterHelpers.useStore().state.session.accounts[
                        accountid
                    ].modals[0].buttons[0].disabled = true
                    getterHelpers.useStore().state.session.accounts[
                        accountid
                    ].modals[0].buttons[0].loading = true
                    getterHelpers.useStore().state.session.accounts[accountid].modals[0].abortable =
                        false
                    try {
                        await onSubmit(modalComponent)
                        if (autoRemove) {
                            getterHelpers
                                .useStore()
                                .commit(MutationTypes.removeModal, { accountId: accountid })
                        }
                    } catch (e: any) {
                        this.loading = false
                        this.disabled = false
                        getterHelpers.useStore().state.session.accounts[
                            accountid
                        ].modals[0].buttons[0].disabled = false
                        getterHelpers.useStore().state.session.accounts[
                            accountid
                        ].modals[0].buttons[0].loading = false
                        getterHelpers.useStore().state.session.accounts[
                            accountid
                        ].modals[0].abortable = true
                    }
                },
                icon: okButtonIcon ? okButtonIcon : "fal fa-check",
                text: okButtonText ? T(okButtonText) : T("Confirm"),
                align: "right",
                disabled: false,
                id: "confirm",
                focusOnMount: true
            }
        ]
        if (!abortable) {
            buttons = buttons.slice(1)
        }
        getterHelpers.useStore().dispatch(ActionTypes.addModal, {
            id: "confirm-" + title + "-" + accountid,
            accountId: accountid,
            abortable: abortable,
            content: {
                title: {
                    text: title,
                    icon: dialogIcon
                },
                body: {
                    use: true,
                    content: undefined,
                    component: "confirm-dialog",
                    properties: {
                        enterString: enterString,
                        content: content
                    }
                }
            },
            buttons: buttons
        })
    },
    errorDialog: function (accountid: string, title: string, content: string) {
        getterHelpers.useStore().dispatch(ActionTypes.addModal, {
            id: "confirm-" + title + "-" + accountid,
            accountId: accountid,
            abortable: true,
            content: {
                title: {
                    text: title,
                    icon: "fal fa-exclamation-triangle"
                },
                body: {
                    use: true,
                    content: undefined,
                    component: "confirm-dialog",
                    properties: {
                        enterString: undefined,
                        content: content
                    }
                }
            },
            buttons: [
                {
                    loading: false,
                    onClick: async function () {
                        getterHelpers
                            .useStore()
                            .commit(MutationTypes.removeModal, { accountId: accountid })
                    },
                    icon: "fal fa-check",
                    text: "OK",
                    align: "right",
                    disabled: false
                }
            ]
        })
    },

    inputDialog: function (
        accountId: string,
        title: string,
        content: string,
        onSubmit: any,
        type: "textarea" | "text" = "textarea",
        checkValue?: (value: string) => boolean,
        placeholder?: string,
        useLinebreak = false
    ) {
        getterHelpers.useStore().dispatch(ActionTypes.addModal, {
            id: "textarea-" + title + "-" + accountId,
            accountId: accountId,
            abortable: true,
            content: {
                title: {
                    text: title,
                    icon: "fal fa-pencil-alt"
                },
                body: {
                    use: true,
                    content: undefined,
                    component: "input-dialog",
                    properties: {
                        content: content,
                        type: type,
                        sendCondition: checkValue,
                        placeholder: placeholder,
                        useLinebreak: useLinebreak
                    }
                }
            },
            buttons: [
                {
                    loading: false,
                    onClick: function () {
                        getterHelpers.useStore().commit(MutationTypes.removeModal, { accountId })
                    },
                    icon: "fal fa-times",
                    text: T("Cancel"),
                    align: "left",
                    disabled: false
                },
                {
                    loading: false,
                    onClick: async function (value?: string) {
                        this.loading = true
                        this.disabled = true
                        getterHelpers.useStore().state.session.accounts[
                            accountId
                        ].modals[0].buttons[0].loading = true
                        getterHelpers.useStore().state.session.accounts[
                            accountId
                        ].modals[0].buttons[0].disabled = true
                        getterHelpers.useStore().state.session.accounts[
                            accountId
                        ].modals[0].abortable = false
                        if (typeof value != "string") {
                            value =
                                getterHelpers.useVue().$refs.modals.$refs.modal.$refs.modalComponent
                                    .value || ""
                        }
                        await onSubmit(value)
                        getterHelpers.useStore().commit(MutationTypes.removeModal, { accountId })
                    },
                    icon: "fal fa-check",
                    text: T("Confirm"),
                    align: "right",
                    disabled: false
                }
            ]
        })
    },

    showUtmLicenseConditions: function (accountId: string, navigateUrl?: string) {
        getterHelpers.useStore().dispatch(ActionTypes.addModal, {
            id: "utmLicenseConditionsPlain",
            accountId: tenantHelpers.getAccountId(accountId),
            abortable: true,
            content: {
                title: {
                    text: T("License conditions"),
                    icon: "fal fa-info-circle"
                },
                body: {
                    content: undefined,
                    component: "utm-license-conditions-plain",
                    properties: {}
                }
            },
            buttons: [
                {
                    text: T("OK"),
                    icon: "fal fa-check",
                    onClick: async function () {
                        if (navigateUrl) {
                            router.navigate(navigateUrl)
                        }

                        getterHelpers.useStore().commit(MutationTypes.removeModal, {
                            accountId: tenantHelpers.getAccountId(accountId)
                        })
                    },
                    align: "left",
                    disabled: false,
                    loading: false
                }
            ]
        })
    },
    showUtmPrivacyPolicyDialog: function (accountId: string, navigateUrl?: string) {
        getterHelpers.useStore().dispatch(ActionTypes.addModal, {
            id: "utmLicenseConditionsPlain",
            accountId: tenantHelpers.getAccountId(accountId),
            abortable: true,
            content: {
                title: {
                    text: T("Privacy Policy"),
                    icon: "fal fa-info-circle"
                },
                body: {
                    content: undefined,
                    component: "utm-privacy-policy-plain",
                    properties: {}
                }
            },
            buttons: [
                {
                    text: T("OK"),
                    icon: "fal fa-check",
                    onClick: async function () {
                        if (navigateUrl) {
                            router.navigate(navigateUrl)
                        }
                        getterHelpers.useStore().commit(MutationTypes.removeModal, {
                            accountId: tenantHelpers.getAccountId(accountId)
                        })
                    },
                    align: "left",
                    disabled: false,
                    loading: false
                }
            ]
        })
    }
}
export default miscDialogs
