import config from "@/classes/config"
import type { UscUtm } from "@/classes/unifiedSecurityConsole/uscUtms"
import tenantHelpers from "@/helpers/helpers.tenants"
import { useStore } from "@/store/vuex.store"
import moment from "moment"
import requestHandler from "./requests"

const unifiedReportingQueries = {
    getObjectCount: async function (accountid: string, objectType: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)

            switch (objectType) {
                case "utms":
                    result = await requestHandler.request(
                        "GET",
                        config.mgtApiUriNext +
                            "/tenants/" +
                            tenantDomain +
                            "/utms?props[]=license&select=data.utms[?(license.licenseScopes[?contains(@,'laas_01') ==`true`])]"
                    )
                    result = result?.filter((utm: UscUtm) => {
                        return utm.license.licenseScopes.some((scope: string) => {
                            return scope === "laas_01"
                        })
                    }).length
                    break
                case "reports":
                    result = await requestHandler.request(
                        "GET",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/laas/reports?props[]=null&select=total"
                    )
                    break
            }
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return result
    },

    getObjectInfos: async function (
        accountid: string,
        objectType: string,
        props?: any[],
        force: boolean = false
    ) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountid)
            let timeStamp: number = moment().unix()

            let propertiesString: string = ""

            if (force === true) {
                propertiesString += "?_=" + timeStamp
            }

            ;(props || []).forEach(function (prop: any) {
                if (propertiesString.length == 0) {
                    propertiesString += "?" + prop.property + "=" + prop.value
                } else {
                    propertiesString += "&" + prop.property + "=" + prop.value
                }
            })

            switch (objectType) {
                case "utms":
                    if (propertiesString.length == 0) {
                        propertiesString = "?"
                    } else if (propertiesString == "?_=" + timeStamp) {
                        propertiesString += "&"
                    } else if (propertiesString.indexOf("props[]=license") == -1) {
                        propertiesString += "&props[]=license&"
                    } else {
                        propertiesString += "&"
                    }
                    propertiesString +=
                        "select=data.utms[?(license.licenseScopes[?contains(@,'laas_01') ==`true`])]"
                    result = await requestHandler.request(
                        "GET",
                        config.mgtApiUriNext +
                            "/tenants/" +
                            tenantDomain +
                            "/utms" +
                            propertiesString
                    )

                    if (window.app.tempStorage.usrUtmVersions) {
                        result = result.map((utm: any) => {
                            if (utm?.utmId) {
                                let usrUtmInfo = window.app.tempStorage.usrUtmVersions?.find(
                                    (usrInfo: any) => {
                                        return usrInfo.device == utm.utmId
                                    }
                                )
                                if (usrUtmInfo) {
                                    utm.messages = {
                                        "spcli-system-info": {
                                            version: usrUtmInfo.version
                                        }
                                    }
                                }
                            }
                            return utm
                        })
                    }

                    result = result?.filter((utm: UscUtm) => {
                        return utm.license.licenseScopes.some((scope: string) => {
                            return scope === "laas_01"
                        })
                    })
                    break
                case "reports":
                    result = await requestHandler.request(
                        "GET",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/laas/reports" +
                            propertiesString
                    )
                    break
            }
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            }
        }
        return result
    },
    getObjectInfo: async function (
        accountid: string,
        objectType: string,
        objectId?: string,
        property?: string,
        withProperties: boolean = false,
        customerId?: string,
        props?: any[]
    ) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountid)
            let propertiesString: string = ""
            ;(props || []).forEach(function (prop: any) {
                if (propertiesString.length == 0) {
                    propertiesString += "?" + prop.property + "=" + prop.value
                } else {
                    propertiesString += "&" + prop.property + "=" + prop.value
                }
            })
            switch (objectType) {
                case "utms":
                    result = await requestHandler.request(
                        "GET",
                        config.mgtApiUriNext +
                            "/tenants/" +
                            tenantDomain +
                            "/utms/" +
                            objectId +
                            propertiesString
                    )
                    break
                case "reports":
                    result = await requestHandler.request(
                        "GET",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/laas/reports/" +
                            objectId +
                            propertiesString
                    )
                    break
            }
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            }
        }
        return result
    },

    addObjectInfo: async function (accountid: string, objectType: string, object: any) {
        let result: boolean = false
        let results: any = []
        let updateMaskString: string = ""
        let payload: any = ""
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw {
                    status: 401
                }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            switch (objectType) {
                case "utms":
                    result = await requestHandler.request(
                        "POST",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/laas/utms/",
                        object
                    )
                    break
                case "reports":
                    result = await requestHandler.request(
                        "POST",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/laas/reports/",
                        object
                    )
                    break
            }
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return result
    },

    updateObjectInfo: async function (
        accountid: string,
        objectType: string,
        objectId: string = "",
        object: any,
        updateMask?: string[]
    ) {
        let result: boolean = false
        let results: any = []
        let updateMaskString: string = ""
        let payload: any = ""
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw {
                    status: 401
                }
            }
            let tenantDomain: string = tenantHelpers.getTenantDomain(accountid)
            switch (objectType) {
                case "utms":
                    result = await requestHandler.request(
                        "PUT",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/laas/utms/" +
                            objectId,
                        object
                    )
                    break
                case "utmProperties":
                    if (updateMask) {
                        results = []
                        for (let i in updateMask) {
                            let property: string = updateMask[i]
                            payload = {}
                            payload[property] = object[property]

                            if (property == "username" && object[property] == "") {
                                results[i] = await requestHandler.request(
                                    "DELETE",
                                    "/sms-mgt-api/api/" +
                                        config.mgtApiVersion +
                                        "/tenants/" +
                                        tenantDomain +
                                        "/laas/utms/" +
                                        objectId +
                                        "/properties/" +
                                        property
                                )
                            } else {
                                results[i] = await requestHandler.request(
                                    "PUT",
                                    "/sms-mgt-api/api/" +
                                        config.mgtApiVersion +
                                        "/tenants/" +
                                        tenantDomain +
                                        "/laas/utms/" +
                                        objectId +
                                        "/properties/" +
                                        property,
                                    payload
                                )
                            }
                        }
                    } else {
                        result = await requestHandler.request(
                            "POST",
                            "/sms-mgt-api/api/" +
                                config.mgtApiVersion +
                                "/tenants/" +
                                tenantDomain +
                                "/laas/utms/" +
                                objectId +
                                "/properties/",
                            object
                        )
                    }
                    break
                case "reports":
                    result = await requestHandler.request(
                        "PUT",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/laas/reports/" +
                            objectId,
                        object
                    )
                    break
            }
            result = true
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            } else {
                console.error(e)
            }
            result = false
        }
        return result
    },

    deleteObject: async function (accountid: string, objectType: string, objectId: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountid)
            switch (objectType) {
                case "utms":
                    result = await requestHandler.request(
                        "DELETE",
                        "/sms-mgt-api/api/" +
                            config.mgtApiVersion +
                            "/tenants/" +
                            tenantDomain +
                            "/laas/utms/" +
                            objectId
                    )
                    break
            }
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            }
        }
        return result
    },

    sendUtmMessage: async function (accountid: string, utmId: string, message: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountid)
            return await requestHandler.request(
                "POST",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantDomain +
                    "/utms/" +
                    utmId +
                    "/jobs/" +
                    message
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            }
        }
        return result
    },

    getUtmMessage: async function (accountid: string, utmId: string, message: string) {
        let result: any = null
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore()?.getters.hasAccountid(accountid) == false) {
                throw { status: 401 }
            }
            let tenantDomain = tenantHelpers.getTenantDomain(accountid)
            return await requestHandler.request(
                "GET",
                config.mgtApiUriNext +
                    "/tenants/" +
                    tenantDomain +
                    "/utms/" +
                    utmId +
                    "/jobs/" +
                    message
            )
        } catch (e: any) {
            if (e.status == 401) {
                //app.router.push({'name':'401'})
            }
        }
        return result
    }
}
export default unifiedReportingQueries
