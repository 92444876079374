<script setup lang="ts">
import { T } from "@/classes/i18n"
import products from "@/classes/objectTypes"
import type { User } from "@/classes/objectTypes/unifiedSecurity/users/users"
import getterHelpers from "@/helpers/helpers.getters"
import useRouterStore from "@/router/routerStore"
import { computed, onMounted, onUnmounted, reactive, ref, watch } from "vue"

import vppApi from "@/classes/objectTypes/mobileSecurity/apple/vpp"
import deviceHelpers from "@/helpers/helpers.devices"
import numberHelpers from "@/helpers/helpers.numbers"
import router from "@/router/router"
import itemlistItem from "@/templates/components/itemlist-item.vue"
import moment from "moment"
import { Carousel, Navigation, Slide } from "vue3-carousel"
import "vue3-carousel/dist/carousel.css"
import loaderComponent from "../components/loader.vue"
import inputVueSelect from "../inputtypes/input-vue-select.vue"

const initialized = ref(false)
const activeTab = ref("description")
const updateCounter = ref(0)
const loader = ref({
    loading: true,
    saving: false,
    loaderText: "Loading..."
})
const assignment = reactive({
    userOptions: <any[]>[],
    deviceOptions: <any[]>[],
    changes: {
        serials: {
            add: <string[]>[],
            remove: <string[]>[]
        },
        clientUserIdStrs: {
            add: <string[]>[],
            remove: <string[]>[]
        }
    }
})

const switchedPage = ref(false)
const inputSerials = ref(<string[]>[])
const inputClientUserIdStrs = ref(<string[]>[])

const thisClientUserIdStrs = ref(<string[]>[])
const thisSerials = ref(<string[]>[])

const activeAccountId = computed(() => {
    return getterHelpers.useStore().getters.getActiveAccountId
})
const objectId = computed(() => {
    return useRouterStore().getObjectId
})
const context = computed(() => {
    return useRouterStore().context
})
const activePage = computed(() => {
    return useRouterStore().activePage
})
const users = computed(() => {
    return (
        products.unifiedSecurity.users.useStore?.()?.getObjectStoreObjects(activeAccountId.value) ||
        []
    )
})
const devices = computed(() => {
    return (
        products.mobileSecurity.iosDevices
            .useStore?.()
            .getObjectStoreObjects(activeAccountId.value) || []
    )
})
const asset = computed(() => {
    return objectId.value
        ? products.mobileSecurity.vppassets
              .useStore?.()
              .getObjectStoreObject(activeAccountId.value, objectId.value)
        : undefined
})
const labels = computed(() => {
    updateCounter.value

    let thisLabels: any = []
    if (!asset.value?.assetInfo?.isInStore) {
        thisLabels.push({
            title: T("Not in App Store"),
            class: "bg-yellow"
        })
    }
    return thisLabels
})

const thisAssignments = computed(() => {
    updateCounter.value
    return (
        products.mobileSecurity.vppAssignments
            .useStore?.()
            .getObjectStoreObjects(activeAccountId.value)
            .filter((assignment) => {
                return assignment.adamId == objectId.value
            }) || []
    )
})

const vppUsers = computed(() => {
    return users.value.filter(function (user: User) {
        return (
            user?.profile?.clientUserIdStr != undefined &&
            user?.profile?.appleId != undefined &&
            user.profile.appleId.length
        )
    })
})
const depDevices = computed(() => {
    return devices.value.filter(function (device: any) {
        return device.depDevice == true || device.depOnly == true
    })
})

const setActiveTab = (tabname: string) => {
    activeTab.value = tabname
}

const init = async (doRequests = true) => {
    loader.value.loading = true
    loader.value.loaderText = "Loading..."
    if (objectId.value) {
        if (doRequests) {
            await products.mobileSecurity.vppassets.queries.getObjectsFromApi(activeAccountId.value)

            // GET USERS AND DEVICES
            await products.unifiedSecurity.users.queries.getObjectsFromApi(activeAccountId.value)
            await products.mobileSecurity.iosDevices.queries.getObjectsFromApi(
                activeAccountId.value
            )
            await products.mobileSecurity.vppAssignments.queries.getObjectsFromApi(
                activeAccountId.value,
                undefined,
                [
                    {
                        property: "adamId",
                        value: objectId.value
                    }
                ]
            )
        }

        let userOptions = vppUsers.value.map(function (user: any) {
            return {
                id: user.profile?.clientUserIdStr,
                text: user.username + " (" + user.profile.appleId + ")"
            }
        })

        let deviceOptions = depDevices.value.map(function (device) {
            return {
                id: device.serialNumber || device.serial_number || device.info.SerialNumber,
                text: deviceHelpers.getAliasedShortDeviceId(
                    device.deviceId ||
                        device.serialNumber ||
                        device.serial_number ||
                        device.info.SerialNumber,
                    device.alias || device.description
                )
            }
        })

        if (asset.value == undefined && switchedPage.value == false) {
            router.navigate("404-" + objectId.value)
        } else if (asset.value !== undefined) {
            // Get users for this asset
            let thisVppUsers = (vppUsers.value || []).filter(function (user) {
                return (
                    thisAssignments.value.filter(function (assignment) {
                        return user.profile?.clientUserIdStr == assignment.clientUserId
                    }).length > 0
                )
            })
            // Get clientUserIdStrs from this users
            thisClientUserIdStrs.value = thisVppUsers.map(function (vppUser) {
                return vppUser.profile?.clientUserIdStr || ""
            })

            let licensesAssignedToDevice: any[] = (thisAssignments.value || []).filter(
                function (assignment) {
                    return assignment.serialNumber != undefined
                }
            )
            // Get serials from this devices
            thisSerials.value = licensesAssignedToDevice.map(function (assignment) {
                return assignment.serialNumber
            })

            // add unknown devices/serials
            thisSerials.value.forEach(function (serial: string) {
                let deviceExists: boolean =
                    deviceOptions.filter(function (device) {
                        return device.id == serial
                    }).length > 0

                if (!deviceExists) {
                    deviceOptions.push({ id: serial, text: T("Unknown Device") })
                }
            })

            // set data
            inputClientUserIdStrs.value = thisClientUserIdStrs.value
            inputSerials.value = thisSerials.value
            assignment.userOptions = userOptions
            assignment.deviceOptions = deviceOptions

            if (!asset.value?.assetInfo?.isInStore) {
                setActiveTab("assignment")
            }
        }
    }
    loader.value.loading = false
    initialized.value = true
}

const save = async () => {
    let result: boolean = true
    loader.value.saving = true
    try {
        if (
            assignment.changes.clientUserIdStrs.remove.length > 0 ||
            assignment.changes.serials.remove.length > 0 ||
            assignment.changes.clientUserIdStrs.add.length > 0 ||
            assignment.changes.serials.add.length > 0
        ) {
            // loop through biggest array and create batches
            let batches: any[] = []
            let biggestArrayLength: number = Math.max.apply(null, [
                assignment.changes.clientUserIdStrs.remove.length,
                assignment.changes.serials.remove.length,
                assignment.changes.clientUserIdStrs.add.length,
                assignment.changes.serials.add.length
            ])
            let batchCount: number = 0
            let entriesCount: number = 0
            for (let i: number = 0; biggestArrayLength > i; i++) {
                // Add batch if it doesn't exist
                if (!batches[batchCount]) {
                    batches.push({
                        serials: {
                            add: [],
                            remove: []
                        },
                        clientUserIdStrs: {
                            add: [],
                            remove: []
                        }
                    })
                }

                if (assignment.changes.serials.add[i]) {
                    // add serial that should be assigned to batch
                    batches[batchCount].serials.add.push(assignment.changes.serials.add[i])
                }
                if (assignment.changes.serials.remove[i]) {
                    // add serial that should be unassigned to batch
                    batches[batchCount].serials.remove.push(assignment.changes.serials.remove[i])
                }
                if (assignment.changes.clientUserIdStrs.add[i]) {
                    // add clientUserIdStr that should be assigned to batch
                    batches[batchCount].clientUserIdStrs.add.push(
                        assignment.changes.clientUserIdStrs.add[i]
                    )
                }
                if (assignment.changes.clientUserIdStrs.remove[i]) {
                    // add clientUserIdStr that should be unassigned to batch
                    batches[batchCount].clientUserIdStrs.remove.push(
                        assignment.changes.clientUserIdStrs.remove[i]
                    )
                }

                entriesCount++
                if (entriesCount == 10) {
                    batchCount++
                    entriesCount = 0
                }
            }
            for (let i: number = 0; batches.length > i; i++) {
                let batch: any = batches[i]
                if (asset.value) {
                    if (batch.clientUserIdStrs.add.length || batch.serials.add.length) {
                        await vppApi.associateVppAssets(activeAccountId.value, {
                            assets: [
                                {
                                    adamId: asset.value.adamId,
                                    pricingParam: asset.value.pricingParam
                                }
                            ],
                            clientUserIds: batch.clientUserIdStrs.add,
                            serialNumbers: batch.serials.add
                        })
                    }
                    if (batch.clientUserIdStrs.remove.length || batch.serials.remove.length) {
                        await vppApi.disassociateVppAssets(activeAccountId.value, {
                            assets: [
                                {
                                    adamId: asset.value.adamId,
                                    pricingParam: asset.value.pricingParam
                                }
                            ],
                            clientUserIds: batch.clientUserIdStrs.remove,
                            serialNumbers: batch.serials.remove
                        })
                    }
                }
            }

            router.navigate("show-tenant-" + activeAccountId.value + ".sms-vpp-assets-dashboard")
        }
    } catch (e: any) {
        result = e
    }
    loader.value.saving = false
    return result
}

// add watcher / onChange event listener
watch(
    inputClientUserIdStrs,
    (newValue: string[], oldValue: string[]) => {
        // get removed clientUserIdStrs
        let removedclientUserIdStrs = oldValue.filter(function (clientUserIdStr: string) {
            return newValue.indexOf(clientUserIdStr) == -1
        })
        // get added clientUserIdStrs
        let addedclientUserIdStrs = newValue.filter(function (clientUserIdStr: string) {
            return oldValue.indexOf(clientUserIdStr) == -1
        })

        removedclientUserIdStrs.forEach(function (clientUserIdStr: string) {
            if (thisClientUserIdStrs.value.indexOf(clientUserIdStr) != -1) {
                assignment.changes.clientUserIdStrs.remove.push(clientUserIdStr)
            }

            if (assignment.changes.clientUserIdStrs.add.indexOf(clientUserIdStr) != -1) {
                let index: number = assignment.changes.clientUserIdStrs.add.indexOf(clientUserIdStr)
                assignment.changes.clientUserIdStrs.add.splice(index, 1)
            }
        })
        addedclientUserIdStrs.forEach(function (clientUserIdStr: string) {
            if (thisClientUserIdStrs.value.indexOf(clientUserIdStr) == -1) {
                assignment.changes.clientUserIdStrs.add.push(clientUserIdStr)
            }
            if (assignment.changes.clientUserIdStrs.remove.indexOf(clientUserIdStr) != -1) {
                let index: number =
                    assignment.changes.clientUserIdStrs.remove.indexOf(clientUserIdStr)
                assignment.changes.clientUserIdStrs.remove.splice(index, 1)
            }
        })
        function onlyUnique(value: any, index: any, self: any) {
            return self.indexOf(value) === index
        }
        assignment.changes.clientUserIdStrs.remove =
            assignment.changes.clientUserIdStrs.remove.filter(onlyUnique)
        assignment.changes.clientUserIdStrs.add =
            assignment.changes.clientUserIdStrs.add.filter(onlyUnique)
    },
    { deep: true }
)

// add watcher / onChange event listener
watch(
    inputSerials,
    (newValue: string[], oldValue: string[]) => {
        // get removed clientUserIdStrs
        let removedserials = oldValue.filter(function (serial: string) {
            return newValue.indexOf(serial) == -1
        })
        // get added serials
        let addedserials = newValue.filter(function (serial: string) {
            return oldValue.indexOf(serial) == -1
        })

        removedserials.forEach(function (serial: string) {
            if (thisSerials.value.indexOf(serial) != -1) {
                assignment.changes.serials.remove.push(serial)
            }

            if (assignment.changes.serials.add.indexOf(serial) != -1) {
                let index: number = assignment.changes.serials.add.indexOf(serial)
                assignment.changes.serials.add.splice(index, 1)
            }
        })
        addedserials.forEach(function (serial: string) {
            if (thisSerials.value.indexOf(serial) == -1) {
                assignment.changes.serials.add.push(serial)
            }
            if (assignment.changes.serials.remove.indexOf(serial) != -1) {
                let index: number = assignment.changes.serials.remove.indexOf(serial)
                assignment.changes.serials.remove.splice(index, 1)
            }
        })
        function onlyUnique(value: any, index: any, self: any) {
            return self.indexOf(value) === index
        }
        assignment.changes.serials.remove = assignment.changes.serials.remove.filter(onlyUnique)
        assignment.changes.serials.add = assignment.changes.serials.add.filter(onlyUnique)
    },
    { deep: true }
)

watch(
    [asset, vppUsers, thisAssignments],
    () => {
        init(false)
    },
    { deep: true }
)

watch([activePage, context], () => {
    if (activePage.value != "vppassets" || context.value == undefined) {
        switchedPage.value = true
    }
})

onMounted(() => {
    switchedPage.value = false
    init()
})

onUnmounted(() => {
    switchedPage.value = true
})
</script>
<template>
    <div>
        <section
            class="padding-xs padding-md-2 padding-lg-4 pagecontent"
            v-if="initialized && asset != undefined"
        >
            <div class="row itemlist show-details view-grid">
                <itemlist-item
                    :item="asset"
                    :editable="false"
                    :show-menu="false"
                    :clickable="false"
                    class="col-xs-24 col-print-24 col-lg-12"
                ></itemlist-item>
                <div class="col-xs-24 col-print-24 col-lg-12 padding-xs"></div>
            </div>
            <div class="row">
                <div class="col-xs-24 padding-xs">
                    <div class="box-shadow">
                        <div class="tabs">
                            <nav>
                                <ul class="nav nav-tabs hide-on-print">
                                    <li
                                        v-if="asset && asset.assetInfo && asset.assetInfo.isInStore"
                                    >
                                        <a
                                            :class="{ active: activeTab == 'description' }"
                                            v-on:click="setActiveTab('description')"
                                        >
                                            <i class="fal fa-fw fa-info-circle"></i>
                                            {{ T("Description") }}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            :class="{ active: activeTab == 'assignment' }"
                                            v-on:click="setActiveTab('assignment')"
                                        >
                                            <i class="fal fa-fw fa-key"></i>
                                            {{ T("License assignment") }}
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <div class="tabcontent">
                                <div
                                    :class="{ hidden: activeTab != 'description' }"
                                    v-if="asset && asset.assetInfo && asset.assetInfo.isInStore"
                                >
                                    <div class="box-content-2">
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <h4>Screenshots</h4>
                                                <div>
                                                    <template
                                                        v-if="
                                                            (asset.assetInfo.screenshotUrls || [])
                                                                .length
                                                        "
                                                    >
                                                        <Carousel
                                                            :items-to-show="3"
                                                            :wrap-around="true"
                                                            :autoplay="5000"
                                                            :transition="1500"
                                                        >
                                                            <Slide
                                                                v-for="url in asset.assetInfo
                                                                    .screenshotUrls"
                                                                :key="url"
                                                            >
                                                                <div class="carousel__item">
                                                                    <img :src="url" />
                                                                </div>
                                                            </Slide>
                                                            <template #addons>
                                                                <Navigation />
                                                            </template>
                                                        </Carousel>
                                                    </template>
                                                    <template
                                                        v-else-if="
                                                            (
                                                                asset.assetInfo
                                                                    .ipadScreenshotUrls || []
                                                            ).length
                                                        "
                                                    >
                                                        <Carousel
                                                            :items-to-show="3"
                                                            :wrap-around="true"
                                                            :autoplay="5000"
                                                            :transition="1500"
                                                        >
                                                            <Slide
                                                                v-for="url in asset.assetInfo
                                                                    .ipadScreenshotUrls"
                                                                :key="url"
                                                            >
                                                                <div class="carousel__item">
                                                                    <img :src="url" />
                                                                </div>
                                                            </Slide>
                                                            <template #addons>
                                                                <Navigation />
                                                            </template>
                                                        </Carousel>
                                                    </template>
                                                </div>
                                                <hr class="margin-xs-y-2" />
                                                <h4>{{ T("Description") }}</h4>
                                                <p>{{ asset.assetInfo.description }}</p>
                                                <hr class="margin-xs-y-2" />
                                                <h4>{{ T("News") }}</h4>
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <p>
                                                            {{ asset.assetInfo.releaseNotes }}
                                                        </p>
                                                    </div>
                                                    <div class="col-xs-12">
                                                        <p class="text-right">
                                                            Version: {{ asset.assetInfo.version }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr class="margin-xs-y-2" />
                                                <h4>{{ T("Infos") }}</h4>
                                                <table>
                                                    <tr v-if="asset.assetInfo.sellerName">
                                                        <td>{{ T("Seller") }}</td>
                                                        <td>{{ asset.assetInfo.sellerName }}</td>
                                                    </tr>
                                                    <tr v-if="asset.assetInfo.fileSizeBytes">
                                                        <td>{{ T("Size") }}</td>
                                                        <td>
                                                            {{
                                                                numberHelpers.formatBytes(
                                                                    Number(
                                                                        asset.assetInfo
                                                                            .fileSizeBytes
                                                                    ),
                                                                    2
                                                                ).value +
                                                                " " +
                                                                numberHelpers.formatBytes(
                                                                    Number(
                                                                        asset.assetInfo
                                                                            .fileSizeBytes
                                                                    ),
                                                                    2
                                                                ).unit
                                                            }}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="asset.assetInfo.genres">
                                                        <td>{{ T("Category") }}</td>
                                                        <td>
                                                            <template
                                                                v-for="(genre, index) in asset
                                                                    .assetInfo.genres"
                                                            >
                                                                {{ genre
                                                                }}<template
                                                                    v-if="
                                                                        asset.assetInfo.genres
                                                                            .length >
                                                                        index + 1
                                                                    "
                                                                    >,
                                                                </template>
                                                            </template>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="asset.assetInfo.minimumOsVersion">
                                                        <td>{{ T("Compatibility") }}</td>
                                                        <td>
                                                            {{
                                                                T("Requires iOS") +
                                                                " " +
                                                                asset.assetInfo.minimumOsVersion +
                                                                " " +
                                                                T("or later") +
                                                                "."
                                                            }}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="asset.assetInfo.trackContentRating">
                                                        <td>{{ T("Age Rating") }}</td>
                                                        <td>
                                                            {{ asset.assetInfo.trackContentRating
                                                            }}<br />
                                                            <template
                                                                v-for="(advisory, index) in asset
                                                                    .assetInfo.advisories"
                                                            >
                                                                {{ advisory
                                                                }}<template
                                                                    v-if="
                                                                        asset.assetInfo.advisories
                                                                            .length > index
                                                                    "
                                                                    ><br
                                                                /></template>
                                                            </template>
                                                        </td>
                                                    </tr>
                                                    <tr
                                                        v-if="
                                                            asset.assetInfo
                                                                .currentVersionReleaseDate
                                                        "
                                                    >
                                                        <td>{{ T("Copyright") }}</td>
                                                        <td>
                                                            &copy;
                                                            {{
                                                                moment(
                                                                    asset.assetInfo
                                                                        .currentVersionReleaseDate
                                                                ).format("YYYY") +
                                                                " " +
                                                                asset.assetInfo.sellerName
                                                            }}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="asset.assetInfo.sellerUrl">
                                                        <td></td>
                                                        <td>
                                                            <a
                                                                target="_blank"
                                                                rel="noreferrer noopener"
                                                                :href="asset.assetInfo.sellerUrl"
                                                                >{{ T("Developer Website") }}</a
                                                            >
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div :class="{ hidden: activeTab != 'assignment' }">
                                    <div class="box-content-2">
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <div class="row padding-xs-y">
                                                    <div class="col-xs-24">
                                                        <p class="notification bg-red color-white">
                                                            {{
                                                                T(
                                                                    "Attention: The assignment of licenses must be synchronized with Apple after saving. Changes may not be displayed for a few minutes"
                                                                )
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row padding-xs-y form-group border-bottom"
                                                >
                                                    <div class="first col-xs-24 col-lg-4">
                                                        <label class="control-label inputname">
                                                            {{ T("Devices") }}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-20">
                                                        <div class="form-field margin-xs-b-0">
                                                            <input-vue-select
                                                                :multiple="true"
                                                                v-model="inputSerials"
                                                                :selectOptions="
                                                                    assignment.deviceOptions
                                                                "
                                                            >
                                                            </input-vue-select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    class="row padding-xs-y form-group border-bottom"
                                                >
                                                    <div class="first col-xs-24 col-lg-4">
                                                        <label class="control-label inputname">
                                                            {{ T("Apple VPP users") }}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-20">
                                                        <div class="form-field margin-xs-b-0">
                                                            <input-vue-select
                                                                :multiple="true"
                                                                v-model="inputClientUserIdStrs"
                                                                :selectOptions="
                                                                    assignment.userOptions
                                                                "
                                                            >
                                                            </input-vue-select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-xs-24 padding-xs-t-2">
                                                        <p class="text-right">
                                                            <a
                                                                class="btn btn-loader twist-in"
                                                                :class="{
                                                                    'btn-loading': loader.saving
                                                                }"
                                                                v-on:click="save"
                                                            >
                                                                <span class="animate">
                                                                    <loaderComponent
                                                                        v-if="loader.saving"
                                                                        style="
                                                                            font-size: 1.5em;
                                                                            top: calc(
                                                                                50% - 0.375em - 1px
                                                                            );
                                                                            position: absolute;
                                                                            left: calc(
                                                                                50% - 0.375em -
                                                                                    0.5px
                                                                            );
                                                                        "
                                                                    ></loaderComponent>
                                                                </span>
                                                                <span
                                                                    ><i class="fal fa-save"></i>
                                                                    <span
                                                                        class="display-xs-none display-lg-inline"
                                                                        >{{ T("Save") }}</span
                                                                    ></span
                                                                >
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <template v-else>
            <p class="text-center padding-xs-t-4 text-size-4 padding-xs-b-0 text-center">
                <loaderComponent class="color-red"></loaderComponent>
            </p>
            <p class="text-center">
                {{ T(loader.loaderText) }}
            </p>
        </template>
    </div>
</template>
