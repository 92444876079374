import apis from "@/classes/apis"
import config from "@/classes/config"
import { useFeatureStore } from "@/classes/featureStore"
import devLog from "@/classes/log"
import timeHelpers from "@/helpers/helpers.time"
import requestHandler from "@/queries/requests"
import ObjectType, {
    type AccountId,
    type AddObjectTypeObject,
    type ObjectTypePayload
} from "../../objectType"

export interface DepDevice {
    color: string
    description: string
    device_assigned_by: string
    device_assigned_date: string
    device_family: string
    model: string
    os: string
    profile_assign_time: string
    profile_status: string
    profile_uuid: string
    serial_number: string
    depDevice: true
    depOnly: boolean
}

class DepDevices extends ObjectType<DepDevice> {
    constructor(payload: ObjectTypePayload<DepDevice>) {
        super(payload)

        /**
         * Gets object-list from api
         * @param accountId
         * @param props (optional) GET request Payoad as array
         */
        this.queries.getObjectsFromApi = async (
            accountId,
            customerId?,
            props?,
            updateStore = true,
            removeOldObjects = false
        ) => {
            let store = this.useStore?.().getObjectStore(accountId)
            let timestamp = store?.objectsTimestamp || 0
            let isRequestable = (function () {
                return store?.gettingObjects == false
            })()
            if ((store && isRequestable) || store == undefined) {
                if (store) {
                    store.gettingObjects = true
                    store.objectsTimestamp = timeHelpers.getUnixTimeStamp()
                }

                let propertiesString: string = props ? this.getPropertiesString(props) : ""
                if (this.getListUrl(accountId).indexOf("?") != -1) {
                    propertiesString = propertiesString.replace("?", "&")
                }

                let result: DepDevice[] = []

                const getDevices = async (accountId: string, cursor?: string) => {
                    const payload = cursor ? { cursor } : {}
                    let response = await requestHandler.request(
                        this.options.apiInfo.getObjectListMethod,
                        this.getListUrl(accountId) + propertiesString,
                        payload
                    )
                    response = apis.parseApiResponse(response)
                    let propertyInresponse = this.options.apiInfo.getObjectListResponseProperty
                    if (
                        typeof propertyInresponse == "string" &&
                        propertyInresponse.length > 0 &&
                        response[propertyInresponse]
                    ) {
                        result.push(...(response[propertyInresponse as string] as DepDevice[]))
                    } else if (
                        typeof propertyInresponse == "function" &&
                        response[propertyInresponse()]
                    ) {
                        result.push(...(response[propertyInresponse()] as DepDevice[]))
                    } else if (Array.isArray(response)) {
                        result.push(...(response as DepDevice[]))
                    } else {
                        throw new Error("Error getting Objects")
                    }
                    // paging
                    if (response.cursor?.length && response.more_to_follow == true) {
                        await getDevices(accountId, response.cursor)
                    }
                }

                try {
                    await getDevices(accountId)
                    if (updateStore) {
                        if (removeOldObjects == true) {
                            this.useStore?.().clearObjectTypeObjectsFromStore(accountId)
                        }
                        this.useStore?.().setObjectTypeObjects(accountId, result)
                    }

                    if (store) {
                        store.gettingObjects = false
                    }
                    return result
                } catch (e: any) {
                    if (store) {
                        store.gettingObjects = false
                    }
                    devLog.log("ObjectType", e.message, e, "error")
                    throw e as Error
                }
            } else {
                return this.useStore?.().getObjectStoreObjects(accountId) || []
            }
        }
    }
    /**
     * Converts Object for ObjectTypeStore
     */
    convertObjectForStore(accountId: AccountId, objectBase: AddObjectTypeObject<DepDevice>) {
        objectBase.depDevice = true
        objectBase.depOnly = true
    }

    public updateRoutes() {
        if (useFeatureStore().isEnabled("APPLE_DEP_V2_ROUTES")) {
            const apiInfo = depDevices.options.apiInfo
            apiInfo.url = config.mgtApiUriNext

            apiInfo.getCountGETProperties = "?props[]=null&select=count"

            // GET
            apiInfo.getObjectListResponseProperty = "devices"
            apiInfo.getObjectListPath = "/tenants/{tenantDomain}/apple/dep/server/devices"

            // Add
            apiInfo.addObjectPath = "/tenants/{tenantDomain}/apple/dep/server/devices"

            // Update
            apiInfo.updateObjectPath = "/tenants/{tenantDomain}/apple/dep/server/devices/{objectId}"

            // Delete
            apiInfo.deleteObjectPath = "/tenants/{tenantDomain}/apple/dep/server/devices/{objectId}"
        }
    }
}

const depDevices = new DepDevices({
    productType: "mobileSecurity",
    slug: "depdevices",
    objectType: "depDevices",
    hasStore: true,
    appearance: {
        iconClass: "fal fa-mobile-alt",
        text: {
            plural: "Devices",
            title: "Devices",
            sidebarName: "Devices",
            singular: "Device"
        },
        color: "red",
        showInSidebar: true,
        showOnDashboard: true
    },
    objectTypeInfo: {
        primaryKeyProperty: {
            property: "serial_number",
            pathToPrimaryProperty: undefined
        },
        nameProperty: {
            primary: "alias",
            pathToPrimaryProperty: undefined,
            secondary: undefined,
            pathToSecondaryProperty: undefined
        }
    },
    apiInfo: {
        url: "/sms-mgt-api/api/1.1",
        getCountGETProperties: "?props[]=null&select=count",
        // GET
        getObjectListResponseProperty: "devices",
        getObjectListMethod: "POST",
        getObjectListPath: "/tenants/{tenantDomain}/ios/dep/server/devices",
        // Add
        addObjectMethod: "POST",
        addObjectPath: "/tenants/{tenantDomain}/ios/dep/server/devices",
        // Update
        updateObjectMethod: "PUT",
        updateObjectPath: "/tenants/{tenantDomain}/ios/dep/server/devices/{objectId}",
        // Delete
        deleteObjectPath: "/tenants/{tenantDomain}/ios/dep/server/devices/{objectId}"
    }
})
export default depDevices
