import { defineStore } from "pinia"
import { T } from "./i18n"

export type UserLogEntry = {
    icon: string
    colorClass: string
    timestamp: string
    entryType: "FOLDABLE" | "DATA"
    showFoldableContent: boolean
    title: string
    payload: any
}

type UserLogWebsocketMessage = {
    from: string
    to: string
    topic: string
    timestamp: number
    tenantDomain: string
    nodeId: number
    data: unknown
}

function getTimeStamp() {
    let d = new Date()
    function numFormat(n: any) {
        return n < 10 ? "0" + n : n
    }
    return (
        "[" +
        numFormat(d.getHours()) +
        ":" +
        numFormat(d.getMinutes()) +
        ":" +
        numFormat(d.getSeconds()) +
        "] "
    )
}

const logOptions: { [props: string]: string } = {
    defaultClass: "color-white",
    infoClass: "color-lightblue",
    warningClass: "color-yellow",
    errorClass: "color-red"
}

export const useUserLogStore = defineStore("userLogs", {
    state: () => ({
        logEntrys: [
            {
                colorClass: "color-lightblue",
                entryType: "DATA",
                icon: "fal fa-fw fa-check-circle",
                showFoldableContent: false,
                timestamp: getTimeStamp(),
                title: T("Log initialized"),
                payload: undefined
            }
        ] as UserLogEntry[]
    }),
    getters: {
        getLogEntrys(state) {
            return state.logEntrys
        }
    },
    actions: {
        resetLog(withDefaultMessage = false) {
            this.$state.logEntrys = new Array<UserLogEntry>()

            if (withDefaultMessage == true) {
                this.$state.logEntrys.push({
                    colorClass: "color-lightblue",
                    entryType: "DATA",
                    icon: "fal fa-fw fa-check-circle",
                    showFoldableContent: false,
                    timestamp: getTimeStamp(),
                    title: T("Log initialized"),
                    payload: undefined
                })
            }
        },
        addLogEntry(
            icon: string,
            colorClass: string,
            title?: string,
            message?: UserLogWebsocketMessage
        ) {
            let entryType: "FOLDABLE" | "DATA"
            if (message) {
                entryType = "FOLDABLE"
                title = title || "{...}"
            } else {
                entryType = "DATA"
                title = title || ""
            }
            this.logEntrys.push({
                icon: icon,
                payload: message,
                entryType: entryType,
                timestamp: getTimeStamp(),
                showFoldableContent: false,
                colorClass: logOptions[colorClass] ? logOptions[colorClass] : colorClass,
                title: title
            })
        }
    }
})
