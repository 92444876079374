const permissionsHelpers = {
    getPermissionLabelFromScope: function (permissionScope: string) {
        const scopeToString: any = {
            "usc:monitoring": "USC monitoring",
            "usc:administration": "Full USC administration",
            "usc:administration:*": "Partial USC administration"
        }
        return scopeToString[permissionScope]
    }
}
export default permissionsHelpers
