<script setup lang="ts">
import { useVue } from "@/app"
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import objectStores from "@/classes/init"
import type { UscUtm } from "@/classes/unifiedSecurityConsole/uscUtms"
import dialogs from "@/dialogs/dialogs"
import getterHelpers from "@/helpers/helpers.getters"
import mixinHelpers from "@/helpers/helpers.mixins"
import tenantHelpers from "@/helpers/helpers.tenants"
import validationHelpers from "@/helpers/helpers.validation"
import requestHandler from "@/queries/requests"
import { MutationTypes, useStore } from "@/store/vuex.store"
import moment from "moment"
import { sprintf } from "sprintf-js"
import { computed, onMounted, onUnmounted, ref, watch, watchEffect } from "vue"
import loader from "../components/loader.vue"
import inputPin from "../inputtypes/input-pin.vue"

// Properies
const props = defineProps<{
    properties: {
        context: string | string[]
        utm: UscUtm
        text?: string
        payload?: any
        isCouldBackup: boolean
        ctime: string
    }
}>()

// Ref Values
const loadingUsePin = ref(true)
const loadingSystemInfo = ref(true)
const showNotification = ref(false)
const usePin = ref(<boolean | undefined>undefined)
const isPinDisabled = ref(<boolean>false)
const utmVersion = ref(<string | undefined>undefined)
const loaderText = ref(T("Waiting for UTM Info..."))
const pinEntered = ref("")
const loadAndBootConfig = ref(false)
const rebootAfterReset = ref(false)
const errors = ref(<string[]>[])

const pinStorageKey = computed(() => {
    return `utm_pin:${activeAccountId.value}:${props.properties.utm.utmId}`
})
const showRememberPin = ref(true)
const rememberPin = ref(false)

const thisDataArray = computed(() => {
    let dataArray: string[] =
        typeof props.properties.context === "string"
            ? props.properties.context.split(" ")
            : props.properties.context
    if (props.properties.payload) {
        dataArray.push(props.properties.payload)
    }
    return dataArray
})

// Computed Values
const activeAccountId = computed(() => {
    return mixinHelpers.getActiveAccountId()
})
const activeTenantDomain = computed(() => {
    return tenantHelpers.getTenantDomain(activeAccountId.value)
})

// Functions
async function submit() {
    isPinDisabled.value = true
    if (useStore().getters.getActiveModal(activeAccountId.value).buttons[1].loading == false) {
        useStore().getters.getActiveModal(activeAccountId.value).buttons[1].loading = true
        useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = true
        errors.value = []
        const payload = {
            name: "pin-spcli",
            data: {
                pin: pinEntered.value,
                command: thisDataArray.value
            }
        }
        try {
            await requestHandler.request(
                "POST",
                config.mgtApiUriNext +
                    "/tenants/" +
                    activeTenantDomain.value +
                    "/utms/" +
                    props.properties.utm.utmId +
                    "/jobs/model",
                payload
            )
            if (props.properties.isCouldBackup && loadAndBootConfig.value) {
                loadAndBootBackup()
            }
            if (
                rebootAfterReset.value == true &&
                props.properties.context == "system config factorysettings"
            ) {
                const otherPayload = {
                    name: "pin-spcli",
                    data: {
                        pin: pinEntered.value,
                        command: ["system", "poweroff"]
                    }
                }
                await requestHandler.request(
                    "POST",
                    config.mgtApiUriNext +
                        "/tenants/" +
                        activeTenantDomain.value +
                        "/utms/" +
                        props.properties.utm.utmId +
                        "/jobs/model",
                    otherPayload
                )
            }
        } catch (e: unknown) {
            if (e instanceof Error) {
                console.error(e.message)
            } else {
                console.error(e)
            }
        }
    }
}

async function loadAndBootBackup() {
    const cfgName =
        "backup-" + moment.unix(Number(props.properties.ctime)).format("Y-MM-DD_H:mm:ss")
    // Load Config
    await requestHandler.request(
        "POST",
        config.mgtApiUriNext +
            "/tenants/" +
            activeTenantDomain.value +
            "/utms/" +
            props.properties.utm.utmId +
            "/jobs/model",
        {
            name: "pin-spcli",
            data: {
                pin: pinEntered.value,
                command: ["system", "config", "load", "name", cfgName]
            }
        }
    )

    // Set Config
    await requestHandler.request(
        "POST",
        config.mgtApiUriNext +
            "/tenants/" +
            activeTenantDomain.value +
            "/utms/" +
            props.properties.utm.utmId +
            "/jobs/model",
        {
            name: "pin-spcli",
            data: {
                pin: pinEntered.value,
                command: ["system", "config", "set", "name", cfgName]
            }
        }
    )

    // Reboot
    await requestHandler.request(
        "POST",
        config.mgtApiUriNext +
            "/tenants/" +
            activeTenantDomain.value +
            "/utms/" +
            props.properties.utm.utmId +
            "/jobs/model",
        {
            name: "pin-spcli",
            data: {
                pin: pinEntered.value,
                command: ["system", "reboot"]
            }
        }
    )
}

async function init() {
    await objectStores.uscUtms.sendSpcliMessage(activeAccountId.value, props.properties.utm.utmId, {
        data: ["system", "info"]
    })
    await objectStores.uscUtms.sendSpcliMessage(activeAccountId.value, props.properties.utm.utmId, {
        data: [
            "extc",
            "value",
            "get",
            "application",
            "spcloudpuppet",
            "variable",
            "SESSIONAUTH_ENABLE"
        ]
    })

    const storageKey = sessionStorage.getItem(pinStorageKey.value)
    if (storageKey) {
        pinEntered.value = storageKey
        showRememberPin.value = false
    }

    setTimeout(() => {
        showNotification.value = true
    }, 45000)
}

async function doRequest(context: string, dataArray: string[]) {
    let utmDetailsComponent: any = useVue().$refs.utmDetails
    let result = true
    let accountId = activeAccountId.value
    context = context.replace("-", " ").replace("variable", " ")
    context = utmDetailsComponent?.contextMap[context] || context
    try {
        let result = await objectStores.uscUtms.sendSpcliMessage(
            activeAccountId.value || "",
            props.properties.utm.utmId,
            { data: dataArray }
        )

        if (result) {
            result.context = context
            result.issued = result.timestamp
            result.lastUpdate = result.timestamp
            result.queue = "out"
            result.statusText = ""
            result.type = "spcli"
            result.status = "PENDING"

            let index: number = -1

            if (utmDetailsComponent) {
                if (result.jobId) {
                    index = utmDetailsComponent?.operationsLog?.findIndex(function (entry: any) {
                        return entry.jobId == result.jobId
                    })
                }
                if (index != -1) {
                    for (let property in result) {
                        utmDetailsComponent.operationsLog[index][property] = result[property]
                    }
                } else {
                    utmDetailsComponent?.operationsLog?.unshift?.(result)
                }
            }
        }
    } catch (e: any) {
        let errorMessage = e?.message
        if (e?.data?.data?.errors?.payload) {
            errorMessage = e.data.data.errors.payload
        } else if (e?.data?.errors?.payload) {
            errorMessage = e?.data?.errors?.payload
        }
        result = false
        let errorMsg = objectStores.uscUtms.formatErrorMessage(errorMessage)
        let activeModal = useStore()?.getters.getActiveModal(accountId)
        if (activeModal != undefined) {
            useStore()?.commit(MutationTypes.removeModal, { accountId })
        }
        dialogs.misc.errorDialog(accountId, T("Error"), errorMsg)
    }
    return result
}

// Lifecicle Hooks
onMounted(() => {
    useStore().commit(MutationTypes.addSubscriptionHook, {
        accountId: activeAccountId.value,
        hookKey: "utmPinDialogs",
        hookFunction: async (message: any) => {
            if (message.topic == "/usc/utm/" + props.properties.utm.utmId + "/message") {
                let jobType: string = message.data?.type || "spcli"
                let jobContext: string =
                    message.data?.clientContext
                        .replace(jobType + "-", "")
                        .split("-")
                        .join(" ") || "UNKNOWN"
                let messageStatus: number = message.data?.status || message.data?.data?.status

                try {
                    if (
                        (messageStatus == 500 || messageStatus == 403) &&
                        (jobContext == "system reboot" ||
                            jobContext == "system config factorysettings" ||
                            jobContext == "system poweroff" ||
                            jobContext == "system cloudbackup restore")
                    ) {
                        let jobStatusText: string =
                            message.data?.data?.message ||
                            message.data?.data?.hint?.message ||
                            message.data?.data?.[0]?.message ||
                            message.data?.data?.payload?.[0]?.message ||
                            ""

                        if (jobStatusText == "locked")
                            jobStatusText = T("The UTM was locked due to too many failed attempts")
                        if (jobStatusText == "backup_restore: file already exists")
                            jobStatusText = T(
                                "The backup could not be restored, because the file already exists on the UTM."
                            )

                        if (errors.value.indexOf(T(jobStatusText)) == -1) {
                            errors.value.push(T(jobStatusText))
                            errors.value = errors.value
                        }
                        useStore().getters.getActiveModal(
                            activeAccountId.value
                        ).buttons[1].loading = false
                        useStore().getters.getActiveModal(
                            activeAccountId.value
                        ).buttons[1].disabled = false
                    }

                    if (jobContext == "extc value get") {
                        let data: any[] = message.data?.data || []
                        if (data.length && data[0].name == "SESSIONAUTH_ENABLE") {
                            usePin.value = data[0].value == "enabled"
                            loadingUsePin.value = false
                        }
                    }
                    if (jobContext == "system info") {
                        let data: any = message.data?.data || {}
                        if (data?.version != undefined) {
                            utmVersion.value = data.version
                            if (
                                validationHelpers.versionCompare(
                                    utmVersion.value || "0",
                                    "12.5.0"
                                ) === -1
                            ) {
                                usePin.value = false
                            }
                            const spcliMap: {
                                [key: string]: Function
                            } = {
                                "system upgrade rollback": () => {
                                    getterHelpers.useStore().commit(MutationTypes.removeModal, {
                                        accountId: activeAccountId.value
                                    })
                                    dialogs.misc.confirmDialog(
                                        activeAccountId.value || "",
                                        T("Rollback"),
                                        sprintf(
                                            T(
                                                "Do you really want to do a rollback to the last installed version of %s?"
                                            ),
                                            props.properties.utm.utmname
                                        ),
                                        async function () {
                                            await doRequest(
                                                props.properties.context as string,
                                                thisDataArray.value
                                            )
                                        }
                                    )
                                },
                                "system upgrade dryrun": () => {
                                    getterHelpers.useStore().commit(MutationTypes.removeModal, {
                                        accountId: activeAccountId.value
                                    })
                                    dialogs.misc.confirmDialog(
                                        activeAccountId.value || "",
                                        props.properties.utm.utmname + " - " + T("Dryrun"),
                                        sprintf(
                                            T(`Are you sure to boot the new version %s?`),
                                            objectStores.uscUtms.getUtmMessageData(
                                                props.properties.utm,
                                                "merged-update-info"
                                            )?.new
                                        ),
                                        async function () {
                                            await doRequest(
                                                props.properties.context as string,
                                                thisDataArray.value
                                            )
                                        }
                                    )
                                },
                                "system upgrade finalize": () => {
                                    getterHelpers.useStore().commit(MutationTypes.removeModal, {
                                        accountId: activeAccountId.value
                                    })
                                    dialogs.misc.confirmDialog(
                                        activeAccountId.value || "",
                                        props.properties.utm.utmname +
                                            " - " +
                                            T("Finalize new version"),
                                        sprintf(
                                            T("Are you sure to finalize the current version %s?"),
                                            objectStores.uscUtms.getUtmMessageData(
                                                props.properties.utm,
                                                "spcli-system-info"
                                            )?.version
                                        ),
                                        async function () {
                                            await doRequest(
                                                props.properties.context as string,
                                                thisDataArray.value
                                            )
                                        }
                                    )
                                }
                            }

                            if (
                                validationHelpers.versionCompare(
                                    utmVersion.value || "0",
                                    config.unifiedSecurityConsole.utmVersionForPin
                                ) === -1 &&
                                typeof props.properties.context == "string" &&
                                Object.keys(spcliMap).indexOf(props.properties.context) !== -1
                            ) {
                                spcliMap[props.properties.context]?.()
                            }
                            loadingSystemInfo.value = false
                        }
                    }
                    const jobContextArray = [
                        "system reboot",
                        "system config factorysettings",
                        "system poweroff",
                        "system cloudbackup restore"
                    ]
                    if (messageStatus == 200 && jobContextArray.indexOf(jobContext) !== -1) {
                        if (rememberPin.value === true) {
                            sessionStorage.setItem(pinStorageKey.value, pinEntered.value)
                        }

                        useStore().commit(MutationTypes.removeModal, {
                            accountId: activeAccountId.value
                        })
                    }
                } catch (e) {}
            }
        }
    })
    init()
})
const pinField = ref(<any>null)
onUnmounted(() => {
    useStore().commit(MutationTypes.deleteSubscriptionHook, {
        accountId: activeAccountId.value,
        hookKey: "utmPinDialogs"
    })
})

// Watchers
watchEffect(() => {
    if (utmVersion.value !== undefined && usePin.value !== undefined) {
        if (useStore().getters.getActiveModal(activeAccountId.value).buttons[1]) {
            useStore().getters.getActiveModal(activeAccountId.value).buttons[1].loading = false
        }
    }
})

watch(pinEntered, () => {
    if (typeof pinEntered.value === "string" && pinEntered.value.length === 6) {
        useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = false
    } else {
        useStore().getters.getActiveModal(activeAccountId.value).buttons[1].disabled = true
    }
})
watch(
    errors,
    () => {
        if (errors.value.length >= 1) {
            pinEntered.value = ""

            isPinDisabled.value = false

            setTimeout(() => {
                pinField.value?.focusInput?.()
            }, 500)
        }
    },
    {
        deep: true
    }
)

// Exposed Values
defineExpose({
    submit
})
</script>
<template>
    <div class="padding-xs-y-2">
        <template v-if="loadingSystemInfo == true || loadingUsePin == true">
            <div class="text-size-3 text-center padding-xs-t-4">
                <div class="text-size-2">
                    <loader class="color-red"></loader>
                </div>
            </div>
            <template v-if="loaderText">
                <div class="text-center padding-xs-t-2" style="opacity: 0.8">
                    <span>
                        {{ T(loaderText) }}
                    </span>
                </div>
            </template>
            <template v-if="showNotification == true">
                <div class="text-center color-red padding-xs-b-4" style="opacity: 0.8">
                    <br />
                    <span>
                        {{
                            T(
                                "Receiving the websocket messages takes unusually long. Please check your connection to the UTM."
                            )
                        }}
                    </span>
                </div>
            </template>
            <template v-else>
                <div class="padding-xs-b-4"></div>
            </template>
        </template>
        <template v-else>
            <template
                v-if="
                    usePin === false ||
                    validationHelpers.versionCompare(utmVersion || '0', '12.5.2') === -1
                "
            >
                <div class="row padding-xs-y form-group">
                    <div class="col-xs-24">
                        <p style="text-align: justify">
                            <template
                                v-if="
                                    validationHelpers.versionCompare(
                                        utmVersion || '0',
                                        '12.5.2'
                                    ) === -1
                                "
                            >
                                {{ T("This UTM does not support a PIN for authentication.") }}
                                {{
                                    T(
                                        "To increase security for actions that could disrupt ongoing operations, the new authentication layer is mandatory from UTM version 12.5.2."
                                    )
                                }}
                                {{
                                    T(
                                        "The UTM does not support this function, therefore the action is not available. Please update the UTM to a version 12.5.2 or higher if you want to use this action."
                                    )
                                }}
                            </template>
                            <template v-else>
                                {{ T("This action requires a PIN for authentication.") }}
                                {{
                                    T(
                                        "To increase security for actions that could disrupt ongoing operations, the new authentication layer is mandatory from UTM version 12.5.2."
                                    )
                                }}
                                {{
                                    T(
                                        "The PIN authentication method is disabled for this UTM and the action is therefore not available."
                                    )
                                }}
                            </template>
                        </p>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="row padding-xs-y form-group">
                    <div class="col-xs-24">
                        <p class="text-center">
                            {{ T("This UTM requires a PIN for authentication.") }}
                            {{
                                T(
                                    "To increase security for actions that could disrupt ongoing operations, the new authentication layer is mandatory from UTM version 12.5.2."
                                )
                            }}
                            {{
                                T(
                                    "The PIN is identical to the websession PIN and is initially 000000 until the first login to the administration web interface."
                                )
                            }}
                        </p>
                    </div>
                </div>
                <div class="text-center">
                    <template v-for="error in errors">
                        <p class="label bg-red">
                            <i class="fal fa-fw fa-exclamation"></i> {{ T(error) }}
                        </p>
                        <br />
                    </template>
                </div>

                <div class="row padding-xs-t form-group">
                    <div class="input col-xs-24 text-center">
                        <label class="form-field" style="display: inline-block">
                            <inputPin
                                v-model="pinEntered"
                                :pinLength="6"
                                :isDisabled="isPinDisabled"
                                :numbersOnly="true"
                                v-on:submit="submit"
                                :focusOnMount="true"
                                ref="pinField"
                                :hidePinOnMount="true"
                                :canToggleVisibility="true"
                            ></inputPin>
                        </label>
                    </div>
                </div>
                <div class="row padding-xs-t">
                    <div class="col-xs-24 text-center">
                        <p class="text-italic">
                            {{
                                T(
                                    "To increase security, the new authentication layer is mandatory from UTM version 12.5.2."
                                )
                            }}
                        </p>
                        <label v-if="showRememberPin" class="form-field toggle checkbox">
                            <input
                                v-model="rememberPin"
                                id="login-rememberme"
                                type="checkbox"
                                class="toggle margin-xs-0"
                            />&nbsp;&nbsp;
                            <span></span>
                            {{ T("Remember PIN for the current session") }}
                        </label>
                    </div>
                </div>

                <div class="row padding-xs-t form-group" v-if="props.properties.isCouldBackup">
                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                        <label for="bootconfig" class="control-label">
                            {{ T("Boot") }}
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                        <label class="checkbox toggle">
                            <input class="toggle" type="checkbox" v-model="loadAndBootConfig" />
                            <span></span>
                        </label>
                    </div>
                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                        <p class="input-description">
                            {{ T("Load configuration, set as bootconfiguration and reboot.") }}
                        </p>
                    </div>
                </div>
                <div
                    class="row padding-xs-t form-group"
                    v-if="props.properties.context == 'system config factorysettings'"
                >
                    <div class="first col-xs-24 col-lg-6 col-xl-5">
                        <label for="Port" class="control-label">
                            {{ T("Shut down") }}
                        </label>
                    </div>
                    <div class="input col-xs-24 col-lg-8 col-xl-7">
                        <label class="checkbox toggle">
                            <input class="toggle" type="checkbox" v-model="rebootAfterReset" />
                            <span></span>
                        </label>
                    </div>
                    <div class="desc col-xs-24 col-lg-10 col-xl-12">
                        <p class="input-description">
                            {{
                                T(
                                    "If you do not shut down the UTM after restoring the factory settings, malfunctions may occur. Further information can be found in the Wiki."
                                )
                            }}
                        </p>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>
