import i18n, { T } from "@/classes/i18n"
import encodingHelpers from "@/helpers/helpers.encoding"
import queries from "@/queries/queries"
import { ActionTypes, MutationTypes, useStore } from "@/store/vuex.store"
import { nextTick } from "vue"
import inputVueSelectVue from "./input-vue-select.vue"

const inputGoogleIframeComponent = {
    name: "input-google-iframe",
    template: `
            <div>
                <template v-if="type == 'appSearch'">
                    <div style="display:flex">
                        <div style=" max-width:calc(100% - 180px);flex-grow: 1;">
                            <input-vue-select
                                v-model="componentValue"
                                :placeholder="T('Enter packagename')"
                                :selectOptions="packagenames"
                                class="limit"
                                ref="appselector"
                                :tags="true"
                            />
                        </div>
                        <template v-if="!(exception == 'securepoint_app_config' && modelValue == 'de.securepoint.ms.agent')">
                            <a class="btn" v-on:click="buttonHandler()" :disabled="disabled || null">
                                <i :class="button.icon"></i>&nbsp;&nbsp;<span>{{ T(button.text) }}</span>
                            </a>
                        </template>
                    </div>
                    <template v-if="exception == 'securepoint_app_config' && modelValue == 'de.securepoint.ms.agent'">
                        <p>
                            <a class="btn" v-on:click="switchToTab('tab_enterprise_toolbox_location')" :disabled="disabled || null">
                                <i class="fal fa-cog"></i>&nbsp;&nbsp;{{T('Configure application')}}
                            </a>
                        </p>
                    </template>
                </template>
                <template v-else-if="type == 'managedConfigurationTemplate'">
                    <div>
                        <pre v-if="componentValue != null" style="width:100%; flex-grow:1">{{ formattedObject(componentValue) }}</pre>
                        <a class="btn" v-on:click="buttonHandler()" :disabled="disabled || null">
                            <i :class="button.icon"></i>&nbsp;&nbsp;<span>{{ T(button.text) }}</span>
                        </a>
                        <a v-if="componentValue != null" class="btn" v-on:click="resetValue()">
                            <i class="fal fa-trash"></i>
                        </a>
                    </div>
                </template>

            </div>
        `,
    data: function () {
        return {
            componentValue: null,
            initialized: false,
            destroyed: false,
            packagenames: []
        }
    },
    props: {
        modelValue: {
            required: true,
            default: null
        },
        type: {
            required: true,
            default: null
        },
        button: {
            required: true,
            default: function () {
                return {
                    icon: "fal",
                    text: ""
                }
            }
        },
        openInModal: {
            required: false,
            default: true
        },
        packageName: {
            required: false,
            default: null
        },
        exception: {
            required: false,
            default: null
        },
        onchange: {
            required: false,
            default: null
        },
        disabled: {
            required: false,
            default: false
        }
    },
    methods: {
        T: function (content: string) {
            return T(content)
        },
        resetValue: function (this: any) {
            let thisComponent: any = this
            switch (this.type) {
                case "appSearch":
                    break
                case "managedConfigurationTemplate":
                    thisComponent.componentValue = null
                    let $event: any = {
                        target: {
                            type: "managedConfiguration",
                            value: null
                        }
                    }
                    thisComponent.$parent.execFunction(thisComponent.onchange, null, $event)
                    break
            }
        },
        select2Settings: function () {
            return { selectOnClose: true, tags: true }
        },
        formattedObject: function (content: any) {
            return JSON.stringify(content, null, 4)
        },
        switchToTab: function (this: any, tabname: string) {
            if (this.$root.$refs.editpage) {
                if (this.$root.$refs.editpage.form.fields[0].activeTab) {
                    this.$root.$refs.editpage.form.fields[0].activeTab = tabname
                }
            }
        },
        buttonException: function (this: any, componentValue: any, exception: any) {
            let result: boolean = false
            if (
                exception == "securepoint_app_config" &&
                this.modelValue == "de.securepoint.ms.agent"
            ) {
                result = true
            }
            return result
        },
        buttonHandler: async function (this: any) {
            let thisComponent: any = this
            let webToken = await await queries.mobileSecurity.getEnterpriseWebtoken(
                useStore().state.session.activeAccountId || ""
            )

            if (webToken?.value) {
                webToken = webToken.value
            }

            let newModal = {}
            switch (this.type) {
                case "appSearch":
                    newModal = {
                        accountId: useStore().state.session.activeAccountId,
                        id: "appSelect",
                        abortable: true,
                        content: {
                            title: {
                                text: T("Select application"),
                                icon: "fal fa-search"
                            },
                            body: {
                                use: false,
                                content: "<div id='googleIframe' style='max-height:480px'></div>",
                                component: undefined
                            }
                        },
                        buttons: []
                    }
                    useStore().dispatch(ActionTypes.addModal, newModal)

                    nextTick(function () {
                        gapi.load("gapi.iframes", function () {
                            let languageTag: any = i18n.getLanguage() == "en" ? "en_US" : "de_DE"
                            var options = {
                                url:
                                    "https://play.google.com/work/embedded/search?token=" +
                                    webToken +
                                    "&mode=SELECT&locale=" +
                                    languageTag,
                                where: document.getElementById("googleIframe"),
                                attributes: {
                                    style: "height:480px;display:block",
                                    scrolling: "yes"
                                }
                            }
                            var iframe = (<any>gapi).iframes.getContext().openChild(options)

                            iframe.register(
                                "onproductselect",
                                function (event: any) {
                                    thisComponent.componentValue = event.packageName
                                    if (thisComponent.onchange) {
                                        let $event: any = {
                                            target: {
                                                type: "appSearch",
                                                value: event.packageName
                                            }
                                        }
                                        thisComponent.$parent.execFunction(
                                            thisComponent.onchange,
                                            null,
                                            $event
                                        )
                                    }
                                },
                                (<any>gapi).iframes.CROSS_ORIGIN_IFRAMES_FILTER
                            )
                        })
                    })
                    break
                case "managedConfigurationTemplate":
                    let packageName: any = this.$parent.getPackageNameFromInput(
                        this.packageName
                    ).value

                    newModal = {
                        accountId: useStore().state.session.activeAccountId,
                        id: "managedConfig",
                        abortable: true,
                        content: {
                            title: {
                                text: T("Managed Configuration"),
                                icon: "fal fa-search"
                            },
                            body: {
                                use: false,
                                content: "<div id='googleIframe' style='max-height:480px'></div>",
                                component: undefined
                            }
                        },
                        buttons: []
                    }
                    useStore().dispatch(ActionTypes.addModal, newModal)

                    nextTick(function () {
                        gapi.load("gapi.iframes", function () {
                            if (thisComponent.componentValue != null) {
                                var options = {
                                    url:
                                        "https://play.google.com/managed/mcm?token=" +
                                        webToken +
                                        "&packageName=" +
                                        packageName +
                                        "&mcmId=" +
                                        thisComponent.componentValue.templateId +
                                        "&locale=" +
                                        i18n.getLanguage(),
                                    where: document.getElementById("googleIframe"),
                                    attributes: {
                                        style: "height:480px;display:block",
                                        scrolling: "yes"
                                    }
                                }
                            } else {
                                var options = {
                                    url:
                                        "https://play.google.com/managed/mcm?token=" +
                                        webToken +
                                        "&packageName=" +
                                        packageName +
                                        "&locale=" +
                                        i18n.getLanguage(),
                                    where: document.getElementById("googleIframe"),
                                    attributes: {
                                        style: "height:480px;display:block",
                                        scrolling: "yes"
                                    }
                                }
                            }
                            var iframe = (<any>gapi).iframes.getContext().openChild(options)
                            iframe.register(
                                "onconfigupdated",
                                function (event: any) {
                                    thisComponent.componentValue = { templateId: event.mcmId }
                                    if (thisComponent.onchange) {
                                        let $event: any = {
                                            target: {
                                                type: "managedConfiguration",
                                                value: event.mcmId
                                            }
                                        }
                                        thisComponent.$parent.execFunction(
                                            thisComponent.onchange,
                                            null,
                                            $event
                                        )
                                    }
                                },
                                (<any>gapi).iframes.CROSS_ORIGIN_IFRAMES_FILTER
                            )
                        })
                    })
                    break
            }
        },
        updateSelectValue: function (this: any, value: string) {
            let thisComponent: any = this
            if (this.type == "appSearch") {
                if (this.$refs.appselector && value) {
                    if (
                        $(this.$refs.appselector.$el).find("option[value='" + value + "']").length
                    ) {
                        $(this.$refs.appselector.$el).val(value).trigger("change")
                    } else {
                    }
                } else if (!this.$refs.appselector && value && !this.destroyed) {
                    setTimeout(function () {
                        thisComponent.updateSelectValue(value)
                    }, 1000)
                }
            }
        }
    },
    created: function (this: any) {
        let packagenameOptions: any[] = []
        for (let i = 0; useStore().state.resources.packagenames.packagenames.length > i; i++) {
            let packagename: any = useStore().state.resources.packagenames.packagenames[i]
            packagenameOptions.push({
                id: packagename.packageName,
                text: packagename.title + " (" + packagename.packageName + ")"
            })
        }
        this.packagenames = packagenameOptions

        if (this.modelValue && !this.initialized) {
            this.componentValue = this.modelValue
            this.updateSelectValue(this.modelValue)
            this.initialized = true
        }
    },
    mounted: function (this: any) {
        if (this.modelValue && !this.initialized) {
            this.componentValue = this.modelValue
            this.updateSelectValue(this.modelValue)
            this.initialized = true
        }
    },
    watch: {
        componentValue: {
            handler: async function (this: any, newValue: any, oldValue: any) {
                useStore().commit(MutationTypes.removeModal, {
                    accountId: useStore().state.session.activeAccountId
                })

                this.$emit("update:modelValue", newValue)
                if (this.type == "appSearch" && newValue) {
                    if (this.$parent.fields != undefined) {
                        if (this.$parent.fields[0].property == "packageName") {
                            if (this.$parent.getTitleFrom) {
                                for (let i in this.$parent.thisField.fields) {
                                    let field: any = this.$parent.thisField.fields[i]
                                    if (field.property == this.$parent.getTitleFrom) {
                                        if (newValue) {
                                            this.$parent.thisField.title =
                                                encodingHelpers.unescapeHTML(newValue)
                                        }
                                    }
                                }
                            }

                            let result: any = null
                            if (this.$parent.fields[0].appTitle.length == 0 || oldValue != null) {
                                try {
                                    this.$parent.fields[0].permissions = []
                                    result = await queries.mobileSecurity.getEnterpriseAppInfo(
                                        useStore().state.session.activeAccountId || "",
                                        newValue
                                    )
                                    let permissions: any = result.permissions
                                    let appTracks: any = result.appTracks
                                    let appTitle: any = encodingHelpers.unescapeHTML(result.title)

                                    this.$parent.fields[0].appTitle = appTitle

                                    this.$root.$refs.editpage.updateOptionsOfField(
                                        "disallowedApplications",
                                        [
                                            {
                                                id: newValue,
                                                text: appTitle + " " + "(" + newValue + ")"
                                            }
                                        ]
                                    )

                                    if (appTracks && appTracks.length) {
                                        for (let fieldIndex in this.$parent.fields) {
                                            let field: any = this.$parent.fields[fieldIndex]
                                            if (field.id == "accessibleTrackIds") {
                                                let addThisTracks: any[] = appTracks.filter(
                                                    function (appTrack: any) {
                                                        let searchId: string = appTrack.trackId

                                                        let foundOption: boolean =
                                                            field.options.filter(function (
                                                                option: any
                                                            ) {
                                                                return option.id == searchId
                                                            }).length > 0
                                                        return !foundOption
                                                    }
                                                )

                                                for (
                                                    let i: number = 0;
                                                    addThisTracks.length > i;
                                                    i++
                                                ) {
                                                    let appTrack: any = addThisTracks[i]
                                                    if (!Array.isArray(field.options)) {
                                                        field.options = []
                                                    }
                                                    field.options.push({
                                                        id: appTrack.trackId,
                                                        text: appTrack.trackAlias
                                                    })
                                                }
                                                field.disabled = false
                                                break
                                            }
                                        }
                                    }

                                    for (let i in permissions) {
                                        let permission: any = permissions[i]
                                        this.$parent.fields[0].permissions.push({
                                            id: permission.permissionId,
                                            text: permission.permissionId
                                        })
                                    }
                                } catch (e: any) {
                                    console.error("got no result for app")
                                }
                            }
                        }
                    }
                }
            },
            deep: true
        },
        modelValue: {
            handler: function (this: any, value: any) {
                if (value && !this.initialized) {
                    this.componentValue = this.modelValue
                    this.initialized = true
                }
                if (value && this.type == "appSearch") {
                    this.updateSelectValue(this.modelValue)
                }
            }
        }
    },
    beforeCreate(this: any) {},
    beforeUnmount(this: any) {
        this.destroyed = true
    },
    destroyed: function () {},
    components: {
        "input-vue-select": inputVueSelectVue
    }
}
export default inputGoogleIframeComponent
