import i18n from "@/classes/i18n"
import AirDatepicker, { type AirDatepickerOptions } from "air-datepicker"
import "air-datepicker/air-datepicker.css"
import localeDe from "air-datepicker/locale/de"
import localeEn from "air-datepicker/locale/en"
import moment from "moment"

const inputGoogleDateComponent = {
    name: "input-googledate",
    template: `
        <div>
            <input
                type="text"
                v-bind="$attrs"
                :value="thisValue"
                style="width:100%"
                :placeholder="placeholder"
            />
        </div>
            `,
    data: function (this: any) {
        return {
            initialized: false,
            thisValue: undefined
        }
    },
    props: {
        modelValue: {
            required: true,
            default: () => {
                return {
                    day: 0,
                    month: 0,
                    year: 0
                }
            }
        },
        id: {
            required: false,
            default: undefined
        },
        placeholder: {
            required: false,
            default: undefined
        }
    },
    computed: {},
    methods: {
        init: function (this: any) {
            let thisComponent: any = this
            let input: any = $(this.$el).find("input")[0]
            let options: Partial<AirDatepickerOptions> = {
                locale: i18n.getLanguage() == "de" ? localeDe : localeEn,
                dateFormat: "d.M.yyyy",
                onSelect: function ({ date, formattedDate, datepicker }) {
                    thisComponent.$emit("update:modelValue", {
                        day: parseInt(moment(date).format("D")),
                        month: parseInt(moment(date).format("M")),
                        year: parseInt(moment(date).format("YYYY"))
                    })
                    thisComponent.thisValue = formattedDate
                }
            }

            if (
                this.modelValue.day != 0 &&
                this.modelValue.month != 0 &&
                this.modelValue.year != 0
            ) {
                this.thisValue =
                    this.modelValue.day + "." + this.modelValue.month + "." + this.modelValue.year
                options.startDate = moment([
                    this.modelValue.year,
                    this.modelValue.month - 1,
                    this.modelValue.day
                ]).toDate()
            }

            if (["startDateFreezePeriod", "endDateFreezePeriod"].indexOf(this.id) != -1) {
                if (this.modelValue.day != 0 && this.modelValue.month != 0) {
                    this.thisValue = this.modelValue.day + "." + this.modelValue.month + "."
                    options.startDate = moment([
                        moment().year(),
                        this.modelValue.month - 1,
                        this.modelValue.day
                    ]).toDate()
                }
                options.dateFormat = "d.M."
                options.showOtherYears = false
                options.onSelect = function ({ date, formattedDate, datepicker }) {
                    thisComponent.$emit("update:modelValue", {
                        day: parseInt(moment(date).format("D")),
                        month: parseInt(moment(date).format("M")),
                        year: 0
                    })
                    thisComponent.thisValue = formattedDate
                }
                options.classes = "hideYears"
            }
            new AirDatepicker(input, options)
        }
    },
    mounted: function (this: any) {
        this.init()
    },
    destroyed: function (this: any) {},
    watch: {}
}
export default inputGoogleDateComponent
