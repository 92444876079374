import tenantHelpers from "../helpers/helpers.tenants"
import config from "./config"
import devLog from "./log"
interface HookEntry {
    id: string
    callback: (event: any) => void
}
const envVars = import.meta.env
class WebsocketHandler {
    public connection: WebSocket | undefined
    private hooks: Array<HookEntry>
    private accountId: string | undefined
    interval: any

    constructor() {
        this.hooks = new Array<HookEntry>()
        return this
    }
    public connect(accountId: string) {
        this.closeConnection() // close connection
        if (accountId) {
            this.accountId = accountId
            const wsURL = config.websocketApiUri
                .replace("{hostname}", document.location.host)
                .replace("{tenantDomain}", tenantHelpers.getTenantDomain(accountId))
                .replace("wss://", envVars.VITE_MODE == "local" ? "ws://" : "wss://")
            this.connection = new WebSocket(wsURL)
            this.connection.onmessage = (event) => {
                this.onEvent(event)
            }
            this.connection.onopen = (event: any) => {
                devLog.log("Websocket", "connected", this.connection, "success")
                this.sendMessage(JSON.stringify({ topic: "/ping" }))
            }
            this.connection.onclose = (event: any) => {
                devLog.log("Websocket", "disconnected", event, "error")
            }
        }
    }

    private onEvent(message: MessageEvent<any>) {
        const msg = typeof message.data == "string" ? JSON.parse(message.data) : message.data
        devLog.log("Websocket", "Message", msg, "info", 2)

        if (this.hooks)
            this.hooks.forEach((hook) => {
                hook.callback(msg)
            })
    }
    public fakeIncomingMessage(message: any) {
        this.onEvent(message)
    }
    public sendMessage(message: any) {
        this.connection?.send(message)
    }
    public closeConnection() {
        clearInterval(this.interval)
        this.connection?.close()
        this.connection = undefined
        this.accountId = undefined
    }

    public deleteAllHooks() {
        this.hooks = new Array<HookEntry>()
    }

    public getConnectionInstance(): WebsocketHandler {
        return this
    }

    public getActiveAccountId() {
        return this.accountId
    }

    /**
     * Add a hook to WS
     */
    public addOnMessageHook(id: string, callback: (event: any) => void) {
        this.hooks.push({ id, callback })
    }
    /**
     * remove a hook from WS by id
     */
    public removeOnMessageHook(id: string) {
        this.hooks = this.hooks.filter((hook) => {
            return hook.id != id
        })
    }
    /**
     * Check if hook already exists
     */
    public hasHookWithId(id: string) {
        return this.hooks.some((hook) => {
            return hook.id == id
        })
    }
}
const websocketHandler = new WebsocketHandler()
export default websocketHandler
