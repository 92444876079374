import { useVue } from "@/app"
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import products from "@/classes/objectTypes"
import vppApi from "@/classes/objectTypes/mobileSecurity/apple/vpp"
import { useUserLogStore } from "@/classes/userLogStore"
import arrayHelpers from "@/helpers/helpers.arrays"
import getterHelpers from "@/helpers/helpers.getters"
import jsonHelpers from "@/helpers/helpers.json"
import tenantHelpers from "@/helpers/helpers.tenants"
import queries from "@/queries/queries"
import requestHandler from "@/queries/requests"
import type { ProductType } from "@/resources/registeredProducts"
import router from "@/router/router"
import { ActionTypes, MutationTypes, useStore } from "@/store/vuex.store"
import { sprintf } from "sprintf-js"
import type { AccountId } from "../../../types/vuex"
import devLog from "../classes/log"
import dialogs from "./dialogs"

const mobileSecurityDialogs = {
    assignDeviceOwnership: function (
        accountid: string,
        productType: string,
        objectType: string,
        objectId: string,
        item?: any
    ) {
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore().getters.hasAccountid(accountid) == false) {
                throw "Wrong accountid, stopping process"
            }

            useStore().dispatch(ActionTypes.addModal, {
                id: accountid + "deviceSetup" + objectId,
                accountId: accountid,
                abortable: true,
                content: {
                    title: {
                        text:
                            objectType == "androidDevices" ||
                            objectType == "enterpriseDevices" ||
                            objectType == "devicesConsent" ||
                            objectType == "managedAppleIdDevices"
                                ? "License agreement"
                                : "Device ownership and license agreement",
                        icon: "fal fa-mobile-alt"
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component: "device-setup-dialog",
                        properties: {
                            accountid: accountid,
                            accountId: accountid,
                            productType: productType,
                            objectType: objectType,
                            objectId: objectId,
                            item: item
                        }
                    }
                },
                buttons: [
                    {
                        loading: false,
                        onClick: function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal, {
                                accountId: tenantHelpers.getAccountId(accountid)
                            })
                        },
                        icon: "fal fa-times",
                        text: T("Cancel"),
                        align: "left",
                        disabled: false
                    },
                    {
                        loading: false,
                        onClick: async function () {
                            this.loading = true
                            useStore().state.session.accounts[
                                accountid
                            ].modals[0].buttons[0].loading = true
                            useStore().state.session.accounts[accountid].modals[0].abortable = false
                            let ownership: any = jsonHelpers.getObjectProperty(
                                useVue(),
                                "$refs.modals.$refs.modal.$refs.modalComponent.ownership"
                            )
                            let username: string = jsonHelpers.getObjectProperty(
                                useVue(),
                                "$refs.modals.$refs.modal.$refs.modalComponent.user.value"
                            )
                            let license: string = jsonHelpers.getObjectProperty(
                                useVue(),
                                "$refs.modals.$refs.modal.$refs.modalComponent.license.value"
                            )
                            let agreed: boolean = jsonHelpers.getObjectProperty(
                                useVue(),
                                "$refs.modals.$refs.modal.$refs.modalComponent.agreed"
                            )

                            username = username === "#" ? "" : username

                            if (agreed) {
                                if (objectType == "androidDevices") {
                                    let consent =
                                        await products.mobileSecurity.androidDevices.queries.updateObjectPropertiesFromApi(
                                            accountid,
                                            objectId,
                                            { consent: config.devicesConsentDate },
                                            "consent",
                                            undefined,
                                            undefined,
                                            "/properties?updateMask[]=consent"
                                        )
                                    if (consent === true) {
                                        router.navigate(
                                            "#show-tenant-" +
                                                accountid +
                                                ".sms-android-device-" +
                                                objectId +
                                                "-details"
                                        )
                                    }
                                } else if (objectType == "enterpriseDevices") {
                                    let consent = await useStore().dispatch(
                                        ActionTypes.updateObjectProperty,
                                        {
                                            accountId: accountid,
                                            productType: "mobileSecurity",
                                            objectType: "enterpriseDevices",
                                            queryType: "enterpriseDevices",
                                            objectId: objectId,
                                            property: "consent",
                                            object: { consent: config.devicesConsentDate },
                                            objectPropertyPath: [],
                                            value: config.devicesConsentDate
                                        }
                                    )
                                    if (consent === true) {
                                        router.navigate(
                                            "#show-tenant-" +
                                                accountid +
                                                ".sms-android-device-" +
                                                objectId +
                                                "-details"
                                        )
                                    }
                                } else if (objectType == "devicesConsent") {
                                    let consent = false
                                    if (config.canUseNewObjectType("iosDevices")) {
                                        let thisResult: boolean | Error =
                                            await products.mobileSecurity.iosDevices.queries.updateObjectPropertiesFromApi(
                                                accountid,
                                                objectId,
                                                { consent: config.devicesConsentDate },
                                                "consent",
                                                [],
                                                undefined,
                                                "/properties/consent"
                                            )
                                        if (thisResult !== true) {
                                            thisResult = false
                                        }
                                        consent = thisResult
                                    } else {
                                        consent = await useStore().dispatch(
                                            ActionTypes.updateObjectProperty,
                                            {
                                                accountId: accountid,
                                                productType: "mobileSecurity",
                                                objectType: "devices",
                                                queryType: "deviceProperties",
                                                objectId: objectId,
                                                property: "consent",
                                                object: { consent: config.devicesConsentDate },
                                                objectPropertyPath: [],
                                                value: config.devicesConsentDate
                                            }
                                        )
                                    }
                                    if (consent === true) {
                                        router.navigate(
                                            "#show-tenant-" +
                                                accountid +
                                                ".sms-ios-device-" +
                                                objectId +
                                                "-details"
                                        )
                                    }
                                } else if (objectType == "managedAppleIdDevices") {
                                    let consent = false
                                    if (config.canUseNewObjectType("iosDevices")) {
                                        let thisResult: boolean | Error =
                                            await products.mobileSecurity.iosDevices.queries.updateObjectPropertiesFromApi(
                                                accountid,
                                                objectId,
                                                { consent: config.devicesConsentDate },
                                                "consent",
                                                [],
                                                undefined,
                                                "/properties/consent"
                                            )
                                        if (thisResult !== true) {
                                            thisResult = false
                                        }
                                        consent = thisResult
                                    } else {
                                        consent = await useStore().dispatch(
                                            ActionTypes.updateObjectProperty,
                                            {
                                                accountId: accountid,
                                                productType: "mobileSecurity",
                                                objectType: "devices",
                                                queryType: "deviceProperties",
                                                objectId: objectId,
                                                property: "consent",
                                                object: { consent: config.devicesConsentDate },
                                                objectPropertyPath: [],
                                                value: config.devicesConsentDate
                                            }
                                        )
                                    }
                                    if (!item?.licenseUUID) {
                                        if (config.canUseNewObjectType("iosDevices")) {
                                            await products.mobileSecurity.iosDevices.queries.updateObjectPropertiesFromApi(
                                                accountid,
                                                objectId,
                                                { licenseUUID: license },
                                                "licenseUUID",
                                                [],
                                                undefined,
                                                "/properties/licenseUUID"
                                            )
                                        } else {
                                            await useStore().dispatch(
                                                ActionTypes.updateObjectProperty,
                                                {
                                                    accountId: accountid,
                                                    productType: "mobileSecurity",
                                                    objectType: "devices",
                                                    queryType: "deviceProperties",
                                                    objectId: objectId,
                                                    property: "licenseUUID",
                                                    object: { licenseUUID: license },
                                                    value: license
                                                }
                                            )
                                        }
                                    }
                                    let result = false
                                    if (item.ownership != ownership) {
                                        try {
                                            if (config.canUseNewObjectType("iosDevices")) {
                                                let thisResult: boolean | Error =
                                                    await products.mobileSecurity.iosDevices.queries.updateObjectPropertiesFromApi(
                                                        accountid,
                                                        objectId,
                                                        { ownership: "BYOD" },
                                                        "ownership",
                                                        [],
                                                        undefined,
                                                        "/properties/ownership"
                                                    )
                                                if (thisResult !== true) {
                                                    thisResult = false
                                                }
                                                result = thisResult
                                            } else {
                                                result = await useStore().dispatch(
                                                    ActionTypes.updateObjectProperty,
                                                    {
                                                        accountId: accountid,
                                                        productType: "mobileSecurity",
                                                        objectType: "devices",
                                                        queryType: "deviceProperties",
                                                        objectId: objectId,
                                                        property: "ownership",
                                                        object: { ownership: "BYOD" },
                                                        value: "BYOD"
                                                    }
                                                )
                                            }
                                        } catch (e) {
                                            console.error(e)
                                            result = true
                                        }
                                    } else {
                                        result = true
                                    }

                                    if (result === true) {
                                        if (config.canUseNewObjectType("iosDevices")) {
                                            products.mobileSecurity.iosDevices
                                                .useStore?.()
                                                .setObjectTypeObjectProperty(
                                                    accountid,
                                                    objectId,
                                                    true,
                                                    "configured"
                                                )
                                        } else {
                                            useStore().commit(MutationTypes.setObjectProperty, {
                                                accountId: accountid,
                                                productType: "mobileSecurity",
                                                objectType: "devices",
                                                objectId: objectId,
                                                property: "configured",
                                                objectPropertyPath: undefined,
                                                value: true
                                            })
                                        }
                                        router.navigate(
                                            "#show-tenant-" +
                                                accountid +
                                                ".sms-ios-device-" +
                                                objectId +
                                                "-details"
                                        )
                                    }
                                } else {
                                    let consent = false
                                    if (config.canUseNewObjectType("iosDevices")) {
                                        let thisResult: boolean | Error =
                                            await products.mobileSecurity.iosDevices.queries.updateObjectPropertiesFromApi(
                                                accountid,
                                                objectId,
                                                { consent: config.devicesConsentDate },
                                                "consent",
                                                [],
                                                undefined,
                                                "/properties/consent"
                                            )
                                        if (thisResult !== true) {
                                            thisResult = false
                                        }
                                        consent = thisResult
                                    } else {
                                        consent = await useStore().dispatch(
                                            ActionTypes.updateObjectProperty,
                                            {
                                                accountId: accountid,
                                                productType: "mobileSecurity",
                                                objectType: "devices",
                                                queryType: "deviceProperties",
                                                objectId: objectId,
                                                property: "consent",
                                                object: { consent: config.devicesConsentDate },
                                                objectPropertyPath: undefined,
                                                value: config.devicesConsentDate
                                            }
                                        )
                                    }
                                    if (!item?.licenseUUID) {
                                        if (config.canUseNewObjectType("iosDevices")) {
                                            await products.mobileSecurity.iosDevices.queries.updateObjectPropertiesFromApi(
                                                accountid,
                                                objectId,
                                                { licenseUUID: license },
                                                "licenseUUID",
                                                [],
                                                undefined,
                                                "/properties/licenseUUID"
                                            )
                                        } else {
                                            await useStore().dispatch(
                                                ActionTypes.updateObjectProperty,
                                                {
                                                    accountId: accountid,
                                                    productType: "mobileSecurity",
                                                    objectType: "devices",
                                                    queryType: "deviceProperties",
                                                    objectId: objectId,
                                                    property: "licenseUUID",
                                                    object: { licenseUUID: license },
                                                    value: license
                                                }
                                            )
                                        }
                                    }
                                    if (ownership == "BYOD") {
                                        if (config.canUseNewObjectType("iosDevices")) {
                                            await products.mobileSecurity.iosDevices.queries.updateObjectPropertiesFromApi(
                                                accountid,
                                                objectId,
                                                { username: username },
                                                "username",
                                                [],
                                                undefined,
                                                "/properties/username"
                                            )
                                        } else {
                                            await useStore().dispatch(
                                                ActionTypes.updateObjectProperty,
                                                {
                                                    accountId: accountid,
                                                    productType: "mobileSecurity",
                                                    objectType: "devices",
                                                    queryType: "deviceProperties",
                                                    objectId: objectId,
                                                    property: "username",
                                                    object: { username: username },
                                                    value: username
                                                }
                                            )
                                        }
                                    }
                                    let result = false
                                    if (item.ownership != ownership) {
                                        try {
                                            if (config.canUseNewObjectType("iosDevices")) {
                                                let thisResult: boolean | Error =
                                                    await products.mobileSecurity.iosDevices.queries.updateObjectPropertiesFromApi(
                                                        accountid,
                                                        objectId,
                                                        { ownership: ownership },
                                                        "ownership",
                                                        [],
                                                        undefined,
                                                        "/properties/ownership"
                                                    )
                                                if (thisResult !== true) {
                                                    thisResult = false
                                                }
                                                result = thisResult
                                            } else {
                                                result = await useStore().dispatch(
                                                    ActionTypes.updateObjectProperty,
                                                    {
                                                        accountId: accountid,
                                                        productType: "mobileSecurity",
                                                        objectType: "devices",
                                                        queryType: "deviceProperties",
                                                        objectId: objectId,
                                                        property: "ownership",
                                                        object: { ownership: ownership },
                                                        value: ownership
                                                    }
                                                )
                                            }
                                        } catch (e) {
                                            console.error(e)
                                            result = true
                                        }
                                    } else {
                                        result = true
                                    }
                                    if (result === true) {
                                        if (config.canUseNewObjectType("iosDevices")) {
                                            products.mobileSecurity.iosDevices
                                                .useStore?.()
                                                .setObjectTypeObjectProperty(
                                                    accountid,
                                                    objectId,
                                                    true,
                                                    "configured"
                                                )
                                        } else {
                                            useStore().commit(MutationTypes.setObjectProperty, {
                                                accountId: accountid,
                                                productType: "mobileSecurity",
                                                objectType: "devices",
                                                objectId: objectId,
                                                property: "configured",
                                                objectPropertyPath: undefined,
                                                value: true
                                            })
                                        }

                                        if (item?.signedIn) {
                                            router.navigate(
                                                "#show-tenant-" +
                                                    accountid +
                                                    ".sms-ios-device-" +
                                                    objectId +
                                                    "-details"
                                            )
                                        }
                                    }
                                }
                            }
                            getterHelpers.useStore().commit(MutationTypes.removeModal, {
                                accountId: tenantHelpers.getAccountId(accountid)
                            })
                        },
                        icon: "fal fa-check",
                        text: T("Confirm"),
                        align: "right",
                        disabled: true
                    }
                ]
            })
        } catch (e: any) {
            devLog.log("dialog", e, undefined, "error", 0)
        }
    },

    setApplePushCertificate: function (accountid: string, update: boolean = false) {
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore().getters.hasAccountid(accountid) == false) {
                throw "Wrong accountid, stopping process"
            }
            let modal: any = {
                id: "setApplePushCertificate",
                abortable: true,
                content: {
                    title: {
                        text: update
                            ? T("Renew Apple Push Certificate")
                            : T("Apple Push Certificate generation"),
                        icon: update ? "fal fa-sync-alt" : "fal fa-plus"
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component: "apple-push-cert-dialog",
                        properties: {
                            accountId: accountid,
                            update: update
                        }
                    }
                },
                buttons: [
                    {
                        text: T("Cancel"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "left",
                        loading: false
                    },
                    {
                        text: T("Done"),
                        icon: "fal fa-check",
                        onClick: async function () {
                            this.loading = true
                            await useStore().dispatch(ActionTypes.updateAccount, accountid)
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "right",
                        disabled: false,
                        loading: false
                    }
                ]
            }
            useVue().$refs.modals.addModal(modal)
        } catch (e: any) {
            devLog.log("dialog", e, undefined, "error", 0)
        }
    },

    setAppleDepDialog: function (accountid: string, type: string = "add") {
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore().getters.hasAccountid(accountid) == false) {
                throw "Wrong accountid, stopping process"
            }
            let title: string =
                type == "expired"
                    ? T("Your Apple DEP-Token has expired")
                    : T("Apple Device Enrollment Program")

            let modal: any = {
                id: "setAppleDepDialog",
                abortable: true,
                content: {
                    title: {
                        text: title,
                        icon:
                            type == "expired" || type == "update"
                                ? "fal fa-sync-alt"
                                : "fal fa-plus"
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component: "apple-dep-dialog",
                        properties: {
                            accountid: accountid,
                            type: type
                        }
                    }
                },
                buttons: [
                    {
                        text: T("Cancel"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "left",
                        loading: false
                    },
                    {
                        text: T("Done"),
                        icon: "fal fa-check",
                        onClick: async function () {
                            this.loading = true
                            await useStore().dispatch(ActionTypes.updateAccount, accountid)
                            location.reload()
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "right",
                        disabled: false,
                        loading: false
                    }
                ]
            }
            useVue().$refs.modals.addModal(modal)
        } catch (e: any) {
            devLog.log("dialog", e, undefined, "error", 0)
        }
    },
    setAppleVPPDialog: function (accountid: string, type: string = "add") {
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore().getters.hasAccountid(accountid) == false) {
                throw "Wrong accountid, stopping process"
            }
            let title: string =
                type == "expired"
                    ? T("Your Apple VPP Token has expired")
                    : T("Apple Volume Purchase Program")

            let modal: any = {
                id: "setAppleVPPDialog",
                abortable: true,
                content: {
                    title: {
                        text: title,
                        icon:
                            type == "expired" || type == "update"
                                ? "fal fa-sync-alt"
                                : "fal fa-plus"
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component: "apple-vpp-dialog",
                        properties: {
                            accountid: accountid,
                            type: type
                        }
                    }
                },
                buttons: [
                    {
                        text: T("Cancel"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "left",
                        loading: false
                    },
                    {
                        text: T("Done"),
                        icon: "fal fa-check",
                        onClick: async function () {
                            this.loading = true
                            await useStore().dispatch(ActionTypes.updateAccount, accountid)
                            location.reload()
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "right",
                        disabled: false,
                        loading: false
                    }
                ]
            }
            useVue().$refs.modals.addModal(modal)
        } catch (e: any) {
            devLog.log("dialog", e, undefined, "error", 0)
        }
    },

    showAppPermissions: function (permissions: any[]) {
        let modalContent: string = ""
        for (let i: number = 0; permissions.length > i; i++) {
            let permission: any = permissions[i]
            if (typeof permission == "object") {
                modalContent += `<p class="box-shadow padding-xs-2">
					<strong>${permission.permission}</strong>
					<br>
					${permission.description}
				</p>`
            } else {
                modalContent += `<p class="box-shadow padding-xs-2"><strong>${permission}</strong></p>`
            }
        }
        try {
            let modal: any = {
                id: "appPermissionsDialog",
                abortable: true,
                content: {
                    title: {
                        text: T("Permissions"),
                        icon: "fal fa-key"
                    },
                    body: {
                        use: true,
                        content: modalContent
                    }
                },
                buttons: [
                    {
                        text: T("Close"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "right",
                        loading: false
                    }
                ]
            }
            useVue().$refs.modals.addModal(modal)
        } catch (e: any) {
            devLog.log("dialog", e, undefined, "error", 0)
        }
    },

    deanonymizeDevice: function (
        accountid: string,
        objectType: string,
        objectId: string,
        isEnterprise: boolean = false
    ) {
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore().getters.hasAccountid(accountid) == false) {
                throw "Wrong accountid, stopping process"
            }
            let modal: any = {
                id: "deanonymizeDeviceDialog",
                abortable: true,
                content: {
                    title: {
                        text: T("Deanonymize device"),
                        icon: "fal fa-eye"
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component: "deanonymize-device-dialog",
                        properties: {
                            accountid: accountid,
                            objectType: objectType,
                            objectId: objectId,
                            isEnterprise: isEnterprise
                        }
                    }
                },
                buttons: [
                    {
                        text: T("Cancel"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "left",
                        loading: false
                    },
                    {
                        text: T("Deanonymize"),
                        icon: "fal fa-check",
                        onClick: async function (this: any) {
                            if (
                                useVue().$refs.modals.$refs.modal.$refs.modalComponent.username &&
                                useVue().$refs.modals.$refs.modal.$refs.modalComponent.password
                            ) {
                                this.loading = true
                                this.disabled = true

                                let result: any =
                                    await useVue().$refs.modals.$refs.modal.$refs.modalComponent.deanonymize()

                                if (!result) {
                                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.error =
                                        T(
                                            "An error has occurred. Please check that the selected user has the auditor role and the given password and TOTP-code for the user is correct."
                                        )
                                    this.loading = false
                                    this.disabled = false
                                } else {
                                    this.text = T("Close")
                                    this.icon = "fal fa-check"
                                    this.onClick = function () {
                                        getterHelpers.useStore().commit(MutationTypes.removeModal)
                                    }
                                    this.loading = false
                                    this.disabled = false
                                    try {
                                        if (
                                            Array.isArray(
                                                useVue()?.$refs?.modals?.$refs?.modal?.buttons
                                            ) &&
                                            useVue()?.$refs?.modals?.$refs?.modal?.buttons[0]
                                                .text == T("Cancel")
                                        ) {
                                            useVue().$refs.modals.$refs.modal.modal.buttons.splice(
                                                0,
                                                1
                                            )
                                        }
                                    } catch (e: unknown) {
                                        console.error(e)
                                    }
                                }
                            } else {
                                useVue().$refs.modals.$refs.modal.$refs.modalComponent.error = T(
                                    "Please set username and password"
                                )
                            }
                        },
                        align: "right",
                        disabled: false,
                        loading: false
                    }
                ]
            }
            useVue().$refs.modals.addModal(modal)
        } catch (e: any) {
            devLog.log("dialog", e, undefined, "error", 0)
        }
    },

    licenseAssignmentDialog: function (accountid: string) {
        try {
            accountid = tenantHelpers.getAccountId(accountid)
            if (useStore().getters.hasAccountid(accountid) == false) {
                throw "Wrong accountid, stopping process"
            }

            let modal: any = {
                id: "licenseAssignmentDialog",
                abortable: true,
                content: {
                    title: {
                        text: T("Assign licenses to devices"),
                        icon: "fal fa-sitemap"
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component: "license-assignment-dialog",
                        properties: {
                            accountid: accountid
                        }
                    }
                },
                buttons: [
                    {
                        text: T("Cancel"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "left",
                        loading: false
                    },
                    {
                        text: T("Save"),
                        icon: "fal fa-save",
                        onClick: async function (this: any) {
                            this.loading = true
                            this.disabled = true
                            let allDevices: any =
                                useVue().$refs.modals.$refs.modal.$refs.modalComponent.allDevices

                            try {
                                for (let i: number = 0; allDevices.length > i; i++) {
                                    let device: any = allDevices[i]
                                    let result: any = undefined
                                    if (
                                        device.license.currentUUID != device.license.selectedUUID &&
                                        device.license.selectedUUID != ""
                                    ) {
                                        switch (device.objectType) {
                                            case "devices":
                                            case "iosDevices":
                                                if (config.canUseNewObjectType("iosDevices")) {
                                                    result =
                                                        await products.mobileSecurity.iosDevices.queries.updateObjectPropertiesFromApi(
                                                            accountid,
                                                            device.id,
                                                            {
                                                                licenseUUID:
                                                                    device.license.selectedUUID
                                                            },
                                                            "licenseUUID",
                                                            undefined,
                                                            undefined,
                                                            "/properties/licenseUUID"
                                                        )
                                                } else {
                                                    result =
                                                        await queries.mobileSecurity.updateObjectInfo(
                                                            accountid,
                                                            "deviceProperties",
                                                            device.id,
                                                            {
                                                                licenseUUID:
                                                                    device.license.selectedUUID
                                                            },
                                                            ["licenseUUID"]
                                                        )
                                                }
                                                break
                                            case "androidDevices":
                                                result =
                                                    await products.mobileSecurity.androidDevices.queries.updateObjectPropertiesFromApi(
                                                        accountid,
                                                        device.id,
                                                        {
                                                            licenseUUID: device.license.selectedUUID
                                                        },
                                                        "licenseUUID",
                                                        undefined,
                                                        undefined,
                                                        "/properties?updateMask[]=licenseUUID"
                                                    )
                                                break
                                            case "enterpriseDevices":
                                                let payload = {
                                                    accountId: accountid,
                                                    objectId: device.id || "",
                                                    productType: <ProductType>"mobileSecurity",
                                                    objectType: "enterpriseDevices",
                                                    queryType: "enterpriseDeviceProperties",
                                                    value: device.license.selectedUUID,
                                                    object: {
                                                        licenseUUID: device.license.selectedUUID
                                                    },
                                                    property: "licenseUUID"
                                                }
                                                result = await useStore().dispatch(
                                                    ActionTypes.updateObjectProperty,
                                                    payload
                                                )
                                                break
                                            case "vpns":
                                                result = config.canUseNewObjectType("vpns")
                                                    ? await products.mobileSecurity.vpns.queries.updateObjectPropertiesFromApi(
                                                          accountid,
                                                          device.id,
                                                          device.license.selectedUUID,
                                                          "licenseUUID",
                                                          undefined,
                                                          undefined,
                                                          "/properties/licenseUUID"
                                                      )
                                                    : await queries.mobileSecurity.updateObjectInfo(
                                                          accountid,
                                                          "vpnProperties",
                                                          device.id,
                                                          {
                                                              licenseUUID:
                                                                  device.license.selectedUUID
                                                          },
                                                          ["licenseUUID"]
                                                      )
                                                break
                                        }
                                    }
                                }
                                this.loading = false
                                this.disabled = false
                                getterHelpers.useStore().commit(MutationTypes.removeModal)
                            } catch (e: any) {
                                console.error(e)
                                this.loading = false
                                this.disabled = false
                            }
                        },
                        align: "right",
                        disabled: false,
                        loading: false
                    }
                ]
            }
            useVue().$refs.modals.addModal(modal)
        } catch (e: any) {
            devLog.log("dialog", e, undefined, "error", 0)
        }
    },

    enrollDeviceDialog: function (accountId: string, type: "iosDevices" | "enterpriseDevices") {
        try {
            let modal: any = {
                id: "enrollmentDialog",
                abortable: true,
                content: {
                    title: {
                        text: T("Enroll new Device"),
                        icon: type == "iosDevices" ? "fab fa-apple" : "fab fa-google"
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component:
                            type == "iosDevices"
                                ? "ios-enrollment-dialog"
                                : "android-enrollment-dialog-new",
                        maxHeight: "535",
                        properties: {
                            accountid: accountId
                        }
                    }
                },
                buttons: [
                    {
                        text: T("Close"),
                        icon: "fal fa-times",
                        onClick: async function (this: any) {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)

                            switch (type) {
                                case "iosDevices":
                                    router.navigate(
                                        `show-tenant-${accountId}.sms-ios-devices-dashboard`
                                    )
                                    break
                                case "enterpriseDevices":
                                    router.navigate(`show-tenant-${accountId}.sms-android-devices`)
                                    break
                            }
                        },
                        align: "right",
                        disabled: false,
                        loading: false
                    }
                ]
            }
            useVue().$refs.modals.addModal(modal)
        } catch (e: any) {
            devLog.log("dialog", e, undefined, "error", 0)
        }
    },
    inviteDeviceDialog: function (accountId: string, type: "iosDevices" | "enterpriseDevices") {
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore().getters.hasAccountid(accountId) == false) {
                throw "Wrong accountid, stopping process"
            }
            let modal: any = {
                id: "inviteDialog",
                abortable: true,
                content: {
                    title: {
                        text: T("Choose users which shall be invited or enter email addresses"),
                        icon: type == "iosDevices" ? "fab fa-apple" : "fab fa-google"
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component:
                            type == "iosDevices" ? "ios-invite-dialog" : "android-invite-dialog"
                    }
                },
                buttons: [
                    {
                        text: T("Cancel"),
                        icon: "fal fa-times",
                        onClick: async function (this: any) {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "left",
                        disabled: false,
                        loading: false
                    },
                    {
                        text: T("Send invitation"),
                        icon: "fal fa-fw fa-envelope",
                        onClick: async function (this: any) {
                            let payload: any = {
                                to: [],
                                type: useVue().$refs.modals.$refs.modal.$refs.modalComponent
                                    .enrollmentType
                            }

                            if (["0", "1"].indexOf(payload.type) != -1) {
                                payload.licenseUUID =
                                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.license.selected
                            }

                            if (payload.type == "2") {
                                let tokenIndex: number =
                                    useVue().$refs.modals.$refs.modal.$refs.modalComponent
                                        .enterprise.selectedToken.index
                                payload.enrollmentTokenId =
                                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.enterprise.enrollmentTokens[
                                        tokenIndex
                                    ].name.split("/")[3]
                            }

                            for (
                                let i: number = 0;
                                useVue().$refs.modals.$refs.modal.$refs.modalComponent.value
                                    .length > i;
                                i++
                            ) {
                                let thisMail: string | any =
                                    useVue().$refs.modals.$refs.modal.$refs.modalComponent.value[i]

                                if (payload.type == 1) {
                                    thisMail = JSON.parse(thisMail)
                                    payload.to.push({
                                        email: thisMail.email,
                                        appleId: thisMail.appleId,
                                        clientUserIdStr: thisMail.clientUserIdStr
                                    })
                                } else {
                                    payload.to.push(
                                        thisMail.slice(
                                            0,
                                            thisMail.indexOf("#usrNr") != -1
                                                ? thisMail.indexOf("#usrNr")
                                                : thisMail.length
                                        )
                                    )
                                }
                            }
                            try {
                                useVue().$refs.modals.$refs.modal.$refs.modalComponent.initialized =
                                    false
                                useVue().$refs.modals.$refs.modal.$refs.modalComponent.loaderText =
                                    T("Sending emails...")
                                await requestHandler.request(
                                    "POST",
                                    "/sms-mgt-api/api/1.1/tenants/" +
                                        accountId +
                                        ".sms/emails/invite",
                                    payload
                                )
                                useUserLogStore().addLogEntry(
                                    "fal fa-fw fa-tablet-alt",
                                    "color-white",
                                    T("Sent invite to:") + payload.to,
                                    undefined
                                )
                                getterHelpers.useStore().commit(MutationTypes.removeModal)
                                let modalSent: any = {
                                    id: "inviteDialog",
                                    abortable: true,
                                    content: {
                                        title: {
                                            text: T("Done"),
                                            icon:
                                                type == "iosDevices"
                                                    ? "fab fa-apple"
                                                    : "fab fa-google"
                                        },
                                        body: {
                                            use: true,
                                            content:
                                                '<i class="fal fa-check"></i> ' +
                                                T("E-Mail(s) have been sent!"),
                                            component: undefined
                                        }
                                    },
                                    buttons: [
                                        {
                                            text: T("Close"),
                                            icon: "fal fa-times",
                                            onClick: async function (this: any) {
                                                getterHelpers
                                                    .useStore()
                                                    .commit(MutationTypes.removeModal)
                                            },
                                            align: "left",
                                            disabled: false,
                                            loading: false
                                        },
                                        {
                                            text: T("Invite more"),
                                            icon: "fal fa-envelope",
                                            onClick: async function (this: any) {
                                                getterHelpers
                                                    .useStore()
                                                    .commit(MutationTypes.removeModal)
                                                dialogs.mobileSecurity.inviteDeviceDialog(
                                                    accountId,
                                                    type
                                                )
                                            },
                                            align: "right",
                                            disabled: false,
                                            loading: false
                                        }
                                    ]
                                }
                                useVue().$refs.modals.addModal(modalSent)
                            } catch (e: any) {
                                useUserLogStore().addLogEntry(
                                    "fal fa-fw fa-ban",
                                    "color-red",
                                    T("Error sending invite"),
                                    e
                                )
                            }
                        },
                        align: "right",
                        disabled: false,
                        loading: false
                    }
                ]
            }
            useVue().$refs.modals.addModal(modal)
        } catch (e: any) {
            devLog.log("dialog", e, undefined, "error", 0)
        }
    },

    enterpriseUnlinkDialog: function (accountId: AccountId) {
        dialogs.misc.confirmDialog(
            accountId,
            T("Unlink Enterprise Account"),
            T("In order to fully delete your Enterprise account visit") +
                " " +
                '<a href="https://play.google.com/work/adminsettings" target="_blank" rel="noreferrer noopener">' +
                T("Google Play Adminsettings") +
                "</a>.",
            async () => {
                await queries.mobileSecurity.unlinkEnterprise(accountId)
                await useStore().dispatch(ActionTypes.updateAccount, accountId)
                location.reload()
            },
            T("Unlink")
        )
    },

    enterpriseErrorDialog: async function (accountId: AccountId, error: any) {
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore().getters.hasAccountid(accountId) == false) {
                throw "Wrong accountid, stopping process"
            }
            if (error.responseJSON) error = error.responseJSON
            error = error.message ? error : error.errors?.length ? error.errors[0] : "unknown error"

            let content: string = ""
            let buttons = []

            content += "<p>"
            if (typeof error == "string") {
                content += error
            } else {
                content += "<strong>" + T("Google is reporting:") + "</strong><br>"
                content += T(error.message)
            }
            content += "</p>"

            content +=
                "<br><p>" +
                T("Android Enterprise features will be disabled for this session") +
                "</p>"

            if (error.reason == "forbidden" || error.status == "PERMISSION_DENIED") {
                content +=
                    "<br><p class='text-bold'>" +
                    T(
                        'If your Google-Account has been deleted, you can unlink it by clicking the "Unlink" button.'
                    ) +
                    "</p>"
                buttons.push({
                    text: T("Unlink"),
                    icon: "fal fa-unlink",
                    onClick: () => {
                        dialogs.mobileSecurity.enterpriseUnlinkDialog(accountId)
                        useStore().commit(MutationTypes.removeModal, { accountId: accountId })
                    },
                    align: "left",
                    disabled: false,
                    loading: false
                })
            }
            buttons.push({
                text: T("Close"),
                icon: "fal fa-times",
                onClick: () => {
                    useStore().commit(MutationTypes.removeModal, { accountId: accountId })
                },
                align: "right",
                disabled: false,
                loading: false
            })

            let modal: any = {
                accountId: accountId,
                id: "enterpriseErrorDialog",
                abortable: true,
                content: {
                    title: {
                        text: T("Error retrieving Google Enterprise info"),
                        icon: "fal fa-exclamation-triangle"
                    },
                    body: {
                        use: true,
                        content: content,
                        component: undefined
                    }
                },
                buttons: buttons
            }
            useStore().dispatch(ActionTypes.addModal, modal)
        } catch (e: any) {
            console.error(e)
            devLog.log("dialog", e, undefined, "error", 0)
        }
    },

    renderEnterpriseErrorDialog: function (error: any) {
        if (useVue().oneTimeDialogs.enterpriseError === 0) {
            useVue().oneTimeDialogs.enterpriseError = 1

            let accountId = useStore().state.session.activeAccountId

            useStore().dispatch(ActionTypes.addModal, {
                accountId: accountId,
                id: "enterpriseErrorDialog",
                abortable: true,
                content: {
                    title: {
                        text: T("An unexpected error occurred"),
                        icon: "fal fa-exclamation-triangle"
                    },
                    body: {
                        use: true,
                        content: `
					<div>
						<div class="row">
							<div class="col-xs-24 col-lg-21">
								<h5 class="margin-xs-y-2">
									${T("Android Enterprise Services experienced an unexpected error")}:
								</h5>
<pre>
${error}
</pre>
							</div>
						</div>
					</div>
					`,
                        component: undefined
                    }
                },
                buttons: [
                    {
                        text: T("Close"),
                        icon: "fal fa-times",
                        onClick: () => {
                            useVue().oneTimeDialogs.enterpriseError = 0
                            useStore().commit(MutationTypes.removeModal, { accountId: accountId })
                        },
                        align: "left",
                        disabled: false,
                        loading: false
                    },
                    {
                        text: T("OK"),
                        icon: "fal fa-check",
                        onClick: () => {
                            useVue().oneTimeDialogs.enterpriseError = 1
                            useStore().commit(MutationTypes.removeModal, { accountId: accountId })
                        },
                        align: "right",
                        disabled: false,
                        loading: false
                    }
                ]
            })
        }
    },

    renderDepErrorDialog: function (
        errorType: "unauthorized" | "expired" | "invalidToken" | "errorGettingObject" | "unknown",
        errorMessage?: string
    ) {
        let accountId = useStore().state.session.activeAccountId
        let newModal = {
            accountId: accountId,
            id: "depErrorDialog",
            abortable: true,
            content: {
                title: {
                    text: T("Check your Apple Business Account"),
                    icon: "fal fa-exclamation-triangle"
                },
                body: {
                    use: true,
                    content: `
						<h5 class="margin-xs-y-2">
							${T("We retrieved an error while fetching your data from Apple")}
						</h5>
						<p>
							${T("This could happen due to updated software license agreements.")}<br>
							${T("Please check your Apple Business Account, for further information.")}
						</p>
						<a class="btn btn-primary" href="https://business.apple.com/" rel="noreferrer noopener" target="_blank">
							<i class="fal fa-angle-right"></i> Apple business Manager
						</a>
					`,
                    component: undefined
                }
            },
            buttons: [
                {
                    text: T("Close"),
                    icon: "fal fa-times",
                    onClick: () => {
                        useStore().commit(MutationTypes.removeModal, { accountId: accountId })
                    },
                    align: "left",
                    disabled: false,
                    loading: false
                },
                {
                    text: T("OK"),
                    icon: "fal fa-check",
                    onClick: () => {
                        useVue().oneTimeDialogs.depError = 1
                        useStore().commit(MutationTypes.removeModal, { accountId: accountId })
                    },
                    align: "right",
                    disabled: false,
                    loading: false
                }
            ]
        }

        switch (errorType) {
            case "unauthorized":
            case "expired":
                newModal.content.title.text = T("Your Apple DEP-Token has expired")
                newModal.content.body.content = `
					<h5 class="margin-xs-y-2">
						${T("We retrieved an error while fetching your data from Apple")}
					</h5>
					<p>
						${T("Please extend your Apple DEP-Token")}
					</p>
				`
                newModal.buttons[1].icon = "fal fa-sync"
                newModal.buttons[1].text = T("Update now")
                newModal.buttons[1].onClick = () => {
                    useVue().oneTimeDialogs.depError = 1
                    useStore().commit(MutationTypes.removeModal, { accountId: accountId })
                    useVue().renderAppleDEPDialog()
                }
                break
            case "invalidToken":
                newModal.content.title.text = T("Your Apple DEP-Token is invalid")
                newModal.content.body.content = `
					<h5 class="margin-xs-y-2">
						${T("We retrieved an error while fetching your data from Apple")}
					</h5>
					<p>
						${T("It seems like your apple DEP-token is invalid.")}
					</p>
				`
                newModal.buttons[1].icon = "fal fa-sync"
                newModal.buttons[1].text = T("Upload a new one")

                if (errorMessage == "dep_agreement_not_signed") {
                    newModal.content.title.text = T("Apple DEP/School: Error")
                    newModal.content.body.content = `
						<h5 class="margin-xs-y-2">
							${T("We retrieved an error while fetching your data from Apple")}
						</h5>
						<p>
							${T("It seems like the Apple DEP/School agreement has been updated.")}<br>
							${T("Please visit the")} <a href="https://business.apple.com" target="_blank">Apple Business Manager</a>/<a href="https://school.apple.com" target="_blank">Apple School Manager</a> ${T("in order to accept the new agreement.")}
							<br><br>
							<strong>${T("Note:")}</strong> ${T("This action has to be done by an administrator.")}
						</p>
					`
                    newModal.buttons[1].icon = "fal fa-check"
                    newModal.buttons[1].text = "OK"
                }

                newModal.buttons[1].onClick = () => {
                    useVue().oneTimeDialogs.depError = 1
                    useStore().commit(MutationTypes.removeModal, { accountId: accountId })
                }
                break
            case "errorGettingObject":
                newModal.content.title.text = T("Error getting object")
                newModal.content.body.content = `
					<h5 class="margin-xs-y-2">
						${T("We retrieved an error while fetching your data from Apple")}
					</h5>
					<p>
						${T("It seems like this object can't be requested with your current Apple DEP-Token.")}
					</p>
					<p>
						${T("This could happen if the object you requested was managed with another Apple DEP-Token.")}
					</p>
				`
                newModal.buttons[1].icon = "fal fa-check"
                newModal.buttons[1].text = "OK"
                newModal.buttons[1].onClick = () => {
                    useStore().commit(MutationTypes.removeModal, { accountId: accountId })
                }
                break
        }

        if (useVue().oneTimeDialogs.depError == 0) {
            useStore().dispatch(ActionTypes.addModal, newModal)
        }
    },

    renderDeleteEnterprisePolicyDialog: async function (
        accountId: string,
        policyId: any,
        profileName: string
    ) {
        dialogs.misc.confirmDialog(
            accountId,
            T("Confirm Delete"),
            sprintf(T("Do you want to delete profile %s?"), profileName),
            async () => {
                let result = await useStore().dispatch(ActionTypes.deleteObject, {
                    accountId: accountId,
                    objectType: "enterpriseProfiles",
                    productType: "mobileSecurity",
                    objectIdProperty: "profileId",
                    objectId: policyId
                })
                useStore().commit(MutationTypes.removeModal, { accountId: accountId })
            }
        )
    },

    renderDeleteProfileDialog: async function (
        tenantDomain: string,
        profileId: string,
        objectType = "profiles"
    ) {
        const accountId = tenantHelpers.getAccountId(tenantDomain)
        let profile: any = {}
        profile = await useStore().getters.getObject({
            accountId: accountId,
            objectId: profileId,
            objectType: objectType,
            productType: "mobileSecurity"
        })

        dialogs.misc.confirmDialog(
            accountId,
            T("Confirm Delete"),
            sprintf(T("Do you want to delete profile %s?"), profile.profilename),
            async () => {
                await useStore().dispatch(ActionTypes.deleteObject, {
                    accountId: useStore().state.session.activeAccountId,
                    objectType: "profiles",
                    productType: "mobileSecurity",
                    objectIdProperty: "profileId",
                    objectId: profileId
                })
                useStore().commit(MutationTypes.removeModal, { accountId: accountId })
            }
        )
    },

    renderDeleteVPNDialog: async function (tenantDomain: string, vpnId: string) {
        let vpn = await queries.mobileSecurity.getObjectInfo(
            tenantHelpers.getAccountId(tenantDomain),
            "vpns",
            vpnId
        )
        const accountId = tenantHelpers.getAccountId(tenantDomain)
        dialogs.misc.confirmDialog(
            accountId,
            T("Confirm Delete"),
            sprintf(T("Do you want to delete vpn %s?"), vpn.vpnname),
            async () => {
                await useStore().dispatch(ActionTypes.deleteObject, {
                    accountId: useStore().state.session.activeAccountId,
                    objectType: "vpns",
                    productType: "mobileSecurity",
                    objectIdProperty: "vpnId",
                    objectId: vpnId
                })
                useStore().commit(MutationTypes.removeModal, { accountId: accountId })
            }
        )
    },
    renderAddCertificateDialog: async function (accountId: string) {
        accountId = tenantHelpers.getAccountId(accountId)

        let modal = {
            accountId: accountId,
            id: "addCertificateDialog",
            abortable: true,
            content: {
                title: {
                    text: T("Add certificate"),
                    icon: "fal fa-plus"
                },
                body: {
                    use: false,
                    content: undefined,
                    component: "add-certificate-dialog"
                }
            },
            buttons: [
                {
                    text: T("Cancel"),
                    icon: "fal fa-times",
                    onClick: async function () {
                        useStore().commit(MutationTypes.removeModal, {
                            accountId: accountId
                        })
                    },
                    align: "left",
                    loading: false
                },
                {
                    text: T("Add Certificate"),
                    icon: "fal fa-plus",
                    onClick: async function () {
                        useVue().$refs.modals.$refs.modal.$refs.modalComponent.submit()
                    },
                    align: "right",
                    loading: false,
                    disabled: false
                }
            ]
        }
        useStore().dispatch(ActionTypes.addModal, modal)
    },

    renderEditCertificateDialog: async function (tenantDomain: string, certificateId: string) {
        const accountId = tenantHelpers.getAccountId(tenantDomain)

        let modal = {
            accountId: accountId,
            id: "addCertificateDialog",
            abortable: true,
            content: {
                title: {
                    text: T("Show certificate"),
                    icon: "fal fa-info-circle"
                },
                body: {
                    use: false,
                    content: undefined,
                    component: "edit-certificate-dialog",
                    properties: {
                        certificateId: certificateId
                    }
                }
            },
            buttons: [
                {
                    text: T("Ok"),
                    icon: "fal fa-check",
                    onClick: async function () {
                        useStore().commit(MutationTypes.removeModal, {
                            accountId: accountId
                        })
                    },
                    align: "right",
                    loading: false,
                    disabled: false
                }
            ]
        }
        useStore().dispatch(ActionTypes.addModal, modal)
    },

    renderDeleteCertificateDialog: async function (tenantDomain: string, certificateId: string) {
        const accountId = tenantHelpers.getAccountId(tenantDomain)
        let certificate = await queries.mobileSecurity.getObjectInfo(
            accountId,
            "certificates",
            certificateId
        )
        dialogs.misc.confirmDialog(
            accountId,
            T("Confirm Delete"),
            sprintf(T("Do you want to delete certificate %s?"), certificate.name),
            async () => {
                await useStore().dispatch(ActionTypes.deleteObject, {
                    accountId: useStore().state.session.activeAccountId,
                    objectType: "certificates",
                    productType: "mobileSecurity",
                    objectIdProperty: "certificateId",
                    objectId: certificateId
                })
                useStore().commit(MutationTypes.removeModal, { accountId: accountId })
            }
        )
    },

    renderInstallOrUninstallAppDialog: async function (
        accountId: string,
        appId: string,
        context: "install" | "uninstall"
    ) {
        accountId = tenantHelpers.getAccountId(accountId)

        let modal = {
            accountId: accountId,
            id: "installOrUninstallAppDialog",
            abortable: true,
            content: {
                title: {
                    text:
                        context == "install"
                            ? T("Install application")
                            : T("Uninstall application"),
                    icon: "fal fa-info-circle"
                },
                body: {
                    use: false,
                    content: undefined,
                    component: "app-install-uninstall-dialog",
                    properties: {
                        appId: appId,
                        context: context
                    }
                }
            },
            buttons: [
                {
                    text: T("Cancel"),
                    icon: "fal fa-times",
                    onClick: async function () {
                        useStore().commit(MutationTypes.removeModal, {
                            accountId: accountId
                        })
                    },
                    align: "left",
                    loading: false
                },
                {
                    text: context == "install" ? T("Install") : T("Uninstall"),
                    icon: context == "install" ? "fal fa-cogs" : "fal fa-trash",
                    onClick: async function () {
                        this.loading = true
                        context == "install"
                            ? config.canUseNewObjectType("iosApps")
                                ? products.mobileSecurity.iosApps.installApp(accountId, appId)
                                : await queries.mobileSecurity.installApp(accountId, appId)
                            : config.canUseNewObjectType("iosApps")
                              ? products.mobileSecurity.iosApps.uninstallApp(accountId, appId)
                              : await queries.mobileSecurity.uninstallApp(accountId, appId)
                        this.onClick = async function () {
                            useStore().commit(MutationTypes.removeModal, {
                                accountId: accountId
                            })
                        }
                        this.text = T("Close")
                        this.icon = "fal fa-check"
                        this.loading = false
                    },
                    align: "right",
                    loading: true
                }
            ]
        }
        useStore().dispatch(ActionTypes.addModal, modal)
    },

    renderDeleteAppDialog: async function (accountId: string, appId: string) {
        accountId = tenantHelpers.getAccountId(accountId)

        let modal = {
            accountId: accountId,
            id: "deleteAppDialog",
            abortable: true,
            content: {
                title: {
                    text: T("Confirm delete"),
                    icon: "fal fa-exclamation-triangle"
                },
                body: {
                    use: true,
                    content: "<p>" + T("Do you really want to delete this application?") + "</p>",
                    component: undefined
                }
            },
            buttons: [
                {
                    text: T("Cancel"),
                    icon: "fal fa-times",
                    onClick: async function () {
                        useStore().commit(MutationTypes.removeModal, {
                            accountId: accountId
                        })
                    },
                    align: "left",
                    loading: false
                },
                {
                    text: T("Uninstall and delete"),
                    icon: "fal fa-trash",
                    onClick: async function () {
                        this.loading = true

                        await queries.mobileSecurity.uninstallApp(
                            tenantHelpers.getTenantDomain(accountId),
                            appId
                        )
                        await useStore().dispatch(ActionTypes.deleteObject, {
                            accountId: accountId,
                            objectType: "apps",
                            productType: "mobileSecurity",
                            objectIdProperty: "appId",
                            objectId: appId
                        })

                        useStore().commit(MutationTypes.removeModal, {
                            accountId: accountId
                        })
                        this.loading = false
                    },
                    align: "center",
                    loading: false
                },
                {
                    text: T("Delete without uninstall"),
                    icon: "fal fa-times",
                    onClick: async function () {
                        this.loading = true
                        await useStore().dispatch(ActionTypes.deleteObject, {
                            accountId: accountId,
                            objectType: "apps",
                            productType: "mobileSecurity",
                            objectIdProperty: "appId",
                            objectId: appId
                        })
                        useStore().commit(MutationTypes.removeModal, {
                            accountId: accountId
                        })

                        this.loading = false
                    },
                    align: "right",
                    loading: false
                }
            ]
        }
        useStore().dispatch(ActionTypes.addModal, modal)
    },

    renderVPPErrorDialog: function (error: any) {
        let errorNumber: any = error?.errorNumber ? error.errorNumber : "-1"
        let errorMessage: any = error?.errorMessage ? error.errorMessage : T("Unknown error")
        dialogs.misc.confirmDialog(
            useStore().state.session.activeAccountId || "",
            T("Error"),
            "<p><strong>" +
                T("The apple servers have reported following error:") +
                "</strong></p>" +
                "<p class='notification'>" +
                "<strong>" +
                errorNumber +
                "</strong><br>" +
                T(errorMessage) +
                "</p>",
            () => {
                router.navigate(
                    "#show-tenant-" +
                        tenantHelpers.getTenantDomain(
                            useStore().state.session.activeAccountId || ""
                        ) +
                        "-mobile-security-settings"
                )
            },
            undefined,
            T("Go to Mobile Security settings"),
            "fal fa-fw fa-angle-right"
        )
    },

    renderVPPLicenseAssignmentDialog: function (accountId: string) {
        accountId = tenantHelpers.getAccountId(accountId)

        let modal = {
            accountId: accountId,
            id: "vppLicenseAssignment",
            abortable: true,
            content: {
                title: {
                    text: T("Assign/Unassign VPP licenses"),
                    icon: "fal fa-link"
                },
                body: {
                    use: true,
                    content: undefined,
                    component: "apple-vpp-license-assignment-dialog"
                }
            },
            buttons: [
                {
                    text: T("Cancel"),
                    icon: "fal fa-times",
                    onClick: async function () {
                        useStore().commit(MutationTypes.removeModal, {
                            accountId: accountId
                        })
                    },
                    align: "left",
                    loading: false
                },
                {
                    text: T("Unassign licenses"),
                    icon: "fal fa-trash",
                    onClick: async function () {
                        this.loading = true
                        const component = useVue().$refs.modals.$refs.modal.$refs.modalComponent
                        const selectedDeviceSerials = component.selectedDevices
                        let deviceChunks = arrayHelpers.chunkify(selectedDeviceSerials, 9)
                        const selectedclientUserIdStrs = component.selectedAppleIds
                        let clientUserIdStrChunks = arrayHelpers.chunkify(
                            selectedclientUserIdStrs,
                            9
                        )
                        const selectedAssetAdamIdStr = component.selectedAssets

                        if (config.canUseNewObjectType("vppassets")) {
                            let assetsPayload = (selectedAssetAdamIdStr || []).map(
                                (adamIdStr: string) => {
                                    let asset = products.mobileSecurity.vppassets
                                        .useStore?.()
                                        .getObjectStoreObject(accountId, adamIdStr)
                                    return {
                                        adamId: adamIdStr,
                                        pricingParam: asset?.pricingParam
                                    }
                                }
                            )
                            if (assetsPayload.length) {
                                await vppApi.disassociateVppAssets(accountId, {
                                    assets: assetsPayload,
                                    clientUserIds: selectedclientUserIdStrs,
                                    serialNumbers: selectedDeviceSerials
                                })
                            }
                        } else {
                            for (let i = 0; selectedAssetAdamIdStr.length > i; i++) {
                                const adamIdStr = selectedAssetAdamIdStr[i] as string
                                for (let j = 0; deviceChunks.length > j; j++) {
                                    const deviceSerials = deviceChunks[j] as string[]
                                    await queries.mobileSecurity.vpp.manageVPPLicensesByAdamIdSrv(
                                        accountId,
                                        adamIdStr,
                                        [],
                                        [],
                                        [],
                                        [],
                                        deviceSerials
                                    )
                                }
                                for (let j = 0; clientUserIdStrChunks.length > j; j++) {
                                    const clientUserIdStrs = clientUserIdStrChunks[j] as string[]
                                    await queries.mobileSecurity.vpp.manageVPPLicensesByAdamIdSrv(
                                        accountId,
                                        adamIdStr,
                                        [],
                                        [],
                                        clientUserIdStrs,
                                        [],
                                        []
                                    )
                                }
                            }
                        }

                        useVue().$refs?.itemlist?.refresh?.()
                        useStore().commit(MutationTypes.removeModal, {
                            accountId: accountId
                        })
                    },
                    align: "right",
                    loading: false
                },
                {
                    text: T("Assign licenses"),
                    icon: "fal fa-save",
                    onClick: async function () {
                        this.loading = true
                        const component = useVue().$refs.modals.$refs.modal.$refs.modalComponent

                        const selectedDeviceSerials = component.selectedDevices
                        let deviceChunks = arrayHelpers.chunkify(selectedDeviceSerials, 9)
                        const selectedclientUserIdStrs = component.selectedAppleIds
                        let clientUserIdStrChunks = arrayHelpers.chunkify(
                            selectedclientUserIdStrs,
                            9
                        )
                        const selectedAssetAdamIdStr = component.selectedAssets

                        if (config.canUseNewObjectType("vppassets")) {
                            let assetsPayload = (selectedAssetAdamIdStr || []).map(
                                (adamIdStr: string) => {
                                    let asset = products.mobileSecurity.vppassets
                                        .useStore?.()
                                        .getObjectStoreObject(accountId, adamIdStr)
                                    return {
                                        adamId: adamIdStr,
                                        pricingParam: asset?.pricingParam
                                    }
                                }
                            )
                            if (assetsPayload.length) {
                                await vppApi.associateVppAssets(accountId, {
                                    assets: assetsPayload,
                                    clientUserIds: selectedclientUserIdStrs,
                                    serialNumbers: selectedDeviceSerials
                                })
                            }
                        } else {
                            for (let i = 0; selectedAssetAdamIdStr.length > i; i++) {
                                const adamIdStr = selectedAssetAdamIdStr[i] as string
                                for (let j = 0; deviceChunks.length > j; j++) {
                                    const deviceSerials = deviceChunks[j] as string[]
                                    await queries.mobileSecurity.vpp.manageVPPLicensesByAdamIdSrv(
                                        accountId,
                                        adamIdStr,
                                        [],
                                        deviceSerials,
                                        [],
                                        [],
                                        []
                                    )
                                }
                                for (let j = 0; clientUserIdStrChunks.length > j; j++) {
                                    const clientUserIdStrs = clientUserIdStrChunks[j] as string[]
                                    await queries.mobileSecurity.vpp.manageVPPLicensesByAdamIdSrv(
                                        accountId,
                                        adamIdStr,
                                        clientUserIdStrs,
                                        [],
                                        [],
                                        [],
                                        []
                                    )
                                }
                            }
                        }

                        useVue().$refs.itemlist.refresh()
                        useStore().commit(MutationTypes.removeModal, {
                            accountId: accountId
                        })
                    },
                    align: "right",
                    loading: false
                }
            ]
        }
        useStore().dispatch(ActionTypes.addModal, modal)
    },

    deleteSharedIpadUserDialog: async function (
        accountId: string,
        deviceId: string,
        username: string
    ) {
        let newModal: any = {
            accountId: accountId,
            id: "deleteSharedIpadUser",
            abortable: true,
            content: {
                title: {
                    text: T("Delete") + ":" + username
                },
                body: {
                    use: true,
                    content: undefined,
                    component: "ipadUser-delete"
                }
            },
            buttons: [
                {
                    text: T("Cancel"),
                    icon: "fal fa-times",
                    onClick: async function () {
                        getterHelpers.useStore().commit(MutationTypes.removeModal, {
                            accountId: tenantHelpers.getAccountId(accountId)
                        })
                    },
                    align: "left",
                    loading: false
                },
                {
                    text: T("Delete"),
                    icon: "fal fa-trash",
                    onClick: async function (modal: any) {
                        try {
                            let thisPayload: any = {
                                username: username
                            }
                            if (modal.$refs.modalComponent.forceLogout) {
                                thisPayload.force = true
                            }
                            await queries.mobileSecurity.sendDeviceMessage(
                                useVue().activeTenantDomain,
                                deviceId,
                                {
                                    type: "DELETE_USER",
                                    payload: thisPayload
                                }
                            )
                            useVue().$refs?.deviceDetails?.sendUserListMessage()
                            getterHelpers.useStore().commit(MutationTypes.removeModal, {
                                accountId: tenantHelpers.getAccountId(accountId)
                            })
                        } catch (e: any) {
                            getterHelpers.useStore().commit(MutationTypes.removeModal, {
                                accountId: tenantHelpers.getAccountId(accountId)
                            })
                            console.error(e)
                        }
                    },
                    align: "right",
                    disabled: false,
                    loading: false
                }
            ]
        }
        useVue().$refs.modals.addModal(newModal)
    },
    renderUnknownVppClientContext: function (
        accountId: string,
        clientContext: { hostname: string; guid: string }
    ) {
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore().getters.hasAccountid(accountId) == false) {
                throw "Wrong accountid, stopping process"
            }
            let modal: any = {
                id: "unknownVppClientContextDialog",
                abortable: true,
                content: {
                    title: {
                        text: T("Apple VPP could not be intialized"),
                        icon: "fal fa-exclamation-triangle"
                    },
                    body: {
                        use: true,
                        content:
                            "<strong>" +
                            T("It seems your Apple VPP token is assigned to another MDM") +
                            ':</strong> "' +
                            clientContext.hostname +
                            '".<br><p>' +
                            T(
                                "We recommend assigning the token explicitly to Securepoint Unified Security or replacing the token with a new one."
                            ) +
                            "</p>",
                        component: undefined
                    }
                },
                buttons: [
                    {
                        text: T("Close"),
                        icon: "fal fa-fw fa-times",
                        align: "left",
                        disabled: false,
                        loading: false,
                        onClick: async function (this: any) {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        }
                    },
                    {
                        text: T("Reassign token now"),
                        icon: "fal fa-fw fa-link",
                        onClick: async function (this: any) {
                            await queries.mobileSecurity.vpp.vppClientConfigSrv(
                                accountId + ".sms",
                                '{"hostname":"' +
                                    config.hostname +
                                    '", "guid":"' +
                                    config.mobileSecurity.vppGuid +
                                    '"}'
                            )
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "right",
                        disabled: false,
                        loading: false
                    }
                ]
            }
            useVue().$refs.modals.addModal(modal)
        } catch (e: any) {
            devLog.log("dialog", e, undefined, "error", 0)
        }
    },

    updateAppleDevice: function (accountId: string, callback: (updateType: string) => void) {
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore().getters.hasAccountid(accountId) == false) {
                throw "Wrong accountid, stopping process"
            }
            let modal: any = {
                id: "updateAppleDeviceDialog",
                abortable: true,
                content: {
                    title: {
                        text: T("Confirm update"),
                        icon: "fal fa-exclamation-triangle"
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component: "apple-update-dialog"
                    }
                },
                buttons: [
                    {
                        text: T("Close"),
                        icon: "fal fa-fw fa-times",
                        align: "left",
                        disabled: false,
                        loading: false,
                        onClick: async function (this: any) {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        }
                    },
                    {
                        text: T("Confirm"),
                        icon: "fal fa-fw fa-check",
                        onClick: async function (this: any, modal: any) {
                            callback(modal.$refs.modalComponent.value)
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "right",
                        disabled: false,
                        loading: false
                    }
                ]
            }
            useVue().$refs.modals.addModal(modal)
        } catch (e: any) {
            devLog.log("dialog", e, undefined, "error", 0)
        }
    },

    androidLostMode: function (accountId: string, callback: (component: any) => void) {
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore().getters.hasAccountid(accountId) == false) {
                throw "Wrong accountid, stopping process"
            }

            let modal: any = {
                id: "androidLostModeDialog",
                abortable: true,
                content: {
                    title: {
                        text: T("Enable Lost Mode"),
                        icon: "fal fa-search-plus"
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component: "android-lost-mode"
                    }
                },
                buttons: [
                    {
                        text: T("Close"),
                        icon: "fal fa-fw fa-times",
                        align: "left",
                        disabled: false,
                        loading: false,
                        onClick: async function (this: any) {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        }
                    },
                    {
                        text: T("Confirm"),
                        icon: "fal fa-fw fa-check",
                        onClick: async function (this: any, modal: any) {
                            callback(modal.$refs.modalComponent)
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "right",
                        disabled: false,
                        loading: false
                    }
                ]
            }
            useVue().$refs.modals.addModal(modal)
        } catch (e: any) {
            devLog.log("dialog", e, undefined, "error", 0)
        }
    },

    iosLocateDevice: function (accountId: string, callback: (message: string) => void) {
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore().getters.hasAccountid(accountId) == false) {
                throw "Wrong accountid, stopping process"
            }

            let modal: any = {
                id: "iosLocateDialog",
                abortable: true,
                content: {
                    title: {
                        text: T("Locate"),
                        icon: "fal fa-map-marker-alt"
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component: "device-locate"
                    }
                },
                buttons: [
                    {
                        text: T("Close"),
                        icon: "fal fa-fw fa-times",
                        align: "left",
                        disabled: false,
                        loading: false,
                        onClick: async function (this: any) {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        }
                    },
                    {
                        text: T("Confirm"),
                        icon: "fal fa-fw fa-check",
                        onClick: async function (this: any, modal: any) {
                            const { customReason, selectedOption } = modal.$refs.modalComponent

                            if (customReason.length > 0) {
                                callback(`${selectedOption} (${customReason})`)
                            } else {
                                callback(selectedOption)
                            }

                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "right",
                        disabled: false,
                        loading: false
                    }
                ]
            }
            useVue().$refs.modals.addModal(modal)
        } catch (e: any) {
            devLog.log("dialog", e, undefined, "error", 0)
        }
    }
}
export default mobileSecurityDialogs
