import { T } from "@/classes/i18n"
import { useGlobalMixin } from "@/mixins/mixins.global"
import queries from "@/queries/queries"
import { useStore } from "@/store/vuex.store"
import Dropzone from "dropzone"
import apis from "../../classes/apis"
import loaderComponent from "../components/loader.vue"
const appleDepDialogComponent = {
    mixins: [useGlobalMixin()],
    name: "apple-dep-dialog",
    template: `
        <div>
            <div class="padding-xs">
                <ol style="line-height:2.25em">
                    <li class="margin-xs-y">
                        {{ T("Download your Apple Push Certificate") }} (*.pem):
                        <a
                            v-on:click="downloadCert()"
                            class="btn btn-primary"
                            id="downloadCert"
                            target="_blank"
                            rel="noreferrer noopener"
                            v-if="!certLoading && !certDownloaded"
                        >
                            {{ T('Download file') }}
                        </a>
                        <loader v-if="certLoading" class="color-primary text-size-2"/>
                        <i v-if="!certLoading && certDownloaded" class="fal fa-fw fa-check color-green"></i>
                    </li>
                    <hr>
                    <li class="margin-xs-y">
                        {{ T("Upload your .pem file to the") }} <a target="_blank" rel="noreferrer noopener" href="https://business.apple.com">{{ T('Apple Device Enrollment Program') }}</a> {{ T('in order to generate your DEP-token-file (.p7m)') }}. 
                        {{ T('You can find more detailed instructions on how to do this here:')}} <a target="_blank" rel="noreferrer noopener" href="https://wiki.securepoint.de/MS/Enrollment-iOS-DEP#Verbindung_zum_DEP_(Device_Enrollment_Program)_herstellen">Wiki</a>
                    </li>
                    <hr>
                    <li class="margin-xs-y">

                        {{ T("Upload your DEP-token-file (.p7m)") }}:&nbsp;&nbsp;&nbsp;

                        <label style="display:inline-block; width:160px; min-width:0px; max-width:100%;" class="file form-inputgroup">
                            <a v-if="!tokenLoading && !tokenUploaded" id="tokenChooser" class="btn btn-primary">
                                <i class="fal fa-fw fa-upload"></i>&nbsp;&nbsp;{{ T('Upload Certificate') }}
                            </a>
                            <loader v-if="tokenLoading" class="color-primary text-size-2"/>
                            <i v-if="!tokenLoading && tokenUploaded" class="fal fa-fw fa-check color-green"></i>
                        </label>

                        <span class="bg-red label error padding-xs display-block" style="max-width: 400px;" v-if="tokenError">
                            <i class="fal fa-exclamation-triangle"></i>&nbsp;&nbsp;{{ tokenError }}
                        </span>

                        <div id="theDropZone" class="dropzoneArea" v-if="!tokenUploaded">
                            <div class="uploadcontent dz-default dz-message">
                                <template v-if="!tokenLoading">
                                    <i class="fal fa-upload"></i> {{ T('Drop File (*.p7m) here') }}
                                </template>
                                <loader v-if="tokenLoading" class="color-primary text-size-2"/>
                            </div>
                        </div>

                    </li>
                </ol>
            </div>
        </div>
    `,
    data: () => {
        return {
            initialized: true,
            certLoading: false,
            certDownloaded: false,

            tokenLoading: false,
            tokenUploaded: false,
            tokenError: undefined
        }
    },
    computed: {},
    props: {
        properties: {
            required: true,
            default: () => {
                return {
                    accountid: undefined
                }
            }
        }
    },
    methods: {
        submit: function (this: any) {
            if (this.emailValid && this.certUploaded) {
                useStore().getters.getActiveModal(this.activeAccountId).buttons[1].onClick()
            }
        },
        downloadCert: async function (this: any) {
            this.certLoading = true
            try {
                await queries.mobileSecurity.downloadApplePushCert(this.properties.accountid)
                this.certDownloaded = true
            } catch (e: any) {
                console.error(e)
            }
            this.certLoading = false
        }
    },
    created: function (this: any) {},
    mounted: function (this: any) {
        let thisComponent: any = this

        Dropzone.options.theDropZone = {
            clickable: "#tokenChooser",
            uploadMultiple: false,
            paramName: "file",
            accept: function (file: any, done: Function) {
                thisComponent.tokenError = undefined
                if (!/p7m$/.test(file.name)) {
                    done(T("Please provide the correct filetype: *.p7m"))
                    thisComponent.tokenError = T("Please provide the correct filetype: *.p7m")
                } else {
                    done()
                }
            },
            previewTemplate: `<div class="dz-preview dz-file-preview"></div>`
        }
        let url = ""
        if (apis.getApiVersionForFeature("appleDEPToken") == "1.1") {
            url =
                apis.getTenantApiUrl(thisComponent.properties.accountid, "appleDEPToken") +
                "/dep/token"
        } else {
            url = apis.getTenantApiUrl(thisComponent.properties.accountid, "appleDEPToken") + "/dep"
        }
        var myDropzone = new Dropzone("#theDropZone", { url: url })

        myDropzone.on("complete", function (event: any) {
            if (event.status == "success") {
                thisComponent.tokenLoading = false
                thisComponent.tokenUploaded = true
            } else {
                thisComponent.tokenLoading = false
                thisComponent.tokenUploaded = false
                thisComponent.tokenError = T("Something went wrong. Please try again.")
            }
        })

        this.initialized = true
    },
    watch: {},
    components: {
        loader: loaderComponent
    }
}
export default appleDepDialogComponent
