import { useVue } from "@/app"
import config from "@/classes/config"
import { useFeatureStore } from "@/classes/featureStore"
import objectStores from "@/classes/init"
import products from "@/classes/objectTypes"
import type { UscProfile } from "@/classes/unifiedSecurityConsole/uscProfiles"
import useRouterStore from "@/router/routerStore"
import { useStore } from "@/store/vuex.store"
import moment from "moment"
import devLog from "../classes/log"
import getterHelpers from "./helpers.getters"
import jsonHelpers from "./helpers.json"
import licenseHelpers from "./helpers.license"
import tenantHelpers from "./helpers.tenants"
import validationHelpers from "./helpers.validation"

const jsonFormHelpers = {
    getFieldById: function (form: JsonFormShemaObject, id: string) {
        return jsonHelpers.jsonSearchPair(form, "id", id, ["options"]) as
            | JsonFormShemaObject
            | undefined
    },
    getFieldByProperty: function (form: JsonFormShemaObject, property: string) {
        return jsonHelpers.jsonSearchPair(form, "property", property, ["options"])
    },
    areConditionsFulfilled: function (
        component: any,
        conditions: JsonFormCondition[],
        field?: any,
        isErrorConditions: boolean = false
    ): boolean {
        let areConditionsFulfilled: boolean = true
        const routerContext = useRouterStore().getContext
        const activeAccountId = getterHelpers.useStore().state.session.activeAccountId
        if (conditions && conditions.length > 0) {
            for (let conditionIndex in conditions) {
                let condition = conditions[conditionIndex]
                let inputObj: any = condition.refId
                    ? jsonFormHelpers.getFieldById(
                          component.form || component.fullJson,
                          condition.refId
                      ) || undefined
                    : field || undefined
                if (
                    condition.hasManagedConfiguration === undefined &&
                    condition.root === undefined &&
                    inputObj
                ) {
                    let hasMdmDevices: boolean = true
                    switch (condition.operator) {
                        case "some_fulfilled":
                            let isSubConditionFulfilled = false

                            for (const recursiveCondition of condition.conditions || []) {
                                const fulfilled = jsonFormHelpers.areConditionsFulfilled(
                                    component,
                                    [recursiveCondition],
                                    field,
                                    isErrorConditions
                                )

                                if (fulfilled === true) {
                                    isSubConditionFulfilled = true
                                    break
                                }
                            }

                            if (isSubConditionFulfilled === false) {
                                areConditionsFulfilled = false
                            }
                            break
                        case "equals":
                            if (inputObj.value != condition.value) {
                                areConditionsFulfilled = false
                            }
                            break
                        case "equals_not":
                            if (inputObj.value == condition.value) {
                                areConditionsFulfilled = false
                            }
                            break
                        case "is_one_of":
                            if (condition.value.indexOf(inputObj.value) == -1) {
                                areConditionsFulfilled = false
                            }
                            break
                        case "has_one_of":
                            let hasOneOf = condition.value.some(
                                (conditionValue: string | number) => {
                                    if (
                                        (String(inputObj.value) || []).indexOf(
                                            conditionValue.toString() as never
                                        ) != -1
                                    ) {
                                        return true
                                    }
                                }
                            )
                            if (!hasOneOf) {
                                areConditionsFulfilled = false
                            }
                            break
                        case "no_fields":
                            if ((inputObj.fields || []).length > 0) {
                                areConditionsFulfilled = false
                            }
                            break
                        case "minLength":
                            if (!validationHelpers.checkLength(inputObj.value, condition.value)) {
                                areConditionsFulfilled = false
                            }
                            break
                        case "maxLength":
                            if (
                                !validationHelpers.checkLength(
                                    inputObj.value,
                                    undefined,
                                    condition.value
                                )
                            ) {
                                areConditionsFulfilled = false
                            }
                            break
                        case "date_is_after":
                            if (inputObj.value && field.value) {
                                let firstDate = moment(inputObj.value, "DD.MM.YYYY")
                                let thisDate = moment(field.value, "DD.MM.YYYY")
                                if (firstDate.isBefore(thisDate)) {
                                } else {
                                    areConditionsFulfilled = false
                                }
                            }

                            break
                        case "has_mdm_device":
                            hasMdmDevices =
                                inputObj.value.some((deviceId: string) => {
                                    if (activeAccountId) {
                                        let device = config.canUseNewObjectType("iosDevices")
                                            ? products.mobileSecurity.iosDevices
                                                  .useStore?.()
                                                  .getObjectStoreObject(activeAccountId, deviceId)
                                            : useStore()?.getters.getObject({
                                                  accountId: activeAccountId,
                                                  productType: "mobileSecurity",
                                                  objectType: "devices",
                                                  objectId: deviceId,
                                                  property: "deviceId"
                                              })
                                        let licenseInfo = licenseHelpers.getLicenseByUUID(
                                            activeAccountId,
                                            device?.licenseUUID || ""
                                        )
                                        return licenseInfo?.type == "MDM"
                                    }
                                    return false
                                }) || false

                            if (!hasMdmDevices) {
                                areConditionsFulfilled = false
                            }
                            break
                        case "!has_mdm_device":
                            hasMdmDevices =
                                inputObj.value.some((deviceId: string) => {
                                    if (activeAccountId) {
                                        let device = config.canUseNewObjectType("iosDevices")
                                            ? products.mobileSecurity.iosDevices
                                                  .useStore?.()
                                                  .getObjectStoreObject(activeAccountId, deviceId)
                                            : useStore()?.getters.getObject({
                                                  accountId: activeAccountId,
                                                  productType: "mobileSecurity",
                                                  objectType: "devices",
                                                  objectId: deviceId,
                                                  property: "deviceId"
                                              })
                                        let licenseInfo = licenseHelpers.getLicenseByUUID(
                                            activeAccountId,
                                            device?.licenseUUID || ""
                                        )
                                        return licenseInfo?.type == "MDM"
                                    }
                                    return false
                                }) || false

                            if (hasMdmDevices) {
                                areConditionsFulfilled = false
                            }
                            break
                        case "isGeneratedUscProfile":
                            if (useVue().$refs?.editpage?.rootObject != undefined) {
                                let object: UscProfile = useVue().$refs?.editpage?.rootObject
                                if (
                                    object?.template?.tenantDomains == undefined &&
                                    object?.template?.uuid != undefined &&
                                    object?.template?.childProfiles == undefined
                                ) {
                                    areConditionsFulfilled = false
                                }
                            }
                            break
                        case "hasClusterUtmSelected":
                            const hasClusterUtm = (inputObj.value || []).some((utmId: string) => {
                                const utm = objectStores.uscUtms.getObjectFromStore(
                                    activeAccountId || "",
                                    utmId
                                )
                                return utm?.cluster?.isCluster == true
                            })
                            if (hasClusterUtm) {
                                areConditionsFulfilled = false
                            }
                            break
                        case "!hasClusterUtmSelected":
                            const hasNoClusterUtm = !(inputObj.value || []).some(
                                (utmId: string) => {
                                    const utm = objectStores.uscUtms.getObjectFromStore(
                                        activeAccountId || "",
                                        utmId
                                    )
                                    return utm?.cluster?.isCluster == true
                                }
                            )
                            if (hasNoClusterUtm) {
                                areConditionsFulfilled = false
                            }
                            break
                        case "hasClusterUtmTagSelected":
                            if (inputObj.value?.length) {
                                const utms = objectStores.uscUtms.getObjectsFromStore(
                                    activeAccountId || ""
                                )
                                const hasClusterUtmByTag = utms.some((utm) => {
                                    let hasTag = (inputObj.value || []).some((tag: string) => {
                                        return utm.tags.indexOf(tag) != -1
                                    })
                                    return utm?.cluster?.isCluster && hasTag
                                })
                                if (hasClusterUtmByTag) {
                                    areConditionsFulfilled = false
                                }
                            }
                            break
                        case "!hasClusterUtmTagSelected":
                            if (inputObj.value?.length) {
                                const utms = objectStores.uscUtms.getObjectsFromStore(
                                    activeAccountId || ""
                                )
                                const hasNoClusterUtmByTag = !utms.some((utm) => {
                                    let hasTag = (inputObj.value || []).some((tag: string) => {
                                        return utm.tags.indexOf(tag) != -1
                                    })
                                    return utm?.cluster?.isCluster && hasTag
                                })
                                if (hasNoClusterUtmByTag) {
                                    areConditionsFulfilled = false
                                }
                            }
                            break
                        case "uscProfileTagsErrorCheck":
                            let accountID = useStore().state.session.activeAccountId
                            if (accountID) {
                                if (
                                    [null, undefined].indexOf(
                                        useStore().state.session.accounts[accountID].unifiedSecurity
                                            .account.parent_accountid
                                    ) != -1
                                ) {
                                    let isTemplateField = this.getFieldById(
                                        useVue().$refs?.editpage?.form,
                                        "_use_template"
                                    )
                                    if (isTemplateField?.value == true) {
                                        let tagsField = this.getFieldById(
                                            useVue().$refs?.editpage?.form,
                                            "tags"
                                        )

                                        if (tagsField?.value.length == 0) {
                                            areConditionsFulfilled = false
                                            if (isErrorConditions && useVue().$refs?.editpage) {
                                                useVue().$refs.editpage.refreshCounter++
                                            }
                                        }
                                    }
                                }
                            }
                            break
                        case "uniqueApiKeyName":
                            if (useVue().context != "edit") {
                                const accountId = useStore().state.session.activeAccountId
                                const apiKeys =
                                    (config.canUseNewObjectType("apiKeys")
                                        ? products.unifiedSecurity.apiKeys
                                              .useStore?.()
                                              .getObjectStoreObjects(accountId || "")
                                        : useStore().state.session.accounts[accountId || ""]
                                              .unifiedSecurity.apiKeys.items) || []
                                const isNameTaken = apiKeys.some((apiKey) => {
                                    return apiKey.name == field.value
                                })
                                if (isNameTaken) {
                                    areConditionsFulfilled = false
                                }
                            }
                            break
                        case "is_Ipv4Cidr_Ipv6_Fqdn": {
                            const fieldValue: Array<string> | string | undefined = field.value
                            if (Array.isArray(fieldValue) && fieldValue.length) {
                                areConditionsFulfilled = !fieldValue.some((value: string) => {
                                    return (
                                        validationHelpers.isFQDN(value) == false &&
                                        validationHelpers.isIpv4Cidr(value) == false &&
                                        validationHelpers.isIpV6(value) == false
                                    )
                                })
                            } else if (typeof fieldValue == "string") {
                                areConditionsFulfilled =
                                    validationHelpers.isFQDN(fieldValue) ||
                                    validationHelpers.isIpv4Cidr(fieldValue) ||
                                    validationHelpers.isIpV6(fieldValue)
                            }
                            break
                        }
                        case "is_ipv4_ipv6": {
                            const fieldValue: Array<string> | string | undefined = field.value
                            if (Array.isArray(fieldValue) && fieldValue.length) {
                                areConditionsFulfilled = !fieldValue.some((value: string) => {
                                    return (
                                        validationHelpers.isIp(value) == false &&
                                        validationHelpers.isIpV6(value) == false
                                    )
                                })
                            } else if (typeof fieldValue == "string") {
                                areConditionsFulfilled =
                                    validationHelpers.isIp(fieldValue) ||
                                    validationHelpers.isIpV6(fieldValue)
                            }
                            break
                        }
                        case "matches_regex":
                            areConditionsFulfilled = inputObj.value.match(
                                new RegExp(condition.value)
                            )
                            break
                        case "feature_flag":
                            areConditionsFulfilled = useFeatureStore().isEnabled(condition.value)
                            break

                        // PIN validations
                        case "increasing_numbers":
                            const increasingNumbers = "01234567890"

                            if (
                                inputObj.value.length === condition.value &&
                                increasingNumbers.includes(inputObj.value)
                            ) {
                                areConditionsFulfilled = false
                            }
                            break

                        case "decreasing_numbers":
                            const decreasingNumbers = "09876543210"

                            if (
                                inputObj.value.length === condition.value &&
                                decreasingNumbers.includes(inputObj.value)
                            ) {
                                areConditionsFulfilled = false
                            }
                            break

                        case "same_numbers":
                            const isSameNumber = /^(\d)\1+$/.test(inputObj.value)
                            if (isSameNumber) {
                                areConditionsFulfilled = false
                            }
                            break
                    }
                }

                if (condition.hasManagedConfiguration === true) {
                    areConditionsFulfilled = false
                    inputObj = jsonFormHelpers.getFieldById(
                        component.form || component.fullJson,
                        "emm_applications"
                    )
                    if (inputObj == undefined) {
                        devLog.log("[formpage]", "Input was not found", condition)
                        break
                    }
                    if (inputObj.fields.length) {
                        for (let i in inputObj.fields) {
                            let thisField: any = inputObj.fields[i]
                            if (
                                jsonHelpers.getObjectProperty(thisField.fields[6], "value") !=
                                undefined
                            ) {
                                areConditionsFulfilled = true
                                break
                            }
                        }
                    }
                }

                if (condition.root) {
                    switch (condition.root) {
                        case "context":
                            if (
                                condition.operator == "equals" &&
                                routerContext != condition.value
                            ) {
                                areConditionsFulfilled = false
                            } else if (
                                condition.operator == "equals_not" &&
                                routerContext == condition.value
                            ) {
                                areConditionsFulfilled = false
                            }
                            break
                        case "hasLicense":
                            if (
                                !licenseHelpers.hasLicense(activeAccountId || "", condition.value)
                            ) {
                                areConditionsFulfilled = false
                            }
                            break
                        case "!hasLicense":
                            if (licenseHelpers.hasLicense(activeAccountId || "", condition.value)) {
                                areConditionsFulfilled = false
                            }
                            break
                        case "hasValidLicense":
                            if (
                                !licenseHelpers.hasLicense(
                                    activeAccountId || "",
                                    condition.value,
                                    "valid"
                                )
                            ) {
                                areConditionsFulfilled = false
                            }
                            break
                        case "!hasValidLicense":
                            if (
                                licenseHelpers.hasLicense(
                                    activeAccountId || "",
                                    condition.value,
                                    "valid"
                                )
                            ) {
                                areConditionsFulfilled = false
                            }
                            break
                        case "isDev":
                            if (config.devMode != condition.value) {
                                areConditionsFulfilled = false
                            }
                            break
                        case "canUseEnterprise":
                            if (
                                !tenantHelpers.hasFunctionality(activeAccountId || "", "enterprise")
                            ) {
                                areConditionsFulfilled = false
                            }
                            break
                        case "isReseller":
                            if (activeAccountId) {
                                if (
                                    [null, undefined].indexOf(
                                        useStore()?.state.session.accounts[activeAccountId || ""]
                                            .unifiedSecurity.account.parent_accountid
                                    ) == -1
                                ) {
                                    areConditionsFulfilled = false
                                }
                            }
                            break
                        case "isTenantOfOneOfResellers":
                            if (condition.value.length) {
                                if (activeAccountId) {
                                    if (
                                        condition.value.indexOf(
                                            String(
                                                useStore()?.state.session.accounts[
                                                    activeAccountId || ""
                                                ].unifiedSecurity.account.parent_accountid
                                            )
                                        ) == -1
                                    ) {
                                        areConditionsFulfilled = false
                                    }
                                }
                            }
                            break
                        case "iosProfileHasVpnConfigOrSecurityActive":
                            let hasVpnConfigIos =
                                useVue().$refs.editpage?.rootObject?.sun != undefined
                            let hasSecurityActiveIos =
                                jsonFormHelpers.getFieldById(
                                    component.form || component.fullJson,
                                    "section_security"
                                )?.activator.value || false
                            areConditionsFulfilled = hasSecurityActiveIos || hasVpnConfigIos
                            break
                        case "androidProfileHasVpnConfigOrSecurityActive":
                            let hasVpnConfigAndroid =
                                useVue().$refs.editpage?.rootObject?.sun != undefined
                            let hasSecurityActiveAndroid =
                                jsonFormHelpers.getFieldById(
                                    component.form || component.fullJson,
                                    "toggleEnterpriseSecuritySection"
                                )?.value || false
                            areConditionsFulfilled = hasSecurityActiveAndroid || hasVpnConfigAndroid
                            break
                        case "hasMobSecOrVpnConfig":
                            let profileHasVpnConfig =
                                useVue().$refs.editpage?.rootObject?.sun != undefined
                            areConditionsFulfilled =
                                licenseHelpers.hasLicense(
                                    activeAccountId || "",
                                    "Mobile Security",
                                    "valid"
                                ) || profileHasVpnConfig
                            break
                        case "hasVpnConfig":
                            return useVue().$refs.editpage?.rootObject?.sun != undefined
                            break
                    }
                }

                if (condition.type == "hasFunctionality") {
                    if (!activeAccountId) {
                        throw "Missing accountId"
                    }
                    areConditionsFulfilled =
                        areConditionsFulfilled && condition.functionality
                            ? tenantHelpers.hasFunctionality(
                                  activeAccountId,
                                  condition.functionality
                              )
                            : false
                }
            }
        }
        return areConditionsFulfilled
    },
    deleteUseLessLoopfields(object: any) {
        let testFields: any = {
            applications: "packageName",
            permissionGrants: "permission",
            choosePrivateKeyRules: "urlPattern",
            persistentPreferredActivities: "receiverActivity",
            policyEnforcementRules: "settingName",
            setupActions: "launchApp",
            openNetworkConfiguration: {
                NetworkConfigurations: "Name"
            }
        }
        let property: string = ""
        for (let field in testFields) {
            if (typeof testFields[field] == "string") {
                property = testFields[field]
                if (jsonHelpers.getObjectProperty(object, field)) {
                    let i: number = object[field].length
                    while (i--) {
                        let loopField = object[field][i]
                        if (!jsonHelpers.getObjectProperty(loopField, property)) {
                            object[field].splice(i, 1)
                        }
                    }
                }
            } else if (typeof testFields[field] == "object") {
                for (let childField in testFields[field]) {
                    property = testFields[field][childField]
                    if (jsonHelpers.getObjectProperty(object, field + "." + childField)) {
                        let i: number = object[field][childField].length
                        while (i--) {
                            let loopField = object[field][childField][i]
                            if (!jsonHelpers.getObjectProperty(loopField, property)) {
                                object[field][childField].splice(i, 1)
                            }
                        }
                    }
                }
            }
        }
        for (let field in testFields) {
            if (typeof testFields[field] == "string") {
                property = testFields[field]
                if (jsonHelpers.getObjectProperty(object, field)) {
                    let i: number = object[field].length
                    if (i == 0) {
                        delete object[field]
                    }
                }
            } else if (typeof testFields[field] == "object") {
                for (let childField in testFields[field]) {
                    property = testFields[field][childField]
                    if (jsonHelpers.getObjectProperty(object, field + "." + childField)) {
                        let i: number = object[field][childField].length
                        if (i == 0) {
                            delete object[field][childField]
                        }
                        if (jsonHelpers.isObjectEmpty(object[field])) {
                            delete object[field]
                        }
                    }
                }
            }
        }
        return object
    }
}

export default jsonFormHelpers
