const en = {
    "XPlease enter": "Please enter",
    "Xto confirm": "to confirm",
    XLink: "Link",
    "Xo'clock": "o'clock",
    "The apple servers have reported following error:":
        "Die Apple Server haben folgenden Fehler zurückgeliefert:",
    "Automatic VPP license assignment": "Automatische VPP Lizenzzuweisung",
    "Choose wether the VPP app license should be automatically assigned to the device or not.":
        "Wählen Sie aus, ob die VPP-App-Lizenz automatisch dem Gerät zugewiesen werden soll oder nicht.",
    "A profile with this name already exists. Saving this profile would overwrite the existing one.":
        "Ein Profil mit diesem Namen existiert bereits. Speichern dieses Profils würde das bestehende überschreiben.",
    Productselection: "Produktauswahl",
    "Inventory list": "Inventarliste",
    xFrom: "From",
    xState: "State",
    "must match a schema in anyOf": "must match one of the points mentioned",
    "Resuming job at label dryrun": 'Resuming Job at "Dryrun".',
    "Resuming job at label reboot": 'Resuming Job at "Reboot".',
    "Resuming job at label finalize": 'Resuming Job at "Finalize".',
    "Job is parked with label dryrun. Waiting for resume response.":
        'Job is parked at "Dryrun". Waiting for resume response.',
    "Job is parked with label reboot. Waiting for resume response.":
        'Job is parked at "Reboot". Waiting for resume response.',
    "Job is parked with label finalize. Waiting for resume response.":
        'Job is parked at "Finalize". Waiting for resume response.',
    "Job is done [Update is done]": "Job is done (Update)",
    xDashboard: "Dashboard",
    "Benutzerdaten sind gespeichert": "Saved Credentials",
    Favorit: "Favorite",
    "Zum Starten wird ein PIN benötigt": "A PIN is needed to start the connection",
    "Letzte Änderung": "Last Change",
    "Log von": "Log of",
    "Hole Logeinträge...": "Getting log entrys...",
    "Das Verbindungs-Log des VPN-Clients kann nach den Schweregraden gefiltert oder sortiert werden. Die Schweregrade sind:":
        "The connection log of the VPN client can be filtered or sorted according to the severity levels. The severity levels are:",
    "Information(Info)": "Information(Info)",
    "Warnungen(Warning)": "Warnings(Warning)",
    "Fehler(Error)": "Error(Error)",
    Datum: "Date",
    Schweregrad: "Severity",
    Nachricht: "Message",
    "WSC-Risiko": "WSC risk",
    "WSC-Gefährdung": "WSC danger",
    "Keine Aktion erforderlich": "No action required",
    "Status wird nicht von der WSC überwacht": "Status is not monitored by the WSC",
    "Computer ist möglicherweise gefährdet": "Computer may be at risk",
    "WSC schützt den Computer nicht aktiv": "WSC does not actively protect the computer",
    Firewall: "Firewall",
    "Automatische Updates": "Automatic updates",
    Antivirus: "Antivirus",
    "Internet-Einstellungen": "Internet settings",
    "WSC-Service-Status": "WSC service status",
    "UAC-Einstellungen": "UAC settings",
    "Fehlende Berechtigung": "Missing permission",
    "PIN deaktiviert": "PIN disabled",
    "PIN gesperrt": "PIN locked",
    "Die Websession PIN ist aufgrund mehrerer Fehleingaben gesperrt. Bitte schalten Sie die Websession PIN auf der Core-UTM wieder frei.":
        "The websession PIN is locked due to several incorrect entries. Please unlock the websession PIN on the Core-UTM again.",
    "Die Websession PIN ist auf der UTM deaktiviert. Bitte aktivieren Sie die PIN um die Konfiguration veröffentlichen zu können.":
        "The websession PIN is deactivated on the UTM. Please activate the PIN to be able to publish the configuration.",
    "Die Core-UTM ist offline. Bitte stellen Sie sicher dass die Core-UTM erreichbar ist.":
        "The Core-UTM is offline. Please make sure that the Core-UTM is accessible.",
    "Die Core-UTM ist nicht für die Unified Network Console freigeschaltet. Bitte aktivieren Sie die Unified Network Console für die Core-UTM.":
        "The Core-UTM is not enabled for the Unified Network Console. Please activate the Unified Network Console for the Core-UTM.",
    "Nicht alle UTMs in der Konfiguration sind online. Unter Umständen müssen Sie die Veröffentlichung der Konfiguration erneut durchführen, wenn die betroffenen UTMs wieder online sind.":
        "Not all UTMs in the configuration are online. You may have to publish the configuration again when the affected UTMs are online again.",
    "Nicht alle UTMs in der Konfiguration sind für die Unified Network Console freigeschaltet. Bitte aktivieren Sie die Unified Network Console für alle UTMs aus dieser Konfiguration, um die Funktionalität auf allen UTMs nutzen zu können.":
        "Not all UTMs in the configuration are enabled for the Unified Network Console. Please activate the Unified Network Console for all UTMs in this configuration in order to be able to use the functionality on all UTMs.",
    "Es fehlen Satelliten oder Roadwarrior.": "Missing satellites or roadwarrior.",
    "Es gibt nichts zu veröffentlichen.": "There is nothing to publish.",
    "Diese UTM wird von der Unified Network Console unterstützt und befindet sich in einer Konfiguration":
        "This UTM is supported by the Unified Network Console and is in a configuration",
    "Dieses VPN wird von der VPN-Konfiguration unterstützt und befindet sich in einer Konfiguration":
        "This VPN is supported by the VPN configuration and is in a configuration",
    Verfügbar: "Available",
    "Nicht verfügbar": "Unavailable",
    "CPU Auslastung:": "CPU utilization:",
    Arbeitsspeicherinformationen: "Working memory information",
    Speicher: "Memory",
    "Diagnose der Verbindung": "Connection diagnosis",
    "Remote-Host aus der Konfiguration": "Remote host of the configuration",
    "Remote-Port aus der Konfiguration": "Remote Port of the configuration",
    "Aufgelöste IP des Remote-Hosts": "Resolved IP of the remote host",
    "Remote-Host und Port sind erreichbar": "Remote host and port are reachable",
    "Zeitüberschreitung beim Abrufen von Daten": "Fetching data timeouted",
    "Das Abrufen des Gerätestatus dauert ungewöhnlich lange. Bitte überprüfen Sie den Verbindungsstatus Ihres VPNs.":
        "Fetching the device status takes unusually long. Please check the connection status of your VPN.",
    "Das Abrufen der Verbindungsinformationen dauert ungewöhnlich lange. Bitte überprüfen Sie den Verbindungsstatus Ihres VPNs.":
        "Fetching the connection information takes unusually long. Please check the connection status of your VPN.",
    "Das Abrufen der Verbindungen dauert ungewöhnlich lange. Bitte überprüfen Sie den Verbindungsstatus Ihres VPNs.":
        "Fetching the connections takes unusually long. Please check the connection status of your VPN.",
    "Öffentliche IP des Clients": "Public IP of the client",
    "Öffentliche IP": "Public IP",
    Erreichbar: "Reachable",
    "Hole Verbindungsinformationen...": "Fetching connection information...",
    'Vergeben Sie einen Alias (wie z. B. "LG1"), um generierte Objekte auf der UTM leichter zuordnen zu können. (max 32 Zeichen (a-zA-Z0-9_-)).':
        'Assign an alias (such as "LG1") to make it easier to identify generated objects on the UTM. (max 32 characters (a-zA-Z0-9_-))',
    "Die UTM wurde erst kürzlich aus der Konfiguration entfernt und kann erst wieder hinzugefügt werden, wenn sie sich nach Löschung der alten Konfigurationsdaten einmal zurückgemeldet hat. Bitte haben Sie einen Moment Geduld.":
        "The UTM was only recently removed from the configuration and can only be added again once it has reported back after the old configuration data has been deleted. Please be patient for a moment.",
    "Aktualisiere...": "Refreshing...",
    "Die Daten der Windows-Sicherheit kommen von dem Windows-Security-Center-Dienst (wscsvc). Das WSC sammelt die aktuellen Informationen zur Sicherheit des Windows-Clients. Neben dem Schutz durch Antivirenprodukte von Drittanbietern, Windows Firewall, dem Status des Dienstes, wird die Konfiguration der Internet-Einstellungen, des Automatischen-Updates und der Benutzerkontensteuerung (UAC) ausgewertet.":
        "The Windows security data of the Windows Security Center service (wscsvc). The WSC collects current information on the security of the Windows client. In addition to the protection provided by third-party antivirus products, Windows Firewall, the status of the service, the configuration of the Internet settings, Automatic Update and User Account Control (UAC) are analysed.",
    'Jeder dieser Security-Provider hat einen Status von "Gut", "Nicht überwacht", "Schlecht" und "Temporär deaktiviert".':
        "Each of these security providers has a status of ‘Good’, ‘Not monitored’, ‘Poor’ and ‘Snooze’.",
    "Erklärungen:": "Explanation:",
    "Windows-Sicherheit": "Windows-Security",
    "Gut: Der Status der Sicherheitsanbieterkategorie ist gut und erfordert keine Aufmerksamkeit des Benutzers.":
        "Good: The status of the security provider category is good and requires no attention from the user.",
    "Nicht überwacht: Der Status der Sicherheitsanbieterkategorie wird von WSC nicht überwacht.":
        "Not monitored: The status of the security provider category is not monitored by WSC.",
    Gut: "Good",
    "Nicht überwacht": "Not monitored",
    Schlecht: "Poor",
    "Temporär deaktiviert": "Snooze",
    "Automatisches-Update": "Automatic Updates",
    Benutzerkontensteuerung: "User account control",
    "Dienst-Status": "Service status",
    "Schlecht: Der Sicherheitsanbieterkategorie ist schlecht, und der Computer ist möglicherweise gefährdet.":
        "Poor: The security provider category is bad and the computer may be at risk.",
    'Temporär deaktiviert: Die Sicherheitsanbieterkategorie befindet sich im Zustand "Temporär deaktiviert", der Computer ist möglicherweise gefährdet.':
        "Snooze: The security provider category is in the Temporarily Disabled state, and the computer may be at risk.",
    Pflichtfeld: "Required",
    "Wählen Sie ein bestehendes Netzwerkobjekt aus oder legen Sie ein neues an.":
        "Select an existing network object or create a new one.",
    "Wählen Sie einen bestehenden Dienst aus oder legen Sie einen neuen an.":
        "Select an existing service or create a new one.",
    "Wählen Sie aus ob Sie eine UTM oder einen Roadwarrior hinzufügen möchten":
        "Select whether you want to add a UTM or a Roadwarrior",
    "Der Name der für die zu vergebenen Adressen verwendet wird":
        "The name used for the addresses to be assigned",
    "Sie besitzen aktuell keine Lizenzen, die Sie zur Nutzung des Securepoint Unified Security Portals berechtigen. Bitte nutzen Sie das Reseller Portal für eine Neulizenzierung oder Verlängerung.":
        "You currently do not have any licenses that entitle you to use the Securepoint Unified Security Portal. Please use the Reseller Portal for a relicensing or renewal.",
    "Dieses Gerät ist einem Profil zugewiesen, das Teil eines Transfernetzes in der VPN-Konfiguration ist. Dieses Transfernetz hat keine ausreichende Kapazität für dieses Gerät mehr.":
        "This device is assigned to a profile that is part of a transfer network in the VPN-Configuration. This transfer network does not have sufficient capacity for this device.",
    "Dieses Gerät ist einem Profil zugewiesen, das Teil eines Transfernetzes in der VPN-Konfiguration ist. Dieses Transfernetz hat keine ausreichende Kapazität mehr.":
        "This device is assigned to a profile that is part of a transfer network in the VPN-Configuration. This transfer network does not have sufficient capacity.",
    "Dieses Gerät ist einem Profil zugewiesen, das Teil eines Transfernetzes in der VPN-Konfiguration ist.":
        "This device is assigned to a profile that is part of a transfer network in the VPN-Configuration.",
    "Das Gerät muss online sein, um die Daten anzufordern":
        "The device must be online to request the data",
    "Websession starten": "Start websession",
    "Nicht verbunden": "Not connected",
    "Um diese Aktion durchführen zu können, muss das Gerät Online sein":
        "To perform this action, the device must be online",
    "Keine Änderung vorhanden": "There is no change",
    '<b>Achtung</b>: Diese Funktion ist veraltet. In Profilen vor Version 1.31 können Apps gelöscht, aber nicht neu hinzugefügt werden. Bitte nehmen Sie die Neuzuweisung von Applikationen zu Geräten stattdessen über den im Seitenmenü befindlichen Menüpunkt "Apps" vor. Dies ermöglicht Ihnen auch die spätere Deinstallation der Anwendungen.':
        "<b>Attention</b>: This function is deprecated. In profiles prior to version 1.31, apps can be deleted but not added. Instead, please reassign applications to devices via the menu item \“Apps\” in the side menu. This also allows you to uninstall the applications later.",
    '<b>Achtung</b>: Diese Funktion ist veraltet. In Profilen vor Version 1.31 können Webclips gelöscht, aber nicht neu hinzugefügt werden. Bitte nehmen Sie die Neuzuweisung von Webclips zu Geräten stattdessen über den im Seitenmenü befindlichen Menüpunkt "Apps" vor. Dies ermöglicht Ihnen auch die spätere Deinstallation der Webclips.':
        "<b>Attention</b>: This function is deprecated. In profiles prior to version 1.31, web clips can be deleted but not added. Instead, please reassign web clips to devices via the menu item \“Apps\” in the side menu. This also allows you to uninstall the web clips later.",
    '<b>Achtung</b>: Das Verwalten von Apps und Webclips über Profile ist veraltet und nicht mehr verfügbar. Bitte nehmen Sie die Zuweisung von Apps und Webclips zu Geräten stattdessen über den im Seitenmenü befindlichen Menüpunkt "Apps" vor.':
        "<b>Attention</b>: Managing apps and web clips via profiles is deprecated and no longer available. Please assign apps and web clips to devices via the menu item \“Apps\” in the side menu instead.",
    Trennen: "Disconnect",
    Verbinden: "Connect",
    "Benutzerdaten müssen gespeichert sein": "User data must be saved",
    "Diese Aktion kann nicht durchgeführt werden, da Otp aktiv ist":
        "This action cannot be performed because Otp is active",
    "Diese Aktion kann nicht durchgeführt werden,da Pin aktiv ist":
        "This action cannot be performed because Pin is active",
    "Verbindet...": "Connecting...",
    "WireGuard-Verbindung starten": "Start a WireGuard-Connection",
    "Diese Verbindung erfordert eine PIN zur Authentifizierung.":
        "This connection requires a PIN for authentication.",
    "Die WireGuard-Verbindung auf dem Securepoint VPN Client ist durch eine PIN geschützt.":
        "The WireGuard connection on the Securepoint VPN Client is protected by a PIN.",
    "Diese Einstellung wird direkt auf dem Client vorgenommen.":
        "This setting is set directly on the client.",
    'Details finden Sie in unserem <a href="https://wiki.securepoint.de/VPN/VPN-Client" target="_blank">Wiki</a>.':
        'You can find details in our  <a href="https://wiki.securepoint.de/VPN/VPN-Client" target="_blank">Wiki</a>.',
    "Eine andere Verbindung wird aktuell aufgebaut / getrennt. Bitte warten Sie bis der Vorgang abgeschlossen ist":
        "Another connection is currently being established / disconnected. Please wait until the process is completed",
    Einstellungen: "Preferences",
    "Verbindung konnte nicht aufgebaut werden.": "Connection could not be established.",
    "Verbindung konnte nicht getrennt werden": "Connection could not be disconnected",
    "Beim Versuch die Verbindung von %s auf %y zu trennen wurde die Zeit überschritten. Bitte versuchen Sie es erneut.":
        "The time was exceeded when trying to disconnect %s on %y. Please try again.",
    "Beim Versuch die Verbindung von %s auf %y aufzubauen wurde die Zeit überschritten. Bitte versuchen Sie es erneut.":
        "The time was exceeded when trying to establish the connection from %s on %y. Please try again.",
    "Um dieses Feature nutzern zu können, muss das Gerät Online sein":
        "In order to use this feature, the device has to be online",
    "Verbindung konnte nicht gelöscht werden": "Connection could not be deleted",
    "Beim Versuch die Verbindung %s auf %y zu löschen wurde die Zeit überschritten. Bitte versuchen Sie es erneut.":
        "The time was exceeded when trying to delete the connection %s on %y. Please try again.",
    Entfernen: "Remove",
    "Clientoperationen bieten die Möglichkeit, den Client beim Benutzer zu starten, zu beenden oder zu aktualisieren. Diese Aktionen benötigen einen aktuellen Status. Dieser kann über die Schaltfläche <strong>Status abfragen</strong> ermittelt werden. Ist keine aktive Benutzersitzung vorhanden, können keine Aktionen durchgeführt werden.":
        "Client operations allow the client to be started, ended or updated by the user. These actions require a current state. This can be fetched by using the <strong>Query state</strong> button. If there is no active user session, no actions can be performed.",
    "Status abfragen": "Query state",
    "Client starten": "Start client",
    "Client beenden": "Exit client",
    "Achtung: Momentan besteht keine aktive Benutzer-Sitzung. Der Securepoint VPN Client kann nicht gestartet werden oder andere benutzerdefinierte Aktionen ausführen.":
        "Attention: There is currently no active user session. The Securepoint VPN Client cannot be started or perform other user-defined actions.",
    Aktionen: "Actions",
    "Die Verbindung wird automatisch bei Trennung neu verbunden":
        "The connection is automatically reconnected when disconnected",
    "Suche Logeinträge": "Search log entries",
    "Keine Daten gefunden": "No data found",
    "Geben Sie eine privates IPv4- oder IPv6-Netz ein": "Enter a private IPv4 or IPv6 network",
    Satellit: "Satellite",

    // Non Compliance
    "Dieser Wert wird nicht verwendet.": "This value is not used.",
    "Die Einstellung wird von der API-Ebene der auf dem Gerät laufenden Android-Version nicht unterstützt.":
        "The setting is not supported in the API level of the Android version running on the device.",
    "Der Verwaltungsmodus (z. B. vollständig verwaltet oder Arbeitsprofil) unterstützt diese Einstellung nicht.":
        "The management mode (such as fully managed or work profile) doesn't support the setting.",
    "Der Benutzer hat nicht die erforderlichen Maßnahmen ergriffen, um der Einstellung zu entsprechen.":
        "The user has not taken required action to comply with the setting.",
    "Die Einstellung hat einen ungültigen Wert.": "The setting has an invalid value.",
    "Die für die Umsetzung der Richtlinie erforderliche Anwendung ist nicht installiert.":
        "The app required to implement the policy is not installed.",
    "Die Richtlinie wird von der Version der Android-Geräterichtlinie auf dem Gerät nicht unterstützt.":
        "The policy is not supported by the version of Android Device Policy on the device.",
    "Eine blockierte Anwendung ist installiert.": "A blocked app is installed.",
    "Die Einstellung wurde zum Zeitpunkt des Berichts noch nicht vorgenommen, wird aber voraussichtlich in Kürze vorgenommen werden.":
        "The setting hasn't been applied at the time of the report, but is expected to be applied shortly.",
    "Die Einstellung kann nicht auf die App angewendet werden, weil die Anwendung sie nicht unterstützt, z. B. weil ihre Ziel-SDK-Version nicht hoch genug ist.":
        "The setting can't be applied to the app because the app doesn't support it, for example because its target SDK version is not high enough.",
    "Die Anwendung ist zwar installiert, wurde aber nicht auf den von der Richtlinie vorgeschriebenen Mindestversionscode aktualisiert.":
        "The app is installed, but it hasn't been updated to the minimum version code specified by policy.",
    "Das Gerät ist nicht mit den Anforderungen der Richtlinie kompatibel.":
        "The device is incompatible with the policy requirements.",

    //InstallationFailureReason
    "Dieser Wert ist unzulässig.": "This value is disallowed.",
    "Eine unbekannte Bedingung verhindert, dass die Anwendung installiert werden kann. Mögliche Gründe sind, dass das Gerät nicht über genügend Speicherplatz verfügt, die Netzwerkverbindung des Geräts unzuverlässig ist oder die Installation länger dauert als erwartet. Die Installation wird automatisch erneut versucht.":
        "An unknown condition is preventing the app from being installed. Some potential reasons are that the device doesn't have enough storage, the device network connection is unreliable, or the installation is taking longer than expected. The installation will be retried automatically.",
    "Die Installation ist noch im Gange.": "The installation is still in progress.",
    "Die Anwendung wurde in Play nicht gefunden.": "The app was not found in Play.",
    "Die Anwendung ist nicht mit dem Gerät kompatibel.": "The app is incompatible with the device.",
    "Die Anwendung wurde vom Administrator nicht genehmigt.":
        "The app has not been approved by the admin.",
    "Die Anwendung hat neue Berechtigungen, die vom Administrator nicht akzeptiert wurden.":
        "The app has new permissions that have not been accepted by the admin.",
    "Die Anwendung ist in dem Land des Nutzers nicht verfügbar.":
        "The app is not available in the user's country.",
    "Es sind keine Lizenzen verfügbar, die dem Benutzer zugewiesen werden können.":
        "There are no licenses available to assign to the user.",
    "Das Unternehmen ist nicht mehr bei Managed Google Play angemeldet oder der Administrator hat die aktuellen Managed Google Play-Nutzungsbedingungen nicht akzeptiert.":
        "The enterprise is no longer enrolled with Managed Google Play or the admin has not accepted the latest Managed Google Play Terms of Service.",
    "Der Benutzer ist nicht mehr gültig. Der Benutzer wurde möglicherweise gelöscht oder deaktiviert.":
        "The user is no longer valid. The user may have been deleted or disabled.",
    "Das Gerät des Benutzers verfügt nicht über genügend Speicherplatz, um die Anwendung zu installieren. Dies kann behoben werden, indem Sie den Speicherplatz auf dem Gerät freimachen. Die Installation oder Aktualisierung der Anwendung wird automatisch fortgesetzt, sobald das Gerät über ausreichend Speicherplatz verfügt.":
        "The user's device does not have sufficient storage space to install the app. This can be resolved by clearing up storage space on the device. App install or update will automatically resume once the device has sufficient storage.",
    "Ein Netzwerkfehler auf dem Gerät des Benutzers hat verhindert, dass die Installation erfolgreich durchgeführt werden konnte. Dies geschieht in der Regel, wenn die Internetverbindung des Geräts beeinträchtigt oder nicht verfügbar ist oder wenn ein Problem mit der Netzwerkkonfiguration vorliegt. Bitte stellen Sie sicher, dass das Gerät Zugang zu einer vollständigen Internetverbindung in einem Netzwerk hat, das die Android Enterprise Network Requirements erfüllt. Die Installation oder Aktualisierung der Anwendung wird automatisch fortgesetzt, sobald dies der Fall ist.":
        "A network error on the user's device has prevented the install from succeeding. This usually happens when the device's internet connectivity is degraded, unavailable or there's a network configuration issue. Please ensure the device has access to full internet connectivity on a network that meets Android Enterprise Network Requirements. App install or update will automatically resume once this is the case.",

    "Es gibt aktuell keine UTM, die als Satellit verwendet werden kann. Bitte stellen Sie sicher, dass Ihre UTMs auf dem neuesten Stand sind und mit der Unified Security Console verbunden sind, um sie zu konfigurieren.":
        "There is currently no UTM that can be used as a satellite. Please ensure that your UTMs are up to date and connected to the Unified Security Console in order to configure them.",
    Laufwerk: "Drive",
    "Das kopierte Profil enthält eine VPN-Konfiguration, die nicht in das neue Profil übernommen wird":
        "The copied profile contains a VPN configuration that is not transferred to the new profile",
    "VPN-Konfiguration": "VPN configuration",
    "Sichere Vernetzung dank Adaptive Secure Connect (ASC)":
        "Secure networking thanks to Adaptive Secure Connect (ASC)",
    "Mit ASC lassen sich UTMs und Endgeräte einfach und sicher in einer sternförmigen Netzwerktopologie miteinander verbinden.":
        "With ASC, UTMs and end devices can be easily and securely connected in a star-shaped network topology.",
    "Auswahl der Unternehmenszentrale als Core-UTM, den Mittelpunkt der Sterntopologie.":
        "Selection of the company headquarters as the core UTM, the center of the star topology.",
    "Anbindung weiterer Standorte als Satelliten-UTMs.":
        "Connection of additional locations as satellite UTMs.",
    "Konfiguration der Roadwarrior-Zugänge, die direkt mit der Core-UTM verbunden werden.":
        "Configuration of the Roadwarrior accesses, which are connected directly to the core UTM.",
    "Vergabe von Zugriffsrechten für Satelliten und Roadwarrior.":
        "Assignment of access rights for Satellites and Roadwarrior.",
    "Erste Schritte:": "First steps:",
    "Mit dieser VPN-Konfiguration lassen sich UTMs einfach in einer sternförmigen Netzwerktopologie miteinander verbinden.":
        "With this VPN configuration, UTMs can be easily connected to each other in a star-shaped network topology.",

    "Zentrale Rolle der Core-UTM: Die Core-UTM fungiert als zentraler Knotenpunkt und verbindet die verschiedenen Standorte. Zusätzlich werden Roadwarrior, also mobile Endgeräte und Windows VPN-Clients, direkt mit der Core-UTM verbunden.":
        "Central role of the Core-UTM: The Core-UTM acts as a central node and connects the various locations. In addition, Roadwarriors, i.e. mobile end devices and Windows VPN clients, are connected directly to the core UTM.",
    "Anbindung von Satelliten-UTMs: Sichere Vernetzung weiterer Standorte über Satelliten-UTMs.":
        "Connection of satellite UTMs: Secure networking of additional locations via satellite UTMs.",
    "Roadwarrior-Unterstützung: Mobile Endgeräte und Windows VPN-Clients können flexibel angebunden werden.":
        "Roadwarrior support: Mobile end devices and Windows VPN clients can be flexibly connected.",

    "Wichtig vorab:": "Important in advance:",
    "Stellen Sie sicher, dass die UTMs auf Version 14.0 oder höher aktualisiert sind.":
        "Make sure that the UTMs are updated to version 14.0 or higher.",
    "Nutzen Sie die neueste Version des Securepoint VPN Clients für iOS- und Android-Geräte.":
        "Use the latest version of the Securepoint VPN Client for iOS and Android devices.",

    "Core-UTM jetzt hinzufügen": "Add Core-UTM now",
    "Weitere Informationen finden Sie in unserem Wiki unter":
        "You can find further information in our wiki at",
    "Möchten Sie die VPN-Verbindung wirklich löschen?":
        "Do you really want to delete the VPN connection?",
    "Das Löschen der Verbindung hat die Zeit überschritten. Bitte überprüfen Sie die Internetverbindung des VPN-Clients und versuchen es erneut.":
        "Deleting the connection has timeouted. Please check the internet connection of the VPN client and try again.",
    "Profile auswählen": "Select profiles",
    Profile: "Profiles",
    "Geräte aus diesen Profilen können sich nach Veröffentlichung der Konfiguration mit diesem Netzwerk verbinden.":
        "Devices from these profiles can connect to this network once the configuration has been published.",
    "Regel kopieren": "Copy rule",
    "In Zwischenablage kopiert!": "Copied to clipboard!",
    "Geben Sie einen Namen ein": "Enter a name",
    "Erlaubt die Änderungen der Einstellungen im Client.":
        "Allows the settings to be changed in the client.",
    "Änderung der nachfolgenden Client-Einstellungen erlauben":
        "Allow changes to the following client settings",
    "IPs in Benutzung": "Used IPs",
    "Abgelaufene Profile einblenden": "Show expired profiles",
    "Abgelaufene Profile ausblenden": "Hide expired profiles",
    Ablaufdatum: "Expiry",
    Aussehen: "Appearance",
    "Umstellung auf Darkmode": "Switch to dark mode",
    "Durch das Setzen kann das Farbschema des Systems überschrieben werden.":
        "The system's color scheme can be overwritten by setting this.",
    Sprache: "Language",
    "Hier kann die Sprache der Anwendung eingestellt werden.":
        "The language of the application can be set here.",
    Deutsch: "German",
    Englisch: "English",
    Anwendungsstart: "Application start",
    "Beim Start ausführen": "Execute on startup",
    "Die Anwendung startet automatisch beim Hochfahren des Computers.":
        "The application starts automatically when the computer boots up.",
    "In verkleinerter Ansicht starten": "Start in minimized view",
    "Der Client wird in einer reduzierten Ansicht ohne Detailinformationen gestartet.":
        "The client is started in a reduced view without detailed information.",
    "Anwendung minimiert starten": "Start application minimized",
    "Anwendung startet im Hintergrund und ist über das Tray-Icon verfügbar.":
        "Application starts in the background and is available via the tray icon.",
    "Migrationsassistent erneut anzeigen": "Show migration wizard again",
    "Beim Starten der Anwendung wird der Migrationsassistent angezeigt. Damit können alte Verbindungen direkt importiert werden.":
        "The migration wizard is displayed when the application is started. This allows old connections to be imported directly.",
    Verbindungen: "Connections",
    "Nach Verbindung minimieren": "Minimize after connection",
    "Die Anwendung wird nach Verbindungsaufbau automatisch minimiert.":
        "The application is automatically minimized after the connection is established.",
    "Mehrere VPN-Verbindungen erlauben": "Allow multiple VPN connections",
    "Die Option mehrerer gleichzeitiger VPN-Verbindungen erfordert sortfältige Konfiguration, um Probleme zu vermeiden.":
        "The option of multiple simultaneous VPN connections requires careful configuration to avoid problems.",
    "Herunterfahren blockieren": "Block shutdown",
    "Der VPN-Client verhindert das Herunterfahren des Computers, solange eine aktive VPN-Verbindung besteht.":
        "The VPN client prevents the computer from shutting down as long as there is an active VPN connection.",
    "Beim Minimieren Pop-Up anzeigen": "Show pop-up when minimizing",
    "Informationen werden mittels eines Popup-Fensters angezeigt, wenn die Anwendung minimiert ist.":
        "Information is displayed in a pop-up window when the application is minimized.",
    "Erweiterte Einstellungen": "Advanced settings",
    "Updates automatisch durchführen": "Perform updates automatically",
    "Updates werden automatisch durchgeführt, um sicherzustellen, dass der Client immer auf dem neusten Stand ist.":
        "Updates are carried out automatically to ensure that the client is always up to date.",
    "DNS-Leaks vermeiden": "Avoid DNS leaks",
    "DNS-Anfragen werden ausschließlich über den verschlüsselten Tunnel geleitet, damit Online-Aktivitäten nicht von Dritten eingesehen werden können.":
        "DNS requests are routed exclusively via the encrypted tunnel so that online activities cannot be viewed by third parties.",
    Aktuellste: "Current",
    "Weitere Informationen": "Further Information",
    "Informationen anzeigen": "Show Information",
    "Informationen ausblenden": "Hide Information",
    "Ist die diese Einstellungen aktiv, sind die nachfolgenden Einstellungen im Client sichtbar und können vom Benutzer geändert werden.":
        "If this setting is active, the following settings are visible in the client and can be changed by the user.",
    "Windows Enrollmentx": "Windows Enrollment",
    "Entscheidet, ob diese Verbindung bei Bedarf automatisch gestartet wird, wenn sie als aktive Verbindung ausgewählt wird.":
        "Decides whether this connection is started automatically if required when it is selected as the active connection.",
    "Diese Einstellung kann auf dem Gerät vom Benutzer selbst anschließend verändert werden.":
        "This setting can then be changed on the device by the user.",
    "Entscheidet, ob diese Verbindung bei sofort gestartet wird, wenn sie als aktive Verbindung ausgewählt wird.":
        "Decides whether this connection is started immediately if it is selected as the active connection.",
    "Bei einem Verbindungsabbruch wird sie automatisch neu gestartet.":
        "It is automatically restarted if the connection is lost.",
    "Entscheidet, ob diese Verbindung bei Bedarf sofort gestartet wird, wenn sie als aktive Verbindung ausgewählt wird.":
        "Decides whether this connection is started immediately on demand if it is selected as the active connection.",
    "Löschen von": "Deletion of",
    "Um dieses Feature benutzen zu können, benötigen Sie Rechte für die USC-Administration":
        "In order to be able to use this feature, you need rights for the USC administration",
    "Dieses Label wird noch %s %y angezeigt.": "This label is displayed for another %s %y.",
    "Token herunterladen": "Download token",
    "Beim Löschen der Verbindung ist ein Fehler aufgetreten.":
        "An error occurred while deleting the connection.",
    "Der Kunde verfügt über keine Lizenz. Bitte nutzen Sie bei Bedarf das Resellerportal für den Erwerb einer Lizenz.":
        "The tenant does not have a license. If required, please use the reseller portal to purchase a license.",
    "Die ausgewählte UTM kann nicht als Core-UTM konfiguriert werden, da sie kein Interface mit einer öffentlichen IP-Adresse oder einem konfigurierten DynDNS besitzt.":
        "The selected UTM cannot be configured as a core UTM because it does not have an interface with a public IP address or a configured DynDNS.",
    "Achtung: Aktuell besteht zwar eine aktive Benutzer-Sitzung, allerdings ist der Client geschlossen. Dadurch können nur Systemverbindungen abgerufen werden. Um den Client zu starten, gehen Sie zu":
        "Attention: Although there currently is an active user session, the client is closed. This means that only system connections can be accessed. To start the client, go to",
    "Es besteht keine gültige Windows-Session. Der Client kann nicht geöffnet werden und es können nur Systemverbindungen abgerufen werden":
        "There is no valid user session and no possibility to start the client. This means that only the system connections can be accessed. To access user connections, the user must first log on to Windows.",
    Aktuell: "Current",
    Unverschlüsselt: "Not encrypted",
    Verschlüsselt: "Encrypted",
    Verschlüsselungsmethode: "Encryption method",
    Verschlüsselungsstatus: "Encryption state",
    Status: "State",
    "Prozent verschlüsselt": "Percentage of encryption",
    einen: "one",
    Tag: "day",
    "Mit UTM Zero-Touch-Enrollment und -Konfiguration können Sie Ihre UTM vor der Inbetriebnahme im Portal registrieren und die Grundeinstellungen festlegen. Diese Funktion ermöglicht es, ein neues Gerät oder ein Ersatzgerät direkt vor Ort zu installieren, ohne dass eine lokale Administration erforderlich ist.":
        "With UTM Zero-Touch enrollment and configuration, you can register your UTM in the portal and define the basic settings before commissioning. This function enables you to install a new device or a replacement device directly on site without the need for local administration.",
    "UTM Zero-Touch-Enrollment und -Konfiguration": "UTM Zero-Touch enrollment and configuration",
    "Erste Schritte zur Konfiguration:": "First steps for configuration:",
    "Erstellen Sie ein UTM Zero-Touch-Profil mit Seriennummer und Enrollment-PIN.":
        "Create a UTM Zero-Touch profile with serial number and enrollment PIN.",
    "Laden Sie die entsprechende UTM-Lizenz hoch.": "Upload the corresponding UTM license.",
    "Setzen Sie eine Websession-PIN.": "Set a websession PIN.",
    "Laden Sie optional eine Konfigurationsdatei hoch.": "Upload an optional configuration file.",
    'Bei der ersten Inbetriebnahme verbindet sich eine noch nicht konfigurierte UTM automatisch mit dem Portal und wird entsprechend der dort hinterlegten Einstellungen lizenziert und konfiguriert. Achten Sie darauf, dass LAN 1 der UTM korrekt an den Router angeschlossen ist, der die Verbindung zum Internet herstellt, so dass die UTM eine IP Adresse per DHCP beziehen kann. Das Profil wird als "angewendet" markiert und kann nicht erneut verwendet werden.':
        'During the first enrollment, an unconfigured UTM automatically connects to the portal and is licensed and configured according to the settings stored there. Make sure that LAN 1 of the UTM is correctly connected to the router that establishes the connection to the Internet so that the UTM can obtain an IP address via DHCP. The profile is marked as "applied" and cannot be used again.',
    "Zero-Touch-Profil jetzt hinzufügen": "Add Zero-Touch profile now",
    "Primärer DNS-Server": "Primary DNS-Server",
    "Sekundärer DNS-Server": "Secondary DNS-Server",
    Netzwerkobjekt: "Network object",
    "Bearbeiten Sie einen bestehenden DNS-Server oder legen Sie einen Neuen an.":
        "Edit an existing DNS server or create a new one.",
    "Wählen oder erstellen Sie ein Netzwerkobjekt für die DNS-Server. Es werden in den RoadWarrior-Pools automatisch Regeln angelegt. Diese werden von den RoadWarrior-Clients zur Namensauflösung für Ressourcen hinter der Core-UTM verwendet.":
        "Select or create a network object for the DNS servers. Rules are automatically created in the RoadWarrior pools. These are used by the RoadWarrior clients to resolve names for resources behind the core UTM.",
    "Behalten von lokalen Änderungen erlauben": "Allow local changes to be saved",
    "Diese Einstellung verbietet oder erlaubt es dem Endnutzer lokale Änderungen der Einstellungen zu behalten. Ist diese Einstellung inaktiv, überschreibt jeder erneute Publish die lokalen Änderungen.":
        "This setting forbids or allows the end user to keep local changes to the settings. If this setting is inactive, each new publish overwrites the local changes.",
    "Dieses Feature ist auf dieser UTM nicht verfügbar, da die PIN deaktiviert ist.":
        "This feature is not available on this UTM, due to a disabled PIN.",
    "Dieses Feature ist auf dieser UTM nicht verfügbar, da die PIN gesperrt ist.":
        "This feature is not available on this UTM, due to a locked PIN.",
    "Websession ist nicht aktiviert": "Websession is disabled",
    "Dieses Feature ist auf dieser UTM nicht aktiviert. Wenn Sie die Websession für diese UTM nutzen wollen, aktualisieren Sie die USC-Rechte auf der UTM. Das Vorgehen finden Sie im Wiki unter $1 verwalten.":
        "This feature is disabled on this UTM. If you want to use the web session for this UTM, update the USC permissions on the UTM. You can find the need steps in the wiki under Manage $1.",
    "USC-Rechte": "USC permissions",
    'Auf dieser UTM existiert bereits eine veraltete VPN-Konfiguration. Daher kann sie aktuell nicht als Core-UTM eingesetzt werden. Bitte entfernen Sie die alte Konfiguration, indem Sie in der Web-Oberfläche der UTM den Knopf "alle VPN-Konfigurationen löschen" unter USP -> Unified Security Console und VPN-Konfiguration betätigen.':
        "An outdated VPN configuration already exists on this UTM. Therefore, it cannot currently be used as a core UTM. Please remove the old configuration by pressing the “Delete all VPN configurations” button in the UTM web interface under USP -> Unified Security Console and VPN configuration.",
    'Auf dieser UTM existiert bereits eine veraltete VPN-Konfiguration. Daher kann sie aktuell nicht als Satellit eingesetzt werden. Bitte entfernen Sie die alte Konfiguration, indem Sie in der Web-Oberfläche der UTM den Knopf "alle VPN-Konfigurationen löschen" unter USP -> Unified Security Console und VPN-Konfiguration betätigen.':
        "An outdated VPN configuration already exists on this UTM. Therefore, it cannot currently be used as a satellite. Please remove the old configuration by pressing the “Delete all VPN configurations” button in the UTM web interface under USP -> Unified Security Console and VPN configuration.",
    Benutzerdefiniert: "Custom",
    Tage: "days",
    Deaktiviert: "Deactivated",
    Aktiviert: "Activated",
    "Das Volume ist vollständig entschlüsselt.": "The volume is fully decrypted.",
    "Das Volume ist vollständig verschlüsselt.": "The volume is fully encrypted.",
    "Das Volume ist teilweise verschlüsselt.": "The volume is partially encrypted.",
    "Das Volume ist teilweise entschlüsselt.": "The volume is partially decrypted.",
    "Das Volume wurde während des Verschlüsselungsfortschritts angehalten. Das Volume ist teilweise verschlüsselt.":
        "The volume was stopped during the encryption progress. The volume is partially encrypted.",
    "Das Gerät muss online sein, um diese Aktion durchführen zu können":
        "The device must be online in order to perform this action",
    "Kein Update verfügbar": "No update available",
    "Update verfügbar": "Update available",
    "Für mehr Informationen zum Client klicken Sie bitte hier":
        "For further information about the client click here",
    "Update auf %s verfügbar": "Update to %s available",
    "Diese Aktion erfordert einen aktuellen Status": "This action requires a current state",
    "Um diese Aktion durchführen zu können, wird ein aktive Benutzer-Sitzung benötigt":
        "An active user session is required to perform this action",
    "Achtung: Die momentanen Daten sind möglicherweise veraltet. Um dieses Feature nutzen zu können, muss das Gerät Online sein.":
        "Attention: The current data may be outdated.  To use this feature, the device must be online.",
    "Das Profil konnte nicht gefunden werden. Möglicherweise wurde das Profil gelöscht":
        "The profile could not be found. The profile may have been deleted",
    Berechtigungen: "Permissions",
    "Diese Einstellung wird auf dem Client nicht verändert.":
        "This setting is not changed on the client.",
    "Das Profil wird diesen Clients zugeordnet.": "The profile is assigned to these clients.",
    "Tags hinzufügen": "Add tags",
    "Achtung: Die Anmeldung mit verwalteter Apple-ID ist nur für Geräte mit iOS < 18 verfügbar.":
        "Attention: Enrollment with managed Apple ID is only available for devices with iOS < 18.",
    "Authentifizierung nach App-Start erforderlich": "Authentication required after app start",
    "Hier legen Sie fest, ob eine Authentifizierung bei App-Start erforderlich ist. Der User muss eine Authentifizierung (PIN oder biometrisch) für den Start der App festlegen.":
        "Here you specify whether authentication is required when starting the app. The user must define an authentication (PIN or biometric) for starting the app.",
    "Die Datenschutzeinstellungen bieten die Möglichkeit, verschiedene UTM-Anwendungen gemäß der DSGVO zu anonymisieren, indem Funktionen zur Maskierung sensibler Daten angewendet werden. So können personenbezogene Daten wie Namen, E-Mail-Adressen oder IP-Adressen automatisch anonymisiert werden, um sicherzustellen, dass alle rechtlichen Anforderungen an den Datenschutz erfüllt werden. Diese Einstellungen können nur auf UTMs ab Version 14.0.1 angewendet werden.":
        "The data protection settings offer the option of anonymising various UTM applications in accordance with the GDPR by applying functions to mask sensitive data. This allows personal data such as names, email addresses or IP addresses to be anonymised automatically to ensure that all legal data protection requirements are met. These settings can only be applied to UTMs from version 14.0.1.",
    "Datenschutzeinstellungen verwalten": "Manage privacy settings",
    Datenschutz: "Privacy",
    "Aktiviert das Definieren der Datenschutz-Einstellungen.":
        "Activates the definition of privacy settings.",
    "Aktiviert die Anonymisierung für alle Anwendungen auf der UTM.":
        "Activates anonymization for all applications on the UTM.",
    Anwendungen: "Applications",
    "Für alle Anwendungen aktivieren": "Activate for all applications",
    "UTM Zero-Touch ist erst ab UTM Version 14.0 oder höher verfügbar.":
        "UTM Zero-Touch is only available from UTM version 14.0 or higher.",

    "Das Profil wird diesen UTMs zugeordnet.<br> Bitte nutzen Sie zur Zuordnung von Profilen an Cluster-UTMs die direkte Zuweisung, um Inkonsistenzen in den Clustern zu vermeiden.":
        "The profile is assigned to these UTMs.<br> Please use direct assignment to assign profiles to cluster UTMs in order to avoid inconsistencies in the clusters.",
    "Das Profil wird diesen UTMs zugeordnet.<br> Bitte beachten Sie, dass bei der Zuordnung einer primären Cluster-UTM eine manuelle Synchronisation der anderen Cluster-UTM notwendig ist, um Inkonsistenzen zu vermeiden. Bitte stellen Sie sicher, dass nur die primäre UTM eines Clusters hier zugewiesen wird. <br><strong>Achtung:</strong> Wenn UTMs ausgewählt werden, die weniger als 3GB Speicher haben, dann werden diese von automatischen Updates ausgeschlossen.":
        "The profile is assigned to these UTMs. <br> Please note that when assigning a primary cluster UTM, manual synchronization of the other cluster UTMs is necessary to avoid inconsistencies. Please make sure that only the primary UTM of a cluster is assigned here. <strong>Attention:</strong> If UTMs are selected that have less than 3GB of memory, they will be excluded from automatic updates.",
    "<strong>Achtung:</strong> Sie haben eine oder mehrere UTMs zugewiesen, die Teil eines UTM-Clusters sind. Bitte stellen Sie sicher, dass Sie nur die primäre Maschine des Clusters ausgewählt haben und führen Sie nach der Veröffentlichung des Profils eine manuelle Synchronisation der anderen Cluster-UTM durch. Damit stellen Sie sicher, dass alle Änderungen in diesem Profil auch auf die zweite UTM übernommen werden.":
        "<strong>Attention:</strong> You have assigned one or more UTMs that are part of a UTM cluster. Please make sure that you have only selected the primary machine of the cluster. Perform a manual synchronization of the other cluster UTM after publishing the profile to ensure that all changes in this profile are also applied to the second UTM.",
    "<strong>Achtung:</strong> Sie haben über ein Tag eine oder mehrere UTMs zugewiesen, die Teil eines UTM-Clusters sind. Bitte beachten Sie, dass das Profil nur der aktuell aktiven Cluster-UTM zugewiesen wird. Um Inkonsistenzen zu vermeiden, weisen Sie daher Profile an Cluster-UTMs sicherheitshalber nur über die direkte Zuordnung zu. Alternativ führen Sie bitte nach der Veröffentlichung des Profils eine manuelle Synchronisation der anderen Cluster-UTM durch. Damit stellen Sie sicher, dass alle Änderungen in diesem Profil auch auf die zweite UTM übernommen werden.":
        "<strong>Attention:</strong> You have assigned one or more UTMs that are part of a UTM cluster via a tag. Please note that the profile is only assigned to the currently active cluster UTM. To avoid inconsistencies, please only assign profiles to cluster UTMs via direct assignment to be on the safe side. Alternatively, please perform a manual synchronization of the other cluster UTM after publishing the profile to ensure that all changes in this profile are also applied to the second UTM.",
    "Authentifizierung Webinterface": "Authentication web interface",
    "DHCP-Server & -Relay": "DHCP server & relay",
    "HTTP-Proxy": "HTTP Proxy",
    "IPS Sperrungen": "IPS bans",
    Mailfilter: "Mail filter",
    Paketfilter: "Paket filter",
    "Reverse-Proxy": "Reverse Proxy",
    "SSH-Server": "SSH Server",
    "WLAN-Server": "WLAN server",
    "Die Verbindung wird im Syslog geloggt, aber zugelassen.":
        "The connection is logged in the syslog, but accepted.",
    "Die Verbindung wird im Syslog geloggt und blockiert.":
        "The connection is logged in the syslog and blocked.",
    "Aktiviert das Verwalten der Threat-Intelligence-Filter-Einstellungen.":
        "Activates the management of threat intelligence filter settings.",
    "Mit dem Threat Intelligence Filter protokollieren oder blocken Sie IP-Zugriffe auf potentiell gefährliche Gegenstellen unabhängig vom verwendeten Protokoll. Der Filter aktualisiert sich automatisch im Hintergrund über die Securepoint Cyber Defence Cloud. Diese Einstellungen können nur auf UTMs ab Version 14.0.1 angewendet werden.":
        "With the Threat Intelligence Filter, you can log or block IP access to potentially dangerous remote sites regardless of the protocol used. The filter updates itself automatically in the background via the Securepoint Cyber Defence Cloud. These settings can only be applied to UTMs from version 14.0.1.",
    "Threat-Intelligence-Filter verwalten": "Manage threat intelligence filters",
    "Verbindung protokolieren": "Log connection",
    "Verbindung protokolieren und blockieren": "Log and block connection",
    "Über dieses Tag weisen Sie dieser Cluster-UTM ein Profil zu. Bitte beachten Sie, dass es dadurch zu Inkonsistenzen im Cluster kommen kann. Nutzen Sie bei Bedarf wenn möglich die direkte Zuweisung der UTM an ein Profil.":
        "Use this tag to assign a profile to this cluster UTM. Please note that this can lead to inconsistencies in the cluster. If necessary, use the direct assignment of the UTM to a profile if possible.",
    "Aktiviert den Schutz vor Brute-Force-Angriffen für den SSH-Dienst.":
        "Activates protection against brute force attacks for the SSH service.",
    "Aktiviert den Schutz vor Brute-Force-Angriffen für das Administrations-Webinterface.":
        "Activates protection against brute force attacks for the administration webinterface",
    "Aktiviert den Schutz vor Brute-Force-Angriffen für das Benutzer-Webinterface.":
        "Activates protection against brute force attacks for the user webinterface.",
    "Aktiviert den Schutz vor Brute-Force-Angriffen für den SMTP-Dienst.":
        "Activates protection against brute force attacks for the SMTP service.",
    "Aktiviert den Schutz vor Brute-Force-Angriffen, indem bei mehreren fehlgeschlagenen Anmeldeversuchen eine temporäre Sperrung der IP ausgelöst wird. Die Regeln für diese Sperrung sind unter IDS/IPS auf der UTM definiert. Diese Einstellungen können nur auf UTMs ab Version 14.0.1 angewendet werden.":
        "Activates protection against brute force attacks by temporarily blocking the IP if several login attempts fail. The rules for this blocking are defined under IDS/IPS on the UTM. These settings can only be applied to UTMs from version 14.0.1.",
    "Fail2Ban-Einstellungen verwalten": "Manage Fail2Ban settings",
    "Aktiviert das Definieren der Fail2Ban-Einstellungen.":
        "Activates the definition of the Fail2Ban settings.",
    "Admin-Interface": "Admin interface",
    "User-Interface": "User interface",
    "Text der Nachricht": "Message text",
    "Ihre Lizenzen reichen nicht aus, um alle ausgeloggten Geräte zu lizensieren.":
        "Your licenses are not sufficient to license all logged out devices.",
    "Bitte erweitern Sie Ihre Lizenzen bei Bedarf.": "Please extend your licenses if required.",
    "Als Quelle wird automatisch der Roadwarrior-Pool gesetzt.":
        "The Roadwarrior pool is automatically set as the source.",
    "VPN-Konfigurationen bieten eine komfortable und sichere Möglichkeit, dem Device VPN-Verbindungen über Adaptive Secure Connect zuzuweisen. Nachfolgend sind wichtige Informationen (Public-Key, Client-IP) der Konfiguration aufgelistet.":
        "VPN configurations offer a convenient and secure way of assigning VPN connections to the device via Adaptive Secure Connect. Important configuration information (public key, client IP) is listed below.",
    Poolname: "Pool name",
    "IP aus dem Pool": "IP from the pool",
    "Tag wird in UTM Profilen verwendet": "Tag is used in UTM profiles",
    Transfernetz: "Transfer network",
    "Für UTMs, die älter als 12.6.2 sind, werden Jobs erstellt, da diese UTMs das automatische Update nur kontrolliert aus der Cloud durchführen können. Neuere UTMs werden lokal über das Profil konfiguriert, führen dann das automatische Update selbstständig durch und erhalten daher keinen Job.":
        "Jobs are created for UTMs older than 12.6.2, due to these UTMs can only perform the automatic update from the cloud in a controlled manner. Newer UTMs are configured locally via the profile, then apply the automatic update independently and therefore do not receive a job.",
    "VPN-Konfigurationen stellen mit Adaptive Secure Connect einen komfortablen und sicheren Weg dar, dem Client VPN-Verbindungen zuzuweisen. Nachfolgend sind wichtige Informationen (Public-Key, Client-IP etc.) der Konfiguration aufgelistet.":
        "VPN configurations offers with Adaptive Secure Connect a convenient and secure way of assigning VPN connections to the client. Important configuration information (public key, client IP etc.) is listed below.",
    "Das Profil wird diesen UTMs zugeordnet.<br>Bitte beachten Sie, dass bei der Zuordnung einer primären Cluster-UTM eine manuelle Synchronisation der anderen Cluster-UTM notwendig ist, um Inkonsistenzen zu vermeiden. Bitte stellen Sie sicher, dass nur die primäre UTM eines Clusters hier zugewiesen wird.<br><strong>Achtung:</strong> Wenn UTMs ausgewählt werden, die weniger als 3GB Speicher haben, dann werden diese von automatischen Updates ausgeschlossen.":
        "The profile is assigned to these UTMs.<br>Please note that when assigning a primary cluster UTM, manual synchronization of the other cluster UTMs is necessary to avoid inconsistencies. Bitte stellen Sie sicher, dass nur die primäre UTM eines Clusters hier zugewiesen wird.<br><strong>Attention:</strong> If UTMs are selected that have less than 3GB of memory, they will be excluded from automatic updates.",
    xEnrollmenttoken: "Enrollmenttoken",
    xEnrollmenttokens: "Enrollmenttokens"
}
export default en
