<script setup lang="ts">
import i18n, { T } from "@/classes/i18n"
import products from "@/classes/objectTypes"
import jsonFormHelpers from "@/helpers/helpers.jsonForms"
import mixinHelpers from "@/helpers/helpers.mixins"
import stringHelpers from "@/helpers/helpers.strings"
import tenantHelpers from "@/helpers/helpers.tenants"
import timeHelpers from "@/helpers/helpers.time"
import router from "@/router/router"
import Button from "@/templates/components/button/button"
import { computed, onMounted, ref } from "vue"
import Icon from "./icon/icon"
import Label from "./label/label"
import tableComponent, { type TableEntryInfo } from "./tableNext.vue"

const props = defineProps<{
    form?: JsonFormShemaObject
}>()

const activeAccountId = computed(() => {
    return mixinHelpers.getActiveAccountId()
})
const objectId = computed(() => {
    return mixinHelpers.getObjectId()
})
const tabField = computed(() => {
    return props.form ? jsonFormHelpers.getFieldById(props.form, "publishStateTab") : undefined
})

const messages = ref(<any[]>[])
const errors = ref(<string[]>[])

const tableSelectableColumns: TableEntryInfo[] = [
    {
        text: T("Time"),
        property: "lastStatusChanged",
        displayType: "text",
        getSortValue: (entry: any) => {
            return String(entry?.updated)
        },
        getValue: (entry: any) => {
            return timeHelpers.formatDate(
                entry?.updated * 1000,
                timeHelpers.getDateFormatI18n(i18n.getLanguage(), true)
            )
        }
    },
    {
        text: T("Type"),
        property: "type",
        displayType: "labels",
        getValue: (entry: any) => {
            let result: Label[] = []
            let text = ""
            if (entry.content?.payload?.clientContext) {
                text = stringHelpers.capitalizeFirstLetter(
                    entry.content.payload.clientContext.split("-").slice(1).join(" ").toLowerCase()
                )
            } else {
                text = entry.type
                    ? stringHelpers.capitalizeFirstLetter(
                          entry.type.replaceAll("_", " ").toLowerCase()
                      )
                    : ""
            }

            result.push(
                new Label({
                    text: T(text),
                    title: T(text)
                })
            )
            return result
        }
    },
    {
        text: T("Client"),
        property: "deviceId",
        displayType: "labels",
        getSortValue: (entry: any) => {
            return 0
        },
        getValue: (entry: any) => {
            let result: Label[] = []
            let client = products.unifiedSecurityConsole.windowsVpns
                .useStore?.()
                .getObjectStoreObject(activeAccountId.value, entry?.deviceId)
            if (client) {
                result.push(
                    new Label({
                        text: client.alias,
                        title: client.alias,
                        onClick: () => {
                            router.navigate(
                                "show-tenant-" +
                                    tenantHelpers.getTenantDomain(activeAccountId.value) +
                                    "-windows-vpns-" +
                                    entry.deviceId +
                                    "-details"
                            )
                        }
                    })
                )
            }
            return result
        }
    },
    {
        text: T("Direction"),
        property: "queue",
        displayType: "labels",
        getValue: (entry: any) => {
            return [
                new Label({
                    icon: new Icon({
                        class: entry?.queue == "in" ? "fal fa-arrow-left" : "fal fa-arrow-right"
                    }),
                    text: T(stringHelpers.capitalizeFirstLetter(entry?.queue)),
                    title:
                        entry?.queue == "in"
                            ? T("Message from device to server")
                            : T("Message from server to device")
                })
            ]
        }
    },
    {
        text: T("Status"),
        property: "status",
        displayType: "labels",
        getValue: (entry: any) => {
            return [
                new Label({
                    title: entry?.statusText
                        ? entry?.statusText
                        : T(stringHelpers.capitalizeFirstLetter(entry?.status)),
                    text: T(stringHelpers.capitalizeFirstLetter(entry?.status)),
                    class:
                        entry?.status == "ERROR"
                            ? "bg-red"
                            : entry?.status == "PENDING"
                              ? "bg-yellow"
                              : "",
                    icon: new Icon({
                        class:
                            entry?.status == "ERROR"
                                ? "fal fa-exclamation-triangle"
                                : entry?.status == "PENDING"
                                  ? "fal fa-bell"
                                  : "fal fa-info"
                    })
                })
            ]
            return []
        }
    }
]
const tableButtons = computed(() => {
    return [
        new Button({
            onClick: () => {
                refresh()
            },
            icon: "fal fa-sync",
            loading: loading.value
        })
    ]
})

const loading = ref(false)

onMounted(() => {
    refresh()
})

const refresh = async () => {
    try {
        let clients = products.unifiedSecurityConsole.windowsVpns
            .useStore?.()
            .getObjectStoreObjects(activeAccountId.value)

        if (clients?.length == 0) {
            await products.unifiedSecurityConsole.windowsVpns.queries.getObjectsFromApi(
                activeAccountId.value
            )
        }

        errors.value = []
        if (Array.isArray(tabField.value?.errors)) {
            tabField.value.errors = []
        }

        loading.value = true
        let result = await products.unifiedSecurityConsole.windowsProfiles.getPublishMessages(
            activeAccountId.value,
            objectId.value || ""
        )

        if (!(result instanceof Error) && result) {
            messages.value = result
        }
        loading.value = false
    } catch (e: any) {
        console.error(e)

        if (e instanceof Error && Array.isArray(errors.value)) {
            errors.value.push(e.message)
            Array.isArray(tabField.value?.errors)
                ? tabField.value.errors.push(e.message)
                : undefined
        } else if (typeof e?.data?.data?.errors?.errors?.[0]?.message == "string") {
            errors.value.push(e.data.data.errors.errors[0].message)
            Array.isArray(tabField.value?.errors)
                ? tabField.value.errors.push(e.data.data.errors.errors[0].message)
                : undefined
        } else if (typeof e?.data?.data?.errors?.[0]?.message == "string") {
            errors.value.push(e.data.data.errors[0].message)
            Array.isArray(tabField.value?.errors)
                ? tabField.value?.errors.push(e.data.data.errors[0].message)
                : undefined
        } else if (typeof e?.data?.message == "string" && Array.isArray(errors.value)) {
            errors.value.push(e.data.message)
            Array.isArray(tabField.value?.errors)
                ? tabField.value?.errors.push(e.data.message)
                : undefined
        }

        loading.value = false
    }
}
</script>
<template>
    <div>
        <tableComponent
            :buttons="tableButtons"
            :object-list="messages"
            :selectable-columns="tableSelectableColumns"
            :has-options="false"
            :is-loading="loading"
        ></tableComponent>
    </div>
    <template v-if="errors.length">
        <div class="padding-xs">
            <template v-for="error in errors">
                <span class="error-bubble label bg-red margin-xs-t">
                    {{ T(error) }}
                </span>
            </template>
        </div>
    </template>
</template>
