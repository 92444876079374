import Icon, { type IconOptions } from "@/templates/components/icon/icon"

export interface ExposedButtonVariables {
    showMessage: (message: string) => void
}

export interface ButtonOptions {
    id?: string
    text?: string
    title?: string
    htmlTooltip?: boolean
    icon?: string | Icon | IconOptions
    onClick?: (event: MouseEvent, exposedVars: ExposedButtonVariables) => void
    link?: string
    target?: "_self" | "_blank" | "_parent" | "_top"
    loading?: boolean | (() => boolean)
    disabled?: boolean | (() => boolean)
    topRightCircle?: undefined | ButtonTopRightCircle
    type?: "default" | "icon" | "blank"
    size?: "xl" | "lg" | "sm" | "xs"
    dropdownEntries?: Array<Button | ButtonOptions | "divider">
    color?: "red" | "white" | "blue" | "green" | "darkbg" | "primary"
    style?: string
}

type ButtonTopRightCircle = {
    counter?: number
    icon?: string
}

export default class Button implements ButtonOptions {
    id?: ButtonOptions["id"] = undefined
    text?: ButtonOptions["text"] = undefined
    title?: ButtonOptions["title"] = undefined
    htmlTooltip?: ButtonOptions["htmlTooltip"] = undefined
    icon?: ButtonOptions["icon"] = undefined
    onClick?: ButtonOptions["onClick"] = undefined
    link?: ButtonOptions["link"] = undefined
    target?: ButtonOptions["target"] = undefined
    loading?: ButtonOptions["loading"] = false
    disabled?: ButtonOptions["disabled"] = false
    topRightCircle?: ButtonOptions["topRightCircle"] = undefined
    type?: ButtonOptions["type"] = "default"
    size?: ButtonOptions["size"] = undefined
    dropdownEntries?: ButtonOptions["dropdownEntries"] = undefined
    color?: ButtonOptions["color"] = "primary"
    style?: ButtonOptions["style"]
    constructor(options: ButtonOptions) {
        if (options) {
            Object.assign(this, options)
        }
    }
    startLoader() {
        this.loading = true
    }
    stopLoader() {
        this.loading = false
    }
    disable() {
        this.disabled = true
    }
    enable() {
        this.disabled = false
    }
}
