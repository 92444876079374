import config from "@/classes/config"
import { T } from "@/classes/i18n"
import products from "@/classes/objectTypes"
import deviceHelpers from "@/helpers/helpers.devices"
import numberHelpers from "@/helpers/helpers.numbers"
import { useGlobalMixin } from "@/mixins/mixins.global"
import queries from "@/queries/queries"
import router from "@/router/router"
import { ActionTypes, useStore } from "@/store/vuex.store"
import moment from "moment"
import { Carousel, Navigation, Slide } from "vue3-carousel"
import "vue3-carousel/dist/carousel.css"
import loaderComponent from "../components/loader.vue"
import inputVueSelectVue from "../inputtypes/input-vue-select.vue"

/* interface JQuery {
    owlCarousel(element?:any,options?:any):void;
} */
const pageVppAssetDetailsComponent = {
    name: "sms-page-vppassetdetails",
    mixins: [useGlobalMixin()],
    template: `
    <div>
        <section class="padding-xs padding-md-2 padding-lg-4 pagecontent" v-if="initialized && asset != undefined">
            <div class="row itemlist show-details view-grid">
                <div class="col-xs-24 col-print-24 col-lg-12  padding-xs item" style="z-index:999999">
                    <div class="box-shadow">
                        <div class="box-body">
                            <div class="box-icon-left">
                                <div class="icon-circle color-white bg-gray hover-bg-red" :title="T(asset.productTypeName)">
                                    <div
                                        class="checkbox-overlay-0 visible"
                                        :style="asset && asset.assetInfo && asset.assetInfo.artworkUrl60 ? 'background-image: url('+(asset.assetInfo.artworkUrl60)+')' : ''"
                                    ></div>
                                    <i class="fal fa-rocket"></i>
                                </div>
                            </div>
                            <div class="box-content-2">
                                <div class="box-title">
                                    <strong class="margin-xs-r">{{asset ? asset.assetInfo ? asset.assetInfo.trackName : asset.adamIdStr : ''}}</strong>
                                    <small class="label margin-xs-r" v-bind:class="label.class" v-for="label in labels" v-bind:title="label.title">
                                    {{ label.title }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="box-body details">
                            <div class="text-smaller">
                                <div class="data-row">
                                    <span class="data-key text-semibold">
                                        <i class="fal fa-fw fa-question"></i> {{T('Type')}}:
                                    </span>
                                    <div class="data-value">
                                        {{ asset.productTypeName }}
                                    </div>
                                </div>
                                <div class="data-row">
                                    <span class="data-key text-semibold">
                                        <i class="fal fa-fw fa-question"></i> {{T('Available licenses')}}:
                                    </span>
                                    <div class="data-value">
                                        {{ asset.availableCount }}/{{ asset.totalCount }}
                                    </div>
                                </div>
                                <div class="data-row" v-if="asset && asset.assetInfo">
                                    <span class="data-key text-semibold">
                                        <i class="fal fa-fw fa-question"></i> {{T('Price')}}:
                                    </span>
                                    <div class="data-value">
                                        {{ asset.assetInfo.price == 0 ? T('Free') : ((asset.assetInfo.price || '') + ' ' + (asset.assetInfo.currency || '')) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-24 col-print-24 col-lg-12 padding-xs">
                </div>
            </div>
            <div class="row">
                <div class="col-xs-24 padding-xs">
                    <div class="box-shadow">
                        <div class="tabs">
                            <nav>
                                <ul class="nav nav-tabs hide-on-print">
                                    <li v-if="asset && asset.assetInfo && asset.assetInfo.isInStore">
                                        <a :class="{'active':activeTab == 'description'}" v-on:click="setActiveTab('description')">
                                            <i class="fal fa-fw fa-info-circle"></i> ${T("Description")}
                                        </a>
                                    </li>
                                    <li>
                                        <a :class="{'active':activeTab == 'assignment'}" v-on:click="setActiveTab('assignment')">
                                            <i class="fal fa-fw fa-key"></i> ${T("License assignment")}
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                            <div class="tabcontent">

                                <div :class="{'hidden': activeTab != 'description'}" v-if="asset && asset.assetInfo && asset.assetInfo.isInStore">
                                    <div class="box-content-2">
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <h4>Screenshots</h4>
                                                <div>
                                                    <template v-if="(asset.assetInfo.screenshotUrls || []).length">
                                                        <carousel :items-to-show="3" :wrap-around="true" :autoplay="5000" :transition="1500">
                                                            <slide v-for="url in asset.assetInfo.screenshotUrls" :key="url">
                                                                <div class="carousel__item">
                                                                    <img :src="url" />
                                                                </div>
                                                            </slide>
                                                            <template #addons>
                                                                <navigation />
                                                            </template>
                                                        </carousel>
                                                    </template>
                                                    <template v-else-if="(asset.assetInfo.ipadScreenshotUrls || []).length">
                                                        <carousel :items-to-show="3" :wrap-around="true" :autoplay="5000" :transition="1500">
                                                            <slide v-for="url in asset.assetInfo.ipadScreenshotUrls" :key="url">
                                                                <div class="carousel__item">
                                                                    <img :src="url" />
                                                                </div>
                                                            </slide>
                                                            <template #addons>
                                                                <navigation />
                                                            </template>
                                                        </carousel>
                                                    </template>
                                                </div>
                                                <hr class="margin-xs-y-2"/>
                                                <h4>{{ T('Description') }}</h4>
                                                <p>{{asset.assetInfo.description}}</p>
                                                <hr class="margin-xs-y-2"/>
                                                <h4>{{ T('News') }}</h4>
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <p>
                                                            {{ asset.assetInfo.releaseNotes }}
                                                        </p>
                                                    </div>
                                                    <div class="col-xs-12">
                                                        <p class="text-right">
                                                            Version: {{ asset.assetInfo.version }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr class="margin-xs-y-2"/>
                                                <h4>{{ T('Infos') }}</h4>
                                                <table>
                                                    <tr v-if="asset.assetInfo.sellerName">
                                                        <td>{{T('Seller')}}</td>
                                                        <td>{{asset.assetInfo.sellerName}}</td>
                                                    </tr>
                                                    <tr v-if="asset.assetInfo.fileSizeBytes">
                                                        <td>{{T('Size')}}</td>
                                                        <td>{{ formatBytes(asset.assetInfo.fileSizeBytes,2).value + ' ' +formatBytes(asset.assetInfo.fileSizeBytes,2).unit }}</td>
                                                    </tr>
                                                    <tr v-if="asset.assetInfo.genres">
                                                        <td>{{T('Category')}}</td>
                                                        <td>
                                                            <template v-for="genre,index in asset.assetInfo.genres">
                                                            {{ genre }}<template v-if="asset.assetInfo.genres.length > (index + 1)">, </template>
                                                            </template>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="asset.assetInfo.minimumOsVersion">
                                                        <td>{{T('Compatibility')}}</td>
                                                        <td>{{ T('Requires iOS') + ' ' + asset.assetInfo.minimumOsVersion + ' ' + T('or later') + '.'}}</td>
                                                    </tr>
                                                    <tr v-if="asset.assetInfo.trackContentRating">
                                                        <td>{{T('Age Rating')}}</td>
                                                        <td>
                                                            {{ asset.assetInfo.trackContentRating }}<br>
                                                            <template v-for="advisory,index in asset.assetInfo.advisories">
                                                            {{ advisory }}<template v-if="asset.assetInfo.advisories.length > index"><br></template>
                                                            </template>

                                                        </td>
                                                    </tr>
                                                    <tr v-if="asset.assetInfo.currentVersionReleaseDate">
                                                        <td>{{T('Copyright')}}</td>
                                                        <td>&copy; {{ moment(asset.assetInfo.currentVersionReleaseDate).format('YYYY') + ' ' + asset.assetInfo.sellerName }}</td>
                                                    </tr>
                                                    <tr v-if="asset.assetInfo.sellerUrl">
                                                        <td></td>
                                                        <td>
                                                            <a target="_blank" rel="noreferrer noopener" :href="asset.assetInfo.sellerUrl">{{T('Developer Website')}}</a>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div :class="{'hidden': activeTab != 'assignment'}">
                                    <div class="box-content-2">
                                        <div class="row">
                                            <div class="col-xs-24">
                                                <div class="row padding-xs-y">
                                                    <div class="col-xs-24">
                                                        <strong></strong>
                                                    </div>
                                                </div>
                                                <div class="row padding-xs-y form-group border-bottom">
                                                    <div class="first col-xs-24 col-lg-4">
                                                        <label class="control-label inputname">
                                                            {{ T('Devices') }}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-20">
                                                        <div class="form-field margin-xs-b-0">
                                                            <input-vue-select
                                                                :multiple="true"
                                                                v-model="assignment.form.serials"
                                                                :selectOptions="assignment.deviceOptions"
                                                            >
                                                            </input-vue-select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row padding-xs-y form-group border-bottom">
                                                    <div class="first col-xs-24 col-lg-4">
                                                        <label class="control-label inputname">
                                                            {{ T('Apple VPP users') }}
                                                        </label>
                                                    </div>
                                                    <div class="input col-xs-24 col-lg-20">
                                                        <div class="form-field margin-xs-b-0">
                                                            <input-vue-select
                                                                :multiple="true"
                                                                v-model="assignment.form.clientUserIdStrs"
                                                                :selectOptions="assignment.userOptions"
                                                            >
                                                            </input-vue-select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-xs-24 padding-xs-t-2">
                                                        <p class="text-right">
                                                            <a
                                                                class="btn btn-loader twist-in"
                                                                :class="{ 'btn-loading': loader.saving }"
                                                                v-on:click="save()"
                                                            >
                                                                <span class="animate">
                                                                    <loader v-if="loader.saving" style="font-size: 1.5em; top: calc(50% - 0.375em - 1px); position: absolute; left: calc(50% - 0.375em - 0.5px);"></loader>
                                                                </span>
                                                                <span><i class="fal fa-save"></i> <span class="display-xs-none display-lg-inline">${T("Save")}</span></span>
                                                            </a>
                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </section>
        <template v-else>
            <p class="text-center padding-xs-t-4 text-size-4 padding-xs-b-0 text-center">
                <loader class="color-red"></loader>
            </p>
            <p class="text-center">
                {{ T(loader.loaderText) }}
            </p>
        </template>
    </div>
    `,
    data() {
        return {
            initialized: false,
            activeTab: "description",
            updateCounter: 0,
            loader: {
                loading: true,
                saving: false,
                loaderText: "Loading..."
            },
            assignment: {
                userOptions: [],
                deviceOptions: [],
                form: {
                    serials: [],
                    clientUserIdStrs: []
                },
                changes: {
                    serials: {
                        add: [],
                        remove: []
                    },
                    clientUserIdStrs: {
                        add: [],
                        remove: []
                    }
                }
            }
        }
    },
    computed: {
        account: function (this: any) {
            this.updateCounter
            return useStore().state.session.accounts[this.activeAccountId] || undefined
        },
        users: function (this: any) {
            if (config.canUseNewObjectType("users")) {
                return products.unifiedSecurity.users
                    .useStore?.()
                    ?.getObjectStoreObjects(this.activeAccountId)
            }
            return this.account.unifiedSecurity.users.items || []
        },
        devices: function (this: any) {
            return config.canUseNewObjectType("iosDevices")
                ? products.mobileSecurity.iosDevices
                      .useStore?.()
                      .getObjectStore(this.activeAccountId).objects
                : this.account.mobileSecurity.devices.items || []
        },
        asset: function (this: any) {
            let thisComponent: any = this
            let result: object[] = this.account
                ? this.account.mobileSecurity.vppassets.items.filter(function (asset: any) {
                      return asset.adamIdStr == thisComponent.objectId
                  })
                : []
            return result.length ? result[0] : undefined
        },
        labels: function (this: any) {
            this.updateCounter

            let thisLabels: any = []
            if (!this?.asset?.assetInfo?.isInStore) {
                thisLabels.push({
                    title: T("Not in App Store"),
                    class: "bg-yellow"
                })
            }
            return thisLabels
        },
        thisLicenses: function (this: any) {
            let thisComponent: any = this
            this.updateCounter
            let result: object[] = (
                this.account ? thisComponent.account.mobileSecurity.vpplicenses.items : []
            ).filter(function (license: any) {
                return license.adamIdStr == thisComponent.objectId
            })
            return result
        },

        vppUsers: function (this: any) {
            let users: any[] = this.users.filter(function (user: any) {
                return (
                    user.profile.clientUserIdStr != undefined &&
                    user.profile.appleId != undefined &&
                    user.profile.appleId.length
                )
            })
            return users
        },
        depDevices: function (this: any) {
            let devices: any[] = this.devices.filter(function (device: any) {
                return device.depDevice == true
            })
            return devices
        }
    },
    props: {},
    methods: {
        moment: moment,
        formatBytes: numberHelpers.formatBytes,
        init: async function (this: any) {
            let thisComponent: any = this
            let asset: any = this.asset
            this.loader.loading = true
            this.loader.loaderText = "Loading..."

            await useStore().dispatch(ActionTypes.getObjectInfos, {
                accountId: this.activeAccountId,
                objectTypes: ["vppassets"]
            })

            // GET USERS AND DEVICES
            if (config.canUseNewObjectType("users")) {
                await products.unifiedSecurity.users.queries.getObjectsFromApi(this.activeAccountId)
            } else {
                await useStore().dispatch(ActionTypes.getObjectInfos, {
                    accountId: this.activeAccountId,
                    objectTypes: ["users"]
                })
            }
            await useStore().dispatch(ActionTypes.getObjectInfos, {
                accountId: this.activeAccountId,
                objectTypes: ["devices", "depDevices"]
            })

            await useStore().dispatch(ActionTypes.getObjectInfos, {
                accountId: this.activeAccountId,
                objectTypes: ["vpplicenses?deviceAssignedOnly=false&objectId=" + this.objectId]
            })

            let userOptions: any = this.vppUsers.map(function (user: any) {
                return {
                    id: user.profile?.clientUserIdStr,
                    text: user.username + " (" + user.profile.appleId + ")"
                }
            })
            let deviceOptions: any = this.depDevices.map(function (device: any) {
                return {
                    id: device.info.serial,
                    text: deviceHelpers.getAliasedShortDeviceId(device.deviceId, device.alias)
                }
            })

            if (this.asset == undefined) {
                router.navigate("404-" + this.objectId)
            }

            // Get users for this asset
            let thisVppUsers: any[] = (thisComponent.vppUsers || []).filter(function (user: any) {
                return (
                    thisComponent.thisLicenses.filter(function (license: any) {
                        return user.profile.clientUserIdStr == license.clientUserIdStr
                    }).length > 0
                )
            })
            // Get clientUserIdStrs from this users
            let thisClientUserIdStrs: string[] = thisVppUsers.map(function (vppUser: any) {
                return vppUser.profile.clientUserIdStr
            })

            let licensesAssignedToDevice: any[] = (thisComponent.thisLicenses || []).filter(
                function (license: any) {
                    return license.serialNumber != undefined
                }
            )

            // Get serials from this devices
            let thisSerials: string[] = licensesAssignedToDevice.map(function (license: any) {
                return license.serialNumber
            })

            // add unknown devices/serials
            thisSerials.forEach(function (serial: string) {
                let deviceExists: boolean =
                    deviceOptions.filter(function (device: any) {
                        return device.id == serial
                    }).length > 0

                if (!deviceExists) {
                    deviceOptions.push({ id: serial, text: T("Unknown Device") })
                }
            })

            // set data
            thisComponent.assignment.form.clientUserIdStrs = thisClientUserIdStrs
            thisComponent.assignment.form.serials = thisSerials
            thisComponent.assignment.userOptions = userOptions
            thisComponent.assignment.deviceOptions = deviceOptions

            // add watcher / onChange event listener
            thisComponent.$watch(
                "assignment.form.clientUserIdStrs",
                function (newValue: string[], oldValue: string[]) {
                    // get removed clientUserIdStrs
                    let removedclientUserIdStrs = oldValue.filter(function (
                        clientUserIdStr: string
                    ) {
                        return newValue.indexOf(clientUserIdStr) == -1
                    })
                    // get added clientUserIdStrs
                    let addedclientUserIdStrs = newValue.filter(function (clientUserIdStr: string) {
                        return oldValue.indexOf(clientUserIdStr) == -1
                    })

                    removedclientUserIdStrs.forEach(function (clientUserIdStr: string) {
                        if (thisClientUserIdStrs.indexOf(clientUserIdStr) != -1) {
                            thisComponent.assignment.changes.clientUserIdStrs.remove.push(
                                clientUserIdStr
                            )
                        }

                        if (
                            thisComponent.assignment.changes.clientUserIdStrs.add.indexOf(
                                clientUserIdStr
                            ) != -1
                        ) {
                            let index: number =
                                thisComponent.assignment.changes.clientUserIdStrs.add.indexOf(
                                    clientUserIdStr
                                )
                            thisComponent.assignment.changes.clientUserIdStrs.add.splice(index, 1)
                        }
                    })
                    addedclientUserIdStrs.forEach(function (clientUserIdStr: string) {
                        if (thisClientUserIdStrs.indexOf(clientUserIdStr) == -1) {
                            thisComponent.assignment.changes.clientUserIdStrs.add.push(
                                clientUserIdStr
                            )
                        }
                        if (
                            thisComponent.assignment.changes.clientUserIdStrs.remove.indexOf(
                                clientUserIdStr
                            ) != -1
                        ) {
                            let index: number =
                                thisComponent.assignment.changes.clientUserIdStrs.remove.indexOf(
                                    clientUserIdStr
                                )
                            thisComponent.assignment.changes.clientUserIdStrs.remove.splice(
                                index,
                                1
                            )
                        }
                    })
                    function onlyUnique(value: any, index: any, self: any) {
                        return self.indexOf(value) === index
                    }
                    thisComponent.assignment.changes.clientUserIdStrs.remove =
                        thisComponent.assignment.changes.clientUserIdStrs.remove.filter(onlyUnique)
                    thisComponent.assignment.changes.clientUserIdStrs.add =
                        thisComponent.assignment.changes.clientUserIdStrs.add.filter(onlyUnique)
                }
            )

            // add watcher / onChange event listener
            thisComponent.$watch(
                "assignment.form.serials",
                function (newValue: string[], oldValue: string[]) {
                    // get removed clientUserIdStrs
                    let removedserials = oldValue.filter(function (serial: string) {
                        return newValue.indexOf(serial) == -1
                    })
                    // get added serials
                    let addedserials = newValue.filter(function (serial: string) {
                        return oldValue.indexOf(serial) == -1
                    })

                    removedserials.forEach(function (serial: string) {
                        if (thisSerials.indexOf(serial) != -1) {
                            thisComponent.assignment.changes.serials.remove.push(serial)
                        }

                        if (thisComponent.assignment.changes.serials.add.indexOf(serial) != -1) {
                            let index: number =
                                thisComponent.assignment.changes.serials.add.indexOf(serial)
                            thisComponent.assignment.changes.serials.add.splice(index, 1)
                        }
                    })
                    addedserials.forEach(function (serial: string) {
                        if (thisSerials.indexOf(serial) == -1) {
                            thisComponent.assignment.changes.serials.add.push(serial)
                        }
                        if (thisComponent.assignment.changes.serials.remove.indexOf(serial) != -1) {
                            let index: number =
                                thisComponent.assignment.changes.serials.remove.indexOf(serial)
                            thisComponent.assignment.changes.serials.remove.splice(index, 1)
                        }
                    })
                    function onlyUnique(value: any, index: any, self: any) {
                        return self.indexOf(value) === index
                    }
                    thisComponent.assignment.changes.serials.remove =
                        thisComponent.assignment.changes.serials.remove.filter(onlyUnique)
                    thisComponent.assignment.changes.serials.add =
                        thisComponent.assignment.changes.serials.add.filter(onlyUnique)
                }
            )

            if (!this.asset?.assetInfo?.isInStore) {
                this.activeTab = "assignment"
            }

            this.loader.loading = false
            this.initialized = true
            /*             nextTick(function() {
                $('#owl-carousel').owlCarousel({
                    loop:true,
                    margin:10,
                    nav:true,
                    responsive:{
                        0:{
                            items:1
                        },
                        600:{
                            items:3
                        },
                        1000:{
                            items:4
                        }
                    }
                })
            }) */
        },
        save: async function (this: any) {
            let result: boolean = true
            this.loader.saving = true
            try {
                if (
                    this.assignment.changes.clientUserIdStrs.remove.length > 0 ||
                    this.assignment.changes.serials.remove.length > 0 ||
                    this.assignment.changes.clientUserIdStrs.add.length > 0 ||
                    this.assignment.changes.serials.add.length > 0
                ) {
                    // loop through biggest array and create batches
                    let batches: any[] = []
                    let biggestArrayLength: number = Math.max.apply(null, [
                        this.assignment.changes.clientUserIdStrs.remove.length,
                        this.assignment.changes.serials.remove.length,
                        this.assignment.changes.clientUserIdStrs.add.length,
                        this.assignment.changes.serials.add.length
                    ])
                    let batchCount: number = 0
                    let entriesCount: number = 0
                    for (let i: number = 0; biggestArrayLength > i; i++) {
                        // Add batch if it doesn't exist
                        if (!batches[batchCount]) {
                            batches.push({
                                serials: {
                                    add: [],
                                    remove: []
                                },
                                clientUserIdStrs: {
                                    add: [],
                                    remove: []
                                }
                            })
                        }

                        if (this.assignment.changes.serials.add[i]) {
                            // add serial that should be assigned to batch
                            batches[batchCount].serials.add.push(
                                this.assignment.changes.serials.add[i]
                            )
                        }
                        if (this.assignment.changes.serials.remove[i]) {
                            // add serial that should be unassigned to batch
                            batches[batchCount].serials.remove.push(
                                this.assignment.changes.serials.remove[i]
                            )
                        }
                        if (this.assignment.changes.clientUserIdStrs.add[i]) {
                            // add clientUserIdStr that should be assigned to batch
                            batches[batchCount].clientUserIdStrs.add.push(
                                this.assignment.changes.clientUserIdStrs.add[i]
                            )
                        }
                        if (this.assignment.changes.clientUserIdStrs.remove[i]) {
                            // add clientUserIdStr that should be unassigned to batch
                            batches[batchCount].clientUserIdStrs.remove.push(
                                this.assignment.changes.clientUserIdStrs.remove[i]
                            )
                        }

                        entriesCount++
                        if (entriesCount == 10) {
                            batchCount++
                            entriesCount = 0
                        }
                    }
                    for (let i: number = 0; batches.length > i; i++) {
                        let batch: any = batches[i]

                        await queries.mobileSecurity.vpp.manageVPPLicensesByAdamIdSrv(
                            this.activeTenantDomain,
                            this.asset.adamIdStr,
                            batch.clientUserIdStrs.add || [],
                            batch.serials.add || [],
                            batch.clientUserIdStrs.remove || [],
                            [],
                            batch.serials.remove || []
                        )
                    }

                    await useStore().dispatch(ActionTypes.getObjectInfos, {
                        accountId: this.activeAccountId,
                        objectTypes: [
                            "vppassets",
                            "vpplicenses?deviceAssignedOnly=false&objectId=" + this.objectId
                        ]
                    })
                    router.navigate(
                        "show-tenant-" + this.activeAccountId + ".sms-vpp-assets-dashboard"
                    )
                }
            } catch (e: any) {
                result = e
            }
            this.loader.saving = false
            return result
        },
        setActiveTab: function (this: any, tabName: string) {
            this.activeTab = tabName
        }
    },
    watch: {
        asset: function (this: any) {}
    },
    created: async function (this: any) {
        if (!this.initialized) {
            this.init()
        }
        this.updateCounter = this.updateCounter + 1
    },
    mounted: function (this: any) {
        this.updateCounter = this.updateCounter + 1
    },
    beforeCreate(this: any) {},
    components: {
        loader: loaderComponent,
        "input-vue-select": inputVueSelectVue,
        carousel: Carousel,
        slide: Slide,
        navigation: Navigation
    }
}
export default pageVppAssetDetailsComponent
