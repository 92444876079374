<script setup lang="ts">
import config from "@/classes/config"
import { T } from "@/classes/i18n"
import objectStores from "@/classes/init"
import products from "@/classes/objectTypes"
import type { VppUser } from "@/classes/objectTypes/mobileSecurity/apple/vppUsers"
import type { License } from "@/classes/unifiedSecurity/licenses"
import type { User } from "@/classes/unifiedSecurity/users"
import imageHelpers from "@/helpers/helpers.images"
import mixinHelpers from "@/helpers/helpers.mixins"
import timeHelpers from "@/helpers/helpers.time"
import queries from "@/queries/queries"
import { ActionTypes, MutationTypes, useStore } from "@/store/vuex.store"
import { computed, onMounted, ref, watch } from "vue"
import loader from "../components/loader.vue"
import inputVueSelect from "../inputtypes/input-vue-select.vue"

const props = defineProps<{
    maximized: boolean
}>()

const activeAccountId = computed(() => {
    return useStore().getters.getActiveAccountId
})
const activeTenantDomain = computed(() => {
    return activeAccountId.value ? activeAccountId.value + ".sms" : undefined
})

const deviceLimitReached = computed(() => {
    return !useStore()?.state.session.accounts[activeAccountId.value].mobileSecurity.settings
        .enrollmentAllowed
})
const enrollmentDisabled = computed(() => {
    return (
        useStore()?.state.session.accounts[activeAccountId.value].mobileSecurity.settings
            .accountRestrictions > 0
    )
})
const mdmLicenseExceeded = computed(() => {
    return useStore()?.state.session.accounts[activeAccountId.value].mobileSecurity.settings
        .mdmLicenseExceeded
})
const darkmode = computed(() => {
    return mixinHelpers.getDarkmode()
})

const initialized = ref(false)
const loaderText = ref("Loading...")
const enrollment = ref({
    EID: "",
    enrollmentURL: "",
    license: {
        licenseOptions: <any[]>[],
        selected: "",
        disabled: false
    },
    ios: {
        enabled: true,
        disabledReason: "",
        qrcodeURL: "",
        useManagedAppleId: false,
        managedAppleId: "",
        appleIdOptions: <any[]>[],
        username: "",
        hasInviteURL: false,
        hasInviteCode: false
    }
})
const copyURLStatus = ref("none")

const copyToClipboard = (text: string) => {
    copyURLStatus.value = "loading"
    navigator.clipboard.writeText(text).then(
        async () => {
            copyURLStatus.value = "success"
            await timeHelpers.sleep(1500)
            copyURLStatus.value = "none"
        },
        (err) => {
            copyURLStatus.value = "none"
            console.error("Could not copy text: ", err)
        }
    )
}

const isEmail = (mail: string | null) => {
    if (mail == null) mail = ""
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,24})+$/.test(mail)
}

const printUserEnrollmentImage = (URL: string) => {
    const source = enrollment.value.ios.qrcodeURL
    const headerContent =
        document.getElementById("print-header")?.outerHTML +
        "<h1 style='margin-top:64px'>iOS User Enrollment</h1>"
    const footerContent = document.getElementsByClassName("main-footer")[0]?.outerHTML
    imageHelpers.printImage(headerContent, footerContent, document.location.origin + source)
}
const printImage = (URL: string) => {
    const source = enrollment.value.ios.qrcodeURL
    const headerContent =
        document.getElementById("print-header")?.outerHTML +
        "<h1 style='margin-top:64px'>iOS Enrollment</h1>"
    const footerContent = document.getElementsByClassName("main-footer")[0]?.outerHTML
    imageHelpers.printImage(headerContent, footerContent, document.location.origin + source)
}

const getMailWithoutIndex = (email: string) => {
    return email != null && email != undefined && email != ""
        ? email.slice(0, email.indexOf("#usrNr") != -1 ? email.indexOf("#usrNr") : email.length)
        : null
}
const getUserEnrollmentQrURI = (username: string) => {
    return (
        config.mgtApiUri +
        "/tenants/" +
        activeAccountId.value +
        ".sms/users/" +
        username +
        "/qrcode/vpp"
    )
}

const updateLicenses = async () => {
    // LICENSE SELECTION
    loaderText.value = "Getting licenses..."
    let licenses: License[] = [],
        licenseOptions: any[] = []
    config.canUseNewObjectType("licenses")
        ? await products.unifiedSecurity.licenses.queries.getObjectsFromApi(activeAccountId.value)
        : await objectStores.licenses.getObjectsFromApi(activeAccountId.value)
    licenses =
        (config.canUseNewObjectType("licenses")
            ? products.unifiedSecurity.licenses
                  .useStore?.()
                  .getObjectStoreObjects(activeAccountId.value)
            : objectStores.licenses.getObjectsFromStore(activeAccountId.value)) || []

    for (let i: number = 0; licenses.length > i; i++) {
        let license = licenses[i]
        let licenceUser = mixinHelpers.getLicenseLimit(license)
        if (
            (license.type == "Mobile Security" || license.type == "MDM") &&
            license.uuid &&
            license.assignedUser < licenceUser &&
            !license.expired &&
            !license.dead
        ) {
            licenseOptions.push({
                id: license.uuid,
                text:
                    license.name +
                    " | " +
                    license.type +
                    " [" +
                    license.assignedUser +
                    "/" +
                    licenceUser +
                    "] (" +
                    license.uuid.substring(0, 4) +
                    ")",
                disabled: license.assignedUser >= licenceUser || license.expired || license.dead,
                licenseType: license.type
            })
        }
    }
    enrollment.value.license.licenseOptions = licenseOptions

    if (licenseOptions.length) {
        enrollment.value.license.selected = licenseOptions[0].id
    }
}

const closeModal = () => {
    useStore()?.commit(MutationTypes.removeModal, { accountId: activeAccountId.value })
}

onMounted(async () => {
    initialized.value = false
    loaderText.value = "Getting tenant info..."

    enrollment.value.enrollmentURL =
        useStore().state.session.accounts[
            activeAccountId.value
        ].mobileSecurity.settings.ios.enrollmentURL
    enrollment.value.EID =
        useStore().state.session.accounts[activeAccountId.value].mobileSecurity.settings.ios.EID

    if (
        useStore().state.session.accounts[activeAccountId.value].mobileSecurity.settings.ios.apns
            .expired
    ) {
        enrollment.value.ios.enabled = false
        enrollment.value.ios.disabledReason =
            '<i class="fal fa-exclamation-triangle color-red"></i>&nbsp;&nbsp;iOS: ' +
            T("Upload an Apple Push Certificate first")
    } else if (
        !useStore().state.session.accounts[activeAccountId.value].mobileSecurity.settings.ios.apns
            .matchesPrivateKey
    ) {
        enrollment.value.ios.enabled = false
        enrollment.value.ios.disabledReason =
            '<i class="fal fa-exclamation-triangle color-red"></i>&nbsp;&nbsp;iOS: ' +
            T("Invalid Apple Push Certificate")
    }

    loaderText.value = "Getting QR-Codes..."
    enrollment.value.ios.qrcodeURL = activeTenantDomain.value
        ? queries.mobileSecurity.getQRCodeURL(activeTenantDomain.value, "ios")
        : ""

    await updateLicenses()

    loaderText.value = "Getting registered Email addresses..."
    try {
        let users: any = {}
        if (config.canUseNewObjectType("users")) {
            await products.unifiedSecurity.users.queries.getObjectsFromApi(activeAccountId.value)
        } else {
            await useStore().dispatch(ActionTypes.getObjectInfos, {
                accountId: activeAccountId.value,
                objectTypes: ["users"]
            })
        }
        if (config.canUseNewObjectType("vppUsers")) {
            await products.mobileSecurity.vppUsers.queries.getObjectsFromApi(activeAccountId.value)
        } else {
            await useStore().dispatch(ActionTypes.getObjectInfos, {
                accountId: activeAccountId.value,
                objectTypes: ["vppUsers"]
            })
        }

        users = config.canUseNewObjectType("users")
            ? products.unifiedSecurity.users
                  .useStore?.()
                  ?.getObjectStoreObjects(activeAccountId.value)
            : useStore().getters.getObjectTypeStore({
                  accountId: activeAccountId.value,
                  objectType: "users"
              })?.items || []

        if (users.length) {
            for (let userIndex in users) {
                let user: User = users[userIndex]
                if (user.profile?.appleId) {
                    enrollment.value.ios.appleIdOptions.push({
                        text: user.username + "<" + user.profile?.appleId + ">",
                        appleId: user.profile?.appleId,
                        clientUserIdStr: user.profile?.clientUserIdStr,
                        id: user.username
                    })
                }
            }
        }
    } catch (e: any) {
        console.error(e)
        loaderText.value =
            "<i class='fas fa-exclamation-triangle color-red'></i> Something went wrong"
    }

    loaderText.value = "Finished loading..."
    initialized.value = true
})

watch(
    () => enrollment.value.ios.useManagedAppleId,
    () => {
        if (!enrollment.value.ios.useManagedAppleId) {
            enrollment.value.ios.username = ""
            enrollment.value.ios.managedAppleId = ""
            enrollment.value.ios.hasInviteURL = false
        }
    }
)

watch(
    () => enrollment.value.ios.username,
    (username) => {
        if (username?.length) {
            let userOption = enrollment.value.ios.appleIdOptions.find((option: any) => {
                return option.id == username
            })
            let vppUser: VppUser | undefined = undefined
            if (config.canUseNewObjectType("vppUsers")) {
                vppUser = products.mobileSecurity.vppUsers
                    .useStore?.()
                    .getObjectStoreObject(activeAccountId.value, userOption.clientUserIdStr)
            } else {
                vppUser = useStore().getters.getObject({
                    accountId: activeAccountId.value,
                    objectId: userOption.clientUserIdStr,
                    objectType: "vppUsers"
                })
            }
            if (vppUser && vppUser.inviteCode) {
                enrollment.value.ios.hasInviteCode = true
            } else {
                enrollment.value.ios.hasInviteCode = false
            }

            if (vppUser && (<any>vppUser).inviteUrl) {
                enrollment.value.ios.hasInviteURL = true
            } else {
                enrollment.value.ios.hasInviteURL = false
            }
            enrollment.value.ios.managedAppleId = userOption.appleId
        } else {
            enrollment.value.ios.managedAppleId = ""
            enrollment.value.ios.hasInviteURL = false
            enrollment.value.ios.hasInviteCode = false
        }
    }
)
</script>
<template>
    <div>
        <template v-if="initialized">
            <template v-if="deviceLimitReached">
                <div class="padding-xs-2">
                    <p>
                        {{
                            T(
                                "You have reached the devices limit. If you want to add more Devices, you have to upgrade your Mobile Security License."
                            )
                        }}
                    </p>
                </div>
            </template>
            <template v-else-if="enrollmentDisabled">
                <div class="padding-xs-2">
                    <p>
                        {{ T("The enrollment has been disabled.") }}
                    </p>
                </div>
            </template>
            <template v-else-if="mdmLicenseExceeded">
                <div class="padding-xs-2">
                    <p>
                        {{
                            T(
                                "Your Mobile Security has exceeded. Please upgrade your Mobile Security License."
                            )
                        }}
                    </p>
                </div>
            </template>
            <template v-else>
                <div>
                    <div>
                        <div class="col-xs-24">
                            <p
                                v-if="enrollment?.ios?.useManagedAppleId == true"
                                class="notification"
                                :class="{ 'color-white': darkmode == '1' }"
                            >
                                <i class="fal fa-exclamation-triangle fa-fw color-red"></i>
                                &nbsp;&nbsp;
                                {{
                                    T(
                                        "Achtung: Die Anmeldung mit verwalteter Apple-ID ist nur für Geräte mit iOS < 18 verfügbar."
                                    )
                                }}
                            </p>

                            <div class="row padding-xs-b form-group border-bottom">
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname" for="useManagedAppleId">
                                        {{ T("Use managed Apple ID") }}
                                    </label>
                                </div>
                                <div class="input col-xs-24 col-lg-8">
                                    <label class="checkbox toggle">
                                        <input
                                            type="checkbox"
                                            id="useManagedAppleId"
                                            class="toggle"
                                            v-model="enrollment.ios.useManagedAppleId"
                                        />
                                        <span></span>
                                    </label>
                                </div>
                                <div class="desc col-xs-24 col-lg-10">
                                    <p class="input-description">
                                        {{
                                            T(
                                                "Wether you want to enroll with a managed Apple ID or not."
                                            )
                                        }}
                                    </p>
                                </div>
                            </div>

                            <div
                                class="row padding-xs-y form-group border-bottom"
                                v-if="enrollment.ios.useManagedAppleId"
                            >
                                <div class="first col-xs-24 col-lg-6 col-xl-5">
                                    <label class="control-label inputname" for="managedAppleId">
                                        {{ T("Managed Apple ID") }}
                                    </label>
                                </div>
                                <div class="input col-xs-24 col-lg-8">
                                    <input-vue-select
                                        v-model="enrollment.ios.username"
                                        :selectOptions="enrollment.ios.appleIdOptions"
                                        :placeholder="T('Choose a Managed Apple ID')"
                                        :multiple="false"
                                        id="managedAppleId"
                                    >
                                    </input-vue-select>
                                </div>
                                <div class="desc col-xs-24 col-lg-10">
                                    <p class="input-description">
                                        {{ T("Choose a Managed Apple ID") }}
                                    </p>
                                </div>
                            </div>

                            <div
                                class="row padding-xs-y form-group border-bottom"
                                v-if="enrollment.license.licenseOptions.length"
                            >
                                <div class="first col-xs-24 col-lg-3">
                                    <label class="control-label inputname" for="licenseUUID">
                                        {{ T("License") }}
                                    </label>
                                </div>
                                <div class="input col-xs-24 col-lg-11">
                                    <label class="checkbox toggle">
                                        <select
                                            id="licenseUUID"
                                            :disabled="enrollment.license.disabled || null"
                                            v-model="enrollment.license.selected"
                                            style="width: 100%"
                                        >
                                            <option value="" disabled>
                                                {{ T("Please select a license") }}
                                            </option>
                                            <template
                                                v-for="license in enrollment.license.licenseOptions"
                                            >
                                                <option
                                                    :value="license.id"
                                                    :disabled="license.disabled || null"
                                                >
                                                    {{ license.text }}
                                                </option>
                                            </template>
                                        </select>
                                        <span></span>
                                    </label>
                                </div>
                                <div class="desc col-xs-24 col-lg-10">
                                    <p class="input-description">
                                        {{
                                            T(
                                                "The license that will be used for new enrolled devices."
                                            )
                                        }}
                                    </p>
                                </div>
                            </div>
                            <template
                                v-if="
                                    enrollment.ios.useManagedAppleId == false ||
                                    (enrollment.ios.useManagedAppleId == true &&
                                        isEmail(getMailWithoutIndex(enrollment.ios.managedAppleId)))
                                "
                            >
                                <div class="row padding-xs-y border-bottom">
                                    <div class="first col-xs-24 col-lg-3">
                                        <label
                                            class="control-label margin-xs-t-0"
                                            for="device-enrollment-url"
                                        >
                                            {{ T("URL") }}
                                        </label>
                                    </div>
                                    <div class="input col-xs-24 col-lg-11">
                                        <input
                                            id="device-enrollment-url"
                                            type="text"
                                            class="form-control"
                                            :value="
                                                enrollment.enrollmentURL +
                                                (enrollment.ios.useManagedAppleId == true &&
                                                isEmail(
                                                    getMailWithoutIndex(
                                                        enrollment.ios.managedAppleId
                                                    )
                                                )
                                                    ? '&managedAppleId=' +
                                                      getMailWithoutIndex(
                                                          enrollment.ios.managedAppleId
                                                      )
                                                    : '') +
                                                '&licenseUUID=' +
                                                enrollment.license.selected
                                            "
                                            readonly
                                            onClick="this.select();"
                                        />
                                    </div>
                                    <div class="input col-xs-24 col-lg-5 text-center">
                                        <a
                                            class="btn btn-primary"
                                            :class="{ 'btn-loading': copyURLStatus == 'loading' }"
                                            v-on:click="
                                                copyToClipboard(
                                                    enrollment.enrollmentURL +
                                                        (enrollment.ios.useManagedAppleId == true &&
                                                        isEmail(
                                                            getMailWithoutIndex(
                                                                enrollment.ios.managedAppleId
                                                            )
                                                        )
                                                            ? '&managedAppleId=' +
                                                              getMailWithoutIndex(
                                                                  enrollment.ios.managedAppleId
                                                              )
                                                            : '') +
                                                        '&licenseUUID=' +
                                                        enrollment.license.selected
                                                )
                                            "
                                            :disabled="copyURLStatus != 'none' || null"
                                        >
                                            <span class="animate">
                                                <loader
                                                    v-if="copyURLStatus == 'loading'"
                                                    style="
                                                        font-size: 1.5em;
                                                        top: calc(50% - 0.375em - 1px);
                                                        position: absolute;
                                                        left: calc(50% - 0.375em - 0.5px);
                                                    "
                                                ></loader>
                                            </span>
                                            <span>
                                                <template v-if="copyURLStatus == 'none'">
                                                    {{ T("Copy to clipboard") }}
                                                </template>
                                                <template v-if="copyURLStatus == 'success'">
                                                    <i class="fal fa-check"></i>
                                                </template>
                                            </span>
                                        </a>
                                    </div>
                                    <div class="input col-xs-24 col-lg-5 text-center">
                                        <a
                                            class="btn btn-primary"
                                            :href="
                                                enrollment.enrollmentURL +
                                                (enrollment.ios.useManagedAppleId == true &&
                                                isEmail(
                                                    getMailWithoutIndex(
                                                        enrollment.ios.managedAppleId
                                                    )
                                                )
                                                    ? '&managedAppleId=' +
                                                      getMailWithoutIndex(
                                                          enrollment.ios.managedAppleId
                                                      )
                                                    : '') +
                                                '&licenseUUID=' +
                                                enrollment.license.selected
                                            "
                                            target="_blank"
                                            v-on:click="
                                                () => {
                                                    updateLicenses()
                                                    closeModal()
                                                }
                                            "
                                        >
                                            {{ T("Download Profile") }}
                                        </a>
                                    </div>
                                </div>
                                <div class="row padding-xs-y">
                                    <div class="col-xs-24">
                                        <div class="form-group padding-xs-y">
                                            <div class="col-sm-12 text-center" v-if="false">
                                                <h5 class="padding-xs-y" v-if="false">
                                                    {{
                                                        T(
                                                            "1. Scan this QR-Code and go through the process to link your Apple ID to the managed Apple ID"
                                                        )
                                                    }}
                                                </h5>
                                                <img
                                                    style="display: inline-block"
                                                    :src="
                                                        getUserEnrollmentQrURI(
                                                            enrollment.ios.username
                                                        )
                                                    "
                                                />
                                                <br />
                                                <a
                                                    class="btn"
                                                    @click="
                                                        printUserEnrollmentImage(
                                                            getUserEnrollmentQrURI(
                                                                enrollment.ios.username
                                                            )
                                                        )
                                                    "
                                                >
                                                    <i class="fal fa-print"></i>
                                                    {{ T("Print QR-Code") }}
                                                </a>
                                            </div>

                                            <div
                                                class="text-center"
                                                :class="{
                                                    'col-sm-24':
                                                        !(
                                                            enrollment.ios.useManagedAppleId &&
                                                            enrollment.ios.hasInviteURL
                                                        ) || true,
                                                    'col-sm-12':
                                                        enrollment.ios.useManagedAppleId &&
                                                        enrollment.ios.hasInviteURL &&
                                                        false
                                                }"
                                            >
                                                <h5
                                                    class="padding-xs-y"
                                                    v-if="
                                                        enrollment.ios.useManagedAppleId &&
                                                        enrollment.ios.hasInviteURL &&
                                                        false
                                                    "
                                                >
                                                    {{
                                                        T("2. Scan this device enrollment QR-Code")
                                                    }}
                                                </h5>
                                                <img
                                                    style="display: inline-block"
                                                    :src="
                                                        enrollment.ios.qrcodeURL +
                                                        '?licenseUUID=' +
                                                        enrollment.license.selected +
                                                        (enrollment.ios.useManagedAppleId == true &&
                                                        isEmail(
                                                            getMailWithoutIndex(
                                                                enrollment.ios.managedAppleId
                                                            )
                                                        )
                                                            ? '&managedAppleId=' +
                                                              getMailWithoutIndex(
                                                                  enrollment.ios.managedAppleId
                                                              )
                                                            : '')
                                                    "
                                                />
                                                <br />
                                                <a
                                                    class="btn"
                                                    @click="
                                                        printImage(
                                                            enrollment.ios.qrcodeURL +
                                                                '?licenseUUID=' +
                                                                enrollment.license.selected +
                                                                (enrollment.ios.useManagedAppleId ==
                                                                    true &&
                                                                isEmail(
                                                                    getMailWithoutIndex(
                                                                        enrollment.ios
                                                                            .managedAppleId
                                                                    )
                                                                )
                                                                    ? '&managedAppleId=' +
                                                                      getMailWithoutIndex(
                                                                          enrollment.ios
                                                                              .managedAppleId
                                                                      )
                                                                    : '')
                                                        )
                                                    "
                                                >
                                                    <i class="fal fa-print"></i>
                                                    {{ T("Print QR-Code") }}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
        </template>
        <template v-if="!initialized">
            <div>
                <div class="text-size-3 text-center padding-xs-t-4">
                    <loader class="color-red"></loader>
                </div>
                <template v-if="loaderText">
                    <div class="text-center padding-xs-t-2 padding-xs-b-4" style="opacity: 0.8">
                        <span>{{ T(loaderText) }}</span>
                    </div>
                </template>
                <template v-else>
                    <div class="padding-xs-b-4"></div>
                </template>
            </div>
        </template>
    </div>
</template>
