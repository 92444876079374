import getterHelpers from "@/helpers/helpers.getters"
import { useGlobalMixin } from "@/mixins/mixins.global"

const setPasswordComponent = {
    name: "set-password",
    mixins: [useGlobalMixin()],
    template: `
        <div>
            <input type="text" style="display:none">
            <input type="password" style="display:none">
            <div class="row padding-xs-b form-group border-bottom">
                <div class="first col-xs-24 col-lg-6 col-xl-5">
                    <label class="control-label inputname" for="password">{{ T('Password') }}</label>
                </div>
                <div class="input col-xs-24 col-lg-8">
                    <label class="password">
                        <input id="password" type="password" v-model="password" :placeholder="T('Password')" autocomplete="new-password">
                    </label>
                </div>
                <div class="desc col-xs-24 col-lg-10">

                </div>
                <div class="col-xs-24">
                    <p>
                        <i 
                            class="fal fa-fw" 
                            :class="{
                                'fa-times color-red':!(hasMinLength && isUnderMaxLength),
                                'fa-check color-green':(hasMinLength && isUnderMaxLength),
                            }"
                        ></i> {{ T('Between 8 and 256 Characters') }}
                    </p>
                    <p>
                        <i
                            class="fal fa-fw"
                            :class="{
                                'fa-times color-red':!(hasNoWhitespacesAtStartOrEnd),
                                'fa-check color-green':hasNoWhitespacesAtStartOrEnd,
                            }"
                        ></i> {{ T('Does not start or end with whitespaces') }}
                    </p>
                    <p>
                        <i
                            class="fal fa-fw"
                            :class="{
                                'fa-times color-red':!(hasNoControlCharacters),
                                'fa-check color-green':hasNoControlCharacters,
                            }"
                        ></i> {{ T('No controlcharacters') }}
                    </p>
                    <p>
                        <i
                            class="fal fa-fw"
                            :class="{
                                'fa-times color-red':!(hasAtLeastThreeCharTypes),
                                'fa-check color-green':hasAtLeastThreeCharTypes,
                            }"
                        ></i> {{ T('Has at least 3 different character types (Lowercase, uppercase, numbers, special characters)') }}
                    </p>
                </div>
            </div>

            <div class="row padding-xs-t form-group">
                <div class="first col-xs-24 col-lg-6 col-xl-5">
                    <label class="control-label inputname" for="passwordRepeat">{{T('Repeat password')}}</label>
                </div>
                <div class="input col-xs-24 col-lg-8">
                    <label class="password">
                        <input id="passwordRepeat" type="password" v-model="passwordRepeat" :placeholder="T('Repeat password')">
                    </label>
                </div>
                <div class="desc col-xs-24 col-lg-10">

                </div>
                <div class="col-xs-24">
                    <p>
                        <i
                            class="fal fa-fw"
                            :class="{
                                'fa-times color-red':!(passwordsMatch),
                                'fa-check color-green':(passwordsMatch),
                            }"
                        ></i> {{ T('Passwords match') }}
                    </p>
                </div>
            </div>

        </div>
    `,
    data: function () {
        return {
            password: "",
            passwordRepeat: ""
        }
    },
    computed: {
        hasMinLength: function (this: any) {
            return this.password.length >= 8
        },
        isUnderMaxLength: function (this: any) {
            return this.password.length <= 256
        },
        hasNoWhitespacesAtStartOrEnd: function (this: any) {
            return this.password.length
                ? this.password[0] !== " " && this.password[this.password.length - 1] !== " "
                : true
        },
        hasNoControlCharacters: function (this: any) {
            return !/[\x00-\x1f\x7f]+/.test(this.password)
        },
        passwordsMatch: function (this: any) {
            return this.password == this.passwordRepeat
        },
        hasAtLeastThreeCharTypes: function (this: any) {
            let existingTypes = []
            if (/[0-9]+/.test(this.password)) {
                existingTypes.push("number")
            }
            if (/[a-z]+/.test(this.password)) {
                existingTypes.push("lowercase")
            }
            if (/[A-Z]+/.test(this.password)) {
                existingTypes.push("uppercase")
            }
            if (/[\W]+/.test(this.password)) {
                existingTypes.push("special character")
            }
            return existingTypes.length >= 3
        }
    },
    methods: {
        checkPasswords: function (this: any) {
            if (
                this.hasMinLength &&
                this.isUnderMaxLength &&
                this.hasNoWhitespacesAtStartOrEnd &&
                this.hasNoControlCharacters &&
                this.hasAtLeastThreeCharTypes &&
                this.passwordsMatch
            ) {
                getterHelpers
                    .useStore()
                    .getters.getActiveModal(
                        getterHelpers.useStore().state.session.activeAccountId
                    ).buttons[1].disabled = false
            } else {
                getterHelpers
                    .useStore()
                    .getters.getActiveModal(
                        getterHelpers.useStore().state.session.activeAccountId
                    ).buttons[1].disabled = true
            }
        }
    },
    created: async function (this: any) {},
    mounted: function (this: any) {},
    watch: {
        password: function (this: any) {
            this.checkPasswords()
        },
        passwordRepeat: function (this: any) {
            this.checkPasswords()
        }
    }
}
export default setPasswordComponent
