import { defineStore } from "pinia"

const useRouterStore = defineStore({
    id: "router",
    state: () => ({
        activePage: <string | undefined>undefined,
        context: <string | undefined>undefined,
        objectId: <string | undefined>undefined,
        zeroTouchCustomerId: <string | undefined>undefined,
        search: <string | undefined>undefined
    }),
    getters: {
        getRouter(state) {
            return {
                page: state.activePage,
                context: state.context,
                objectId: state.objectId,
                zeroTouchCustomerId: state.zeroTouchCustomerId,
                search: state.search
            }
        },
        getActivePage(state) {
            return state.activePage
        },
        getContext(state) {
            return state.context
        },
        getObjectId(state) {
            return state.objectId
        },
        getZeroTouchCustomerId(state) {
            return state.zeroTouchCustomerId
        },
        getSearch(state) {
            return state.search
        }
    },
    actions: {
        /**
         * sets Router
         */
        setRouter(
            page: string,
            context: string | undefined = undefined,
            objectId: string | undefined = undefined,
            zeroTouchCustomerId: string | undefined = undefined,
            search: string | undefined = undefined
        ) {
            this.activePage = page
            this.context = context
            this.objectId = objectId
            this.zeroTouchCustomerId = zeroTouchCustomerId
            this.search = search
        },
        setActivePage(page: string | undefined) {
            this.activePage = page
        },
        setContext(context: string | undefined) {
            this.context = context
        },
        setObjectId(objectId: string | undefined) {
            this.objectId = objectId
        },
        setZeroTouchCustomerId(id: string | undefined) {
            this.zeroTouchCustomerId = id
        },
        setSearch(search: string | undefined) {
            this.search = search
        }
    }
})
export default useRouterStore
