import dialogs from "@/dialogs/dialogs"
import { useGlobalMixin } from "@/mixins/mixins.global"
import queries from "@/queries/queries"

const inputIosWallpaperComponent = {
    name: "input-ios-wallpaper",
    mixins: [useGlobalMixin()],
    props: {
        modelValue: {
            type: Array,
            default: [],
            required: false
        }
    },
    template: `
        <div>
                <div class="row flexrow">
                    <div>
                        <div 
                            class="imagepreview" 
                            :style="wallpaper.image ? 'background-image:url('+wallpaper.image+')' : ''"
                        >
                            <template v-if="wallpaper.id != ''">
                                <a class="deleteButton" v-on:click="deleteImage('wallpaper')">
                                    <i class="fal fa-times"></i>
                                </a>
                            </template>
                            <template v-if="wallpaper.id == ''">
                                <i class="fal fa-question"></i>
                            </template>
                        </div>

                        <a v-on:click="openMediabrowser('wallpaper')" class="btn btn-primary" style="margin-top:8px; width:150px;">
                            {{T('Select wallpaper')}}
                        </a>
                        
                        <p style="line-height:2.8em; margin-top:2em">
                            <input type="checkbox" name="useAsLockscreen" :value="true" v-model="useAsLockscreen"> {{T('Use wallpaper as lockscreen, too')}}
                        </p>
                    </div>
                    <div>
                        <div 
                            v-if="wallpaper.where == 2"
                            class="imagepreview" 
                            :style="(lockscreen.id && lockscreen.image && wallpaper.where != 3) ? 'background-image:url('+lockscreen.image+')' : (wallpaper.where == 3 && wallpaper.image != '') ? 'background-image:url('+wallpaper.image+')' : ''"
                        >
                            <template v-if="lockscreen.id && wallpaper.where != 3">
                                <a class="deleteButton" v-on:click="deleteImage('lockscreen')">
                                    <i class="fal fa-times"></i>
                                </a>
                            </template>
                            <template v-if="lockscreen.id == '' && wallpaper.where != 3">
                                <i class="fal fa-question"></i>
                            </template>
                        </div>
                        <template v-if="wallpaper.where == 2">
                            <a v-on:click="openMediabrowser('lockscreen')" class="btn btn-primary" style="margin-top:8px; width:150px;">
                                {{T('Select lockscreen')}}
                            </a>
                        </template>
                    </div>
                </div>
        </div>
    `,
    data() {
        return {
            initialized: false,
            useAsLockscreen: false,
            wallpaper: {
                id: "",
                image: "",
                where: 2
            },
            lockscreen: {
                id: "",
                image: "",
                where: 1
            },
            error: ""
        }
    },
    methods: {
        openMediabrowser: function (this: any, type: "wallpaper" | "lockscreen") {
            dialogs.unifiedSecurity.mediaSelector((imageId: string) => {
                if (imageId) {
                    this[type].id = imageId
                }
            })
        },
        deleteImage: function (this: any, type: "wallpaper" | "lockscreen") {
            this[type].id = ""
            this[type].image = ""
        },
        getImage: async function (this: any, imageId: string) {
            return await queries.unifiedSecurity.getObjectInfo(
                this.activeAccountId,
                "images",
                imageId,
                undefined,
                undefined,
                undefined,
                [{ property: "type", value: "data-uri" }]
            )
        },
        updateValue: function (this: any) {
            let value = []
            if (this.wallpaper.id != "") {
                value.push({
                    id: this.wallpaper.id,
                    where: this.wallpaper.where
                })
            }

            if (this.lockscreen.id != "" && this.wallpaper.where != 3) {
                value.push({
                    id: this.lockscreen.id,
                    where: 1
                })
            }

            this.$emit("update:modelValue", value)
        }
    },
    mounted: function (this: any) {
        if (this.modelValue && this.modelValue.length) {
            this.modelValue.forEach((entry: any) => {
                if (entry.where == 2) {
                    this.wallpaper.id = entry.id
                    this.wallpaper.where = entry.where
                } else if (entry.where == 1) {
                    this.lockscreen.id = entry.id
                    this.lockscreen.where = entry.where
                } else if (entry.where == 3) {
                    this.wallpaper.id = entry.id
                    this.wallpaper.where = entry.where
                    this.lockscreen.id = ""
                    this.lockscreen.where = ""
                    this.useAsLockscreen = true
                }
            })
        }
        this.initialized = true
    },
    watch: {
        "wallpaper.id": async function (this: any, imageId: string) {
            this.wallpaper.image = ""
            if (imageId) {
                this.wallpaper.image = await this.getImage(imageId)
            } else {
                this.wallpaper.image = ""
            }
            if (this.initialized) {
                this.updateValue()
            }
        },
        "wallpaper.where": async function (this: any) {
            this.wallpaper.where = parseInt(this.wallpaper.where)
            if (this.wallpaper.where == 3 || this.wallpaper.where == "3") {
                this.lockscreen.id = ""
                this.lockscreen.image = ""
            }
            if (this.initialized) {
                this.updateValue()
            }
        },
        "lockscreen.id": async function (this: any, imageId: string) {
            this.lockscreen.image = ""
            if (imageId) {
                this.lockscreen.image = await this.getImage(imageId)
            } else {
                this.lockscreen.image = ""
            }
            if (this.initialized) {
                this.updateValue()
            }
        },
        useAsLockscreen: async function (this: any) {
            if (this.useAsLockscreen) {
                this.wallpaper.where = 3
            } else {
                this.wallpaper.where = 2
            }
        }
    }
}
export default inputIosWallpaperComponent
