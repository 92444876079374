<script setup lang="ts">
import mixinHelpers from "@/helpers/helpers.mixins"
import { computed } from "vue"
import { type TableEntryStatus } from "./tableNext.vue"
import Tooltip from "./tooltip.vue"

const props = withDefaults(
    defineProps<{
        status: TableEntryStatus
        align?: "left" | "center" | "right"
        lineHeight?: number
    }>(),
    {
        align: "center"
    }
)

const darkMode = computed(() => {
    return mixinHelpers.getDarkmode()
})
</script>
<template>
    <div
        :class="{
            'cell-status': true,
            'text-left': props.align == 'left',
            'text-right': props.align == 'right',
            'text-center': props.align == 'center'
        }"
        :style="'max-height: ' + lineHeight + 'px;'"
        v-if="props.status"
    >
        <Tooltip :tooltip="props.status?.tooltip || ''" :is-tag="'span'">
            <i
                class="fa fa-fw"
                style="font-size: 1.25em"
                :data-icon="props.status?.icon == undefined"
                :data-color="props.status?.color"
                :class="[
                    'color-' +
                        (props.status?.color == 'grey'
                            ? darkMode
                                ? 'lightgray'
                                : 'darkgray'
                            : props.status?.color),
                    {
                        'fa-circle':
                            props.status?.icon == undefined &&
                            (props.status?.color == 'grey' || props.status?.color == 'orange'),
                        'fa-circle-exclamation':
                            props.status?.icon == undefined && props.status?.color == 'red',
                        'fa-circle-info':
                            props.status?.icon == undefined && props.status?.color == 'yellow',
                        'fa-circle-check':
                            props.status?.icon == undefined && props.status?.color == 'green',
                        [props.status?.icon || '']: props.status?.icon != undefined
                    }
                ]"
            ></i>
        </Tooltip>
        <span v-if="props.status?.statusText" class="padding-xs-l">
            {{ props.status?.statusText }}
        </span>
    </div>
</template>
