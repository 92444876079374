import { useVue } from "@/app"
import { T } from "@/classes/i18n"
import getterHelpers from "@/helpers/helpers.getters"
import { MutationTypes } from "@/store/vuex.store"
import devLog from "../classes/log"

const unifiedReportingDialogs = {
    showReportsDialog: function (item: any) {
        try {
            let modal: any = {
                id: "ReportFiles",
                abortable: true,
                content: {
                    title: {
                        text: T("Reports")
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component: "show-reports-dialog",
                        properties: {
                            report: item
                        }
                    }
                },
                buttons: [
                    {
                        text: T("Cancel"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "left",
                        loading: false
                    },
                    {
                        text: T("Done"),
                        icon: "fal fa-check",
                        onClick: async function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "right",
                        disabled: false,
                        loading: false
                    }
                ]
            }
            useVue().$refs.modals.addModal(modal)
        } catch (e: any) {
            devLog.log("dialog", e, undefined, "error", 0)
        }
    }
}
export default unifiedReportingDialogs
