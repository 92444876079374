import type { UscUtm } from "@/classes/unifiedSecurityConsole/uscUtms"
import jsonHelpers from "./helpers.json"
const arrayHelpers = {
    getIndexByPropertyValue: function (
        array: any[],
        property: string,
        value: any
    ): number | undefined {
        let result: number | undefined = array.findIndex((arrayEntry) => {
            return arrayEntry[property] == value
        })
        if (result == -1) result = undefined
        return result
    },
    getObjectByPropertyValue: function (
        array: any[],
        property: string,
        value: any
    ): any | undefined {
        let result: any | undefined = array.find((arrayEntry) => {
            return arrayEntry[property] == value
        })
        return result
    },

    onlyUniqueFilter: function (value: any, index: any, self: any) {
        return self.indexOf(value) === index
    },

    sortByObjProperty: function (array: any, propertyPath: string, direction: string = "ASC") {
        let sortOrder = direction == "ASC" ? 1 : -1
        return array.sort(function (a: any, b: any) {
            let propertyA: string | number = String(
                jsonHelpers.getObjectProperty(a, propertyPath) || ""
            ).toLowerCase()
            let propertyB: string | number = String(
                jsonHelpers.getObjectProperty(b, propertyPath) || ""
            ).toLowerCase()
            if (!isNaN(Number(propertyA)) && !isNaN(Number(propertyB))) {
                propertyA = Number(propertyA)
                propertyB = Number(propertyB)
            }
            let result: any = propertyA < propertyB ? -1 : propertyA > propertyB ? 1 : 0
            return result * sortOrder
        })
    },
    chunkify: function (arr: any[], chunkSize: number) {
        if ((arr || []).length == 0) {
            return []
        }
        if (chunkSize <= 0) throw "Invalid chunk size"
        var R: any[] = []
        for (var i = 0, len = arr.length; i < len; i += chunkSize)
            R.push(arr.slice(i, i + chunkSize))
        return R
    },
    sortUtmsByObjProperty(array: UscUtm[], propertyPath: string, direction: string = "ASC") {
        let sortOrder = direction == "ASC" ? 1 : -1
        let clusterObject: {
            [id: string]: UscUtm[]
        } = {}
        // Remove all none Master Cluster Utms from Array
        array = array.filter((utm: UscUtm) => {
            if (utm.cluster?.isCluster && utm.license?.clusterUuid) {
                if (utm.cluster?.licenseRole == "master") {
                    return utm
                } else {
                    const hasMaster =
                        array.filter((masterUtm: UscUtm) => {
                            return (
                                masterUtm.cluster?.licenseRole == "master" &&
                                masterUtm.license.clusterUuid == utm.license.clusterUuid
                            )
                        }).length > 0

                    if (hasMaster) {
                        if (!Array.isArray(clusterObject[utm.license.clusterUuid])) {
                            clusterObject[utm.license.clusterUuid] = []
                        }
                        clusterObject[utm.license.clusterUuid].push(utm)
                    } else {
                        return utm
                    }
                }
            } else {
                return utm
            }
        })
        // Sorts every ClusterObject Array
        Object.keys(clusterObject).forEach((key: string) => {
            clusterObject[key].sort(function (a: any, b: any) {
                let propertyA = String(
                    jsonHelpers.getObjectProperty(a, propertyPath) || ""
                ).toLowerCase()
                let propertyB = String(
                    jsonHelpers.getObjectProperty(b, propertyPath) || ""
                ).toLowerCase()
                let result: any = propertyA < propertyB ? -1 : propertyA > propertyB ? 1 : 0
                return result * sortOrder
            })
        })

        // Sorts the Array
        array = array.sort(function (a: any, b: any) {
            let propertyA = String(
                jsonHelpers.getObjectProperty(a, propertyPath) || ""
            ).toLowerCase()
            let propertyB = String(
                jsonHelpers.getObjectProperty(b, propertyPath) || ""
            ).toLowerCase()
            let result: any = propertyA < propertyB ? -1 : propertyA > propertyB ? 1 : 0
            return result * sortOrder
        })

        JSON.parse(JSON.stringify(array)).forEach((thisUtm: UscUtm, index: number) => {
            if (
                thisUtm.cluster?.isCluster &&
                thisUtm.license?.clusterUuid &&
                thisUtm.cluster.licenseRole === "master" &&
                Array.isArray(clusterObject[thisUtm.license.clusterUuid])
            ) {
                let thisUtmId = thisUtm.utmId
                let index = array.findIndex((utm) => {
                    return utm.utmId == thisUtmId
                })
                if (index !== -1) {
                    index = index + 1
                    array.splice(index, 0, ...clusterObject[thisUtm.license.clusterUuid])
                }
            }
        })
        return array
    }
}
export default arrayHelpers
