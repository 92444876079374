import { useVue } from "@/app"
import { T } from "@/classes/i18n"
import { type TSecureDns } from "@/classes/objectTypes/mobileSecurity/secure-dns/secure-dns"
import getterHelpers from "@/helpers/helpers.getters"
import tenantHelpers from "@/helpers/helpers.tenants"
import { MutationTypes, useStore } from "@/store/vuex.store"

const secureDnsDialogs = {
    showEndpointGenerator(accountId: string, item: TSecureDns, isNew: boolean) {
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore().getters.hasAccountid(accountId) == false) {
                throw "Wrong accountid, stopping process"
            }

            let modal: any = {
                id: "securedns-endpoint-generator",
                abortable: true,
                content: {
                    title: {
                        text: isNew ? T("Integrate your new profile") : T("Integrate your profile"),
                        icon: "fal fa-shield-alt"
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component: "securedns-endpoint-generator",
                        properties: {
                            ...item.endpoints,
                            configId: item.id
                        },
                        maxHeight: "1000"
                    }
                },
                buttons: [
                    {
                        text: T("OK"),
                        icon: "fal fa-check",
                        onClick: async function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "left",
                        disabled: false,
                        loading: false
                    }
                ]
            }

            useVue().$refs.modals.addModal(modal)
        } catch (e: unknown) {
            console.error(e)
        }
    },
    inviteSecureDNSClients(accountId: string) {
        try {
            accountId = tenantHelpers.getAccountId(accountId)
            if (useStore().getters.hasAccountid(accountId) == false) {
                throw "Wrong accountid, stopping process"
            }
            let modal: any = {
                id: "inviteSecureDNS",
                abortable: true,
                content: {
                    title: {
                        text: T("Secure DNS invitation email"),
                        icon: "fal fa-envelope"
                    },
                    body: {
                        use: true,
                        content: undefined,
                        component: "invite-securedns"
                    }
                },
                buttons: [
                    {
                        text: T("Cancel"),
                        icon: "fal fa-times",
                        onClick: async function () {
                            getterHelpers.useStore().commit(MutationTypes.removeModal)
                        },
                        align: "left",
                        disabled: false,
                        loading: false
                    },
                    {
                        text: T("Send invitation"),
                        icon: "fal fa-fw fa-envelope",
                        onClick: async function () {
                            useVue().$refs.modals.$refs.modal.$refs.modalComponent.submit()
                        },
                        align: "right",
                        disabled: true,
                        loading: true
                    }
                ]
            }
            useVue().$refs.modals.addModal(modal)
        } catch (e: unknown) {
            console.error(e)
        }
    }
}
export default secureDnsDialogs
